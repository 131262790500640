const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0, 3, 0),
    borderTop: '1px solid #b3b3b3',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '26px',
    fontWeight: 500,
    '& > span': {
      fontSize: '32px',
      marginRight: theme.spacing(1),
    },
  },
  challengeContainer: {
    display: 'flex',
  },
  challengeImage: {
    height: '3.75rem',
  },
  challengeDescriptionContainer: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& > span': {
      fontWeight: 'bold',
    },
    '& button': {
      marginTop: theme.spacing(1 / 2),
      color: theme.palette.primary.main,
      fontSize: '0.875rem',
    },
  },
})

export default styles
