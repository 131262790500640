import React from 'react'
import { connect } from 'react-redux'
import { discardIdeaById } from 'services/ideas'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import Navigation from 'support/navigation'
import { showPopupDialog } from 'support/popup_dialogs'
import ConfirmDiscardedContainer from '../../ConfirmDiscardedContainer'
import DiscardView from './DiscardView'

const mapStateToProps = (state, ownProps) => ({
  id: ownProps.id,
  rejectedReasons: ownProps.rejectedReasons,
  customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles
})

const mapDispatchToProps = dispatch => {
  return {
    onClick: (id, reason, returnInvestment) => {
      const onSuccess = () => {
        showPopupDialog(<ConfirmDiscardedContainer />, dispatch)
      }
      const onFailure = () => {
        dispatch(hideDialog())
        Navigation.navigateToExplore()
      }
      dispatch(discardIdeaById(id, reason, returnInvestment, onSuccess, onFailure))
      dispatch(hideDialog())
    },
    cancelButtonAction: () => dispatch(hideDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscardView)
