import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import { NxtExpandedCard } from 'components'
import NxtCardListItems from 'components/nxt_card/nxt_card_list_items/View'
import imgDefault from 'img/nxt-inverted-image.png'
import i18n from 'support/i18n'
import { differenceInCalendarDays } from 'date-fns'
import { calcPercentage } from 'support/utils/circleGraph'
import NxtCircleContent from 'components/nxt_circle_content/View'
import { ideaStatusIconMapper, ideaStatusMapper } from 'pages/idea/detail/Constants'
import clsx from 'clsx'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { getCurrentStatusProps } from 'pages/idea/detail/View.Utils'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import styles from './View.Style'

class MyIdeas extends Component {
  constructor(props) {
    super(props)
    this.renderCircle = this.renderCircle.bind(this)
  }

  componentDidMount() {
    this.getMyIdeas()
  }

  getMyIdeas = () => {
    const { getMyIdeas } = this.props
    getMyIdeas()
  }

  renderCircle(status, totalDays, days, percentage) {
    const { classes } = this.props

    if (status && ideaStatusMapper[status] > 0) {
      return (
        <span
          className={clsx(`icon-${ideaStatusIconMapper[ideaStatusMapper[status]]}`, classes.icon)}
        />
      )
    }

    return (
      <div className={classes.secondCircle}>
        <CircularProgressbarWithChildren
          value={totalDays - days}
          maxValue={totalDays}
          strokeWidth={3}
          styles={buildStyles({
            textColor: 'black',
            strokeLinecap: 'butt',
            pathColor: '#999999',
            trailColor: '#f0f0f0',
          })}
        >
          <NxtCircleContent percentage={percentage} days={totalDays - days} />
        </CircularProgressbarWithChildren>
      </div>
    )
  }

  render() {
    const {
      classes,
      navigateToIdea,
      navigateToNewIdea,
      userIdeas,
      ideaStatusStepper,
      isPrivateFlowActive,
      isMyIdeasLoading,
      navigateToUserProfile,
      customTitlesConf
    } = this.props

    const { customIdeaTitles } = customTitlesConf

    return (
      <Fragment>
        <div className={classes.top}>
          <h1 style={{ textTransform: "capitalize" }}>
            <i className="icon-idea" style={{ marginRight: 10 }} />
            {i18n.t('SCREEN.MYIDEAS', { title: customIdeaTitles.pluralTitle || "ideas" })}
          </h1>
        </div>
        <div className={classes.middle}>
          <NxtCircularLoader isLoading={isMyIdeasLoading}>
            <div className={classes.container}>
              <div className={classes.submitIdea}>
                <Button onClick={() => navigateToNewIdea(isPrivateFlowActive)}>
                  <i className="icon-no-idea" />
                  <span>{i18n.t('MY_IDEAS.SUBMIT', { title: customIdeaTitles.singularTitle || "idea" })}</span>
                </Button>
              </div>
              {userIdeas.map(card => {
                const {
                  anonymous,
                  amountInvested,
                  challenged,
                  created,
                  countdownEnds,
                  goal,
                  id,
                  images,
                  investors,
                  ownerName,
                  pledged,
                  profit,
                  sponsored,
                  status,
                  title,
                  ownerEmail,
                } = card

                const [img] = images
                const totalDays = differenceInCalendarDays(countdownEnds, created)
                const days = differenceInCalendarDays(Date.now(), created)
                const percentage = calcPercentage(pledged, goal)
                const btnText = i18n.t('GO_TO_IDEA', { title: customIdeaTitles.singularTitle || "idea" })
                let currentStatusProps = {}

                if (ideaStatusStepper && status) {
                  currentStatusProps = getCurrentStatusProps(ideaStatusStepper, status)
                }
                return (
                  <NxtExpandedCard
                    isAnonymous={anonymous}
                    altTextBlock={`${investors} ${i18n.t('INVESTORS')}`}
                    onSubmitterClick={() => navigateToUserProfile(ownerEmail)}
                    challenged={challenged}
                    defaultSrc={imgDefault}
                    height="536px"
                    id={id}
                    img={typeof img !== 'undefined' ? img.url : ''}
                    investors={investors}
                    key={id}
                    onMainButtonClick={navigateToIdea}
                    percentage={percentage}
                    pledged={pledged}
                    sponsored={sponsored}
                    status={status}
                    submittedBy={ownerName}
                    title={title}
                    circleColor={currentStatusProps.color}
                    circleValue={
                      ideaStatusMapper[status] !== ideaStatusMapper.PENDING_SUPPORT
                        ? '100'
                        : percentage
                    }
                    circleContent={this.renderCircle(status, totalDays, days, percentage)}
                    showCircle={
                      ideaStatusMapper[status] !== ideaStatusMapper.DRAFT &&
                      ideaStatusMapper[status] !== ideaStatusMapper.PENDING_VALIDATION
                    }
                    buttonText={btnText}
                  >
                    <NxtCardListItems
                      direction="row"
                      primaryValue={amountInvested}
                      primaryText={i18n.t('INVESTED_SHORT')}
                      secondaryText={i18n.t('INVESTORS')}
                      variant="primary"
                    />
                    <NxtCardListItems
                      direction="row"
                      primaryValue={pledged}
                      primaryText={i18n.t('PLEDGED')}
                      secondaryText={i18n.t('INVESTORS')}
                    />
                    <NxtCardListItems
                      direction="row"
                      primaryValue={profit}
                      primaryText={i18n.t('EARNED')}
                      secondaryText={i18n.t('INVESTORS')}
                      variant="primary"
                    />
                  </NxtExpandedCard>
                )
              })}
            </div>
          </NxtCircularLoader>
        </div>
      </Fragment>
    )
  }
}

MyIdeas.propTypes = {
  classes: PropTypes.shape({
    top: PropTypes.string,
    middle: PropTypes.string,
    container: PropTypes.string,
    submitIdea: PropTypes.string,
  }).isRequired,
  getMyIdeas: PropTypes.func.isRequired,
  navigateToUserProfile: PropTypes.func.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  isMyIdeasLoading: PropTypes.bool.isRequired,
  userIdeas: PropTypes.arrayOf(
    PropTypes.shape({
      challenged: PropTypes.bool,
      textWithoutTags: PropTypes.string,
      id: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          bucket: PropTypes.string,
          key: PropTypes.string,
          name: PropTypes.string,
          size: PropTypes.number,
          url: PropTypes.string,
        }),
      ),
      investors: PropTypes.string,
      goal: PropTypes.number,
      pledged: PropTypes.string,
      profit: PropTypes.string,
      sponsored: PropTypes.bool,
      ownerName: PropTypes.string,
      title: PropTypes.string,
      created: PropTypes.number,
      countdownEnds: PropTypes.number,
    }),
  ).isRequired,
  customTitlesConf: PropTypes.shape({
    customIdeaTitles: PropTypes.shape({
      singularTitle: PropTypes.string,
      pluralTitle: PropTypes.string
    }),
    isChallenge: PropTypes.bool,
    isInvestment: PropTypes.bool,
  }).isRequired
}

export default withStyles(styles)(MyIdeas)
