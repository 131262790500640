import React, { useState, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, IconButton } from '@material-ui/core'
import { Delete, Close, Check } from '@material-ui/icons'
import { generateRequestCsrf } from 'support/utils/auth'
import i18n from 'support/i18n'
import styles from './View.Style'

const DocumentElement = ({
  classes,
  documentId,
  name,
  timeAgo,
  size,
  src,
  onDeleteDocument,
  canDelete,
  csrfToken,
}) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const cleanUrl = url => {
    const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL.replace('https://', '')
    return url.replace('app.i360.global', apiUrl)
  }
  const getFileTypeIcon = () => {
    if (name.includes('.rm')) {
      return 'video'
    }
    if (name.includes('.rm')) {
      return 'video'
    }
    if (
      name.includes('.jpg') ||
      name.includes('.jpeg') ||
      name.includes('.png') ||
      name.includes('.gif') ||
      name.includes('.bmp') ||
      name.includes('.psd') ||
      name.includes('.tiff')
    ) {
      return 'image'
    }
    if (name.includes('.docx')) {
      return 'text'
    }
    if (name.includes('.xlsx')) {
      return 'excel'
    }
    if (name.includes('.ppt')) {
      return 'presentation'
    }
    if (name.includes('.pdf')) {
      return 'pdf'
    }
    return 'unknown'
  }
  return (
    <Fragment>
      <div className={classes.leftContent}>
        <span className={`fcon-${getFileTypeIcon()} icon`} />
        <span>
          {src ? (
            <a
              href={`${cleanUrl(src)}?_nxt_csrf=${generateRequestCsrf(csrfToken)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {name}
            </a>
          ) : (
            name
          )}
        </span>
      </div>
      <div className={classes.middleContent}>
        <span>{timeAgo}</span>
      </div>
      <div className={classes.rightContent}>
        {!isDeleting && (
          <Fragment>
            <span>{`${i18n.toCurrency(size / 1024, {
              unit: '',
              precision: 0,
            })} KB`}</span>
            {canDelete && (
              <IconButton
                className={classes.deleteButton}
                onClick={() => setIsDeleting(true)}
                aria-label="delete"
              >
                <Delete />
              </IconButton>
            )}
          </Fragment>
        )}
        {canDelete && isDeleting && (
          <Fragment>
            <IconButton
              id="confirm-delete"
              onClick={() => {
                onDeleteDocument(documentId)
                setIsDeleting(false)
              }}
              aria-label="confirm"
            >
              <Check />
            </IconButton>
            <IconButton id="cancel-delete" onClick={() => setIsDeleting(false)} aria-label="cancel">
              <Close />
            </IconButton>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

DocumentElement.defaultProps = {
  src: '',
  onDeleteDocument: () => {},
}

DocumentElement.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string,
  documentId: PropTypes.string.isRequired,
  canDelete: PropTypes.bool.isRequired,
  onDeleteDocument: PropTypes.func,
  csrfToken: PropTypes.string.isRequired,
}

export default withStyles(styles)(DocumentElement)
