import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import styles from './AlertModalView.Style'

export const SINGULAR_IDEA_MATCH = /idea|Idea/g

const AlertModalView = props => {
  const {
    classes,
    title,
    mainButtonAction,
    mainButtonText,
    cancelable,
    cancelButtonAction,
    cancelButtonText,
    alternative,
    alternativeButtonAction,
    alternativeButtonText,
    customIdeaTitles,
    description,
  } = props

  return (
    <div className={classes.dialogBox}>
      {cancelable && (
        <IconButton
          className={classes.iconClose}
          aria-label="close"
          onClick={cancelButtonAction}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <span className="icon-close" />
        </IconButton>
      )}
      <DialogTitle id="alert-dialog-title">
        {title?.replaceAll(SINGULAR_IDEA_MATCH, customIdeaTitles.singularTitle)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span>{description}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={mainButtonAction} color="primary" variant="contained" autoFocus>
          {mainButtonText.replaceAll(SINGULAR_IDEA_MATCH, customIdeaTitles.singularTitle)}
        </Button>
        {alternative && (
          <Button onClick={alternativeButtonAction} color="primary">
            {alternativeButtonText}
          </Button>
        )}
        {cancelable && cancelButtonText && (
          <Button onClick={cancelButtonAction} color="primary">
            {cancelButtonText}
          </Button>
        )}
      </DialogActions>
    </div>
  )
}

AlertModalView.propTypes = {
  title: PropTypes.string,
  mainButtonText: PropTypes.string,
  mainButtonAction: PropTypes.func,
  cancelable: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  cancelButtonAction: PropTypes.func,
  alternative: PropTypes.bool,
  alternativeButtonText: PropTypes.string,
  alternativeButtonAction: PropTypes.func,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string,
  }),
}

AlertModalView.defaultProps = {
  title: '',
  mainButtonText: '',
  mainButtonAction: undefined,
  cancelable: false,
  cancelButtonText: undefined,
  cancelButtonAction: undefined,
  alternative: false,
  alternativeButtonText: undefined,
  alternativeButtonAction: undefined,
  customIdeaTitles: {
    singularTitle: '',
    pluralTitle: '',
  },
}

export default withStyles(styles)(AlertModalView)
