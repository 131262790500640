import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import NxtDatePicker from 'components/nxt_date_picker/View'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import addDays from 'date-fns/addDays'
import styles from './View.Style'

const IntervalDatesPicker = props => {
  const { classes, onSelectFromDate, onSelectToDate, fromDate, toDate, disabled, minDate } = props
  return (
    <div className={classes.root}>
      <NxtDatePicker
        onDateChange={onSelectFromDate}
        value={fromDate}
        label={i18n.t('DASHBOARD.START_DATE')}
        endIconName="icon-th"
        isDisabled={disabled}
        minDate={minDate}
      />
      <NxtDatePicker
        onDateChange={onSelectToDate}
        value={toDate}
        label={i18n.t('DASHBOARD.END_DATE')}
        endIconName="icon-th"
        minDate={addDays(fromDate, 1)}
        isDisabled={disabled}
      />
    </div>
  )
}

IntervalDatesPicker.defaultProps = {
  disabled: false,
  minDate: new Date(null),
}

IntervalDatesPicker.propTypes = {
  onSelectFromDate: PropTypes.func.isRequired,
  onSelectToDate: PropTypes.func.isRequired,
  fromDate: PropTypes.instanceOf(Date).isRequired,
  toDate: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
}

export default withStyles(styles)(IntervalDatesPicker)
