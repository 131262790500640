import React, { useState, useRef, useEffect } from 'react'
import { Button, CircularProgress, IconButton, withStyles } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import styles from './View.Style'

function VideoUploadDialog({
  classes,
  fileVideoSrc,
  fileVideoType,
  fileVideoId,
  isVideoLoading,
  isVideoReady,
  isVideoRemoving,
  hasVideoError,
  onUploadVideo,
  onDeleteVideo,
  onCancel,
  beforeUploadVideo,
  storageVideoUploadUrl,
  accept,
}) {
  const fileInput = useRef(null)
  const [file, setFile] = useState(undefined)

  const handleGenerateUploadUrl = (event) => {
    setFile(event.target.files[0])
    beforeUploadVideo(0, event.target.files[0])
    fileInput.current = null
  }

  useEffect(() => {
    if (storageVideoUploadUrl && file) {
      onUploadVideo(file)
    }
  }, [storageVideoUploadUrl, onUploadVideo, file])

  const isVideoEncoding = fileVideoSrc && !isVideoReady
  return (
    <div className={classes.root}>
      <div className={classes.modalTitle}>
        <h2>Subir Video</h2>
        <div className={classes.icon}>
          <IconButton
            aria-label="delete"
            onClick={onCancel}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <span className="icon-close" />
          </IconButton>
        </div>
      </div>

      <div className={classes.modalBody}>
        {fileVideoSrc && isVideoReady ? (
          <video className={classes.video} controls>
            <source src={fileVideoSrc} type={fileVideoType} />
            <track label="video_challenge" src="captions_en.vtt" kind="captions" srcLang="en" />
          </video>
        ) : (
          <div className={classes.noVideo}>
            <span className="icon-video" />
            <div className={classes.textWrapper}>
              <p>{i18n.t('VIDEO.NO_VIDEO')}</p>
              <p></p>
            </div>
          </div>
        )}
      </div>

      <div className={classes.buttonsWrapper}>
        <div>
          <label htmlFor="contained-button-video">
            <input
              disabled={!!fileVideoId}
              accept={accept}
              className={classes.input}
              id="contained-button-video"
              type="file"
              ref={fileInput}
              onChange={handleGenerateUploadUrl}
            />
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={isVideoLoading || !!fileVideoSrc}
            >
              {isVideoLoading && (
                <CircularProgress className={classes.spinner} size={20} color="primary" />
              )}
              {i18n.t('VIDEO.UPLOAD_TITLE')}
            </Button>
          </label>
          <Button onClick={onCancel}>{i18n.t('VIDEO.CLOSE')}</Button>
        </div>
        {fileVideoId && (!isVideoLoading || hasVideoError) && (
          <Button color="secondary" onClick={() => onDeleteVideo(fileVideoId)}>
            {isVideoRemoving && (
              <CircularProgress className={classes.spinner} size={20} color="primary" />
            )}
            {i18n.t('VIDEO.DELETE')}
          </Button>
        )}
      </div>
      {isVideoLoading && <p>{i18n.t('VIDEO.UPLOAD_WAIT')}</p>}
      {isVideoEncoding && !hasVideoError && <p>{i18n.t('VIDEO.VIDEO_ENCODING')}</p>}
      {hasVideoError && <p>{i18n.t('VIDEO.VIDEO_ENCODING_ERROR')}</p>}
    </div>
  )
}

VideoUploadDialog.defaultProps = {
  isVideoLoading: false,
  isVideoReady: false,
  isVideoRemoving: false,
  hasVideoError: false,
  fileVideoId: '',
  fileVideoSrc: '',
  fileVideoType: '',
  storageVideoUploadUrl: '',
  accept: process.env.REACT_APP_VALID_VIDEO_TYPES,
}

VideoUploadDialog.propTypes = {
  fileVideoSrc: PropTypes.string,
  fileVideoType: PropTypes.string,
  fileVideoId: PropTypes.string,
  isVideoLoading: PropTypes.bool,
  isVideoReady: PropTypes.bool,
  isVideoRemoving: PropTypes.bool,
  hasVideoError: PropTypes.bool,
  onUploadVideo: PropTypes.func.isRequired,
  onDeleteVideo: PropTypes.func.isRequired,
  beforeUploadVideo: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  storageVideoUploadUrl: PropTypes.string,
  accept: PropTypes.string,
}

export default withStyles(styles)(VideoUploadDialog)
