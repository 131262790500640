const styles = () => ({
  container: {
    margin: '30px auto',
    padding: '0 10px',
    display: 'grid',
    'grid-template-columns': '1fr 1fr',
    width: '60%',
    'grid-column-start': '1',
    'grid-column-end': '4',
  },
})

export default styles
