import { ROI_SETTINGS, fromValueToPercentage } from 'pages/challenge/form/View.Utils'
import { VIDEO_TYPE } from 'common_constants/Video'
import { extractPropertiesFromJsonObject } from '../RequestUtils'

export const setImageContent = (image, state, type) => {
  return `${encodeURIComponent('id')}=${encodeURIComponent(
    state.data.challengeSteps.id,
  )}&${encodeURIComponent('image')}=${encodeURIComponent(image)}&${encodeURIComponent(
    'mode',
  )}=${encodeURIComponent(type)}`
}

export const setVideoContent = video => {
  const videoFile = new FormData()
  videoFile.append('file', video)
  return videoFile
}

export const getDeleteChallengeVideoUrlFromState = url => state => {
  const id = state.data.challengeSteps.challengeDescription.fileVideoId
  return url.replace('##challengeVideoId##', id)
}

export const setMainImageContent = (imageName, state) => {
  return `${encodeURIComponent('id')}=${encodeURIComponent(
    state.data.challengeSteps.id,
  )}&${encodeURIComponent('name')}=${encodeURIComponent(imageName)}`
}

export const setDeleteImageContent = (imageName, state, type) => {
  return `${state.data.challengeSteps.id}/image/${encodeURIComponent(type)}/${encodeURIComponent(
    imageName,
  )}`
}

const setVideoFromState = (externalVideoSrc, fileVideoSrc) => {
  let videoType = VIDEO_TYPE.NONE
  let videoLink = ''
  if (externalVideoSrc) {
    videoType = VIDEO_TYPE.YOUTUBE
    videoLink = externalVideoSrc
  }
  if (fileVideoSrc) {
    videoType = VIDEO_TYPE.BLOBSTORAGE
    videoLink = fileVideoSrc
  }
  return [videoType, videoLink]
}

export const setChallengeInfo = (
  id = '',
  { title, description, whyImportant = '', externalVideo = '', fileVideoUrl = '' },
) => {
  const [videoType, videoLink] = setVideoFromState(externalVideo, fileVideoUrl)

  return `${encodeURIComponent('id')}=${encodeURIComponent(id)}&${encodeURIComponent(
    'title',
  )}=${encodeURIComponent(title)}&${encodeURIComponent('desc')}=${encodeURIComponent(
    description,
  )}&${encodeURIComponent('importance')}=${encodeURIComponent(whyImportant)}&${encodeURIComponent(
    'videoType',
  )}=${encodeURIComponent(videoType)}&${encodeURIComponent('videoLink')}=${encodeURIComponent(
    videoLink,
  )}`
}

export const setPeopleInfo = (id = '', selectedSponsor, selectedProposer) => {
  return `${encodeURIComponent('id')}=${encodeURIComponent(id)}&${encodeURIComponent(
    'sponsor',
  )}=${encodeURIComponent(selectedSponsor.email)}&${encodeURIComponent(
    'proposer',
  )}=${encodeURIComponent(selectedProposer.email || '')}`
}

export const setRoiInfo = (id = '', gaming, activeTab) => {
  const goalMult = fromValueToPercentage(
    gaming.advancedMode.defaultValues.currentGoal,
    gaming.advancedMode.currentGoal,
  )
  const supportMult = fromValueToPercentage(
    gaming.advancedMode.defaultValues.supportMult,
    gaming.advancedMode.supportMult,
  )
  const approveMult = fromValueToPercentage(
    gaming.advancedMode.defaultValues.approveMult,
    gaming.advancedMode.approveMult,
  )
  const implementMult = fromValueToPercentage(
    gaming.advancedMode.defaultValues.implementMult,
    gaming.advancedMode.implementMult,
  )

  return `${encodeURIComponent('id')}=${encodeURIComponent(id)}&${encodeURIComponent(
    'goalMult',
  )}=${encodeURIComponent(goalMult)}&${encodeURIComponent('supportMult')}=${encodeURIComponent(
    supportMult,
  )}&${encodeURIComponent('approveMult')}=${encodeURIComponent(approveMult)}&${encodeURIComponent(
    'implementMult',
  )}=${encodeURIComponent(implementMult)}&${encodeURIComponent('roiDefault')}=${encodeURIComponent(
    activeTab === 0 ? gaming.easyMode.settings === ROI_SETTINGS.DEFAULT : false,
  )}&${encodeURIComponent('roiLessIdeas')}=${encodeURIComponent(
    activeTab === 0 ? gaming.easyMode.settings === ROI_SETTINGS.FEWER_IDEAS : false,
  )}&${encodeURIComponent('roiMoreIdeas')}=${encodeURIComponent(
    activeTab === 0 ? gaming.easyMode.settings === ROI_SETTINGS.MORE_IDEAS : false,
  )}`
}

export function setRewardInfo(id, reward) {
  return extractPropertiesFromJsonObject({
    id,
    rewardDesc: reward.description,
    rewardFor: reward.rewarded,
    rewardOther: reward.rewardedOther,
  })
}

export function setChangeDatesBody(state) {
  return extractPropertiesFromJsonObject({
    id: state.data.challengeSteps.id,
    starts: state.data.challengeSteps.preview.fromDate.getTime(),
    ends: state.data.challengeSteps.preview.toDate.getTime(),
  })
}

export function setLaunchBody(state) {
  return extractPropertiesFromJsonObject({
    id: state.data.challengeSteps.id,
    starts: state.data.challengeSteps.preview.fromDate.getTime(),
    ends: state.data.challengeSteps.preview.toDate.getTime(),
    groupIds: state.data.challengeSteps.preview.rightGroups.reduce((accumulator, current) => {
      return accumulator ? `${accumulator}##${current.id}` : current.id
    }, ''),
    organization: state.data.domain.domainConfiguration.isOrganizationsActive
      ? state.data.challengeSteps.preview.organization || null
      : null,
  })
}

export function setProposeBody(state) {
  const { fileVideoUrl, externalVideo } = state.data.challengeSteps.challengeDescription
  const [videoType, videoLink] = setVideoFromState(externalVideo, fileVideoUrl)
  return extractPropertiesFromJsonObject({
    id: state.data.challengeSteps.id,
    title: state.data.challengeSteps.challengeDescription.title,
    desc: state.data.challengeSteps.challengeDescription.description,
    importance: state.data.challengeSteps.challengeDescription.whyImportant,
    videoLink,
    videoType,
  })
}
