const styles = theme => ({
  root: {
    '& > :first-child': {
      padding: theme.spacing(2, 0),
    },
    '& .MuiTableCell-root .MuiAvatar-root img': {
      width: '100%',
      height: '100%',
      display: 'block',
    },
    '& .MuiButtonGroup-root .MuiButton-containedPrimary': {
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      '&:hover': {
        border: '2px solid #0f2075',
      }
    }
  },
  viewAsContainer: {
    '& > span': {
      fontSize: '0.9rem',
      marginRight: theme.spacing(),
    },
  },
  bottomTableTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  hide: {
    display: 'none',
  },
})

export default styles
