import ideaStatusList from 'common_constants/Selects'

export function getStatusSelectList(ideaStatusStepper) {
  const approved = { 
    name: ideaStatusStepper.approvedLabel,
    id: ideaStatusList[0].id,
    value: ideaStatusList[0].value,
  }
  const discarded = { 
    name: ideaStatusStepper.discardedLabel,
    id: ideaStatusList[1].id,
    value: ideaStatusList[1].value,
  }
  const implemented = { 
    name: ideaStatusStepper.implementedLabel,
    id: ideaStatusList[2].id,
    value: ideaStatusList[2].value,
  }
  const pendingSupport = { 
    name: ideaStatusStepper.pendingSupportLabel,
    id: ideaStatusList[3].id,
    value: ideaStatusList[3].value,
  }
  const pilotActive = { 
    name: ideaStatusStepper.pilotActiveLabel,
    id: ideaStatusList[4].id,
    value: ideaStatusList[4].value,
  }
  const roundClosed = { 
    name: ideaStatusStepper.roundClosedLabel,
    id: ideaStatusList[5].id,
    value: ideaStatusList[5].value,
  }
  const supported = { 
    name: ideaStatusStepper.supportedLabel,
    id: ideaStatusList[6].id,
    value: ideaStatusList[6].value,
  }
  return [
    pendingSupport,
    supported,
    roundClosed,
    approved,
    pilotActive,
    implemented,
    discarded,
  ]
}