import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'
import RoundRow from './components/round_row/View'

const InvestmentSection = (props) => {
  const { classes, investment, onInvestorClick, customIdeaTitle } = props

  return (
    <div className={classes.root}>
      <h2 className={classes.titleSection}>
        <span className="icon-star" />
        {customIdeaTitle === "" ? i18n.t('IDEA.INVESTORS') : i18n.t('IDEA.INVESTORS').replace("idea", customIdeaTitle)}
      </h2>

      {investment.seedInvestors.length > 0 && (
        <RoundRow
          investors={investment.seedInvestors}
          roundTitle={i18n.t('VENTURE_ROUNDS.TITLES.SEED')}
          roundSubtitle={i18n.t('VENTURE_ROUNDS.SUBTITLES.SEED')}
          hasBar={investment.firstRoundInvestors.length > 0}
          onInvestorClick={onInvestorClick}
        />
      )}

      {investment.firstRoundInvestors.length > 0 && (
        <RoundRow
          investors={investment.firstRoundInvestors}
          circleContent={
            /* eslint-disable-next-line react/no-danger */
            <span dangerouslySetInnerHTML={{ __html: i18n.t('VENTURE_ROUNDS.FIRST') }} />
          }
          roundTitle={i18n.t('VENTURE_ROUNDS.TITLES.FIRST')}
          roundSubtitle={i18n.t('VENTURE_ROUNDS.SUBTITLES.FIRST')}
          hasBar={investment.secondRoundInvestors.length > 0}
          onInvestorClick={onInvestorClick}
        />
      )}

      {investment.secondRoundInvestors.length > 0 && (
        <RoundRow
          investors={investment.secondRoundInvestors}

          circleContent={
            /* eslint-disable-next-line react/no-danger */
            <span dangerouslySetInnerHTML={{ __html: i18n.t('VENTURE_ROUNDS.SECOND') }} />
          }
          roundTitle={i18n.t('VENTURE_ROUNDS.TITLES.SECOND')}
          roundSubtitle={i18n.t('VENTURE_ROUNDS.SUBTITLES.SECOND')}
          hasBar={investment.thirdRoundInvestors.length > 0}
          onInvestorClick={onInvestorClick}
        />
      )}

      {investment.thirdRoundInvestors.length > 0 && (
        <RoundRow
          investors={investment.thirdRoundInvestors}
          circleContent={
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: i18n.t('VENTURE_ROUNDS.THIRD') }}
            />
          }
          roundTitle={i18n.t('VENTURE_ROUNDS.TITLES.THIRD')}
          roundSubtitle={i18n.t('VENTURE_ROUNDS.SUBTITLES.THIRD')}
          hasBar={false}
          onInvestorClick={onInvestorClick}
        />
      )}
    </div>
  )
}

InvestmentSection.propTypes = {
  investment: PropTypes.shape({
    seedInvestors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    firstRoundInvestors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    secondRoundInvestors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    thirdRoundInvestors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  onInvestorClick: PropTypes.func.isRequired,
  customIdeaTitle: PropTypes.string.isRequired
}

export default withStyles(styles)(InvestmentSection)
