import { connect } from 'react-redux'
import AlertModalView from 'support/popup_dialogs/views/components/alert_modal_view/AlertModalView'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import Navigation from 'support/navigation'
import i18n from 'support/i18n'

const mapStateToProps = (state) => {
  const { customIdeaTitles } = state.data.domain.customTitlesConf

  return {
    title: i18n.t('IDEA.SELECT_TYPE', { title: customIdeaTitles.singularIdea || "idea" }),
    mainButtonText: i18n.t('IDEA.SEND_IDEA', { title: customIdeaTitles.singularIdea || "idea" }),
    alternativeButtonText: i18n.t('IDEA.CONTRIBUTE'),
    alternative: true,
    cancelable: true,
    customIdeaTitles
  }
}

const mapDispatchToProps = dispatch => {
  return {
    mainButtonAction: () => {
      dispatch(hideDialog())
      dispatch(Navigation.navigateToNewPrivateIdea())
    },
    alternativeButtonAction: () => {
      dispatch(hideDialog())
      dispatch(Navigation.navigateToNewIdea())
    },
    cancelButtonAction: () => dispatch(hideDialog())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModalView)
