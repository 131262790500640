import background from 'img/image_3.png'

const styles = theme => ({
  notVisible: {
    visibility: 'hidden',
  },
  base: {
    ...theme.loginBase,
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    backgroundColor: props =>
      props.useCustomLogin && props.useCustomColor && props.customColor ? props.customColor : 'white',
    backgroundImage: props =>
      props.useCustomLogin && props.useCustomBgImg ? `url(${props.customBgImg})` : `url(${background})`,
    backgroundSize: props => (props.useCustomLogin && props.useCustomBgImg ? 'cover' : 'cover'),
    backgroundPosition: props =>
      props.useCustomLogin && props.useCustomBgImg ? 'bottom' : 'bottom',
    color: props =>
      props.useCustomLogin && props.useCustomTextColor && props.customTextColor
        ? props.customTextColor
        : theme.palette.common.black,
  },
  customize: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    padding: "1rem 3rem 2rem 3rem",
    '& > img': {
      maxWidth: '120px',
      height: 'auto',
      marginTop: '25px',
      [theme.breakpoints.up('lg')]: {
        marginTop: '0',
      }
    },
    '& .content': {
      padding: 15,
    },
    '& .content h2': {
      marginBottom: '33px',
      opacity: '.89',
      fontWeight: 600,
      fontSize: '20px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
    '& .content p': {
      marginBottom: '35px',
      opacity: '.7',
      fontStyle: 'italic',
      fontSize: '15px',
      lineHeight: '25px',
    },
    '& .footer p': {
      marginBottom: '28px',
      opacity: '.39',
      fontSize: '11px',
      textTransform: 'uppercase',
      color: '#FFF',
      letterSpacing: '.91px',
    },
    '& .footer svg': {
      width: '30px',
      fill: props =>
        props.useCustomLogin && props.useCustomTextColor && props.customTextColor
          ? props.customTextColor
          : theme.palette.common.black,
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '& .footer a': {
      opacity: 0.6
    },
    '& .footer a:hover': {
      opacity: 1
    },
  },
})

export default styles
