import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Button, RadioGroup } from '@material-ui/core'
import Slider from 'components/slider/View'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import ImageItem from './components/image_item/View'
import styles from './View.Style'

class ImageUploader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeStep: 0,
    }

    this.fileInput = React.createRef()
    this.handleUploadImage = this.handleUploadImage.bind(this)
    this.handleDeleteButton = this.handleDeleteButton.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { images, onSelectMainImage, isOneImageOnly } = this.props
    if (prevProps.images.length < images.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ activeStep: images.length - 1 })
    }
    if (!isOneImageOnly && prevProps.images.length !== images.length && images.length === 1) {
      onSelectMainImage(images[0].name)
    }
  }

  handleUploadImage({ target }) {
    const { onSelectImage } = this.props
    if (!target.files.length) return
    const reader = new FileReader()
    reader.readAsDataURL(target.files[0])

    reader.onload = event => {
      if (event.target.readyState === FileReader.DONE) {
        const src = event.target.result
        onSelectImage({ name: target.files[0].name, src })
        if (this.fileInput) {
          this.fileInput.value = ''
        }
      }
    }
  }

  handleDeleteButton() {
    const { deleteImage, images } = this.props
    const { activeStep } = this.state
    const activeImageName = images[activeStep] && images[activeStep].name
    if (activeImageName) {
      const imageToDelete = images[activeStep]
      const newImages = images.filter(image => image.name !== activeImageName)
      deleteImage(newImages, activeImageName, imageToDelete)
      this.setState({ activeStep: 0 })
    }
  }

  render() {
    const {
      classes,
      images,
      imageUploadError,
      onSelectMainImage,
      mainImageValue,
      usingDelete,
      canChooseMainImage,
      isOneImageOnly,
    } = this.props
    const { activeStep } = this.state
    const maxSteps = images.length
    const showButton = true
    return (
      <div className={classes.root}>
        <div className={classes.sliderContainer}>
          {!isOneImageOnly &&
            (images.length > 0 && (
              <RadioGroup value={mainImageValue} className={classes.radioImages}>
                <Slider
                  activeStep={activeStep}
                  maxSteps={maxSteps}
                  setActiveStep={newStep => this.setState({ activeStep: newStep })}
                >
                  {images.map(image => (
                    <ImageItem
                      key={image.key}
                      image={image}
                      mainImageValue={mainImageValue}
                      canChooseMainImage={canChooseMainImage}
                      onSelectMainImage={onSelectMainImage}
                      onDeleteButton={this.handleDeleteButton}
                    />
                  ))}
                </Slider>
              </RadioGroup>
            ))}
          {isOneImageOnly && images.length === 1 && (
            <ImageItem
              image={images[0]}
              mainImageValue={mainImageValue}
              canChooseMainImage={canChooseMainImage}
              onSelectMainImage={onSelectMainImage}
              onDeleteButton={this.handleDeleteButton}
              usingDelete={usingDelete}
            />
          )}
          {showButton && (
            <label htmlFor="contained-button-file">
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                ref={ref => {
                  this.fileInput = ref
                }}
                onChange={this.handleUploadImage}
              />
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
              >
                {images.length === 0 ? i18n.t('IDEA.CHOOSE_IMAGES') : i18n.t('ADD_IMAGE')}
              </Button>
            </label>
          )}
          {imageUploadError && <p className={classes.error}>*{imageUploadError}</p>}
        </div>
      </div>
    )
  }
}

ImageUploader.defaultProps = {
  imageUploadError: '',
  onSelectMainImage: () => {},
  mainImageValue: '',
  usingDelete: true,
  canChooseMainImage: false,
  isOneImageOnly: false,
  deleteImage: () => {},
}

ImageUploader.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    sliderContainer: PropTypes.string,
    imageContainer: PropTypes.string,
    img: PropTypes.string,
    input: PropTypes.string,
    deleteButton: PropTypes.string,
    button: PropTypes.string,
    error: PropTypes.string,
    stepperRoot: PropTypes.string,
    radioButtonMain: PropTypes.string,
  }).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      src: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ).isRequired,
  onSelectImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func,
  imageUploadError: PropTypes.string,
  onSelectMainImage: PropTypes.func,
  mainImageValue: PropTypes.string,
  usingDelete: PropTypes.bool,
  canChooseMainImage: PropTypes.bool,
  isOneImageOnly: PropTypes.bool,
}

export default withStyles(styles)(ImageUploader)
