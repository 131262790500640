import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import NxtUserCard from 'components/nxt_user_card/View'
import ButtonLink from 'components/button_link/View'
import i18n from 'support/i18n'
import style from './View.Style'

const CreatorsSection = props => {
  const { classes, submitter, promoter, onUserNameClick, isAnonymous } = props
  return (
    <div className={classes.root}>
      {isAnonymous && (
        <NxtUserCard
          classes={{ nameImage: classes.userCardIdeaRoot }}
          isInverted
          label={i18n.t('SUBMITTED_BY')}
          textColor="black"
          imageSize="2rem"
          usernameElement={i18n.t('ANONYMOUS')}
        />
      )}
      {!isAnonymous && submitter && (
        <NxtUserCard
          classes={{ nameImage: classes.userCardIdeaRoot }}
          isInverted
          imageSrc={submitter.photo}
          label={i18n.t('SUBMITTED_BY')}
          textColor="black"
          imageSize="2rem"
          usernameElement={
            <div className={classes.linkContainer}>
              <ButtonLink
                id="submitter-id"
                classes={{ linkButton: classes.submitterUserLink }}
                onLinkClick={() => onUserNameClick(submitter.email)}
              >
                {submitter.fullName}
              </ButtonLink>
            </div>
          }
        />
      )}
      {promoter.fullName && (
        <NxtUserCard
          classes={{ nameImage: classes.userCardIdeaRoot }}
          imageSrc={promoter.photo}
          usernameElement={
            <div className={classes.linkContainer}>
              <ButtonLink
                id="promoter-id"
                classes={{ linkButton: classes.promoterUserLink }}
                onLinkClick={() => onUserNameClick(promoter.email)}
              >
                {promoter.fullName}
              </ButtonLink>
            </div>
          }
          label={i18n.t('EXPLORE_IDEA.PROMOTED_BY')}
          textColor="black"
          imageSize="2rem"
        />
      )}
    </div>
  )
}

CreatorsSection.defaultProps = {
  promoter: {
    fullName: '',
    photo: '',
    email: '',
  },
}

CreatorsSection.propTypes = {
  submitter: PropTypes.shape({
    fullName: PropTypes.string,
    photo: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  promoter: PropTypes.shape({
    fullName: PropTypes.string,
    photo: PropTypes.string,
    email: PropTypes.string,
  }),
  onUserNameClick: PropTypes.func.isRequired,
}

export default withStyles(style)(CreatorsSection)
