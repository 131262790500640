import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  withStyles,
} from '@material-ui/core'
import i18n from 'support/i18n'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import styles from './View.Style'

class DiscardView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      discardReason: '',
      returnInvestment: false,
      value: '',
    }
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  handleChangeRadio = event => {
    this.setState({ value: event.target.value })
    this.setState({ discardReason: event.target.value.replace('-', '\n') })
  }

  handleOnClick(discardReason, returnInvestment) {
    const { id, onClick } = this.props
    onClick(id, discardReason, returnInvestment)
  }

  handleTextFieldChange(event) {
    this.setState({ discardReason: event.target.value })
  }

  render() {
    const { classes, cancelButtonAction, rejectedReasons, customIdeaTitles } = this.props
    const { discardReason, returnInvestment, value } = this.state

    return (
      <div className={classes.dialogBox}>
        <DialogTitle id="alert-dialog-title">{i18n.t('CONFIRMATION_MESSAGE')}</DialogTitle>
        <DialogContent>
          <span
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: i18n.t('EXPLORE_IDEA.DISCARD_IDEA', {
                title: customIdeaTitles.singularTitle || 'idea',
              }),
            }}
          />
          <div>
            <FormControl>
              <RadioGroup value={value} onChange={this.handleChangeRadio}>
                {rejectedReasons?.data?.map(item => (
                  <FormControlLabel
                    value={`${item.title}-${item.text}`}
                    control={
                      <Radio
                        className={classes.root}
                        icon={<span className={classes.radioNotChecked} />}
                        checkedIcon={<span className="icon-approved" />}
                      />
                    }
                    label={
                      <div>
                        <p>{item.title}</p>
                        <small>{item.text}</small>
                      </div>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          <TextField
            color="primary"
            id="filled-multiline-static"
            fullWidth
            label={i18n.t('IDEA.MESSAGE')}
            multiline
            onChange={this.handleTextFieldChange}
            minRows="4"
            margin="normal"
            value={discardReason}
            variant="filled"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.handleOnClick(discardReason, returnInvestment)}
          >
            {i18n.t('ACCEPT')}
          </Button>
          <Button variant="contained" color="secondary" onClick={cancelButtonAction}>
            {i18n.t('CANCEL')}
          </Button>
        </DialogActions>
      </div>
    )
  }
}

DiscardView.defaultProps = {
  id: '',
  rejectedReasons: {},
  customIdeaTitles: {
    singularTitle: '',
    pluralTitle: '',
  },
}

DiscardView.propTypes = {
  id: PropTypes.string,
  rejectedReasons: PropTypes.shape({}),
  onClick: PropTypes.func.isRequired,
  cancelButtonAction: PropTypes.func.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string,
  }),
}

export default withStyles(styles)(DiscardView)
