const styles = theme => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(8),
    '& > div': {
      boxSizing: 'border-box',
    },
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  lightTheme: {
    backgroundColor: theme.palette.common.white,
    color: "#474747",
  },
  darkTheme: {
    backgroundColor: '#333',
    color: theme.palette.common.white,
  },
  leftPart: {
    position: 'relative',
    padding: theme.spacing(2.5, 1.25, 2.5, 2.5),
    borderTopLeftRadius: theme.spacing(1 / 2),
    borderBottomLeftRadius: theme.spacing(1 / 2),
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
  },
  rightPart: {
    padding: theme.spacing(2.5, 2.5, 2.5, 1.25),
    borderTopRightRadius: theme.spacing(1 / 2),
    borderBottomRightRadius: theme.spacing(1 / 2),
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
  },
  titlesContainer: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  titlesContainerWithCorner: {
    margin: theme.spacing(0, 0, 8, 6),
  },
  resumeCardTitle: {
    margin: 0,
    fontSize: '1.875rem',
    fontWeight: 700,
    wordBreak: 'break-word',
    maxHeight: '152px',
    overflow: 'hidden',
  },
  resumeCardSubTitle: {
    margin: theme.spacing(1 / 2, 0, 0, 0),
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  resumeCardSlider: {
    marginBottom: theme.spacing(2),
  },
  overlay: {
    position: 'relative',
    backgroundColor: 'black',
    height: '25%',
    top: '75%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
  },
  text: {
    wordBreak: 'break-word',
    textAlign: 'justify'
  },
})

export default styles
