const styles = () => ({
  root: {
    width: '100%',
    paddingTop: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
    '& .Mui-checked': {
      color: '#0096e2',
      '& .MuiFormControlLabel-label': {
        fontWeight: 700,
      }
    }
  },
  formControl: {
    width: '100%',
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  option: {
    flex: 1,
  },
  rangeLabelContainer: {
    paddingLeft: '20px',
  },
  rangeLabel: {
    fontWeight: 700,
  }
})

export default styles
