import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Paper } from '@material-ui/core'
import clsx from 'clsx'
import styles from './View.Style'
import ResumeSlider from './components/resume_slider/View'
import NxtCardCorner from '../nxt_card/nxt_card_corner/View'

const NxtResumeCard = props => {
  const {
    classes,
    selectedProposer,
    children,
    title,
    subTitle,
    description,
    images,
    videoSrc,
    videoType,
    showLeftCorner,
    componentTheme,
    sliderOptions,
  } = props

  return (
    <Paper className={classes.root}>
      <div
        className={clsx(
          componentTheme === 'light' ? classes.lightTheme : classes.darkTheme,
          classes.leftPart,
        )}
      >
        {showLeftCorner && <NxtCardCorner challenged />}
        <div
          className={clsx(
            classes.titlesContainer,
            showLeftCorner && classes.titlesContainerWithCorner,
          )}
        >
          <h2 className={classes.resumeCardTitle}>{title}</h2>
          {subTitle && subTitle.length > 0 && (
            <h6 className={classes.resumeCardSubTitle}>{subTitle}</h6>
          )}
        </div>
        <div className={classes.resumeCardSlider}>
          <ResumeSlider
            videoSrc={videoSrc}
            videoType={videoType}
            images={images}
            selectedProposer={selectedProposer}
            arrowsPosition={sliderOptions.arrowsPosition}
            mode={sliderOptions.mode}
          />
        </div>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description }}
          className={classes.text}
        />
      </div>
      <div
        className={clsx(
          componentTheme === 'light' ? classes.lightTheme : classes.darkTheme,
          classes.rightPart,
        )}
      >
        {children}
      </div>
    </Paper>
  )
}

NxtResumeCard.defaultProps = {
  videoSrc: '',
  videoType: '',
  subTitle: '',
  showLeftCorner: false,
  selectedProposer: {
    fullName: '',
    photo: '',
  },
  componentTheme: 'dark',
  sliderOptions: {
    arrowsPosition: 'bottom',
    mode: 'dark',
  },
}

NxtResumeCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      src: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedProposer: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    photo: PropTypes.string,
  }),
  subTitle: PropTypes.string,
  videoSrc: PropTypes.string,
  videoType: PropTypes.string,
  showLeftCorner: PropTypes.bool,
  componentTheme: PropTypes.oneOf(['light', 'dark']),
  sliderOptions: PropTypes.shape({
    arrowsPosition: PropTypes.string,
    mode: PropTypes.string,
  }),
}

export default withStyles(styles)(NxtResumeCard)
