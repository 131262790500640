import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import * as PropTypes from 'prop-types'
import defaultLogo from '../../img/i360-logo-horizontal.png'
import i18n from '../../support/i18n'
import ButtonLink from "../button_link/View"
import Image from '../image/View'
import styles from './View.Style'

const Footer = (props) => {
  const {
    classes,
    newIdeas,
    logoUrl,
    showAdmin,
    totalInvestments,
    customFooterText,
    onLogoClick,
    onAdminClick,
    customIdeaTitles
  } = props
  return (
    <>
      <div className={classes.footer}>
        <div className={classes.footerContent}>
          <ButtonLink onLinkClick={onLogoClick}>
            <Image
              alt="app logo"
              className={classes.appLogo}
              src={logoUrl}
              defaultSrc={defaultLogo}
            />
          </ButtonLink>
          <div className={classes.summary}>
            <div className={classes.summaryIcon}>
              <b className="icon-idea" />
              <span>{newIdeas}</span>
            </div>
            <span className={classes.summaryText}>
              {i18n.t('IDEAS_PROPOSED', { title: customIdeaTitles.pluralTitle || "ideas" })}
            </span>
          </div>

          <div className="summary">
            <div className={classes.summaryIcon}>
              <b className="icon-ideas-implemented" />
              <span>{totalInvestments}</span>
            </div>
            <span className={classes.summaryText}>{i18n.t('IDEAS_INVESTED')}</span>
          </div>
          {showAdmin && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={onAdminClick}
            >
              <span className="icon-star" />
              {i18n.t('AVATAR_MENU.ADMIN')}
            </Button>
          )}
        </div>
      </div>
      <div className={classes.links}>
        <div className={classes.linksContent}>
          <a
            href="https://storage.googleapis.com/politicas-de-uso/terminos_de_uso_plataforma_i360_oct_2021.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.t('FOOTER.LEGAL')}
          </a>

        </div>
        {customFooterText && (
          <div className={classes.customizedMessageFooter}>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: customFooterText }} />
          </div>
        )}
      </div>
    </>
  )
}

Footer.defaultProps = {
  customFooterText: '',
  logoUrl: '',
}

Footer.propTypes = {
  classes: PropTypes.shape({
    appLogo: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    footer: PropTypes.string.isRequired,
    footerContent: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    summaryIcon: PropTypes.string.isRequired,
    summaryText: PropTypes.string.isRequired,
    links: PropTypes.string.isRequired,
    linksContent: PropTypes.string.isRequired,
    customizedMessageFooter: PropTypes.string.isRequired,
  }).isRequired,
  newIdeas: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  showAdmin: PropTypes.bool.isRequired,
  onLogoClick: PropTypes.func.isRequired,
  totalInvestments: PropTypes.string.isRequired,
  customFooterText: PropTypes.string,
  onAdminClick: PropTypes.func.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(Footer)
