import Query from 'query-string'
import { connect } from 'react-redux'
import Navigation from 'support/navigation'
import View from './View'

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps
  const query = Query.parse(location.search)
  return { instanceId: query.instanceId };
}

const mapDispatchToProps = () => ({
  onOkClick: (event) => {
    Navigation.navigateToCustomLogin(event.instanceId)
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
