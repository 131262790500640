const styles = () => ({
  root: {
    '& .pie-label-wrapper': {
      paddingRight: '10px',
    },
    '& .pie-label': {
      backgroundColor: "#eee",
      padding: '10px 10px 15px',
    },
    '& .title': {
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    '& .title > span': {
      padding: '3px 6px',
      borderRadius: '8px',
      backgroundColor: '#fff',
      width: '10px',
      height: '10px',
      color: '#666',
      marginLeft: '5px',
    },
    '& .pie-label .subtitle': {
      color: '#666',
      fontSize: '12px',
      fontWeight: 'bold',
    }
  },
})

export default styles
