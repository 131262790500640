import { connect } from 'react-redux'
import Navigation from 'support/navigation'
import { goToAdminThunk } from 'support/navigation/Thunks'
import View from './View'
import * as types from "../../actions/sync/auth/Constants";
import { logoutService } from "../../services/auth";
import i18n from "../../support/i18n";
import { showPopupErrorSnackbar } from "../../support/popup_dialogs";

const mapStateToProps = (state, ownProps) => {
  return {
    showAdmin: ownProps.showAdmin,
    isPrivateFlowActive: state.data.domain.domainConfiguration.isPrivateFlowActive,
    isPreviousValidationEnabled: state.data.domain.domainConfiguration.isPreviousValidationEnabled,
    useRanking: state.data.domain.customTitlesConf.isRanking,
    customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles
  }
}

const mapDispatchToProps = dispatch => {
  function onLogoutSuccess() {
    return {
      type: types.PASSWORD_LOGOUT_SUCCESS,
    }
  }

  function onLogoutFailure() {
    showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.LOGOUT_FAILURE'), dispatch)
  }

  return {
    navigateToAdmin: () => {
      dispatch(goToAdminThunk())
    },
    navigateToDashboard: () => {
      Navigation.navigateToDashboard()
    },
    navigateToPendingValidation: () => {
      Navigation.navigateToPendingValidation()
    },
    navigateToPrivateIdeas: () => {
      Navigation.navigateToPrivateIdeas()
    },
    navigateToMyProfile: () => {
      Navigation.navigateToUserProfile('me')
    },
    navigateToRanking: () => {
      Navigation.navigateToRanking()
    },
    navigateToSettings: () => {
      Navigation.navigateToSettings()
    },
    navigateToLogout: () => {
      dispatch(logoutService(onLogoutSuccess, onLogoutFailure))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
