import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import i18n from 'support/i18n'
import NxtCardListItems from 'components/nxt_card/nxt_card_list_items/View'
import Flip from 'components/animations/flip/View'
import FlipCard from 'components/animations/flip/components/flip_card/View'
import Description from 'components/description/View'
import Overlay from 'components/overlay/View'
import CircleGraph from 'components/circle_graph/View'
import Image from 'components/image/View'
import NxtCardCorner from 'components/nxt_card/nxt_card_corner/View'
import ButtonLink from 'components/button_link/View'
import imgDefault from 'img/i360-logo-192.png'
import photoDefault from 'img/default-photo.png'
import styles from './View.Style'

class NxtSmallCard extends Component {
  constructor(props) {
    super(props)
    this.onClickMainButton = this.onClickMainButton.bind(this)
    this.onClickSecondaryButton = this.onClickSecondaryButton.bind(this)
    this.onCardClick = this.onCardClick.bind(this)
    this.renderDescription = this.renderDescription.bind(this)
    this.renderSubmittedBy = this.renderSubmittedBy.bind(this)
    this.renderListItem = this.renderListItem.bind(this)
    this.renderTitle = this.renderTitle.bind(this)
  }

  onClickMainButton() {
    const { id, onClickMainButton } = this.props
    onClickMainButton(id)
  }

  onClickSecondaryButton() {
    const { id, onClickSecondaryButton } = this.props
    onClickSecondaryButton(id)
  }

  onCardClick() {
    const { id, onCardClick } = this.props
    onCardClick(id)
  }

  renderDescription() {
    const { description } = this.props
    if (description) {
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: description.substr(0, 60) }} />
    }
    return null
  }

  renderSubmittedBy() {
    const { submittedBy, ownerPhoto, classes, onSubmitterClick, isAnonymous } = this.props
    if (submittedBy) {
      return (
        <div className={classes.submittedBy}>
          <div className={classes.ownerPhoto}>
            <Image alt={submittedBy} src={ownerPhoto} defaultSrc={photoDefault} />
          </div>
          <small>
            {i18n.t('SUBMITTED_BY')}
            {!isAnonymous && (
              <ButtonLink
                classes={{ linkButton: classes.submitterLinkButton }}
                onLinkClick={onSubmitterClick}
              >
                {submittedBy}
              </ButtonLink>
            )}
            {isAnonymous && <span className={classes.submitterSpan}>{submittedBy}</span>}
          </small>
        </div>
      )
    }
    return null
  }

  renderListItem() {
    const { investors, pledged } = this.props
    if (investors || pledged) {
      return (
        <NxtCardListItems
          primaryValue={pledged}
          secondaryValue={investors}
          primaryText={i18n.t('PLEDGED')}
          secondaryText={i18n.t('INVESTORS')}
        />
      )
    }
    return null
  }

  renderTitle() {
    const { alt, defaultSrc, img, title } = this.props
    if (title) {
      return (
        <Overlay alt={alt} img={img} defaultSrc={defaultSrc}>
          <Description text={title} color="white" />
        </Overlay>
      )
    }
    return null
  }

  render() {
    const {
      alt,
      mainButtonText,
      children,
      classes,
      challenged,
      onClickSecondaryButton,
      defaultSrc,
      sponsored,
      theme,
      circleContent,
      circleValue,
      circleMaxValue,
      circleColor,
      showCircle,
      singleId,
      hideButttonsBlock,
      hideSubmitedBy,
      altTextBlock,
      img,
      onCardClick,
      isPrivate
    } = this.props

    return (
      <div className={classes.container}>
        <Flip>
          <FlipCard side="front">
            <NxtCardCorner challenged={challenged} sponsored={sponsored} />
            {showCircle && (
              <div className={classes.graph}>
                <CircleGraph
                  className={{ base: classes.circleBase }}
                  value={circleValue}
                  maxValue={circleMaxValue}
                  themeColor={theme}
                  pathColor={circleColor}
                >
                  {circleContent}
                </CircleGraph>
              </div>
            )}
            <Image alt={alt} src={img} defaultSrc={defaultSrc} className="image" />
            {this.renderTitle()}
          </FlipCard>
          <FlipCard side="back" theme={theme} className={classes.back}>
            {onCardClick && (
              <Button disableRipple onClick={this.onCardClick} className={classes.cardFullButton} />
            )}
            <div className={classes.descriptionBlock}>
              {!hideSubmitedBy && this.renderSubmittedBy()}
              {singleId && (
                <div className={classes.singleId}>
                  <strong>Id: {singleId}</strong>
                </div>
              )}
              {this.renderDescription()}
            </div>
            <ul
              className={clsx(
                classes.list,
                theme === 'light' && classes.listLight,
                theme === 'dark' && classes.listDark,
              )}
            >
              {children}
            </ul>
            {!hideButttonsBlock && (
              <div className={classes.buttonBlock}>
                <Button
                  className={classes.button}
                  color="primary"
                  fullWidth
                  onClick={this.onClickMainButton}
                  variant="contained"
                >
                  {mainButtonText}
                </Button>
                {onClickSecondaryButton && (
                  <Button
                    className={classes.button}
                    color="primary"
                    fullWidth
                    onClick={this.onClickSecondaryButton}
                    variant="contained"
                  >
                    {i18n.t('CHALLENGES.VIEW')}
                  </Button>
                )}
              </div>
            )}
            {!isPrivate && altTextBlock && (
              <div className={classes.altTextBlock}>
                {altTextBlock}
              </div>
            )}
          </FlipCard>
        </Flip>
      </div>
    )
  }
}

NxtSmallCard.defaultProps = {
  alt: 'default image',
  mainButtonText: i18n.t('GO_TO_IDEA'),
  description: '',
  img: '',
  pledged: '',
  title: '',
  submittedBy: '',
  ownerPhoto: '',
  investors: '',
  id: '',
  sponsored: false,
  challenged: false,
  theme: 'light',
  onClickSecondaryButton: null,
  onSubmitterClick: () => { },
  onCardClick: undefined,
  circleValue: '0',
  circleMaxValue: '100',
  circleColor: '',
  defaultSrc: imgDefault,
  showCircle: false,
  isAnonymous: false,
  hideButttonsBlock: false,
  hideSubmitedBy: false,
  altTextBlock: '',
  isPrivate: false,
  onClickMainButton: () => { }
}

NxtSmallCard.propTypes = {
  alt: PropTypes.string,
  mainButtonText: PropTypes.node,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
  circleValue: PropTypes.string,
  circleMaxValue: PropTypes.string,
  circleColor: PropTypes.string,
  defaultSrc: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  pledged: PropTypes.string,
  title: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  singleId: PropTypes.string,
  submittedBy: PropTypes.string,
  ownerPhoto: PropTypes.string,
  investors: PropTypes.string,
  id: PropTypes.string,
  onClickMainButton: PropTypes.func,
  onSubmitterClick: PropTypes.func,
  onClickSecondaryButton: PropTypes.func,
  onCardClick: PropTypes.func,
  challenged: PropTypes.bool,
  showCircle: PropTypes.bool,
  sponsored: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
  isAnonymous: PropTypes.bool,
  hideButttonsBlock: PropTypes.bool,
  hideSubmitedBy: PropTypes.bool,
  altTextBlock: PropTypes.string,
  isPrivate: PropTypes.bool
}

export default withStyles(styles)(NxtSmallCard)
