const styles = theme => ({
  root: {
    '& > span': {
      fontSize: '22px',
    },
    display: 'flex',
  },
  titleLogistic: {
    fontSize: '1.125rem',
    fontWeight: 500,
  },
  valuesContainer: {
    width: '100%',
    margin: theme.spacing(0, 2),
  },
  lineWrapper: {
    display: 'flex',
    marginTop: theme.spacing(2),
    '& div:nth-child(2)': {
      margin: theme.spacing(0, 2),
    },
  },
  valuesLogistic: {
    width: '42%',
    padding: theme.spacing(2.5, 1.25),
    backgroundColor: 'rgb(247, 247, 247)',
    display: 'flex',
  },
  economicBenefits: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px dotted black',
    flex: 1,
    '& span:first-child': {
      color: 'rgb(102, 102, 102)',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '16px',
    },
    '& span:last-child': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: '40px',
    },
  },
  otherBenefits: {
    display: 'flex',
    flexDirection: 'column',
    '& span:first-child': {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: theme.spacing(1.25),
    },
    '& span:last-child': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
})

export default styles
