import React, { Fragment, useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Image from 'components/image/View'
import i18n from 'support/i18n'
import logo from 'img/i360-logo-horizontal.png'
import styles from './View.Style'

const LegalAcceptance = ({
  classes,
  gdpr,
  customBgImg,
  customColor,
  customImg,
  repeatBackgroundImg,
  useCustomBgImg,
  useCustomColor,
  useCustomImg,
  getDomainLoginInfo,
  postAcceptLegal,
  postDeclineLegal,
  domain,
  legalAcceptTitle,
  legalAcceptHTML,
}) => {
  const [bgStyle, setBgStyle] = useState('')

  const onAccept = () => {
    postAcceptLegal()
  }

  const onCancel = () => {
    postDeclineLegal()
  }

  useEffect(() => {
    getDomainLoginInfo(domain)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain])

  useEffect(() => {
    let background = '';

    if (useCustomBgImg && customBgImg) {
      background += `url(${customBgImg})`
      if (repeatBackgroundImg) {
        background += ' repeat'
      } else {
        background += ' center center / cover no-repeat'
      }
    }
    if (useCustomColor && customColor) {
      background += ` ${customColor}`
    }
    setBgStyle(background)
  }, [
    useCustomBgImg,
    customBgImg,
    repeatBackgroundImg,
    useCustomColor,
    customColor
  ])

  return (
    <Fragment>
      <div className={classes.container}>
        {bgStyle ? (
          <div
            className={classes.background}
            style={{ background: bgStyle }}
          />
        ) : (
          <div className={classes.background} />
        )}
        <div className={classes.content}>
          <Image
            defaultSrc=""
            src={useCustomImg && customImg ? customImg : logo}
            alt="custom company logo"
          />
          <div className={classes.gdpr}>
            {legalAcceptTitle && (
              <h2>{legalAcceptTitle}</h2>
            )}
            {legalAcceptHTML && (
              <>
                <div
                  className={classes.legalAccept}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: legalAcceptHTML }}
                />
                <div className={classes.divider} />
              </>
            )}
            {gdpr && (
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: gdpr }}
              />
            )}
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                onClick={onAccept}
              >
                {i18n.t('ACCEPT')}
              </Button>
              <Button
                variant="contained"
                onClick={onCancel}
              >
                {i18n.t('CANCEL')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

LegalAcceptance.defaultProps = {
  customBgImg: '',
  customColor: '',
  customImg: '',
  repeatBackgroundImg: false,
  useCustomBgImg: false,
  useCustomColor: false,
  useCustomImg: false,
}

LegalAcceptance.propTypes = {
  gdpr: PropTypes.string.isRequired,
  customBgImg: PropTypes.string,
  customColor: PropTypes.string,
  customImg: PropTypes.string,
  repeatBackgroundImg: PropTypes.bool,
  useCustomBgImg: PropTypes.bool,
  useCustomColor: PropTypes.bool,
  useCustomImg: PropTypes.bool,
  domain: PropTypes.string.isRequired,
  getDomainLoginInfo: PropTypes.func.isRequired,
  postAcceptLegal: PropTypes.func.isRequired,
  postDeclineLegal: PropTypes.func.isRequired,
}

export default withStyles(styles)(LegalAcceptance)
