import React from 'react'
import { Card, CardContent, Grid, TextField, Typography } from '@material-ui/core'
import i18n from 'support/i18n'
import { ONLY_TEXT, TextFieldStyled } from './utils'

export const PersonalData = ({ onChangeValue, info, isDisabled, anyError }) => {
  const { name, lastName, company, workPosition } = info

  const onChange = (event, field) => {
    const { value } = event.target

    if (['name', 'lastName', 'workPosition'].includes(field)) {
      return value.match(ONLY_TEXT) !== null
        ? onChangeValue('personalData', { ...info, [field]: event.target.value })
        : null
    }

    return onChangeValue('personalData', { ...info, [field]: value })
  }

  return (
    <Grid item xs={12}>
      <Card
        variant="elevation"
        elevation={3}
        style={{
          borderRadius: 8,
        }}
      >
        <CardContent spacing={2}>
          <Typography variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
            {i18n.t('FORMS.STANDARD_FORM.FORM.PERSONAL_DATA')}
          </Typography>
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.NAME')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'name')}
            value={name}
            disabled={isDisabled}
            error={anyError && 'name' in anyError}
            helperText={anyError?.name}
          />
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.LAST_NAME')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'lastName')}
            value={lastName}
            disabled={isDisabled}
            error={anyError && 'lastName' in anyError}
            helperText={anyError?.lastName}
          />
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.COMPANY')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'company')}
            value={company}
            disabled={isDisabled}
            error={anyError && 'company' in anyError}
            helperText={anyError?.company}
          />
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.WORK_POSITION')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'workPosition')}
            value={workPosition}
            disabled={isDisabled}
            error={anyError && 'workPosition' in anyError}
            helperText={anyError?.workPosition}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}
