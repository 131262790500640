import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { withStyles } from '@material-ui/core'
import ButtonLink from 'components/button_link/View'
import styles from './View.Style'

const CommentCard = props => {
  const {
    classes,
    commentText,
    ideaName,
    navigateToIdea,
    ideaId,
    commentLikes,
    commentReplies,
  } = props
  return (
    <Fragment>
      <div className={classes.title}>
        <span className="icon-comment" />
        <span>{i18n.t('TIMELINE.NEW_COMMENT')}</span>
        <ButtonLink
          classes={{ linkButton: classes.linkButton }}
          onLinkClick={() => navigateToIdea(ideaId)}
        >
          {ideaName}
        </ButtonLink>
      </div>
      <div className={classes.body}>
        {commentText ? (
          <span
            className={classes.quote}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: commentText }}
          />
        ) : (
          <span className={classes.quote}>{i18n.t('COMMENT.DELETED')}</span>
        )}
        <span className={classes.status}>{`${commentLikes} ${i18n.t(
          'TIMELINE.LIKES',
        )} · ${commentReplies} ${i18n.t('TIMELINE.RESPONSES')}`}</span>
      </div>
    </Fragment>
  )
}

CommentCard.propTypes = {
  commentText: PropTypes.string.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
}

export default withStyles(styles)(CommentCard)
