const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    '& > div': {
      width: '50%',
    },
  },
  userCardIdeaRoot: {},
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  promoterUserLink: {
    width: '100%',
    fontSize: '0.875rem',
    textDecoration: 'unset',
    color: theme.palette.primary.main,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
  },
  submitterUserLink: {
    width: '100%',
    fontSize: '0.875rem',
    textDecoration: 'unset',
    color: theme.palette.primary.main,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'end',
  },
})

export default styles
