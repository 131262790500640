const styles = theme => ({
  logisticDataWrapper: {
    paddingBottom: theme.spacing(3),
  },
  root: {
    padding: theme.spacing(1, 2),
    '& > div': {
      marginBottom: theme.spacing(6.25),
    },
  },
  logisticHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '26px',
    fontWeight: 500,
    '& > span': {
      fontSize: '32px',
      marginRight: theme.spacing(1),
    },
  },
})

export default styles
