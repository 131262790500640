import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Paper, Tabs, Tab } from '@material-ui/core'
import i18n from 'support/i18n'
import clsx from 'clsx'
import { addDays } from 'date-fns'
import { USERS_LIMIT_CONSTANT } from 'common_constants/Dashboard'
import { dashboardTabs, typesViewAs } from './Constants'
import styles from './View.Style'
import WeekActivity from './components/activity_week/View'
import Activity from './components/activity/View'
import ActivityIdeas from './components/activity_ideas/View'
import ActivityPeople from './components/activity_people/View'
import IntervalDatesPicker from './components/interval_dates_picker/View'
import ReportSelector from './components/report_selector/View'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: props.activeTab,
    }
    this.userParticipationOffset = 0
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleDatesChange = this.handleDatesChange.bind(this)
    this.handleViewAsClick = this.handleViewAsClick.bind(this)
    this.getDates = this.getDates.bind(this)
    this.initTab = this.initTab.bind(this)
    this.handleFilterByText = this.handleFilterByText.bind(this)
    this.handleFilterByType = this.handleFilterByType.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.handlePeoplePageChange = this.handlePeoplePageChange.bind(this)
    this.handleIdeaPageChange = this.handleIdeaPageChange.bind(this)
    this.handlePrivateIdeaPageChange = this.handlePrivateIdeaPageChange.bind(this)
    this.handleWeekActivityDateChange = this.handleWeekActivityDateChange.bind(this)
    this.handlePrevWeekActivity = this.handlePrevWeekActivity.bind(this)
    this.handleNextWeekActivity = this.handleNextWeekActivity.bind(this)
  }

  componentDidMount() {
    const {
      activeTab,
      getSystemAccesses,
      getUserParticipation,
      domainCreationDate,
      getActiveOrganizations,
      getGroups,
      getActiveGoals,
      getActiveChallenges,
      getActiveTypes,
      getActiveAttributes,
    } = this.props

    getUserParticipation(this.userParticipationOffset)
    getActiveOrganizations()
    getGroups()
    getActiveGoals()
    getActiveChallenges()
    getActiveTypes()
    getActiveAttributes()
    if (domainCreationDate) {
      getSystemAccesses(domainCreationDate)
    }
    this.initTab(activeTab)
  }

  componentDidUpdate(prevProps) {
    const {
      getActivityComparisonTable,
      getIdeasComparisonTable,
      getIdeasSeries,
      getPrivateIdeasSeries,
      getPeopleComparisonTable,
      getPrivateIdeasComparisonTable,
      getUserParticipation,
      privateIdeasFromDate,
      privateIdeasToDate,
      activityFromDate,
      activityToDate,
      ideasFromDate,
      ideasToDate,
      peopleFromDate,
      peopleToDate,
      usersParticipation,
      getSystemAccesses,
      domainCreationDate,
      weekActivityFromDate,
      weekActivityToDate,
      getWeekActivity,
      getMostActive,
    } = this.props

    if (domainCreationDate !== prevProps.domainCreationDate && domainCreationDate) {
      getSystemAccesses(domainCreationDate)
    }
    if (
      prevProps.activityFromDate.getTime() !== activityFromDate.getTime() ||
      prevProps.activityToDate.getTime() !== activityToDate.getTime()
    ) {
      getActivityComparisonTable(activityFromDate, activityToDate)
    }
    if (
      prevProps.ideasFromDate.getTime() !== ideasFromDate.getTime() ||
      prevProps.ideasToDate.getTime() !== ideasToDate.getTime()
    ) {
      getIdeasComparisonTable(ideasFromDate, ideasToDate)
      getIdeasSeries(ideasFromDate)
      getPrivateIdeasSeries(ideasFromDate)
    }
    if (
      prevProps.peopleFromDate.getTime() !== peopleFromDate.getTime() ||
      prevProps.peopleToDate.getTime() !== peopleToDate.getTime()
    ) {
      getPeopleComparisonTable(peopleFromDate, peopleToDate)
    }
    if (
      prevProps.privateIdeasFromDate.getTime() !== privateIdeasFromDate.getTime() ||
      prevProps.privateIdeasToDate.getTime() !== privateIdeasToDate.getTime()
    ) {
      getPrivateIdeasComparisonTable(privateIdeasFromDate, privateIdeasToDate)
    }
    if (
      usersParticipation.hasMore &&
      prevProps.usersParticipation.series[0] !== usersParticipation.series[0]
    ) {
      this.userParticipationOffset += USERS_LIMIT_CONSTANT
      getUserParticipation(this.userParticipationOffset)
    }
    if (
      prevProps.weekActivityFromDate.getTime() !== weekActivityFromDate.getTime() ||
      prevProps.weekActivityToDate.getTime() !== weekActivityToDate.getTime()
    ) {
      getWeekActivity(weekActivityToDate)
      getMostActive(weekActivityFromDate)
      getActivityComparisonTable(weekActivityFromDate, weekActivityToDate)
    }
  }

  getDates(tab) {
    switch (tab) {
      case dashboardTabs.IDEAS: {
        const { ideasFromDate, ideasToDate } = this.props
        return {
          fromDate: ideasFromDate,
          toDate: ideasToDate,
        }
      }
      case dashboardTabs.PEOPLE: {
        const { peopleFromDate, peopleToDate } = this.props
        return {
          fromDate: peopleFromDate,
          toDate: peopleToDate,
        }
      }
      case dashboardTabs.PRIVATE_IDEAS: {
        const { privateIdeasFromDate, privateIdeasToDate } = this.props
        return {
          fromDate: privateIdeasFromDate,
          toDate: privateIdeasToDate,
        }
      }
      default: {
        const { activityFromDate, activityToDate } = this.props
        return {
          fromDate: activityFromDate,
          toDate: activityToDate,
        }
      }
    }
  }

  initTab(tab) {
    const {
      privateIdeas,
      getActivityComparisonTable,
      getIdeasComparisonTable,
      getPeopleComparisonTable,
      getPrivateIdeasComparisonTable,
      getUsers,
      peopleUserFilter,
      getPrivateIdeas,
      getIdeas,
      getIdeasSeries,
      weekActivityFromDate,
      weekActivityToDate,
      getWeekActivity,
      getMostActive,
    } = this.props

    const { fromDate, toDate } = this.getDates(tab)

    switch (tab) {
      case dashboardTabs.IDEAS: {
        const { rowsPerPage, page } = privateIdeas.pagination

        getIdeasComparisonTable(fromDate, toDate)
        getIdeas({ limit: rowsPerPage, offset: page * rowsPerPage })
        getIdeasSeries(fromDate)

        break
      }
      case dashboardTabs.PEOPLE: {
        getPeopleComparisonTable(fromDate, toDate)
        getUsers(peopleUserFilter)

        break
      }
      case dashboardTabs.PRIVATE_IDEAS: {
        const { rowsPerPage, page } = privateIdeas.pagination

        getPrivateIdeasComparisonTable(fromDate, toDate)
        getPrivateIdeas({ limit: rowsPerPage, offset: page * rowsPerPage })

        break
      }
      case dashboardTabs.ACTIVITY_WEEK: {
        getWeekActivity(weekActivityFromDate)
        getMostActive(weekActivityToDate)
        getActivityComparisonTable(weekActivityFromDate, weekActivityToDate)

        break
      }
      default: {
        getActivityComparisonTable(fromDate, toDate)
      }
    }
  }

  handleChange(event, newValue) {
    const { onChangeTab } = this.props

    onChangeTab(newValue)
    this.setState({ activeTab: newValue })
    this.initTab(newValue)
  }

  handleDateChange(date, isFrom) {
    const datesObject = {}

    if (isFrom) {
      datesObject.fromDate = date
    } else {
      datesObject.toDate = date
    }

    this.handleDatesChange(datesObject)
  }

  handleWeekActivityDateChange({ fromDate, toDate }) {
    const datesObject = { fromDate, toDate }
    this.handleDatesChange(datesObject)
  }

  handlePrevWeekActivity() {
    const { weekActivityFromDate, weekActivity } = this.props
    const fromDate = addDays(weekActivityFromDate, -7)

    if (fromDate.getTime() >= weekActivity.data.firstDate.timestamp) {
      this.handleDatesChange({
        fromDate,
        toDate: addDays(new Date(weekActivityFromDate.getTime()), -1)
      })
    }
  }

  handleNextWeekActivity() {
    const { weekActivityToDate } = this.props
    const toDate = addDays(weekActivityToDate, 7)

    if (toDate.getTime() <= addDays(new Date(), -1)) {
      this.handleDatesChange({
        fromDate: addDays(new Date(weekActivityToDate.getTime()), 1),
        toDate,
      })
    }
  }

  handleDatesChange(datesObject) {
    const { onChangeDate } = this.props
    const { activeTab } = this.state

    onChangeDate(activeTab, datesObject)
  }

  handleViewAsClick(typeOfView) {
    const {
      getUsers,
      getGridUsers,
      peopleUserFilter,
      gridPeopleUserFilter,
      people,
    } = this.props

    if (typeOfView === typesViewAs.LIST) {
      if (!people.usersTable.users.length) {
        getUsers(peopleUserFilter)
      }
    } else if (typeOfView === typesViewAs.GRID) {
      if (!people.usersGrid.users.length) {
        getGridUsers(gridPeopleUserFilter)
      }
    }
  }

  handleFilter(filter, viewAs, replaceUsers) {
    const {
      getUsers,
      getGridUsers,
      setUsersTableFilter,
      setUsersGridFilter
    } = this.props

    if (viewAs === typesViewAs.GRID) {
      setUsersGridFilter(filter)
      getGridUsers(filter, replaceUsers)
    } else {
      setUsersTableFilter(filter)
      getUsers(filter)
    }
  }

  handleFilterByText(value) {
    const { userTableFilter } = this.props
    const { filterType, rowsPerPage } = userTableFilter
    const newFilter = {
      name: value,
      filterType,
      page: 0,
      rowsPerPage,
    }

    this.handleFilter(newFilter)
  }

  handleFilterByType(value, viewAs) {
    let replaceUsers = false

    const { userTableFilter, userGridFilter } = this.props
    const { name: tableName, rowsPerPage: tableRowsPerPage } = userTableFilter
    const { name: gridName, rowsPerPage: gridRowsPerPage } = userGridFilter
    const newFilter = {
      name: tableName,
      filterType: value,
      page: 0,
      rowsPerPage: tableRowsPerPage,
    }

    if (viewAs === typesViewAs.GRID) {
      newFilter.name = gridName
      newFilter.rowsPerPage = gridRowsPerPage
      replaceUsers = true
    }

    this.handleFilter(newFilter, viewAs, replaceUsers)
  }

  handlePeoplePageChange(value, newRowsPerPage, viewAs) {
    const { userTableFilter, userGridFilter } = this.props
    const { filterType: tableFilterType, name: tableName } = userTableFilter
    const { filterType: gridFilterType, name: gridName } = userGridFilter
    const newFilter = {
      name: tableName,
      filterType: tableFilterType,
      page: value,
      rowsPerPage: newRowsPerPage,
    }

    if (viewAs === typesViewAs.GRID) {
      newFilter.name = gridName
      newFilter.filterType = gridFilterType
    }

    this.handleFilter(newFilter, viewAs)
  }

  handlePrivateIdeaPageChange(page, rowsPerPage, filters) {
    const { getPrivateIdeas, setPrivateIdeaTableFilter } = this.props

    const newFilter = {
      page,
      rowsPerPage,
    }

    setPrivateIdeaTableFilter(newFilter)
    getPrivateIdeas({ ...filters, limit: rowsPerPage, offset: page * rowsPerPage })
  }

  handleIdeaPageChange(page, rowsPerPage, filters) {
    const { getIdeas, setIdeaTableFilter } = this.props

    const newFilter = {
      page,
      rowsPerPage,
    }

    setIdeaTableFilter(newFilter)
    getIdeas({ ...filters, limit: rowsPerPage, offset: page * rowsPerPage })
  }

  render() {
    const {
      classes,
      isLoading,
      isActivitySeriesLoading,
      isIdeasSeriesLoading,
      isPrivateIdeasLoading,
      activity,
      ideas,
      people,
      privateIdeas,
      showPrivateTab,
      onSendReport,
      navigateToUserProfile,
      domainCreationDate,
      usersParticipation,
      isUsersParticipationSeriesLoading,
      onSendPeopleReport,
      ideasTableBottom,
      ideasTableBottomTotal,
      ideasTableBottomIsLoading,
      navigateToIdea,
      organizations,
      organizationsName,
      isOrganizationsActive,
      groups,
      strategics,
      challenges,
      types,
      typesName,
      isTypesActive,
      attributes,
      attributesName,
      isAttributesActive,
      ideaTablePagination,
      weekActivity,
      isWeekActivitySeriesLoading,
      weekActivityFromDate,
      weekActivityToDate,
      activityFromDate,
      activityToDate,
      ideasFromDate,
      ideasToDate,
      privateIdeasFromDate,
      privateIdeasToDate,
      navigateToProfile,
      ideaStatusStepper,
      customIdeaTitles
    } = this.props

    const { activeTab } = this.state
    const { fromDate, toDate } = this.getDates(activeTab)

    return (
      <div className={classes.root}>
        <h1 className={classes.titleContainer}>
          <span className="icon-portfolio" />
          {i18n.t('DASHBOARD.TITLE')}
        </h1>
        <div className={classes.reportContainer}>
          <ReportSelector onMainButtonAction={onSendReport} customIdeaTitles={customIdeaTitles} />
        </div>
        <Tabs
          value={activeTab}
          onChange={this.handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab
            label={i18n.t('DASHBOARD.ACTIVITY_WEEK')}
            value={dashboardTabs.ACTIVITY_WEEK}
            disabled={isLoading}
          />
          <Tab
            label={i18n.t('DASHBOARD.ACTIVITY')}
            value={dashboardTabs.ACTIVITY}
            disabled={isLoading}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label={i18n.t('DASHBOARD.IDEAS', { title: customIdeaTitles.pluralTitle || "ideas" })}
            value={dashboardTabs.IDEAS}
            disabled={isLoading}
          />
          <Tab
            label={i18n.t('DASHBOARD.PEOPLE')}
            value={dashboardTabs.PEOPLE}
            disabled={isLoading}
          />
          {showPrivateTab && (
            <Tab
              style={{ textTransform: "capitalize" }}
              label={i18n.t('DASHBOARD.PRIVATE_IDEAS', { title: customIdeaTitles.pluralTitle || "ideas" })}
              value={dashboardTabs.PRIVATE_IDEAS}
              disabled={isLoading}
            />
          )}
        </Tabs>
        <Paper className={classes.paper}>
          <div className={classes.dashboardContainer}>
            <div className={clsx(activeTab === dashboardTabs.ACTIVITY_WEEK && classes.hide)}>
              <IntervalDatesPicker
                onSelectFromDate={date => this.handleDateChange(date, true)}
                onSelectToDate={date => this.handleDateChange(date)}
                fromDate={fromDate}
                toDate={toDate}
                disabled={isLoading}
                minDate={domainCreationDate}
              />
            </div>
            <div className={clsx(activeTab !== dashboardTabs.ACTIVITY_WEEK && classes.hide)}>
              <WeekActivity
                onDateChange={this.handleWeekActivityDateChange}
                onPrevWeek={this.handlePrevWeekActivity}
                onNextWeek={this.handleNextWeekActivity}
                series={weekActivity.data.series}
                tableData={activity.tableData}
                categories={weekActivity.data.categories}
                isTableLoading={isLoading}
                isChartLoading={isWeekActivitySeriesLoading}
                mostActiveUsers={weekActivity.mostActive.results}
                fromDate={weekActivityFromDate}
                toDate={weekActivityToDate}
                maxDate={addDays(new Date(), -7)}
                minDate={weekActivity.data.firstDate
                  ? new Date(weekActivity.data.firstDate.timestamp)
                  : undefined
                }
                customIdeaTitles={customIdeaTitles}
              />
            </div>
            <div className={clsx(activeTab !== dashboardTabs.ACTIVITY && classes.hide)}>
              <Activity
                tableData={activity.tableData}
                toDate={activityToDate}
                fromDate={activityFromDate}
                onChangeDates={this.handleDatesChange}
                isChartLoading={isActivitySeriesLoading}
                isTableLoading={isLoading}
                series={activity.chart.series}
                devicesInfo={activity.devicesInfo}
                customIdeaTitles={customIdeaTitles}
              />
            </div>
            <div className={clsx(activeTab !== dashboardTabs.IDEAS && classes.hide)}>
              <ActivityIdeas
                tableHeaders={[
                  {
                    borderColor: ideaStatusStepper.pendingSupportColor,
                    icon: 'earn-money',
                    text: ideaStatusStepper.pendingSupportLabel,
                  },
                  {
                    borderColor: ideaStatusStepper.supportedColor,
                    icon: 'success',
                    text: ideaStatusStepper.supportedLabel,
                  },
                  {
                    borderColor: ideaStatusStepper.roundClosedColor,
                    icon: 'success',
                    text: ideaStatusStepper.roundClosedLabel,
                  },
                  {
                    borderColor: ideaStatusStepper.approvedColor,
                    icon: 'approved',
                    text: ideaStatusStepper.approvedLabel,
                  },
                  {
                    borderColor: ideaStatusStepper.pilotActiveColor,
                    icon: 'prototype',
                    text: ideaStatusStepper.pilotActiveLabel,
                  },
                  {
                    borderColor: ideaStatusStepper.implementedColor,
                    icon: 'ranking',
                    text: ideaStatusStepper.implementedLabel,
                  },
                  {
                    borderColor: ideaStatusStepper.discardedColor,
                    icon: 'close',
                    text: ideaStatusStepper.discardedLabel,
                  }
                ]}
                tableData={ideas.tableData}
                tableDataBottom={ideasTableBottom}
                tableDataBottomTotal={ideasTableBottomTotal}
                tableDataBottomIsLoading={ideasTableBottomIsLoading}
                toDate={ideasFromDate}
                fromDate={ideasToDate}
                isChartLoading={isIdeasSeriesLoading}
                isTableLoading={isLoading}
                series={ideas.chart.series}
                navigateToIdea={navigateToIdea}
                organizations={organizations}
                organizationsName={organizationsName}
                isOrganizationsActive={isOrganizationsActive}
                groups={groups}
                strategics={strategics}
                challenges={challenges}
                types={types}
                typesName={typesName}
                isTypesActive={isTypesActive}
                attributes={attributes}
                attributesName={attributesName}
                isAttributesActive={isAttributesActive}
                statusFilterOptions={[
                  { value: 'PENDING_VALIDATION', text: ideaStatusStepper.pendingValidation },
                  { value: 'PENDING_SUPPORT', text: ideaStatusStepper.pendingSupportLabel },
                  { value: 'SUPPORTED', text: ideaStatusStepper.supportedLabel },
                  { value: 'ROUND_CLOSED', text: ideaStatusStepper.roundClosedLabel },
                  { value: 'APPROVED_BY_IT', text: ideaStatusStepper.approvedLabel },
                  { value: 'PILOT_ACTIVE', text: ideaStatusStepper.pilotActiveLabel },
                  { value: 'IMPLEMENTED', text: ideaStatusStepper.implementedLabel },
                  { value: 'DISCARDED', text: ideaStatusStepper.discardedLabel },
                ]}
                onPageChange={this.handleIdeaPageChange}
                rowsPerPage={ideaTablePagination.rowsPerPage}
                page={ideaTablePagination.page}
                ideaStatusColors={[
                  '#7cb6ec',
                  ideaStatusStepper.pendingSupportColor,
                  ideaStatusStepper.supportedColor,
                  ideaStatusStepper.roundClosedColor,
                  ideaStatusStepper.approvedColor,
                  ideaStatusStepper.pilotActiveColor,
                  ideaStatusStepper.implementedColor,
                  ideaStatusStepper.discardedColor,
                ]}
                customIdeaTitles={customIdeaTitles}
              />
            </div>
            <div className={clsx(activeTab !== dashboardTabs.PEOPLE && classes.hide)}>
              <ActivityPeople
                tableData={people.tableData}
                isTableLoading={isLoading}
                tableUsersData={people.usersTable.users}
                gridUsersData={people.usersGrid.users}
                totalSearchItems={people.usersTable.totalSearchItems}
                gridTotalSearchItems={people.usersGrid.totalSearchItems}
                isTableUsersDataLoading={people.usersTable.isLoading}
                isGridUsersDataLoading={people.usersGrid.isLoading}
                series={usersParticipation.series}
                totalUsers={usersParticipation.totalUsers}
                participationAvg={usersParticipation.participationAvg}
                successAvg={usersParticipation.successAvg}
                onViewAsClick={this.handleViewAsClick}
                navigateToUserProfile={navigateToUserProfile}
                onFilterByText={this.handleFilterByText}
                onFilterByType={this.handleFilterByType}
                isUsersParticipationSeriesLoading={isUsersParticipationSeriesLoading}
                onSendReportClick={onSendPeopleReport}
                onPageChange={this.handlePeoplePageChange}
                rowsPerPage={people.usersTable.filter.rowsPerPage}
                gridRowsPerPage={people.usersGrid.filter.rowsPerPage}
                page={people.usersTable.filter.page}
                gridPage={people.usersGrid.filter.page}
                onGridCardClick={navigateToProfile}
                customIdeaTitles={customIdeaTitles}
              />
            </div>
            {showPrivateTab && activeTab === dashboardTabs.PRIVATE_IDEAS && (
              <div className={clsx(activeTab !== dashboardTabs.PRIVATE_IDEAS && classes.hide)}>
                <ActivityIdeas
                  tableHeaders={[
                    {
                      borderColor: ideaStatusStepper.roundClosedColor,
                      icon: 'success',
                      text: ideaStatusStepper.roundClosedLabel,
                    },
                    {
                      borderColor: ideaStatusStepper.approvedColor,
                      icon: 'approved',
                      text: ideaStatusStepper.approvedLabel,
                    },
                    {
                      borderColor: ideaStatusStepper.pilotActiveColor,
                      icon: 'prototype',
                      text: ideaStatusStepper.pilotActiveLabel,
                    },
                    {
                      borderColor: ideaStatusStepper.implementedColor,
                      icon: 'ranking',
                      text: ideaStatusStepper.implementedLabel,
                    },
                    {
                      borderColor: ideaStatusStepper.discardedColor,
                      icon: 'close',
                      text: ideaStatusStepper.discardedLabel,
                    }
                  ]}
                  tableData={privateIdeas.tableData}
                  tableDataBottom={privateIdeas.tableDataBottom.data}
                  tableDataBottomTotal={privateIdeas.tableDataBottom.total}
                  tableDataBottomIsLoading={privateIdeas.tableDataBottom.ideasLoading}
                  toDate={privateIdeasFromDate}
                  fromDate={privateIdeasToDate}
                  onChangeDates={this.handleDatesChange}
                  isChartLoading={isPrivateIdeasLoading}
                  isTableLoading={isLoading}
                  series={privateIdeas.chart.series}
                  navigateToIdea={navigateToIdea}
                  organizations={organizations}
                  groups={groups}
                  strategics={strategics}
                  statusFilterOptions={[
                    { value: 'ROUND_CLOSED', text: ideaStatusStepper.roundClosedLabel },
                    { value: 'APPROVED_BY_IT', text: ideaStatusStepper.approvedLabel },
                    { value: 'PILOT_ACTIVE', text: ideaStatusStepper.pilotActiveLabel },
                    { value: 'IMPLEMENTED', text: ideaStatusStepper.implementedLabel },
                    { value: 'DISCARDED', text: ideaStatusStepper.discardedLabel },
                  ]}
                  onPageChange={this.handlePrivateIdeaPageChange}
                  rowsPerPage={privateIdeas.pagination.rowsPerPage}
                  page={privateIdeas.pagination.page}
                  isPrivate
                  ideaStatusColors={[
                    '#7cb6ec',
                    ideaStatusStepper.roundClosedColor,
                    ideaStatusStepper.approvedColor,
                    ideaStatusStepper.pilotActiveColor,
                    ideaStatusStepper.implementedColor,
                    ideaStatusStepper.discardedColor,
                  ]}
                  customIdeaTitles={customIdeaTitles}
                />
              </div>
            )}
          </div>
        </Paper>
      </div>
    )
  }
}

Dashboard.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  showPrivateTab: PropTypes.bool.isRequired,
  activityFromDate: PropTypes.instanceOf(Date).isRequired,
  activityToDate: PropTypes.instanceOf(Date).isRequired,
  ideasFromDate: PropTypes.instanceOf(Date).isRequired,
  ideasToDate: PropTypes.instanceOf(Date).isRequired,
  peopleFromDate: PropTypes.instanceOf(Date).isRequired,
  peopleToDate: PropTypes.instanceOf(Date).isRequired,
  privateIdeasFromDate: PropTypes.instanceOf(Date).isRequired,
  privateIdeasToDate: PropTypes.instanceOf(Date).isRequired,
  getActivityComparisonTable: PropTypes.func.isRequired,
  getSystemAccesses: PropTypes.func.isRequired,
  getIdeasComparisonTable: PropTypes.func.isRequired,
  getPeopleComparisonTable: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getUserParticipation: PropTypes.func.isRequired,
  onSendReport: PropTypes.func.isRequired,
  getIdeasSeries: PropTypes.func.isRequired,
  getPrivateIdeasSeries: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isActivitySeriesLoading: PropTypes.bool.isRequired,
  isIdeasSeriesLoading: PropTypes.bool.isRequired,
  isUsersParticipationSeriesLoading: PropTypes.bool.isRequired,
  activity: PropTypes.shape({}).isRequired,
  ideas: PropTypes.shape({}).isRequired,
  peopleUserFilter: PropTypes.shape({
    name: PropTypes.string,
    filterType: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }).isRequired,
  usersParticipation: PropTypes.shape({
    hasMore: PropTypes.bool,
    totalUsers: PropTypes.number,
    participationAvg: PropTypes.string,
    successAvg: PropTypes.string,
    series: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onSendPeopleReport: PropTypes.func.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(Dashboard)
