import React from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import LogoFacebook from 'components/svg/components/facebook/View'
import LogoTwitter from 'components/svg/components/twitter/View'
import LogoLinkedin from 'components/svg/components/linkedin/View'
import logoBlack from '../../img/i360-logo.png'
import styles from './View.Style'

const SideLogin = props => {
  const { classes } = props

  return (
    <div className={classes.side}>
      <Link to="/">
        <img className={classes.header} src={logoBlack} alt="Logo" />
      </Link>

      <div className="content">
        <h2>
          <small>i360</small>
          Innovación con impacto
        </h2>
        <p>
          Digitalizamos la gestión de la innovación para acelerar la transformación de tu organización.
        </p>
      </div>
      <div className="footer">
        <div className="social">
          <a href="https://twitter.com/innova360cl" target="_blank" rel="noopener noreferrer">
            <LogoTwitter />
          </a>
          <a href="https://www.facebook.com/innova360cl" target="_blank" rel="noopener noreferrer">
            <LogoFacebook />
          </a>
          <a href="https://www.linkedin.com/company/innova360cl" target="_blank" rel="noopener noreferrer">
            <LogoLinkedin />
          </a>
        </div>
      </div>
    </div>
  )
}

SideLogin.propTypes = {
  classes: PropTypes.shape({
    side: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(SideLogin)
