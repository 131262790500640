import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, FormControl, MenuItem, Select } from '@material-ui/core'
import {
  IdeasSlider,
  IdeasGrid,
  NxtBenefits,
  NxtExpandedCard,
  NxtReward,
  NxtSmallCard,
  NxtUserCard,
  ShowFilter,
} from 'components'
import imgDefault from 'img/nxt-inverted-image.png'
import { arrayToGroups } from 'support/utils/array'
import i18n from 'support/i18n'
import { differenceInCalendarDays } from 'date-fns'
import NxtCircleContent from 'components/nxt_circle_content/View'
import { challengeStatuses } from 'pages/challenge/form/View.Utils'
import SearchInput from './components/search_input/View'
import styles from './View.Style'

class Challenges extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      challengeStatus: 'ACTIVE',
      showSlider: true,
    }
    this.handleFilterShow = this.handleFilterShow.bind(this)
    this.renderSliderComponent = this.renderSliderComponent.bind(this)
    this.renderGridComponent = this.renderGridComponent.bind(this)
    this.handleNewChallengeClick = this.handleNewChallengeClick.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleResetSearchClick = this.handleResetSearchClick.bind(this)
  }

  componentDidMount() {
    const { getActiveChallenges } = this.props
    getActiveChallenges()
  }

  handleFilterChange = event => {
    const { getFilteredChallenges } = this.props
    const status = event.target.value

    this.setState({ challengeStatus: status })
    getFilteredChallenges(status)
  }

  handleFilterShow(value) {
    this.setState({ showSlider: value })
  }

  handleNewChallengeClick() {
    const { canProposeChallenge, navigateToNewChallenge, navigateToProposeChallenge } = this.props
    if (canProposeChallenge) {
      navigateToProposeChallenge()
    } else {
      navigateToNewChallenge()
    }
  }

  handleSearchChange(event) {
    this.setState({ search: event.target.value })
  }

  handleResetSearchClick() {
    const { getFilteredChallenges } = this.props
    const { challengeStatus } = this.state
    getFilteredChallenges(challengeStatus)
  }

  renderListComponent = card => {
    // const { approvedMult, implementedMult, supportedMult, rewardText } = card
    const { rewardText } = card
    return (
      <Fragment>
        {/* <li>
          <NxtBenefits
            approvedMult={approvedMult}
            implementedMult={implementedMult}
            supportedMult={supportedMult}
            benefitsLabel={i18n.t('CHALLENGES.DETAIL.EXTRA_BENEFITS')}
            supportedLabel={i18n.t('CHALLENGES.DETAIL.SUPPORTED')}
            approvedLabel={i18n.t('CHALLENGES.DETAIL.APPROVED')}
            implementedLabel={i18n.t('CHALLENGES.DETAIL.IMPLEMENTED')}
          />
        </li> */}
        <li>
          <NxtReward
            rewardText={
              rewardText.length > 240
                ? `${rewardText.substring(0, 240)}...`
                : rewardText
            }
            rewardLabel={i18n.t('CHALLENGES.DETAIL.REWARD')}
            whoGetRewardLabel={i18n.t('CHALLENGES.WHO_REWARDED_INFO')}
          />
        </li>
      </Fragment>
    )
  }

  renderSliderComponent(array) {
    const {
      classes,
      navigateToChallengeDetail,
      navigateToNewIdea,
      navigateToUserProfile,
      navigateToIdea,
      isPrivateFlowActive,
      customIdeaTitles
    } = this.props

    const dataGrouped = arrayToGroups(array, 3)

    return array.length === 0 ? null : (
      <IdeasSlider arrowsPosition="center" stepper={array.length > 3}>
        {dataGrouped.length > 0 &&
          dataGrouped.map((group, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className={classes.cardGroup} key={`slide-${index}`}>
                {group.map(card => {
                  const {
                    starts,
                    ends,
                    numIdeas,
                    mainPhoto,
                    id,
                    sponsored,
                    sponsorName,
                    sponsorPhoto,
                    proposerName,
                    name,
                    status,
                    proposerEmail,
                    ideas,
                  } = card

                  const days = differenceInCalendarDays(Date.now(), starts)
                  const totalDays = differenceInCalendarDays(ends, starts)

                  return (
                    <NxtExpandedCard
                      isPrivate={isPrivateFlowActive}
                      buttonText={
                        <Fragment>
                          <i className="icon-target" />
                          {i18n.t('CHALLENGES.DETAIL.ANSWER_CHALLENGE')}
                        </Fragment>
                      }
                      days={days}
                      defaultSrc={imgDefault}
                      height="29rem"
                      id={id}
                      img={mainPhoto}
                      key={id}
                      onMainButtonClick={navigateToNewIdea}
                      sponsored={sponsored}
                      sponsorName={sponsorName}
                      sponsorPhoto={sponsorPhoto}
                      submittedBy={proposerName}
                      onSubmitterClick={() => navigateToUserProfile(proposerEmail)}
                      title={name}
                      totalDays={totalDays}
                      theme="dark"
                      onGoToButtonClick={navigateToChallengeDetail}
                      circleContent={<NxtCircleContent days={totalDays - days} />}
                      showCircle={status !== challengeStatuses.PROPOSED}
                      circleValue={days}
                      circleMaxValue={totalDays}
                      circleColor="white"
                      goToButtonText={i18n.t('CHALLENGES.GO_TO_CHALLENGE')}
                      ideas={ideas}
                      numIdeas={numIdeas}
                      clickable
                      navigateToIdea={navigateToIdea}
                      customIdeaTitles={customIdeaTitles}
                    >
                      {this.renderListComponent(card)}
                    </NxtExpandedCard>
                  )
                })}
              </div>
            )
          })}
      </IdeasSlider>
    )
  }

  renderGridComponent(array) {
    const { classes, navigateToChallengeDetail, isPrivateFlowActive, customIdeaTitles } = this.props

    return array.length === 0 ? null : (
      <IdeasGrid length={array.length} countSquare={false}>
        {array.length > 0 &&
          array.map((card) => {
            return (
              <NxtSmallCard
                isPrivate={isPrivateFlowActive}
                altTextBlock={`${card.numIdeas} ${i18n.t('CHALLENGES.DETAIL.IDEAS_PROPOSED', { title: customIdeaTitles.pluralTitle || "ideas" })}`}
                days={differenceInCalendarDays(Date.now(), card.starts)}
                defaultSrc={imgDefault}
                description={card.name}
                height="35rem"
                id={card.id}
                img={card.mainPhoto}
                key={card.id}
                onCardClick={navigateToChallengeDetail}
                sponsored={card.sponsored}
                submittedBy={card.proposerName}
                title={card.name}
                totalDays={differenceInCalendarDays(card.ends, card.starts)}
                theme="dark"
                hideButttonsBlock
                hideSubmitedBy
              >
                <li>
                  <NxtBenefits
                    approvedMult={card.approvedMult}
                    implementedMult={card.implementedMult}
                    showBottomLabel={false}
                    supportedMult={card.supportedMult}
                    benefitsLabel={i18n.t('CHALLENGES.DETAIL.EXTRA_BENEFITS')}
                    supportedLabel={i18n.t('CHALLENGES.DETAIL.SUPPORTED')}
                    approvedLabel={i18n.t('CHALLENGES.DETAIL.APPROVED')}
                    implementedLabel={i18n.t('CHALLENGES.DETAIL.IMPLEMENTED')}
                  />
                </li>
                <li>
                  <div className={classes.userCard}>
                    <NxtUserCard
                      label={i18n.t('CHALLENGES.DETAIL.SPONSOR')}
                      imageSrc={card.sponsorPhoto}
                      usernameElement={<span>{card.sponsorName}</span>}
                      isTextInverted
                      imageSize="2.2rem"
                    />
                  </div>
                </li>
              </NxtSmallCard>
            )
          })}
      </IdeasGrid>
    )
  }

  render() {
    const {
      classes,
      challenges,
      isAdmin,
      canProposeChallenge,
      isInnovator,
      applySearch,
      isPrivateFlowActive
    } = this.props

    const { challengeStatus, showSlider, search } = this.state

    return (
      <Fragment>
        <div className={classes.top}>
          <h1>
            <i className="icon-target" /> {i18n.t('CHALLENGES.CHALLENGES')}
          </h1>
          <div className={classes.headerContainer}>
            <div>
              {(isAdmin || isInnovator || canProposeChallenge) && (
                <Button variant="contained" color="primary" onClick={this.handleNewChallengeClick}>
                  {canProposeChallenge
                    ? i18n.t('CHALLENGES.PROPOSE')
                    : i18n.t('CHALLENGES.CREATE_CHALLENGE')}
                </Button>
              )}
            </div>

            <div className={classes.filterContainer}>
              <span>{i18n.t('CHALLENGES.FILTER.SHOW')}: </span>
              <FormControl variant="outlined" classes={{ root: classes.formControl }}>
                <Select value={challengeStatus} onChange={this.handleFilterChange}>
                  <MenuItem value="ACTIVE">{i18n.t('CHALLENGES.FILTER.ACTIVE')}</MenuItem>
                  {(isAdmin || isInnovator) && [
                    <MenuItem key="PROPOSED" value="PROPOSED">
                      {i18n.t('CHALLENGES.FILTER.PROPOSED')}
                    </MenuItem>,
                    <MenuItem key="PLANNED" value="PLANNED">
                      {i18n.t('CHALLENGES.FILTER.COMING_SOON')}
                    </MenuItem>,
                    <MenuItem key="DELETED" value="DELETED">
                      {i18n.t('CHALLENGES.FILTER.DELETED')}
                    </MenuItem>,
                  ]}
                  <MenuItem value="FINISHED">{i18n.t('CHALLENGES.FILTER.FINISHED')}</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div>
              <SearchInput
                applySearch={applySearch}
                resetSearch={this.handleResetSearchClick}
                handleChange={this.handleSearchChange}
                value={search}
              />
            </div>
          </div>
        </div>
        <div className={classes.middle}>
          <div className={classes.center}>
            <ShowFilter
              isPrivateFlowActive={isPrivateFlowActive}
              title={challenges.length === 1
                ? `${challenges.length} ${i18n.t('IDEAS_WALL.CHALLENGE')}`
                : `${challenges.length} ${i18n.t('CHALLENGES.CHALLENGES')}`
              }
              cards={challenges}
              showSlider={showSlider}
              onClick={this.handleFilterShow}
              slideComponent={this.renderSliderComponent(challenges)}
              gridComponent={this.renderGridComponent(challenges)}
              onCardTypeChange={this.handleFilterShow}
              isChallenge
              filteredName={challengeStatus}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

Challenges.propTypes = {
  classes: PropTypes.shape({
    cardGroup: PropTypes.string,
    userCard: PropTypes.string,
    top: PropTypes.string,
    middle: PropTypes.string,
  }).isRequired,
  challenges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getActiveChallenges: PropTypes.func.isRequired,
  navigateToChallengeDetail: PropTypes.func.isRequired,
  navigateToProposeChallenge: PropTypes.func.isRequired,
  navigateToNewChallenge: PropTypes.func.isRequired,
  navigateToUserProfile: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isInnovator: PropTypes.bool.isRequired,
  canProposeChallenge: PropTypes.bool.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  isPrivateFlowActive: PropTypes.bool.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(Challenges)
