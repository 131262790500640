import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Paper } from '@material-ui/core'
import IdeasSlider from 'components/show_filter/components/ideas_slide/View'
import NxtSmallCard from 'components/nxt_small_card/View'
import imgDefault from 'img/nxt-inverted-image.png'
import NxtCardListItems from 'components/nxt_card/nxt_card_list_items/View'
import i18n from 'support/i18n'
import { arrayToGroups } from 'support/utils/array'
import styles from './View.Style'

const MyIdeasSection = (props) => {
  const { classes, ideas, onIdeaClick, username, userPhoto, customIdeaTitles } = props
  const ideasGrouped = arrayToGroups(ideas, 4)

  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <span className="icon-idea" />
        <span className={classes.titleText}>
          {i18n.t('IDEAS', { title: customIdeaTitles.pluralTitle.charAt(0).toUpperCase() + customIdeaTitles.pluralTitle.slice(1) || "Ideas" })}
        </span>
      </div>
      {ideas.length > 0 && (
        <IdeasSlider>
          {ideasGrouped.map((ideasGroup, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className={classes.cardGroup} key={`slide-${index}`}>
                {ideasGroup.map(card => {
                  const {
                    challenged,
                    id,
                    images,
                    investors,
                    ownerName,
                    pledged,
                    textWithoutTags,
                    title,
                  } = card

                  return (
                    <NxtSmallCard
                      challenged={challenged}
                      defaultSrc={imgDefault}
                      description={textWithoutTags}
                      id={id}
                      img={images.length > 0 && images[0].url}
                      investors={investors}
                      key={id}
                      onClickMainButton={() => onIdeaClick(id)}
                      pledged={pledged}
                      submittedBy={ownerName}
                      title={title}
                      mainButtonText={i18n.t('GO_TO_IDEA', { title: customIdeaTitles.singularTitle || "idea" })}
                      ownerPhoto={userPhoto}
                    >
                      <NxtCardListItems
                        primaryValue={pledged}
                        secondaryValue={investors}
                        variant="black"
                        primaryText={i18n.t('PLEDGED')}
                        secondaryText={i18n.t('INVESTORS')}
                      />
                    </NxtSmallCard>
                  )
                })}
              </div>
            )
          })}
        </IdeasSlider>
      )}
      {ideas.length === 0 && (
        <Paper className={classes.noIdeasPaper}>
          {username} {i18n.t('PROFILE.NO_IDEAS', { title: customIdeaTitles.pluralTitle || "ideas" })}
        </Paper>
      )}
    </div>
  )
}

MyIdeasSection.defaultProps = {
  userPhoto: '',
}

MyIdeasSection.propTypes = {
  ideas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onIdeaClick: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  userPhoto: PropTypes.string,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(MyIdeasSection)
