import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { withStyles } from '@material-ui/core'
import NxtMiniCard from 'components/nxt_mini_card/View'
import ButtonLink from 'components/button_link/View'
import styles from './View.Style'

const IdeaCard = (props) => {
  const { classes, ideaId, ideaName, ideaPhotoSrc, navigateToIdea, pledged, investors, customIdeaTitle } = props
  return (
    <Fragment>
      <div className={classes.title}>
        <span className="icon-idea" />
        <span>
          {i18n.t('TIMELINE.PUBLISH_IDEA', { title: customIdeaTitle || "idea" })}
        </span>
      </div>
      <div className={classes.body}>
        <ButtonLink
          classes={{ linkButton: classes.linkButton }}
          onLinkClick={() => navigateToIdea(ideaId)}
        >
          <NxtMiniCard
            pledged={pledged}
            investors={investors}
            title={ideaName}
            imageSrc={ideaPhotoSrc}
          />
        </ButtonLink>
      </div>
    </Fragment>
  )
}

IdeaCard.propTypes = {
  ideaId: PropTypes.string.isRequired,
  ideaName: PropTypes.string.isRequired,
  ideaPhotoSrc: PropTypes.string.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  pledged: PropTypes.string.isRequired,
  investors: PropTypes.string.isRequired,
  customIdeaTitle: PropTypes.string.isRequired
}

export default withStyles(styles)(IdeaCard)
