/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/Button/Button'
import i18n from 'support/i18n'
import styles from './View.Style'

const SimpleMenu = props => {
  const {
    classes,
    showAdmin,
    navigateToAdmin,
    navigateToDashboard,
    navigateToPendingValidation,
    navigateToPrivateIdeas,
    navigateToMyProfile,
    navigateToRanking,
    navigateToSettings,
    navigateToLogout,
    isPrivateFlowActive,
    isPreviousValidationEnabled,
    useRanking,
    customIdeaTitles
  } = props

  return (
    <ul className={classes.container}>
      <li>
        <ButtonBase component="a" onClick={navigateToMyProfile}>
          <span className="icon-vcard" />
          <b>{i18n.t('SCREEN.PROFILE')}</b>
        </ButtonBase>
      </li>
      {showAdmin && (
        <li>
          <ButtonBase component="a" onClick={navigateToDashboard}>
            <span className="icon-portfolio" />
            <b>{i18n.t('SCREEN.DASHBOARD')}</b>
          </ButtonBase>
        </li>
      )}
      {useRanking && (
        <li>
          <ButtonBase component="a" onClick={navigateToRanking}>
            <span className="icon-ranking" />
            <b>{i18n.t('SCREEN.RANKING')}</b>
          </ButtonBase>
        </li>
      )}
      <li>
        <ButtonBase component="a" onClick={navigateToSettings}>
          <span className="icon-settings" />
          <b>{i18n.t('SCREEN.SETTINGS')}</b>
        </ButtonBase>
      </li>
      {showAdmin && (
        <Fragment>
          {isPreviousValidationEnabled && (
            <li>
              <ButtonBase component="a" onClick={navigateToPendingValidation}>
                <span className="icon-rocket" />
                <b>{i18n.t('SCREEN.PENDING_VALIDATION')}</b>
              </ButtonBase>
            </li>
          )}
          {isPrivateFlowActive && (
            <li>
              <ButtonBase component="a" onClick={navigateToPrivateIdeas}>
                <span className="icon-lock" />
                <b style={{ textTransform: "capitalize" }}>{i18n.t('SCREEN.PRIVATE_IDEAS', { title: customIdeaTitles.pluralTitle || "ideas" })}</b>
              </ButtonBase>
            </li>
          )}
          <li>
            <ButtonBase component="a" onClick={navigateToAdmin}>
              <span className="icon-owner" />
              <b>{i18n.t('SCREEN.ADMIN')}</b>
            </ButtonBase>
          </li>
        </Fragment>
      )}
      <li>
        <ButtonBase component="a" onClick={navigateToLogout}>
          <span className="icon-logout" />
          <b>{i18n.t('SCREEN.LOGOUT')}</b>
        </ButtonBase>
      </li>
    </ul>
  )
}

SimpleMenu.propTypes = {
  showAdmin: PropTypes.bool.isRequired,
  isPrivateFlowActive: PropTypes.bool.isRequired,
  navigateToAdmin: PropTypes.func.isRequired,
  navigateToDashboard: PropTypes.func.isRequired,
  navigateToPendingValidation: PropTypes.func.isRequired,
  navigateToPrivateIdeas: PropTypes.func.isRequired,
  navigateToMyProfile: PropTypes.func.isRequired,
  navigateToRanking: PropTypes.func.isRequired,
  navigateToSettings: PropTypes.func.isRequired,
  navigateToLogout: PropTypes.func.isRequired,
  useRanking: PropTypes.bool.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(SimpleMenu)
