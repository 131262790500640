/* eslint-disable react/forbid-prop-types */
import React, { Component, Fragment } from 'react'
import { Tab, Tabs, withStyles } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import { differenceInDays } from 'date-fns'
import i18n from '../../../support/i18n'
import DocumentSection from '../../../components/document_section/View'
import NxtControlButtons from '../../../components/nxt_control_buttons/View'
import { challengeStatuses } from '../../challenge/form/View.Utils'
import style from './View.Style'
import ChallengeSection from './components/challenge_section/View'
import LogisticDataSection from './components/logistic_data_section/View'
import OrganizationTypeSection from './components/organization_type_section/View'
import StrategicPrioritiesSection from './components/strategic_priorities_section/View'
import ReportIdeaSection from './components/report_idea_section/View'
import ContributionSection from './components/contribution_section/View'
import StatusSection from './components/status_section/View'
import InvestmentSection from './components/investment_section/View'
import CommentsSection from './components/comments_section/View'
import SummarySection from './components/summary_section/View'
import { ideaStatusMapper, statusMapper, queryParamsActions } from './Constants'
import { getCurrentStatusProps } from './View.Utils'
import SendJoinSection from './components/send_join_section/View'
import AcceptRejectSection from './components/accept_reject_section/View'
import TransferLeader from './components/transfer_leader/View'
import KpiSection from './components/kpi_section/View'
import StandardForm from './components/standarForm/StandardForm'
import Information from './components/Information'

const StyledTabs = withStyles({
  indicator: {
    display: 'none',
  },
})(props => <Tabs {...props} />)

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    backgroundColor: '#fff',
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    '&:focus': {
      opacity: 1,
    },
    borderRadius: '5px 5px 0px 0px',
  },
}))(props => <Tab disableRipple {...props} />)

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

class IdeaDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttons: [],
      showTransferLeader: false,
      selectedTransferUser: {},
      tabSelection: 0,
      isStandardFormVisible: props.isOwner || props.isAdmin || props.isInnovator,
      stateTabIdea: {
        commentText: '',
      },
    }

    const { match, location } = props
    const queryParametersHandler = new URLSearchParams(location.search)
    this.showAcceptSnackbar =
      queryParametersHandler.get('action') === queryParamsActions.SHOW_ACCEPT_SNACKBAR
    this.showRejectSnackbar =
      queryParametersHandler.get('action') === queryParamsActions.SHOW_REJECT_SNACKBAR
    this.id = match.params.id
    this.handleReportClick = this.handleReportClick.bind(this)
    this.handleSaveContribution = this.handleSaveContribution.bind(this)
    this.handleDeleteContribution = this.handleDeleteContribution.bind(this)
    this.handleDocumentUpload = this.handleDocumentUpload.bind(this)
    this.handleDeleteDocument = this.handleDeleteDocument.bind(this)
    this.handleSendInvitation = this.handleSendInvitation.bind(this)
    this.handleDeleteUserFromTeam = this.handleDeleteUserFromTeam.bind(this)
    this.handleDeleteUserFromTeamInitiative = this.handleDeleteUserFromTeamInitiative.bind(this)
    this.handlePostComment = this.handlePostComment.bind(this)
    this.handleDeleteComment = this.handleDeleteComment.bind(this)
    this.handleVoteComment = this.handleVoteComment.bind(this)
    this.handleEditComment = this.handleEditComment.bind(this)
    this.handleDiscardReason = this.handleDiscardReason.bind(this)
    this.handleActivatePilot = this.handleActivatePilot.bind(this)
    this.handleApproveReason = this.handleApproveReason.bind(this)
    this.calculateButtons = this.calculateButtons.bind(this)
    this.handleTransferLeader = this.handleTransferLeader.bind(this)
    this.handleSelectSuggestion = this.handleSelectSuggestion.bind(this)
    this.handleInvestmentClick = this.handleInvestmentClick.bind(this)
    this.showRoundStepper = this.showRoundStepper.bind(this)
    this.handleChangeTabs = this.handleChangeTabs.bind(this)
    this.handleOnGetStandarForm = this.handleOnGetStandarForm.bind(this)
    this.handleUpdateComment = this.handleUpdateComment.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.handleDeleteFile = this.handleDeleteFile.bind(this)
  }

  componentDidMount() {
    const {
      getIdea,
      getInvestors,
      getContributions,
      getIsUserInvitedToInnovationIdeaTeam,
      getIsUserInvitedToIdeaTeam,
      getIdeaTeam,
      getComments,
      getRejectedReasons,
      getApprovedReasons,
      getIsTeamRequestActive,
      onVisitIdea,
    } = this.props
    const { isStandardFormVisible } = this.state

    getIsUserInvitedToInnovationIdeaTeam(this.id)
    getIsUserInvitedToIdeaTeam(this.id)
    getIdea(this.id)
    getInvestors(this.id)
    getContributions(this.id)
    getIdeaTeam(this.id)
    getComments(this.id)
    getRejectedReasons()
    getApprovedReasons()
    getIsTeamRequestActive(this.id)
    onVisitIdea(this.id)
    this.calculateButtons()

    if (isStandardFormVisible) this.handleOnGetStandarForm()
  }

  componentDidUpdate(prevProps) {
    const {
      isContributionActive,
      userEmail,
      getContributions,
      hasIdeaLoaded,
      hasDomainLoaded,
      currentStatus,
      isOwner,
      isAdmin,
      isInnovator,
      videoSrc,
      videoType,
      getIdeaVideoType,
    } = this.props

    if (videoSrc && !videoType) {
      getIdeaVideoType(videoSrc)
    }

    if (isContributionActive !== prevProps.isContributionActive && userEmail) {
      getContributions(this.id)
    }

    if (hasIdeaLoaded && hasDomainLoaded) {
      const { showAcceptInvitationSnackbar, showRejectInvitationSnackbar } = this.props
      if (this.showAcceptSnackbar) {
        showAcceptInvitationSnackbar()
      } else if (this.showRejectSnackbar) {
        showRejectInvitationSnackbar()
      }
    }

    if (
      prevProps.currentStatus !== currentStatus ||
      prevProps.isOwner !== isOwner ||
      prevProps.isAdmin !== isAdmin ||
      prevProps.isInnovator !== isInnovator
    ) {
      this.calculateButtons()
    }

    if (
      prevProps.isOwner !== isOwner ||
      prevProps.isAdmin !== isAdmin ||
      prevProps.isInnovator !== isInnovator
    ) {
      this.setState({
        isStandardFormVisible: isOwner || isAdmin || isInnovator,
      })
    }
  }

  componentWillUnmount() {
    const { onComponentUnmount } = this.props
    onComponentUnmount()
  }

  getActiveSummaryStep() {
    const { investment } = this.props
    if (investment.thirdRoundInvestors.length) return 3
    if (investment.secondRoundInvestors.length) return 2
    if (investment.firstRoundInvestors.length) return 1
    if (investment.seedInvestors.length) return 0
    return -1
  }

  getIdeaSubTitle(currentStatusText) {
    const { currentStatus, endDate } = this.props
    const mappedStatus = ideaStatusMapper[currentStatus]

    if (
      mappedStatus === ideaStatusMapper.PENDING_SUPPORT ||
      mappedStatus === ideaStatusMapper.SUPPORTED
    ) {
      const passedDays = differenceInDays(endDate, Date.now())
      return `${currentStatusText} ${i18n.t('GRAPH.DAYS_CAN_INVEST').replace('dd', passedDays)}`
    }
    if (mappedStatus === ideaStatusMapper.ROUND_CLOSED) {
      const passedDays = differenceInDays(endDate, Date.now())
      return `${currentStatusText} ${i18n.t('GRAPH.DAYS_FOR_APPROVED').replace('dd', passedDays)}`
    }
    if (mappedStatus === ideaStatusMapper.DRAFT) {
      return `${i18n.t('IDEA_STATUS.DRAFT')}`
    }
    return currentStatusText
  }

  calculateButtons() {
    const buttons = []
    const {
      onEditClick,
      onDeleteClick,
      currentStatus,
      isAdmin,
      isInnovator,
      isOwner,
      onActivatePilot,
      onImplementPilot,
      isPendingValidation,
      customIdeaTitles,
    } = this.props

    if (isAdmin || isInnovator || (isOwner && !isPendingValidation)) {
      buttons.push({
        text: i18n.t('IDEA.EDIT', { title: customIdeaTitles.singularTitle || 'idea' }),
        action: () => onEditClick(this.id),
      })
      buttons.push({
        text: i18n.t('IDEA.DELETE', { title: customIdeaTitles.singularTitle || 'idea' }),
        action: () => onDeleteClick(this.id),
        isNotPrimary: true,
      })
    }

    if (
      (ideaStatusMapper[currentStatus] < ideaStatusMapper.APPROVED_BY_IT ||
        ideaStatusMapper[currentStatus] === ideaStatusMapper.DISCARDED) &&
      (isAdmin || isInnovator)
    ) {
      buttons.push({
        text: i18n.t('EXPLORE_IDEA.APPROVE', { title: customIdeaTitles.singularTitle || 'idea' }),
        action: this.handleApproveReason,
      })
    }

    if (
      ideaStatusMapper[currentStatus] !== ideaStatusMapper.DISCARDED &&
      ideaStatusMapper[currentStatus] !== ideaStatusMapper.DRAFT &&
      (isAdmin || isInnovator)
    ) {
      buttons.push({
        text: i18n.t('IDEA.DISCARD', { title: customIdeaTitles.singularTitle || 'idea' }),
        action: this.handleDiscardReason,
      })
    }

    if (
      ideaStatusMapper[currentStatus] === ideaStatusMapper.APPROVED_BY_IT &&
      (isAdmin || isInnovator)
    ) {
      buttons.push({
        text: i18n.t('EXPLORE_IDEA.ACTIVATE_PILOT'),
        action: () => onActivatePilot(this.id),
      })
    }

    if (
      (isAdmin || isInnovator) &&
      (ideaStatusMapper[currentStatus] === ideaStatusMapper.PILOT_ACTIVE ||
        ideaStatusMapper[currentStatus] === ideaStatusMapper.IMPLEMENTED ||
        ideaStatusMapper[currentStatus] === ideaStatusMapper.APPROVED_BY_IT)
    ) {
      buttons.push({
        text: i18n.t('EXPLORE_IDEA.TRANSFER'),
        action: () =>
          this.setState(prevState => ({ showTransferLeader: !prevState.showTransferLeader })),
      })
    }

    if (
      ideaStatusMapper[currentStatus] === ideaStatusMapper.PILOT_ACTIVE &&
      (isAdmin || isInnovator)
    ) {
      buttons.push({
        text: i18n.t('EXPLORE_IDEA.MARK_IMPLEMENTED'),
        action: () => onImplementPilot(this.id),
      })
    }

    this.setState({ buttons })
  }

  handleReportClick() {
    // INFO: customIdeaTitle send with value for message component
    const { onReportClick, customIdeaTitles } = this.props
    onReportClick(this.id, customIdeaTitles)
  }

  handleSaveContribution() {
    const { onSaveContribution, ownContribution } = this.props
    onSaveContribution(this.id, ownContribution.userId)
  }

  handleDeleteContribution() {
    const { onDeleteContribution } = this.props
    onDeleteContribution(this.id)
  }

  handleDeleteUserFromTeam(email) {
    const { onPendingUserRemoveClick } = this.props
    onPendingUserRemoveClick(this.id, email)
  }

  handleDeleteUserFromTeamInitiative(email) {
    const { onPendingUserRemoveClick } = this.props
    onPendingUserRemoveClick(this.id, email, true)
  }

  handleSendInvitation(email) {
    const { onSendInvitationClick } = this.props
    onSendInvitationClick(email, this.id)
  }

  canInvest() {
    const { currentStatus } = this.props
    return (
      ideaStatusMapper[currentStatus] === ideaStatusMapper.PENDING_SUPPORT ||
      ideaStatusMapper[currentStatus] === ideaStatusMapper.SUPPORTED ||
      ideaStatusMapper[currentStatus] === ideaStatusMapper.DRAFT
    )
  }

  showRoundStepper() {
    const { currentStatus } = this.props
    return (
      ideaStatusMapper[currentStatus] === ideaStatusMapper.PENDING_SUPPORT ||
      ideaStatusMapper[currentStatus] === ideaStatusMapper.SUPPORTED
    )
  }

  handleDocumentUpload(file) {
    const { onDocumentUpload } = this.props
    onDocumentUpload(this.id, file)
  }

  handleDeleteDocument(documentId) {
    const { onDocumentDelete } = this.props
    onDocumentDelete(this.id, documentId)
  }

  handlePostComment(comment, parentId) {
    const { onPostComment } = this.props
    onPostComment(comment, parentId, this.id)
  }

  handleDeleteComment(commentId) {
    const { onDeleteComment } = this.props
    onDeleteComment(commentId, this.id)
  }

  handleVoteComment(commentId) {
    const { onVoteComment } = this.props
    onVoteComment(commentId, this.id)
  }

  handleEditComment(comment, commentId) {
    const { onEditComment } = this.props
    onEditComment(comment, commentId, this.id)
  }

  handleApproveReason() {
    const { onApproveClick, approvedReasons } = this.props
    onApproveClick(this.id, approvedReasons)
  }

  handleDiscardReason() {
    const { onDiscardClick, rejectedReasons } = this.props
    onDiscardClick(this.id, rejectedReasons)
  }

  handleActivatePilot() {
    const { onActivatePilot } = this.props
    onActivatePilot(this.id)
  }

  handleTransferLeader() {
    const { selectedTransferUser } = this.state
    const { onTransferLeader } = this.props
    onTransferLeader(this.id, selectedTransferUser.email)
    this.setState({ showTransferLeader: false, selectedTransferUser: {} })
  }

  handleSelectSuggestion(user) {
    this.setState({ showTransferLeader: false, selectedTransferUser: user })
  }

  handleInvestmentClick(investmentAmount = 0) {
    const { investInIdea, isDraft, publishIdea, images, showNoImageDraftSnackbar } = this.props
    if (isDraft && !images.length) {
      showNoImageDraftSnackbar()
    } else if (isDraft) {
      publishIdea(this.id, investmentAmount)
    } else {
      investInIdea(this.id, investmentAmount)
    }
  }

  handleChangeTabs(event, newValue) {
    event.preventDefault()
    this.setState({ tabSelection: newValue })
  }

  handleUpdateComment(value) {
    this.setState({
      stateTabIdea: {
        commentText: value,
      },
    })
  }

  // FORM HANDLERS
  handleOnGetStandarForm() {
    const { handleGetStandardForm, userEmail, currentDomain } = this.props
    const ideaId = this.id
    handleGetStandardForm({ ideaId, userEmail, currentDomain })
  }

  handleFileUpload(file) {
    const { onFileUpload } = this.props
    onFileUpload(this.id, file)
  }

  handleDeleteFile(fileKey) {
    const { onFileDelete } = this.props
    onFileDelete(this.id, fileKey)
  }

  render() {
    const {
      classes,
      hasIdeaLoaded,
      hasDomainLoaded,
      challengeDetail,
      challengeStatus,
      pilotEstimatedQuantity,
      pilotEstimatedHours,
      pilotEstimatedTime,
      pilotEstimatedTimeType,
      implementEstimatedQuantity,
      implementEstimatedHours,
      implementEstimatedTime,
      implementEstimatedTimeType,
      economicBenefits,
      otherBenefits,
      organizationTitle,
      organizationName,
      typeTitle,
      typeName,
      attributeTitle,
      attributeName,
      navigateToChallengeDetail,
      isOrganizationsActive,
      isTypesActive,
      isAttributesActive,
      goals,
      ideaStatusStepper,
      currentStatus,
      statusMessageText,
      statusMessageTitle,
      userEmail,
      contributors,
      ownContribution,
      isTimeActive,
      isBudgetActive,
      isOtherActive,
      isContributionActive,
      getContributions,
      setContributionTime,
      setContributionBudget,
      setContributionOther,
      investment,
      currencyIcon,
      navigateToUserProfile,
      submitter,
      promoter,
      onSummaryStepperHelpClick,
      maxInvestment,
      minInvestment,
      userBalance,
      isInvestmentInProcess,
      createdDate,
      endDate,
      singleId,
      totalInvestors,
      pledged,
      goal,
      visits,
      title,
      description,
      videoSrc,
      videoType,
      images,
      onAskInvestmentClick,
      team,
      pilotTeam,
      onSearchSuggestions,
      suggestions,
      pendingInvitationsUsers,
      pendingInvitationsPilotTeamUsers,
      isOwner,
      canSendIdeaTeamInvitations,
      canSendPilotProjectTeamInvitations,
      isAdmin,
      isPromoter,
      ownerEmail,
      promoterEmail,
      isSearchingForUser,
      documents,
      isDocumentLoading,
      isInnovator,
      showPendingTeamInvitationSection,
      showPendingInnovationTeamInvitationSection,
      postAcceptJoinIdeaTeam,
      postRejectJoinIdeaTeam,
      postAcceptJoinInnovationTeam,
      postRejectJoinInnovationTeam,
      isProcessingInnovationTeamInvitation,
      isProcessingTeamInvitation,
      onRequestJoinIdeaTeam,
      postingCommentStatus,
      comments,
      totalComments,
      userInvestmentsMap,
      contributeCurrencySymbol,
      onShareIdea,
      isPendingValidation,
      onValidatePendingIdeaClick,
      onRejectPendingIdeaClick,
      isLoadingPendingIdea,
      isDraft,
      onResetSuggestionList,
      isAlreadyInvited,
      isAnonymous,
      kpis,
      onUpdateKpiAction,
      onUpdateKpiSuccess,
      onDeleteKpi,
      onSubmitKpiForm,
      isFormInUse,
      csrf,
      isPrivateFlowActive,
      customIdeaTitles,
      handleStandardFormSave,
      currentDomain,
      forms,
      instanceColors,
    } = this.props

    const {
      buttons,
      showTransferLeader,
      selectedTransferUser,
      tabSelection,
      isStandardFormVisible,
      stateTabIdea,
    } = this.state

    const currentStatusProps = getCurrentStatusProps(ideaStatusStepper, currentStatus)
    const canInvest = this.canInvest()
    const isPilotActive =
      ideaStatusMapper[currentStatus] === ideaStatusMapper.PILOT_ACTIVE ||
      ideaStatusMapper[currentStatus] === ideaStatusMapper.IMPLEMENTED

    return (
      <Fragment>
        {hasIdeaLoaded && hasDomainLoaded && (
          <div className={classes.root}>
            {/* STATUS SECTION */}
            {!isPendingValidation && !isDraft && (
              <StatusSection
                ideaStatusStepper={ideaStatusStepper}
                currentStatus={currentStatus}
                messageText={statusMessageText}
                messageTitle={statusMessageTitle}
                customIdeaTitle={customIdeaTitles.singularTitle}
              />
            )}
            {isPendingValidation && (
              <AcceptRejectSection
                classes={{ root: classes.pendingValidationContainer }}
                message={i18n.t('IDEA_STATUS.PENDING_VALIDATION')}
                isLoading={isLoadingPendingIdea}
                canAcceptOrDecline={isInnovator || isAdmin}
                acceptButtonLabel={i18n.t('IDEA_STATUS.VALIDATE', {
                  title: customIdeaTitles.singularTitle || 'idea',
                })}
                declineButtonLabel={i18n.t('IDEA_STATUS.REJECT', {
                  title: customIdeaTitles.singularTitle || 'idea',
                })}
                onAcceptClick={() => onValidatePendingIdeaClick(this.id)}
                onDeclineClick={() => onRejectPendingIdeaClick(this.id)}
              />
            )}
            {showPendingTeamInvitationSection && (
              <AcceptRejectSection
                message={i18n.t('IDEA_TEAM.WANT_JOIN')}
                isLoading={isProcessingTeamInvitation}
                onAcceptClick={() => postAcceptJoinIdeaTeam(this.id)}
                onDeclineClick={() => postRejectJoinIdeaTeam(this.id)}
              />
            )}
            {showPendingInnovationTeamInvitationSection && (
              <AcceptRejectSection
                message={i18n.t('IDEA_INNOVATION_TEAM.WANT_JOIN')}
                isLoading={isProcessingInnovationTeamInvitation}
                onAcceptClick={() => postAcceptJoinInnovationTeam(this.id)}
                onDeclineClick={() => postRejectJoinInnovationTeam(this.id)}
              />
            )}
            {!isDraft && (
              <ReportIdeaSection
                onReportClick={this.handleReportClick}
                customIdeaTitle={customIdeaTitles.singularTitle}
              />
            )}
            {/* STANDARD FORM INFORMATION */}
            {forms.isStandardActive && isStandardFormVisible && (
              <Information
                instanceColors={instanceColors}
                isVisible
                type="instance"
                content={i18n.t('FORMS.INFORMATION.STANDARD_FORM', {
                  title: customIdeaTitles.singularTitle || 'idea',
                })}
              />
            )}
            {/* BUTTONS SECTION */}
            <NxtControlButtons type="idea" buttons={buttons} />
            <TransferLeader
              showTransferLeader={showTransferLeader}
              suggestions={suggestions}
              onSearchSuggestions={onSearchSuggestions}
              onSelectSuggestion={this.handleSelectSuggestion}
              onTransferLeader={this.handleTransferLeader}
              selectedUser={selectedTransferUser}
              onRemoveUser={() => this.setState({ selectedTransferUser: {} })}
              isSearchingForUser={isSearchingForUser}
            />
            {/* TABS */}
            <StyledTabs
              value={tabSelection}
              onChange={this.handleChangeTabs}
              aria-label={`wrapped label tabs ${tabSelection}`}
              id={`simple-tab-${tabSelection}`}
              indicatorColor="primary"
            >
              <StyledTab
                value={0}
                label={i18n.t('SCREEN.EXPLORE', {
                  title: customIdeaTitles.singularTitle || 'Idea',
                })}
                style={{
                  marginLeft: 20,
                }}
              />
              {forms.isStandardActive && isStandardFormVisible && (
                <StyledTab value={1} label={i18n.t('FORMS.STANDARD_FORM.TITLE')} />
              )}
            </StyledTabs>
            {/* IDEA TAB */}
            <CustomTabPanel value={tabSelection} index={0}>
              <SummarySection
                title={title}
                subTitle={this.getIdeaSubTitle(currentStatusProps.text)}
                description={description}
                videoSrc={videoSrc}
                videoType={videoType}
                images={images}
                activeStep={this.getActiveSummaryStep()}
                onSummaryStepperHelpClick={onSummaryStepperHelpClick}
                submitter={submitter}
                isAnonymous={isAnonymous}
                promoter={promoter}
                onUserNameClick={navigateToUserProfile}
                onAskInvestmentClick={() => onAskInvestmentClick(this.id)}
                maxInvestment={maxInvestment}
                minInvestment={isDraft ? 0 : minInvestment}
                currencyIcon={currencyIcon}
                userBalance={userBalance}
                onInvestButtonClick={this.handleInvestmentClick}
                isInvestmentInProcess={isInvestmentInProcess}
                currentStatus={currentStatus}
                currentStatusColor={currentStatusProps.color}
                createdDate={createdDate}
                endDate={endDate}
                singleId={singleId}
                totalInvestors={totalInvestors}
                pledged={pledged}
                goal={goal}
                visits={visits}
                hasChallengeAssociated={!!challengeDetail.name}
                canInvest={
                  canInvest &&
                  challengeStatus !== challengeStatuses.FINISHED &&
                  challengeStatus !== challengeStatuses.DELETED
                }
                canAskForInvestments={
                  !isDraft &&
                  canInvest &&
                  challengeStatus !== challengeStatuses.FINISHED &&
                  challengeStatus !== challengeStatuses.DELETED
                }
                showInvestmentDetail={
                  !isDraft &&
                  ideaStatusMapper[currentStatus] !== ideaStatusMapper.PENDING_VALIDATION
                }
                showRoundStepper={this.showRoundStepper()}
                investHelpText={isDraft ? i18n.t('EXPLORE_IDEA.FIRST_INVEST') : ''}
                inProcessButtonText={
                  isDraft ? i18n.t('EXPLORE_IDEA.PUBLISHING') : i18n.t('EXPLORE_IDEA.INVESTING')
                }
                buttonText={
                  isDraft ? i18n.t('EXPLORE_IDEA.PUBLISH_IDEA') : i18n.t('EXPLORE_IDEA.INVEST')
                }
                shouldInputBeDirty={!isDraft}
                customIdeaTitle={customIdeaTitles.singularTitle}
              />
              {(isAdmin || isInnovator || isOwner) && (
                <DocumentSection
                  csrfToken={csrf}
                  documents={documents}
                  isDocumentLoading={isDocumentLoading}
                  onFileSelect={this.handleDocumentUpload}
                  onDeleteDocument={this.handleDeleteDocument}
                  canDelete={isAdmin || isInnovator || isOwner}
                />
              )}
              <LogisticDataSection
                pilotEstimatedTimeType={pilotEstimatedTimeType}
                pilotEstimatedTime={pilotEstimatedTime}
                pilotEstimatedQuantity={pilotEstimatedQuantity}
                pilotEstimatedHours={pilotEstimatedHours}
                implementEstimatedTimeType={implementEstimatedTimeType}
                implementEstimatedTime={implementEstimatedTime}
                implementEstimatedQuantity={implementEstimatedQuantity}
                implementEstimatedHours={implementEstimatedHours}
                otherBenefits={otherBenefits}
                economicBenefits={economicBenefits}
              />
              <ChallengeSection
                challengeName={challengeDetail.name}
                challengeImageSrc={challengeDetail.imgSrc}
                challengeId={challengeDetail.id}
                onClickViewChallenge={navigateToChallengeDetail}
              />
              <OrganizationTypeSection
                titleText={organizationTitle}
                itemText={organizationName}
                showRow={!!organizationName && isOrganizationsActive}
              />
              <OrganizationTypeSection
                titleText={typeTitle}
                itemText={typeName}
                showRow={!!typeName && isTypesActive}
              />
              <OrganizationTypeSection
                titleText={attributeTitle}
                itemText={attributeName}
                showRow={!!attributeName && isAttributesActive}
              />
              <StrategicPrioritiesSection strategies={Object.values(goals)} />
              {isContributionActive &&
                userEmail &&
                !isDraft &&
                (isTimeActive || isBudgetActive || isOtherActive) && (
                  <ContributionSection
                    userEmail={userEmail}
                    contributors={contributors}
                    ownContribution={ownContribution}
                    isTimeActive={isTimeActive}
                    isBudgetActive={isBudgetActive}
                    isOtherActive={isOtherActive}
                    currencySymbol={contributeCurrencySymbol}
                    getContributions={getContributions}
                    onTimeChange={setContributionTime}
                    onBudgetChange={setContributionBudget}
                    onOtherChange={setContributionOther}
                    onSaveContribution={this.handleSaveContribution}
                    onDeleteContribution={this.handleDeleteContribution}
                  />
                )}
              {isPilotActive && (isAdmin || isInnovator) && !isPrivateFlowActive && (
                <KpiSection
                  ideaId={this.id}
                  total={kpis ? kpis.length : 0}
                  list={kpis}
                  onUpdateKpiAction={onUpdateKpiAction}
                  onUpdateKpiSuccess={onUpdateKpiSuccess}
                  onDeleteKpi={onDeleteKpi}
                  onSubmit={onSubmitKpiForm}
                  isFormInUse={isFormInUse}
                />
              )}
              {(investment.seedInvestors.length > 0 ||
                investment.firstRoundInvestors.length > 0 ||
                investment.secondRoundInvestors.length > 0 ||
                investment.thirdRoundInvestors.length > 0) && (
                <InvestmentSection
                  investment={investment}
                  onInvestorClick={navigateToUserProfile}
                  customIdeaTitle={customIdeaTitles.singularTitle}
                />
              )}
              {canSendPilotProjectTeamInvitations && !isPrivateFlowActive && (
                <SendJoinSection
                  isAnonymous={isAnonymous}
                  sectionTitle={i18n.t('IDEA.INITIATIVE_TEAM')}
                  canAddMembers={isAdmin || isInnovator || isPromoter}
                  disableAddMembers={false}
                  inputLabel={i18n.t('IDEA.INVITE_MEMBER_IT')}
                  team={pilotTeam}
                  inputHelpText={i18n.t('IDEA.INVITE_PROMOTERS')}
                  suggestions={suggestions}
                  pendingInvitationsUsers={pendingInvitationsPilotTeamUsers}
                  highlightedUserEmail={promoterEmail}
                  onSendInvitationClick={this.handleSendInvitation}
                  onSearchSuggestions={onSearchSuggestions}
                  onPendingUserClick={navigateToUserProfile}
                  onTeamUserClick={navigateToUserProfile}
                  onPendingUserRemoveClick={this.handleDeleteUserFromTeamInitiative}
                  isSearchingForUser={isSearchingForUser}
                  onRequestJoinIdeaTeam={onRequestJoinIdeaTeam}
                  canJoinTeam={false}
                />
              )}
              {!isPendingValidation && (
                <Fragment>
                  <SendJoinSection
                    isAnonymous={isAnonymous}
                    sectionTitle={i18n.t('IDEA.THE_TEAM', {
                      title: customIdeaTitles.singularTitle || 'idea',
                    })}
                    canAddMembers={isOwner && canSendIdeaTeamInvitations}
                    disableAddMembers={
                      currentStatus !== statusMapper.PENDING_SUPPORT &&
                      currentStatus !== statusMapper.SUPPORTED &&
                      currentStatus !== statusMapper.ROUND_CLOSED
                    }
                    inputLabel={`${i18n.t('IDEA.INVITE_MEMBER_JOIN')} ${title}`}
                    team={team}
                    suggestions={suggestions}
                    pendingInvitationsUsers={pendingInvitationsUsers}
                    highlightedUserEmail={ownerEmail}
                    canJoinTeam={!isOwner}
                    isSearchingForUser={isSearchingForUser}
                    onSendInvitationClick={this.handleSendInvitation}
                    onSearchSuggestions={onSearchSuggestions}
                    onPendingUserClick={navigateToUserProfile}
                    onTeamUserClick={navigateToUserProfile}
                    onPendingUserRemoveClick={this.handleDeleteUserFromTeam}
                    onRequestJoinIdeaTeam={onRequestJoinIdeaTeam}
                    ideaId={this.id}
                    isAlreadyInvited={isAlreadyInvited}
                  />
                  <CommentsSection
                    comments={comments}
                    totalComments={totalComments}
                    onPostComment={this.handlePostComment}
                    postingCommentStatus={postingCommentStatus}
                    onDeleteComment={this.handleDeleteComment}
                    onVoteComment={this.handleVoteComment}
                    onEditComment={this.handleEditComment}
                    onUserClick={navigateToUserProfile}
                    userInvestmentsMap={userInvestmentsMap}
                    currencyIcon={currencyIcon}
                    onShareIdea={() => onShareIdea(this.id)}
                    isAdminOrInnovator={isAdmin || isInnovator}
                    userEmail={userEmail}
                    searchSuggestions={onSearchSuggestions}
                    suggestions={suggestions}
                    resetUserSuggestions={onResetSuggestionList}
                    isPrivate={isPrivateFlowActive}
                    customIdeaTitle={customIdeaTitles.singularTitle}
                    onUpdateComment={this.handleUpdateComment}
                    stateTabIdea={stateTabIdea}
                  />
                </Fragment>
              )}
            </CustomTabPanel>
            {/* FORM TAB */}
            {forms.isStandardActive && isStandardFormVisible && (
              <CustomTabPanel value={tabSelection} index={1}>
                <StandardForm
                  formDataState={forms}
                  onSaveForm={handleStandardFormSave}
                  ideaId={this.id}
                  userEmail={userEmail}
                  currentDomain={currentDomain}
                  getFormValues={this.handleOnGetStandarForm}
                  isIdeaOwner={isOwner || isAdmin}
                  onUploadFile={this.handleFileUpload}
                  onDeleteFile={this.handleDeleteFile}
                  ideaDocuments={documents}
                />
              </CustomTabPanel>
            )}
          </div>
        )}
      </Fragment>
    )
  }
}

IdeaDetail.defaultProps = {
  organizationName: '',
  typeName: [],
  attributeName: [],
  customIdeaTitles: {
    singularTitle: '',
    pluralTitle: '',
  },
}

IdeaDetail.propTypes = {
  getIdea: PropTypes.func.isRequired,
  getIdeaTeam: PropTypes.func.isRequired,
  contributeCurrencySymbol: PropTypes.string.isRequired,
  navigateToChallengeDetail: PropTypes.func.isRequired,
  onReportClick: PropTypes.func.isRequired,
  isSearchingForUser: PropTypes.bool.isRequired,
  hasIdeaLoaded: PropTypes.bool.isRequired,
  hasDomainLoaded: PropTypes.bool.isRequired,
  challengeDetail: PropTypes.shape({
    name: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  challengeStatus: PropTypes.string.isRequired,
  goals: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ownerEmail: PropTypes.string.isRequired,
  promoterEmail: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  videoType: PropTypes.string.isRequired,
  pilotEstimatedQuantity: PropTypes.string.isRequired,
  pilotEstimatedHours: PropTypes.string.isRequired,
  pilotEstimatedTime: PropTypes.string.isRequired,
  pilotEstimatedTimeType: PropTypes.string.isRequired,
  implementEstimatedQuantity: PropTypes.string.isRequired,
  implementEstimatedHours: PropTypes.string.isRequired,
  implementEstimatedTime: PropTypes.string.isRequired,
  implementEstimatedTimeType: PropTypes.string.isRequired,
  economicBenefits: PropTypes.string.isRequired,
  otherBenefits: PropTypes.string.isRequired,
  isOrganizationsActive: PropTypes.bool.isRequired,
  organizationTitle: PropTypes.string.isRequired,
  organizationName: PropTypes.string,
  typeName: PropTypes.array,
  attributeName: PropTypes.array,
  userEmail: PropTypes.string.isRequired,
  contributors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      photoSrc: PropTypes.string,
    }),
  ).isRequired,
  ownContribution: PropTypes.shape({
    time: PropTypes.string,
    budget: PropTypes.string,
    other: PropTypes.string,
  }).isRequired,
  showPendingTeamInvitationSection: PropTypes.bool.isRequired,
  showPendingInnovationTeamInvitationSection: PropTypes.bool.isRequired,
  isProcessingInnovationTeamInvitation: PropTypes.bool.isRequired,
  isProcessingTeamInvitation: PropTypes.bool.isRequired,
  isTimeActive: PropTypes.bool.isRequired,
  isBudgetActive: PropTypes.bool.isRequired,
  isOtherActive: PropTypes.bool.isRequired,
  isContributionActive: PropTypes.bool.isRequired,
  getContributions: PropTypes.func.isRequired,
  setContributionTime: PropTypes.func.isRequired,
  setContributionBudget: PropTypes.func.isRequired,
  setContributionOther: PropTypes.func.isRequired,
  onSaveContribution: PropTypes.func.isRequired,
  onDeleteContribution: PropTypes.func.isRequired,
  ideaStatusStepper: PropTypes.shape({
    approvedLabel: PropTypes.string.isRequired,
    approvedColor: PropTypes.string.isRequired,
    discardedLabel: PropTypes.string.isRequired,
    discardedColor: PropTypes.string.isRequired,
    implementedLabel: PropTypes.string.isRequired,
    implementedColor: PropTypes.string.isRequired,
    pendingSupportLabel: PropTypes.string.isRequired,
    pendingSupportColor: PropTypes.string.isRequired,
    pilotActiveLabel: PropTypes.string.isRequired,
    pilotActiveColor: PropTypes.string.isRequired,
    roundClosedLabel: PropTypes.string.isRequired,
    roundClosedColor: PropTypes.string.isRequired,
    supportedLabel: PropTypes.string.isRequired,
    supportedColor: PropTypes.string.isRequired,
  }).isRequired,
  currentStatus: PropTypes.string.isRequired,
  statusMessageText: PropTypes.string.isRequired,
  statusMessageTitle: PropTypes.string.isRequired,
  investment: PropTypes.shape({
    seedInvestors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    firstRoundInvestors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    secondRoundInvestors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    thirdRoundInvestors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  currencyIcon: PropTypes.string.isRequired,
  getInvestors: PropTypes.func.isRequired,
  onSummaryStepperHelpClick: PropTypes.func.isRequired,
  submitter: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    photo: PropTypes.string,
  }).isRequired,
  promoter: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    photo: PropTypes.string,
  }).isRequired,
  navigateToUserProfile: PropTypes.func.isRequired,
  singleId: PropTypes.string.isRequired,
  totalInvestors: PropTypes.string.isRequired,
  pledged: PropTypes.string.isRequired,
  goal: PropTypes.string.isRequired,
  visits: PropTypes.string.isRequired,
  investInIdea: PropTypes.func.isRequired,
  publishIdea: PropTypes.func.isRequired,
  maxInvestment: PropTypes.number.isRequired,
  minInvestment: PropTypes.number.isRequired,
  userBalance: PropTypes.number.isRequired,
  isInvestmentInProcess: PropTypes.bool.isRequired,
  onAskInvestmentClick: PropTypes.func.isRequired,
  getIsUserInvitedToInnovationIdeaTeam: PropTypes.func.isRequired,
  getIsUserInvitedToIdeaTeam: PropTypes.func.isRequired,
  postAcceptJoinIdeaTeam: PropTypes.func.isRequired,
  postRejectJoinIdeaTeam: PropTypes.func.isRequired,
  postAcceptJoinInnovationTeam: PropTypes.func.isRequired,
  postRejectJoinInnovationTeam: PropTypes.func.isRequired,
  onSearchSuggestions: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    }),
  ).isRequired,
  team: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      isAdmin: PropTypes.bool.isRequired,
      photoSrc: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pilotTeam: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      isAdmin: PropTypes.bool.isRequired,
      photoSrc: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pendingInvitationsUsers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      photoSrc: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pendingInvitationsPilotTeamUsers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      photoSrc: PropTypes.string.isRequired,
    }),
  ).isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      photoSrc: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onPendingUserRemoveClick: PropTypes.func.isRequired,
  onSendInvitationClick: PropTypes.func.isRequired,
  isPendingValidation: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isPromoter: PropTypes.bool.isRequired,
  canSendIdeaTeamInvitations: PropTypes.bool.isRequired,
  canSendPilotProjectTeamInvitations: PropTypes.bool.isRequired,
  onDocumentUpload: PropTypes.func.isRequired,
  isDocumentLoading: PropTypes.bool.isRequired,
  isDraft: PropTypes.bool.isRequired,
  onDocumentDelete: PropTypes.func.isRequired,
  onComponentUnmount: PropTypes.func.isRequired,
  showAcceptInvitationSnackbar: PropTypes.func.isRequired,
  showRejectInvitationSnackbar: PropTypes.func.isRequired,
  isInnovator: PropTypes.bool.isRequired,
  isLoadingPendingIdea: PropTypes.bool.isRequired,
  onPostComment: PropTypes.func.isRequired,
  postingCommentStatus: PropTypes.string.isRequired,
  csrf: PropTypes.string.isRequired,
  getComments: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  totalComments: PropTypes.number.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  onVoteComment: PropTypes.func.isRequired,
  onEditComment: PropTypes.func.isRequired,
  userInvestmentsMap: PropTypes.shape({}).isRequired,
  onShareIdea: PropTypes.func.isRequired,
  onValidatePendingIdeaClick: PropTypes.func.isRequired,
  onRejectPendingIdeaClick: PropTypes.func.isRequired,
  onActivatePilot: PropTypes.func.isRequired,
  onImplementPilot: PropTypes.func.isRequired,
  onTransferLeader: PropTypes.func.isRequired,
  showNoImageDraftSnackbar: PropTypes.func.isRequired,
  onResetSuggestionList: PropTypes.func.isRequired,
  isPrivateFlowActive: PropTypes.bool.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string,
  }),
  currentDomain: PropTypes.string.isRequired,
}

export default withStyles(style)(IdeaDetail)
