import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    text: {
      color: theme.palette.common.white,
      width: '100%',
      margin: '0',
      overflowX: 'hidden',
      fontWeight: 'bold',
      textShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)',
    },
  })

export default styles
