const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  autosuggestionContainer: {
    width: '70%',
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    '& > button:first-child': {
      marginRight: theme.spacing(1),
    },
    '& > button:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
  helpText: {
    paddingBottom: theme.spacing(2),
  },
})

export default styles
