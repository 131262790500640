import React, { useEffect, useState } from 'react'
import { Card, CardContent, Grid, TextField, Typography } from '@material-ui/core'
import i18n from 'support/i18n'
import { TextFieldStyled, initFile } from './utils'
import UploadFile from './UploadFile'

export const MarketInformation = ({
  onChangeValue,
  marketInfo,
  isDisabled,
  anyError,
  onFileSubmit,
  onFileDelete,
  standardFile,
  ideaDocuments,
}) => {
  const {
    mainCompetitors,
    mainCustomers,
    acceleratorBelong,
    seedCapital,
    whoFinances,
    // file,
  } = marketInfo

  const { isFileLoading, fileData } = standardFile
  const [fileUpload, setFileUpload] = useState(fileData)

  const onChange = (event, field) => {
    const { value } = event.target
    if (['mainCompetitors', 'mainCustomers', 'acceleratorBelong', 'whoFinances'].includes(field)) {
      if (value?.trim().length > 0) {
        const sanitized = value.replace(/[^\w\s.,ñ]/gi, '')
        return onChangeValue('marketInfo', { ...marketInfo, [field]: sanitized })
      }
    }

    const data = { ...marketInfo, [field]: value }
    return onChangeValue('marketInfo', data)
  }

  const handleSubmitFile = values => {
    onChangeValue('marketInfo', { ...marketInfo, file: values })
  }

  useEffect(() => {
    if (!fileData) setFileUpload(initFile)

    const fileFound = ideaDocuments?.find(item => item.id === fileData?.id)

    setFileUpload(!fileFound ? initFile : fileFound)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData])

  useEffect(() => {
    if (marketInfo.file !== fileUpload) {
      onChangeValue('marketInfo', { ...marketInfo, file: fileUpload })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketInfo])

  return (
    <Grid item xs={12}>
      <Card
        variant="elevation"
        elevation={3}
        style={{
          borderRadius: 8,
        }}
      >
        <CardContent spacing={2}>
          <Typography variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
            {i18n.t('FORMS.STANDARD_FORM.FORM.MARKET_INFO')}
          </Typography>
          <TextField
            id="standard-full-width"
            label={i18n.t('FORMS.STANDARD_FORM.FORM.MAIN_COMPETITORS')}
            margin="normal"
            required
            fullWidth
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'mainCompetitors')}
            value={mainCompetitors}
            disabled={isDisabled}
            error={anyError && 'mainCompetitors' in anyError}
            helperText={anyError?.mainCompetitors}
          />
          <TextField
            id="standard-full-width"
            fullWidth
            required
            label={i18n.t('FORMS.STANDARD_FORM.FORM.MAIN_CUSTOMERS')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'mainCustomers')}
            value={mainCustomers}
            disabled={isDisabled}
            error={anyError && 'mainCustomers' in anyError}
            helperText={anyError?.mainCustomers}
          />
          {/* FILES UPLOAD */}
          <Grid item xs={12} style={{ marginTop: 16, marginBottom: 8 }}>
            <Typography variant="subtitle2" style={{ fontWeight: 'bold', marginBottom: 5 }}>
              {i18n.t('FORMS.STANDARD_FORM.FORM.FILES')}
            </Typography>
            <UploadFile
              marketFile={fileUpload || initFile}
              onSubmitFile={onFileSubmit}
              onDeleteFile={onFileDelete}
              isDisabled={isDisabled}
              isFileLoading={isFileLoading}
              toSubmitInfo={handleSubmitFile}
            />
          </Grid>
          <TextField
            required
            id="standard-full-width"
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.ACCELERATOR_BELONG')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'acceleratorBelong')}
            value={acceleratorBelong}
            disabled={isDisabled}
            error={anyError && 'acceleratorBelong' in anyError}
            helperText={anyError?.acceleratorBelong}
          />
          <TextField
            required
            id="standard-full-width"
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.SEED_CAPITAL')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'seedCapital')}
            value={seedCapital}
            disabled={isDisabled}
            error={anyError && 'seedCapital' in anyError}
            helperText={anyError?.seedCapital}
          />
          <TextField
            id="standard-full-width"
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.WHO_FINANCES')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'whoFinances')}
            value={whoFinances}
            disabled={isDisabled}
            error={anyError && 'whoFinances' in anyError}
            helperText={anyError?.whoFinances}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}
