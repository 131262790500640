import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    logoBox: {
      width: '100px',
    },
    logo: {
      height: '58px',
    },
  })

export default styles
