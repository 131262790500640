import React, { useState } from 'react'
import { Box, Collapse, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  container: props => ({
    backgroundColor: props.backgroundColor,
    marginBottom: 5,
  }),
  text: {
    color: props => props.color,
    padding: '8px 0',
    fontWeight: 'bold',
  },
  icon: {
    color: props => props.color,
    display: 'flex',
    opacity: 0.9,
    padding: '7px 0',
    fontSize: 22,
    marginRight: 12,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 8,
    paddingLeft: 16,
  },
})

const Information = ({ content, isVisible, type = 'info', instanceColors }) => {
  const [open, setOpen] = useState(isVisible)

  const COLORS = {
    instance: {
      backgroundColor: instanceColors?.primary,
      color: instanceColors?.headerText,
      icon: instanceColors?.headerText,
    },
    info: { backgroundColor: 'rgb(232, 244, 253)', color: 'rgb(13, 60, 97)', icon: '#2196f3' },
    warning: { backgroundColor: 'rgb(255, 244, 229)', color: 'rgb(102, 60, 0)', icon: '#ff9800' },
    error: { backgroundColor: 'rgb(253, 236, 234)', color: 'rgb(97, 26, 21)', icon: '#f44336' },
    success: { backgroundColor: 'rgb(237, 247, 237)', color: 'rgb(30, 70, 32)', icon: '#4caf50' },
  }

  const classes = useStyles(COLORS[type])

  const ICONS = {
    instance: <InfoOutlinedIcon className={classes.icon} />,
    info: <InfoOutlinedIcon className={classes.icon} />,
    warning: <ReportProblemOutlinedIcon className={classes.icon} />,
    error: <ErrorOutlineIcon className={classes.icon} />,
    success: <CheckCircleOutlinedIcon className={classes.icon} />,
  }

  return (
    <Collapse in={open}>
      <Paper variant="outlined" className={`${classes.container} ${classes.text}`}>
        <Box p={2}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={11}>
              <Box display="flex" direction="row" alignItems="center">
                {ICONS[type]}
                <Typography>{content}</Typography>
              </Box>
            </Grid>
            <Grid item className={classes.action}>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Collapse>
  )
}

export default Information
