import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Paper, withStyles } from '@material-ui/core'
import NxtSlider from 'components/nxt_slider/View'
import i18n from 'support/i18n'
import {
  SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED,
  SET_ADVANCED_APPROVED_IDEAS,
  SET_ADVANCED_IMPLEMENTED_IDEAS,
  SET_ADVANCED_SUPPORT_IDEAS,
} from 'actions/sync/challenge_steps/Constants'
import styles from './View.Style'
import SliderModifiedPanel from './components/slider_modified_panel/View'
import { buildMarkers } from './View.Utils'

class AdvancedMode extends Component {
  constructor(props) {
    super(props)
    const { approveMult, implementMult, supportMult, currentGoal, defaultValues } = this.props
    this.state = {
      selectedIdeaGoal: currentGoal,
      selectedForSupported: supportMult,
      selectedForApproved: approveMult,
      selectedForImplemented: implementMult,
    }
    this.currentMarks = buildMarkers(
      defaultValues.currentGoal,
      (defaultValues.currentGoal * 25) / 100,
      true,
    )
    this.supportMarks = buildMarkers(defaultValues.supportMult, 0, false, '%')
    this.approveMarks = buildMarkers(defaultValues.approveMult, 0, false, '%')
    this.implementMarks = buildMarkers(defaultValues.implementMult, 0, false, '%')
    this.defaultCurrentGoal = defaultValues.currentGoal
    this.defaultSupportMult = defaultValues.supportMult
    this.defaultApproveMult = defaultValues.approveMult
    this.defaultImplementMult = defaultValues.implementMult
    this.minCurrentGoal = (defaultValues.currentGoal * 25) / 100
    this.maxCurrentGoal = defaultValues.currentGoal * 2
    this.maxSupported = defaultValues.supportMult * 2
    this.maxApproved = defaultValues.approveMult * 2
    this.maxImplementMult = defaultValues.implementMult * 2
    this.setPercentage = this.setPercentage.bind(this)
  }

  setPercentage(type, amount) {
    const { onSliderChangeCommitted } = this.props
    switch (type) {
      case SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED:
        onSliderChangeCommitted(SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED, amount)
        this.setState({
          selectedIdeaGoal: parseInt(amount, 10),
        })
        break
      case SET_ADVANCED_SUPPORT_IDEAS:
        onSliderChangeCommitted(SET_ADVANCED_SUPPORT_IDEAS, amount)
        this.setState({
          selectedForSupported: parseInt(amount, 10),
        })
        break
      case SET_ADVANCED_APPROVED_IDEAS:
        onSliderChangeCommitted(SET_ADVANCED_APPROVED_IDEAS, amount)
        this.setState({
          selectedForApproved: parseInt(amount, 10),
        })
        break
      case SET_ADVANCED_IMPLEMENTED_IDEAS:
        onSliderChangeCommitted(SET_ADVANCED_IMPLEMENTED_IDEAS, amount)
        this.setState({
          selectedForImplemented: parseInt(amount, 10),
        })
        break
      default:
        break
    }
  }

  render() {
    const {
      selectedIdeaGoal,
      selectedForSupported,
      selectedForApproved,
      selectedForImplemented,
    } = this.state

    const { classes, approveMult, implementMult, supportMult, currentGoal, customIdeaTitles } = this.props

    return (
      <Paper square className={classes.root}>
        <h3>{i18n.t('CHALLENGES.CUSTOM_SETTINGS')}</h3>
        <div className={classes.mainContent}>
          <h5>{i18n.t('CHALLENGES.IDEA_GOAL', { title: customIdeaTitles.pluralTitle || "ideas" })}</h5>
          <Paper square={false} className={classes.simplePaper} elevation={3}>
            <p>{i18n.t('CHALLENGES.IDEA_GOAL_INFO')}</p>
            <div className={classes.sliderContainer}>
              <div className={classes.slider}>
                <NxtSlider
                  marks={this.currentMarks}
                  step={null}
                  max={this.maxCurrentGoal}
                  min={this.minCurrentGoal}
                  minLabel="25"
                  maxLabel="200"
                  value={currentGoal}
                  onChangeCommitted={(event, value) =>
                    this.setPercentage(SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED, value)
                  }
                />
                <SliderModifiedPanel
                  currentValue={`${i18n.toNumber(this.defaultCurrentGoal, { precision: 0 })}`}
                  modifiedValue={`${i18n.toNumber(selectedIdeaGoal, { precision: 0 })}`}
                />
              </div>
              <div className={classes.indicator}>
                <span>{`${selectedIdeaGoal * 200 / this.maxCurrentGoal}%`}</span>
              </div>
            </div>
          </Paper>
          <div className={classes.roiSection}>
            <h5>{i18n.t('CHALLENGES.IDEA_STATUS_ROI')}</h5>
            <Paper square={false} className={classes.simplePaper} elevation={3}>
              <p>{i18n.t('CHALLENGES.FOR_SUPPORTED', { title: customIdeaTitles.pluralTitle || "ideas" })}</p>
              <div className={classes.sliderContainer}>
                <div className={classes.slider}>
                  <NxtSlider
                    marks={this.supportMarks}
                    step={null}
                    max={this.maxSupported}
                    minLabel="0"
                    maxLabel="200"
                    value={supportMult}
                    onChangeCommitted={(event, value) =>
                      this.setPercentage(SET_ADVANCED_SUPPORT_IDEAS, value)
                    }
                  />
                  <SliderModifiedPanel
                    currentValue={`${this.defaultSupportMult}%`}
                    modifiedValue={`${selectedForSupported}%`}
                  />
                </div>
                <div className={classes.indicator}>
                  <span>{`${selectedForSupported * 200 / this.maxSupported}%`}</span>
                </div>
              </div>
              <p>{i18n.t('CHALLENGES.FOR_APPROVED', { title: customIdeaTitles.pluralTitle || "ideas" })}</p>
              <div className={classes.sliderContainer}>
                <div className={classes.slider}>
                  <NxtSlider
                    marks={this.approveMarks}
                    step={null}
                    max={this.maxApproved}
                    minLabel="0"
                    maxLabel="200"
                    value={approveMult}
                    onChangeCommitted={(event, value) =>
                      this.setPercentage(SET_ADVANCED_APPROVED_IDEAS, value)
                    }
                  />
                  <SliderModifiedPanel
                    currentValue={`${this.defaultApproveMult}%`}
                    modifiedValue={`${selectedForApproved}%`}
                  />
                </div>
                <div className={classes.indicator}>
                  <span>{`${selectedForApproved * 200 / this.maxApproved}%`}</span>
                </div>
              </div>
              <p>{i18n.t('CHALLENGES.FOR_IMPLEMENTED', { title: customIdeaTitles.pluralTitle || "ideas" })}</p>
              <div className={classes.sliderContainer}>
                <div className={classes.slider}>
                  <NxtSlider
                    marks={this.implementMarks}
                    step={null}
                    max={this.maxImplementMult}
                    minLabel="0"
                    maxLabel="200"
                    value={implementMult}
                    onChangeCommitted={(event, value) =>
                      this.setPercentage(SET_ADVANCED_IMPLEMENTED_IDEAS, value)
                    }
                  />
                  <SliderModifiedPanel
                    currentValue={`${this.defaultImplementMult}%`}
                    modifiedValue={`${selectedForImplemented}%`}
                  />
                </div>
                <div className={classes.indicator}>
                  <span>{`${selectedForImplemented * 200 / this.maxImplementMult}%`}</span>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </Paper>
    )
  }
}
AdvancedMode.propTypes = {
  onSliderChangeCommitted: PropTypes.func.isRequired,
  approveMult: PropTypes.number.isRequired,
  implementMult: PropTypes.number.isRequired,
  supportMult: PropTypes.number.isRequired,
  currentGoal: PropTypes.number.isRequired,
  defaultValues: PropTypes.shape({}).isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}
export default withStyles(styles)(AdvancedMode)
