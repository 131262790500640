import React from 'react'
import { connect } from 'react-redux'
import { getStatusSelectList } from 'pages/explore/View.Utils'
import { getActiveAttributes } from 'services/attributes'
import i18n from '../../../support/i18n'
import { applyFilterService, applySearchService } from '../../../services/ideas'
import { getChallengeById, getChallengeVisitorsById } from '../../../services/challenges'
import { getActiveOrganizations } from '../../../services/organizations'
import { getActiveTypes } from '../../../services/types'
import Navigation from '../../../support/navigation'
import { FIRST_LIMIT_CONSTANT } from '../../../common_constants/Ideas'
import {
  showPopupDialog,
  showPopupSuccessSnackbar,
  showPopupErrorSnackbar,
} from '../../../support/popup_dialogs'
import { getChallengeDetailVideoType } from '../../../services/videos'
import { hideDialog } from '../../../support/popup_dialogs/modal_alert_controller/ModalActions'
import ConfirmDeleteModal from './components/dialogs/ConfirmDeleteContainer'
import ConfirmFinishModal from './components/dialogs/ConfirmFinishContainer'
import View from './View'
import ImageViewDialog from '../form/components/preview_launch/components/dialogs/ImageViewDialog/View'

const mapStateToProps = state => {
  return {
    challenge: state.data.challenge.challengeById,
    ideasChallenge: state.data.challenge.ideas,
    totalIdeasChallenge: state.data.challenge.totalIdeas,
    challengesLoading: state.data.challenge.challengesLoading,
    challengeIdeasLoading: state.data.challenge.challengeIdeasLoading,
    hasMoreIdeasToLoad: state.data.idea.hasMore,
    isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
    isTypesActive: state.data.domain.domainConfiguration.isTypesActive,
    isAttributesActive: state.data.domain.domainConfiguration.isAttributesActive,
    organizationsName: state.data.domain.domainConfiguration.organizationsName,
    organizations: state.data.domain.organizations,
    ideaStatusStepper: state.data.domain.domainConfiguration.ideaStatusStepper,
    typesName: state.data.domain.domainConfiguration.typesName,
    types: state.data.domain.types,
    attributesName: state.data.domain.domainConfiguration.attributesName,
    attributes: state.data.domain.attributes,
    isAdmin: state.data.user.admin,
    isInnovator: state.data.user.isInnovator,
    statusList: getStatusSelectList(state.data.domain.domainConfiguration.ideaStatusStepper),
    isPrivateFlowActive: state.data.domain.domainConfiguration.isPrivateFlowActive,
    customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    applyFilter: (
      challenge,
      organizations,
      status,
      types,
      attributes,
      limit = FIRST_LIMIT_CONSTANT,
      offset = 0,
    ) => {
      dispatch(applyFilterService({ challenge, organizations, status, types, attributes, limit, offset }))
    },
    applySearch: search => {
      dispatch(applySearchService({ search, challengeId: ownProps.match.params.id }))
    },
    getChallengeById: (id, limit = 2, offset = 0) => {
      dispatch(getChallengeById(id, limit, offset))
    },
    getOrganization: () => {
      dispatch(getActiveOrganizations())
    },
    getTypes: () => {
      dispatch(getActiveTypes())
    },
    getAttributes: () => dispatch(getActiveAttributes()),
    navigateToIdeaDetail: id => {
      dispatch(Navigation.navigateToIdea(id))
    },
    navigateToNewIdea: id => {
      dispatch(Navigation.navigateToNewIdea(id))
    },
    onEditClick: id => {
      dispatch(Navigation.navigateToChallengeForm(id))
    },
    navigateToUserProfile: userEmail => {
      Navigation.navigateToUserProfile(userEmail)
    },
    onDeleteClick: id => {
      showPopupDialog(<ConfirmDeleteModal id={id} />, dispatch)
    },
    onFinishClick: id => {
      showPopupDialog(<ConfirmFinishModal id={id} />, dispatch)
    },
    onVisitorsClick: id => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('CHALLENGES.DOWNLOAD_CHALLENGE_VISITORS_OK'), dispatch)
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('CHALLENGES.DOWNLOAD_CHALLENGE_VISITORS_KO'), dispatch)
      }
      dispatch(getChallengeVisitorsById(id, onSuccess, onFailure))
    },
    onClickToShowRewardImage: src => {
      const onClose = () => dispatch(hideDialog())
      showPopupDialog(<ImageViewDialog src={src} onClose={onClose} />, dispatch)
    },
    getChallengeVideoType: url => dispatch(getChallengeDetailVideoType(url)),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
