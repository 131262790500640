import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { NxtExpandedCard } from 'components'
import imgDefault from 'img/nxt-inverted-image.png'
import i18n from 'support/i18n'
import styles from './View.Style'

class PendingValidation extends Component {
  componentDidMount() {
    const { getPendingIdeas } = this.props
    getPendingIdeas()
  }

  render() {
    const { classes, navigateToIdea, ideas, customIdeaTitles } = this.props

    return (
      <Fragment>
        <div className={classes.top}>
          <h1 style={{ textTransform: "capitalize" }}>
            <i className="icon-idea" /> {i18n.t('PENDING_VALIDATION.TITLE', { title: customIdeaTitles.pluralTitle || "ideas" })}
          </h1>
        </div>
        <div className={classes.middle}>
          <div className={classes.container}>
            {ideas.map(card => {
              const {
                anonymous,
                challenged,
                id,
                images,
                investors,
                ownerName,
                sponsored,
                status,
                title,
              } = card
              const [img] = images
              return (
                <NxtExpandedCard
                  isAnonymous={anonymous}
                  altTextBlock={`${investors} ${i18n.t('INVESTORS')}`}
                  challenged={challenged}
                  defaultSrc={imgDefault}
                  height="536px"
                  id={id}
                  img={typeof img !== 'undefined' ? img.url : ''}
                  investors={investors}
                  key={id}
                  onMainButtonClick={navigateToIdea}
                  sponsored={sponsored}
                  status={status}
                  submittedBy={ownerName}
                  title={title}
                  buttonText={i18n.t('GO_TO_IDEA', { title: customIdeaTitles.singularTitle || "idea" })}
                />
              )
            })}
          </div>
        </div>
      </Fragment>
    )
  }
}

PendingValidation.propTypes = {
  getPendingIdeas: PropTypes.func.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  ideas: PropTypes.arrayOf(
    PropTypes.shape({
      challenged: PropTypes.bool,
      textWithoutTags: PropTypes.string,
      id: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          bucket: PropTypes.string,
          key: PropTypes.string,
          name: PropTypes.string,
          size: PropTypes.number,
          url: PropTypes.string,
        }),
      ),
      investors: PropTypes.string,
      goal: PropTypes.string,
      pledged: PropTypes.string,
      profit: PropTypes.string,
      sponsored: PropTypes.bool,
      ownerName: PropTypes.string,
      title: PropTypes.string,
      created: PropTypes.number,
      countdownEnds: PropTypes.number,
      status: PropTypes.string,
    }),
  ).isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(PendingValidation)
