import React from 'react'
import { connect } from 'react-redux'
import debounce from 'debounce'
import {
  deleteFileStandardForm,
  getStandardForm,
  postFileStandardForm,
  postStandardForm,
} from 'services/standardForm'
import {
  showPopupDialog,
  showPopupSuccessSnackbar,
  showPopupErrorSnackbar,
  showPopupWarningSnackbar,
} from '../../../support/popup_dialogs'
import { getUserByEmail } from '../../../services/challenge_steps'
import {
  getApprovedReasons,
  getRejectedReasons,
  getTotalInvestments,
  getVisitsIdeas,
} from '../../../services/domain'
import { requestJoinIdeaTeam, visitIdea } from '../../../services/ideas'
import { hideDialog } from '../../../support/popup_dialogs/modal_alert_controller/ModalActions'
import { getBase64Split, transformVideoType } from '../../../support/utils/file'
import { MAX_SIZE_DOCUMENT, MAX_DOCUMENT_BYTES } from '../../../common_constants/Ideas'
import { sortByProp, reduceToProp } from '../../../support/utils/array'
import { generateRequestCsrf } from '../../../support/utils/auth'
import { resetSponsorSuggestions } from '../../../actions/sync/challenge_steps'
import {
  setContributionTime,
  setContributionBudget,
  setContributionOther,
  resetIdeaState,
} from '../../../actions/sync/create_idea_form'
import { getIdeaVideoType } from '../../../services/videos'
import {
  getIdea,
  getIdeaContributions,
  saveContribution,
  deleteContribution,
  getIdeaInvestors,
  postInvestIdea,
  getIsUserInvitedToInnovationIdeaTeam,
  getIsUserInvitedToIdeaTeam,
  postAcceptJoinIdeaTeam,
  postRejectJoinIdeaTeam,
  postAcceptJoinInnovationTeam,
  postRejectJoinInnovationTeam,
  postSendInvitations,
  getIdeaTeam,
  reportIdea,
  deleteUserFromTeam,
  postIdeaDocument,
  deleteIdeaDocument,
  postComment,
  getIdeaComments,
  deleteComment,
  voteComment,
  putComment,
  postValidateIdea,
  deleteIdea,
  postActivatePilotProject,
  postImplementPilotProject,
  postTransferPromoter,
  postPublishDraft,
  getIsTeamRequestActive,
  getKpis,
  updateKpiSuccess,
  newKpi,
  updateKpi,
} from '../../../services/create_idea'
import Navigation from '../../../support/navigation'
import i18n from '../../../support/i18n'
import ConfirmSubmitContainer from './components/report_idea_section/components/dialogs/ConfirmSubmitContainer'
import AskInvestmentDialog from './components/summary_section/components/ask_investments_section/dialogs/AskInvestmentDialogContainer'
import SuccessSubmitModal from './components/report_idea_section/components/dialogs/SuccessSubmitContainer'
import ConfirmDeleteKpiModal from './components/kpi_section/dialogs/ConfirmDeleteContainer'
import ConfirmDeleteIdeaModal from './components/dialogs/ConfirmDeleteContainer'
import DiscardView from './components/dialogs/components/discard_view'
import ApproveView from './components/dialogs/components/approve_view'
import SendInvitationsContainer from './components/comments_section/SendInvitationsContainer'
import View from './View'

const mapStateToProps = state => {
  let videoSrc = ''
  const sortedType = [...state.data.createIdea.type]
  const sortedAttribute = [...state.data.createIdea.attribute]

  if (state.data.createIdea.videos.externalVideo) {
    videoSrc = state.data.createIdea.videos.externalVideo
  } else if (state.data.createIdea.videos.fileVideoUrl) {
    videoSrc = `${process.env.REACT_APP_BACKEND_BASE_URL}${
      state.data.createIdea.videos.fileVideoUrl
    }?_nxt_csrf=${generateRequestCsrf(state.auth.csrf)}`
  }

  sortByProp(sortedType, 'order')
  sortByProp(sortedAttribute, 'order')

  return {
    hasIdeaLoaded: state.data.createIdea.hasLoaded,
    hasDomainLoaded: state.data.domain.dataLoaded,
    challengeDetail: state.data.createIdea.challenge,
    challengeStatus: state.data.createIdea.challengeStatus,
    goals: state.data.createIdea.goals,
    pilotEstimatedQuantity: state.data.createIdea.pilotEstimatedQuantity,
    pilotEstimatedHours: state.data.createIdea.pilotEstimatedHours,
    pilotEstimatedTime: state.data.createIdea.pilotEstimatedTime,
    pilotEstimatedTimeType: state.data.createIdea.pilotEstimatedTimeType,
    implementEstimatedQuantity: state.data.createIdea.implementEstimatedQuantity,
    implementEstimatedHours: state.data.createIdea.implementEstimatedHours,
    implementEstimatedTime: state.data.createIdea.implementEstimatedTime,
    implementEstimatedTimeType: state.data.createIdea.implementEstimatedTimeType,
    economicBenefits: state.data.createIdea.economicBenefits,
    otherBenefits: state.data.createIdea.otherBenefits,
    isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
    organizationTitle: state.data.domain.domainConfiguration.organizationsName,
    organizationName: state.data.createIdea.organization.name,
    isTypesActive: state.data.domain.domainConfiguration.isTypesActive,
    typeTitle: state.data.domain.domainConfiguration.typesName,
    typeName: reduceToProp(sortedType, 'name'),
    isAttributesActive: state.data.domain.domainConfiguration.isAttributesActive,
    attributeTitle: state.data.domain.domainConfiguration.attributesName,
    attributeName: reduceToProp(sortedAttribute, 'name'),
    contributors: state.data.createIdea.contributors,
    ownContribution: state.data.createIdea.ownContribution,
    isBudgetActive: state.data.domain.domainConfiguration.isBudgetActive,
    isTimeActive: state.data.domain.domainConfiguration.isTimeActive,
    isOtherActive: state.data.domain.domainConfiguration.isOtherActive,
    isContributionActive: state.data.domain.domainConfiguration.isContributionActive,
    userEmail: state.data.user.userEmail,
    ideaStatusStepper: state.data.domain.domainConfiguration.ideaStatusStepper,
    isPendingValidation: state.data.createIdea.isPendingValidation,
    currentStatus: state.data.createIdea.status,
    statusMessageText: state.data.createIdea.statusMessageText,
    statusMessageTitle: state.data.createIdea.statusMessageTitle,
    investment: state.data.createIdea.investment,
    contributeCurrencySymbol: state.data.domain.domainConfiguration.contributeCurrencySymbol,
    currencyIcon: state.data.domain.domainConfiguration.currencyIcon,
    submitter: state.data.createIdea.submitter,
    promoter: state.data.createIdea.promoter,
    maxInvestment: state.data.createIdea.maxInvestment,
    minInvestment: state.data.createIdea.minInvestment,
    isInvestmentInProcess: state.data.createIdea.isInvestmentInProcess,
    userBalance: parseInt(state.data.user.balance || 0, 10),
    createdDate: state.data.createIdea.createdDate,
    endDate: state.data.createIdea.endDate,
    singleId: state.data.createIdea.singleId,
    totalInvestors: state.data.createIdea.totalInvestors,
    pledged: state.data.createIdea.pledged,
    goal: state.data.createIdea.goal,
    visits: state.data.createIdea.visits,
    documents: state.data.createIdea.documents,
    title: state.data.createIdea.title,
    description: state.data.createIdea.description,
    videoSrc,
    videoType: transformVideoType(state.data.createIdea.videos.fileVideoType),
    images: state.data.createIdea.images,
    isAlreadyInvited: state.data.createIdea.isAlreadyInvited,
    showPendingTeamInvitationSection: state.data.createIdea.showPendingTeamInvitationSection,
    showPendingInnovationTeamInvitationSection:
      state.data.createIdea.showPendingInnovationTeamInvitationSection,
    isProcessingInnovationTeamInvitation:
      state.data.createIdea.isProcessingInnovationTeamInvitation,
    isProcessingTeamInvitation: state.data.createIdea.isProcessingTeamInvitation,
    isDocumentLoading: state.data.createIdea.isDocumentLoading,
    team: state.data.createIdea.team,
    pilotTeam: state.data.createIdea.pilotTeam,
    pendingInvitationsUsers: state.data.createIdea.pendingInvitationsUsers,
    pendingInvitationsPilotTeamUsers: state.data.createIdea.pendingInvitationsPilotTeamUsers,
    suggestions: state.data.challengeSteps.sponsorSuggestions,
    ownerEmail: state.data.createIdea.owner,
    promoterEmail: state.data.createIdea.promoter.email,
    isOwner: state.data.createIdea.owner === state.data.user.userEmail,
    isPromoter: state.data.createIdea.promoter.email === state.data.user.userEmail,
    isAdmin: state.data.user.admin,
    isInnovator: state.data.user.isInnovator,
    canSendPilotProjectTeamInvitations: state.data.createIdea.canSendPilotProjectTeamInvitations,
    canSendIdeaTeamInvitations: state.data.createIdea.canSendIdeaTeamInvitations,
    isSearchingForUser: state.data.challengeSteps.sponsorStarted,
    postingCommentStatus: state.data.createIdea.postingCommentStatus,
    comments: state.data.createIdea.comments,
    totalComments: state.data.createIdea.totalComments,
    userInvestmentsMap: state.data.createIdea.userInvestmentsMap,
    rejectedReasons: state.data.domain.rejectedReasons,
    approvedReasons: state.data.domain.approvedReasons,
    isLoadingPendingIdea: state.data.createIdea.isLoadingPendingIdea,
    isDraft: state.data.createIdea.isDraft,
    isAnonymous: state.data.createIdea.isAnonymous,
    kpis: state.data.createIdea.kpis,
    isFormInUse: state.data.createIdea.isFormInUse,
    csrf: state.auth.csrf,
    isPrivateFlowActive: state.data.domain.domainConfiguration.isPrivateFlowActive,
    customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles,
    currentDomain: state.data.domain.currentDomain,
    forms: state.data.forms,
    instanceColors: state.data.domain.config.defaultColors,
  }
}

const mapDispatchToProps = dispatch => {
  const onFailureKpis = () => {
    showPopupErrorSnackbar(i18n.t('ERROR'), dispatch)
  }

  return {
    // StandarForm
    handleStandardFormSave: values => {
      const onSuccess = response => {
        if (response?.messageFromBackend?.length > 0) {
          return showPopupWarningSnackbar(response.messageFromBackend, dispatch)
        }

        return showPopupSuccessSnackbar(i18n.t('FORMS.ALERTS.ON_SUCCESS_FORM'), dispatch)
      }

      const onFailure = () => {
        showPopupWarningSnackbar(i18n.t('FORMS.ALERTS.ON_FAILURE_FORM'), dispatch)
      }

      dispatch(postStandardForm(values, onSuccess, onFailure))
    },
    handleGetStandardForm: values => {
      dispatch(getStandardForm(values))
    },
    onFileUpload: (ideaId, file) => {
      const onSuccess = () => {
        return showPopupSuccessSnackbar('FILE UPLOADED', dispatch)
      }
      const onFailure = () => {
        showPopupWarningSnackbar('ERROR FILE UPLOAD', dispatch)
      }

      getBase64Split(
        file,
        (fileName, base64File) => {
          dispatch(postFileStandardForm(ideaId, fileName, base64File, onSuccess, onFailure))
        },
        true,
      )
    },
    onFileDelete: (ideaId, fileKey) => {
      const onFailure = () => {
        showPopupErrorSnackbar('REMOVE FILE ERROR', dispatch)
      }

      dispatch(deleteFileStandardForm(ideaId, fileKey, onFailure))
    },
    // Idea
    onVisitIdea: ideaId => {
      const onSuccess = () => {
        dispatch(getVisitsIdeas())
      }
      dispatch(visitIdea(ideaId, onSuccess))
    },
    onComponentUnmount: () => dispatch(resetIdeaState()),
    onValidatePendingIdeaClick: ideaId => {
      const onSuccess = () => {
        dispatch(getIdea(ideaId))
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('IDEA_INNOVATION_TEAM.TEAM_JOIN_KO'), dispatch)
      }
      dispatch(postValidateIdea(ideaId, onSuccess, onFailure))
    },
    onRejectPendingIdeaClick: ideaId => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.IDEA_DELETED'), dispatch)
          Navigation.navigateToExplore()
        }
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ERROR_DELETING'), dispatch)
        }
        dispatch(deleteIdea(ideaId, onSuccess, onFailure))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('CONFIRMATION_MESSAGE')}
          description={i18n.t('EXPLORE_IDEA.DELETE_IDEA')}
        />,
        dispatch,
      )
    },
    showAcceptInvitationSnackbar: () => {
      showPopupSuccessSnackbar(i18n.t('IDEA.ACTION.INVITE_TEAM_ACCEPT'), dispatch)
    },
    showRejectInvitationSnackbar: () => {
      showPopupErrorSnackbar(i18n.t('IDEA.ACTION.INVITE_TEAM_REJECT'), dispatch)
    },
    postAcceptJoinInnovationTeam: ideaId => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('IDEA_INNOVATION_TEAM.TEAM_JOIN_OK'), dispatch)
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('IDEA_INNOVATION_TEAM.TEAM_JOIN_KO'), dispatch)
      }
      dispatch(postAcceptJoinInnovationTeam(ideaId, onSuccess, onFailure))
    },
    postRejectJoinInnovationTeam: ideaId => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('TEAM_REJECT_OK'), dispatch)
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('IDEA_INNOVATION_TEAM.TEAM_JOIN_KO'), dispatch)
      }
      dispatch(postRejectJoinInnovationTeam(ideaId, onSuccess, onFailure))
    },
    postAcceptJoinIdeaTeam: ideaId => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('IDEA_TEAM.TEAM_JOIN_OK'), dispatch)
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('IDEA_TEAM.TEAM_JOIN_KO'), dispatch)
      }
      dispatch(postAcceptJoinIdeaTeam(ideaId, onSuccess, onFailure))
    },
    postRejectJoinIdeaTeam: ideaId => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('TEAM_REJECT_OK'), dispatch)
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('IDEA_TEAM.TEAM_JOIN_KO'), dispatch)
      }
      dispatch(postRejectJoinIdeaTeam(ideaId, onSuccess, onFailure))
    },
    getIsUserInvitedToInnovationIdeaTeam: ideaId => {
      dispatch(getIsUserInvitedToInnovationIdeaTeam(ideaId))
    },
    getIsUserInvitedToIdeaTeam: ideaId => {
      dispatch(getIsUserInvitedToIdeaTeam(ideaId))
    },
    getIdeaTeam: ideaId => {
      dispatch(getIdeaTeam(ideaId))
    },
    onSendInvitationClick: (email, ideaId) => {
      const onSuccess = () => {
        dispatch(getIdeaTeam(ideaId))
        showPopupSuccessSnackbar(i18n.t('IDEA.INVITE_SUCCESS_SEND_EMAIL'), dispatch)
      }
      const onFailure = () =>
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ERROR_INVESTING'), dispatch)

      dispatch(postSendInvitations(email, ideaId, onSuccess, onFailure))
    },
    onSearchSuggestions: value => {
      dispatch(getUserByEmail(value))
    },
    onAskInvestmentClick: id => {
      showPopupDialog(<AskInvestmentDialog ideaId={id} />, dispatch)
    },
    investInIdea: (id, investmentAmount) => {
      const onSuccess = () => {
        dispatch(getIdeaInvestors(id))
        dispatch(getTotalInvestments())
        showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.INVEST_RECEIVED'), dispatch)
      }
      const onFailure = () =>
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ERROR_INVESTING'), dispatch)
      dispatch(postInvestIdea(id, investmentAmount, onSuccess, onFailure))
    },
    publishIdea: (id, investmentAmount) => {
      const onSuccess = () => {
        dispatch(getIdea(id))
      }
      const onFailure = () => showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ERROR_PUBLISH'), dispatch)
      dispatch(postPublishDraft(id, investmentAmount, onSuccess, onFailure))
    },
    showNoImageDraftSnackbar: () => {
      showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.IMAGE_MANDATORY'), dispatch)
    },
    getIdea: id => {
      dispatch(getIdea(id))
    },
    navigateToChallengeDetail: challengeId => {
      Navigation.navigateToChallengeDetail(challengeId)
    },
    navigateToUserProfile: userEmail => {
      Navigation.navigateToUserProfile(userEmail)
    },
    onReportClick: (id, customIdeaTitles) => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          showPopupDialog(<SuccessSubmitModal />, dispatch)
        }
        dispatch(reportIdea(id, onSuccess))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('EXPLORE_IDEA.REPORT_IDEA', {
            title: customIdeaTitles.singularTitle || 'idea',
          })}
          description={i18n.t('EXPLORE_IDEA.REPORT_IDEA_TEXT', {
            title: customIdeaTitles.singularTitle || 'idea',
          })}
        />,
        dispatch,
      )
    },
    getContributions: id => dispatch(getIdeaContributions(id)),
    setContributionTime: debounce(value => dispatch(setContributionTime(value)), 300),
    setContributionBudget: debounce(value => dispatch(setContributionBudget(value)), 300),
    setContributionOther: debounce(value => dispatch(setContributionOther(value)), 100),
    onSaveContribution: (ideaId, contribId) => dispatch(saveContribution(ideaId, contribId)),
    onDeleteContribution: ideaId => dispatch(deleteContribution(ideaId)),
    getInvestors: id => dispatch(getIdeaInvestors(id)),
    onSummaryStepperHelpClick: () => Navigation.navigateToExternal('https://help.nextinit.com'),
    onPendingUserRemoveClick: (ideaId, email, isInitiative) => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          dispatch(getIdeaTeam(ideaId))
          showPopupSuccessSnackbar(i18n.t('IDEA.TEAM_DELETE_OK'), dispatch)
        }
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('IDEA.TEAM_DELETE_FAIL'), dispatch)
        }
        dispatch(deleteUserFromTeam(ideaId, email, isInitiative, onSuccess, onFailure))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('CONFIRMATION_MESSAGE')}
          description={i18n.t('IDEA.TEAM_DELETE_CONFIRM')}
        />,
        dispatch,
      )
    },
    onDocumentUpload: (id, file) => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.UPLOAD_DOC_COMPLETE'), dispatch)
        dispatch(hideDialog())
      }
      const onFailure = error => {
        if (error.status === 413) {
          showPopupErrorSnackbar(
            i18n.t('EXPLORE_IDEA.UPLOAD_MAX_SIZE_DOC_ERROR', { max_size: MAX_SIZE_DOCUMENT }),
            dispatch,
          )
        } else {
          showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.UPLOAD_DOC_ERROR'), dispatch)
        }
        dispatch(hideDialog())
      }

      if (file.size > MAX_DOCUMENT_BYTES) {
        showPopupErrorSnackbar(
          i18n.t('EXPLORE_IDEA.UPLOAD_MAX_SIZE_DOC_ERROR', { max_size: MAX_SIZE_DOCUMENT }),
          dispatch,
        )
      } else {
        getBase64Split(
          file,
          (fileName, base64File) => {
            dispatch(postIdeaDocument(id, fileName, base64File, onSuccess, onFailure))
          },
          true,
        )
      }
    },
    onDocumentDelete: (id, documentId) => {
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.DELETE_KO'), dispatch)
      }
      dispatch(deleteIdeaDocument(id, documentId, onFailure))
    },
    onPostComment: (value, parentId, ideaId) => {
      const onSuccess = () => {
        dispatch(getIdeaComments(ideaId))
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ERROR_COMMENT'), dispatch)
      }
      dispatch(postComment(value, parentId, ideaId, onSuccess, onFailure))
    },
    getComments: ideaId => dispatch(getIdeaComments(ideaId)),
    onDeleteComment: (commentId, ideaId) => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          showPopupSuccessSnackbar(i18n.t('COMMENT.DELETE_MSG_SUCCESS'), dispatch)
          dispatch(getIdeaComments(ideaId))
        }
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('COMMENT.DELETE_MSG_ERROR'), dispatch)
        }
        dispatch(deleteComment(commentId, ideaId, onSuccess, onFailure))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('CONFIRMATION_MESSAGE')}
          description={i18n.t('COMMENT.DELETE_MSG')}
        />,
        dispatch,
      )
    },
    onVoteComment: (commentId, ideaId) => {
      const onSuccess = () => {
        dispatch(getIdeaComments(ideaId))
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
      }
      dispatch(voteComment(commentId, ideaId, onSuccess, onFailure))
    },
    onEditComment: (comment, commentId, ideaId) => {
      const onSuccess = () => {
        dispatch(getIdeaComments(ideaId))
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
      }
      dispatch(putComment(comment, commentId, ideaId, onSuccess, onFailure))
    },
    onDiscardClick: (id, rejectedReasons) => {
      showPopupDialog(<DiscardView id={id} rejectedReasons={rejectedReasons} />, dispatch)
    },
    onApproveClick: (id, approvedReasons) => {
      showPopupDialog(<ApproveView id={id} approvedReasons={approvedReasons} />, dispatch)
    },
    onDeleteClick: id => {
      showPopupDialog(<ConfirmDeleteIdeaModal id={id} />, dispatch)
    },
    onEditClick: id => {
      dispatch(Navigation.navigateToEditIdea(id))
    },
    getRejectedReasons: () => {
      dispatch(getRejectedReasons())
    },
    getApprovedReasons: () => {
      dispatch(getApprovedReasons())
    },
    onShareIdea: id => {
      showPopupDialog(<SendInvitationsContainer ideaId={id} />, dispatch)
    },
    onRequestJoinIdeaTeam: (id, request) => {
      dispatch(requestJoinIdeaTeam(id, request))
    },
    onActivatePilot: ideaId => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.ACTIVATE_SUCCESS'), dispatch)
          dispatch(getIdea(ideaId))
        }
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ACTIVATE_ERROR'), dispatch)
        }
        dispatch(postActivatePilotProject(ideaId, onSuccess, onFailure))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('CONFIRMATION_MESSAGE')}
          description={i18n.t('EXPLORE_IDEA.ACTIVATE_MESSAGE')}
        />,
        dispatch,
      )
    },
    onImplementPilot: ideaId => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.IMPLEMENT_SUCCESS'), dispatch)
          dispatch(getIdea(ideaId))
        }
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.IMPLEMENT_ERROR'), dispatch)
        }
        dispatch(postImplementPilotProject(ideaId, onSuccess, onFailure))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('CONFIRMATION_MESSAGE')}
          description={i18n.t('EXPLORE_IDEA.LOADING_MESSAGE')}
        />,
        dispatch,
      )
    },
    onTransferLeader: (ideaId, email) => {
      const onSuccess = () => {
        dispatch(getIdea(ideaId))
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.TRANSFER_ERROR'), dispatch)
      }
      dispatch(postTransferPromoter(ideaId, email, onSuccess, onFailure))
    },
    onResetSuggestionList: () => dispatch(resetSponsorSuggestions()),
    getIsTeamRequestActive: id => dispatch(getIsTeamRequestActive(id)),
    onUpdateKpiAction: ({ id, type, unit, range, expected, current, name, success, ideaId }) => {
      const onSuccess = () => dispatch(getKpis(ideaId))
      dispatch(
        updateKpi(
          { id, type, unit, range, expected, current, name, success, ideaId },
          onSuccess,
          onFailureKpis,
        ),
      )
    },
    onUpdateKpiSuccess: ({ id, success, ideaId }) => {
      const onSuccess = () => dispatch(getKpis(ideaId))
      dispatch(updateKpiSuccess({ id, success, ideaId }, onSuccess, onFailureKpis))
    },
    onDeleteKpi: ({ id, ideaId }) => {
      showPopupDialog(<ConfirmDeleteKpiModal id={id} ideaId={ideaId} />, dispatch)
    },
    onSubmitKpiForm: ({ type, unit, range, expected, current, name, ideaId }) => {
      const onSuccess = () => dispatch(getKpis(ideaId))
      dispatch(
        newKpi({ type, unit, range, expected, current, name, ideaId }, onSuccess, onFailureKpis),
      )
    },
    getIdeaVideoType: url => dispatch(getIdeaVideoType(url)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
