import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Select, MenuItem } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

const ListFilter = (props) => {
  const { classes, onSelectChange, totalUsers, customIdeaTitles } = props
  const [withSelect, setWithSelect] = useState('w_')
  const [typeSelect, setTypeSelect] = useState('investments')

  const handleSelectChange = (value, select) => {
    if (select === 'with') {
      setWithSelect(value)
      onSelectChange(value + typeSelect)
    }
    if (select === 'type') {
      setTypeSelect(value)
      onSelectChange(withSelect + value)
    }
  }

  return (
    <div className={classes.root}>
      <span>{i18n.t('DASHBOARD.SHOW_PEOPLE_WITH')}</span>
      <Select
        className={classes.select}
        onChange={event => handleSelectChange(event.target.value, 'with')}
        value={withSelect}
        variant="outlined"
      >
        <MenuItem value="w_">{i18n.t('DASHBOARD.WITH')}</MenuItem>
        <MenuItem value="wo_">{i18n.t('DASHBOARD.WITHOUT')}</MenuItem>
      </Select>
      <Select
        className={classes.select}
        onChange={event => handleSelectChange(event.target.value, 'type')}
        variant="outlined"
        value={typeSelect}
      >
        <MenuItem value="comments">{i18n.t('DASHBOARD.COMMENTS')}</MenuItem>
        <MenuItem value="ideasOwned">{i18n.t('DASHBOARD.IDEAS', { title: customIdeaTitles.pluralTitle || "ideas" })}</MenuItem>
        <MenuItem value="ideasFunded">{i18n.t('DASHBOARD.IDEASFUNDED', { title: customIdeaTitles.pluralTitle || "ideas" })}</MenuItem>
        <MenuItem value="ideasApproved">{i18n.t('DASHBOARD.IDEASAPPROVED', { title: customIdeaTitles.pluralTitle || "ideas" })}</MenuItem>
        <MenuItem value="ideasPilot">{i18n.t('DASHBOARD.IDEASPILOT')}</MenuItem>
        <MenuItem value="ideasImplemented">{i18n.t('DASHBOARD.IDEASIMPLEMENTED', { title: customIdeaTitles.pluralTitle || "ideas" })}</MenuItem>
        <MenuItem value="ideasDiscarded">{i18n.t('DASHBOARD.IDEASDISCARDED', { title: customIdeaTitles.pluralTitle || "ideas" })}</MenuItem>
        <MenuItem value="investments">{i18n.t('DASHBOARD.INVESTMENTS')}</MenuItem>
      </Select>
      <div>
        <span className={classes.totalUsers}>{totalUsers}</span>
        <span>{i18n.t('DASHBOARD.USERS_FOUND')}</span>
      </div>
    </div>
  )
}

ListFilter.defaultProps = {
  customIdeaTitles: {
    singularTitle: "",
    pluralTitle: ""
  }
}

ListFilter.propTypes = {
  onSelectChange: PropTypes.func.isRequired,
  totalUsers: PropTypes.number.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  })
}

export default withStyles(styles)(ListFilter)
