const styles = theme => ({
  icon: {
    marginLeft: '15px',
    color: '#00A3FF',
    fontSize: '15px',
  },
  title: {
    display: 'flex',
  },
  formItem: {
    display: 'flex',
    '& h3': {
      width: theme.spacing(30),
    },
  },
  imageContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > span': {
      color: theme.palette.error.main,
      margin: theme.spacing(1, 0),
      fontSize: '12px',
    },
  },
  externalVideoContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > span': {
      color: theme.palette.error.main,
      margin: theme.spacing(1, 0),
      fontSize: '12px',
    },
  },
  inputItem: {
    width: '100%',
    '& span': {
      display: 'block',
      marginBottom: theme.spacing(0.5),
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& > span': {
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2),
      fontSize: '12px',
    },
  },
  helperText: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateInputItem: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

export default styles
