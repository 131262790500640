import React, { Component } from 'react'
import { withStyles, Typography, Divider, TextField } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import ImageUploader from 'components/image_uploader/View'
import clsx from 'clsx'
import styles from './View.Style'
import RewardChoices from './components/reward_choices/View'

class ExtraRewards extends Component {
  constructor(props) {
    super(props)
    this.title = i18n.t('CHALLENGES.REWARD')
    this.description = i18n.t('CHALLENGES.REWARD_DESC')
    this.descriptionHelp = i18n.t('CHALLENGES.REWARD_DESC_INFO')
    this.image = i18n.t('CHALLENGES.IMAGE')
    this.whoRewarded = i18n.t('CHALLENGES.WHO_REWARDED')
    this.descriptionRef = React.createRef()
    this.rewardedRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { errorObject } = this.props
    if (prevProps.errorObject !== errorObject && Object.keys(errorObject).length > 0) {
      const refToScroll = Object.keys(errorObject).find(key => key === 'rewardDescription')
        ? this.descriptionRef
        : this.rewardedRef
      refToScroll.current.scrollIntoView()
    }
  }

  render() {
    const {
      classes,
      reward,
      onDescriptionChange,
      onRewardedChange,
      onOtherChange,
      onSelectImage,
      deleteImage,
      errorObject,
      customIdeaTitles
    } = this.props

    const { description, image, rewarded, rewardedOther } = reward
    const images = image.key ? [image] : []

    return (
      <div className={classes.root}>
        <Typography variant="h5" className={classes.mainTitle}>
          {this.title}
        </Typography>
        <Divider />
        <div className={classes.rewardsWrapper}>
          <div className={classes.line}>
            <div
              className={clsx(classes.inputLabel, errorObject.rewardDescription && classes.error)}
              ref={this.descriptionRef}
            >
              {this.title}
            </div>
            <div className={classes.inputContainer}>
              <TextField
                id="challenge-reward-description"
                placeholder={this.description}
                margin="dense"
                fullWidth
                multiline
                defaultValue={description}
                onChange={event => onDescriptionChange(event.target.value)}
                helperText={this.descriptionHelp}
                error={errorObject.rewardDescription}
              />
            </div>
          </div>
          <div className={classes.line}>
            <div className={classes.imageLabel}>{this.image}:</div>
            <div className={classes.inputContainer}>
              <ImageUploader
                images={images}
                onSelectImage={onSelectImage}
                deleteImage={deleteImage}
                imageUploadError={errorObject.imageUploadError}
                isOneImageOnly
              />
            </div>
          </div>
          <div className={classes.line}>
            <div
              className={clsx(classes.inputLabel, errorObject.rewarded && classes.error)}
              ref={this.rewardedRef}
            >
              {this.whoRewarded}
            </div>
            <div className={classes.inputContainer}>
              <RewardChoices
                onRewardedChange={onRewardedChange}
                onOtherChange={onOtherChange}
                value={rewarded}
                otherValue={rewardedOther}
                customIdeaTitles={customIdeaTitles}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
ExtraRewards.defaultProps = {}
ExtraRewards.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  reward: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.shape({}).isRequired,
    rewarded: PropTypes.string,
    rewardedOther: PropTypes.string,
  }).isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onRewardedChange: PropTypes.func.isRequired,
  onOtherChange: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  errorObject: PropTypes.shape({
    rewardDescription: PropTypes.bool,
    rewarded: PropTypes.bool,
    imageUploadError: PropTypes.string,
  }).isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}
export default withStyles(styles)(ExtraRewards)
