const styles = theme => ({
  root: {
    padding: theme.spacing(2.5),
    '& .MuiSlider-markLabel': {
      display: 'none',
    }
  },
  mainContent: {
    padding: theme.spacing(0, 2.5, 2.5, 2.5),
  },
  roiSection: {
    marginTop: theme.spacing(5),
  },
  simplePaper: {
    padding: theme.spacing(3),
    backgroundColor: '#f5f5f5',
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  slider: {
    flex: 9,
    '& > div': {
      '& span:first-child, span:last-child': {
        fontSize: '13px',
      }
    }
  },
  indicator: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '42px',
      height: '42px',
      fontSize: '12px',
      fontWeight: 700,
      borderRadius: '50%',
      backgroundColor: '#000',
      marginTop: '-25px',
    }
  }
})

export default styles
