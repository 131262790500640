import { extractPropertiesFromJsonObject } from '../RequestUtils'

export const setKpiContent = ({ id, type, unit, range, expected, current, name, success }) => {
  let uri = ''

  if (id) uri = `${uri}${encodeURIComponent('id')}=${encodeURIComponent(id)}&`
  if (type) uri = `${uri}${encodeURIComponent('type')}=${encodeURIComponent(type)}&`
  if (unit) uri = `${uri}${encodeURIComponent('unit')}=${encodeURIComponent(unit)}&`
  uri = `${uri}${encodeURIComponent('range')}=${encodeURIComponent(range || '')}&`
  if (expected) uri = `${uri}${encodeURIComponent('expected')}=${encodeURIComponent(expected)}&`
  if (current) uri = `${uri}${encodeURIComponent('current')}=${encodeURIComponent(current)}&`
  if (name) uri = `${uri}${encodeURIComponent('name')}=${encodeURIComponent(name)}&`
  if (success) uri = `${uri}${encodeURIComponent('isSuccess')}=${encodeURIComponent(success)}&`

  return uri
}

export const setContent = (ideaId, image) => {
  let url = ''
  if (ideaId) {
    url = `${encodeURIComponent('ideaId')}=${encodeURIComponent(ideaId)}&`
  }
  return `${url}${encodeURIComponent('image')}=${encodeURIComponent(image)}&${encodeURIComponent(
    'upload_type',
  )}=${encodeURIComponent('IMAGE_UPLOAD')}`
}

export const getGoalFromJson = goals => {
  if (!goals) return null
  return Object.entries(goals).map(goal => `${goal[0]}##${goal[1]}`)
}

const getIdeaVideoUrl = (externalVideo, videos) => {
  if (externalVideo) return externalVideo
  if (videos.fileVideo && videos.fileVideo.name) return null
  return 'none'
}

export function getIdeaData(ideaForm, videos) {
  return {
    id: ideaForm.id,
    isPrivate: ideaForm.isPrivate,
    images: ideaForm.images ? ideaForm.images.map(image => image.name) : null,
    title: ideaForm.title || null,
    text: ideaForm.description || null,
    challenge: ideaForm.challenge || null,
    'organizations[]': ideaForm.organization || null,
    'types[]': ideaForm.type || null,
    'attributes[]': ideaForm.attribute || null,
    'goals[]': getGoalFromJson(ideaForm.goalValues),
    status: ideaForm.status || null,
    pilotMoney: ideaForm.pilotEstimatedQuantity || null,
    pilotHours: ideaForm.pilotEstimatedHours || null,
    pilotTime: ideaForm.pilotEstimatedTime || null,
    pilotTimeUnit: ideaForm.pilotEstimatedTimeType || null,
    implementMoney: ideaForm.implementEstimatedQuantity || null,
    implementHours: ideaForm.implementEstimatedHours || null,
    implementTime: ideaForm.implementEstimatedTime || null,
    implementTimeUnit: ideaForm.implementEstimatedTimeType || null,
    revenueAmount: ideaForm.economicBenefits || null,
    other: ideaForm.otherBenefits || null,
    video: getIdeaVideoUrl(ideaForm.video, videos),
    'files[]': ideaForm.documents ? ideaForm.documents.map(document => document.key) : null,
    amount: ideaForm.initialInvestment || 0,
    endDate: ideaForm.endDate ? ideaForm.endDate.getTime() : null,
    isanonymous: ideaForm.isAnonymous || null,
  }
}

export function setIdeaBodyFromState(formData, videosState) {
  const outGoingData = getIdeaData(formData, videosState)
  return extractPropertiesFromJsonObject(outGoingData)
}

export const setTitleBody = title => `${encodeURIComponent('title')}=${encodeURIComponent(title)}&`

export function setContributionBodyFromState(state) {
  return `${encodeURIComponent('hours')}=${encodeURIComponent(
    state.data.createIdea.ownContribution.time,
  )}&${encodeURIComponent('budget')}=${encodeURIComponent(
    state.data.createIdea.ownContribution.budget,
  )}&${encodeURIComponent('other')}=${encodeURIComponent(
    state.data.createIdea.ownContribution.other,
  )}&`
}

export function setInvestmentAmount(investmentAmount) {
  return `${encodeURIComponent('amount')}=${encodeURIComponent(investmentAmount)}`
}

export function setAskInvestmentsBody(ideaId, usersEmails, content) {
  return `${encodeURIComponent('content')}=${encodeURIComponent(content)}&${encodeURIComponent(
    'ideaK',
  )}=${encodeURIComponent(ideaId)}&${encodeURIComponent('emails')}=${encodeURIComponent(
    usersEmails,
  )}&${encodeURIComponent('externalEmails')}=''`
}

export function setPostSendInvitationBody(email) {
  return `${encodeURIComponent('email')}=${encodeURIComponent(email)}`
}

export function setDocumentBody(fileName, base64File) {
  return `${encodeURIComponent('fileName')}=${encodeURIComponent(fileName)}&${encodeURIComponent(
    'file',
  )}=${encodeURIComponent(base64File)}`
}

export function setCommentBody(text, parentId) {
  let ret = `${encodeURIComponent('text')}=${encodeURIComponent(text)}`
  if (parentId) ret += `&${encodeURIComponent('parent')}=${encodeURIComponent(parentId)}`
  return ret
}

export const setValidatePendingIdea = () => {
  return `${encodeURIComponent('validated')}=${encodeURIComponent(true)}`
}

export const setPublishDraft = investmentAmount => {
  let investment = investmentAmount
  if (!investment) {
    investment = 0
  }
  return `${encodeURIComponent('amount')}=${encodeURIComponent(investment)}`
}

export const getIdeaVideoUrlFromState = url => state => {
  const id = state.data.createIdea.videos.fileVideoId
  return url.replace('##videoId##', id)
}

export const setFileUploadUrl = state => {
  return state.data.createIdea.fileUploadUrl
}

export const setFileContent = file => {
  const fileFormData = new FormData()
  fileFormData.append('file', file)
  return fileFormData
}

