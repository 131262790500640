import React from 'react'
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import i18n from 'support/i18n'
import { ONLY_NUMBERS, ONLY_TEXT, TextFieldStyled } from './utils'

export const CompanyInfo = ({ onChangeValue, info, anyError, isDisabled }) => {
  const {
    cel,
    corporateEmail,
    countryHeadOffice,
    otherCountries,
    companyId,
    annualSales,
    companySize,
    companyAge,
    solutionName,
    solutionLevel,
    solutionDescription,
    valueProposal,
  } = info

  const onChange = (event, field) => {
    const { value } = event.target

    if (['cel'].includes(field)) {
      return value.match(ONLY_NUMBERS) !== null
        ? onChangeValue('companyInfo', { ...info, cel: event.target.value })
        : null
    }

    if (['countryHeadOffice', 'otherCountries'].includes(field)) {
      return value.match(ONLY_TEXT) !== null
        ? onChangeValue('companyInfo', { ...info, [field]: event.target.value })
        : null
    }

    if (['corporateEmail'].includes(field)) {
      const emailRegex = /^[a-zA-Z0-9#$&@*-=?_|.]+$/
      if (event.target.value.length > 0) {
        return value.match(emailRegex) !== null
          ? onChangeValue('companyInfo', { ...info, [field]: event.target.value })
          : null
      }
    }

    if (['companyId'].includes(field)) {
      const { value: item } = event.target
      const regex = /^[0-9-kK]+$/

      if (item?.trim().length > 0) {
        if (regex.test(item)) {
          return onChangeValue('companyInfo', { ...info, [field]: item.toUpperCase() })
        }

        return null
      }
    }

    if (['solutionDescription', 'valueProposal'].includes(field)) {
      const { value: item } = event.target

      if (item?.trim().length > 0) {
        const sanitized = item.replace(/[^\w\s.,ñ]/gi, '')
        return onChangeValue('companyInfo', { ...info, [field]: sanitized })
      }
    }

    return onChangeValue('companyInfo', { ...info, [field]: value })
  }

  return (
    <Grid item xs={12}>
      <Card
        variant="elevation"
        elevation={3}
        style={{
          borderRadius: 8,
        }}
      >
        <CardContent spacing={2}>
          <Typography variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
            {i18n.t('FORMS.STANDARD_FORM.FORM.COMPANY_INFO')}
          </Typography>
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.CEL')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'cel')}
            value={cel}
            error={anyError && 'cel' in anyError}
            helperText={anyError?.cel}
            inputProps={{ maxLength: 9 }}
            disabled={isDisabled}
            placeholder="9 87654321"
          />
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.CORPORATE_EMAIL')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'corporateEmail')}
            value={corporateEmail}
            disabled={isDisabled}
            error={anyError && 'corporateEmail' in anyError}
            helperText={anyError?.corporateEmail}
            onFocus={event => event.target.select()}
          />
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.COUNTRY_HEAD_OFFICE')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'countryHeadOffice')}
            value={countryHeadOffice}
            disabled={isDisabled}
            error={anyError && 'countryHeadOffice' in anyError}
            helperText={anyError?.countryHeadOffice}
          />
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.OTHER_COUNTRIES')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'otherCountries')}
            value={otherCountries}
            disabled={isDisabled}
            error={anyError && 'otherCountries' in anyError}
            helperText={anyError?.otherCountries}
          />
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.COMPANY_ID')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'companyId')}
            value={companyId}
            disabled={isDisabled}
            placeholder={i18n.t('FORMS.PLACEHOLDERS.DNI')}
            error={anyError && 'companyId' in anyError}
            helperText={anyError?.companyId}
            inputProps={{ maxLength: 10 }}
          />
          {/* RADIO BUTTONS */}
          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              style={{ marginTop: 16, marginBottom: 8 }}
              error={anyError && 'annualSales' in anyError}
              disabled={isDisabled}
            >
              <FormLabel
                component="legend"
                required
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  marginBottom: 8,
                }}
              >
                {i18n.t('FORMS.STANDARD_FORM.FORM.ANNUAL_SALES')}
              </FormLabel>
              <RadioGroup
                aria-label="annual-sales"
                name="annual-sales"
                value={annualSales}
                onChange={event => onChange(event, 'annualSales')}
              >
                <FormControlLabel
                  value="1-2.400"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_ANNUAL_SALES_1')}
                />
                <FormControlLabel
                  value="2.401-80.000"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_ANNUAL_SALES_2')}
                />
                <FormControlLabel
                  value="+80.000"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_ANNUAL_SALES_3')}
                />
              </RadioGroup>
              <FormHelperText>{anyError?.annualSales}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              style={{ marginTop: 16, marginBottom: 8 }}
              error={anyError && 'companySize' in anyError}
              disabled={isDisabled}
            >
              <FormLabel
                required
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  marginBottom: 8,
                }}
              >
                {i18n.t('FORMS.STANDARD_FORM.FORM.COMPANY_SIZE')}
              </FormLabel>
              <RadioGroup
                aria-label="company-size"
                name="company-size"
                value={companySize}
                onChange={event => onChange(event, 'companySize')}
              >
                <FormControlLabel
                  value="1-10"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_COMPANY_SIZE_1')}
                />
                <FormControlLabel
                  value="11-50"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_COMPANY_SIZE_2')}
                />
                <FormControlLabel
                  value="51-200"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_COMPANY_SIZE_3')}
                />
                <FormControlLabel
                  value="+200"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_COMPANY_SIZE_4')}
                />
              </RadioGroup>
              <FormHelperText>{anyError?.companySize}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              style={{ marginTop: 16, marginBottom: 8 }}
              error={anyError && 'companyAge' in anyError}
              disabled={isDisabled}
            >
              <FormLabel
                required
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  marginBottom: 8,
                }}
              >
                {i18n.t('FORMS.STANDARD_FORM.FORM.COMPANY_AGE')}
              </FormLabel>
              <RadioGroup
                aria-label="company-age"
                name="company-age"
                value={companyAge}
                onChange={event => onChange(event, 'companyAge')}
              >
                <FormControlLabel
                  value="-1"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_COMPANY_AGE_1')}
                />
                <FormControlLabel
                  value="1-3"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_COMPANY_AGE_2')}
                />
                <FormControlLabel
                  value="3-5"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_COMPANY_AGE_3')}
                />
                <FormControlLabel
                  value="+5"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_COMPANY_AGE_4')}
                />
              </RadioGroup>
              <FormHelperText>{anyError?.companyAge}</FormHelperText>
            </FormControl>
          </Grid>
          {/* END RADIO BUTTONS */}
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.SOLUTION_NAME')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'solutionName')}
            value={solutionName}
            disabled={isDisabled}
            error={anyError && 'solutionName' in anyError}
            helperText={anyError?.solutionName}
          />
          {/* RADIO BUTTONS */}
          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              style={{ marginTop: 16, marginBottom: 8 }}
              error={anyError && 'solutionLevel' in anyError}
              disabled={isDisabled}
            >
              <FormLabel
                required
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  marginBottom: 8,
                }}
              >
                {i18n.t('FORMS.STANDARD_FORM.FORM.SOLUTION_LEVEL')}
              </FormLabel>
              <RadioGroup
                aria-label="solution-level"
                name="solution-level"
                value={solutionLevel}
                onChange={event => onChange(event, 'solutionLevel')}
              >
                <FormControlLabel
                  value="Es solo una idea"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_SOLUTION_LEVEL_1')}
                />
                <FormControlLabel
                  value="Cuenta como un MVP o prototipo funcional"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_SOLUTION_LEVEL_2')}
                />
                <FormControlLabel
                  value="Prototipo ya validado comercialmente"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_SOLUTION_LEVEL_3')}
                />
                <FormControlLabel
                  value="Solucion tiene ventas con clientes"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_SOLUTION_LEVEL_4')}
                />
                <FormControlLabel
                  value="Etapa de internacionalizacion"
                  control={<Radio color="primary" />}
                  label={i18n.t('FORMS.STANDARD_FORM.FORM.OPTION_SOLUTION_LEVEL_5')}
                />
              </RadioGroup>
              <FormHelperText>{anyError?.solutionLevel}</FormHelperText>
            </FormControl>
          </Grid>
          {/* END RADIO BUTTONS */}
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.SOLUTION_DESCRIPTION')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'solutionDescription')}
            value={solutionDescription}
            error={anyError && 'solutionDescription' in anyError}
            helperText={anyError?.solutionDescription}
            disabled={isDisabled}
          />
          <TextField
            required
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.VALUE_PROPOSAL')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'valueProposal')}
            value={valueProposal}
            error={anyError && 'valueProposal' in anyError}
            helperText={anyError?.valueProposal}
            disabled={isDisabled}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}
