import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    formField: {
      minWidth: '14rem',
      backgroundColor: 'white',
      borderRadius: '4px',
      color: theme.palette.primary.main,
      margin: 0,
      borderCollapse: 'separate',
      '&:after, &:before': {
        content: 'unset',
      },
      '& > div': {
        padding: '7px 15px',
        minHeight: '25px',
        display: 'flex',
        alignItems: 'center',
        '& > input': {
          padding: '3px 0',
        },
        '& > input::placeholder': {
          color: theme.palette.primary.main,
        },
        '&:after, &:before': {
          content: 'unset',
        },
      },
      '& > div:focus': {
        backgroundColor: 'unset',
      },
      '& svg': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: '39px',
        width: '39px',
        borderRadius: '0 4px 4px 0',
        top: 'unset',
      },
    },
  })

export default styles
