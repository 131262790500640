const styles = theme => ({
  container: {
    padding: '4%',
    width: 350,
    '& p': {
      textAlign: 'left',
      fontSize: 12,
    },
  },
  list: {
    overflow: 'auto',
    height: 340,
    width: '370px',
  },
  photo: {
    height: 35,
    width: 35,
    borderRadius: '100%',
    marginRight: theme.spacing(1),
  },
  link: {
    display: 'flex',
    fontSize: 12,
    fontWeight: 'bold',
    width: 100,
    textTransform: 'none',
    textDecoration: 'underline',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      justifyContent: 'unset',
      display: 'unset',
      textAlign: 'left',
    },
    '&:hover': {
      color: '#0088cc',
      backgroundColor: 'transparent',
    },
  },
  row: {
    borderTop: 1,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 3fr 2fr',
    padding: 10,
    height: 90,
    width: 350,
  },
  best: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 45,
  },
  iconTitle: {
    fontSize: 28,
    marginRight: 15,
  },
  iconTop: {
    fontSize: 25,
    marginRight: 5,
  },
  icon: {
    marginRight: 10,
  },
  value: {
    textAlign: 'right',
  },
  rowDescription: {
    margin: 0,
    textAlign: 'left',
    '& .MuiButton-root': {
      margin: 0,
      padding: 0,
      fontSize: '18px',
      minWidth: '40px',
      color: theme.palette.primary.main,
    }
  },
  descriptionText: {
    fontSize: '11px',
    paddingLeft: '10px',
  }
})

export default styles
