import React, { Component, Fragment } from 'react'
import { Divider, Typography, withStyles, Paper } from '@material-ui/core'
import i18n from 'i18n-js'
import * as PropTypes from 'prop-types'
import NxtResumeCard from 'components/nxt_resume_card/View'
import { fromValueToPercentage } from 'pages/challenge/form/View.Utils'
import ChallengeResumeDetail from 'components/nxt_challenge_resume_detail/View'
import ActivePeriod from './components/active_period/View'
import styles from './View.Style'
import OrganizationSelector from './components/organization_selector/View'
import GroupsLimit from './components/groups_limit/View'

class PreviewLaunch extends Component {
  constructor(props) {
    super(props)
    this.activePeriodRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { errorObject } = this.props
    if (prevProps.errorObject !== errorObject && Object.keys(errorObject).length > 0) {
      this.activePeriodRef.current.scrollIntoView()
    }
  }

  render() {
    const {
      classes,
      previewChallenge,
      onSelectPreviewFromDate,
      onSelectPreviewToDate,
      onOrganizationSelect,
      organizationList,
      isOrganizationsActive,
      organizationsName,
      onMoveToRight,
      onMoveToLeft,
      onRightGroupClick,
      approveMult,
      implementMult,
      supportMult,
      challengeDescription,
      reward,
      selectedSponsor,
      selectedProposer,
      onClickToShowRewardImage,
      errorObject,
      defaultValues,
      showOrganisations,
      isFromDateDisable,
      isGroupDisabled,
      customIdeaTitles
    } = this.props

    const { fromDate, toDate, defaultGroups, rightGroups, organization } = previewChallenge
    const {
      title,
      description,
      images,
      externalVideo,
      fileVideoSrc,
      fileVideoType,
      whyImportant,
    } = challengeDescription

    const supportPercentage = fromValueToPercentage(defaultValues.supportMult, supportMult)
    const implementPercentage = fromValueToPercentage(defaultValues.implementMult, implementMult)
    const approvePercentage = fromValueToPercentage(defaultValues.approveMult, approveMult)

    return (
      <div className={classes.root}>
        <NxtResumeCard
          images={images}
          title={title}
          description={description}
          videoSrc={externalVideo || fileVideoSrc}
          fileVideoType={fileVideoType}
          selectedProposer={selectedProposer}
        >
          <ChallengeResumeDetail
            onClickToShowRewardImage={onClickToShowRewardImage}
            reward={reward}
            whyImportant={whyImportant}
            supportMult={supportPercentage}
            implementMult={implementPercentage}
            approveMult={approvePercentage}
            selectedSponsor={selectedSponsor}
            defaultValues={defaultValues}
            customIdeaTitles={customIdeaTitles}
          />
        </NxtResumeCard>
        <Typography variant="h5" className={classes.mainTitle} ref={this.activePeriodRef}>
          {i18n.t('CHALLENGES.WHEN_ACTIVE')}
        </Typography>
        <Divider />
        <div className={classes.datesContainer}>
          <ActivePeriod
            isFromDateDisable={isFromDateDisable}
            fromDate={fromDate}
            toDate={toDate}
            onSelectPreviewFromDate={onSelectPreviewFromDate}
            onSelectPreviewToDate={onSelectPreviewToDate}
            hasToDateError={errorObject.toDate}
            customIdeaTitles={customIdeaTitles}
          />
        </div>
        {((defaultGroups && defaultGroups.length > 0) || isGroupDisabled) && (
          <Fragment>
            <Typography variant="h5" className={classes.mainTitle}>
              {i18n.t('CHALLENGES.LIMIT_TO_GROUP')}
            </Typography>
            <Divider />
            {isGroupDisabled && rightGroups.length > 0 && (
              <ol className={classes.groupsSelected}>
                {rightGroups.map(group => (
                  <li>{group.name}</li>
                ))}
              </ol>
            )}
            {isGroupDisabled && rightGroups.length === 0 && (
              <Paper className={classes.noGroupsSelected}>
                {i18n.t('CHALLENGES.NO_GROUPS_SELECTED')}
              </Paper>
            )}
            {!isGroupDisabled && (
              <div className={classes.groupsLimit}>
                <GroupsLimit
                  leftGroups={defaultGroups}
                  rightGroups={rightGroups}
                  onMoveToRight={onMoveToRight}
                  onMoveToLeft={onMoveToLeft}
                  onRightGroupClick={onRightGroupClick}
                />
              </div>
            )}
          </Fragment>
        )}
        {isOrganizationsActive && showOrganisations && (
          <Fragment>
            <Typography variant="h5" className={classes.mainTitle}>
              {organizationsName}
            </Typography>
            <Divider />
            <OrganizationSelector
              onOrganizationSelect={onOrganizationSelect}
              organization={organization}
              organizationList={organizationList}
            />
          </Fragment>
        )}
      </div>
    )
  }
}
PreviewLaunch.defaultProps = {
  classes: {
    root: '',
    mainTitle: '',
    datesContainer: '',
    groupsLimit: '',
  },
}
PreviewLaunch.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    mainTitle: PropTypes.string,
    datesContainer: PropTypes.string,
    groupsLimit: PropTypes.string,
  }),
  previewChallenge: PropTypes.shape({
    fromDate: PropTypes.instanceOf(Date).isRequired,
    toDate: PropTypes.instanceOf(Date),
    leftGroups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.string,
        id: PropTypes.string,
        checked: PropTypes.bool,
      }),
    ),
    rightGroups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.string,
        id: PropTypes.string,
        checked: PropTypes.bool,
      }),
    ),
    organization: PropTypes.string,
  }).isRequired,
  isOrganizationsActive: PropTypes.bool.isRequired,
  organizationsName: PropTypes.string.isRequired,
  isFromDateDisable: PropTypes.bool.isRequired,
  showOrganisations: PropTypes.bool.isRequired,
  isGroupDisabled: PropTypes.bool.isRequired,
  onSelectPreviewFromDate: PropTypes.func.isRequired,
  onSelectPreviewToDate: PropTypes.func.isRequired,
  onOrganizationSelect: PropTypes.func.isRequired,
  organizationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  onMoveToRight: PropTypes.func.isRequired,
  onMoveToLeft: PropTypes.func.isRequired,
  onClickToShowRewardImage: PropTypes.func.isRequired,
  onRightGroupClick: PropTypes.func.isRequired,
  approveMult: PropTypes.number.isRequired,
  implementMult: PropTypes.number.isRequired,
  supportMult: PropTypes.number.isRequired,
  challengeDescription: PropTypes.shape({}).isRequired,
  reward: PropTypes.shape({}).isRequired,
  defaultValues: PropTypes.shape({}).isRequired,
  selectedSponsor: PropTypes.shape({}).isRequired,
  selectedProposer: PropTypes.shape({}).isRequired,
  errorObject: PropTypes.shape({}).isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}
export default withStyles(styles)(PreviewLaunch)
