const styles = () => ({
  container: {
    width: '100%',
    paddingTop: '20px',
    backgroundColor: '#fff',
    '& .MuiTabs-root': {
      maxWidth: '300px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
})

export default styles
