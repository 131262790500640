import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { FormControlLabel } from '@material-ui/core'
import NxtCheckbox from 'components/nxt_checkbox/View'
import styles from './View.Style'

const OrganisationsSection = props => {
  const { classes, organisations, onOrganisationClick, organisationLabel } = props
  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <span className="icon-target" />
        <span className={classes.titleText}>{organisationLabel}</span>
      </div>
      <span className={classes.infoText}></span>
      {organisations.map(organisation => (
        <FormControlLabel
          key={organisation.id}
          control={<NxtCheckbox isChecked={organisation.isActive} />}
          onClick={() => onOrganisationClick({ ...organisation, isActive: !organisation.isActive })}
          label={organisation.name}
        />
      ))}
    </div>
  )
}

OrganisationsSection.propTypes = {
  organisationLabel: PropTypes.string.isRequired,
  organisations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onOrganisationClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(OrganisationsSection)
