const styles = () => ({
  container: {
    width: '100%',
    paddingTop: '50px',
    paddingBottom: '50px',
    '& .pie-label-wrapper': {
      paddingRight: '10px',
    },
    '& .pie-label': {
      backgroundColor: "#eee",
      padding: '10px 10px 15px',
    },
    '& .title': {
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    '& .title > span': {
      padding: '3px 6px',
      borderRadius: '8px',
      backgroundColor: '#fff',
      width: '10px',
      height: '10px',
      color: '#666',
      marginLeft: '5px',
    },
    '& .pie-label .subtitle': {
      color: '#666',
      fontSize: '12px',
      fontWeight: 'bold',
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  graph: {
    flex: 2
  },
  filters: {
    flex: 1,
    padding: '0 20px'
  },
  dates: {
    display: 'flex',
    flexDirection: 'row'
  },
  datesLabel: {
    fontWeight: 700,
    marginBottom: '15px'
  },
  and: {
    paddingLeft: '20px',
    paddingRight: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #f0f0f0',
    borderBottom: '1px solid #f0f0f0',
    marginTop: '20px',
    marginBottom: '20px',
  },
  legend: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingRight: '15px',
  },
  legendColor: {
    display: 'inline-block',
    width: '10px',
    height: '10px',
  },
  legendText: {
    display: 'inline-block',
    color: '#5c5b5b',
    fontSize: '0.9rem',
    paddingLeft: '10px',
  },
  odd: {
    color: '#5c5b5b',
    backgroundColor: '#fafafa',
    borderTop: '1px solid #ececec',
    borderBottom: '1px solid #ececec',
    fontSize: '0.9rem',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  even: {
    color: '#5c5b5b',
    backgroundColor: 'transparent',
    borderTop: '1px solid #ececec',
    borderBottom: '1px solid #ececec',
    fontSize: '0.9rem',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
})

export default styles
