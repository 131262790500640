import parse from 'date-fns/parse'
import { enUS, es, fr, nl } from 'date-fns/locale'
import i18n from 'support/i18n'

export function stringToTimestamp(date) {
  const timestamp = parse(date, 'MMM dd, yyyy hh:mm:ss a', new Date(), { locale: enUS }).getTime()
  return timestamp
}

export function getIntervalIndex(date, interval) {
  const dt = new Date(date.getTime())

  if (interval) {
    dt.setSeconds(0)
    dt.setMinutes(0)
    dt.setHours(0)
  }

  return dt.getTime()
}

export function getNames(type = 'MONTHS', substring = 0) {
  const months = [
    'JAN', 
    'FEB', 
    'MAR', 
    'APR', 
    'MAY', 
    'JUN', 
    'JUL', 
    'AUG', 
    'SEP', 
    'OCT', 
    'NOV', 
    'DEC', 
  ]
  const days = [
    'SUN', 
    'MON', 
    'TUE', 
    'WED', 
    'THU', 
    'FRI', 
    'SAT', 
  ]
  const keys = type === 'MONTHS' ? months : days
  const names = []

  keys.forEach(key => {
    const fullKey = `DATES.${type}.${key}`
    names.push(substring ? i18n.t(fullKey).substring(0, substring) : i18n.t(fullKey))
  });

  return names
}

export function locale(lang) {
  const locales = { en: enUS, es, fr, nl }

  return locales[lang]
}
