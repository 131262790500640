const styles = theme => ({
  cards: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: '1rem',
  },
  cardContainer: {
    position: 'relative',
    width: '146px',
    height: '146px',
    margin: '4px',
    '& img': {
      width: '100%',
      height: '100%',
      display: 'block',
    }
  },
  userInfo: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,
    padding: '0 10px',
    width: '100%',
    background: 'rgba(0,0,0,.7)',
    color: '#fff',
    fontWeight: '700',
    fontSize: '0.9rem',
    lineHeight: '35px',
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main,
  },
  row: {
    zIndex: 1,
    color: '#fff',
    borderBottom: '1px dotted rgba(255, 255, 255, 0.6)',
  },
  noBorder: {
    borderBottom: 'none',
  },
  button: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 2,
  },
  title: {
    fontSize: '0.8rem',
  },
  data: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.6rem',
    fontWeight: 700,
  },
  number: {
    flex: 1,
  },
  icon: {
    flex: 1,
    textAlign: 'right',
  },
  loadMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
})

export default styles
