import React, { useCallback, useEffect, useState } from 'react'
import {
  Avatar,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import i18n from 'support/i18n'
import DeleteIcon from '@material-ui/icons/Delete'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import { ShowIcon, generateUUID } from './utils'

const useStyles = makeStyles({
  gridContainer: {
    border: '2px dashed #b3b3b3',
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#D6D6D6',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
  cardContainer: {
    padding: 5,
    marginTop: 10,
  },
  avatar: {
    color: '#000',
  },
  loading: {
    padding: 5,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export const LIMIT_SIZE_FILE = 3000 // kB

const UploadFile = props => {
  const classes = useStyles()
  const { isDisabled, marketFile, onSubmitFile, onDeleteFile, isFileLoading, toSubmitInfo } = props
  const [fileErrors, setFileErrors] = useState([])

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      onSubmitFile(acceptedFiles[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fileValidator = file => {
    Object.assign(file, {
      id: generateUUID(),
    })

    if (file.type !== 'application/pdf') {
      return {
        code: 'file-bad-type',
        message: i18n.t('FORMS.ERRORS.FILE_UPLOAD_FORMAT'),
      }
    }

    // FILE SIZE TYPED IN BYTES
    if (file.size / 1000 > LIMIT_SIZE_FILE) {
      return {
        code: 'file-too-large',
        message: i18n.t('FORMS.ERRORS.FILE_UPLOAD_SIZE'),
      }
    }

    return null
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    validator: fileValidator,
  })

  useEffect(() => {
    setFileErrors(fileRejections)
  }, [fileRejections])

  useEffect(() => {
    toSubmitInfo(marketFile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketFile])

  return (
    <>
      <Grid
        {...getRootProps()}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12}>
          <input
            {...getInputProps()}
            style={{ display: 'none' }}
            disabled={isDisabled || isFileLoading || marketFile?.id !== ''}
          />
          <span className="icon-upload" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Subir archivos</Typography>
        </Grid>
      </Grid>
      {isFileLoading && (
        <Card className={classes.loading}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                <CircularProgress />
              </Avatar>
            }
          />
        </Card>
      )}
      {!isFileLoading &&
        fileErrors?.map(({ file, errors }) => (
          <Card className={classes.cardContainer} style={{ border: '1px solid red' }} key={file.id}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  <PriorityHighIcon color="error" />
                </Avatar>
              }
              action={
                <IconButton aria-label="remove" onClick={() => setFileErrors([])}>
                  <HighlightOffIcon color="error" />
                </IconButton>
              }
              title={file.name}
              subheader={errors?.map(item => item.message)}
            />
          </Card>
        ))}
      {!isFileLoading && marketFile?.id !== '' && (
        <Card className={classes.cardContainer} key={marketFile.id}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                <span className={`fcon-${ShowIcon(marketFile.name)} icon`} />
              </Avatar>
            }
            action={
              <IconButton aria-label="remove" onClick={() => onDeleteFile(marketFile.key)}>
                <DeleteIcon color="error" />
              </IconButton>
            }
            title={marketFile.name}
            subheader={`${i18n.toCurrency(marketFile.size / 1024, {
              unit: '',
              precision: 0,
            })} KB`}
          />
        </Card>
      )}
    </>
  )
}

export default UploadFile
