const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  reportLinkButton: {
    fontSize: '0.75rem',
    color: theme.palette.primary.main,
  },
})

export default styles
