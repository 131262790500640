export default [
  { name: 'APPROVED_BY_IT', id: 'APPROVED_BY_IT', value: 'approved' },
  { name: 'DISCARDED', id: 'DISCARDED', value: 'deprecated' },
  { name: 'IMPLEMENTED', id: 'IMPLEMENTED', value: 'implemented' },
  { name: 'PENDING_SUPPORT', id: 'PENDING_SUPPORT', value: 'raising-capital' },
  { name: 'PILOT_ACTIVE', id: 'PILOT_ACTIVE', value: 'prototyped' },
  { name: 'ROUND_CLOSED', id: 'ROUND_CLOSED', value: 'round-closed' },
  { name: 'SUPPORTED', id: 'SUPPORTED', value: 'funded' },
]
export const TYPE_OF_TIME = [
  { name: 'LOGISTIC.DAYS', id: 'days' },
  { name: 'LOGISTIC.WEEKS', id: 'weeks' },
  { name: 'LOGISTIC.MONTHS', id: 'months' },
]
