const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0, 3, 0),
  },
  paper: {
    padding: theme.spacing(2),
  },
  listContainer: {
    width: '100%',
    maxHeight: '250px',
    overflow: 'auto',
    border: '1px solid #eeeeee',
    marginTop: theme.spacing(1),
    paddingLeft: 0,
    '& > li:first-child': {
      borderTop: 'unset',
    },
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '0.6fr 0.3fr 0.1fr',
    gridTemplateAreas: '"left middle right"',
    borderTop: 'solid #eeeeee 1px',
    padding: theme.spacing(0.5, 0),
  },
  leftContent: {
    gridArea: 'left',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '& > span': {
      paddingLeft: theme.spacing(1),
    },
    '& a': {
      color: theme.palette.primary.main,
    },
    '& .icon': {
      color: "#5f5f5f",
      fontSize: '22px',
    }
  },
  middleContent: {
    gridArea: 'middle',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
  },
  rightContent: {
    gridArea: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > span': {
      fontSize: '12px',
    },
  },
  iconPadded: {
    padding: theme.spacing(2),
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    fontSize: '12px',
  },
})

export default styles
