import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import styles from './View.Style'

export const renderFilterText = {
  "ACTIVE": i18n.t('CHALLENGES.FILTER.ACTIVE'),
  "PROPOSED": i18n.t('CHALLENGES.FILTER.PROPOSED'),
  "PLANNED": i18n.t('CHALLENGES.FILTER.COMING_SOON'),
  "DELETED": i18n.t('CHALLENGES.FILTER.DELETED'),
  "FINISHED": i18n.t('CHALLENGES.FILTER.FINISHED')
}

const NxtEmptyCards = ({ classes, isChallenge, isPrivate, filteredName, customIdeaTitles }) => {
  if (isPrivate) {
    return (
      <div className={classes.container}>
        <div>
          <h1>{i18n.t('CHALLENGES.EMPTY_TITLE')}</h1>
          <p>
            {isChallenge ? (
              `${i18n.t('NO_CHALLENGES_FOUND')} ${renderFilterText[filteredName]}`
            ) : (
              i18n.t('NO_IDEAS_FOUND', { title: customIdeaTitles.pluralTitle || "ideas" })
            )}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div>
        <h1>{i18n.t('CHALLENGES.EMPTY_TITLE')}</h1>
        <p>
          {isChallenge ? (
            `${i18n.t('NO_CHALLENGES_FOUND')} ${renderFilterText[filteredName]}`
          ) : (
            i18n.t('NO_IDEAS_FOUND', { title: customIdeaTitles.pluralTitle || "ideas" })
          )}
        </p>
      </div>
    </div>
  )
}



export default withStyles(styles)(NxtEmptyCards)
