import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, CircularProgress } from '@material-ui/core'
import styles from './View.Style'

const NxtProgressCircle = props => {
  const { classes, value, mainColor, size, thickness, children, backgroundColor } = props
  return (
    <div className={classes.root}>
      <CircularProgress
        className={classes.outerCircle}
        thickness={thickness}
        variant="determinate"
        size={size}
        value={value}
        style={{ color: mainColor }}
      />
      <CircularProgress
        className={classes.innerCircle}
        thickness={thickness}
        variant="determinate"
        size={size}
        value={100}
        style={{ color: backgroundColor }}
      />
      <div className={classes.children}>{children}</div>
    </div>
  )
}

NxtProgressCircle.defaultProps = {
  backgroundColor: '#eeeeee',
}

NxtProgressCircle.propTypes = {
  value: PropTypes.number.isRequired,
  mainColor: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  thickness: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
}

export default withStyles(styles)(NxtProgressCircle)
