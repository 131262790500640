import { ServiceCall } from '../../support/network/ServiceCallAction'
import * as howItWorks from '../../actions/sync/how_it_works/Constants'
import {
  GET_DOMAIN_LOGIN_INFO_FAILURE,
  GET_DOMAIN_LOGIN_INFO_STARTED,
  GET_DOMAIN_LOGIN_INFO_SUCCESS,
  GET_GDPR_INFO_STARTED,
  GET_GDPR_INFO_SUCCESS,
  GET_GDPR_INFO_FAILURE,
  GET_USER_DOMAINS_STARTED,
  GET_USER_DOMAINS_SUCCESS,
  GET_USER_DOMAINS_FAILURE,
} from '../../actions/sync/domain/Constants'
import {
  transformApprovedReasonsResponseDataWithState,
  transformConfigResponseDataWithState,
  transformFullInfoResponseDataWithState,
  transformRejectedReasonsResponseDataWithState,
  transformInfoPointsResponse,
  transformTotalInvestmentsResponse,
  transformNewIdeasResponse,
  transformVisitsIdeas,
  parseResponseLogin,
  parseUserDomainsResponse,
} from './ResponseUtils'
import { prepareHeadersFromState } from '../HeadersUtils'
import {
  APPROVED_REASONS_FAILURE,
  APPROVED_REASONS_STARTED,
  APPROVED_REASONS_SUCCESS,
  CONFIG_FAILURE,
  CONFIG_STARTED,
  CONFIG_SUCCESS,
  FULL_INFO_FAILURE,
  FULL_INFO_STARTED,
  FULL_INFO_SUCCESS,
  REJECT_REASONS_FAILURE,
  REJECT_REASONS_STARTED,
  REJECT_REASONS_SUCCESS,
  GET_TOTAL_INVESTMENTS_FAILURE,
  GET_TOTAL_INVESTMENTS_STARTED,
  GET_TOTAL_INVESTMENTS_SUCCESS,
  GET_NEW_IDEAS_FAILURE,
  GET_NEW_IDEAS_STARTED,
  GET_NEW_IDEAS_SUCCESS,
} from '../TypesConstants'
import {
  GET_VISIT_IDEA_FAILURE,
  GET_VISIT_IDEA_STARTED,
  GET_VISIT_IDEA_SUCCESS,
} from '../../actions/sync/ideas/ideasActionConstans'

export const FULL_INFO_URL = '/s/domain/fullinfo'
export const CONFIG_URL = '/s/domain/conf'
export const REJECT_REASONS_URL = '/s/domain/reasons'
export const APPROVED_REASONS_URL = '/s/domain/approveReasons'
const POINTS_INFO_URL = '/s/domain/infosteps'
const GET_DOMAIN_LOGIN_INFO_URL = '/b/domainLoginInfo/##domain##'
const GET_GDPR_INFO = '/b/getDataProtection/##language##'
const GET_USER_DOMAINS_ENPOINT = '/b/##email##/domains'

export function fullInfoService(onSuccess = () => {}, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = FULL_INFO_STARTED
  apiCall.successReqType = FULL_INFO_SUCCESS
  apiCall.failureReqType = FULL_INFO_FAILURE
  apiCall.setEndpointFromState = () => FULL_INFO_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformFullInfoResponseDataWithState
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function configService() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = CONFIG_STARTED
  apiCall.successReqType = CONFIG_SUCCESS
  apiCall.failureReqType = CONFIG_FAILURE
  apiCall.setEndpointFromState = () => CONFIG_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformConfigResponseDataWithState
  return apiCall.getAction()
}

export function getRejectedReasons() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = REJECT_REASONS_STARTED
  apiCall.successReqType = REJECT_REASONS_SUCCESS
  apiCall.failureReqType = REJECT_REASONS_FAILURE
  apiCall.setEndpointFromState = () => REJECT_REASONS_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformRejectedReasonsResponseDataWithState
  return apiCall.getAction()
}

export function getApprovedReasons() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = APPROVED_REASONS_STARTED
  apiCall.successReqType = APPROVED_REASONS_SUCCESS
  apiCall.failureReqType = APPROVED_REASONS_FAILURE
  apiCall.setEndpointFromState = () => APPROVED_REASONS_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformApprovedReasonsResponseDataWithState
  return apiCall.getAction()
}

export function getHowItWorksInfoPointsService() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = howItWorks.POINTS_INFO_STARTED
  apiCall.successReqType = howItWorks.POINTS_INFO_SUCCESS
  apiCall.failureReqType = howItWorks.POINTS_INFO_FAILURE
  apiCall.setEndpointFromState = () => POINTS_INFO_URL
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformInfoPointsResponse
  return apiCall.getAction()
}

export function getDomainLoginInfo(domain, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_DOMAIN_LOGIN_INFO_STARTED
  apiCall.successReqType = GET_DOMAIN_LOGIN_INFO_SUCCESS
  apiCall.failureReqType = GET_DOMAIN_LOGIN_INFO_FAILURE
  apiCall.setEndpointFromState = () => GET_DOMAIN_LOGIN_INFO_URL.replace('##domain##', domain)
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = parseResponseLogin
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function getGDPR() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_GDPR_INFO_STARTED
  apiCall.successReqType = GET_GDPR_INFO_SUCCESS
  apiCall.failureReqType = GET_GDPR_INFO_FAILURE
  apiCall.setEndpointFromState = () => GET_GDPR_INFO.replace('##language##', 'es')
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = jsonResponse => jsonResponse
  return apiCall.getAction()
}

export function getTotalInvestments() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_TOTAL_INVESTMENTS_STARTED
  apiCall.successReqType = GET_TOTAL_INVESTMENTS_SUCCESS
  apiCall.failureReqType = GET_TOTAL_INVESTMENTS_FAILURE
  apiCall.setEndpointFromState = () => FULL_INFO_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformTotalInvestmentsResponse
  return apiCall.getAction()
}

export function getNewIdeas() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_NEW_IDEAS_STARTED
  apiCall.successReqType = GET_NEW_IDEAS_SUCCESS
  apiCall.failureReqType = GET_NEW_IDEAS_FAILURE
  apiCall.setEndpointFromState = () => FULL_INFO_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformNewIdeasResponse
  return apiCall.getAction()
}

export function getVisitsIdeas() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_VISIT_IDEA_STARTED
  apiCall.successReqType = GET_VISIT_IDEA_SUCCESS
  apiCall.failureReqType = GET_VISIT_IDEA_FAILURE
  apiCall.setEndpointFromState = () => FULL_INFO_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformVisitsIdeas
  return apiCall.getAction()
}

export function getUserDomains(email, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_USER_DOMAINS_STARTED
  apiCall.successReqType = GET_USER_DOMAINS_SUCCESS
  apiCall.failureReqType = GET_USER_DOMAINS_FAILURE
  apiCall.setEndpointFromState = () => GET_USER_DOMAINS_ENPOINT.replace('##email##', email)
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = parseUserDomainsResponse
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}
