import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: theme.spacing(-1),
      padding: '1rem 0',
      '& > button': {
        margin: theme.spacing(0.5),
      },
    },
  })

export default styles
