const styles = theme => ({
  root: {
    position: 'relative',
    width: '500px',
    marginBottom: theme.spacing(2),
    '& img': {
      width: '100%',
      overflow: 'hidden',
      display: 'block',
      height: '310px',
      objectFit: 'cover',
    },
  },
  overlay: {
    background: 'rgba(51, 51, 51, 0.5)',
    position: 'absolute',
    top: '258px',
    zIndex: 1,
    width: '100%',
    '& > div': {
      padding: theme.spacing(1),
    },
  },
  bottom: {
    background: 'rgba(51, 51, 51, 0.5)',
    width: '100%',
    '& > div': {
      padding: theme.spacing(1),
    },
  },
  iframeVideo: {
    maxHeight: '310px',
  },
  video: {
    width: '100%',
    height: '310px'
  },
})

export default styles
