import React, { Fragment, useEffect } from 'react'
import { Redirect, Route, Switch, matchPath } from 'react-router'
import * as PropTypes from 'prop-types'
import { Footer, Header } from 'components'
import * as pages from 'common_constants/Pages'
import {
  ChallengeDetail,
  ChallengeForm,
  Challenges,
  Error,
  Explore,
  HowItWorks,
  Investments,
  LegalAcceptance,
  MyIdeas,
  PendingValidation,
  PrivacyCookies,
  PrivateIdeas,
  Profile,
  Ranking,
  UserPreferences,
  Dashboard,
} from 'pages'
import IdeaForm from 'pages/idea/form'
import IdeaDetail from 'pages/idea/detail'
import { TAB_TYPES } from './View.Constants'
import NavigationService from '../../NavigationService'

const LoggedInNavigator = (props) => {
  const {
    appVersion,
    classes,
    copyrightYear,
    newIdeas,
    loggedIn,
    logoUrl,
    navigateToExplore,
    navigateToMyIdeas,
    navigateToInvestments,
    navigateToChallenges,
    notifications,
    showAdmin,
    submitYourIdea,
    userBalance,
    userName,
    userPhoto,
    totalInvestments,
    customFooterText,
    markNotificationsRead,
    onLogoClick,
    onCopyrightClick,
    currencyIcon,
    domains,
    navigateToDomain,
    isPrivateFlowActive,
    currentLocation,
    userLegalAcceptance,
    navigateToAdmin,
    navigateToLogout,
    domainFailure,
    customTitlesConf
  } = props

  const ROUTES = NavigationService.getRoutes()

  const isLocationInThisPaths = (paths, location) =>
    !!matchPath(location, {
      path: paths,
      exact: true,
      strict: false,
    })

  const getActiveTabType = location => {
    const exploreTabPaths = [ROUTES[pages.EXPLORE_PAGE].path]
    const investmentsTabPaths = [ROUTES[pages.INVESTMENTS_PAGE].path]
    const challengesTabPaths = [ROUTES[pages.CHALLENGES_PAGE].path]

    const myIdeasTabPaths = [
      ROUTES[pages.MY_IDEAS_PAGE].path,
      ROUTES[pages.EDIT_IDEA].path,
      ROUTES[pages.NEW_IDEA].path,
      ROUTES[pages.NEW_IDEA_CHALLENGE_SELECTED].path,
      ROUTES[pages.NEW_PRIVATE_IDEA].path,
    ]

    switch (true) {
      case isLocationInThisPaths(exploreTabPaths, location):
        return TAB_TYPES.EXPLORE
      case isLocationInThisPaths(myIdeasTabPaths, location):
        return TAB_TYPES.MY_IDEAS
      case isLocationInThisPaths(investmentsTabPaths, location):
        return TAB_TYPES.INVESTMENTS
      case isLocationInThisPaths(challengesTabPaths, location):
        return TAB_TYPES.CHALLENGES
      default:
        return TAB_TYPES.NONE
    }
  }

  useEffect(() => {
    if (domainFailure) {
      navigateToLogout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainFailure])

  return (
    <div className={classes.base}>
      {userLegalAcceptance ? (
        <Fragment>
          <Header
            activeTab={getActiveTabType(currentLocation)}
            logoUrl={logoUrl}
            onLogoClick={onLogoClick}
            navigateToExplore={navigateToExplore}
            navigateToMyIdeas={navigateToMyIdeas}
            navigateToInvestments={navigateToInvestments}
            navigateToChallenges={navigateToChallenges}
            notifications={notifications}
            showAdmin={showAdmin}
            userBalance={userBalance}
            userName={userName}
            userPhoto={userPhoto}
            currencyIcon={currencyIcon}
            domains={domains}
            navigateToDomain={navigateToDomain}
            markNotificationsRead={markNotificationsRead}
            customTitlesConf={customTitlesConf}
          />
          <div className={classes.main}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />} />
              <Route
                exact
                path={ROUTES[pages.ROOT_LOGGED_PAGE].path}
                render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
              />
              <Route
                exact
                path={ROUTES[pages.DASHBOARD].path}
                render={componentProps => <Dashboard {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.DETAIL_CHALLENGE].path}
                render={componentProps => <ChallengeDetail {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.PROPOSE_CHALLENGE].path}
                render={componentProps => <ChallengeForm {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.NEW_CHALLENGE].path}
                render={componentProps => <ChallengeForm {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.EDIT_CHALLENGE].path}
                render={componentProps => <ChallengeForm {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.CHALLENGES_PAGE].path}
                render={(componentProps) => customTitlesConf.isChallenge
                  ? <Challenges {...componentProps} />
                  : <Error />
                }
              />
              <Route
                exact
                path={ROUTES[pages.EXPLORE_PAGE].path}
                render={componentProps => <Explore {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.HOW_IT_WORKS].path}
                render={componentProps => <HowItWorks {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.NEW_IDEA].path}
                render={componentProps => <IdeaForm key="new" {...componentProps} />}
              />
              {isPrivateFlowActive && (
                <Route
                  exact
                  path={ROUTES[pages.NEW_PRIVATE_IDEA].path}
                  render={componentProps => <IdeaForm isPrivate key="new" {...componentProps} />}
                />
              )}
              <Route
                exact
                path={ROUTES[pages.NEW_IDEA_CHALLENGE_SELECTED].path}
                render={componentProps => <IdeaForm key="newChallenge" {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.EDIT_IDEA].path}
                render={componentProps => <IdeaForm key="edit" {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.DETAIL_IDEA].path}
                render={componentProps => <IdeaDetail {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.INVESTMENTS_PAGE].path}
                render={componentProps => customTitlesConf.isInvestment
                  ? <Investments {...componentProps} />
                  : <Error />
                }
              />
              <Route
                exact
                path={ROUTES[pages.LEGAL_ACCEPTANCE_PAGE].path}
                render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
              />
              <Route
                exact
                path={ROUTES[pages.MY_IDEAS_PAGE].path}
                render={componentProps => <MyIdeas {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.PENDING_VALIDATION_PAGE].path}
                render={componentProps => <PendingValidation {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.PRIVACY_COOKIES_PAGE].path}
                render={componentProps => <PrivacyCookies {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.PRIVATE_IDEAS_PAGE].path}
                render={componentProps => <PrivateIdeas {...componentProps} />}
              />
              {!!window.history.state?.profile && (
                <Redirect to={window.history.state.profile} />
              )}
              <Route
                exact
                path={ROUTES[pages.PROFILE_PAGE].path}
                render={componentProps => <Profile {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.PROFILE_PAGE_USER].path}
                render={componentProps => <Profile {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.RANKING_PAGE].path}
                render={
                  (componentProps) => customTitlesConf.isRanking
                    ? <Ranking {...componentProps} />
                    : <Error />
                }
              />
              <Route
                exact
                path={ROUTES[pages.USER_PREFERENCES_PAGE].path}
                render={componentProps => <UserPreferences {...componentProps} />}
              />
              <Route
                exact
                path={ROUTES[pages.LOGIN_PAGE].path}
                render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
              />
              <Route
                exact
                path={ROUTES[pages.AZURE_LOGIN].path}
                render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
              />
              <Route
                exact
                path={ROUTES[pages.ADFS_LOGIN].path}
                render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
              />
              <Route
                exact
                path={ROUTES[pages.IDENTITY_CHOOSE_PAGE].path}
                render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
              />
              <Route
                exact
                path={ROUTES[pages.CUSTOM_LOGIN_PAGE].path}
                render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
              />
              <Route
                render={() => (loggedIn ? <Error /> : <Redirect to={ROUTES[pages.LOGIN_PAGE].path} />)}
              />
            </Switch>
          </div>
          <Footer
            appVersion={appVersion}
            copyrightYear={copyrightYear}
            logoUrl={logoUrl}
            newIdeas={newIdeas}
            showAdmin={showAdmin}
            submitYourIdea={submitYourIdea}
            totalInvestments={totalInvestments}
            customFooterText={customFooterText}
            onLogoClick={onLogoClick}
            onCopyrightClick={onCopyrightClick}
            isPrivate={isPrivateFlowActive}
            onAdminClick={navigateToAdmin}
            customIdeaTitles={customTitlesConf.customIdeaTitles}
          />
        </Fragment>
      ) : (
        <Switch>
          <Route
            exact
            path={ROUTES[pages.LEGAL_ACCEPTANCE_PAGE].path}
            render={componentProps => <LegalAcceptance {...componentProps} />}
          />
          <Redirect from="*" to={ROUTES[pages.LEGAL_ACCEPTANCE_PAGE].path} />
        </Switch>
      )}
    </div>
  )
}

LoggedInNavigator.defaultProps = {
  customFooterText: '',
  logoUrl: '',
  userPhoto: '',
}

LoggedInNavigator.propTypes = {
  classes: PropTypes.shape({
    base: PropTypes.string.isRequired,
  }).isRequired,
  logoUrl: PropTypes.string,
  notifications: PropTypes.shape({
    notifications: PropTypes.arrayOf(PropTypes.shape({})),
    activities: PropTypes.arrayOf(PropTypes.shape({})),
    totalUnread: PropTypes.number,
    unreadActivities: PropTypes.number,
    unreadNotifications: PropTypes.number,
  }).isRequired,
  userBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string,
  currentLocation: PropTypes.string.isRequired,
  customFooterText: PropTypes.string,
  domains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  navigateToDomain: PropTypes.func.isRequired,
  markNotificationsRead: PropTypes.func.isRequired,
  onCopyrightClick: PropTypes.func.isRequired,
  onLogoClick: PropTypes.func.isRequired,
  navigateToLogout: PropTypes.func.isRequired,
  domainFailure: PropTypes.bool.isRequired,
  customTitlesConf: PropTypes.shape({
    customIdeaTitles: PropTypes.shape({
      singularTitle: PropTypes.string,
      pluralTitle: PropTypes.string
    }),
    isChallenge: PropTypes.bool,
    isInvestment: PropTypes.bool
  }).isRequired
}

export default LoggedInNavigator
