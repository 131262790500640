import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    content: {
      gridArea: 'content',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '"loginContent"',
    },
    form: {
      display: 'flex',
      maxWidth: '31rem',
      height: 'auto',
      maxHeight: '24rem',
      flexDirection: 'column',
      background: theme.palette.common.white,
      borderRadius: '0.3rem',
      color: theme.palette.common.black,
      gridArea: 'loginForm',
      padding: '1rem',
    },
    login: {
      gridArea: 'loginContent',
      padding: '0 3rem',
      display: 'grid',
      gridTemplateColumns: '1fr 31rem 1fr',
      gridTemplateRows: '25% 1fr 1fr',
      gridTemplateAreas: '". loginTitle ." ". loginForm ."',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      backgroundColor: props =>
        props.useCustomColor
          ? props.customColor
          : theme.palette.primary.main,
    color: props =>
      props.useCustomTextColor && props.customTextColor
        ? props.customTextColor
        : '',
        '&:hover': {
          backgroundColor: props =>
            props.useCustomColor ? props.customColor : '',
        },
    },
    terms: {
      gridArea: 'loginForm',
    },
    title: {
      display: 'flex',
      alignItems: 'flex-end',
      gridArea: 'loginTitle',
      paddingBottom: theme.spacing(2),
      '& > h1': {
        fontFamily: '"Open Sans", open-sans, sans-serif',
        fontSize: '28px',
        fontWeight: '400',
        margin: 0,
        '& small': {
          display: 'block',
          marginTop: '10px',
          fontSize: '16px',
        },
      },
    },
    forgotContainer: {
      textAlign: 'left',
      marginTop: theme.spacing(2),
      fontSize: '0.75rem',
    },
    buttonProgress: {
      position: 'absolute',
    },
  })

export default styles
