const styles = theme => ({
  root: {
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    opacity: '0.7',
    width: '100%',
    height: '100%',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    '& th:nth-child(2) > div:last-child': {
      borderBottom: '3px solid',
    },
    '& th:nth-child(3) > div:last-child': {
      borderBottom: '3px solid',
    },
    '& th:nth-child(4) > div:last-child': {
      borderBottom: '3px solid',
    },
    '& th:nth-child(5) > div:last-child': {
      borderBottom: '3px solid',
    },
    '& th:nth-child(6) > div:last-child': {
      borderBottom: '3px solid',
    },
    '& th:nth-child(7) > div:last-child': {
      borderBottom: '3px solid',
    },
    '& th:nth-child(8) > div:last-child': {
      borderBottom: '3px solid',
    },
    '& th:nth-child(9) > div:last-child': {
      borderBottom: '3px solid',
    },
    '& th:nth-child(10) > div:last-child': {
      borderBottom: '3px solid',
    },
  },
  headerColumn: {
    fontSize: '11px',
    fontWeight: '400',
    padding: '0 4px 7px',
    width: props => `${85 / props.header.length}%`,
    '& > div > [class^="icon-"]': {
      fontSize: '37px',
      color: '#999',
      paddingBottom: theme.spacing(1),
    },
    '& > div': {
      backgroundColor: '#f4f4f4',
      display: 'flex',
      flexDirection: 'column',
      padding: '18px 6px',
    },
  },
  headerTitle: {
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '& > div': {
      backgroundColor: '#fff',
      width: '50%',
    },
  },
  cell: {
    border: '1px solid #dcdcdc',
    padding: '30px 10px',
    textAlign: 'center',
  },
  wideColummn: {
    border: 'none',
    width: '15%',
  },
  divider: {
    border: 'none',
    '& > div': {
      borderLeft: '1px solid #ddd',
    }
  },
  twoRowsText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subText: {
    color: '#9e9e9e',
    fontSize: '12px',
  },
  amount: {
    fontFamily: 'HelveticaNeueCondensedBold,Helvetica Neue,sans-serif',
    fontStretch: 'condensed',
    fontWeight: 'bold',
    fontSize: '20px',
    fontSmoothing: 'antialiased',
  },
  positiveComparison: {
    color: '#00d24c',
    backgroundColor: 'rgba(0,210,76,.1)',
  },
  negativeComparison: {
    color: '#fa5d7f',
    backgroundColor: 'rgba(250,93,127,.1)',
  },
  equalComparison: {
    color: '#ddd',
    backgroundColor: '#fff',
  },
})

export default styles
