import { connect } from 'react-redux'
import AlertModalView from 'support/popup_dialogs/views/components/alert_modal_view/AlertModalView'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import i18n from 'support/i18n'

const mapStateToProps = (state) => ({
  title: i18n.t('EXPLORE_IDEA.REPORT_IDEA_OK_TITLE'),
  description: i18n.t('EXPLORE_IDEA.REPORT_IDEA_OK'),
  mainButtonText: i18n.t('ACCEPT'),
  customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles
})

const mapDispatchToProps = dispatch => {
  return {
    mainButtonAction: () => {
      dispatch(hideDialog())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModalView)
