import { VZAAR_STATES } from 'common_constants/Video'
import { generateRequestCsrf } from 'support/utils/auth'

export const transformConfirmationVideoResponse = jsonResponse => ({
  isVideoReady: jsonResponse.state === VZAAR_STATES.READY,
  hasVideoError: jsonResponse.state === VZAAR_STATES.FAILED,
  videoId: jsonResponse.id,
})

export const transformUploadVideoResponse = jsonResponse => ({
  video: jsonResponse.playerUrl,
  videoId: `${jsonResponse.id}`,
  isVideoReady: jsonResponse.state === VZAAR_STATES.READY,
})

export const transformStorageVideoUploadUrlResponse = jsonResponse => ({
  storageVideoUploadUrl: jsonResponse.uploadUrl
})

export const transformStorageVideoResponse = (jsonResponse, state, videoEndpoint) => ({
  video: jsonResponse.files[0],
  videoUrl: `${videoEndpoint}/${jsonResponse.files[0].key}`,
  videoSrc: `${videoEndpoint}/${jsonResponse.files[0].key}?_nxt_csrf=${generateRequestCsrf(state.auth.csrf)}`,
})

export const transformVideoResponse = headers => ({
  type: headers['content-type'],
})
