import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import clsx from 'clsx'
import NxtLogisticCurrency from 'components/nxt_logistic_currency'
import styles from './View.Style'

class DefaultLogisticRow extends Component {
  constructor(props) {
    super(props)
    this.getContainerClass = this.getContainerClass.bind(this)
  }

  getContainerClass() {
    const { estimatedQuantity, estimatedHours, estimatedTime } = this.props

    let classWidth = 'isOne'
    if (estimatedQuantity && estimatedHours && estimatedTime) {
      classWidth = 'isThree'
    } else if (
      (estimatedQuantity && estimatedHours) ||
      (estimatedQuantity && estimatedTime) ||
      (estimatedTime && estimatedHours)
    ) {
      classWidth = 'isTwo'
    } else if (estimatedQuantity) {
      classWidth = 'isOneQuantity'
    }
    return classWidth
  }

  render() {
    const {
      classes,
      titleIcon,
      titleText,
      estimatedQuantity,
      estimatedQuantityLabel,
      estimatedHoursLabel,
      estimatedHours,
      estimatedTime,
      estimatedTimeType,
    } = this.props

    return (
      <div className={classes.root}>
        <span className={titleIcon} />
        <div className={classes.valuesContainer}>
          <div className={classes.titleLogistic}>{titleText}</div>
          <Paper className={clsx(classes.valuesLogistic, classes[this.getContainerClass()])}>
            {estimatedQuantity.length > 0 && (
              <div className={classes.estimatedQuantity}>
                <span>{estimatedQuantityLabel}</span>
                <span>
                  <NxtLogisticCurrency>{estimatedQuantity}</NxtLogisticCurrency>
                </span>
              </div>
            )}
            {estimatedHours.length > 0 && (
              <div className={classes.estimatedHours}>
                <span>{estimatedHoursLabel}</span>
                <span>{estimatedHours}</span>
              </div>
            )}
            {estimatedTime.length > 0 && (
              <div className={classes.estimatedTime}>
                <span>{estimatedTimeType}</span>
                <span>{estimatedTime}</span>
              </div>
            )}
          </Paper>
        </div>
      </div>
    )
  }
}

DefaultLogisticRow.defaultProps = {
  classes: {
    root: '',
    valuesContainer: '',
    titleLogistic: '',
    valuesLogistic: '',
    estimatedQuantity: '',
    estimatedHours: '',
    estimatedTime: '',
    isOne: '',
    isThree: '',
    isTwo: '',
    isOneQuantity: '',
  },
  estimatedQuantity: '',
  estimatedQuantityLabel: i18n.t('LOGISTIC.ESTIMATED_AMOUNT'),
  estimatedHours: '',
  estimatedHoursLabel: i18n.t('LOGISTIC.HOURSMAN'),
  estimatedTime: '',
  estimatedTimeType: '',
  titleIcon: '',
  titleText: '',
}

DefaultLogisticRow.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    valuesContainer: PropTypes.string,
    titleLogistic: PropTypes.string,
    valuesLogistic: PropTypes.string,
    estimatedQuantity: PropTypes.string,
    estimatedHours: PropTypes.string,
    estimatedTime: PropTypes.string,
    isOne: PropTypes.string,
    isThree: PropTypes.string,
    isTwo: PropTypes.string,
    isOneQuantity: PropTypes.string,
  }),
  titleIcon: PropTypes.string,
  titleText: PropTypes.string,
  estimatedQuantity: PropTypes.string,
  estimatedQuantityLabel: PropTypes.string,
  estimatedHours: PropTypes.string,
  estimatedHoursLabel: PropTypes.string,
  estimatedTime: PropTypes.string,
  estimatedTimeType: PropTypes.string,
}

export default withStyles(styles)(DefaultLogisticRow)
