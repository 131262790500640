const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '56px 1fr',
    gridTemplateRows: '56px 1fr',
    gridTemplateAreas: '"icon title" "bar images"',
  },
  title: {
    gridArea: 'title',
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    fontSize: '13px',
    '& > span:first-child': {
      fontSize: '24px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
  bar: {
    gridArea: 'bar',
    borderLeft: '1px dashed #666666',
    marginLeft: '50%',
  },
  images: {
    gridArea: 'images',
    paddingLeft: theme.spacing(2),
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 0,
    margin: 0,
  },
  icon: {
    gridArea: 'icon',
    color: '#666666',
    border: '1px dashed',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    '& sup': {
      fontSize: '13px',
    },
  },
})

export default styles
