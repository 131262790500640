import { initValuesForm } from 'pages/idea/detail/components/standarForm/components/utils'

export const setStandardFormBody = values => () => {
  const {
    personalData,
    companyInfo,
    marketInfo,
    socialNetworks,
    optionalQuestions,
    params,
  } = values

  const parsedInfo = {
    data: {
      form: [
        // PERSONAL DATA
        {
          value: personalData.name,
          name: 'pernom',
        },
        {
          value: personalData.lastName,
          name: 'perape',
        },
        {
          value: personalData.company,
          name: 'peremp',
        },
        {
          value: personalData.workPosition,
          name: 'percar',
        },
        // COMPANY INFO
        {
          value: companyInfo.cel,
          name: 'empcel',
        },
        {
          value: companyInfo.corporateEmail,
          name: 'empema',
        },
        {
          value: companyInfo.countryHeadOffice,
          name: 'emppai',
        },
        {
          value: companyInfo.otherCountries,
          name: 'empext',
        },
        {
          value: companyInfo.companyId,
          name: 'emprut',
        },
        {
          value: companyInfo.annualSales,
          name: 'empven',
        },
        {
          value: companyInfo.companySize,
          name: 'emptam',
        },
        {
          value: companyInfo.companyAge,
          name: 'empant',
        },
        {
          value: companyInfo.solutionName,
          name: 'empnom',
        },
        {
          value: companyInfo.solutionLevel,
          name: 'empmad',
        },
        {
          value: companyInfo.solutionDescription,
          name: 'empdes',
        },
        {
          value: companyInfo.valueProposal,
          name: 'empval',
        },
        // MARKET INFO
        {
          value: marketInfo.mainCompetitors,
          name: 'mercom',
        },
        {
          value: marketInfo.mainCustomers,
          name: 'mercli',
        },
        {
          value: marketInfo.file,
          name: 'merarc',
        },
        {
          value: marketInfo.acceleratorBelong,
          name: 'merace',
        },
        {
          value: marketInfo.seedCapital,
          name: 'mersem',
        },
        {
          value: marketInfo.whoFinances,
          name: 'merqui',
        },
        // SOCIAL NETWORKS
        {
          value: socialNetworks.webSite,
          name: 'redweb',
        },
        {
          value: socialNetworks.facebook,
          name: 'redfac',
        },
        {
          value: socialNetworks.twitter,
          name: 'redtwi',
        },
        {
          value: socialNetworks.instagram,
          name: 'redins',
        },
        {
          value: socialNetworks.linkedin,
          name: 'redlin',
        },
        {
          value: socialNetworks.others,
          name: 'redotr',
        },
        // OPTIONAL QUESTIONS
        {
          value: optionalQuestions.legalAgent,
          name: 'opcrep',
        },
        {
          value: optionalQuestions.companyAddress,
          name: 'opcdir',
        },
        {
          value: optionalQuestions.businessName,
          name: 'opcraz',
        },
      ],
      param: {
        idea: params.ideaId,
        user: params.userEmail,
        domain: params.currentDomain,
      },
    },
  }

  return JSON.stringify(parsedInfo)
}

export const parseStandardFormResponse = response => {
  // Backend return { success: false } when form doesnt exist
  // Backend can be return { success: false, error: 'some message' } when send form
  // Backend response the obj with id type number without { success: true }

  if (Object.keys(response).includes('success') && Object.keys(response).includes('error')) {
    return { messageFromBackend: response?.error || '', ...initValuesForm }
  }

  if (Object.keys(response).includes('success') && !Object.keys(response).includes('error')) {
    return { messageFromBackend: '', ...initValuesForm }
  }

  return {
    personalData: {
      name: response.pernom,
      lastName: response.perape,
      company: response.peremp,
      workPosition: response.percar,
    },
    companyInfo: {
      cel: response.empcel,
      corporateEmail: response.empema,
      countryHeadOffice: response.emppai,
      otherCountries: response.empext,
      companyId: response.emprut,
      annualSales: response.empven,
      companySize: response.emptam,
      companyAge: response.empant,
      solutionName: response.empnom,
      solutionLevel: response.empmad,
      solutionDescription: response.empdes,
      valueProposal: response.empval,
    },
    marketInfo: {
      mainCompetitors: response.mercom,
      mainCustomers: response.mercli,
      file: response.merarc,
      acceleratorBelong: response.merace,
      seedCapital: response.mersem,
      whoFinances: response.merqui,
    },
    socialNetworks: {
      webSite: response.redweb,
      facebook: response.redfac,
      twitter: response.redtwi,
      instagram: response.redins,
      linkedin: response.redlin,
      others: response.redotr,
    },
    optionalQuestions: {
      legalAgent: response.opcrep,
      companyAddress: response.opcdir,
      businessName: response.opcraz,
    },
  }
}

export const getStandardFormBody = values => () => {
  const parsedInfo = {
    data: {
      param: {
        idea: values.ideaId,
        user: values.userEmail,
        domain: values.currentDomain,
      },
    },
  }

  return JSON.stringify(parsedInfo)
}

export function transformFileUploadResponse(response) {
  const { id, name, size, type, url, key } = response

  return {
    id: id.toString(),
    name,
    size,
    type,
    url,
    key,
    src: url,
  }
}

export const parseDeleteFileStandardForm = () => {
  // Return 204 no-content from service
  return initValuesForm.marketInfo.file
}
