import { connect } from 'react-redux'
import { postUserPrefsService } from 'services/user'
import View from './View'

const mapStateToProps = state => {
  return {
    ideastatus: state.data.user.prefs.ideastatus,
    invest: state.data.user.prefs.invest,
    splash: state.data.user.prefs.splash,
    week: state.data.user.prefs.week,
    newidea: state.data.user.prefs.newidea,
    challenge: state.data.user.prefs.challenge,
    newcoment: state.data.user.prefs.newcoment,
    customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getIdeaStatusPref: (key, value) => {
      dispatch(postUserPrefsService(key, value))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
