import React, { useEffect, useState } from 'react'
import i18n from 'support/i18n'
import SelectWithChecks from '../select_with_checks/View'

const AttributeSelect = ({
  attributes,
  isAttributesActive,
  getAttributes,
  setAttributeSelected,
  disabled,
  attributesDefaultText,
  attributeSelected
}) => {
  const [attributeList, setAttributeList] = useState([])

  useEffect(() => {
    if (attributes) {
      setAttributeList(attributes)
    }
  }, [attributes])

  const handleGetAttributes = () => {
    if (isAttributesActive && !attributeList.length) {
      getAttributes()
    }
  }

  const idCheckFunction = (value, element) => {
    return value.indexOf(element.id) > -1
  }

  const onAttributeChange = (event, callback) => {
    const _callback = callback instanceof Function ? callback : () => { }

    setAttributeSelected(event.target.value);
    _callback()
  }

  const renderAttributesValue = (selected) => {
    const selectedLength = selected.length

    switch (selectedLength) {
      case 0:
        return attributesDefaultText
      case 1:
        return attributeList.find(type => type.id === selected[0]).name
      default:
        return `${i18n.t('HOME.FILTER.TEXT.SELECTED')}: ${selectedLength}`
    }
  }

  if (!isAttributesActive) return null

  return (
    <SelectWithChecks
      checkFunction={idCheckFunction}
      name="attributeSelected"
      defaultText={attributesDefaultText}
      onValueChange={onAttributeChange}
      list={attributeList}
      multiple
      renderValue={renderAttributesValue}
      value={attributeSelected}
      onOpenHandler={handleGetAttributes}
      disabled={disabled}
    />
  )
}
export default AttributeSelect
