const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0),
    borderTop: '1px solid #b3b3b3',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '26px',
    fontWeight: 500,
    '& > span': {
      fontSize: '32px',
      marginRight: theme.spacing(1),
    },
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemContainer: {
    fontSize: '0.875rem',
    color: 'rgb(51, 51, 51)',
    marginBottom: '5px',
    '& span:first-child': {
      margin: theme.spacing(0, 2),
    },
    '& span:last-child': {
      fontWeight: 700,
    },
  },
})

export default styles
