/* eslint-disable react/jsx-no-bind */
import React from 'react'
import * as PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import bulbRocket from '../../img/bulb_rocket.png'
import bulbRocketLogo from '../../img/logo.png'
import i18n from '../../support/i18n'

const PrivacyCookies = props => {
  const { classes, history } = props

  function goBack() {
    history.goBack()
  }

  return (
    <div>
      <div className={classes.header}>
        <img alt="nextinit logo with bulb rocket" src={bulbRocketLogo} />
        <h3>{i18n.t('LEGAL_TXT.COPYRIGHT.TITLE')}</h3>
      </div>
      <div className={classes.body}>
        <div>
          <p>{i18n.t('LEGAL_TXT.COPYRIGHT.P1')}</p>
          <p>{i18n.t('LEGAL_TXT.COPYRIGHT.P2')}</p>
          <p>{i18n.t('LEGAL_TXT.COPYRIGHT.P3')}</p>
          <p>{i18n.t('LEGAL_TXT.COPYRIGHT.P4')}</p>
        </div>
        <img alt="bulb rocket" src={bulbRocket} />
      </div>
      <div>
        <Button color="primary" className={classes.backButton} onClick={goBack} variant="contained">
          {i18n.t('GO_BACK')}
        </Button>
      </div>
    </div>
  )
}

PrivacyCookies.propTypes = {
  classes: PropTypes.shape({
    backButton: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

export default withStyles(styles)(PrivacyCookies)
