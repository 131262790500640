const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '29px',
    left: '-341px',
    width: '500px',
    zIndex: 25,
    lineHeight: 'normal',
    '&:before': {
      position: 'absolute',
      top: '-7px',
      right: '131px',
      display: 'inline-block',
      borderRight: '7px solid transparent',
      borderBottom: '7px solid #FFF',
      borderLeft: '7px solid transparent',
      borderBottomColor: 'rgba(0, 0, 0, 0.2)',
      content: '""',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '-10px',
      right: '129px',
      margin: 'auto',
      height: 0,
      width: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #fff',
    },
    '& > button': {
      margin: theme.spacing(2, 2),
    },
  },
  tab: {
    width: '50%',
  },
  tabsLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inActiveTab: {
    opacity: 0.4,
  },
  noItems: {
    padding: theme.spacing(2),
    fontSize: '14px',
  },
  unread: {
    backgroundColor: '#8a8a8a',
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    padding: theme.spacing(0, 1),
    borderRadius: '25%',
  },
  content: {
    borderTop: '2px solid #dedede',
    borderBottom: '1px solid #dedede',
  },
  notificationList: {
    maxHeight: '320px',
    overflowY: 'auto',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    '& > li:first-child': {
      borderTop: 'none',
    },
    '& > li': {
      borderTop: '1px solid #dedede',
    },
  },
})
export default styles
