import React, { useEffect, useState } from 'react'
import i18n from 'support/i18n'
import SelectWithChecks from '../select_with_checks/View'

const TypeSelect = ({
  types,
  isTypesActive,
  getTypes,
  setTypeSelected,
  disabled,
  typesDefaultText,
  typeSelected
}) => {
  const [typeList, setTypeList] = useState([])

  useEffect(() => {
    if (types) {
      setTypeList(types)
    }
  }, [types])

  const handleGetAttributes = () => {
    if (isTypesActive && !typeList.length) {
      getTypes()
    }
  }

  const idCheckFunction = (value, element) => {
    return value.indexOf(element.id) > -1
  }

  const onTypesChange = (event, callback) => {
    const _callback = callback instanceof Function ? callback : () => { }

    setTypeSelected(event.target.value);
    _callback()
  }

  const renderTypesValue = selected => {
    const selectedLength = selected.length

    switch (selectedLength) {
      case 0:
        return typesDefaultText
      case 1:
        return typeList.find(type => type.id === selected[0]).name
      default:
        return `${i18n.t('HOME.FILTER.TEXT.SELECTED')}: ${selectedLength}`
    }
  }

  if (!isTypesActive) return null

  return (
    <SelectWithChecks
      checkFunction={idCheckFunction}
      name="typeSelected"
      defaultText={typesDefaultText}
      onValueChange={onTypesChange}
      list={typeList}
      multiple
      renderValue={renderTypesValue}
      value={typeSelected}
      onOpenHandler={handleGetAttributes}
      disabled={disabled}
    />
  )
}
export default TypeSelect
