import React, { Fragment, useState } from 'react'
import { withStyles, IconButton } from '@material-ui/core'
import { Delete, Edit, ThumbUp, Reply, Check, Clear } from '@material-ui/icons'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import defaultAvatar from 'img/default-photo.png'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import { Image, ButtonLink } from 'components'
// eslint-disable-next-line import/no-cycle
import CommentList from '../../View'
import MentionTextField from '../../../mention_text_field/View'
import styles from './View.Style'

const CommentItem = props => {
  const {
    classes,
    commentId,
    text,
    userName,
    userEmail,
    timeAgo,
    onUserClick,
    userInvested,
    userBalance,
    photoSrc,
    replies,
    votes,
    subComments,
    isSubComment,
    onPostComment,
    onDeleteComment,
    isDeleted,
    postingCommentStatus,
    onVoteComment,
    isVoted,
    onEditComment,
    userInvestmentsMap,
    isAdminOrInnovator,
    loggedUserEmail,
    onMentionType,
    userSuggestions,
    resetUserSuggestions,
  } = props

  const [showSubComments, setShowSubComments] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editComment, setEditComment] = useState(
    text
      .replace(/<br \/> /g, '\n')
      .replace(/<a.*?>/g, '<')
      .replace(/<\/a>/g, '>'),
  )

  return (
    <Fragment>
      <div className={classes.avatar}>
        <Image defaultSrc={defaultAvatar} src={photoSrc} />
      </div>
      <div className={classes.comment}>
        <div className={classes.firstRow}>
          <ButtonLink classes={{ linkButton: classes.userName }} onLinkClick={onUserClick}>
            {userName}
          </ButtonLink>
          <span>{timeAgo}</span>
        </div>
        <div className={classes.secondRow}>
          <span>
            {`${i18n.t('DASHBOARD.INVESTED')}: `}
            <NxtVirtualCurrency>{userInvested}</NxtVirtualCurrency>
          </span>
          <span>
            <span>
              {`  ·  ${i18n.t('BALANCE')}: `}
              <NxtVirtualCurrency>{userBalance}</NxtVirtualCurrency>
            </span>
          </span>
        </div>
        <div className={classes.thirdRow}>
          {isDeleted && <i>{i18n.t('COMMENT.DELETED')}</i>}
          {/* eslint-disable-next-line react/no-danger */}
          {!isDeleted && !isEditing && <span dangerouslySetInnerHTML={{ __html: text }} />}
          {!isDeleted && isEditing && (
            <Fragment>
              <MentionTextField
                userSuggestions={userSuggestions}
                resetUserSuggestions={resetUserSuggestions}
                textValue={editComment}
                onTextChange={setEditComment}
                onMentionType={onMentionType}
                id={`edit-${commentId}`}
                textFieldRows={2}
              />
              <div>
                <IconButton
                  size="small"
                  color="primary"
                  disabled={!editComment}
                  onClick={() => {
                    onEditComment(editComment, commentId)
                    setIsEditing(false)
                  }}
                >
                  <Check />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    setEditComment(text.replace(/<br \/> /g, '\n'))
                    setIsEditing(false)
                  }}
                >
                  <Clear />
                </IconButton>
              </div>
            </Fragment>
          )}
        </div>
        <div className={classes.fourthRow}>
          <div className={classes.buttonsWrapper}>
            {!isSubComment && !isDeleted && (
              <IconButton
                disabled={isEditing}
                onClick={() => setShowSubComments(true)}
                size="small"
                color="primary"
              >
                <Reply />
              </IconButton>
            )}
            {!isDeleted && (isAdminOrInnovator || loggedUserEmail === userEmail) && (
              <Fragment>
                <IconButton
                  disabled={isEditing}
                  color="primary"
                  size="small"
                  onClick={() => onDeleteComment(commentId)}
                >
                  <Delete />
                </IconButton>
                {!isDeleted && (isAdminOrInnovator || loggedUserEmail === userEmail) && (
                  <IconButton
                    disabled={isEditing}
                    color="primary"
                    size="small"
                    onClick={() => setIsEditing(true)}
                  >
                    <Edit />
                  </IconButton>
                )}
              </Fragment>
            )}
            <IconButton
              disabled={isVoted || isEditing}
              color="primary"
              size="small"
              onClick={() => onVoteComment(commentId)}
            >
              <ThumbUp />
            </IconButton>
          </div>
          <div>
            {!isSubComment && !isDeleted && (
              <span>{`${i18n.t('IDEA.REPLIES')} ${replies} | `}</span>
            )}
            <span>{`${i18n.t('VOTES')} ${votes}`}</span>
          </div>
        </div>
        {!isSubComment && !isDeleted && (
          <Fragment>
            {subComments && subComments.length > 0 && (
              <ButtonLink onLinkClick={() => setShowSubComments(!showSubComments)}>
                <span>
                  {`${i18n.t(showSubComments ? 'HIDE' : 'SHOW')} (${subComments.length}) ${i18n.t(
                    'IDEA.REPLIES',
                  )}`}
                  <span className={showSubComments ? 'icon-up' : 'icon-down'} />
                </span>
              </ButtonLink>
            )}
            {showSubComments && (
              <CommentList
                userInvestmentsMap={userInvestmentsMap}
                onPostComment={onPostComment}
                parentId={commentId}
                isSubComment
                comments={subComments}
                onDeleteComment={onDeleteComment}
                postingCommentStatus={postingCommentStatus}
                onVoteComment={onVoteComment}
                onEditComment={onEditComment}
                onUserClick={onUserClick}
                isAdminOrInnovator={isAdminOrInnovator}
                userEmail={loggedUserEmail}
                onMentionType={onMentionType}
                userSuggestions={userSuggestions}
                resetUserSuggestions={resetUserSuggestions}
              />
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

CommentItem.defaultProps = {
  isSubComment: false,
  commentId: '',
  text: '',
  userName: '',
  userEmail: '',
  timeAgo: '',
  photoSrc: '',
  onUserClick: () => {},
  userInvested: '',
  userBalance: '',
  replies: '',
  votes: '',
  subComments: [],
  onPostComment: () => {},
  postingCommentStatus: '',
  isVoted: false,
}

CommentItem.propTypes = {
  isSubComment: PropTypes.bool,
  commentId: PropTypes.string,
  text: PropTypes.string,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  photoSrc: PropTypes.string,
  timeAgo: PropTypes.string,
  onUserClick: PropTypes.func,
  userInvested: PropTypes.string,
  userBalance: PropTypes.string,
  replies: PropTypes.string,
  votes: PropTypes.string,
  subComments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      isDeleted: PropTypes.bool,
    }),
  ),
  onPostComment: PropTypes.func,
  postingCommentStatus: PropTypes.string,
  onDeleteComment: PropTypes.func.isRequired,
  onVoteComment: PropTypes.func.isRequired,
  isVoted: PropTypes.bool,
  onEditComment: PropTypes.func.isRequired,
  userInvestmentsMap: PropTypes.shape({}).isRequired,
  isAdminOrInnovator: PropTypes.bool.isRequired,
  loggedUserEmail: PropTypes.string.isRequired,
}

export default withStyles(styles)(CommentItem)
