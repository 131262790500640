const styles = theme => ({
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '50%',
  },
  formControl: {
    width: '10rem',
    marginLeft: '1rem',
    '& > div > div:first-child': {
      padding: '9px 14px',
    },
  },
  iconStatus: {
    fontSize: '1em',
    paddingLeft: '1rem',
  },
  itemRow: {
    '& img': {
      width: '5rem',
      maxHeight: '5rem',
    },
  },
  label: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#333',
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    textAlign: 'left',
    textDecoration: 'none',
    '&:hover, &:focus': {
      color: theme.palette.common.black,
      textDecoration: 'underline',
      outline: 'none',
    },
  },
  middle: {
    gridArea: 'middle',
    gridColumn: 2,
    gridRow: 2,
    marginTop: '2rem',
    marginBottom: '3rem',
    '& > .MuiTabs-root': {
      backgroundColor: '#ddd',
    },
    '& > .MuiTabs-root > .MuiTabs-scroller > .MuiTabs-flexContainer > .Mui-selected': {
      backgroundColor: '#fff',
    },
    '& > .MuiTabs-root > .MuiTabs-scroller .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    }
  },
  tableHeader: {
    padding: '1rem',
    display: 'flex',
  },
  tableTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    width: '50%',
    '& > span': {
      fontSize: '31px',
      marginRight: '1rem',
    },
    '& > h3': {
      width: '100%',
      fontWeight: '500',
    },
  },
  top: {
    gridArea: 'top',
    gridColumn: 2,
    gridRow: 2,
    textAlign: 'center',
  },
  topContainer: {
    display: 'flex',
  },
  noInvestments: {
    padding: '10px',
    marginTop: '10px',
    textAlign: 'center',
  }
})

export default styles
