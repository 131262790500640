export const TextFieldStyled = {
  shrink: true,
  style: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
}

export const initFile = {
  name: '',
  id: '',
  key: '',
  url: '',
  type: '',
  size: 0,
}

export const initValuesForm = {
  personalData: {
    name: '',
    lastName: '',
    company: '',
    workPosition: '',
  },
  companyInfo: {
    cel: '',
    corporateEmail: '',
    countryHeadOffice: '',
    otherCountries: '',
    companyId: '',
    annualSales: '',
    companySize: '',
    companyAge: '',
    solutionName: '',
    solutionLevel: '',
    solutionDescription: '',
    valueProposal: '',
  },
  marketInfo: {
    mainCompetitors: '',
    mainCustomers: '',
    file: initFile,
    acceleratorBelong: '',
    seedCapital: '',
    whoFinances: '',
  },
  socialNetworks: {
    webSite: '',
    facebook: '',
    twitter: '',
    instagram: '',
    linkedin: '',
    others: '',
  },
  optionalQuestions: {
    legalAgent: '',
    companyAddress: '',
    businessName: '',
  },
}

const TypeIcons = {
  mp4: 'video',
  webm: 'video',
  rm: 'video',
  jpg: 'image',
  jpeg: 'image',
  png: 'image',
  gif: 'image',
  bmp: 'image',
  psd: 'image',
  tiffimage: 'image',
  text: 'image',
  xlsx: 'excel',
  ppt: 'presentation',
  pdf: 'pdf',
  docx: 'text',
  zip: 'zip',
}

export const ShowIcon = name => {
  const type = name.split('.').pop()
  return TypeIcons[type] ?? 'text'
}

export const ONLY_TEXT = '^[a-zA-Zñ,. ]*$'
export const ONLY_NUMBERS = '^[0-9]*$'
export const ONLY_EMAIL = '^[\\w-.]+@([\\w-]+\\.)+[\\w-]{2,4}$'
export const webPattern = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/gm
export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const dniPattern = /^[0-9]{2}[0-9]{3}[0-9]{3}-[0-9K]$/i
export const alphanumericPattern = /^[a-zA-Z0-9 ]+$/
export const limitedKeyUpRegex = /^[a-zA-Z0-9#&@*-=?_|.]+$/

export const generateUUID = () => {
  const crypto = window.crypto || window.msCrypto
  if (crypto && crypto.getRandomValues) {
    const uuid = new Uint8Array(16)
    crypto.getRandomValues(uuid)
    uuid[6] = (uuid[6] && 0x0f) || 0x40
    uuid[8] = (uuid[8] && 0x3f) || 0x80
    return [
      ...uuid.slice(0, 4),
      ...uuid.slice(4, 6),
      ...uuid.slice(6, 8),
      ...uuid.slice(8, 10),
      ...uuid.slice(10),
    ]
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('')
  }
  let uuid = ''
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 32; i++) {
    uuid += Math.floor(Math.random() * 16).toString(16)
  }
  return uuid
}
