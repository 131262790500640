import React, { useState } from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'
import i18n from 'support/i18n'
import SelectedUserList from '../emails_search/components/selected_users_list/View'

const EmailFormToUsers = ({ users, textLabel, textToSend, onChangeText }) => {
  const [isEmpty, setIsEmpty] = useState(false)

  const handleOnChange = event => {
    const { value } = event.target
    onChangeText(event)
    setIsEmpty(!value.trim().length)
  }

  const handleOnBlur = event => {
    const { value } = event.target
    setIsEmpty(!value.trim().length)
  }

  return (
    <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography spacing={2}>{i18n.t('PEOPLE_SELECTOR.SELECTED_CONTACTS')}</Typography>
      <SelectedUserList
        style={{
          root: { borderTop: 'unset' },
        }}
        users={users.map(user => ({
          photoSrc: user.photoSrc,
          fullName: user.fullName,
        }))}
      />
      <Grid item>
        <TextField
          value={textToSend}
          label={textLabel}
          error={isEmpty}
          onBlur={handleOnBlur}
          multiline
          minRows="10"
          maxRows="10"
          onChange={handleOnChange}
          margin="none"
          variant="outlined"
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

export default EmailFormToUsers
