import { connect } from 'react-redux'
import {
  setAuthMessageBox,
  resetAuthMessageBox,
  userAlreadyExists,
  increaseSignUpStep,
} from '../../actions/sync/auth'
import { postRecoverPassword, postSignUp, postUserDetail } from '../../services/auth'
import { getDomainLoginInfo, getGDPR, getUserDomains } from '../../services/domain'
import { MESSAGE_TYPES } from '../../components/auth_message_box/View.Utils'
import { POSSIBLES_AUTH_STATUSES, POSSIBLES_AUTH_ERRORS } from '../../support/utils/auth'
import i18n from '../../support/i18n'
import { hideDialog } from '../../support/popup_dialogs/modal_alert_controller/ModalActions'
import { showAnotherPopupDialog } from '../../support/popup_dialogs'
import Navigation from '../../support/navigation'
import View from './View'

const mapStateToProps = (state, ownProps) => {
  return {
    isSigning: state.auth.isSigning,
    isUserAlreadyExisting: state.auth.isUserAlreadyExisting,
    isSavingUserDetails: state.auth.isSavingUserDetails,
    signUpStep: state.auth.signUpStep,
    customizationValues: state.data.domain.loginInfo,
    useCreateAccount: state.data.domain.loginInfo.useCreateAccount,
    useCustomColor: state.data.domain.loginInfo.useCustomColor,
    customColor: state.data.domain.loginInfo.customColor,
    useCustomTextColor: state.data.domain.loginInfo.useCustomTextColor,
    customTextColor: state.data.domain.loginInfo.customTextColor,
    useCustomImg: state.data.domain.loginInfo.useCustomImg,
    customImg: state.data.domain.loginInfo.customImg,
    useCustomText: state.data.domain.loginInfo.useCustomText,
    customText: state.data.domain.loginInfo.customText,
    useCustomBgImg: state.data.domain.loginInfo.useCustomBgImg,
    customBgImg: state.data.domain.loginInfo.customBgImg,
    useCustomLogin: !!ownProps.match.params.domain,
    messageType: state.auth.messageBox.messageType,
    messageText: state.auth.messageBox.messageText,
    isLogging: state.auth.isLogging,
    gdpr: state.data.domain.gdpr,
    domaninFailure: state.data.domain.failure,
    userDomainList: state.data.domain.userDomainList,
    currentDomain: ownProps.match.params.domain,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad: () => dispatch(getDomainLoginInfo(ownProps.match.params.domain)),
  onForgotPasswordClick: (email, instanceName) => {
    dispatch(
      setAuthMessageBox(
        MESSAGE_TYPES.SUCCESS,
        i18n.t('COMMON_LOGIN.HEADER.FORGOT_PASSWORD_EMAIL_SENT'),
      ),
    )
    dispatch(postRecoverPassword(email, instanceName))
  },
  resetAuthMessageBox: () => {
    dispatch(resetAuthMessageBox())
  },
  setAuthMessageBox: (messageType, messageText) => {
    dispatch(setAuthMessageBox(messageType, messageText))
  },
  onSubmit: (email, password) => {
    dispatch(resetAuthMessageBox())
    const { domain } = ownProps.match.params
    let messageText = i18n.t('COMMON_LOGIN.HEADER.CHANGE_PASSWORD_GENERAL_KO')
    const onFailure = responseError => {
      if (responseError.status === POSSIBLES_AUTH_STATUSES.FORBIDDEN) {
        switch (responseError.data.error) {
          case POSSIBLES_AUTH_ERRORS.USER_ALREADY_EXISTS:
            dispatch(userAlreadyExists())
            return
          case POSSIBLES_AUTH_ERRORS.INSTANCE_BLOCK:
            messageText = i18n.t('COMMON_LOGIN.HEADER.INSTANCE_BLOCKED')
            break
          default:
            break
        }
      }
      dispatch(setAuthMessageBox(MESSAGE_TYPES.ERROR, messageText))
    }
    const onSuccess = () => {
      dispatch(increaseSignUpStep())
    }
    dispatch(postSignUp(domain, email, password, onSuccess, onFailure))
  },
  onSaveUserDetails: (firstName, lastName, base64Photo) => {
    dispatch(resetAuthMessageBox())
    const { domain } = ownProps.match.params
    const onFailure = () => {
      dispatch(
        setAuthMessageBox(
          MESSAGE_TYPES.ERROR,
          i18n.t('COMMON_LOGIN.HEADER.CHANGE_PASSWORD_GENERAL_KO'),
        ),
      )
    }
    const onSuccess = response => {
      if (response.login) {
        Navigation.navigateToExplore()
      } else {
        dispatch(increaseSignUpStep())
      }
    }
    dispatch(postUserDetail(domain, firstName, lastName, base64Photo, onSuccess, onFailure))
  },
  onImageSelected: component => {
    showAnotherPopupDialog(component, dispatch)
  },
  onClose: () => {
    dispatch(hideDialog())
  },
  getGDPR: () => dispatch(getGDPR()),
  // Get user domains
  handleGetUserDomains: email => {
    const onFailure = () => {
      dispatch(setAuthMessageBox(MESSAGE_TYPES.ERROR, i18n.t('DOMAIN.FAILURE_GET_DOMAINS')))
    }

    dispatch(getUserDomains(email, onFailure))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
