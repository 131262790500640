import { connect } from 'react-redux'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import VideoUploadDialog from 'components/video_upload_dialog/View'
import { showPopupErrorSnackbar } from 'support/popup_dialogs'
import { VIDEO_STATE_TYPE } from 'common_constants/Video'
import { MAX_SIZE_VIDEO, MAX_VIDEO_BYTES } from 'common_constants/Ideas'
import { transformVideoType } from 'support/utils/file'
import i18n from 'support/i18n'
import { generateRequestCsrf } from 'support/utils/auth'

const mapStateToProps = (state, ownProps) => {
  let videoStateData
  let fileVideoSrc
  let fileVideoType

  if (ownProps.stateType === VIDEO_STATE_TYPE.CHALLENGE) {
    videoStateData = state.data.challengeSteps.challengeDescription
  } else {
    videoStateData = state.data.createIdea.videos
  }

  if (videoStateData.fileVideoUrl) {
    fileVideoSrc = `${process.env.REACT_APP_BACKEND_BASE_URL}${
      videoStateData.fileVideoUrl
    }?_nxt_csrf=${generateRequestCsrf(state.auth.csrf)}`
  }

  if (videoStateData.fileVideo.type) {
    fileVideoType = transformVideoType(videoStateData.fileVideo.type)
  }

  return {
    fileVideoSrc,
    fileVideoType,
    fileVideoId: videoStateData.fileVideoId,
    isVideoLoading: videoStateData.isVideoLoading,
    isVideoReady: videoStateData.isVideoReady,
    isVideoRemoving: videoStateData.isVideoRemoving,
    hasVideoError: videoStateData.hasVideoError,
    storageVideoUploadUrl: videoStateData.storageVideoUploadUrl,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  beforeUploadVideo: (id, video) => {
    const onFailure = () => {
      const message = i18n.t('VIDEO.VIDEO_ERROR')
      showPopupErrorSnackbar(message, dispatch)
    }
    if (video.size > MAX_VIDEO_BYTES) {
      showPopupErrorSnackbar(
        i18n.t('VIDEO.VIDEO_TOO_LARGE_UPLOAD_ERROR', { max_size: MAX_SIZE_VIDEO }),
        dispatch,
      )
    } else {
      dispatch(ownProps.beforeOuterUploadVideo(id, onFailure))
    }
  },
  onUploadVideo: video => {
    const onFailure = errorResponse => {
      let message = i18n.t('VIDEO.VIDEO_ERROR')
      if (errorResponse.status === 413) {
        message = i18n.t('VIDEO.VIDEO_TOO_LARGE_UPLOAD_ERROR', { max_size: MAX_SIZE_VIDEO })
      }
      showPopupErrorSnackbar(message, dispatch)
    }
    dispatch(ownProps.onOuterUploadVideo(video, onFailure))
  },
  onDeleteVideo: fileVideoId => dispatch(ownProps.onOuterDeleteVideo(fileVideoId)),
  onCancel: () => dispatch(hideDialog()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoUploadDialog)
