import React from 'react'
import i18n from 'support/i18n'
import SelectWithChecks from '../select_with_checks/View'

const StatusSelect = ({
  defaultText,
  setValueChange,
  list,
  status,
  disabled
}) => {
  const idCheckFunction = (_value, element) => {
    return _value.indexOf(element.id) > -1
  }

  const renderStatusValue = selected => {
    const selectedLength = selected.length
    switch (selectedLength) {
      case 0:
        return defaultText
      case 1:
        return (
          list.find(statusItem => statusItem.id === selected[0]) || { name: defaultText }
        ).name
      default:
        return `${i18n.t('HOME.FILTER.TEXT.SELECTED')}: ${selectedLength}`
    }
  }

  return (
    <SelectWithChecks
      checkFunction={idCheckFunction}
      name="status"
      defaultText={defaultText}
      onValueChange={setValueChange}
      list={list}
      renderValue={renderStatusValue}
      value={status}
      disabled={disabled}
      multiple
    />
  )
}

export default StatusSelect
