import React from 'react'
import { Image } from 'components'
import { RenderIconRrss } from './RenderIconRrss'

export const RenderCustomSide = ({
  classes,
  useCustomImg,
  customImg,
  useCustomText,
  customText,
  useCustomSubTitle,
  customSubTitle,
  customRrss,
}) => {
  return (
    <>
      <div className={classes.customize}>
        {useCustomImg && (
          <Image style={{ marginBottom: 5, borderRadius: 5 }} defaultSrc="" src={customImg} alt="custom company logo" />
        )}
        <div className="content">
          <h2>{useCustomText ? customText : null}</h2>
          <p>{useCustomSubTitle ? customSubTitle : null}</p>
        </div>
        <div className="footer">
          <div className="social">
            {customRrss?.map((item) =>
              (item.isChecked) && (
                <a key={item.title.toString()} href={item.url} target="_blank" rel="noopener noreferrer">
                  {RenderIconRrss[item.title]}
                </a>
              )
            )}
          </div>
        </div>
      </div>
    </>
  )
}
