import { createStyles } from '@material-ui/core/styles'

const styles = () =>
  createStyles({
    container: {
      width: '100%',
      height: '30rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '& h1': {
        margin: '42px 0 0',
        paddingBottom: '8px',
        fontSize: '25px',
        fontFamily: '"HelveticaNeueCondensedBold", arial, sans-serif',
        borderBottom: '1px solid #aeaeae',
        fontWeight: 'bold',
        color: '#000',
        lineHeight: '1',
      },
      '& p': {
        fontSize: '18px',
        clear: 'both',
        lineHeight: '16px',
        paddingTop: '18px',
        margin: '0 0 10px',
      },
    },
  })

export default styles
