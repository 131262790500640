/* eslint-disable react/jsx-no-bind */
import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import styles from './View.Style'
import Pagination from './Pagination'

const AutoPlaySwipeableViews = SwipeableViews

const Slider = props => {
  const {
    activeStep,
    classes,
    children,
    maxSteps,
    setActiveStep,
    onNextClick,
    onPreviousClick,
    arrowsPosition,
    stepper,
  } = props

  function handleNext() {
    if (activeStep + 1 === maxSteps) {
      setActiveStep(0)
    } else {
      setActiveStep(activeStep + 1)
    }
    onNextClick()
  }

  function handleBack() {
    if (activeStep === 0) {
      setActiveStep(maxSteps - 1)
    } else {
      setActiveStep(activeStep - 1)
    }
    onPreviousClick()
  }

  function handleStepChange(step) {
    setActiveStep(step)
  }

  const renderNextButton = () => {
    return (
      <Button
        className={`${arrowsPosition}-next-button`}
        size="small"
        onClick={handleNext}
        disableRipple
      >
        {classes.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </Button>
    )
  }

  const renderBackButton = () => {
    return (
      <Button
        className={`${arrowsPosition}-back-button`}
        size="small"
        onClick={handleBack}
        disableRipple
      >
        {classes.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </Button>
    )
  }

  const renderBody = () => {
    return (
      <>
        <AutoPlaySwipeableViews
          axis={classes.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {children}
        </AutoPlaySwipeableViews>
        {stepper && (
          <Pagination dots={maxSteps} index={activeStep} onChangeIndex={handleStepChange} />
        )}

        {(arrowsPosition === 'center' || arrowsPosition === 'center-inside') &&
          stepper &&
          renderNextButton()}
        {(arrowsPosition === 'center' || arrowsPosition === 'center-inside') &&
          stepper &&
          renderBackButton()}
      </>
    )
  }

  return (
    <>
      {arrowsPosition === 'center' || arrowsPosition === 'center-inside' ? (
        <div className={classes.container}>{renderBody()}</div>
      ) : (
        renderBody()
      )}
    </>
  )
}

Slider.defaultProps = {
  className: {
    stepperRoot: '',
  },
  onNextClick: () => {},
  onPreviousClick: () => {},
  arrowsPosition: 'bottom',
  arrowsColor: 'rgba(0, 0, 0, 0.86)',
  arrowsSize: '1em',
  arrowsBgColor: '#333',
  stepper: false,
}

Slider.propTypes = {
  classes: PropTypes.shape({
    amount: PropTypes.string,
    days: PropTypes.string,
    descriptionCard: PropTypes.string,
    dialGraph: PropTypes.string,
    dialGraphText: PropTypes.string,
    graphBlock: PropTypes.string,
    sliderContent: PropTypes.string,
    stepperRoot: PropTypes.string,
  }).isRequired,
  className: PropTypes.shape({
    stepperRoot: PropTypes.string,
  }),
  activeStep: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
  arrowsPosition: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  arrowsColor: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  arrowsSize: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  arrowsBgColor: PropTypes.string,
  stepper: PropTypes.bool,
}

export default withStyles(styles)(Slider)
