import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, ButtonGroup, Button } from '@material-ui/core'
import i18n from 'support/i18n'
import { typesViewAs } from 'pages/dashboard/Constants'
import clsx from 'clsx'
import CustomTable from 'components/custom_table/View'
import TableHeader from './components/table_header/View'
import TableBody from './components/table_body/View'
// import { headerCells } from './utils/header/headerCells'
import ComparisonTable from '../comparison_table/View'
import ParticipationSuccessScatterChart from './components/participation_success_scatter_chart/View'
import PeopleSectionBar from './components/people_section_bar/View'
import ListFilter from './components/list_filter/View'
import PeopleGrid from './components/people_grid/View'
import styles from './View.Style'

class ActivityPeople extends Component {
  constructor(props) {
    super(props)
    this.state = { viewAs: 0 }

    this.handleViewAsClick = this.handleViewAsClick.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleFilterByType = this.handleFilterByType.bind(this)
  }

  handleViewAsClick(viewAs) {
    const { onViewAsClick } = this.props
    this.setState({
      viewAs,
    })
    onViewAsClick(viewAs)
  }

  handlePageChange(value, newRowsPerPage) {
    const { onPageChange } = this.props
    const { viewAs } = this.state
    onPageChange(value, newRowsPerPage, viewAs)
  }

  handleFilterByType(value) {
    const { onFilterByType } = this.props
    const { viewAs } = this.state
    onFilterByType(value, viewAs)
  }

  render() {
    const {
      classes,
      tableData,
      tableUsersData,
      gridUsersData,
      isTableLoading,
      navigateToUserProfile,
      onFilterByText,
      isTableUsersDataLoading,
      isGridUsersDataLoading,
      series,
      isUsersParticipationSeriesLoading,
      totalUsers,
      participationAvg,
      successAvg,
      onTooltipEmailClick,
      onTooltipUserNameClick,
      onSendReportClick,
      totalSearchItems,
      gridTotalSearchItems,
      rowsPerPage,
      gridRowsPerPage,
      page,
      gridPage,
      onGridCardClick,
      customIdeaTitles
    } = this.props

    const headerCells = [
      { id: 'USER', label: i18n.t('DASHBOARD.USER'), numeric: false },
      { id: 'INVESTMENTS', label: i18n.t('DASHBOARD.INVESTMENTS'), numeric: true },
      { id: 'COMMENTS', label: i18n.t('DASHBOARD.COMMENTS'), numeric: true },
      { id: 'IDEAS', label: i18n.t('DASHBOARD.IDEAS', { title: customIdeaTitles.pluralTitle || "ideas" }), numeric: true },
      { id: 'IDEASFUNDED', label: i18n.t('DASHBOARD.IDEASFUNDED', { title: customIdeaTitles.pluralTitle || "ideas" }), numeric: true },
      { id: 'IDEASAPPROVED', label: i18n.t('DASHBOARD.IDEASAPPROVED', { title: customIdeaTitles.pluralTitle || "ideas" }), numeric: true },
      { id: 'IDEASPILOT', label: i18n.t('DASHBOARD.IDEASPILOT'), numeric: true },
      { id: 'IDEASIMPLEMENTED', label: i18n.t('DASHBOARD.IDEASIMPLEMENTED'), numeric: true },
      { id: 'IDEASDISCARDED', label: i18n.t('DASHBOARD.IDEASDISCARDED'), numeric: true },
    ]

    const comparisonTableHeaders = [
      { text: i18n.t('DASHBOARD.W_INVESTMENTS'), icon: 'earn-money', borderColor: '#ffcd40' },
      { text: i18n.t('DASHBOARD.W_COMMENTS'), icon: 'comment', borderColor: '#d19600' },
      { text: i18n.t('DASHBOARD.W_IDEASOWNED', { title: customIdeaTitles.pluralTitle || "ideas" }), icon: 'idea', borderColor: '#0070a7' },
      { text: i18n.t('DASHBOARD.W_IDEASFUNDED', { title: customIdeaTitles.pluralTitle || "ideas" }), icon: 'success', borderColor: '#0097e2' },
      { text: i18n.t('DASHBOARD.W_IDEASAPPROVED', { title: customIdeaTitles.pluralTitle || "ideas" }), icon: 'approved', borderColor: '#63ffe4' },
      { text: i18n.t('DASHBOARD.W_IDEASPILOT'), icon: 'prototype', borderColor: '#00d24c' },
      { text: i18n.t('DASHBOARD.W_IDEASIMPLEMENTED', { title: customIdeaTitles.pluralTitle || "ideas" }), icon: 'ranking', borderColor: '#fa5d7f' },
    ]
    const { viewAs } = this.state

    return (
      <div className={classes.root}>
        <ComparisonTable
          header={comparisonTableHeaders}
          dataRows={tableData.dataRows}
          comparisonRow={tableData.comparisonRow}
          isLoading={isTableLoading}
          enableExtraColumn
          enableChart
        />
        <div className={classes.bottomTableTitle}>
          <h3>{i18n.t('DASHBOARD.PEOPLE')}</h3>
          <div className={classes.viewAsContainer}>
            <span>{i18n.t('DASHBOARD.DISPLAY_AS')}</span>
            <ButtonGroup color="secondary" aria-label="outlined primary button group" size="small">
              <Button
                color={viewAs === 0 ? 'primary' : ''}
                onClick={() => this.handleViewAsClick(typesViewAs.QUADRANT)}
                variant="contained"
              >
                {i18n.t('DASHBOARD.QUADRANT')}
              </Button>
              <Button
                color={viewAs === 1 ? 'primary' : ''}
                onClick={() => this.handleViewAsClick(typesViewAs.GRID)}
                variant="contained"
              >
                {i18n.t('DASHBOARD.GRID')}
              </Button>
              <Button
                color={viewAs === 2 ? 'primary' : ''}
                onClick={() => this.handleViewAsClick(typesViewAs.LIST)}
                variant="contained"
              >
                {i18n.t('DASHBOARD.LIST')}
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <div className={clsx(viewAs !== typesViewAs.LIST && classes.hide)}>
          <PeopleSectionBar onSendButtonClick={onSendReportClick}>
            <ListFilter
              onSelectChange={this.handleFilterByType}
              totalUsers={totalSearchItems}
              customIdeaTitles={customIdeaTitles}
            />
          </PeopleSectionBar>
          <CustomTable
            isLoading={isTableUsersDataLoading}
            renderHeaderComponent={
              <TableHeader onFilterByText={onFilterByText} headRows={headerCells} />
            }
            renderBodyComponent={
              <TableBody bodyRows={tableUsersData} navigateToUserProfile={navigateToUserProfile} />
            }
            paginated
            fullCount={totalSearchItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPaginationChanged={this.handlePageChange}
            customIdeaTitles={customIdeaTitles}
          />
        </div>

        <div className={clsx(viewAs !== typesViewAs.GRID && classes.hide)}>
          <PeopleSectionBar onSendButtonClick={onSendReportClick}>
            <ListFilter
              onSelectChange={this.handleFilterByType}
              totalUsers={gridTotalSearchItems}
              customIdeaTitles={customIdeaTitles}
            />
          </PeopleSectionBar>
          <PeopleGrid
            users={gridUsersData}
            loading={isGridUsersDataLoading}
            cardsPerPage={gridRowsPerPage}
            page={gridPage}
            onCardClick={onGridCardClick}
            onLoadMore={this.handlePageChange}
          />
        </div>

        <div className={clsx(viewAs !== typesViewAs.QUADRANT && classes.hide)}>
          <PeopleSectionBar onSendButtonClick={onSendReportClick}>
            <span>{i18n.t('DASHBOARD.QUADRANT_TITLE')}</span>
          </PeopleSectionBar>
          <ParticipationSuccessScatterChart
            series={series}
            totalUsers={totalUsers}
            participationAvg={participationAvg}
            successAvg={successAvg}
            isLoading={isUsersParticipationSeriesLoading}
            onTooltipEmailClick={onTooltipEmailClick}
            onTooltipUserNameClick={onTooltipUserNameClick}
          />
        </div>

      </div>
    )
  }
}

ActivityPeople.propTypes = {
  tableData: PropTypes.shape({
    dataRows: PropTypes.arrayOf(
      PropTypes.shape({
        firstColumn: PropTypes.arrayOf(
          PropTypes.shape({
            mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        ),
        data: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
    comparisonRow: PropTypes.shape({
      firstColumn: PropTypes.arrayOf(
        PropTypes.shape({
          mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
      data: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          type: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
  isTableLoading: PropTypes.bool.isRequired,
  series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUsersParticipationSeriesLoading: PropTypes.bool.isRequired,
  onViewAsClick: PropTypes.func.isRequired,
  onFilterByText: PropTypes.func.isRequired,
  onFilterByType: PropTypes.func.isRequired,
  totalUsers: PropTypes.number.isRequired,
  participationAvg: PropTypes.string.isRequired,
  successAvg: PropTypes.string.isRequired,
  onSendReportClick: PropTypes.func.isRequired,
  totalSearchItems: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(ActivityPeople)
