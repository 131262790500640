import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Box } from '@material-ui/core'
import i18n from 'support/i18n'

export default function ConfirmModal({ open, onClose, onSave }) {
  return (
    <Dialog open={open} keepMounted onClose={onClose}>
      <Box p={1} style={{ minWidth: 500 }}>
        <DialogTitle>{i18n.t('FORMS.STANDARD_FORM.MODAL.TITLE')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{i18n.t('FORMS.STANDARD_FORM.MODAL.SUB_TITLE')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onSave}>
            {i18n.t('FORMS.STANDARD_FORM.BTN_FINISH')}
          </Button>
          <Button onClick={onClose} color="primary">
            {i18n.t('FORMS.STANDARD_FORM.BTN_CANCEL')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
