/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, ButtonBase } from '@material-ui/core'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import i18n from 'support/i18n'
import BarChart from 'components/bar_chart/View'
import SingleDateRangePicker from 'components/single_date_range_picker/View'
import MostActiveUsers from './components/most_active_users/View'
import styles from './View.Style'
import ComparisonTable from '../comparison_table/View'

const WeekActivity = (props) => {
  const {
    classes,
    tableData,
    isChartLoading,
    isTableLoading,
    categories,
    series,
    mostActiveUsers,
    fromDate,
    toDate,
    minDate,
    maxDate,
    onDateChange,
    onPrevWeek,
    onNextWeek,
    customIdeaTitles
  } = props

  const comparisonTableHeaders = [
    { text: i18n.t('DASHBOARD.IDEAS_CREATED', { title: customIdeaTitles.pluralTitle || "ideas" }), borderColor: '#ffcd40' },
    { text: i18n.t('DASHBOARD.INVESTMENTS'), borderColor: '#d19600' },
    { text: i18n.t('DASHBOARD.COMMENTS'), borderColor: '#0070a7' },
    { text: i18n.t('DASHBOARD.IDEAS_VISITS', { title: customIdeaTitles.pluralTitle || "ideas" }), borderColor: '#0097e2' },
    { text: i18n.t('DASHBOARD.SYSTEM_ACCESS'), borderColor: '#63ffe4' },
  ]

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.leftArrow}>
          <ButtonBase onClick={onPrevWeek}>
            <ChevronLeft />
          </ButtonBase>
        </div>
        <div className={classes.dateSelector}>
          <SingleDateRangePicker
            fromDate={fromDate}
            toDate={toDate}
            minDate={minDate}
            maxDate={maxDate}
            onDateChange={onDateChange}
          />
        </div>
        <div className={classes.rightArrow}>
          <ButtonBase onClick={onNextWeek}>
            <ChevronRight />
          </ButtonBase>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.graphContainer}>
          <BarChart
            title={i18n.t('DASHBOARD.TOTAL_ACCESS')}
            yAxisTitle={i18n.t('DASHBOARD.TOTAL_ACCESS_NUM')}
            categories={categories}
            series={series}
            isLoading={isChartLoading}
            tooltip={{
              headerFormat: '<b>{point.key}</b><br>',
              pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
            }}
          />
        </div>
        <div className={classes.usersContainer}>
          <MostActiveUsers data={mostActiveUsers} customIdeaTitles={customIdeaTitles} />
        </div>
      </div>
      <ComparisonTable
        header={comparisonTableHeaders}
        dataRows={tableData.dataRows}
        comparisonRow={tableData.comparisonRow}
        isLoading={isTableLoading}
      />
    </div>
  )
}

WeekActivity.propTypes = {
  tableData: PropTypes.shape({
    dataRows: PropTypes.arrayOf(
      PropTypes.shape({
        firstColumn: PropTypes.arrayOf(
          PropTypes.shape({
            mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        ),
        data: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
    comparisonRow: PropTypes.shape({
      firstColumn: PropTypes.arrayOf(
        PropTypes.shape({
          mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
      data: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          type: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
  isChartLoading: PropTypes.bool.isRequired,
  isTableLoading: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
  mostActiveUsers: PropTypes.array.isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  fromDate: PropTypes.instanceOf(Date).isRequired,
  toDate: PropTypes.instanceOf(Date).isRequired,
  onDateChange: PropTypes.func.isRequired,
  onPrevWeek: PropTypes.func.isRequired,
  onNextWeek: PropTypes.func.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(WeekActivity)
