import React, { Fragment } from 'react'
import { DialogTitle, Table, TableBody, TableCell, TableRow, withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

export const SINGULAR_IDEA_MATCH = /idea|Idea/g
export const PLURAL_IDEA_MATCH = /ideas|Ideas/g

const DataProtection = (props) => {
  const { classes, data, linkColor, customIdeaTitles } = props
  const {
    responsable,
    purpose,
    legitimation,
    recipients,
    rights,
    additionalinformation,
    responsableUrl,
    purposeUrl,
    legitimationUrl,
    recipientsUrl,
    rightsUrl,
    additionalinformationUrl,
  } = data

  const linkStyle = linkColor ? { color: linkColor } : {}
  const tableContent = [
    {
      firstCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.RESPONSABLE'),
      secondCellText: responsable,
      secondCellUrl: responsableUrl,
    },
    {
      firstCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.PURPOSE'),
      secondCellText: purpose?.replaceAll(SINGULAR_IDEA_MATCH, customIdeaTitles.singularTitle).replace(PLURAL_IDEA_MATCH, customIdeaTitles.pluralTitle),
      secondCellUrl: purposeUrl,
    },
    {
      firstCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.LEGITIMATION'),
      secondCellText: legitimation,
      secondCellUrl: legitimationUrl,
    },
    {
      firstCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.RECIPIENTS'),
      secondCellText: recipients,
      secondCellUrl: recipientsUrl,
    },
    {
      firstCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.RIGHTS'),
      secondCellText: rights,
      secondCellUrl: rightsUrl,
    },
  ]

  return (
    <>
      <DialogTitle style={{ textAlign: "center" }}>
        {i18n.t('TERMS_AND_CONDITIONS_TABLE.INFORMATION')}
      </DialogTitle>
      <Table aria-label="simple table">
        <TableBody>
          {tableContent.map(row => {
            return (
              <TableRow key={row.firstCellText}>
                <TableCell component="th" scope="row" className={classes.boldText}>
                  {row.firstCellText}
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  {row.secondCellText}
                  <a
                    href={row.secondCellUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="more-info"
                    style={linkStyle}
                  >
                    {i18n.t('TERMS_AND_CONDITIONS_TABLE.MORE_INFO')}
                  </a>
                </TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell className={classes.boldText} component="th" scope="row">
              {i18n.t('TERMS_AND_CONDITIONS_TABLE.ADDITIONAL_INFORMATION')}
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              {additionalinformation}
              <a
                href={additionalinformationUrl}
                id="button-link"
                target="_blank"
                rel="noreferrer"
                className="more-info"
                style={linkStyle}
              >
                {i18n.t('TERMS_AND_CONDITIONS_TABLE.MORE_INFO')}
              </a>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

export default withStyles(styles)(DataProtection)
