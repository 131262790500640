import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import NxtUserCard from 'components/nxt_user_card/View'
// import NxtBenefits from 'components/nxt_benefits/View'
import NxtReward from 'components/nxt_reward/View'
import i18n from 'support/i18n'
import styles from './View.Style'

const NxtChallengeResumeDetail = (props) => {
  const {
    actionButton,
    // approveMult,
    classes,
    daysToGo,
    ideas,
    // implementMult,
    onClickToShowRewardImage,
    reward,
    // supportMult,
    selectedSponsor,
    visits,
    whyImportant,
    isPrivate,
    customIdeaTitles
  } = props

  const { rewarded, rewardedOther, image, description } = reward

  return (
    <Fragment>
      {selectedSponsor.fullName && (
        <NxtUserCard
          label={i18n.t('CHALLENGES.DETAIL.SPONSORED_BY')}
          imageSrc={selectedSponsor.photo}
          usernameElement={<span>{selectedSponsor.fullName}</span>}
          isInverted
        />
      )}
      <div className={classes.viewsContainer}>
        {daysToGo && <div>{daysToGo}</div>}
        <div>
          <span className={classes.viewsNumber}>{visits}</span>
          <span className={classes.viewsLabel}>{i18n.t('CHALLENGES.DETAIL.VISITS')}</span>
        </div>
        {!isPrivate && (
          <div>
            <span className={classes.viewsNumber}>{ideas}</span>
            <span className={classes.viewsLabel}>
              {i18n.t('CHALLENGES.DETAIL.IDEAS', { title: customIdeaTitles.pluralTitle || "ideas" })}
            </span>
          </div>
        )}
      </div>
      {whyImportant && (
        <div className={classes.importanceContainer}>
          <h3 className={classes.importanceLabel}>{i18n.t('CHALLENGES.WHY_IMPORTANT')}</h3>
          <span className={classes.importanceLabel}>{whyImportant}</span>
        </div>
      )}
      <div className={classes.extraBenefitsContainer}>
        {/* (!!approveMult || !!implementMult || !!supportMult) && (
          <NxtBenefits
            supportedMult={supportMult}
            approvedMult={approveMult}
            implementedMult={implementMult}
            benefitsLabel={i18n.t('CHALLENGES.DETAIL.EXTRA_BENEFITS')}
            supportedLabel={i18n.t('CHALLENGES.DETAIL.SUPPORTED')}
            approvedLabel={i18n.t('CHALLENGES.DETAIL.APPROVED')}
            implementedLabel={i18n.t('CHALLENGES.DETAIL.IMPLEMENTED')}
          />
        ) */}
        {(description || image.src) && (
          <Fragment>
            <div className={classes.benefitsDivider} />
            <NxtReward
              rewardText={description}
              whoGetRewardText={
                rewarded && rewarded !== 'OTHER' ? i18n.t(`CHALLENGES.${rewarded}`, { title: customIdeaTitles.pluralTitle || "ideas" }) : rewardedOther
              }
              rewardImageSrc={image.src}
              onClickToShowRewardImage={onClickToShowRewardImage}
              rewardLabel={i18n.t('CHALLENGES.DETAIL.REWARD')}
              whoGetRewardLabel={i18n.t('CHALLENGES.WHO_REWARDED_INFO')}
              customIdeaTitles={customIdeaTitles}
            />
          </Fragment>
        )}
      </div>
      {actionButton && <div className={classes.actionButton}>{actionButton}</div>}
    </Fragment>
  )
}

NxtChallengeResumeDetail.defaultProps = {
  daysToGo: null,
  visits: 0,
  ideas: 0,
  isPrivate: false,
  customIdeaTitles: {
    singularTitle: "",
    pluralTitle: ""
  }
}

NxtChallengeResumeDetail.propTypes = {
  // approveMult: PropTypes.number.isRequired,
  daysToGo: PropTypes.element,
  // implementMult: PropTypes.number.isRequired,
  onClickToShowRewardImage: PropTypes.func.isRequired,
  reward: PropTypes.shape({}).isRequired,
  selectedSponsor: PropTypes.shape({}).isRequired,
  // supportMult: PropTypes.number.isRequired,
  whyImportant: PropTypes.string.isRequired,
  visits: PropTypes.number,
  ideas: PropTypes.number,
  isPrivate: PropTypes.bool,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  })
}

export default withStyles(styles)(NxtChallengeResumeDetail)
