import React from 'react'
import PropTypes from 'prop-types'

const styles = {
  root: {
    height: 12,
    width: 12,
    cursor: 'pointer',
    border: 0,
    padding: 0,
    background: 'transparent',
  },
  dot: {
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
    height: 8,
    width: 8,
    borderRadius: '50%',
    margin: '3 2px',
  },
  active: {
    backgroundColor: '#0096e2',
  },
}

class PaginationDot extends React.Component {
  handleClick = event => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onClick(event, this.props.index)
  }

  render() {
    const { active } = this.props

    let styleDot

    if (active) {
      styleDot = Object.assign({}, styles.dot, styles.active)
    } else {
      styleDot = styles.dot
    }

    return (
      <button type="button" style={styles.root} onClick={this.handleClick}>
        <div style={styleDot} />
      </button>
    )
  }
}

PaginationDot.propTypes = {
  active: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default PaginationDot
