/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Boost from 'highcharts/modules/boost'
import NoData from 'highcharts/modules/no-data-to-display'
import i18n from 'support/i18n'
import styles from './View.Style'

Boost(Highcharts)
NoData(Highcharts)

const BarChart = ({
  title,
  categories,
  yAxisTitle,
  tooltip,
  plotOptions,
  series,
  isLoading,
}) => {
  const [options, setOptions] = useState(null)
  const chartRef = useRef(null)

  const onChartCreated = (chartReference) => {
    chartRef.current = chartReference
  }

  useEffect(() => {
    if (chartRef.current) {
      if (isLoading) {
        chartRef.current.showLoading()
      } else {
        chartRef.current.hideLoading()
      }
    }

    setOptions({
      chart: {
        type: 'column',
        marginTop: 80,
        marginRight: 40
      },
      title: {
        text: title
      },
      lang: { noData: isLoading ? '' : i18n.t('DASHBOARD.DATA_NOT_AVAILABLE_FOR_PERIOD') },
      credits: {
        enabled: false
      },
      xAxis: {
        categories
      },
      yAxis: {
        min: 0,
        title: {
          text: yAxisTitle
        }
      },
      tooltip: {
          headerFormat: tooltip.headerFormat,
          pointFormat: tooltip.pointFormat
      },
      plotOptions: {
        column: {
          stacking: plotOptions.column.stacking,
          depth: plotOptions.column.depth,
        }
      },
      series
    })
  }, [
    title,
    categories,
    yAxisTitle,
    tooltip,
    plotOptions,
    series,
    isLoading
  ])

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      callback={onChartCreated}
    />
  )
}

BarChart.defaultProps = {
  title: '',
  categories: [],
  yAxisTitle: '',
  tooltip: {
    headerFormat: '',
    pointFormat: ''
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      depth: 40
    }
  },
  series: []
}

BarChart.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.array,
  yAxisTitle: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  tooltip: PropTypes.objectOf(
    PropTypes.shape({
      headerFormat: PropTypes.string,
      pointFormat: PropTypes.array,
    }),
  ),
  plotOptions: PropTypes.object,
  series: PropTypes.array,
}

export default withStyles(styles)(BarChart)
