import { getCurrencyHtml } from 'support/utils/currency'

const parseInvestmentIdeas = data => {
  return data.map(row => {
    const { id, images, status, title } = row.idea
    const imageUrl =
      typeof images !== 'undefined' && images.length > 0 && typeof images[0].url !== 'undefined'
        ? images[0].url
        : ''
    return {
      id,
      invested: row.invested || 0,
      image: imageUrl,
      profit: row.profit || 0,
      status,
      title,
    }
  })
}

const parseTotalInvestedFromData = data => {
  return data.reduce(
    (accumulator, currentValue) => ({
      invested: accumulator.invested + currentValue.invested,
    }),
    { invested: 0 },
  ).invested
}

const parseTotalProfitFromData = data => {
  return data.reduce(
    (accumulator, currentValue) => ({
      profit: accumulator.profit + currentValue.profit,
    }),
    { profit: 0 },
  ).profit
}

const parseUserLastName = (name, fullName) => {
  if (!name || !fullName) return ''
  return fullName.replace(name, '').trim()
}

const parsePortfolioDistributionData = ({
  portfolio,
  currencyConfiguration,
  iconName,
  hasIconLeftSide,
}) => {
  const groups = {
    "PENDING_SUPPORT": "raising",
    "SUPPORTED": "closed",
    "APPROVED_BY_IT": "approved",
    "BUSINESS_PLAN_APROVED": "prototyped",
    "PILOT_PROJECT_APROVED": "prototyped"
  }
  const data = {
    count: {
      raising: 0,
			approved: 0,
			closed: 0,
			prototyped: 0
		},
		invest: {
			raising: 0,
			approved: 0,
			closed: 0,
			prototyped: 0
		},
		win: {
			raising: 0,
			approved: 0,
			closed: 0,
			prototyped: 0
    }
  }

  portfolio.forEach(element => {
    data.count[ groups[ element.currentIdeaStatus ]] += 1;
    data.invest[ groups[ element.currentIdeaStatus ]] += element.amount;
    data.win[ groups[ element.currentIdeaStatus ]] += element.profit;
  });

  Object.keys(data.invest).forEach(key => {
    data.invest[key] = getCurrencyHtml({
      number: data.invest[key],
      currencyConfiguration,
      iconName,
      hasIconLeftSide,
    })
    data.win[key] = getCurrencyHtml({
      number: data.win[key],
      currencyConfiguration,
      iconName,
      hasIconLeftSide,
    })
  })

  return data
}

export const transformGetInvestmentsServiceResponseDataWithState = jsonResponse => {
  return {
    ideas: parseInvestmentIdeas(jsonResponse.data),
    totalInvested: parseTotalInvestedFromData(jsonResponse.data),
    totalProfit: parseTotalProfitFromData(jsonResponse.data),
  }
}

export const transformEditProfileServiceResponseDataWithState = jsonResponse => {
  return {
    userName: jsonResponse.name,
    userLastName: parseUserLastName(jsonResponse.name, jsonResponse.fullname),
    userPhoto: jsonResponse.photo,
  }
}

export const transformUserPrefsServiceResponseWithState = jsonResponse => ({
  challenge: jsonResponse.challenge,
  ideastatus: jsonResponse.ideastatus,
  invest: jsonResponse.invest,
  newcoment: jsonResponse.newcoment,
  newidea: jsonResponse.newidea,
  splash: jsonResponse.splash,
  week: jsonResponse.week,
})

export const transformGetPortfolioServiceResponseDataWithState = (jsonResponse, state) => ({
  distribution: parsePortfolioDistributionData({
    portfolio: jsonResponse,
    currencyConfiguration: state.data.domain.domainConfiguration.currencyConfiguration,
    iconName: state.data.domain.domainConfiguration.currencyIcon,
    hasIconLeftSide: state.data.domain.domainConfiguration.hasCurrencyIconToLeft,
  }),
  portfolio: jsonResponse
})
