import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const OrganizationTypeSection = props => {
  const { classes, showRow, titleText, itemText } = props

  const renderText = (text) => {
    return (
      <div className={classes.itemContainer}>
        <span className="icon-approved" />
        <span>{text}</span>
      </div>
    )
  }

  return (
    <Fragment>
      {showRow && (
        <div className={classes.root}>
          <h2 className={classes.titleSection}>
            <span className="icon-th-list" />
            {titleText}
          </h2>
          {typeof itemText === 'string' ? (
            renderText(itemText) 
            ) : (
              <div className={classes.listContainer}>
                {itemText.map(text => (renderText(text)))}
              </div>
            )
          }
        </div>
      )}
    </Fragment>
  )
}

OrganizationTypeSection.defaultProps = {
  classes: {
    root: '',
    titleSection: '',
    itemContainer: '',
  },
}

OrganizationTypeSection.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    titleSection: PropTypes.string,
    itemContainer: PropTypes.string,
  }),
  showRow: PropTypes.bool.isRequired,
  titleText: PropTypes.string.isRequired,
  itemText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}

export default withStyles(styles)(OrganizationTypeSection)
