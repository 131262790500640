import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import i18n from 'support/i18n'
import InvestmentsTable from 'components/investments_table/View'
import styles from './View.Style'

const InvestmentsSection = props => {
  const [isOpen, setIsOpen] = useState(false)
  const { classes, ideas, onIdeaClick, username } = props
  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <span className="icon-evolution" />
        <span className={classes.titleText}>{i18n.t('SCREEN.INVESTMENTS')}</span>
      </div>
      {ideas.length > 0 && (
        <Accordion onChange={(_, isOpened) => setIsOpen(isOpened)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="investments-content"
            id="investments-content"
          >
            {isOpen ? i18n.t('HIDE') : i18n.t('SHOW')}
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.investmentsRoot }}>
            <InvestmentsTable
              ideas={ideas}
              onTitleClick={onIdeaClick}
              classes={{ middle: classes.rootTable }}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {ideas.length === 0 && (
        <Paper className={classes.noIdeasPaper}>
          {username} {i18n.t('PROFILE.NO_INVESTMENTS')}
        </Paper>
      )}
    </div>
  )
}

InvestmentsSection.propTypes = {
  ideas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onIdeaClick: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
}

export default withStyles(styles)(InvestmentsSection)
