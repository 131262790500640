import React from 'react'
import { connect } from 'react-redux'
import { debounce } from 'debounce'
import {
  onUploadImageFailure,
  resetForm,
  setImages,
  removeIdeaFile,
  setChallengeOrganization,
} from 'actions/sync/create_idea_form'
import { startConfirmationService, uploadFileThunk } from 'actions/async/uploadFileActions'
import { getActiveGoals } from 'services/goals'
import { getActiveTypes } from 'services/types'
import { getActiveAttributes } from 'services/attributes'
import { getActiveOrganizations } from 'services/organizations'
import { getActiveChallenges } from 'services/challenges'
import {
  editIdea,
  getIdea,
  postIdeaImage,
  saveIdeaDraft,
  titleAvailable,
  uploadFile,
  deleteIdeaImage,
  postFileUploadUrl,
  postIdeaFile,
  getNewIdeaChallengeOrganization,
} from 'services/create_idea'
import {
  postIdeaVideo,
  generateVideoUploadUrl,
  deleteIdeaVideo,
  getIdeaVideoType,
} from 'services/videos'
import { resetIdeaStorageVideo, setIdeaExternalVideo } from 'actions/sync/video'
import {
  showPopupDialog,
  showPopupErrorSnackbar,
  showPopupSuccessSnackbar,
} from 'support/popup_dialogs'
import i18n from 'support/i18n'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import ImageCropper from 'components/image_uploader/components/image_cropper/View'
import Navigation from 'support/navigation'
import { ifExistsReturn } from 'support/utils/object'
import { VIDEO_STATE_TYPE } from 'common_constants/Video'
import { reduceToProp } from 'support/utils/array'
import { MAX_SIZE_DOCUMENT, MAX_DOCUMENT_BYTES } from 'common_constants/Ideas'
import { generateRequestCsrf } from 'support/utils/auth'
import ConfirmSubmitModal from './components/dialogs/ConfirmSubmitContainer'
import ErrorSubmitModal from './components/dialogs/ErrorSubmitContainer'
import VideoUploadDialog from './components/dialogs/UploadVideoContainer'
import View from './View'

const mapStateToProps = (state, ownProps) => {
  return {
    // main data
    isPrivate:
      ifExistsReturn(ownProps.isPrivate) || ifExistsReturn(state.data.createIdea.isPrivate),
    title: state.data.createIdea.title,
    isTitleLoading: state.data.createIdea.isTitleLoading,
    isTitleAvailable: state.data.createIdea.isTitleAvailable,
    images: state.data.createIdea.images,
    imageUploadError: state.data.createIdea.errors.imageUploadError,
    description: state.data.createIdea.description,
    externalVideo: state.data.createIdea.videos.externalVideo,
    fileVideo: state.data.createIdea.videos.fileVideo,
    fileVideoSrc: state.data.createIdea.videos.fileVideoUrl
      ? `${process.env.REACT_APP_BACKEND_BASE_URL}${
          state.data.createIdea.videos.fileVideoUrl
        }?_nxt_csrf=${generateRequestCsrf(state.auth.csrf)}`
      : state.data.createIdea.videos.fileVideoUrl,
    fileVideoType: state.data.createIdea.videos.fileVideoType,
    fileVideoUrl: state.data.createIdea.videos.fileVideoUrl,
    fileVideoId: state.data.createIdea.videos.fileVideoId,
    isFileVideoLoading: state.data.createIdea.videos.isVideoLoading,
    isFileVideoReady: state.data.createIdea.videos.isVideoReady,
    isVideoRemoving: state.data.createIdea.videos.isVideoRemoving,
    hasVideoError: state.data.createIdea.videos.hasVideoError,
    endDate: state.data.createIdea.endDate,
    challenge: state.data.createIdea.challenge.id,
    challenges: state.data.challenge.challenges,
    challengeOrganization: state.data.createIdea.challengeOrganization,
    organization: state.data.createIdea.organization,
    organizations: state.data.domain.folderOrganizations,
    organizationsName: state.data.domain.domainConfiguration.organizationsName,
    isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
    type: reduceToProp(state.data.createIdea.type, 'id', item => item.toString()),
    types: state.data.domain.types,
    typesName: state.data.domain.domainConfiguration.typesName,
    isTypesActive: state.data.domain.domainConfiguration.isTypesActive,
    attribute: reduceToProp(state.data.createIdea.attribute, 'id', item => item.toString()),
    attributes: state.data.domain.attributes,
    attributesName: state.data.domain.domainConfiguration.attributesName,
    isAttributesActive: state.data.domain.domainConfiguration.isAttributesActive,
    goalValues: state.data.createIdea.goals,
    goals: state.data.domain.goals,
    documents: state.data.createIdea.documents,
    isDocumentLoading: state.data.createIdea.isDocumentLoading,
    errors: state.data.createIdea.errors,
    isPreviousValidationEnabled: state.data.domain.domainConfiguration.isPreviousValidationEnabled,
    currencyIcon: state.data.domain.domainConfiguration.currencyIcon,
    // logistic data
    implementEstimatedHours: state.data.createIdea.implementEstimatedHours,
    implementEstimatedQuantity: state.data.createIdea.implementEstimatedQuantity,
    implementEstimatedTime: state.data.createIdea.implementEstimatedTime,
    implementEstimatedTimeType: state.data.createIdea.implementEstimatedTimeType,
    pilotEstimatedHours: state.data.createIdea.pilotEstimatedHours,
    pilotEstimatedQuantity: state.data.createIdea.pilotEstimatedQuantity,
    pilotEstimatedTime: state.data.createIdea.pilotEstimatedTime,
    pilotEstimatedTimeType: state.data.createIdea.pilotEstimatedTimeType,
    isDraft: state.data.createIdea.isDraft,
    economicBenefits: state.data.createIdea.economicBenefits,
    otherBenefits: state.data.createIdea.otherBenefits,
    showLogisticData: state.data.domain.domainConfiguration.logistic,
    isLogisticActiveOnCreate: state.data.domain.domainConfiguration.isLogisticActiveOnCreate,
    isChallengeMandatoryForIdeaCreation:
      state.data.domain.domainConfiguration.isChallengeMandatoryForIdeaCreation,
    logisticCurrency: state.data.domain.domainConfiguration.logisticCurrencyConfiguration.unit,
    isAnonymous: state.data.createIdea.isAnonymous,
    isAnonymousIdeas: state.data.domain.domainConfiguration.isAnonymousIdeas,
    isAnonymousIdeasMandatory: state.data.domain.domainConfiguration.isAnonymousIdeasMandatory,
    isAdmin: state.data.user.admin,
    isInnovator: state.data.user.isInnovator,
    isPendingValidation: state.data.createIdea.isPendingValidation,
    creatingIdea: state.data.createIdea.creatingIdea,
    hasLoaded: state.data.createIdea.hasLoaded,
    // files data
    fileToUpload: state.data.createIdea.fileToUpload,
    files: state.data.createIdea.files,
    csrf: state.auth.csrf,
    customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    navigateToChallenge: challengeId => Navigation.navigateToChallengeDetail(challengeId),
    checkTitleChange: debounce(value => {
      dispatch(titleAvailable(value))
    }, 1000),
    getGoals: () => dispatch(getActiveGoals()),
    getChallenges: () => dispatch(getActiveChallenges()),
    getChallengeOrganization: challengeId => {
      dispatch(getNewIdeaChallengeOrganization(challengeId))
    },
    setChallengeOrganization: challengeId => {
      dispatch(setChallengeOrganization(challengeId))
    },
    getOrganizations: () => dispatch(getActiveOrganizations()),
    getTypes: () => dispatch(getActiveTypes()),
    getAttributes: () => dispatch(getActiveAttributes()),
    resetForm: () => dispatch(resetForm()),
    submitDraft: formData => {
      const onSuccess = data => {
        dispatch(Navigation.navigateToIdea(data.idea.id))
      }
      const onFailure = ({ data }) => {
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        showPopupDialog(<ErrorSubmitModal description={i18n.t(messageTag)} />, dispatch)
      }
      dispatch(saveIdeaDraft(formData, onSuccess, onFailure))
    },
    submitIdea: formData => showPopupDialog(<ConfirmSubmitModal formData={formData} />, dispatch),
    editIdea: (id, formData) => {
      const onSuccess = () => {
        Navigation.navigateBack()
      }
      const onFailure = ({ data }) => {
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        showPopupDialog(<ErrorSubmitModal description={i18n.t(messageTag)} />, dispatch)
      }
      dispatch(editIdea(id, formData, onSuccess, onFailure))
    },
    onCancel: () => {
      Navigation.navigateBack()
    },
    onFileSelect: file => {
      const onSuccess = url => {
        dispatch(uploadFileThunk(url, file))
      }
      dispatch(uploadFile(onSuccess))
    },
    getIdea: id => {
      dispatch(getIdea(id))
    },
    onSelectImage: (id, image) => {
      const onSuccess = () => dispatch(hideDialog())
      const onFailure = error => {
        if (error.status === 413) {
          dispatch(onUploadImageFailure(i18n.t('IDEA.CHOOSE_VALID_IMAGE')))
        }
        dispatch(hideDialog())
      }
      const component = (
        <ImageCropper
          imageFile={image}
          onSelectCrop={(_, croppedImage) => {
            dispatch(postIdeaImage(id, croppedImage, onSuccess, onFailure))
          }}
          onClose={() => dispatch(hideDialog())}
        />
      )
      showPopupDialog(component, dispatch)
    },
    onDeleteImage: (ideaId, newImages, imageToDelete) => {
      dispatch(setImages(newImages))
      if (ideaId) {
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('IDEA.DELETE_IMAGE_ERROR'), dispatch)
          dispatch(setImages([...newImages, imageToDelete]))
        }
        dispatch(deleteIdeaImage(ideaId, imageToDelete.key, onFailure))
      }
    },
    onSelectFileVideo: ideaId =>
      showPopupDialog(
        <VideoUploadDialog
          parentId={ideaId}
          onOuterUploadVideo={postIdeaVideo}
          beforeOuterUploadVideo={generateVideoUploadUrl}
          onOuterDeleteVideo={deleteIdeaVideo}
          stateType={VIDEO_STATE_TYPE.IDEA}
        />,
        dispatch,
      ),
    onVideoEncodingError: () =>
      showPopupErrorSnackbar(i18n.t('VIDEO.VIDEO_ENCODING_ERROR'), dispatch),
    onRemoveFileVideo: () => {
      dispatch(resetIdeaStorageVideo())
    },
    checkVideoState: videoId =>
      dispatch(
        startConfirmationService({
          isIdea: true,
          videoId,
        }),
      ),
    onGenerateFileUploadUrl: (id, file) => {
      const onFailure = () => {
        const message = i18n.t('EXPLORE_IDEA.UPLOAD_DOC_ERROR')
        showPopupErrorSnackbar(message, dispatch)
      }
      if (file.size > MAX_DOCUMENT_BYTES) {
        showPopupErrorSnackbar(
          i18n.t('EXPLORE_IDEA.UPLOAD_MAX_SIZE_DOC_ERROR', { max_size: MAX_SIZE_DOCUMENT }),
          dispatch,
        )
      } else {
        dispatch(postFileUploadUrl(id, file, onFailure))
      }
    },
    onUploadFile: data => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.UPLOAD_DOC_COMPLETE'), dispatch)
        dispatch(hideDialog())
      }

      const onFailure = errorResponse => {
        let message = i18n.t('EXPLORE_IDEA.UPLOAD_DOC_ERROR')
        if (errorResponse.status === 413) {
          message = i18n.t('EXPLORE_IDEA.UPLOAD_MAX_SIZE_DOC_ERROR', {
            max_size: MAX_SIZE_DOCUMENT,
          })
        }
        showPopupErrorSnackbar(message, dispatch)
      }

      dispatch(postIdeaFile(data, onSuccess, onFailure))
    },
    onDeleteFile: fileKey => dispatch(removeIdeaFile(fileKey)),
    onExternalVideoChange: src => dispatch(setIdeaExternalVideo(src)),
    getIdeaVideoType: url => dispatch(getIdeaVideoType(url)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
