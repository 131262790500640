import React, { useEffect, useState } from 'react'
// import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button } from '@material-ui/core'
import clsx from 'clsx'
import i18n from 'support/i18n'
import imgDefault from 'img/default-photo.png'
import Flip from 'components/animations/flip/View'
import FlipCard from 'components/animations/flip/components/flip_card/View'
import { Image } from 'components'
import styles from './View.Style'

function PeopleGrid({
  theme,
  classes,
  users,
  page,
  cardsPerPage,
  onLoadMore,
  onCardClick,
}) {
  const [currPage, setCurrPage] = useState(0)

  const handleCardClick = (userData) => onCardClick(userData.user.email)

  const handleLoadMore = () => {
    setCurrPage(currPage + 1)
    onLoadMore(currPage + 1, cardsPerPage)
  }

  const renderRow = (value, title, icon, last) => {
    return (
      <>
        <div className={classes.background}/>
        <div className={clsx(classes.row, last && classes.noBorder)}>
          <div className={classes.title}>
            {title}
          </div>
          <div className={classes.data}>
            <div className={classes.number}>
              <span>{value}</span>
            </div>
            <div className={classes.icon}>
              <span className={icon} />
            </div>
          </div>
        </div>
      </>
    )
  }

  useEffect(() => {
    setCurrPage(page)
  }, [page])

  return (
    <>
      <div className={classes.cards}>
        {users && users.map(userData => (
          <div key={userData.user.email} className={classes.cardContainer}>
            <Flip>
              <FlipCard side="front" className={classes.front}>
                <Image 
                  defaultSrc={imgDefault} 
                  src={userData.user.photo} 
                  alt={userData.user.fullname} 
                />
                <span className={classes.userInfo}>
                  {userData.user.fullname}
                </span>
                <Button 
                  onClick={() => handleCardClick(userData)} 
                  className={classes.button} 
                />
              </FlipCard>
              <FlipCard side="back" theme={theme}>
                {renderRow(userData.ideasOwned, i18n.t('DASHBOARD.IDEAS'), 'icon-idea')}
                {renderRow(userData.investments, i18n.t('DASHBOARD.INVESTMENTS'), 'icon-evolution')}
                {renderRow(userData.comments, i18n.t('DASHBOARD.COMMENTS'), 'icon-comment', true)}
                <Button 
                  onClick={() => handleCardClick(userData)} 
                  className={classes.button} 
                  />
              </FlipCard>
            </Flip>        
          </div>
        ))}
      </div>
      {users.length ? (
        <div className={classes.loadMoreWrapper}>
          <Button 
            variant="contained" 
            color="primary" 
            size="large" 
            onClick={handleLoadMore}
          >
            {i18n.t('HOME.FILTER.LOAD_MORE')}
          </Button>
        </div>
      ): (
        <></>
      )}
    </>
  )
}

export default withStyles(styles)(PeopleGrid)
