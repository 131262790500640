import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { Slider, Button, Paper, withStyles, IconButton, CircularProgress } from '@material-ui/core'
import Cropper from 'react-easy-crop'
import styles from './View.Style'

class ImageCropper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      crop: { x: 0, y: 0 },
      area: null,
      zoom: 1,
      isUploading: false,
    }

    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect() {
    this.setState({ isUploading: true })
    setTimeout(() => {
      const { imageFile, onSelectCrop } = this.props
      const { area } = this.state
      const image = new Image()
      image.src = imageFile.src
      const canvas = document.createElement('canvas')
      canvas.width = 500
      canvas.height = 310
      const ctx = canvas.getContext('2d')

      ctx.drawImage(image, area.x, area.y, area.width, area.height, 0, 0, 500, 310)
      onSelectCrop(imageFile.name, canvas.toDataURL())
    }, 200)
  }

  render() {
    const { classes, imageFile, aspect, onClose } = this.props
    const { crop, zoom, isUploading } = this.state
    return (
      <Paper className={classes.root}>
        <IconButton className={classes.close} onClick={onClose}>
          <span className="icon-close" />
        </IconButton>
        <div className={classes.imageSection}>
          <Cropper
            image={imageFile.src}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={_crop => this.setState({ crop: _crop })}
            onCropComplete={(unusedParam, areaInPixels) => this.setState({ area: areaInPixels })}
          />
        </div>
        <div className={classes.buttonsSection}>
          <Slider
            value={zoom}
            min={1}
            max={4}
            step={0.05}
            aria-labelledby="Zoom"
            className={classes.colorSlider}
            onChange={(e, _zoom) => this.setState({ zoom: _zoom })}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={this.handleSelect}
            disabled={isUploading}
          >
            <span className={classes.buttonTitle}>{i18n.t('EXPLORE_IDEA.EDIT_KPI')}</span>
            {isUploading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </div>
      </Paper>
    )
  }
}

ImageCropper.defaultProps = {
  aspect: 50 / 31,
}

ImageCropper.propTypes = {
  aspect: PropTypes.number,
  onSelectCrop: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  imageFile: PropTypes.shape({
    src: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(ImageCropper)
