import i18n from 'support/i18n'
import { getLocaleShortMonth } from 'support/date_fns_locale'
import { format, parse } from 'date-fns'
import { locale } from 'support/utils/dates'

function getValuePercentageAndType(val1, val2) {
  const value = val2 - val1
  const percentage = `${Math.round((value * 100) / val1)}%`
  let type = 'equal'
  if (value > 0) type = 'positive'
  if (value < 0) type = 'negative'
  return {
    value: value || '=',
    percentage: value ? percentage : '',
    type,
  }
}

function getComparisonFromArrays(previousArray, currentArray) {
  const comparisonArray = []
  previousArray.forEach((item, index) => {
    comparisonArray.push(getValuePercentageAndType(item, currentArray[index]))
  })
  return comparisonArray
}

export function transformActivityTable(jsonResponse, fromDate, toDate) {
  const { previous } = jsonResponse.items[0]
  const { current } = jsonResponse.items[0]

  let comparisonRow = []

  const returnObject = {
    dataRows: [
      {
        firstColumn: [
          {
            mainText: fromDate.getDate(),
            subText: `${getLocaleShortMonth(fromDate)} ${fromDate.getFullYear()}`,
          },
        ],
        data: [
          previous && previous.ideas,
          previous && previous.investments,
          previous && previous.idea_comments,
          previous && previous.idea_visits,
          previous && previous.system_access,
        ],
      },
      {
        firstColumn: [
          {
            mainText: toDate.getDate(),
            subText: `${getLocaleShortMonth(toDate)} ${toDate.getFullYear()}`,
          },
        ],
        data: [
          current && current.ideas,
          current && current.investments,
          current && current.idea_comments,
          current && current.idea_visits,
          current && current.system_access,
        ],
      },
    ],
  }

  comparisonRow = getComparisonFromArrays(
    returnObject.dataRows[0].data,
    returnObject.dataRows[1].data,
  )
  returnObject.comparisonRow = {
    firstColumn: [{}],
    data: comparisonRow,
  }
  return returnObject
}

export function transformRequestReportResponse(jsonResponse) {
  return {
    success: jsonResponse.success,
    mailTo: jsonResponse.mailTo,
  }
}

export function transformIdeasTable(jsonResponse, fromDate, toDate) {
  const { previous } = jsonResponse.items[0]
  const { current } = jsonResponse.items[0]

  let comparisonRow = []

  const returnObject = {
    dataRows: [
      {
        firstColumn: [
          {
            mainText: fromDate.getDate(),
            subText: `${getLocaleShortMonth(fromDate)} ${fromDate.getFullYear()}`,
          },
          {
            mainText: previous && previous.ideas,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          previous && previous.ideas_raising,
          previous && previous.ideas_supported,
          previous && previous.ideas_round_closed,
          previous && previous.ideas_approved,
          previous && previous.ideas_pilot,
          previous && previous.ideas_implemented,
          previous && previous.ideas_discarded,
        ],
      },
      {
        firstColumn: [
          {
            mainText: toDate.getDate(),
            subText: `${getLocaleShortMonth(toDate)} ${toDate.getFullYear()}`,
          },
          {
            mainText: current && current.ideas,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          current && current.ideas_raising,
          current && current.ideas_supported,
          current && current.ideas_round_closed,
          current && current.ideas_approved,
          current && current.ideas_pilot,
          current && current.ideas_implemented,
          current && current.ideas_discarded,
        ],
      },
    ],
  }

  comparisonRow = getComparisonFromArrays(
    returnObject.dataRows[0].data,
    returnObject.dataRows[1].data,
  )

  const { value, percentage, type } = getValuePercentageAndType(
    returnObject.dataRows[0].firstColumn[1].mainText,
    returnObject.dataRows[1].firstColumn[1].mainText,
  )
  returnObject.comparisonRow = {
    firstColumn: [
      { mainText: '', subText: i18n.t('DASHBOARD.CHANGE') },
      {
        mainText: value,
        subText: percentage,
        type,
      },
    ],
    data: comparisonRow,
  }
  return returnObject
}

export function transformPeopleTable(jsonResponse, fromDate, toDate) {
  const { previous } = jsonResponse.items[0]
  const { current } = jsonResponse.items[0]

  let comparisonRow = []

  const returnObject = {
    dataRows: [
      {
        chart: [{
          name: 'user_w_investments',
          y: previous && previous.user_w_investments,
          color: "#4b4b4b"
        }, {
          name: 'user_w_comments',
          y: previous && previous.user_w_comments,
          color: "#fa5d7f"
        }, {
          name: 'user_w_ideas',
          y: previous && previous.user_w_ideas,
          color: "#ffcd40"
        }, {
          name: 'user_w_isupported',
          y: previous && previous.user_w_isupported,
          color: "#d19600"
        }, {
          name: 'user_w_iapproved',
          y: previous && previous.user_w_iapproved,
          color: "#0097e2"
        }, {
          name: 'user_w_ipilot',
          y: previous && previous.user_w_ipilot,
          color: "#63ffe4"
        }, {
          name: 'user_w_iimplemented',
          y: previous && previous.user_w_iimplemented,
          color: "#00d24c"
        }],
        firstColumn: [
          {
            mainText: fromDate.getDate(),
            subText: `${getLocaleShortMonth(fromDate)} ${fromDate.getFullYear()}`,
          },
          {
            mainText: previous && previous.au,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          previous && previous.user_w_investments,
          previous && previous.user_w_comments,
          previous && previous.user_w_ideas,
          previous && previous.user_w_isupported,
          previous && previous.user_w_iapproved,
          previous && previous.user_w_ipilot,
          previous && previous.user_w_iimplemented,
        ],
      },
      {
        chart: [{
          name: 'user_w_investments',
          y: current && current.user_w_investments,
          color: "#4b4b4b"
        }, {
          name: 'user_w_comments',
          y: current && current.user_w_comments,
          color: "#fa5d7f"
        }, {
          name: 'user_w_ideas',
          y: current && current.user_w_ideas,
          color: "#ffcd40"
        }, {
          name: 'user_w_isupported',
          y: current && current.user_w_isupported,
          color: "#d19600"
        }, {
          name: 'user_w_iapproved',
          y: current && current.user_w_iapproved,
          color: "#0097e2"
        }, {
          name: 'user_w_ipilot',
          y: current && current.user_w_ipilot,
          color: "#63ffe4"
        }, {
          name: 'user_w_iimplemented',
          y: current && current.user_w_iimplemented,
          color: "#00d24c"
        }],
        firstColumn: [
          {
            mainText: toDate.getDate(),
            subText: `${getLocaleShortMonth(toDate)} ${toDate.getFullYear()}`,
          },
          {
            mainText: current && current.au,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          current && current.user_w_investments,
          current && current.user_w_comments,
          current && current.user_w_ideas,
          current && current.user_w_isupported,
          current && current.user_w_iapproved,
          current && current.user_w_ipilot,
          current && current.user_w_iimplemented,
        ],
      },
    ],
  }

  comparisonRow = getComparisonFromArrays(
    returnObject.dataRows[0].data,
    returnObject.dataRows[1].data,
  )

  const { value, percentage, type } = getValuePercentageAndType(
    returnObject.dataRows[0].firstColumn[1].mainText,
    returnObject.dataRows[1].firstColumn[1].mainText,
  )
  returnObject.comparisonRow = {
    firstColumn: [
      { mainText: '', subText: i18n.t('DASHBOARD.CHANGE') },
      {
        mainText: value,
        subText: percentage,
        type,
      },
    ],
    data: comparisonRow,
  }
  return returnObject
}

export function transformPrivateIdeasTable(jsonResponse, fromDate, toDate) {
  const { previous } = jsonResponse.items[0]
  const { current } = jsonResponse.items[0]

  let comparisonRow = []

  const returnObject = {
    dataRows: [
      {
        firstColumn: [
          {
            mainText: fromDate.getDate(),
            subText: `${getLocaleShortMonth(fromDate)} ${fromDate.getFullYear()}`,
          },
          {
            mainText: previous && previous.private_ideas,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          previous && previous.private_ideas_round_closed,
          previous && previous.private_ideas_approved,
          previous && previous.private_ideas_pilot,
          previous && previous.private_ideas_implemented,
          previous && previous.private_ideas_discarded,
        ],
      },
      {
        firstColumn: [
          {
            mainText: toDate.getDate(),
            subText: `${getLocaleShortMonth(toDate)} ${toDate.getFullYear()}`,
          },
          {
            mainText: current && current.private_ideas,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          current && current.private_ideas_round_closed,
          current && current.private_ideas_approved,
          current && current.private_ideas_pilot,
          current && current.private_ideas_implemented,
          current && current.private_ideas_discarded,
        ],
      },
    ],
  }

  comparisonRow = getComparisonFromArrays(
    returnObject.dataRows[0].data,
    returnObject.dataRows[1].data,
  )

  const { value, percentage, type } = getValuePercentageAndType(
    returnObject.dataRows[0].firstColumn[1].mainText,
    returnObject.dataRows[1].firstColumn[1].mainText,
  )
  returnObject.comparisonRow = {
    firstColumn: [
      { mainText: '', subText: i18n.t('DASHBOARD.CHANGE') },
      {
        mainText: value,
        subText: percentage,
        type,
      },
    ],
    data: comparisonRow,
  }
  return returnObject
}

export function transformSystemAccessesResponse(jsonResponse) {
  return [
    {
      name: i18n.t('DASHBOARD.ACCESS'),
      data: jsonResponse.items,
    },
  ]
}

export function transformIdeasSeriesResponse(jsonResponse, state) {
  const { customIdeaTitles } = state.data.domain.customTitlesConf
  const seriesAsObject = jsonResponse.items[0] || {}

  const names = {
    'ideas': i18n.t("DASHBOARD.IDEAS", { title: customIdeaTitles.pluralTitle || "ideas" }),
    'ideas_approved': state.data.domain.domainConfiguration.ideaStatusStepper.approvedLabel,
    'ideas_discarded': state.data.domain.domainConfiguration.ideaStatusStepper.discardedLabel,
    'ideas_implemented': state.data.domain.domainConfiguration.ideaStatusStepper.implementedLabel,
    'ideas_pilot': state.data.domain.domainConfiguration.ideaStatusStepper.pilotActiveLabel,
    'ideas_raising': state.data.domain.domainConfiguration.ideaStatusStepper.pendingSupportLabel,
    'ideas_round_closed': state.data.domain.domainConfiguration.ideaStatusStepper.roundClosedLabel,
    'ideas_supported': state.data.domain.domainConfiguration.ideaStatusStepper.supportedLabel,
  }
  return Object.keys(seriesAsObject).map(key => ({
    name: names[key.replace('private_', '')],
    data: seriesAsObject[key],
  }))
}

export function transformUsersTableResponse(jsonResponse) {
  return {
    users: jsonResponse.userstats || [],
    totalSearchItems: jsonResponse.total,
  }
}

/* eslint-disable */
// The following code is a legacy code taken from iris in order to calculate the
// participation/success percentage.
export function transformUsersParticipationResponse(jsonResponse, state, offset) {
  const p_data = jsonResponse.userstats
  var totals = {
    engage: 0,
    success: 0,
    ideas: 0,
    teams: 0,
    comments: 0,
    investments: 0,
    funded: 0,
    teamsFunded: 0,
    commentsLiked: 0,
    investmentsSucess: 0,
    ideasAvg: 0,
    fundedAvg: 0,
    teamsAvg: 0,
    teamsFundedAvg: 0,
    commentsAvg: 0,
    commentsLikedAvg: 0,
    investmentsAvg: 0,
    investmentsSucessAvg: 0,
    userParticipate: 0,
    userParticipation: 0,
  }
  var userseries = []

  if (p_data !== null && p_data.length > 0) {
    var userL = p_data
    var maxIdeas = 0
    var maxTeams = 0
    var maxComments = 0
    var maxInvestments = 0

    //first we need to find the max tresholds
    for (var i = 0; i < userL.length; i++) {
      var user = userL[i]

      if (maxIdeas < user.ideasOwned) maxIdeas = user.ideasOwned
      if (maxTeams < user.teamsInvolved) maxTeams = user.teamsInvolved
      if (maxComments < user.comments) maxComments = user.comments
      if (maxInvestments < user.investments) maxInvestments = user.investments
    }

    //with max tresholds, we can now calculate each user
    for (var i = 0; i < userL.length; i++) {
      var user = userL[i]

      var ideas = user.ideasOwned
      var funded = user.ideasFunded
      var teams = user.teamsInvolved
      var teamsFunded = user.teamsFunded
      var comments = user.comments
      var commentsLiked = user.commentsLiked
      var investments = user.investments
      var investmentsSucess = user.investmentsSuccess

      totals.ideas += ideas
      totals.funded += funded
      totals.teams += teams
      totals.teamsFunded += teamsFunded
      totals.comments += comments
      totals.commentsLiked += commentsLiked
      totals.investments += investments
      totals.investmentsSucess += investmentsSucess

      //PARTICIPATION
      var ideasW = 0.6
      var teamsW = 0.5
      var investW = 0.5
      var commentW = 0.2

      var wIdeas = (ideas / maxIdeas) * ideasW
      var wTeams = (teams / maxTeams) * teamsW
      var wInvestments = (investments / maxInvestments) * investW
      var wComments = (comments / maxComments) * commentW

      var xValue = Math.ceil(
        ((!isFinite(wIdeas) ? 0 : wIdeas) +
          (!isFinite(wTeams) ? 0 : wTeams) +
          (!isFinite(wInvestments) ? 0 : wInvestments) +
          (!isFinite(wComments) ? 0 : wComments)) *
        100,
      )

      xValue = xValue > 100 ? 100 : xValue
      totals.userParticipation += xValue

      if (xValue > 0) totals.userParticipate++

      totals.engage += xValue

      //SUCCESS
      ideasW = 0.3
      teamsW = 0.3
      investW = 0.3
      commentW = 0.1

      wIdeas = (funded / ideas) * ideasW
      wTeams = (teamsFunded / teams) * teamsW
      wInvestments = (investmentsSucess / investments) * investW
      wComments = (commentsLiked / comments) * commentW

      var yValue = Math.ceil(
        ((funded + teamsFunded + investmentsSucess + commentsLiked) /
          (ideas + teams + investments + comments)) *
        100,
      )

      yValue = isNaN(yValue) ? 0 : yValue

      yValue = yValue > 100 ? 100 : yValue
      totals.success += yValue

      userseries[i] = {
        name: user.user.fullname,
        email: user.user.email,
        photo: user.user.photo,
        ideas: ideas,
        funded: funded,
        teams: teams,
        teamsFunded: teamsFunded,
        comments: comments,
        commentsLiked: commentsLiked,
        investments: investments,
        investmentsSuccess: investmentsSucess,
        x: xValue,
        y: yValue,
      }
    }

    totals.successAvg = (totals.success / userL.length).toFixed(2)
    totals.userParticipationAvg = ((totals.userParticipate / userL.length) * 100).toFixed(2)
  }
  const {
    totalUsers,
    participationAvg,
    successAvg,
    series,
  } = state.data.dashboard.people.usersParticipation
  let oldSeries = []
  if (offset !== 0 && series.length > 0) {
    oldSeries = series[0].data
  }
  let totalLength = oldSeries.length + userseries.length
  if (totalLength === 0) {
    totalLength = 1
  }
  const participatingWeightedAverage =
    (1 / totalLength) *
    (Number(participationAvg) * oldSeries.length +
      Number(totals.userParticipationAvg) * userseries.length)
  const successWeightedAverage =
    (1 / totalLength) *
    (Number(successAvg) * oldSeries.length + Number(totals.successAvg) * userseries.length)

  let numberOfUsers = p_data.length
  if (offset !== 0) {
    numberOfUsers = totalUsers + p_data.length
  }
  return {
    hasMore: jsonResponse.more,
    totalUsers: numberOfUsers,
    participationAvg: participatingWeightedAverage.toFixed(2),
    successAvg: successWeightedAverage.toFixed(2),
    series: [
      {
        data: oldSeries.concat(userseries),
      },
    ],
  }
}

export function transformWeekActivityResponse(jsonResponse, state) {
  const results = jsonResponse.results;
  const categories = [];
  const series = [{
    name: 'Web Access',
    data: [],
    stack: 'access'
  }, {
    name: 'Mobile Access',
    data: [],
    stack: 'access'
  }, {
    name: 'iOS Access',
    data: [],
    stack: 'mobileaccess',
    color: '#b4b5b9'
  }, {
    name: 'Android Access',
    data: [],
    stack: 'mobileaccess',
    color: '#A4C639'
  }];
  for (let i = 0; i < results.length; i++) {
    const date = parse(results[i].date, 'yyyy-MM-dd', new Date())
    categories[categories.length] = format(date, "dd MMM", {
      locale: locale(state.i18n.currentLang)
    })
    series[0].data[series[0].data.length] = results[i]["web_access"]
    series[1].data[series[1].data.length] = results[i]["mobile_access"]
    series[2].data[series[2].data.length] = results[i]["ios_access"]
    series[3].data[series[3].data.length] = results[i]["android_access"]
  }

  return {
    firstDate: jsonResponse.firstDate,
    categories,
    series
  }
}