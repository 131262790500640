import React from 'react'
import i18n from 'support/i18n'
import { showPopupDialog } from 'support/popup_dialogs'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import { getNewIdeas, getTotalInvestments } from 'services/domain'
import SuccessModal from './SuccessContainer'
import ErrorModal from './ErrorContainer'

export const mainButtonActionSetup = (dispatch, ownProps, setup) => {
  const onSuccess = () => {
    dispatch(getNewIdeas())
    dispatch(getTotalInvestments())
    showPopupDialog(<SuccessModal description={setup.successModalText} />, dispatch)
  }
  const onFailure = ({ data }) => {
    const messageTag =
      data && data.error && data.error.error
        ? `BACKEND_ERRORS.${data.error.error}`
        : 'BACKEND_ERRORS.UNEXPECTED'
    showPopupDialog(<ErrorModal description={i18n.t(messageTag)} />, dispatch)
  }
  dispatch(setup.onClickActionButton(ownProps, onSuccess, onFailure))
  dispatch(hideDialog())
  dispatch(setup.navigateToAfter)
}
