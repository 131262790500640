import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    buttonSearch: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    container: {
      ...theme.flexRowCenter,
      justifyContent: 'space-between',
      maxWidth: '300px',
    },
    searchField: {
      width: '30rem',
      backgroundColor: 'transparent',
      borderRadius: '4px',
      color: theme.palette.primary,
      margin: 0,
      borderCollapse: 'separate',
      border: '1px solid rgba(0, 0, 0, 0.3)', 
      '&:after, &:before': {
        content: 'unset',
      },
      '& > div': {
        margin: 0,
        position: 'absolute',
        right: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: '36px',
        width: '36px',
        borderRadius: '0 4px 4px 0',
        top: 'unset',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: '36px',
        '&:after, &:before': {
          content: 'unset',
        },
      },
      '& > input': {
        paddingLeft: '15px',
        height: '23px',
      },
      '& > input::placeholder': {
        color: '#000',
      },
    },
  })

export default styles
