import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { Router } from 'react-router'
import NavigationService from '../../NavigationService'
import LoggedInNavigator from '../logged_in_navigator'
import LoggedOutNavigator from '../logged_out_navigator'

const RootNav = props => {
  const {
    loggedIn,
    logging,
    domain,
    setDomain,
    onRouteChange,
    hasDomainLoaded,
    isAdmin,
    isInnovator,
    isDomainInMaintenance,
    onMaintenanceDomain,
  } = props

  const customHistory = NavigationService.getHistory()
  const [currentLocation, setCurrentLocation] = useState(customHistory.location.pathname)

  if (loggedIn && domain) {
    NavigationService.setDomain(domain)
  }

  useEffect(() => {
    const urlDomain = customHistory.location.pathname.split('/')[1]
    if (loggedIn && urlDomain && domain !== urlDomain) {
      NavigationService.setDomain(urlDomain)
      setDomain(urlDomain)
    }
  })

  useEffect(() => {
    setCurrentLocation(customHistory.location.pathname)
    onRouteChange(customHistory.location.pathname)
    customHistory.listen(location => {
      onRouteChange(location.pathname)
      setCurrentLocation(location.pathname)
      window.scrollTo(0, 0)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isAdmin && !isInnovator && hasDomainLoaded && isDomainInMaintenance) {
      onMaintenanceDomain()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDomainLoaded, isDomainInMaintenance])

  return (
    <Router history={customHistory}>
      {loggedIn && <LoggedInNavigator loggedIn={loggedIn} currentLocation={currentLocation} />}
      {!loggedIn && <LoggedOutNavigator logging={logging} loggedIn={loggedIn} />}
    </Router>
  )
}

RootNav.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  domain: PropTypes.string.isRequired,
  setDomain: PropTypes.func.isRequired,
  onMaintenanceDomain: PropTypes.func.isRequired,
  hasDomainLoaded: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isInnovator: PropTypes.bool.isRequired,
  isDomainInMaintenance: PropTypes.bool.isRequired,
}

export default RootNav
