const styles = theme => ({
  editorButtons: {
    padding: '1rem 0',
    '& > button:first-child': {
      marginRight: '1rem',
    },
  },
  joinTheTeamContainer: {
    padding: '1rem 0',
  },
  root: {
    padding: theme.spacing(3, 0, 3, 0),
    borderTop: '1px solid #b3b3b3',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '26px',
    fontWeight: 500,
    '& > span': {
      fontSize: '32px',
      marginRight: theme.spacing(1),
    },
  },
})

export default styles
