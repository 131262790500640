const styles = theme => ({
  modalPreview: {
    display: 'flex',
    flexDirection: 'column',
    width: 960,
    borderRadius: 5,
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.secondary.main,
    height: 60,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: '11px 15px',
  },
  modalLogoHeader: {
    position: "absolute",
    height: 60,
    borderRadius: 5
  },
  modalTitleHeader: {
    display: 'block',
    position: 'relative',
    width: '100%',
    color: theme.palette.headerText ? theme.palette.headerText.main : '#FFF',
    fontSize: 24,
    textAlign: 'center',
  },
  modalBody: {
    padding: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
  },
  modalContentBody: {
    color: theme.palette.common.black,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    textAlign: "justify",
    paddingRight: 50,
  },
  modalLogoBody: {
    height: 300,
    borderRadius: 5
  },
  footer: {
    padding: 15,
    height: 45,
    backgroundColor: '#f5f5f5',
    border: '1px solid #ffff',
    borderTopColor: '#e5e5e5',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spanCheckbox: {
    fontSize: '15px',
    display: 'flex',
    marginLeft: '40px',
    marginTop: '10px',
  },
  checkbox: {
    float: 'left',
    marginTop: '-30px',
  },
})

export default styles
