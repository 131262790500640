import createStyles from '@material-ui/core/styles/createStyles'

const styles = theme =>
  createStyles({
    bottom: {
      gridArea: 'bottom',
      ...theme.gridMiddleColumnCenter,
      height: '25rem',
      backgroundColor: '#1c1c1c',
      color: '#fff',
    },
    center: {
      gridArea: 'center',
    },
    topCenter: {
      gridArea: 'center',
      ...theme.gridMiddleColumnCenter,
      gridTemplateColumns: 'auto 960px auto',
      gridTemplateRows: '310px auto',
      backgroundColor: theme.palette.common.black,
      '& > div:first-child': {
        height: '310px',
        gridColumn: 2,
        gridRow: 1,
      },
      '& > div:nth-child(2)': {
        gridColumn: 2,
        gridRow: 2,
        backgroundColor: '#f1f1f1',
      },
    },
    centerDark: {
      backgroundColor: '#1c1c1c',
      color: '#fff',
    },
    exploreBottom: {
      gridArea: 'center',
    },
    fill1: {
      gridColumn: 1,
      gridRow: 2,
      backgroundColor: '#f1f1f1',
    },
    fill2: {
      gridColumn: 3,
      gridRow: 2,
      backgroundColor: '#f1f1f1',
    },
    gridCenter: {
      gridArea: 'center',
    },
    ideasFilter: {
      marginBottom: '1rem',
    },
    mainTitle: {
      margin: '2rem 0',
      fontFamily: '"HelveticaNeueCondensedBold", "Helvetica Neue", sans-serif',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    middle: {
      gridArea: 'middle',
      ...theme.gridMiddleColumnCenter,
      gridTemplateColumns: 'auto 960px auto',
    },
    top: {
      gridArea: 'top',
    },
  })

export default styles
