import React from 'react'
import MuiTab from '@material-ui/core/Tab'
import styled from '@material-ui/core/styles/styled'
import styles from './View.Style'

const Tab = (props) => (
  <MuiTab disableRipple {...props} />
)

export default styled(Tab)(styles)


