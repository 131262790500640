import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Slider from 'components/slider/View'
import styles from './View.Style'

class IdeasSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
    }
    this.setActiveStep = this.setActiveStep.bind(this)
  }

  setActiveStep(newStep) {
    this.setState({ activeStep: newStep })
  }

  render() {
    const {
      classes,
      children,
      onNextClick,
      onPreviousClick,
      arrowsPosition,
      arrowsBgColor,
      arrowsColor,
      arrowsSize,
      stepper,
    } = this.props
    const { activeStep } = this.state
    const childrenCount = React.Children.count(children)
    return (
      <div>
        <Slider
          activeStep={activeStep}
          className={{ stepperRoot: classes.stepperRoot }}
          maxSteps={childrenCount}
          setActiveStep={this.setActiveStep}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          arrowsPosition={arrowsPosition}
          arrowsBgColor={arrowsBgColor}
          arrowsColor={arrowsColor}
          arrowsSize={arrowsSize}
          stepper={stepper}
        >
          {children}
        </Slider>
      </div>
    )
  }
}
IdeasSlider.defaultProps = {
  onNextClick: () => {},
  onPreviousClick: () => {},
  arrowsPosition: 'bottom',
  arrowsBgColor: 'rgba(0, 0 , 0, 0.55)',
  arrowsColor: '#fff',
  arrowsSize: '1.4em',
  stepper: false,
}

IdeasSlider.propTypes = {
  classes: PropTypes.shape({
    stepperRoot: PropTypes.string.isRequired,
  }).isRequired,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
  arrowsPosition: PropTypes.string,
  arrowsBgColor: PropTypes.string,
  arrowsColor: PropTypes.string,
  arrowsSize: PropTypes.string,
  stepper: PropTypes.bool,
}

export default withStyles(styles)(IdeasSlider)
