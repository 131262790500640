import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import logoNextinit from 'img/i360-logo-192.png'
import styles from './View.Style'
import Image from '../../../../components/image/View'
import defaultAvatar from '../../../../img/default-photo.png'

const ChooseList = props => {
  const { classes, info, onClick } = props
  return (
    <div className={classes.content}>
      <div className={classes.choose}>
        <h1 className={classes.title}>
          Selecciona un i360
          <small>Por favor, selecciona el i360 al que quieres acceder</small>
        </h1>

        <ul className={classes.list}>
          {info.map(row => {
            const { avatarUrl, logoUrl, nextinitName, userName, domain } = row
            return (
              <li className={classes.listItem} key={nextinitName}>
                <Button className={classes.identityButton} onClick={() => onClick(domain)}>
                  <Image
                    alt="company logo"
                    className={classes.mediaLogo}
                    src={logoUrl}
                    defaultSrc={logoNextinit}
                  />

                  <div className={classes.mediaBody}>
                    <h2>{nextinitName}</h2>
                    <span>
                      Acceder como <b>{userName}</b>
                    </span>
                  </div>

                  <Image
                    alt="user"
                    className={classes.mediaPhoto}
                    src={avatarUrl}
                    defaultSrc={defaultAvatar}
                  />
                </Button>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

ChooseList.propTypes = {
  info: PropTypes.arrayOf(
    PropTypes.shape({
      avatarUrl: PropTypes.string,
      domain: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
      nextinitName: PropTypes.string,
      userName: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(ChooseList)
