import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import { ButtonLink } from 'components'
import styles from './View.Style'
import IdeaAvatar from '../../../idea_avatar/View'

const PendingInvitations = props => {
  const {
    classes,
    pendingInvitationsUsers,
    onPendingUserClick,
    onPendingUserRemoveClick,
    showDeleteUserIcon,
  } = props
  return (
    <Fragment>
      {pendingInvitationsUsers.length > 0 && (
        <div className={classes.root}>
          <h3 className={classes.titleSection}>
            <span className="icon-time" />
            {i18n.t('IDEA.INVITATION_SENT')}
          </h3>
          <div className={classes.pendingUsersContainer}>
            {pendingInvitationsUsers.map(pendingUser => (
              <ButtonLink
                onLinkClick={() => onPendingUserClick(pendingUser.email)}
                key={pendingUser.email}
              >
                <IdeaAvatar
                  name={pendingUser.fullName}
                  photoSrc={pendingUser.photoSrc}
                  hasDeleteButton={showDeleteUserIcon}
                  onDeleteButtonClick={() => onPendingUserRemoveClick(pendingUser.email)}
                />
              </ButtonLink>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  )
}

PendingInvitations.propTypes = {
  showDeleteUserIcon: PropTypes.bool.isRequired,
  pendingInvitationsUsers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      photoSrc: PropTypes.string,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onPendingUserClick: PropTypes.func.isRequired,
  onPendingUserRemoveClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(PendingInvitations)
