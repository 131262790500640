/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { Button } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import AnalyticsService from 'support/analytics'
import ActiveFilters from './components/active_filters/View'
import styles from './View.Style'
import OrganizationSelect from './components/organization_select'
import StatusSelect from './components/status_select'
import AttributeSelect from './components/attribute_select'
import TypeSelect from './components/type_select'
import ChallengeSelect from './components/challenge_select'

function ExploreFilterFields({
  statusList,
  challenges,
  classes,
  getOrganization,
  getTypes,
  getAttributes,
  organizationsName,
  organizations,
  organizationsApplied,
  isOrganizationsActive,
  isTypesActive,
  isAttributesActive,
  typesName,
  types,
  attributesName,
  attributes,
  challengeDetail,
  handleActiveFilters,
  activeFilters,
  disabled,
}) {
  const [organizationSelected, setOrganizationSelected] = useState([])
  const [selectedChallenge, setSelectedChallenge] = useState('')
  const [status, setStatus] = useState([])
  const [typeSelected, setTypeSelected] = useState([])
  const [attributeSelected, setAttributeSelected] = useState([])
  const [organizationDefaultText, setOrganizationDefaultText] = useState(`${i18n.t(
    'HOME.FILTER.TEXT.SELECT_BY',
  )}${organizationsName || i18n.t('ORGANIZATION.TITLE')}`)
  const [challengeDefaultText, setChallengeDefaultText] = useState(i18n.t('HOME.FILTER.TEXT.SELECT_CHALLENGES'))
  const [statusDefaultText, setStatusDefaultText] = useState(i18n.t('HOME.FILTER.TEXT.SELECT_STATUS'))
  const [typesDefaultText, setTypesDefaultText] = useState(`${i18n.t(
    'HOME.FILTER.TEXT.SELECT_BY',
  )}${typesName || i18n.t('TYPE.TITLE')}`)
  const [attributesDefaultText, setAttributesDefaultText] = useState(`${i18n.t(
    'HOME.FILTER.TEXT.SELECT_BY',
  )}${attributesName || i18n.t('ATTRIBUTE.TITLE')}`)

  useEffect(() => {
    if (organizationsApplied) {
      setChallengeDefaultText(i18n.t('HOME.FILTER.TEXT.SELECT_CHALLENGES'))
      setStatusDefaultText(i18n.t('HOME.FILTER.TEXT.SELECT_STATUS'))
      setTypesDefaultText(`${i18n.t(
        'HOME.FILTER.TEXT.SELECT_BY',
      )}${typesName || i18n.t('TYPE.TITLE')}`)
      setAttributesDefaultText(`${i18n.t(
        'HOME.FILTER.TEXT.SELECT_BY',
      )}${attributesName || i18n.t('ATTRIBUTE.TITLE')}`)
      setOrganizationSelected(organizationsApplied.map(org => org.id))
    }
    setOrganizationDefaultText(`${i18n.t(
      'HOME.FILTER.TEXT.SELECT_ORGANIZATIONS',
    )}${organizationsName || i18n.t('ORGANIZATION.TITLE')}`
    )
  }, [getTypes, getAttributes, organizationsApplied, isOrganizationsActive, isTypesActive, isAttributesActive, organizationsName, typesName, attributesName, organizations])

  const onStatusChange = (event, callback) => {
    const _callback = callback instanceof Function ? callback : () => { }

    setStatus(event.target.value);
    _callback()
  }

  const applyFilter = ({ newStatus, newChallenge, newOrganization, newType, newAttribute }) => {
    let challengeId = newChallenge

    if (challengeDetail) {
      challengeId = challengeDetail
    }

    handleActiveFilters({
      activeFilters: {
        status: statusList.filter(statusItem => newStatus.includes(statusItem.id)),
        challenge: challenges.find(challenge => challenge.id === challengeId) || {},
        organizations: organizations.filter(org => newOrganization.includes(`${org.id}`)),
        types: types.filter(type => newType.includes(`${type.id}`)),
        attributes: attributes.filter(attribute => newAttribute.includes(`${attribute.id}`)),
      },
    })
  }

  const showCleanButton = () => {
    if (status.length
      || selectedChallenge
      || organizationSelected.length
      || typeSelected.length
      || attributeSelected.length
    ) {
      return true
    }

    return false
  }

  const handleFilterClick = () => {
    AnalyticsService.trackEvent({ category: 'swiper', action: 'click', label: 'apply new filter' })
    applyFilter({
      newStatus: status,
      newChallenge: selectedChallenge,
      newOrganization: organizationSelected,
      newType: typeSelected,
      newAttribute: attributeSelected,
    })
  }

  const handleCleanFilters = () => {
    AnalyticsService.trackEvent({ category: 'swiper', action: 'click', label: 'clean all filters' })

    applyFilter({
      newStatus: [],
      newChallenge: '',
      newOrganization: [],
      newType: [],
      newAttribute: [],
    })

    setOrganizationSelected([])
    setSelectedChallenge('')
    setStatus([])
    setTypeSelected([])
    setAttributeSelected([])

  }

  const handlerOrganizationSelected = (value) => {
    setOrganizationSelected(value)
  }

  const handlerAttributeSelected = (value) => {
    setAttributeSelected(value)
  }

  const handlerTypeSelected = (value) => {
    setTypeSelected(value)
  }

  const handlerChallengerSelected = (value) => {
    setSelectedChallenge(value)
  }

  return (
    <div className={classes.container}>
      <div className={classes.flexRowSpaceBetween}>
        {/* STATUS */}
        <StatusSelect
          defaultText={statusDefaultText}
          setValueChange={onStatusChange}
          list={statusList}
          status={status}
          disabled={disabled}
        />

        {/* CHALLENGES */}
        {challenges.length > 0 && (
          <ChallengeSelect
            challenges={challenges}
            challengeDefaultText={challengeDefaultText}
            setSelectedChallenge={handlerChallengerSelected}
            selectedChallenge={selectedChallenge}
            disabled={disabled}
          />
        )}

        {/* ORGANIZATION */}
        <OrganizationSelect
          organizations={organizations}
          isOrganizationsActive={isOrganizationsActive}
          getOrganization={getOrganization}
          setOrganizationSelected={handlerOrganizationSelected}
          organizationDefaultText={organizationDefaultText}
          organizationSelected={organizationSelected}
          disabled={disabled}
        />

        {/* TYPES */}
        <TypeSelect
          types={types}
          isTypesActive={isTypesActive}
          getTypes={getTypes}
          setTypeSelected={handlerTypeSelected}
          typesDefaultText={typesDefaultText}
          typeSelected={typeSelected}
          disabled={disabled}
        />

        {/* ATTRIBUTES */}
        <AttributeSelect
          attributes={attributes}
          isAttributesActive={isAttributesActive}
          getAttributes={getAttributes}
          setAttributeSelected={handlerAttributeSelected}
          disabled={disabled}
          attributesDefaultText={attributesDefaultText}
          attributeSelected={attributeSelected}
        />

        {/* BUTTONS */}
        <Button
          href=""
          color="primary"
          onClick={handleFilterClick}
          variant="contained"
          disabled={disabled}
        >
          {i18n.t('HOME.FILTER.TEXT.BTN')}
        </Button>
        {showCleanButton() && (
          <Button
            href="" color="primary"
            onClick={handleCleanFilters}
            variant="contained"
            disabled={disabled}
          >
            {i18n.t('HOME.FILTER.TEXT.CLEAN')}
          </Button>
        )}
      </div>

      {!disabled && (
        <ActiveFilters activeFilters={activeFilters} showChallenges={challenges.length > 0} />
      )}
    </div>
  )
}

ExploreFilterFields.defaultProps = {
  statusList: [],
  challenges: [],
  organizationsName: '',
  organizations: [],
  organizationsApplied: [],
  typesName: '',
  types: [],
  attributesName: '',
  attributes: [],
  isOrganizationsActive: false,
  isTypesActive: false,
  disabled: false,
  // typesApplied: []
}

ExploreFilterFields.propTypes = {
  statusList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      ideas: PropTypes.array.isRequired,
      mainPhoto: PropTypes.string.isRequired,
      numIdeas: PropTypes.string.isRequired,
      proposerName: PropTypes.string.isRequired,
      proposerPhoto: PropTypes.string.isRequired,
      sponsorName: PropTypes.string.isRequired,
      sponsorPhoto: PropTypes.string.isRequired,
    }),
  ),
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    flexRowSpaceBetween: PropTypes.string.isRequired,
  }).isRequired,
  getOrganization: PropTypes.func.isRequired,
  getTypes: PropTypes.func.isRequired,
  getAttributes: PropTypes.func.isRequired,
  organizationsName: PropTypes.string,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  organizationsApplied: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  isOrganizationsActive: PropTypes.bool,
  isTypesActive: PropTypes.bool,
  isAttributesActive: PropTypes.bool,
  typesName: PropTypes.string,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  attributesName: PropTypes.string,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  disabled: PropTypes.bool,
}

export default withStyles(styles)(ExploreFilterFields)
