export const SEARCH_USER_SPONSOR_STARTED = 'SEARCH_USER_SPONSOR_STARTED'
export const SEARCH_USER_SPONSOR_SUCCESS = 'SEARCH_USER_SPONSOR_SUCCESS'
export const SEARCH_USER_SPONSOR_FAILURE = 'SEARCH_USER_SPONSOR_FAILURE'

export const SEARCH_USER_PROPOSER_STARTED = 'SEARCH_USER_PROPOSER_STARTED'
export const SEARCH_USER_PROPOSER_SUCCESS = 'SEARCH_USER_PROPOSER_SUCCESS'
export const SEARCH_USER_PROPOSER_FAILURE = 'SEARCH_USER_PROPOSER_FAILURE'

export const GET_ROI_INFO_STARTED = 'GET_ROI_INFO_STARTED'
export const GET_ROI_INFO_SUCCESS = 'GET_ROI_INFO_SUCCESS'
export const GET_ROI_INFO_FAILURE = 'GET_ROI_INFO_FAILURE'

export const SET_SPONSOR_SUGGESTION = 'SET_SPONSOR_SUGGESTION'
export const SET_PROPOSER_SUGGESTION = 'SET_PROPOSER_SUGGESTION'

export const SET_EASY_SETTING = 'SET_EASY_SETTING'
export const SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED = 'SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED'
export const SET_ADVANCED_SUPPORT_IDEAS = 'SET_ADVANCED_SUPPORT_IDEAS'
export const SET_ADVANCED_APPROVED_IDEAS = 'SET_ADVANCED_APPROVED_IDEAS'
export const SET_ADVANCED_IMPLEMENTED_IDEAS = 'SET_ADVANCED_IMPLEMENTED_IDEAS'
export const INCREASE_CHALLENGE_STEP = 'INCREASE_CHALLENGE_STEP'
export const DECREASE_CHALLENGE_STEP = 'DECREASE_CHALLENGE_STEP'
export const SET_TITLE_DESCRIPTION = 'SET_TITLE_DESCRIPTION'
export const SET_WHY_IMPORTANT_DESCRIPTION = 'SET_WHY_IMPORTANT_DESCRIPTION'
export const SET_DESCRIPTION = 'SET_DESCRIPTION'
export const SET_GAMING_ACTIVE_TAB = 'SET_GAMING_ACTIVE_TAB'

export const ADD_CHALLENGE_STEPS_IMAGE_STARTED = 'ADD_CHALLENGE_STEPS_IMAGE_STARTED'
export const ADD_CHALLENGE_STEPS_IMAGE_SUCCESS = 'ADD_CHALLENGE_STEPS_IMAGE_SUCCESS'
export const ADD_CHALLENGE_STEPS_IMAGE_FAILURE = 'ADD_CHALLENGE_STEPS_IMAGE_FAILURE'
export const SET_CHALLENGE_MAIN_IMAGE_STARTED = 'SET_CHALLENGE_MAIN_IMAGE_STARTED'
export const SET_CHALLENGE_MAIN_IMAGE_SUCCESS = 'SET_CHALLENGE_MAIN_IMAGE_SUCCESS'
export const SET_CHALLENGE_MAIN_IMAGE_FAILURE = 'SET_CHALLENGE_MAIN_IMAGE_FAILURE'
export const DELETE_CHALLENGE_INFO_IMAGE_STARTED = 'DELETE_CHALLENGE_INFO_IMAGE_STARTED'
export const DELETE_CHALLENGE_INFO_IMAGE_SUCCESS = 'DELETE_CHALLENGE_INFO_IMAGE_SUCCESS'
export const DELETE_CHALLENGE_INFO_IMAGE_FAILURE = 'DELETE_CHALLENGE_INFO_IMAGE_FAILURE'

export const SET_CHALLENGE_EXTERNAL_VIDEO_URL = 'SET_CHALLENGE_EXTERNAL_VIDEO_URL'
export const UPLOAD_CHALLENGE_VIDEO_STARTED = 'UPLOAD_CHALLENGE_VIDEO_STARTED'
export const UPLOAD_CHALLENGE_VIDEO_SUCCESS = 'UPLOAD_CHALLENGE_VIDEO_SUCCESS'
export const UPLOAD_CHALLENGE_VIDEO_FAILURE = 'UPLOAD_CHALLENGE_VIDEO_FAILURE'

export const SET_CHALLENGE_VIDEO = 'SET_CHALLENGE_VIDEO'

export const DELETE_CHALLENGE_VIDEO_STARTED = 'DELETE_CHALLENGE_VIDEO_STARTED'
export const DELETE_CHALLENGE_VIDEO_SUCCESS = 'DELETE_CHALLENGE_VIDEO_SUCCESS'
export const DELETE_CHALLENGE_VIDEO_FAILURE = 'DELETE_CHALLENGE_VIDEO_FAILURE'
export const RESET_VIDEO_CHALLENGE = 'RESET_VIDEO_CHALLENGE'

export const GET_CHALLENGE_DRAFT_STARTED = 'GET_CHALLENGE_DRAFT_STARTED'
export const GET_CHALLENGE_DRAFT_SUCCESS = 'GET_CHALLENGE_DRAFT_SUCCESS'
export const GET_CHALLENGE_DRAFT_FAILURE = 'GET_CHALLENGE_DRAFT_FAILURE'

export const GET_GROUPS_STARTED = 'GET_GROUPS_STARTED'
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS'
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE'

export const GET_CHALLENGE_SELECTED_GROUPS_STARTED = 'GET_CHALLENGE_SELECTED_GROUPS_STARTED'
export const GET_CHALLENGE_SELECTED_GROUPS_SUCCESS = 'GET_CHALLENGE_SELECTED_GROUPS_SUCCESS'
export const GET_CHALLENGE_SELECTED_GROUPS_FAILURE = 'GET_CHALLENGE_SELECTED_GROUPS_FAILURE'

export const PUT_CHALLENGE_DATES_STARTED = 'PUT_CHALLENGE_DATES_STARTED'
export const PUT_CHALLENGE_DATES_SUCCESS = 'PUT_CHALLENGE_DATES_SUCCESS'
export const PUT_CHALLENGE_DATES_FAILURE = 'PUT_CHALLENGE_DATES_FAILURE'

export const GET_CHALLENGE_STARTED = 'GET_CHALLENGE_STARTED'
export const GET_CHALLENGE_SUCCESS = 'GET_CHALLENGE_SUCCESS'
export const GET_CHALLENGE_FAILURE = 'GET_CHALLENGE_FAILURE'

export const SET_PREVIEW_FROM_DATE = 'SET_PREVIEW_FROM_DATE'
export const SET_PREVIEW_TO_DATE = 'SET_PREVIEW_TO_DATE'
export const SET_CHALLENGE_ORGANIZATION = 'SET_CHALLENGE_ORGANIZATION'

export const SET_CHALLENGE_REWARD_DESCRIPTION = 'SET_CHALLENGE_REWARD_DESCRIPTION'
export const SET_CHALLENGE_REWARD_REWARDED = 'SET_CHALLENGE_REWARD_REWARDED'
export const SET_CHALLENGE_REWARD_OTHER = 'SET_CHALLENGE_REWARD_OTHER'
export const ADD_CHALLENGE_STEPS_REWARD_IMAGE_STARTED = 'ADD_CHALLENGE_STEPS_REWARD_IMAGE_STARTED'
export const ADD_CHALLENGE_STEPS_REWARD_IMAGE_SUCCESS = 'ADD_CHALLENGE_STEPS_REWARD_IMAGE_SUCCESS'
export const ADD_CHALLENGE_STEPS_REWARD_IMAGE_FAILURE = 'ADD_CHALLENGE_STEPS_REWARD_IMAGE_FAILURE'
export const DELETE_CHALLENGE_REWARD_IMAGE_STARTED = 'DELETE_CHALLENGE_REWARD_IMAGE_STARTED'
export const DELETE_CHALLENGE_REWARD_IMAGE_SUCCESS = 'DELETE_CHALLENGE_REWARD_IMAGE_SUCCESS'
export const DELETE_CHALLENGE_REWARD_IMAGE_FAILURE = 'DELETE_CHALLENGE_REWARD_IMAGE_FAILURE'
export const ADD_GROUPS_TO_RIGHT = 'ADD_GROUPS_TO_RIGHT'
export const SET_RIGHT_GROUPS = 'SET_RIGHT_GROUPS'

export const PUT_CHALLENGE_INFO_STARTED = 'PUT_CHALLENGE_INFO_STARTED'
export const PUT_CHALLENGE_INFO_SUCCESS = 'PUT_CHALLENGE_INFO_SUCCESS'
export const PUT_CHALLENGE_INFO_FAILURE = 'PUT_CHALLENGE_INFO_FAILURE'

export const PUT_PEOPLE_INFO_STARTED = 'PUT_PEOPLE_INFO_STARTED'
export const PUT_PEOPLE_INFO_SUCCESS = 'PUT_PEOPLE_INFO_SUCCESS'
export const PUT_PEOPLE_INFO_FAILURE = 'PUT_PEOPLE_INFO_FAILURE'

export const PUT_ROI_INFO_STARTED = 'PUT_ROI_INFO_STARTED'
export const PUT_ROI_INFO_SUCCESS = 'PUT_ROI_INFO_SUCCESS'
export const PUT_ROI_INFO_FAILURE = 'PUT_ROI_INFO_FAILURE'

export const PUT_REWARD_INFO_STARTED = 'PUT_REWARD_INFO_STARTED'
export const PUT_REWARD_INFO_SUCCESS = 'PUT_REWARD_INFO_SUCCESS'
export const PUT_REWARD_INFO_FAILURE = 'PUT_REWARD_INFO_FAILURE'

export const DELETE_CHALLENGE_DRAFT_STARTED = 'DELETE_CHALLENGE_DRAFT_STARTED'
export const DELETE_CHALLENGE_DRAFT_SUCCESS = 'DELETE_CHALLENGE_DRAFT_SUCCESS'
export const DELETE_CHALLENGE_DRAFT_FAILURE = 'DELETE_CHALLENGE_DRAFT_FAILURE'

export const POST_LAUNCH_CHALLENGE_STARTED = 'POST_LAUNCH_CHALLENGE_STARTED'
export const POST_LAUNCH_CHALLENGE_SUCCESS = 'POST_LAUNCH_CHALLENGE_SUCCESS'
export const POST_LAUNCH_CHALLENGE_FAILURE = 'POST_LAUNCH_CHALLENGE_FAILURE'

export const PUT_PROPOSE_CHALLENGE_STARTED = 'PUT_PROPOSE_CHALLENGE_STARTED'
export const PUT_PROPOSE_CHALLENGE_SUCCESS = 'PUT_PROPOSE_CHALLENGE_SUCCESS'
export const PUT_PROPOSE_CHALLENGE_FAILURE = 'PUT_PROPOSE_CHALLENGE_FAILURE'

export const SET_CHALLENGE_IMAGE_UPLOAD_ERROR = 'SET_CHALLENGE_IMAGE_UPLOAD_ERROR'

export const RESET_CHALLENGE = 'RESET_CHALLENGE'
