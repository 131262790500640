import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import styles from './View.Style'
import StrategicRow from './components/strategic_row/View'

const StrategicPrioritiesSection = props => {
  const { classes, strategies } = props
  return (
    <Fragment>
      {strategies && strategies.length > 0 && (
        <div className={classes.root}>
          <h2 className={classes.titleSection}>
            <span className="icon-strategic" />
            {i18n.t('IDEA.STRATEGIC')}
          </h2>
          <div className={classes.strategicContainer}>
            {strategies.map(strategy => (
              <StrategicRow
                width={strategy.percentage}
                description={strategy.description}
                name={strategy.name}
                key={strategy.name}
              />
            ))}
          </div>
        </div>
      )}
    </Fragment>
  )
}

StrategicPrioritiesSection.defaultProps = {}

StrategicPrioritiesSection.propTypes = {
  strategies: PropTypes.arrayOf(
    PropTypes.shape({
      percentage: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default withStyles(styles)(StrategicPrioritiesSection)
