import React, { Fragment, Component } from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button, Paper } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import NxtCardListItems from 'components/nxt_card/nxt_card_list_items/View'
import NxtCardCorner from 'components/nxt_card/nxt_card_corner/View'
import NxtCardStatus from 'components/nxt_card/nxt_card_status/View'
import CircleGraph from 'components/circle_graph/View'
import Image from 'components/image/View'
import ButtonLink from 'components/button_link/View'
import i18n from 'support/i18n'
import imgDefault from 'img/i360-logo-192.png'
import defaultPhoto from 'img/default-photo.png'
import styles from './View.Style'

class NxtExpandedCard extends Component {
  constructor(props) {
    super(props)
    this.onMainButtonClick = this.onMainButtonClick.bind(this)
    this.handleGoToButtonClick = this.handleGoToButtonClick.bind(this)
    this.renderListItem = this.renderListItem.bind(this)
    this.renderTextBlock = this.renderTextBlock.bind(this)
    this.renderFullGotoButton = this.renderFullGotoButton.bind(this)
  }

  onMainButtonClick() {
    const { id, onMainButtonClick } = this.props
    onMainButtonClick(id)
  }

  handleGoToButtonClick() {
    const { id, onGoToButtonClick } = this.props
    onGoToButtonClick(id)
  }

  renderListItem() {
    const { investors, pledged } = this.props
    if (investors || pledged) {
      return (
        <NxtCardListItems
          primaryValue={pledged}
          secondaryValue={investors}
          primaryText={i18n.t('PLEDGED')}
          secondaryText={i18n.t('INVESTORS')}
        />
      )
    }
    return null
  }

  renderTextBlock() {
    const { altTextBlock, classes, submittedBy, isAnonymous, singleId } = this.props
    if (submittedBy) {
      return (
        <div>
          <div className={classes.subText}>
            <p className={classes.subTextLeft}>
              {`${i18n.t('SUBMITTED_BY')} 
              ${
                !isAnonymous ? (
                  submittedBy
                ) : (
                  <span className={classes.submitterSpan}>{submittedBy}</span>
                )
              } 
              `}
            </p>
            {altTextBlock && <p className={classes.subTextRight}>{altTextBlock}</p>}
          </div>
          {singleId && (
            <div className={classes.singleId}>
              <strong>Id: {singleId}</strong>
            </div>
          )}
        </div>
      )
    }
    return null
  }

  renderFullGotoButton() {
    const { classes } = this.props
    return (
      <Button
        disableRipple
        className={classes.fullGoToButton}
        onClick={this.handleGoToButtonClick}
      />
    )
  }

  render() {
    const {
      alt,
      buttonText,
      challenged,
      children,
      classes,
      defaultSrc,
      goToButtonText,
      height,
      img,
      onGoToButtonClick,
      sponsored,
      status,
      title,
      theme,
      circleValue,
      circleMaxValue,
      circleColor,
      circleContent,
      showCircle,
      clickable,
      sponsorName,
      sponsorPhoto,
      ideas,
      numIdeas,
      navigateToIdea,
      disableMainButton,
      isPrivate,
      customIdeaTitles,
    } = this.props

    const childrenCount = React.Children.count(children)

    return (
      <div className={classes.container}>
        <Paper
          className={clsx(
            classes.paper,
            theme === 'dark' && classes.paperBlack,
            clickable && classes.paperClickable,
            !disableMainButton ? classes.enableMainButton : classes.disableMainButton,
          )}
          style={{ height }}
        >
          <div className={classes.gridHeader}>
            {clickable && onGoToButtonClick && this.renderFullGotoButton()}
            <div className={classes.relative}>
              {status && <NxtCardStatus status={status} />}
              <Image alt={alt} className={classes.img} src={img} defaultSrc={defaultSrc} />
              {sponsorName && (
                <div className={classes.sponsor}>
                  <Image
                    alt={sponsorName}
                    className={classes.sponsorPhoto}
                    src={sponsorPhoto}
                    defaultSrc={defaultPhoto}
                  />
                  <div className={classes.sponsorName}>
                    <h4>{sponsorName}</h4>
                    <span>{i18n.t('CHALLENGES.DETAIL.SPONSOR')}</span>
                  </div>
                </div>
              )}
            </div>
            <NxtCardCorner challenged={challenged} sponsored={sponsored} />
            <div
              className={clsx(classes.headerBlock, theme === 'dark' && classes.headerBlockBlack)}
            >
              <div className={classes.textBlock}>
                <h2 className={classes.title}>{title}</h2>
              </div>
              <div className={classes.graph}>
                {showCircle && (
                  <CircleGraph
                    value={circleValue}
                    maxValue={circleMaxValue}
                    themeColor={theme}
                    pathColor={circleColor}
                  >
                    {circleContent}
                  </CircleGraph>
                )}
              </div>
            </div>
          </div>
          <div
            className={clsx(
              classes.gridInfo,
              classes.colorLight,
              theme === 'dark' && classes.colorBlack,
            )}
          >
            {clickable && onGoToButtonClick && this.renderFullGotoButton()}
            {this.renderTextBlock()}
          </div>
          {childrenCount > 0 && (
            <div className={classes.gridList}>
              {clickable && onGoToButtonClick && this.renderFullGotoButton()}
              <ul
                className={clsx(
                  classes.list,
                  theme === 'light' && classes.listLight,
                  theme === 'dark' && classes.listDark,
                )}
              >
                {children}
              </ul>
            </div>
          )}
          {!clickable && (
            <div className={classes.gridButton}>
              {!disableMainButton && (
                <Button
                  color="primary"
                  fullWidth
                  onClick={this.onMainButtonClick}
                  variant="contained"
                >
                  {buttonText}
                </Button>
              )}
              {onGoToButtonClick && goToButtonText && (
                <Button
                  color="primary"
                  fullWidth
                  onClick={this.handleGoToButtonClick}
                  variant="contained"
                >
                  {goToButtonText}
                </Button>
              )}
            </div>
          )}
        </Paper>
        {clickable && (
          <div className={classes.gridButton}>
            <div className={classes.bottom}>
              <div className={classes.ideas}>
                {!isPrivate && numIdeas && (
                  <p>
                    <strong>{numIdeas} </strong>
                    <span>
                      {i18n.t('CHALLENGES.DETAIL.IDEAS_PROPOSED', {
                        title: customIdeaTitles.pluralTitle || 'Ideas',
                      })}
                    </span>
                  </p>
                )}
                {!isPrivate && ideas.length > 0 && (
                  <ul className={classes.ideasAvatar}>
                    {ideas.map(idea => (
                      <Fragment key={idea.id}>
                        <li>
                          <ButtonLink onLinkClick={() => navigateToIdea(idea.id)}>
                            <Image
                              defaultSrc={defaultPhoto}
                              src={idea.images.length ? idea.images[0].url : defaultPhoto}
                              alt=""
                            />
                          </ButtonLink>
                        </li>
                      </Fragment>
                    ))}
                  </ul>
                )}
              </div>
              {!disableMainButton && (
                <div className={classes.mainButton}>
                  <Button onClick={this.onMainButtonClick}>{buttonText}</Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

NxtExpandedCard.defaultProps = {
  alt: 'default image',
  buttonText: i18n.t('GO_TO_IDEA'),
  challenged: false,
  altTextBlock: '',
  defaultSrc: imgDefault,
  height: '',
  id: '',
  img: '',
  investors: '',
  goToButtonText: i18n.t('CHALLENGES.GO_TO_CHALLENGE'),
  pledged: '',
  sponsored: false,
  sponsorName: '',
  status: '',
  submittedBy: '',
  theme: 'light',
  title: '',
  onGoToButtonClick: undefined,
  circleMaxValue: '100',
  circleColor: '',
  showCircle: false,
  isAnonymous: false,
  singleId: '',
  clickable: false,
  ideas: [],
  numIdeas: '',
  disableMainButton: false,
  isPrivate: false,
}

NxtExpandedCard.propTypes = {
  alt: PropTypes.string,
  altTextBlock: PropTypes.string,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  challenged: PropTypes.bool,
  defaultSrc: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string,
  img: PropTypes.string,
  investors: PropTypes.string,
  onMainButtonClick: PropTypes.func.isRequired,
  onGoToButtonClick: PropTypes.func,
  goToButtonText: PropTypes.string,
  pledged: PropTypes.string,
  sponsored: PropTypes.bool,
  sponsorName: PropTypes.string,
  status: PropTypes.string,
  submittedBy: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
  title: PropTypes.string,
  circleValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  circleColor: PropTypes.string,
  circleMaxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCircle: PropTypes.bool,
  isAnonymous: PropTypes.bool,
  singleId: PropTypes.string,
  clickable: PropTypes.bool,
  ideas: PropTypes.arrayOf(PropTypes.shape({})),
  numIdeas: PropTypes.string,
  disableMainButton: PropTypes.bool,
  isPrivate: PropTypes.bool,
}

export default withStyles(styles)(NxtExpandedCard)
