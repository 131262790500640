const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    gridArea: 'content',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: '"loginContent"',
    marginLeft:  props => (props.useCustomLogin ? '' : '20rem'),
  },
  form: {
    display: 'flex',
    maxWidth: '31rem',
    width: '100%',
    height: 'auto',
    flexDirection: 'column',
    background: theme.palette.common.white,
    /* borderRadius: props => (props.useCustomLogin ? '0.3rem' : '0.3rem'), */
    borderRadius: '0.3rem',
    color: theme.palette.common.black,
    gridArea: 'loginForm',
    /* padding: props => (props.useCustomLogin ? theme.spacing(2) : 'unset'), */
    padding: theme.spacing(2),
  },
  login: {
    padding: '50px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      gridArea: 'loginContent',
      display: 'grid',
      padding: '0 3rem',
      gridTemplateColumns: '1fr 32rem 1fr',
      gridTemplateRows: '25% 1fr 20%',
      gridTemplateAreas: '". loginTitle ." ". loginForm ."',
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: props =>
      props.useCustomLogin && props.useCustomColor ? props.customColor : theme.palette.primary.main,
    color: props =>
      props.useCustomLogin && props.useCustomTextColor && props.customTextColor
        ? props.customTextColor
        : '',
    '&:hover': {
      backgroundColor: props =>
        props.useCustomLogin && props.useCustomColor ? props.customColor : '',
    },
  },
  socialGoogleButton: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: '#dd4b39',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#dd4b39',
    },
  },
  microsoftContainer: {
    display: 'flex',
    '& > button:first-child': {
      'margin-right': props => (props.isAzureAuthEnabled && props.isAdfsAuthEnabled ? '1rem' : 0),
    },
  },
  socialMicrosoftButton: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: '#395d98',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#395d98',
    },
  },
  terms: {
    textAlign: 'center',
    '& a': {
      color: '#454545',
      textDecoration: 'none',
      opacity: 0.6,
    },
    '& a:hover': {
      opacity: 1,
    }
  },
  title: {
    display: 'flex',
    alignItems: 'flex-end',
    gridArea: 'loginTitle',
    color: "white",
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(1 / 2),
    },
    [theme.breakpoints.up('xs')]: {
      paddingBottom: theme.spacing(2),
    },
    padding: '20px',
    marginBottom: '5px',
    '& > h1': {
      fontFamily: '"Open Sans", open-sans, sans-serif',
      fontSize: '28px',
      fontWeight: '400',
      margin: 0,
      '& small': {
        display: 'block',
        marginTop: '10px',
        fontSize: '16px',
      },
    },
  },
  forgotContainer: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    fontSize: '0.75rem',
  },
  buttonProgress: {
    position: 'absolute',
  },
  activationEmailLink: {
    fontSize: '0.875rem',
    marginLeft: theme.spacing(1 / 4),
  },
  createAccount: {
    padding: theme.spacing(0, 0, 1, 0),
  },
})

export default styles
