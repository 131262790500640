import React from 'react'
import * as PropTypes from 'prop-types'
import NxtResumeCard from 'components/nxt_resume_card/View'
import CreatorsSection from './components/creators_section/View'
import SummaryStepper from './components/summary_stepper/View'
import AskInvestmentsSection from './components/ask_investments_section/View'
import SummaryData from './components/summary_data/View'
import InvestSection from './components/invest_section/View'

const SummarySection = (props) => {
  const {
    title,
    description,
    images,
    submitter,
    promoter,
    onUserNameClick,
    activeStep,
    onSummaryStepperHelpClick,
    currentStatus,
    currentStatusColor,
    createdDate,
    endDate,
    singleId,
    totalInvestors,
    pledged,
    goal,
    visits,
    currencyIcon,
    onAskInvestmentClick,
    maxInvestment,
    minInvestment,
    userBalance,
    onInvestButtonClick,
    isInvestmentInProcess,
    videoSrc,
    videoType,
    hasChallengeAssociated,
    showInvestmentDetail,
    subTitle,
    canInvest,
    canAskForInvestments,
    investHelpText,
    inProcessButtonText,
    buttonText,
    shouldInputBeDirty,
    showRoundStepper,
    isAnonymous,
    customIdeaTitle
  } = props

  return (
    <NxtResumeCard
      title={title}
      subTitle={subTitle}
      description={description}
      images={images}
      videoSrc={videoSrc}
      videoType={videoType}
      componentTheme="light"
      showLeftCorner={hasChallengeAssociated}
      sliderOptions={{
        arrowsPosition: "center-inside",
        stepper: false,
        mode: 'light',
      }}
    >
      <CreatorsSection
        isAnonymous={isAnonymous}
        submitter={submitter}
        promoter={promoter}
        onUserNameClick={onUserNameClick}
      />
      {showRoundStepper && (
        <SummaryStepper activeStep={activeStep} onHelpClick={onSummaryStepperHelpClick} />
      )}
      {showInvestmentDetail && (
        <SummaryData
          createdDate={createdDate}
          endDate={endDate}
          ideaId={singleId}
          investors={totalInvestors}
          pledged={pledged}
          goal={goal}
          visits={visits}
          currentStatus={currentStatus}
          progressColor={currentStatusColor}
          customIdeaTitle={customIdeaTitle}
        />
      )}
      {canInvest && (
        <InvestSection
          minInvestment={minInvestment}
          maxInvestment={maxInvestment}
          currencyIcon={currencyIcon}
          userBalance={userBalance}
          onInvestButtonClick={onInvestButtonClick}
          isInvestmentInProcess={isInvestmentInProcess}
          helpText={investHelpText}
          inProcessButtonText={inProcessButtonText}
          buttonText={buttonText}
          shouldInputBeDirty={shouldInputBeDirty}
          customIdeaTitle={customIdeaTitle}
        />
      )}
      {canAskForInvestments && (
        <AskInvestmentsSection onAskInvestmentClick={onAskInvestmentClick} />
      )}
    </NxtResumeCard>
  )
}

SummarySection.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string,
      src: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeStep: PropTypes.number.isRequired,
  onSummaryStepperHelpClick: PropTypes.func.isRequired,
  submitter: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    photo: PropTypes.string,
    email: PropTypes.string.isRequired,
  }).isRequired,
  promoter: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    photo: PropTypes.string,
    email: PropTypes.string.isRequired,
  }).isRequired,
  onUserNameClick: PropTypes.func.isRequired,
  currentStatus: PropTypes.string.isRequired,
  currentStatusColor: PropTypes.string.isRequired,
  createdDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  singleId: PropTypes.string.isRequired,
  totalInvestors: PropTypes.string.isRequired,
  pledged: PropTypes.string.isRequired,
  goal: PropTypes.string.isRequired,
  visits: PropTypes.string.isRequired,
  onInvestButtonClick: PropTypes.func.isRequired,
  maxInvestment: PropTypes.number.isRequired,
  minInvestment: PropTypes.number.isRequired,
  userBalance: PropTypes.number.isRequired,
  currencyIcon: PropTypes.string.isRequired,
  investHelpText: PropTypes.string.isRequired,
  inProcessButtonText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  videoType: PropTypes.string.isRequired,
  isInvestmentInProcess: PropTypes.bool.isRequired,
  onAskInvestmentClick: PropTypes.func.isRequired,
  hasChallengeAssociated: PropTypes.bool.isRequired,
  showInvestmentDetail: PropTypes.bool.isRequired,
  showRoundStepper: PropTypes.bool.isRequired,
  canInvest: PropTypes.bool.isRequired,
  canAskForInvestments: PropTypes.bool.isRequired,
  shouldInputBeDirty: PropTypes.bool.isRequired,
  customIdeaTitle: PropTypes.string.isRequired
}

export default SummarySection
