import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import NxtLogisticCurrency from 'components/nxt_logistic_currency'
import styles from './View.Style'

const BenefitsLogisticRow = props => {
  const {
    classes,
    titleIcon,
    titleText,
    economicBenefits,
    economicBenefitsLabel,
    otherBenefits,
    otherBenefitsLabel,
  } = props

  return (
    <div className={classes.root}>
      <span className={titleIcon} />
      <div className={classes.valuesContainer}>
        <div className={classes.titleLogistic}>{titleText}</div>
        <div className={classes.lineWrapper}>
          {economicBenefits.length > 0 && (
            <Paper className={classes.valuesLogistic}>
              <div className={classes.economicBenefits}>
                <span>{economicBenefitsLabel}</span>
                <span>
                  <NxtLogisticCurrency>{economicBenefits}</NxtLogisticCurrency>
                </span>
              </div>
            </Paper>
          )}
          {otherBenefits && (
            <div className={classes.otherBenefits}>
              <span>{otherBenefitsLabel}</span>
              <span>{otherBenefits}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

BenefitsLogisticRow.defaultProps = {
  classes: {
    root: '',
    valuesContainer: '',
    titleLogistic: '',
    lineWrapper: '',
    valuesLogistic: '',
    economicBenefits: '',
    otherBenefits: '',
  },
  titleIcon: '',
  titleText: '',
  economicBenefits: '',
  economicBenefitsLabel: i18n.t('LOGISTIC.REVENUE'),
  otherBenefits: '',
  otherBenefitsLabel: i18n.t('LOGISTIC.OTHER'),
}

BenefitsLogisticRow.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    valuesContainer: PropTypes.string,
    titleLogistic: PropTypes.string,
    lineWrapper: PropTypes.string,
    valuesLogistic: PropTypes.string,
    economicBenefits: PropTypes.string,
    otherBenefits: PropTypes.string,
  }),
  titleIcon: PropTypes.string,
  titleText: PropTypes.string,
  economicBenefits: PropTypes.string,
  economicBenefitsLabel: PropTypes.string,
  otherBenefits: PropTypes.string,
  otherBenefitsLabel: PropTypes.string,
}

export default withStyles(styles)(BenefitsLogisticRow)
