import { connect } from 'react-redux'
import AnalyticsService from '../../../analytics'
import { setCurrentDomain } from '../../../../actions/sync/domain'
import View from './View'
import * as types from "../../../../actions/sync/auth/Constants";
import {showPopupErrorSnackbar} from "../../../popup_dialogs";
import i18n from "../../../i18n";
import {logoutService} from "../../../../services/auth";

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn,
    logging: state.auth.logging,
    domain: state.data.domain.currentDomain,
    hasDomainLoaded: state.data.domain.dataLoaded,
    isAdmin: state.data.user.admin,
    isInnovator: state.data.user.isInnovator,
    isDomainInMaintenance: state.data.maintenance.isDomainInMaintenance,
  }
}

const mapDispatchToProps = dispatch => {
  function onLogoutSuccess() {
    return {
      type: types.PASSWORD_LOGOUT_SUCCESS,
    }
  }

  function onLogoutFailure() {
    showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.LOGOUT_FAILURE'), dispatch)
  }

  return {
    setDomain: domain => {
      dispatch(setCurrentDomain(domain))
    },
    onRouteChange: page => {
      AnalyticsService.trackPageView(page)
    },
    onMaintenanceDomain: () => {
      dispatch(logoutService(onLogoutSuccess, onLogoutFailure))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
