import { connect } from 'react-redux'
import { getPendingIdeas } from 'services/ideas'
import Navigation from 'support/navigation'
import View from './View'

const mapStateToProps = (state) => {
  return {
    ideas: state.data.idea.data,
    customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPendingIdeas: () => {
      dispatch(getPendingIdeas())
    },
    navigateToIdea: id => {
      dispatch(Navigation.navigateToIdea(id))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
