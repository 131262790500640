export const GET_CONFIRMATION_CHALLENGE_VIDEO_STARTED = 'GET_CONFIRMATION_CHALLENGE_VIDEO_STARTED'
export const GET_CONFIRMATION_CHALLENGE_VIDEO_SUCCESS = 'GET_CONFIRMATION_CHALLENGE_VIDEO_SUCCESS'
export const GET_CONFIRMATION_CHALLENGE_VIDEO_FAILURE = 'GET_CONFIRMATION_CHALLENGE_VIDEO_FAILURE'

export const GET_CONFIRMATION_IDEA_VIDEO_STARTED = 'GET_CONFIRMATION_IDEA_VIDEO_STARTED'
export const GET_CONFIRMATION_IDEA_VIDEO_SUCCESS = 'GET_CONFIRMATION_IDEA_VIDEO_SUCCESS'
export const GET_CONFIRMATION_IDEA_VIDEO_FAILURE = 'GET_CONFIRMATION_IDEA_VIDEO_FAILURE'

export const POST_UPLOAD_IDEA_VIDEO_STARTED = 'POST_UPLOAD_IDEA_VIDEO_STARTED'
export const POST_UPLOAD_IDEA_VIDEO_SUCCESS = 'POST_UPLOAD_IDEA_VIDEO_SUCCESS'
export const POST_UPLOAD_IDEA_VIDEO_FAILURE = 'POST_UPLOAD_IDEA_VIDEO_FAILURE'

export const POST_STORAGE_VIDEO_UPLOAD_URL_STARTED = 'POST_STORAGE_VIDEO_UPLOAD_URL_STARTED'
export const POST_STORAGE_VIDEO_UPLOAD_URL_SUCCESS = 'POST_STORAGE_VIDEO_UPLOAD_URL_SUCCESS'
export const POST_STORAGE_VIDEO_UPLOAD_URL_FAILURE = 'POST_STORAGE_VIDEO_UPLOAD_URL_FAILURE'

export const POST_UPLOAD_IDEA_STORAGE_VIDEO_STARTED = 'POST_UPLOAD_IDEA_STORAGE_VIDEO_STARTED'
export const POST_UPLOAD_IDEA_STORAGE_VIDEO_SUCCESS = 'POST_UPLOAD_IDEA_STORAGE_VIDEO_SUCCESS'
export const POST_UPLOAD_IDEA_STORAGE_VIDEO_FAILURE = 'POST_UPLOAD_IDEA_STORAGE_VIDEO_FAILURE'

export const RESET_IDEA_STORAGE_VIDEO = 'RESET_IDEA_STORAGE_VIDEO'

export const POST_UPLOAD_CHALLENGE_VIDEO_STARTED = 'POST_UPLOAD_CHALLENGE_VIDEO_STARTED'
export const POST_UPLOAD_CHALLENGE_VIDEO_SUCCESS = 'POST_UPLOAD_CHALLENGE_VIDEO_SUCCESS'
export const POST_UPLOAD_CHALLENGE_VIDEO_FAILURE = 'POST_UPLOAD_CHALLENGE_VIDEO_FAILURE'

export const DELETE_IDEA_VIDEO_STARTED = 'DELETE_IDEA_VIDEO_STARTED'
export const DELETE_IDEA_VIDEO_SUCCESS = 'DELETE_IDEA_VIDEO_SUCCESS'
export const DELETE_IDEA_VIDEO_FAILURE = 'DELETE_IDEA_VIDEO_FAILURE'

export const DELETE_CHALLENGE_VIDEO_STARTED = 'DELETE_CHALLENGE_VIDEO_STARTED'
export const DELETE_CHALLENGE_VIDEO_SUCCESS = 'DELETE_CHALLENGE_VIDEO_SUCCESS'
export const DELETE_CHALLENGE_VIDEO_FAILURE = 'DELETE_CHALLENGE_VIDEO_FAILURE'

export const POST_CHALLENGE_STORAGE_VIDEO_UPLOAD_URL_STARTED = 'POST_CHALLENGE_STORAGE_VIDEO_UPLOAD_URL_STARTED'
export const POST_CHALLENGE_STORAGE_VIDEO_UPLOAD_URL_SUCCESS = 'POST_CHALLENGE_STORAGE_VIDEO_UPLOAD_URL_SUCCESS'
export const POST_CHALLENGE_STORAGE_VIDEO_UPLOAD_URL_FAILURE = 'POST_CHALLENGE_STORAGE_VIDEO_UPLOAD_URL_FAILURE'

export const POST_UPLOAD_CHALLENGE_STORAGE_VIDEO_STARTED = 'POST_UPLOAD_CHALLENGE_STORAGE_VIDEO_STARTED'
export const POST_UPLOAD_CHALLENGE_STORAGE_VIDEO_SUCCESS = 'POST_UPLOAD_CHALLENGE_STORAGE_VIDEO_SUCCESS'
export const POST_UPLOAD_CHALLENGE_STORAGE_VIDEO_FAILURE = 'POST_UPLOAD_CHALLENGE_STORAGE_VIDEO_FAILURE'

export const RESET_CHALLENGE_STORAGE_VIDEO = 'RESET_CHALLENGE_STORAGE_VIDEO'

export const SET_EXTERNAL_VIDEO = 'SET_EXTERNAL_VIDEO'

export const GET_IDEA_VIDEO_TYPE_STARTED = 'GET_IDEA_VIDEO_TYPE_STARTED'
export const GET_IDEA_VIDEO_TYPE_SUCCESS = 'GET_IDEA_VIDEO_TYPE_SUCCESS'
export const GET_IDEA_VIDEO_TYPE_FAILURE = 'GET_IDEA_VIDEO_TYPE_FAILURE'

export const GET_CHALLENGE_VIDEO_TYPE_STARTED = 'GET_CHALLENGE_VIDEO_TYPE_STARTED'
export const GET_CHALLENGE_VIDEO_TYPE_SUCCESS = 'GET_CHALLENGE_VIDEO_TYPE_SUCCESS'
export const GET_CHALLENGE_VIDEO_TYPE_FAILURE = 'GET_CHALLENGE_VIDEO_TYPE_FAILURE'

export const GET_CHALLENGE_DETAIL_VIDEO_TYPE_STARTED = 'GET_CHALLENGE_DETAIL_VIDEO_TYPE_STARTED'
export const GET_CHALLENGE_DETAIL_VIDEO_TYPE_SUCCESS = 'GET_CHALLENGE_DETAIL_VIDEO_TYPE_SUCCESS'
export const GET_CHALLENGE_DETAIL_VIDEO_TYPE_FAILURE = 'GET_CHALLENGE_DETAIL_VIDEO_TYPE_FAILURE'

