import { connect } from 'react-redux'
import { postAcceptLegal, postDeclineLegal } from 'services/user'
import { getDomainLoginInfo } from 'services/domain'
import View from './View'
import * as types from "../../actions/sync/auth/Constants";
import {showPopupErrorSnackbar} from "../../support/popup_dialogs";
import {logoutService} from "../../services/auth";
import i18n from "../../support/i18n";

const mapStateToProps = state => ({
  gdpr: state.data.domain.gdpr,
  customBgImg: state.data.domain.loginInfo.customBgImg,
  customColor: state.data.domain.loginInfo.customColor,
  customImg: state.data.domain.loginInfo.customImg,
  repeatBackgroundImg: state.data.domain.loginInfo.repeatBackgroundImg,
  useCreateAccount: state.data.domain.loginInfo.useCreateAccount,
  useCustomBgImg: state.data.domain.loginInfo.useCustomBgImg,
  useCustomColor: state.data.domain.loginInfo.useCustomColor,
  useCustomImg: state.data.domain.loginInfo.useCustomImg,
  domain: state.data.domain.currentDomain,
  legalAcceptTitle: state.data.domain.domainConfiguration.legalAcceptTitle,
  legalAcceptHTML: state.data.domain.domainConfiguration.legalAcceptHTML,
})

const mapDispatchToProps = dispatch => {
  return {
    postAcceptLegal: () => dispatch(postAcceptLegal()),
    postDeclineLegal: () => {
      const onSuccess = () => {
        function onLogoutSuccess() {
          return {
            type: types.PASSWORD_LOGOUT_SUCCESS,
          }
        }

        function onLogoutFailure() {
          showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.LOGOUT_FAILURE'), dispatch)
        }
        dispatch(logoutService(onLogoutSuccess, onLogoutFailure))
      }
      dispatch(postDeclineLegal(onSuccess))
    },
    getDomainLoginInfo: domain => dispatch(getDomainLoginInfo(domain)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
