const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0),
    borderTop: '1px solid #b3b3b3',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '26px',
    fontWeight: 500,
    '& > span': {
      fontSize: '32px',
      marginRight: theme.spacing(1),
    },
  },
  strategicContainer: {
    position: 'relative',
    '& > div': {
      margin: theme.spacing(2, 0),
      height: '31px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderRadius: theme.spacing(0, 1.875, 1.875, 0),
      minWidth: '3%',
    },
    '& > div:last-child': {
      marginBottom: 0,
    },
  },
  percentageContainer: {
    backgroundColor: 'black',
    color: theme.palette.common.white,
    height: '100%',
    width: '31px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
  },
})

export default styles
