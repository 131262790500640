import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { Checkbox, Input, ListItemText, MenuItem, Select, FormControl } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const SelectWithChecks = props => {
  const {
    classes,
    checkFunction,
    defaultText,
    onValueChange,
    list,
    multiple,
    name,
    renderValue,
    value,
    disabled,
    width,
    onOpenHandler,
  } = props
  return (
    <Fragment>
      <FormControl disabled={disabled} style={{ width }}>
        <Select
          className={classes.formField}
          displayEmpty
          input={<Input />}
          multiple={multiple}
          onChange={onValueChange}
          renderValue={renderValue}
          value={value}
          inputProps={{
            name,
          }}
          onOpen={onOpenHandler}
        >
          <MenuItem disabled value="">
            {defaultText}
          </MenuItem>
          {list.map(element => {
            const checked = checkFunction(value, element)
            return (
              <MenuItem key={element.name} value={element.id || element.name}>
                <Checkbox checked={checked} />
                <ListItemText
                  primary={i18n.t(`HOME.FILTER.OPTIONS.${element.name}`, {
                    defaultValue: element.name,
                  })}
                />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Fragment>
  )
}

SelectWithChecks.defaultProps = {
  defaultText: 'Select',
  multiple: false,
  disabled: false,
  width: 224,
  onOpenHandler: () => { }
}

SelectWithChecks.propTypes = {
  /**
   * CSS classes.
   */
  classes: PropTypes.shape({
    formField: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Default text showed when there is no choice selected.
   */
  defaultText: PropTypes.string,
  /**
   * Function to handle the select change.
   */
  onValueChange: PropTypes.func.isRequired,
  /**
   * Data list to iterate.
   */
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /**
   * If the select accept multiple choice or not.
   * Default: False.
   */
  multiple: PropTypes.bool,
  /**
   * Name to be added to the onChange event. You can access it by `event.target.name`.
   */
  name: PropTypes.string.isRequired,
  /**
   * Function to evaluate when a option is checked.
   */
  checkFunction: PropTypes.func.isRequired,
  /**
   * Function to tell the select what to show when a choice is selected.
   */
  renderValue: PropTypes.func.isRequired,
  /**
   * Value from the parent's state.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Disable the select input.
   */
  disabled: PropTypes.bool,
  width: PropTypes.number,
  onOpenHandler: PropTypes.func
}

export default withStyles(styles)(SelectWithChecks)
