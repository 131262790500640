import backGroundImage from 'img/bg-user-dashboard-balance2x.png'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  fullName: {
    fontSize: '1.625rem',
    fontWeight: 500,
  },
  secondLineContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > span:first-child': {
      color: '#717171',
      fontSize: '1rem',
    },
  },
  detailSection: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    '& > img:first-child': {
      height: '100px',
      width: '100px',
      borderRadius: '100%',
      marginRight: theme.spacing(1),
    },
  },
  fundsDetail: {
    background: `url(${backGroundImage})`,
    backgroundSize: '18px 4px',
    width: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  fundDetailBalance: {
    flex: 1,
    margin: theme.spacing(0, 1),
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px dotted #666',
    justifyContent: 'space-between',
    height: '66px',
    '& > span:first-child': {
      color: '#666',
      fontSize: '1rem',
    },
    '& > span:last-child': {
      color: theme.palette.primary.main,
      fontSize: '2.5rem',
    },
  },
  fundDetail: {
    flex: 1,
    margin: theme.spacing(0, 1),
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px dotted #666',
    justifyContent: 'space-between',
    minHeight: '66px',
    '& > div': {
      color: '#666',
      fontSize: '1rem',
      display: 'flex',
      alignItems: 'center',
    },
    '& > div > span:first-child': {
      marginRight: theme.spacing(1 / 2),
      fontSize: '1.25rem',
    },
    '& > span:last-child': {
      color: '#666',
      fontSize: '1.75rem',
    },
  },
})

export default styles
