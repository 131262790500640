import { generateRequestCsrf } from 'support/utils/auth'
import { stringToTimestamp } from 'support/utils/dates'
import { isYoutubeUrl, transformToEmbedUrl } from 'support/utils/file'
import { parseIdeasData } from '../ideas/ResponseUtils'

export const parseImages = array => {
  return array.map(image => {
    return {
      src: image.url,
    }
  })
}

export const parseChallengeObject = (challenge, parseImage, state) => {
  let ends = null
  let starts = null
  let video = ''

  if (challenge.ends) {
    if (typeof challenge.ends === 'string') ends = stringToTimestamp(challenge.ends)
    else ends = challenge.ends.timestamp
  }

  if (challenge.starts) {
    if (typeof challenge.starts === 'string') starts = stringToTimestamp(challenge.starts)
    else starts = challenge.starts.timestamp
  }

  if (challenge.videoURL) {
    if (isYoutubeUrl(challenge.videoURL)) {
      video = transformToEmbedUrl(challenge.videoURL)
    } else {
      video = `${challenge.videoURL}?_nxt_csrf=${generateRequestCsrf(state.auth.csrf)}`
    }
  }

  return {
    approvedMult: challenge.approveMult || 0,
    description: challenge.desc,
    desc: challenge.descWithoutTags,
    ends,
    goalMult: challenge.goalMult || 0,
    id: challenge.id,
    ideas: parseIdeasData(challenge.ideas),
    images: parseImage ? parseImages(challenge.images) : challenge.images || [],
    implementedMult: challenge.implementMult || 0,
    importance: challenge.importance,
    mainPhoto: challenge.mainPhoto,
    video,
    name: challenge.title,
    numIdeas: `${challenge.numIdeas || '0'}`,
    proposerName: challenge.proposerName || '',
    proposerEmail: challenge.proposerEmail || '',
    proposerPhoto: challenge.proposerPhoto || '',
    rewardText: challenge.rewardDesc || '',
    rewardOther: challenge.rewardOther || '',
    rewardImage: challenge.rewardImage
      ? {
          src: challenge.rewardImage.url || '',
          key: challenge.rewardImage.key || '',
          name: challenge.rewardImage.name || '',
        }
      : {},
    rewardFor: challenge.rewardFor || '',
    sponsorName: challenge.sponsorName || '',
    sponsorPhoto: challenge.sponsorPhoto || '',
    starts,
    supportedMult: challenge.supportMult || 0,
    visits: challenge.visits,
    status: challenge.status || '',
    organisation: challenge.organization || '',
  }
}

export const parseChallenges = (challenges, state) => {
  if (!challenges) return []
  return challenges.map((object) => parseChallengeObject(object, false, state))
}

export const transformGetChallengeService = (jsonResponse, state) => ({
  challenges: parseChallenges(jsonResponse.data, state),
  items: jsonResponse.items,
})

export const transformGetChallengeByIdService = (jsonResponse, state) => {
  return {
    challenge: parseChallengeObject(jsonResponse, true, state),
  }
}

export const transformSearchChallengeService = (jsonResponse, state) => ({
  challenges: parseChallenges(jsonResponse.items, state),
  items: jsonResponse.size,
})