import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { FormControlLabel, Paper, withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import RadioSelector from 'components/radio_selector/View'
import CheckboxSelector from 'components/checkbox_selector/View'
import NxtCheckbox from 'components/nxt_checkbox/View'
import GoalsSlider from 'pages/idea/form/components/goals_slider/View'
import SimpleDivider from 'components/simple_divider/View'
import { checkYoutubeUrl } from 'components/video_form/View.Utils'
import NxtFormHelper from 'components/nxt_form_helper/View'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import DocumentSection from 'components/document_section/View'
import MainFormSection from './components/main_form_section/View'
import LogisticData from './components/logistic_data_section/View'
import ButtonsFooter from './components/buttons_footer/View'
import style from './View.Style'

class IdeaForm extends Component {
  constructor(props) {
    super(props)
    const { match } = this.props
    this.state = {
      id: match && match.params && match.params.id ? match.params.id : undefined,
      // main data
      title: props.title,
      description: props.description,
      video: props.externalVideo ? props.externalVideo : props.fileVideoUrl,
      endDate: props.endDate,
      isPrivate: props.isPrivate,
      // challenges
      challenge:
        match && match.params && match.params.challengeId
          ? match.params.challengeId
          : props.challenge,
      // organizations
      organization: props.organization.id,
      organizationsOptions: props.challengeOrganization
        ? props.organizations.filter(org => org.id === props.challengeOrganization.toString())
        : props.organizations,
      // types
      type: props.type,
      // attributes
      attribute: props.attribute,
      // types
      goalValues: Object.keys(props.goalValues).reduce(
        (acc, item) => ({ ...acc, [item]: props.goalValues[item].percentage }),
        {},
      ),
      // anonymous
      isAnonymous: props.isAnonymousIdeasMandatory || props.isAnonymous,
      // logistic data
      implementEstimatedHours: props.implementEstimatedHours,
      implementEstimatedQuantity: props.implementEstimatedQuantity,
      implementEstimatedTime: props.implementEstimatedTime,
      implementEstimatedTimeType: props.implementEstimatedTimeType,
      pilotEstimatedHours: props.pilotEstimatedHours,
      pilotEstimatedQuantity: props.pilotEstimatedQuantity,
      pilotEstimatedTime: props.pilotEstimatedTime,
      pilotEstimatedTimeType: props.pilotEstimatedTimeType,
      economicBenefits: props.economicBenefits,
      otherBenefits: props.otherBenefits,
      logisticDataErrors: {
        implementEstimatedHours: false,
        implementEstimatedQuantity: false,
        implementEstimatedTime: false,
        pilotEstimatedHours: false,
        pilotEstimatedQuantity: false,
        pilotEstimatedTime: false,
        economicBenefits: false,
      },
      documents: props.documents,
    }
    this.typesRef = React.createRef()
    this.attributesRef = React.createRef()
    this.challengeRef = React.createRef()
    this.organizationsRef = React.createRef()
    this.descriptionRef = React.createRef()
    this.titleRef = React.createRef()
    this.imageRef = React.createRef()
    this.externalVideoRef = React.createRef()
    this.logisticDataRef = React.createRef()

    this.handleSubmitDraft = this.handleSubmitDraft.bind(this)
    this.handleSubmitIdea = this.handleSubmitIdea.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSaveIdea = this.handleSaveIdea.bind(this)
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleStateChange = this.handleStateChange.bind(this)
    this.handleChallengeChange = this.handleChallengeChange.bind(this)
    this.handleIsAnonymous = this.handleIsAnonymous.bind(this)
    this.isLogisticDataVisible = this.isLogisticDataVisible.bind(this)
    this.handleDeleteImage = this.handleDeleteImage.bind(this)
    this.handleTypeChange = this.handleTypeChange(this)
    this.handleAttributeChange = this.handleAttributeChange(this)
    this.handleDocumentUpload = this.handleDocumentUpload.bind(this)
    this.handleDeleteDocument = this.handleDeleteDocument.bind(this)
    this.isNewFile = this.isNewFile.bind(this)
  }

  componentDidMount() {
    const {
      getGoals,
      getChallenges,
      getOrganizations,
      getTypes,
      getAttributes,
      getIdea,
      resetForm,
      getChallengeOrganization,
    } = this.props

    const { id, challenge } = this.state

    if (id) {
      getIdea(id)
      if (challenge) {
        getChallengeOrganization(challenge)
      }
    } else {
      resetForm()
    }

    getGoals()
    getChallenges()
    getOrganizations()
    getTypes()
    getAttributes()
  }

  componentDidUpdate(prevProps) {
    const {
      isTitleAvailable,
      title,
      description,
      images,
      externalVideo,
      endDate,
      challenge,
      challengeOrganization,
      organization,
      organizations,
      type,
      attribute,
      goalValues,
      implementEstimatedHours,
      implementEstimatedQuantity,
      implementEstimatedTime,
      implementEstimatedTimeType,
      pilotEstimatedHours,
      pilotEstimatedQuantity,
      pilotEstimatedTime,
      pilotEstimatedTimeType,
      economicBenefits,
      otherBenefits,
      isAnonymous,
      isPrivate,
      fileVideoUrl,
      onVideoEncodingError,
      hasVideoError,
      fileToUpload,
      onUploadFile,
      documents,
      fileVideoSrc,
      fileVideoType,
      getIdeaVideoType,
      getChallengeOrganization,
    } = this.props

    let newState = {}

    if (fileVideoSrc && !fileVideoType) {
      getIdeaVideoType(fileVideoSrc)
    }

    if (prevProps.documents.length !== documents.length) {
      newState = { ...newState, documents }
    }

    if (fileToUpload.uploadUrl && this.isNewFile(prevProps.fileToUpload.file)) {
      onUploadFile(fileToUpload)
    }

    if (prevProps.hasVideoError !== hasVideoError && hasVideoError) {
      onVideoEncodingError()
    }

    if (prevProps.isPrivate !== isPrivate) {
      newState = { ...newState, isPrivate }
    }

    if (prevProps.isTitleAvailable !== isTitleAvailable) {
      newState = { ...newState, titleError: isTitleAvailable ? '' : i18n.t('IDEA.DUPLICATE_ERROR') }
    }

    if (prevProps.title !== title) {
      newState = { ...newState, title }
    }

    if (prevProps.description !== description) {
      newState = { ...newState, description }
    }

    if (prevProps.images !== images) {
      newState = { ...newState, images }
    }

    if (prevProps.externalVideo !== externalVideo) {
      newState = { ...newState, video: externalVideo }
    }

    if (prevProps.fileVideoUrl !== fileVideoUrl && !externalVideo) {
      newState = { ...newState, video: fileVideoUrl }
    }

    if (prevProps.endDate !== endDate) {
      newState = { ...newState, endDate }
    }

    if (prevProps.challenge !== challenge) {
      newState = { ...newState, challenge }
      if (challenge) {
        getChallengeOrganization(challenge)
      }
    }

    if (prevProps.organization.id !== organization.id) {
      newState = { ...newState, organization: organization.id }
    }

    if (prevProps.goalValues !== goalValues) {
      newState = {
        ...newState,
        goalValues: Object.keys(goalValues).reduce(
          (acc, item) => ({ ...acc, [item]: goalValues[item].percentage }),
          {},
        ),
      }
    }

    if (prevProps.implementEstimatedHours !== implementEstimatedHours) {
      newState = { ...newState, implementEstimatedHours }
    }

    if (prevProps.implementEstimatedQuantity !== implementEstimatedQuantity) {
      newState = { ...newState, implementEstimatedQuantity }
    }

    if (prevProps.implementEstimatedTime !== implementEstimatedTime) {
      newState = { ...newState, implementEstimatedTime }
    }

    if (prevProps.implementEstimatedTimeType !== implementEstimatedTimeType) {
      newState = { ...newState, implementEstimatedTimeType }
    }

    if (prevProps.pilotEstimatedHours !== pilotEstimatedHours) {
      newState = { ...newState, pilotEstimatedHours }
    }

    if (prevProps.pilotEstimatedQuantity !== pilotEstimatedQuantity) {
      newState = { ...newState, pilotEstimatedQuantity }
    }

    if (prevProps.pilotEstimatedTime !== pilotEstimatedTime) {
      newState = { ...newState, pilotEstimatedTime }
    }

    if (prevProps.pilotEstimatedTimeType !== pilotEstimatedTimeType) {
      newState = { ...newState, pilotEstimatedTimeType }
    }

    if (prevProps.economicBenefits !== economicBenefits) {
      newState = { ...newState, economicBenefits }
    }

    if (prevProps.otherBenefits !== otherBenefits) {
      newState = { ...newState, otherBenefits }
    }

    if (prevProps.isAnonymous !== isAnonymous) {
      newState = { ...newState, isAnonymous }
    }

    if (prevProps.challengeOrganization !== challengeOrganization) {
      if (challengeOrganization) {
        newState = {
          ...newState,
          organization: challengeOrganization,
          organizationsOptions: [
            ...organizations.filter(org => org.id === challengeOrganization.toString()),
          ],
        }
      } else {
        newState = {
          ...newState,
          organizationsOptions: [...organizations],
        }
      }
    }

    if (!challengeOrganization && prevProps.organizations.length !== organizations.length) {
      newState = { ...newState, organizationsOptions: organizations }
    }

    if (prevProps.type.length !== type.length) {
      newState = { ...newState, type }
    }

    if (prevProps.attribute.length !== attribute.length) {
      newState = { ...newState, attribute }
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState)
    }
  }

  validateForm(isSavingDraft = false) {
    const {
      isOrganizationsActive,
      isTypesActive,
      isAttributesActive,
      isTitleAvailable,
      isChallengeMandatoryForIdeaCreation,
      isPrivate,
      images,
      externalVideo,
      fileVideoUrl,
    } = this.props
    const {
      title,
      description,
      organization,
      type,
      attribute,
      challenge,
      video,
      externalVideoError,
      logisticDataErrors,
    } = this.state
    let newState = {
      titleError: '',
      descriptionError: '',
      challengeError: '',
      organizationError: '',
      typeError: '',
      attributesError: '',
      missingImageError: '',
      invalidYoutubeUrlError: '',
    }
    let reference = null
    if (!title) {
      newState = { ...newState, titleError: i18n.t('IDEA.TITLE_ERROR') }
      reference = this.titleRef
    }
    if (!isTitleAvailable) {
      newState = { ...newState, titleError: i18n.t('IDEA.DUPLICATE_ERROR') }
      reference = this.titleRef
    }
    if (!description) {
      newState = { ...newState, descriptionError: i18n.t('IDEA.DESC_ERROR') }
      reference = this.descriptionRef
    }
    if (!isSavingDraft && !images.length) {
      newState = { ...newState, missingImageError: i18n.t('IDEA.IMAGE_ERROR') }
      reference = this.imageRef
    }
    if (externalVideo && (externalVideoError || (video && !checkYoutubeUrl(video)))) {
      newState = { ...newState, invalidYoutubeUrlError: i18n.t('IDEA.EXTERNAL_VIDEO_ERROR') }
      reference = this.externalVideoRef
    }
    if (fileVideoUrl) {
      newState = { ...newState, invalidYoutubeUrlError: '' }
    }
    if (!isPrivate && isChallengeMandatoryForIdeaCreation && !challenge) {
      newState = { ...newState, challengeError: i18n.t('IDEA.CHALLENGE_MANDATORY_ERROR') }
      reference = this.challengeRef
    }
    if (isOrganizationsActive && !organization) {
      newState = { ...newState, organizationError: i18n.t('IDEA.ORGANIZATION_MANDATORY_ERROR') }
      reference = this.organizationsRef
    }
    if (isTypesActive && !type.length) {
      newState = { ...newState, typeError: i18n.t('IDEA.TYPE_MANDATORY_ERROR') }
      reference = this.typesRef
    }
    if (isAttributesActive && !attribute.length) {
      newState = { ...newState, attributesError: i18n.t('IDEA.ATTRIBUTE_MANDATORY_ERROR') }
      reference = this.attributesRef
    }
    if (Object.values(logisticDataErrors).includes(true)) {
      reference = this.logisticDataRef
    }

    this.setState(newState)

    if (reference) {
      if (reference.current) {
        reference.current.scrollIntoView()
      }
      return false
    }

    return true
  }

  handleTitleChange(value) {
    const { isTitleLoading, checkTitleChange } = this.props

    this.setState({
      title: value,
    })

    if (!isTitleLoading) {
      checkTitleChange(value)
    }
  }

  handleIsAnonymous() {
    const { isAnonymous } = this.state

    this.setState({
      isAnonymous: !isAnonymous,
    })
  }

  handleStateChange(element) {
    return value => {
      this.setState({
        [element]: value,
      })
    }
  }

  handleTypeChange() {
    return value => {
      const { type } = this.state

      if (!value.checked && type.includes(value.name)) {
        this.setState({
          type: type.filter(item => item !== value.name),
        })
      } else if (value.checked && !type.includes(value.name)) {
        const newTypes = type.map(item => item)
        newTypes.push(value.name)
        this.setState({ type: newTypes })
      }
    }
  }

  handleAttributeChange() {
    return value => {
      const { attribute } = this.state
      if (!value.checked && attribute.includes(value.name)) {
        this.setState({
          attribute: attribute.filter(item => item !== value.name),
        })
      } else if (value.checked && !attribute.includes(value.name)) {
        const newAttributes = attribute.map(item => item)
        newAttributes.push(value.name)
        this.setState({ attribute: newAttributes })
      }
    }
  }

  handleNumericStateChange(element) {
    const { logisticDataErrors } = this.state

    return value => {
      const error = value < 1 && value !== ''
      this.setState({
        [element]: value,
        logisticDataErrors: {
          ...logisticDataErrors,
          [element]: error,
        },
      })
    }
  }

  handleChallengeChange(value) {
    const { challenge } = this.state
    const { getChallengeOrganization, setChallengeOrganization } = this.props
    let innerChallengeId = value

    if (challenge === value) {
      innerChallengeId = ''
      setChallengeOrganization(innerChallengeId)
    } else {
      getChallengeOrganization(innerChallengeId)
    }

    this.setState({
      challenge: innerChallengeId,
    })
  }

  handleSubmitDraft() {
    const { submitDraft } = this.props

    if (this.validateForm(true)) {
      submitDraft(this.state)
    }
  }

  handleSubmitIdea() {
    const { submitIdea } = this.props

    if (this.validateForm()) {
      submitIdea(this.state)
    }
  }

  handleCancel() {
    const { onCancel } = this.props
    onCancel()
  }

  handleSaveIdea() {
    const { editIdea, isDraft } = this.props
    const { id } = this.state

    if (this.validateForm(isDraft)) {
      editIdea(id, this.state)
    }
  }

  handleDeleteImage(newImages, _imageName, imageToDelete) {
    const { onDeleteImage } = this.props
    const { id } = this.state

    onDeleteImage(id, newImages, imageToDelete)
  }

  handleDocumentUpload(file) {
    const { onGenerateFileUploadUrl } = this.props
    const { id } = this.state

    if (file) onGenerateFileUploadUrl(id || 0, file)
  }

  handleDeleteDocument(fileKey) {
    const { onDeleteFile } = this.props

    onDeleteFile(fileKey)
  }

  handleExternalVideoChange() {
    return value => {
      const { onExternalVideoChange } = this.props
      onExternalVideoChange(value)
    }
  }

  isLogisticDataVisible() {
    const {
      showLogisticData,
      isLogisticActiveOnCreate,
      isAdmin,
      isInnovator,
      isPendingValidation,
    } = this.props

    if (isLogisticActiveOnCreate) {
      return showLogisticData
    }

    return showLogisticData && (isAdmin || isInnovator) && isPendingValidation
  }

  isNewFile(prevFile) {
    const { fileToUpload } = this.props

    if (
      fileToUpload.file.path !== prevFile.path ||
      fileToUpload.file.lastModified !== prevFile.lastModified ||
      fileToUpload.file.name !== prevFile.name ||
      fileToUpload.file.size !== prevFile.size ||
      fileToUpload.file.type !== prevFile.type
    ) {
      return true
    }

    return false
  }

  render() {
    const {
      classes,
      images,
      imageUploadError,
      externalVideo,
      fileVideoSrc,
      fileVideoType,
      isFileVideoLoading,
      isFileVideoReady,
      isVideoRemoving,
      onSelectFileVideo,
      onRemoveFileVideo,
      challenges = [],
      organizationsName,
      organizations = [],
      isOrganizationsActive,
      typesName,
      types = [],
      isTypesActive,
      attributesName,
      attributes = [],
      isAttributesActive,
      goals = [],
      navigateToChallenge,
      isTitleLoading,
      isPreviousValidationEnabled,
      currencyIcon,
      onSelectImage,
      logisticCurrency,
      isPrivate,
      isAnonymousIdeas,
      isAnonymousIdeasMandatory,
      creatingIdea,
      fileVideoId,
      hasVideoError,
      isDocumentLoading,
      csrf,
      documents,
      isInnovator,
      isAdmin,
      customIdeaTitles,
    } = this.props

    const {
      id,
      titleError,
      missingImageError,
      descriptionError,
      organizationError,
      invalidYoutubeUrlError,
      typeError,
      attributesError,
      // main data
      title,
      description,
      endDate,
      // challenges
      challenge,
      // organizations
      organization,
      organizationsOptions,
      // types
      type,
      // attributes
      attribute,
      // goals
      goalValues,
      // logistic data
      implementEstimatedHours,
      implementEstimatedQuantity,
      implementEstimatedTime,
      implementEstimatedTimeType,
      pilotEstimatedHours,
      pilotEstimatedQuantity,
      pilotEstimatedTime,
      pilotEstimatedTimeType,
      economicBenefits,
      otherBenefits,
      challengeError,
      logisticDataErrors,
      // anonymous
      isAnonymous,
    } = this.state

    return (
      <div className={classes.root}>
        <h1>
          <span className="icon-idea" />
          {i18n.t('IDEA.CREATE')}
        </h1>
        <Paper className={classes.paper}>
          <NxtCircularLoader isLoading={creatingIdea}>
            <MainFormSection
              id={id}
              titleValue={title}
              descriptionValue={description}
              imageValues={images}
              externalVideoValue={externalVideo}
              onExternalVideoError={error => this.setState({ externalVideoError: error })}
              onExternalVideoChange={this.handleExternalVideoChange('video')}
              fileVideoSrc={fileVideoSrc}
              fileVideoType={fileVideoType}
              onSelectFileVideo={() => onSelectFileVideo(id)}
              onRemoveFileVideo={() => onRemoveFileVideo(fileVideoId)}
              isFileVideoLoading={isFileVideoLoading}
              isFileVideoReady={isFileVideoReady}
              isVideoRemoving={isVideoRemoving}
              hasVideoError={hasVideoError}
              expireDateValue={endDate}
              onTitleChange={this.handleTitleChange}
              onDescriptionChange={this.handleStateChange('description')}
              onSelectImage={image => onSelectImage(id, image)}
              deleteImage={this.handleDeleteImage}
              onChangeExpiredDate={this.handleStateChange('endDate')}
              isTitleLoading={isTitleLoading}
              titleError={titleError}
              descriptionError={descriptionError}
              imageError={imageUploadError}
              titleRef={this.titleRef}
              descriptionRef={this.descriptionRef}
              imageRef={this.imageRef}
              missingImageError={missingImageError}
              externalVideoRef={this.externalVideoRef}
              invalidYoutubeUrlError={invalidYoutubeUrlError}
              disableUploadVideoFile={!isInnovator}
              disableExpireDate={!isAdmin && !isInnovator}
              customIdeaTitle={customIdeaTitles.singularTitle}
            />
            <SimpleDivider />
            {!isPrivate && (
              <div className={classes.section} ref={this.challengeRef}>
                <h2>
                  <span className="icon-target" />
                  {i18n.t('CHALLENGE.TITLE')}
                </h2>
                <RadioSelector
                  onValueChange={this.handleChallengeChange}
                  items={challenges}
                  linkName={i18n.t('EXPLORE_IDEA.VIEW_CHALLENGE')}
                  helpText={i18n.t('CHALLENGE.DESC', {
                    title: customIdeaTitles.singularTitle || 'idea',
                  })}
                  value={challenge}
                  navigateTo={navigateToChallenge}
                  error={challengeError}
                />
                <SimpleDivider />
              </div>
            )}
            {isOrganizationsActive && (
              <div className={classes.section} ref={this.organizationsRef}>
                <h2>
                  <span className="icon-th-list" />
                  {organizationsName}
                </h2>
                <RadioSelector
                  items={organizationsOptions}
                  subItemsKey="organizations"
                  value={organization}
                  onValueChange={this.handleStateChange('organization')}
                  error={organizationError}
                />
                {organizations.length === 0 && (
                  <h3 className={classes.noOrgsSpan}>{i18n.t('ORGANIZATION.NO_ACTIVE')}</h3>
                )}
                <SimpleDivider />
              </div>
            )}
            {isTypesActive && (
              <div className={classes.section} ref={this.typesRef}>
                <h2>
                  <span className="icon-th-list" />
                  {typesName}
                </h2>
                <CheckboxSelector
                  items={types}
                  values={type}
                  onValueChange={this.handleTypeChange}
                  error={typeError}
                />
                <SimpleDivider />
              </div>
            )}
            {isAttributesActive && (
              <div className={classes.section} ref={this.attributesRef}>
                <h2>
                  <span className="icon-th-list" />
                  {attributesName}
                </h2>
                <CheckboxSelector
                  items={attributes}
                  values={attribute}
                  onValueChange={this.handleAttributeChange}
                  error={attributesError}
                />
                <SimpleDivider />
              </div>
            )}
            <div className={classes.section}>
              <h2>
                <span className="icon-web" />
                {i18n.t('IDEA.STRATEGIC')}
              </h2>
              <GoalsSlider
                helpText={i18n.t('IDEA.STRATEGIC_SUB', {
                  title: customIdeaTitles.singularTitle || 'idea',
                })}
                goals={goals}
                values={goalValues}
                onGoalChange={this.handleStateChange('goalValues')}
              />
              <SimpleDivider />
            </div>
            {this.isLogisticDataVisible() && (
              <div className={classes.section} ref={this.logisticDataRef}>
                <h2>
                  <span className="icon-calculator" />
                  {i18n.t('LOGISTIC.TITLE')}
                </h2>
                <LogisticData
                  logisticCurrency={logisticCurrency}
                  implementEstimatedHours={implementEstimatedHours}
                  implementEstimatedQuantity={implementEstimatedQuantity}
                  implementEstimatedTime={implementEstimatedTime}
                  implementEstimatedTimeType={implementEstimatedTimeType}
                  pilotEstimatedHours={pilotEstimatedHours}
                  pilotEstimatedQuantity={pilotEstimatedQuantity}
                  pilotEstimatedTime={pilotEstimatedTime}
                  pilotEstimatedTimeType={pilotEstimatedTimeType}
                  economicBenefits={economicBenefits}
                  otherBenefits={otherBenefits}
                  onImplementQuantityChange={this.handleNumericStateChange(
                    'implementEstimatedQuantity',
                  )}
                  onImplementHoursChange={this.handleNumericStateChange('implementEstimatedHours')}
                  onImplementTimeChange={this.handleNumericStateChange('implementEstimatedTime')}
                  onImplementTimeTypeChange={this.handleStateChange('implementEstimatedTimeType')}
                  onPilotQuantityChange={this.handleNumericStateChange('pilotEstimatedQuantity')}
                  onPilotHoursChange={this.handleNumericStateChange('pilotEstimatedHours')}
                  onPilotTimeChange={this.handleNumericStateChange('pilotEstimatedTime')}
                  onPilotTimeTypeChange={this.handleStateChange('pilotEstimatedTimeType')}
                  onEconomicBenefitsChange={this.handleNumericStateChange('economicBenefits')}
                  onOtherBenefitsChange={this.handleStateChange('otherBenefits')}
                  logisticDataErrors={logisticDataErrors}
                  customIdeaTitle={customIdeaTitles.singularTitle}
                />
                <SimpleDivider />
              </div>
            )}
            <DocumentSection
              csrfToken={csrf}
              documents={documents}
              isDocumentLoading={isDocumentLoading}
              onFileSelect={this.handleDocumentUpload}
              onDeleteDocument={this.handleDeleteDocument}
              canDelete
            />

            {!isAnonymousIdeasMandatory && isAnonymousIdeas && (
              <div className={classes.section}>
                <h2>
                  <span className="icon-help" />
                  {i18n.t('IDEA.ANONYMOUS')}
                </h2>
                <NxtFormHelper
                  helpText={i18n.t('IDEA.ANONYMOUS_SUB').replace('##BRAND_NAME##', '')}
                >
                  <FormControlLabel
                    className={classes.check}
                    control={<NxtCheckbox isChecked={isAnonymous} />}
                    onClick={this.handleIsAnonymous}
                    label={i18n.t('IDEA.ANONYMOUS_CHECK')}
                  />
                </NxtFormHelper>
                <SimpleDivider />
              </div>
            )}
          </NxtCircularLoader>
        </Paper>
        <ButtonsFooter
          isPreviousValidationEnabled={isPreviousValidationEnabled}
          onInitialInvestmentChange={this.handleNumericStateChange('initialInvestment')}
          currencyIcon={currencyIcon}
          edit={id !== undefined}
          onSubmitDraft={this.handleSubmitDraft}
          onSubmitIdea={this.handleSubmitIdea}
          onCancel={this.handleCancel}
          onSaveIdea={this.handleSaveIdea}
          isSubmitting={creatingIdea}
          customIdeaTitle={customIdeaTitles.singularTitle}
        />
      </div>
    )
  }
}

IdeaForm.defaultProps = {
  title: '',
  description: '',
  externalVideo: '',
  fileVideoSrc: '',
  fileVideoUrl: '',
  challenge: '',
  challenges: [],
  organization: {},
  organizations: [],
  organizationsName: '',
  isOrganizationsActive: false,
  type: [],
  types: [],
  isTypesActive: false,
  isAttributesActive: false,
  goalValues: {},
  goals: [],
  isTitleLoading: false,
  isTitleAvailable: true,
  isPreviousValidationEnabled: false,
  imageUploadError: '',
  logisticCurrency: '',
  customIdeaTitles: {
    singularTitle: '',
    pluralTitle: '',
  },
}

IdeaForm.propTypes = {
  title: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      src: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ).isRequired,
  imageUploadError: PropTypes.string,
  onSelectImage: PropTypes.func.isRequired,
  onDeleteImage: PropTypes.func.isRequired,
  externalVideo: PropTypes.string,
  fileVideoSrc: PropTypes.string,
  fileVideoUrl: PropTypes.string,
  isFileVideoReady: PropTypes.bool.isRequired,
  onVideoEncodingError: PropTypes.func.isRequired,
  onSelectFileVideo: PropTypes.func.isRequired,
  onRemoveFileVideo: PropTypes.func.isRequired,
  description: PropTypes.string,
  challenge: PropTypes.string,
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  organization: PropTypes.shape({}),
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  organizationsName: PropTypes.string,
  isOrganizationsActive: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  type: PropTypes.array,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  isTypesActive: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  isAttributesActive: PropTypes.bool,
  goalValues: PropTypes.shape({}),
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  isTitleLoading: PropTypes.bool,
  isTitleAvailable: PropTypes.bool,
  isPreviousValidationEnabled: PropTypes.bool,
  navigateToChallenge: PropTypes.func.isRequired,
  currencyIcon: PropTypes.string.isRequired,
  getGoals: PropTypes.func.isRequired,
  getChallenges: PropTypes.func.isRequired,
  getChallengeOrganization: PropTypes.func.isRequired,
  setChallengeOrganization: PropTypes.func.isRequired,
  getOrganizations: PropTypes.func.isRequired,
  getTypes: PropTypes.func.isRequired,
  getAttributes: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitDraft: PropTypes.func.isRequired,
  submitIdea: PropTypes.func.isRequired,
  checkTitleChange: PropTypes.func.isRequired,
  getIdea: PropTypes.func.isRequired,
  editIdea: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // Logistic data
  implementEstimatedHours: PropTypes.string.isRequired,
  implementEstimatedQuantity: PropTypes.string.isRequired,
  implementEstimatedTime: PropTypes.string.isRequired,
  implementEstimatedTimeType: PropTypes.string.isRequired,
  pilotEstimatedHours: PropTypes.string.isRequired,
  pilotEstimatedQuantity: PropTypes.string.isRequired,
  pilotEstimatedTime: PropTypes.string.isRequired,
  pilotEstimatedTimeType: PropTypes.string.isRequired,
  economicBenefits: PropTypes.string.isRequired,
  otherBenefits: PropTypes.string.isRequired,
  logisticCurrency: PropTypes.string,
  isChallengeMandatoryForIdeaCreation: PropTypes.bool.isRequired,
  isDraft: PropTypes.bool.isRequired,
  isLogisticActiveOnCreate: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isInnovator: PropTypes.bool.isRequired,
  isPendingValidation: PropTypes.bool.isRequired,
  isDocumentLoading: PropTypes.bool.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  getIdeaVideoType: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      size: PropTypes.string,
      src: PropTypes.string,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fileToUpload: PropTypes.object.isRequired,
  onExternalVideoChange: PropTypes.func.isRequired,
  csrf: PropTypes.string.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string,
  }),
}

export default withStyles(style)(IdeaForm)
