/* eslint-disable react/jsx-no-bind */
import React from 'react'
import * as PropTypes from 'prop-types'
import { TextField, Dialog, Button, CircularProgress, InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import withStyles from '@material-ui/core/styles/withStyles'
import ButtonLink from 'components/button_link/View'
import TermsOfService from 'components/terms_of_service/View'
import i18n from 'support/i18n'
import AuthMessageBox from 'components/auth_message_box/View'
/* import AppStores from 'components/app_stores/View' */
import styles from './View.Style'

const SignIn = props => {
  const {
    classes,
    onSubmit,
    onForgotPasswordClick,
    messageText,
    messageType,
    isLogging,
    isEmailNotVerifiedYet,
    onSendMeActivationEmailClick,
    onCreateAccountClick,
    onClickGoogleAuth,
    onClickAzureAuth,
    onClickAdfsAuth,
    isGoogleAuthEnabled,
    isAzureAuthEnabled,
    isAdfsAuthEnabled,
    /* appStores,
    usePolicies, */
    useCreateAccount,
    useCustomSubTitleWelcome,
    customSubTitleWelcome,
    useCustomWelcome,
    customWelcome,
    /* customAppStoreLinks */
  } = props

  const [hasEmailError, setEmailError] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const [loginData, setLoginData] = React.useState({
    email: '',
    password: '',
  })

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleTextFieldChange(event) {
    const { name, value } = event.target
    const textField = { ...loginData, [name]: value }
    if (textField.email && hasEmailError) {
      setEmailError(false)
    }
    setLoginData(textField)
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (!loginData.email) {
      setEmailError(true)
    } else {
      onSubmit(loginData.email, loginData.password)
    }
  }

  function handleForgotPassword() {
    if (!loginData.email) {
      setEmailError(true)
    } else {
      onForgotPasswordClick(loginData.email)
    }
  }

  function renderMessageText() {
    if (isEmailNotVerifiedYet) {
      return (
        <div>
          <span>{i18n.t('COMMON_LOGIN.HEADER.EMAIL_NOT_CONFIRMED_YET')}</span>
          <ButtonLink
            classes={{ linkButton: classes.activationEmailLink }}
            onLinkClick={() => onSendMeActivationEmailClick(loginData.email)}
          >
            {i18n.t('COMMON_LOGIN.HEADER.RESEND_ACTIVATION_EMAIL')}
          </ButtonLink>
        </div>
      )
    }
    return messageText
  }

  return (
    <div className={classes.content}>
      <div className={classes.login}>
        <div className={classes.title}>
          <h1>
            {useCustomWelcome ? customWelcome : i18n.t('COMMON_LOGIN.HEADER.WELCOME_SIGN_UP')}
            <small>{useCustomSubTitleWelcome ? customSubTitleWelcome : i18n.t('COMMON_LOGIN.HEADER.LOGIN_TITLE')}</small>
          </h1>
        </div>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            autoComplete="email"
            autoFocus
            fullWidth
            label={i18n.t('COMMON_LOGIN.HEADER.EMAIL')}
            placeholder={i18n.t('COMMON_LOGIN.HEADER.EMAIL')}
            margin="normal"
            name="email"
            onChange={handleTextFieldChange}
            InputLabelProps={{ shrink: true }}
            required
            variant="outlined"
            error={hasEmailError}
            helperText={hasEmailError && i18n.t('COMMON_LOGIN.HEADER.MANDATORY_FIELD')}
          />
          <TextField
            fullWidth
            label={i18n.t('COMMON_LOGIN.HEADER.PWD')}
            placeholder={i18n.t('COMMON_LOGIN.HEADER.PWD')}
            margin="normal"
            name="password"
            onChange={handleTextFieldChange}
            required
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
            }}
          />
          <ButtonLink
            classes={{ linkButton: classes.forgotContainer }}
            onLinkClick={handleForgotPassword}
          >
            {i18n.t('COMMON_LOGIN.HEADER.FORGOT_PWD')}
          </ButtonLink>
          <Button
            className={classes.submit}
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            disabled={isLogging}
          >
            {i18n.t('COMMON_LOGIN.HEADER.SIGN_IN')}
            {isLogging && (
              <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />
            )}
          </Button>
          {isGoogleAuthEnabled && (
            <Button
              className={classes.socialGoogleButton}
              color="primary"
              fullWidth
              onClick={onClickGoogleAuth}
              variant="contained"
              disabled={isLogging}
            >
              Google
              {isLogging && (
                <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />
              )}
            </Button>
          )}
          {(isAdfsAuthEnabled || isAzureAuthEnabled) && (
            <div className={classes.microsoftContainer}>
              {isAzureAuthEnabled && (
                <Button
                  className={classes.socialMicrosoftButton}
                  color="primary"
                  fullWidth
                  onClick={onClickAzureAuth}
                  variant="contained"
                  disabled={isLogging}
                >
                  Azure
                  {isLogging && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                      color="secondary"
                    />
                  )}
                </Button>
              )}
              {isAdfsAuthEnabled && (
                <Button
                  className={classes.socialMicrosoftButton}
                  color="primary"
                  fullWidth
                  onClick={onClickAdfsAuth}
                  variant="contained"
                  disabled={isLogging}
                >
                  ADFS
                  {isLogging && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                      color="secondary"
                    />
                  )}
                </Button>
              )}
            </div>
          )}
          {useCreateAccount && onCreateAccountClick && (
            <div className={classes.createAccount}>
              <ButtonLink onLinkClick={onCreateAccountClick}>
                {i18n.t('COMMON_LOGIN.HEADER.CREATE_ACCOUNT')}
              </ButtonLink>
            </div>
          )}
          {/* {appStores && (
            <AppStores
              appStoreUrl="https://apps.apple.com/cl/app/i360/id1550101603"
              googlePlayUrl="https://play.google.com/store/apps/details?id=com.innova360"
            />
          )}
          <AppStores
            appStoreUrl={customAppStoreLinks?.iosUrl}
            googlePlayUrl={customAppStoreLinks?.androidUrl}
          />
          {usePolicies && (
            <div className={classes.terms}>
              <a href="https://storage.googleapis.com/politicas-de-uso/terminos_de_uso_plataforma_i360_oct_2021.pdf" target="_blank" rel="noreferrer">
                © Políticas de Uso/Protección y Tratamiento de Datos
              </a>
            </div>
          )} */}
          <AuthMessageBox messageType={messageType} messageText={renderMessageText()} />
        </form>
      </div>
      <Dialog
        aria-labelledby="Terms of service"
        maxWidth="md"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <TermsOfService />
      </Dialog>
    </div>
  )
}

SignIn.defaultProps = {
  onCreateAccountClick: null,
  onClickGoogleAuth: () => { },
  onClickAzureAuth: () => { },
  onClickAdfsAuth: () => { },
  isGoogleAuthEnabled: true,
  isAzureAuthEnabled: false,
  isAdfsAuthEnabled: false,
  /* appStores: false,
  usePolicies: false, */
  useCreateAccount: false,
  useCustomSubTitleWelcome: false,
  customSubTitleWelcome: '',
  useCustomWelcome: false,
  customWelcome: '',
}

SignIn.propTypes = {
  classes: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    terms: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLogging: PropTypes.bool.isRequired,
  isEmailNotVerifiedYet: PropTypes.bool.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
  onSendMeActivationEmailClick: PropTypes.func.isRequired,
  messageType: PropTypes.number.isRequired,
  messageText: PropTypes.node.isRequired,
  onCreateAccountClick: PropTypes.func,
  onClickGoogleAuth: PropTypes.func,
  onClickAzureAuth: PropTypes.func,
  onClickAdfsAuth: PropTypes.func,
  isGoogleAuthEnabled: PropTypes.bool,
  isAzureAuthEnabled: PropTypes.bool,
  isAdfsAuthEnabled: PropTypes.bool,
  /* appStores: PropTypes.bool,
  usePolicies: PropTypes.bool, */
  useCreateAccount: PropTypes.bool,
  useCustomSubTitleWelcome: PropTypes.bool,
  customSubTitleWelcome: PropTypes.string,
  useCustomWelcome: PropTypes.bool,
  customWelcome: PropTypes.string,
  customAppStoreLinks: PropTypes.shape({
    androidUrl: PropTypes.string,
    iosUrl: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(SignIn)
