const styles = theme => ({
  root: {
    width: '568px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalBody: {
    flex: 2,
    display: 'flex',
    minHeight: '30vh',
    maxHeight: '50vh',
  },
  noVideo: {
    color: '#ccc',
    border: '8px dotted',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span[class*="icon-"]': {
      fontSize: '150px',
      marginRight: theme.spacing(2),
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 46,
  },
  video: {
    margin: '20px auto',
    width: 500,
  },
  buttonsWrapper: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    justifyContent: 'space-between',
    '& > div > label': {
      paddingRight: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  spinner: {
    marginRight: theme.spacing(1.5),
  },
})

export default styles
