import React from 'react'
import * as PropTypes from 'prop-types'
import { FormControlLabel, RadioGroup, TextField, withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import NxtRadio from 'components/nxt_radio/View'
import styles from './View.Style'

const RewardChoices = props => {
  const { classes, value, onRewardedChange, otherValue, onOtherChange, customIdeaTitles } = props
  return (
    <div className={classes.root}>
      <RadioGroup
        id="challenge-reward-radio"
        onChange={event => onRewardedChange(event.target.value)}
        value={value}
      >
        <FormControlLabel
          id="challenge-reward-radio-piloted"
          control={<NxtRadio />}
          onClick={event => event.target.value === value && onRewardedChange('')}
          label={i18n.t('CHALLENGES.TEAM_ON_IDEAS_PILOTED', { title: customIdeaTitles.pluralTitle || "ideas" })}
          value="TEAM_ON_IDEAS_PILOTED"
        />
        <FormControlLabel
          id="challenge-reward-radio-more"
          control={<NxtRadio />}
          onClick={event => event.target.value === value && onRewardedChange('')}
          label={i18n.t('CHALLENGES.TEAM_ON_IDEA_MORE_FUNDED', { title: customIdeaTitles.singularTitle || "idea" })}
          value="TEAM_ON_IDEA_MORE_FUNDED"
        />
        <FormControlLabel
          id="challenge-reward-radio-funded"
          control={<NxtRadio />}
          onClick={event => event.target.value === value && onRewardedChange('')}
          label={i18n.t('CHALLENGES.TEAM_ON_ALL_IDEAS_FUNDED', { title: customIdeaTitles.pluralTitle || "ideas" })}
          value="TEAM_ON_ALL_IDEAS_FUNDED"
        />
        <FormControlLabel
          id="challenge-reward-radio-all"
          control={<NxtRadio />}
          onClick={event => event.target.value === value && onRewardedChange('')}
          label={i18n.t('CHALLENGES.TEAM_ON_ALL_IDEAS', { title: customIdeaTitles.pluralTitle || "ideas" })}
          value="TEAM_ON_ALL_IDEAS"
        />
        <FormControlLabel
          id="challenge-reward-radio-other"
          control={<NxtRadio />}
          onClick={event => event.target.value === value && onRewardedChange('')}
          label={i18n.t('CHALLENGES.REWARD_OTHER')}
          value="OTHER"
        />
      </RadioGroup>
      <TextField
        id="challenge-reward-other"
        multiline
        fullWidth
        defaultValue={otherValue}
        onChange={event => onOtherChange(event.target.value)}
        disabled={value !== 'OTHER'}
      />
    </div>
  )
}

RewardChoices.defaultProps = {
  value: '',
  otherValue: '',
}

RewardChoices.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,
  onRewardedChange: PropTypes.func.isRequired,
  otherValue: PropTypes.string,
  onOtherChange: PropTypes.func.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(RewardChoices)
