const POST_UPLOAD_STORAGE_STATIC_VIDEO_URL = '/api/v1/idea/0/document/video'

export const setVideoContent = video => {
  const videoFile = new FormData()
  videoFile.append('file', video)
  return videoFile
}

// this function takes from state, and state takes from dynamic url from api, so now, if isIdea -> static url to video
export const setIdeaStorageUploadUrl = (isIdea, state) => {
  return isIdea
    ? POST_UPLOAD_STORAGE_STATIC_VIDEO_URL
    : state.data.challengeSteps.challengeDescription.storageVideoUploadUrl
}
