/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Boost from 'highcharts/modules/boost'
import NoData from 'highcharts/modules/no-data-to-display'
import i18n from 'support/i18n'
import styles from './View.Style'

Boost(Highcharts)
NoData(Highcharts)

const DistributionGraph = ({ classes, data }) => {
  const [colors] = useState({
    "raising": "#0096e2",
    "approved": "#34aae5",
    "closed": "#67bfec",
    "prototyped": "#99d5f2",
    "background": "#FFFFFF"
  })
  const [titles] = useState({
    "raising": i18n.t("INVESTMENTS.RAISING"),
    "approved": i18n.t("INVESTMENTS.APPROVED_IT"),
    "closed": i18n.t("INVESTMENTS.ROUND_CLOSED"),
    "prototyped": i18n.t("INVESTMENTS.PROTOTYPED")
  })
  const [options, setOptions] = useState(null)

  useEffect(() => {
    if (data) {
      setOptions({
        chart: {
          backgroundColor: colors.background,
          plotBorderWidth: null,
          plotShadow: false
        },
        title: false,
        tooltip: {
          enabled: false,
          formatter: function() { 
            return `<b>${this.point.ammount} investiment (${this.point.percentage} %)</b>`
          }
        },
        plotOptions: {
          pie: {
              allowPointSelect: true,
              showInLegend: false
          }
        },
        series: [{
          type: 'pie',
          name: 'Portfolio',
          size: '70%',
          innerSize: '50%',
          data: [
            {
              name: titles.raising,
              y: (data.count && data.count.raising) ? data.count.raising : 0,
              ammount : (data.count && data.count.raising) ? data.count.raising : 0,
              dataLabels: `${titles.raising} 3`,
              invest: (data.invest && data.invest.raising ) ? data.invest.raising : 0,
              win: (data.win && data.win.raising) ? data.win.raising : 0,
              color: colors.raising
            },
            {
              name: titles.approved,
              y: (data.count && data.count.approved) ? data.count.approved : 0,
              ammount : (data.count && data.count.approved) ? data.count.approved : 0,
              dataLabels: `${titles.approved} 3`,
              invest: (data.invest && data.invest.approved ) ? data.invest.approved : 0,
              win: (data.win && data.win.approved) ? data.win.approved : 0,
              color: colors.approved
            },
            {
              name: titles.closed,
              y: (data.count && data.count.closed) ? data.count.closed : 0,
              ammount : (data.count && data.count.closed) ? data.count.closed : 0,
              dataLabels: `${titles.closed} 3`,
              invest: (data.invest && data.invest.closed ) ? data.invest.closed : 0,
              win: (data.win && data.win.closed) ? data.win.closed : 0,
              color: colors.closed
            },
            {
              name: titles.prototyped,
              y: (data.count && data.count.prototyped) ? data.count.prototyped : 0,
              ammount : (data.count && data.count.prototyped) ? data.count.prototyped : 0,
              dataLabels: `${titles.prototyped} 3`,
              invest: (data.invest && data.invest.prototyped ) ? data.invest.prototyped : 0,
              win: (data.win && data.win.prototyped) ? data.win.prototyped : 0,
              color: colors.prototyped
            },
          ],
          dataLabels: { 
            enabled: true,
            useHTML: true,
            formatter: function() {
                return (
                  `<div class="pie-label-wrapper" style="background-color: ${this.point.color}">
                    <div class="pie-label">
                      <div class="title">
                        ${this.point.name}
                        <span>
                          ${this.y}
                        </span>
                      </div>
                      <div class="subtitle amount">
                        ${this.point.invest} (+${this.point.win})
                      </div>
                    </div>
                  </div>`
                );
              }
            },
            style: {
              fontWeight: 'bold',
              fontSize: "14px",
              color: "black"
            }
          }
        ],
      })
    }
  }, [data, colors, titles])

  return (
    <div className={classes.root}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}

DistributionGraph.defaultProps = {
  data: {
    count: {
      raising: 0,
      approved: 0,
      closed: 0,
      prototyped: 0
    },
    invest: {
      raising: 0,
      approved: 0,
      closed: 0,
      prototyped: 0
    },
    win: {
      raising: 0,
      approved: 0,
      closed: 0,
      prototyped: 0
    }
  },
}

DistributionGraph.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object
}

export default withStyles(styles)(DistributionGraph)
