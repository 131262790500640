import { connect } from 'react-redux'
import { getActiveChallenges, applySearchChallengesService } from 'services/challenges'
import Navigation from 'support/navigation/index'
import AnalyticsService from 'support/analytics'
import View from './View'

const mapStateToProps = (state) => {
  return {
    challenges: state.data.challenge.challenges,
    isAdmin: state.data.user.admin,
    isInnovator: state.data.user.isInnovator,
    isPrivateFlowActive: state.data.domain.domainConfiguration.isPrivateFlowActive,
    canProposeChallenge:
      !state.data.user.admin &&
      !state.data.user.isInnovator &&
      state.data.domain.domainConfiguration.challengeProposal,
    customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getActiveChallenges: () => dispatch(getActiveChallenges()),
    getFilteredChallenges: status => {
      dispatch(getActiveChallenges(status))
      AnalyticsService.trackEvent({
        category: 'swiper',
        action: 'click',
        label: 'apply new filter',
      })
    },
    navigateToChallengeDetail: id => dispatch(Navigation.navigateToChallengeDetail(id)),
    navigateToNewChallenge: () => dispatch(Navigation.navigateToChallengeNew()),
    navigateToProposeChallenge: () => dispatch(Navigation.navigateToProposeChallenge()),
    navigateToNewIdea: challengeId => dispatch(Navigation.navigateToNewIdea(challengeId)),
    navigateToUserProfile: email => {
      dispatch(Navigation.navigateToUserProfile(email))
    },
    applySearch: search => dispatch(applySearchChallengesService(search)),
    navigateToIdea: (id, index) => {
      dispatch(Navigation.navigateToIdea(id))
      AnalyticsService.trackEvent({
        category: 'swiper',
        action: 'click',
        label: 'idea panel',
        value: index,
      })
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
