import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Slider from 'components/slider/View'
import { Image } from 'components/index'
import { isYoutubeUrl } from 'support/utils/file'
import * as fileTypes from 'common_constants/files'
import i18n from 'support/i18n'
import NxtUserCard from 'components/nxt_user_card/View'
import imgDefault from 'img/i360-logo-fondo-ideas.png'
import styles from './View.Style'

class ResumeSlider extends Component {
  constructor(props) {
    super(props)
    const slides = [...props.images]
    if (props.videoSrc) {
      slides.unshift({
        isVideo: true,
        src: props.videoSrc,
        type: props.videoType,
        origin: isYoutubeUrl(props.videoSrc) ? fileTypes.YOUTUBE : fileTypes.BLOBSTORAGE
      })
    }
    if (slides.length === 0) {
      slides.push({
        src: '',
      })
    }
    this.state = {
      activeStep: 0,
      slides,
    }
    this.renderSlide = this.renderSlide.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { videoSrc, videoType, images } = this.props
    if (prevProps.videoSrc !== videoSrc || prevProps.videoType !== videoType) {
      const slides = [...images]
      if (videoSrc) {
        slides.unshift({
          isVideo: true,
          src: videoSrc,
          type: videoType,
          origin: isYoutubeUrl(videoSrc) ? fileTypes.YOUTUBE : fileTypes.BLOBSTORAGE
        })
      }
      if (slides.length === 0) {
        slides.push({
          src: '',
        })
      }
      this.setState({ slides })
    }
  }

  renderSlide(itemSlide) {
    const { classes } = this.props
    if (itemSlide.isVideo) {
      if (itemSlide.origin === fileTypes.YOUTUBE) {
        return (
          <div className={classes.iframeVideo} key={itemSlide.src}>
            <iframe
              title="ytVideo"
              width="500"
              height="310"
              src={itemSlide.src}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        )
      }
      
      return (
        <video className={classes.video} controls>
          <source src={itemSlide.src} type={itemSlide.type} />
          <track label="video_challenge" src="captions_en.vtt" kind="captions" srcLang="en" />
        </video>
      )
    }

    return (
      <Image defaultSrc={imgDefault} src={itemSlide.src} alt={itemSlide.name} key={itemSlide.src} />
    )
  }

  render() {
    const { 
      classes, 
      selectedProposer, 
      arrowsPosition, 
      mode,
      proposerPosition, 
    } = this.props
    const { activeStep, slides } = this.state

    const handleActiveStep = (value) => {
      this.setState({ activeStep: value })
    }

    return (
      <>
        <div className={classes.root}>
          {selectedProposer.fullName && proposerPosition === 'overlay' && (
            <div className={classes.overlay}>
              <NxtUserCard
                label={i18n.t('CHALLENGES.DETAIL.PROPOSER')}
                imageSrc={selectedProposer.photo}
                usernameElement={<span>{selectedProposer.fullName}</span>}
                isTextInverted
                imageSize="2.2rem"
              />
            </div>
          )}
          <Slider
            activeStep={activeStep}
            maxSteps={slides.length}
            setActiveStep={(value) => handleActiveStep(value)}
            arrowsPosition={arrowsPosition}
            arrowsColor={mode === 'dark' ? '#fff' : '#000'}
            arrowsBgColor={mode === 'dark' ? '#333' : '#fff'}
            arrowsSize="1.8em"
            stepper={slides.length > 1}
          >
            {slides.map(slide => {
              return this.renderSlide(slide)
            })}
          </Slider>
        </div>

        {selectedProposer.fullName && proposerPosition === 'bottom' && (
          <div className={classes.bottom}>
            <NxtUserCard
              label={i18n.t('CHALLENGES.DETAIL.PROPOSER')}
              imageSrc={selectedProposer.photo}
              usernameElement={<span>{selectedProposer.fullName}</span>}
              isTextInverted
              imageSize="2.2rem"
            />
          </div>
        )}
      </>
    )
  }
}

ResumeSlider.defaultProps = {
  classes: {
    root: '',
    iframeVideo: '',
    overlay: '',
  },
  images: [],
  videoSrc: '',
  videoType: '',
  selectedProposer: {},
  arrowsPosition: 'bottom',
  mode: 'dark',
  proposerPosition: 'bottom',
}

ResumeSlider.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
    }),
  ),
  videoSrc: PropTypes.string,
  videoType: PropTypes.string,
  selectedProposer: PropTypes.shape({}),
  arrowsPosition: PropTypes.string,
  mode: PropTypes.string,
  proposerPosition: PropTypes.oneOf(['overlay', 'bottom']) 
}

export default withStyles(styles)(ResumeSlider)
