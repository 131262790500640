import React from 'react'
import { withStyles, TextField } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import clsx from 'clsx'
import TextEditor from 'components/text_editor/View'
import styles from './View.Style'

const DescriptionPanel = props => {
  const {
    classes,
    title,
    onTitleChange,
    whyImportant,
    onWhyImportantChange,
    description,
    onDescriptionChange,
    hasDescriptionError,
    hasTitleError,
  } = props

  return (
    <div className={classes.root}>
      <div className={classes.line}>
        <div className={clsx(classes.inputLabel, hasTitleError && classes.error)}>
          {i18n.t('CHALLENGES.TITLE')}
        </div>
        <div className={classes.inputContainer}>
          <TextField
            id="input-title"
            placeholder={i18n.t('CHALLENGES.TITLE')}
            margin="dense"
            fullWidth
            defaultValue={title}
            onChange={event => onTitleChange(event.target.value)}
            error={hasTitleError}
          />
        </div>
      </div>
      <div className={classes.line}>
        <div className={clsx(classes.inputLabel, hasDescriptionError && classes.error)}>
          {i18n.t('CHALLENGES.DESC')}
        </div>
        <div className={classes.inputContainer}>
          <TextEditor
            id="input-description"
            classes={{ description: classes.inputItem }}
            value={description}
            onTextChange={onDescriptionChange}
          />
        </div>
      </div>
      <div className={classes.line}>
        <div className={classes.inputLabel}>{i18n.t('CHALLENGES.WHY_IMPORTANT')}</div>
        <div className={classes.inputContainer}>
          <TextField
            id="input-why-important"
            placeholder={i18n.t('CHALLENGES.WHY_IMPORTANT')}
            margin="dense"
            fullWidth
            multiline
            defaultValue={whyImportant}
            onChange={event => onWhyImportantChange(event.target.value)}
          />
          <p className={classes.whyImportantMessage}>{i18n.t('CHALLENGES.WHY_IMPORTANT_INFO')}</p>
        </div>
      </div>
    </div>
  )
}
DescriptionPanel.defaultProps = {
  title: '',
  whyImportant: '',
  description: '',
  hasTitleError: false,
  hasDescriptionError: false,
}
DescriptionPanel.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    line: PropTypes.string,
    inputLabel: PropTypes.string,
    inputContainer: PropTypes.string,
    whyImportantMessage: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onWhyImportantChange: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  whyImportant: PropTypes.string,
  description: PropTypes.string,
  hasTitleError: PropTypes.bool,
  hasDescriptionError: PropTypes.bool,
}
export default withStyles(styles)(DescriptionPanel)
