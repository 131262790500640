import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { withStyles, InputAdornment } from '@material-ui/core'
import { format, addDays } from 'date-fns'
import i18n from 'i18n-js'
import * as datesUtils from 'support/utils/dates'
import styles from './View.Styles'

class SingleDateRangePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      localeFile: null,
    }

    this.formatWeekSelectLabel = this.formatWeekSelectLabel.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentDidMount() {
    let { locale } = this.props
    locale = locale === 'en' ? 'en-US' : locale
    this.setLocaleFile(locale)
  }

  async setLocaleFile(localeString) {
    let localeFile = await import(`date-fns/locale/${localeString}`)
    localeFile = localeFile.default
    this.setState({
      localeFile,
    })
  }

  handleDateChange(newValue) {
    const { onDateChange } = this.props
    onDateChange({ fromDate: newValue, toDate: addDays(newValue, 6)})
  }

  formatWeekSelectLabel() {
    const { fromDate, toDate, format: dateFormat } = this.props
    const start = format(
      fromDate, dateFormat, { locale: datesUtils.locale(i18n.currentLocale()) 
    })
    const end = format(
      toDate, dateFormat, { locale: datesUtils.locale(i18n.currentLocale()) 
    })
    return `${i18n.t("WEEK_SELECTOR.WEEK")} ${start} ${i18n.t("WEEK_SELECTOR.TO")} ${end}`
  };

  render() {
    const { localeFile } = this.state
    const {
      classes,
      label,
      isFullWidth,
      fromDate,
      endIconName,
      minDate,
      maxDate,
      hasError,
      isDisabled,
    } = this.props
    return (
      <div className={classes.root}>
        {localeFile && (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeFile}>
            <DatePicker
              disabled={isDisabled}
              value={fromDate}
              onChange={this.handleDateChange}
              label={label}
              fullWidth={isFullWidth}
              minDate={minDate}
              maxDate={maxDate}
              error={hasError}
              InputProps={{
                endAdornment:
                  endIconName.length > 0 ? (
                    <InputAdornment position="end">
                      <span className={endIconName}></span>
                    </InputAdornment>
                  ) : null,
              }}
              labelFunc={this.formatWeekSelectLabel}
            />
          </MuiPickersUtilsProvider>
        )}
      </div>
    )
  }
}

SingleDateRangePicker.defaultProps = {
  className: '',
  locale: i18n.currentLocale(),
  label: '',
  isFullWidth: true,
  format: 'dd MMM',
  endIconName: '',
  minDate: new Date('1900-01-01'),
  maxDate: undefined,
  hasError: false,
  isDisabled: false,
}
SingleDateRangePicker.propsTypes = {
  className: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  locale: PropTypes.string,
  label: PropTypes.string,
  isFullWidth: PropTypes.bool,
  format: PropTypes.string,
  fromDate: PropTypes.instanceOf(Date).isRequired,
  toDate: PropTypes.instanceOf(Date).isRequired,
  endIconName: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
}
export default withStyles(styles)(SingleDateRangePicker)
