export const parseAttributes = attributes => {
  if (!attributes) return []
  return attributes.map(attribute => {
    return {
      desc: attribute.desc,
      name: attribute.name,
      id: `${attribute.id || ''}`,
      order: attribute.order,
    }
  })
}

export const transformAttributeServiceResponseDataWithState = jsonResponse => {
  const parsedAttributes = parseAttributes(jsonResponse)
  return {
    attributes: parsedAttributes,
  }
}
