export const parseInvestors = (investors) => {
  if (!investors) return []
  return investors.map((investor) => {
    return {
      photo: investor.photo,
      name: investor.fullname,
      email: investor.email,
      amountInvested: investor.amountInvested,
    }
  })
}

export const parsePosts = (investors) => {
  if (!investors) return []
  return investors.map((investor) => {
    return {
      photo: investor.photo,
      name: investor.fullname,
      email: investor.email,
      ideas: investor.ideas,
      deletedIdeas: investor.deletedIdeas,
    }
  })
}

export const parsePotentialInvestors = (richest) => {
  if (!richest) return []
  return richest.map((user) => {
    return {
      photo: user.photo,
      name: user.fullname,
      email: user.email,
      balance: user.balance,
    }
  })
}

export const parseIdeas = (ideas) => {
  if (!ideas) return []
  return ideas.map((idea) => {
    return {
      photo: idea.images[0].url,
      title: idea.title,
      id: idea.id,
      investment: idea.pledged,
    }
  })
}

export const parseIdeasInvestors = (ideas) => {
  if (!ideas) return []
  return ideas.map((idea) => {
    return {
      photo: idea.images[0].url,
      title: idea.title,
      id: idea.id,
      investors: idea.investors,
    }
  })
}

export const transformRankingServiceResponseDataWithState = (jsonResponse) => {
  const { investors, entrepeneurs, richest, ideaMoreInvested, ideaMoreInvestors } = jsonResponse
  return {
    topInvestors: parseInvestors(investors),
    topPosts: parsePosts(entrepeneurs),
    topPotentialInvestors: parsePotentialInvestors(richest),
    topIdeas: parseIdeas(ideaMoreInvested),
    topIdeasInvestors: parseIdeasInvestors(ideaMoreInvestors),
  }
}
