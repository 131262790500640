import React, { useState, Fragment } from 'react'
import { Slider } from '../../../../components'
import SliderCard from '../slider_card/View'

const ChallegerSlider = ({ challenges, classes, navigateToChallenge, navigateToIdea, isPrivate, customIdeaTitle }) => {
  const [activeStep, setActiveStep] = useState(0)

  const handleActiveStep = (value) => {
    setActiveStep(value)
  }

  return (
    <div className={classes.top}>
      <div className={classes.topCenter}>
        <Slider
          activeStep={activeStep}
          maxSteps={challenges.length}
          setActiveStep={(value) => handleActiveStep(value)}
          arrowsPosition="center"
          arrowsColor="rgba(255, 255, 255, 0.56)"
          arrowsSize="1.8em"
          arrowsBgColor="transparent"
          stepper={challenges.length > 1}
        >
          {challenges.map((card, index) => (
            <Fragment key={card.id}>
              {Math.abs(activeStep - index) <= 2 ? (
                <SliderCard
                  isPrivate={isPrivate}
                  card={card}
                  navigateToChallengeDetail={navigateToChallenge}
                  navigateToIdea={navigateToIdea}
                  customIdeaTitle={customIdeaTitle}
                />
              ) : null}
            </Fragment>
          ))}
        </Slider>
        <div className={classes.fill1} />
        <div className={classes.fill2} />
      </div>
    </div>
  )
}

export default ChallegerSlider