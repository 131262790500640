export const GET_INVESTMENTS_STARTED = 'GET_INVESTMENTS_STARTED'
export const GET_INVESTMENTS_SUCCESS = 'GET_INVESTMENTS_SUCCESS'
export const GET_INVESTMENTS_FAILURE = 'GET_INVESTMENTS_FAILURE'

export const EDIT_PROFILE_STARTED = 'EDIT_PROFILE_STARTED'
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS'
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE'

export const POST_REQUEST_INVESTMENT_STARTED = 'POST_REQUEST_INVESTMENT_STARTED'
export const POST_REQUEST_INVESTMENT_SUCCESS = 'POST_REQUEST_INVESTMENT_SUCCESS'
export const POST_REQUEST_INVESTMENT_FAILURE = 'POST_REQUEST_INVESTMENT_FAILURE'

export const GET_PORTFOLIO_INVESTMENTS_STARTED = 'GET_PORTFOLIO_INVESTMENTS_STARTED'
export const GET_PORTFOLIO_INVESTMENTS_SUCCESS = 'GET_PORTFOLIO_INVESTMENTS_SUCCESS'
export const GET_PORTFOLIO_INVESTMENTS_FAILURE = 'GET_PORTFOLIO_INVESTMENTS_FAILURE'

export const GET_USER_INFO_STARTED = 'GET_USER_INFO_STARTED'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE'

export const POST_USER_ACCEPT_LEGAL_STARTED = 'POST_USER_ACCEPT_LEGAL_STARTED'
export const POST_USER_ACCEPT_LEGAL_SUCCESS = 'POST_USER_ACCEPT_LEGAL_SUCCESS'
export const POST_USER_ACCEPT_LEGAL_FAILURE = 'POST_USER_ACCEPT_LEGAL_FAILURE'

export const POST_USER_DECLINE_LEGAL_STARTED = 'POST_USER_DECLINE_LEGAL_STARTED'
export const POST_USER_DECLINE_LEGAL_SUCCESS = 'POST_USER_DECLINE_LEGAL_SUCCESS'
export const POST_USER_DECLINE_LEGAL_FAILURE = 'POST_USER_DECLINE_LEGAL_FAILURE'
