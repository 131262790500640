import React, { Component, Fragment } from 'react'
import ReactList from 'react-list'
import * as Proptypes from 'prop-types'
import clsx from 'clsx'
import { withStyles, Button, List, ListItem, Divider } from '@material-ui/core'
import { Image } from 'components'
import defaultPhoto from 'img/default-photo.png'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import styles from 'pages/ranking/components/ranking_section/View.Style'

class RankingSection extends Component {
  constructor() {
    super()
    this.renderItem = this.renderItem.bind(this)
  }

  renderItem(idx, key) {
    const {
      classes,
      rows,
      linkText,
      linkAttribute,
      navigateToLink,
      amount,
      amountText,
      amountNavigateToLink,
      hasCurrency,
    } = this.props

    return (
      <Fragment>
        <ListItem key={key} className={classes.row}>
          <div className={classes.best}>
            {idx === 0 && <span className={clsx(classes.iconTop, 'icon-best')} />}
            <h1>{idx + 1}</h1>
          </div>
          <Image className={classes.photo} src={rows[idx].photo} defaultSrc={defaultPhoto} />
          <div>
            <Button
              id="button-link"
              onClick={() => navigateToLink(rows[idx][linkAttribute])}
              className={classes.link}
            >
              {rows[idx][linkText]}
            </Button>
            <p className={classes.rowDescription}>
              <span className={classes.descriptionText}>
                {typeof amountText === 'string' || amountText instanceof String 
                  ? amountText 
                  : amountText(rows[idx])
                }
              </span>
              {amountNavigateToLink && (
                <Button 
                  onClick={() => amountNavigateToLink(rows[idx][linkAttribute])}
                  disableRipple 
                  className={classes.descriptionButton}
                >
                  <span className="icon-more-funds" />
                </Button>
              )}
            </p>
          </div>
          <div className={classes.value}>
            {hasCurrency ? (
              <NxtVirtualCurrency>{rows[idx][amount]}</NxtVirtualCurrency>
            ) : (
              <span>{rows[idx][amount]}</span>
            )}
          </div>
        </ListItem>
        <Divider />
      </Fragment>
    )
  }

  render() {
    const { classes, icon, title, rows } = this.props
    return (
      <div className={classes.container}>
        <h3>
          <span className={clsx(classes.iconTitle, `${icon}`)} />
          {title}
        </h3>
        <Divider />
        <List className={classes.list}>
          <ReactList itemRenderer={this.renderItem} length={rows.length} />
        </List>
      </div>
    )
  }
}

RankingSection.defaultProps = {
  hasCurrency: false,
  rows: [],
  amountNavigateToLink: undefined,
}

RankingSection.propTypes = {
  rows: Proptypes.shape([]),
  linkAttribute: Proptypes.string.isRequired,
  linkText: Proptypes.string.isRequired,
  amount: Proptypes.string.isRequired,
  amountText: Proptypes.oneOfType([Proptypes.string, Proptypes.func]).isRequired,
  hasCurrency: Proptypes.bool,
  title: Proptypes.string.isRequired,
  icon: Proptypes.string.isRequired,

  amountNavigateToLink: Proptypes.func,
  navigateToLink: Proptypes.func.isRequired,
}

export default withStyles(styles)(RankingSection)
