import React, { Component } from 'react'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { ClickAwayListener, IconButton } from '@material-ui/core'
import defaultPhoto from 'img/default-photo.png'
import Image from 'components/image/View'
import SimpleMenu from 'components/simple_menu'
import NotificationsMenu from 'components/header/components/header_user_logo/components/notifications_menu/View'
import styles from './View.Style'

class HeaderUserLogo extends Component {
  constructor(props) {
    super(props)
    this.state = { showNavList: false, showNotificationsMenu: false }

    this.hideMenu = this.hideMenu.bind(this)
    this.hideNotifications = this.hideNotifications.bind(this)
  }

  hideMenu() {
    const { showNavList } = this.state
    if (showNavList) {
      this.setState({ showNavList: false })
    }
  }

  hideNotifications() {
    const { showNotificationsMenu } = this.state
    if (showNotificationsMenu) {
      this.setState({ showNotificationsMenu: false })
    }
  }

  render() {
    const { classes, userPhoto, notifications, showAdmin, markNotificationsRead, customIdeaTitles } = this.props
    const { showNavList, showNotificationsMenu } = this.state

    return (
      <div className={classes.root}>
        <Image
          id="user-image"
          className={classes.image}
          alt="user profile"
          src={userPhoto}
          defaultSrc={defaultPhoto}
        />
        <div className={classes.baseLogo}>
          <ClickAwayListener onClickAway={this.hideNotifications}>
            <div>
              <IconButton
                id="notifications-button"
                className={classes.button}
                onClick={() => this.setState({ showNotificationsMenu: true })}
              >
                <span className={clsx(classes.badge, classes.notifications)}>
                  {notifications.isLoading ? '...' : notifications.totalUnread}
                </span>
              </IconButton>
              {showNotificationsMenu && (
                <NotificationsMenu
                  id="notifications-menu"
                  notifications={notifications.notifications}
                  activities={notifications.activities}
                  unreadNotifications={notifications.unreadNotifications}
                  unreadActivities={notifications.unreadActivities}
                  markNotificationsRead={markNotificationsRead}
                  customIdeaTitles={customIdeaTitles}
                />
              )}
            </div>
          </ClickAwayListener>
          <ClickAwayListener onClickAway={this.hideMenu}>
            <div>
              <IconButton
                id="menu-button"
                className={classes.button}
                onClick={() => this.setState({ showNavList: !showNavList })}
              >
                <span className={clsx(classes.badge, classes.burger, 'icon-menu')} />
                {showNavList && <SimpleMenu id="user-menu" showAdmin={showAdmin} />}
              </IconButton>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    )
  }
}

HeaderUserLogo.defaultProps = {
  userPhoto: '',
  customIdeaTitles: {
    singularTitle: "",
    pluralTitle: ""
  }
}

HeaderUserLogo.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  notifications: PropTypes.shape({
    notifications: PropTypes.arrayOf(PropTypes.shape({})),
    activities: PropTypes.arrayOf(PropTypes.shape({})),
    totalUnread: PropTypes.number,
    unreadActivities: PropTypes.number,
    unreadNotifications: PropTypes.number,
  }).isRequired,
  showAdmin: PropTypes.bool.isRequired,
  userPhoto: PropTypes.string,
  markNotificationsRead: PropTypes.func.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  })
}

export default withStyles(styles)(HeaderUserLogo)
