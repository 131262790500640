const styles = () => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '37.5% 0',
  },
  outerCircle: {
    zIndex: 3,
    position: 'absolute',
  },
  innerCircle: {
    zIndex: 2,
    position: 'absolute',
  },
  children: {
    zIndex: 8,
  },
})

export default styles
