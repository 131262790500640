export const setEndpoint = ({
  baseUrl,
  organizations,
  group,
  types,
  attributes,
  status,
  challenge,
  goal,
  limit = 11,
  offset = 0,
}) => {
  let url = baseUrl
  url += `?${encodeURIComponent('limit')}=${limit}`
  url += `&${encodeURIComponent('offset')}=${offset}`
  if (organizations && organizations.length) {
    url += `&${encodeURIComponent('organizations')}=${organizations}`
  }
  if (group && group.length) {
    url += `&${encodeURIComponent('group')}=${group}`
  }
  if (types && types.length) {
    url += `&${encodeURIComponent('types')}=${types}`
  }
  if (attributes && attributes.length) {
    url += `&${encodeURIComponent('attributes')}=${attributes}`
  }
  if (status && status.length) {
    url += `&${encodeURIComponent('status')}=${status}`
  }
  if (challenge && challenge.length) {
    url += `&${encodeURIComponent('challenge')}=${challenge}`
  }
  if (goal && goal.length) {
    url += `&${encodeURIComponent('goal')}=${goal}`
  }

  return url
}

export const setDiscardIdeaBodyFromState = (reason, returnInvestment) => {
  return `${encodeURIComponent('dt')}=${reason}&${encodeURIComponent(
    'returnInvests',
  )}=${returnInvestment}`
}

export const setRequestBody = request =>
  `${encodeURIComponent('text')}=${encodeURIComponent(request)}`

export const setAdditionalFilters = ({ challengeId, shouldLoadPrivateIdeas }) => {
  let url = challengeId
    ? `&${encodeURIComponent('challenge')}=${encodeURIComponent(challengeId)}`
    : ''
  if (shouldLoadPrivateIdeas) {
    url += `&${encodeURIComponent('private')}=${encodeURIComponent(true)}`
  }
  return url
}

export const setVisitsBody = ideaKey =>
  `${encodeURIComponent('key')}=${ideaKey}`
