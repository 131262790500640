import React, { Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import RankingColumn from './components/ranking_column/View'
import styles from './View.Style'
import IdeasColumn from './components/ideas_column/View'

const ExploreBottom = (props) => {
  const {
    classes,
    footerInvestors,
    footerEntrepreneurs,
    headerSection,
    helpList,
    navigateToExternal,
    navigateToIdea,
    navigateToRanking,
    navigateToUserProfile,
    showRanking,
    customIdeaTitles
  } = props

  const showHelp = helpList.length > 0

  return (
    <>
      <div className={classes.exploreBottomCenter}>
        <div className={classes.left}>
          <IdeasColumn
            classes={classes}
            headerSection={headerSection}
            helpList={helpList}
            showHelp={showHelp}
            navigateToExternal={navigateToExternal}
            navigateToIdea={navigateToIdea}
            customIdeaTitle={customIdeaTitles.singularTitle}
          />
        </div>
        {showRanking && (
          <div className={classes.center}>
            <RankingColumn
              classes={classes}
              footerInvestors={footerInvestors}
              footerEntrepreneurs={footerEntrepreneurs}
              navigateToRanking={navigateToRanking}
              navigateToUserProfile={navigateToUserProfile}
              customIdeaTitle={customIdeaTitles.pluralTitle}
            />
          </div>
        )}
      </div>
    </>
  )
}

ExploreBottom.defaultProps = {
  headerSection: null,
  customIdeaTitles: {
    singularTitle: "",
    pluralTitle: ""
  }
}

ExploreBottom.propTypes = {
  classes: PropTypes.shape({
    data: PropTypes.string.isRequired,
    dataList: PropTypes.string.isRequired,
    dataItem: PropTypes.string.isRequired,
    earnList: PropTypes.string.isRequired,
    exploreBottomCenter: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
    center: PropTypes.string.isRequired,
    right: PropTypes.string.isRequired,
    helpList: PropTypes.string.isRequired,
    helpItem: PropTypes.string.isRequired,
    investedAmount: PropTypes.string.isRequired,
    lineColor: PropTypes.string.isRequired,
    lineRoot: PropTypes.string.isRequired,
    linkButton: PropTypes.string.isRequired,
    seeAll: PropTypes.string.isRequired,
    tabs: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    typographyRoot: PropTypes.string.isRequired,
    userAvatar: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }).isRequired,
  footerInvestors: PropTypes.arrayOf(
    PropTypes.shape({
      amountInvested: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      fullname: PropTypes.string.isRequired,
      ideas: PropTypes.number.isRequired,
      investments: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
    }),
  ).isRequired,
  footerEntrepreneurs: PropTypes.arrayOf(
    PropTypes.shape({
      amountInvested: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      fullname: PropTypes.string.isRequired,
      ideas: PropTypes.number.isRequired,
      investments: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
    }),
  ).isRequired,
  headerSection: PropTypes.shape({}),
  helpList: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string.isRequired,
      header: PropTypes.bool.isRequired,
      ico: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  navigateToRanking: PropTypes.func.isRequired,
  navigateToUserProfile: PropTypes.func.isRequired,
  navigateToExternal: PropTypes.func.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  showRanking: PropTypes.bool.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  })
}

export default withStyles(styles)(ExploreBottom)
