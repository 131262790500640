import React from 'react'
import * as PropTypes from 'prop-types'
import { FormControlLabel } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { ButtonLink, NxtRadio } from 'components'
import styles from './View.Style'

const RadioItem = props => {
  const { classes, linkName, item, navigateTo, onRadioClick } = props
  return (
    <FormControlLabel
      className={classes.container}
      classes={{ root: classes.marginLeft || '' }}
      key={item.id}
      control={<NxtRadio value={`${item.id}`} />}
      onClick={onRadioClick}
      label={
        <div className={navigateTo ? classes.radioLabelWithLink : classes.radioLabel}>
          {item.name}
          {navigateTo && (
            <ButtonLink onLinkClick={() => navigateTo(item.id)}>{linkName}</ButtonLink>
          )}
        </div>
      }
      labelPlacement="end"
    />
  )
}

RadioItem.defaultProps = {
  navigateTo: null,
  linkName: '',
}

RadioItem.propTypes = {
  classes: PropTypes.shape({
    marginLeft: PropTypes.string,
    radioLabelWithLink: PropTypes.string,
    radioLabel: PropTypes.string,
  }).isRequired,
  navigateTo: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  linkName: PropTypes.string,
  onRadioClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(RadioItem)
