import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import { CircularProgress, FormControlLabel, IconButton } from '@material-ui/core'
import DataProtection from 'components/data_protection/View'
import i18n from 'support/i18n'
import styles from 'pages/profile/components/user_detail_section/components/edit_my_profile_dialog/View.Style'
import ProfileImagePicker from 'components/profile_image_picker/View'
import { isValidPassword } from 'support/utils/auth'

class EditUserDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.myName,
      lastName: props.myLastName,
      photo: props.myPhotoSrc,
      password: '',
      newPassword: '',
      repeatNewPassword: '',
      acceptDataProtection: false,
    }
    this.handleOnChangeEvent = this.handleOnChangeEvent.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.setImageBase64 = this.setImageBase64.bind(this)
  }

  componentDidMount() {
    const { getGDPR } = this.props
    getGDPR()
  }

  onSubmit() {
    const { name, lastName, photo, password, newPassword, repeatNewPassword } = this.state
    const { onSubmit, showErrorOnSubmit } = this.props
    let error = false

    if (name === '' || lastName === '') {
      error = i18n.t('PROFILE.INVALID_FIRST_LAST')
    } else if (newPassword && !password) {
      error = i18n.t('PROFILE.INVALID_OLD_PASSWORD')
    } else if (password && newPassword !== repeatNewPassword) {
      error = i18n.t('PROFILE.INVALID_DIFFERENT_PASSWORD')
    } else if (password && !isValidPassword(newPassword)) {
      error = i18n.t('PROFILE.INVALID_PASSWORD')
    }
    if (error) {
      showErrorOnSubmit(error)
    } else {
      onSubmit(name, lastName, photo, password, newPassword)
    }
  }

  setImageBase64(imageBase64) {
    this.setState({ photo: imageBase64 })
  }

  handleOnChangeEvent(event, key) {
    const { value } = event.target
    this.setState({ [key]: value })
  }

  render() {
    const { name, lastName, photo, acceptDataProtection } = this.state
    const { classes, onSelectImage, onClose, isEditUserLoading, gdpr, customIdeaTitles } = this.props

    return (
      <div className={classes.container}>
        <div className={classes.icon}>
          <IconButton
            aria-label="delete"
            onClick={onClose}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <span className="icon-close" />
          </IconButton>
        </div>
        <div className={classes.mainProfile}>
          <DialogContent className={classes.columnInputs}>
            <h3>{i18n.t('PROFILE.YOUR_NAME')}</h3>
            <TextField
              id="nameInput"
              label={i18n.t('PROFILE.FIRST')}
              value={name}
              onChange={event => {
                this.handleOnChangeEvent(event, 'name')
              }}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="lastNameInput"
              label={i18n.t('PROFILE.LAST')}
              value={lastName}
              onChange={event => {
                this.handleOnChangeEvent(event, 'lastName')
              }}
              margin="normal"
              variant="outlined"
            />
          </DialogContent>
          <ProfileImagePicker
            image={photo}
            setImageBase64={this.setImageBase64}
            onSelectImage={onSelectImage}
            onClose={onClose}
          />
        </div>

        <DialogContent>
          <h3>{i18n.t('PROFILE.YOUR_PASSWORD')}</h3>
          <span className={classes.text}>{i18n.t('PROFILE.LEAVE_BLANK')}</span>
          <div className={classes.passwordInputs}>
            <TextField
              id="passwordInput"
              type="password"
              label={i18n.t('PROFILE.OLD_PASSWORD')}
              onChange={event => {
                this.handleOnChangeEvent(event, 'password')
              }}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="newPasswordInput"
              type="password"
              label={i18n.t('PROFILE.NEW_PASSWORD')}
              onChange={event => {
                this.handleOnChangeEvent(event, 'newPassword')
              }}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="repeatNewPasswordInput"
              type="password"
              label={i18n.t('PROFILE.RETYPE_PASSWORD')}
              onChange={event => {
                this.handleOnChangeEvent(event, 'repeatNewPassword')
              }}
              margin="normal"
              variant="outlined"
            />
          </div>

          <FormControlLabel
            id="acceptDataProtection"
            control={
              <Checkbox
                color="primary"
                checked={acceptDataProtection}
                onChange={() => this.setState({ acceptDataProtection: !acceptDataProtection })}
              />
            }
            label={i18n.t('TERMS_AND_CONDITIONS_TABLE.CHECKBOX')}
          />
        </DialogContent>

        <DialogActions className={classes.buttonsContainer}>
          <Button
            onClick={this.onSubmit}
            color="primary"
            variant="contained"
            disabled={!acceptDataProtection || isEditUserLoading}
            autoFocus
          >
            {i18n.t('PROFILE.SAVE_CHANGES')}
            {isEditUserLoading && (
              <CircularProgress className={classes.spinner} size={20} color="primary" />
            )}
          </Button>
          <Button onClick={onClose} variant="contained">
            {i18n.t('CANCEL')}
          </Button>
        </DialogActions>

        <DialogContent>
          <DataProtection data={gdpr} customIdeaTitles={customIdeaTitles} />
        </DialogContent>
      </div>
    )
  }
}

EditUserDialog.propTypes = {
  myName: PropTypes.string.isRequired,
  myLastName: PropTypes.string.isRequired,
  myPhotoSrc: PropTypes.string,
  isEditUserLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showErrorOnSubmit: PropTypes.func.isRequired,
  gdpr: {
    id: null,
    language: '',
    responsable: '',
    purpose: '',
    legitimation: '',
    recipients: '',
    rights: '',
    additionalinformation: '',
    responsableUrl: '',
    purposeUrl: '',
    legitimationUrl: '',
    recipientsUrl: '',
    rightsUrl: '',
    additionalinformationUrl: '',
    gdprHtml: '',
    customIdeaTitle: ''
  }
}

EditUserDialog.defaultProps = {
  myPhotoSrc: '',
  gdpr: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number,
    language: PropTypes.string,
    responsable: PropTypes.string,
    purpose: PropTypes.string,
    legitimation: PropTypes.string,
    recipients: PropTypes.string,
    rights: PropTypes.string,
    additionalinformation: PropTypes.string,
    responsableUrl: PropTypes.string,
    purposeUrl: PropTypes.string,
    legitimationUrl: PropTypes.string,
    recipientsUrl: PropTypes.string,
    rightsUrl: PropTypes.string,
    additionalinformationUrl: PropTypes.string,
    gdprHtml: PropTypes.string,
    customIdeaTitles: PropTypes.shape({
      singularTitle: PropTypes.string,
      pluralTitle: PropTypes.string
    }).isRequired
  })),
}

export default withStyles(styles)(EditUserDialog)
