export const HELP_SECTION_STARTED = 'HELP_SECTION_STARTED'
export const HELP_SECTION_SUCCESS = 'HELP_SECTION_SUCCESS'
export const HELP_SECTION_FAILURE = 'HELP_SECTION_FAILURE'

export const RANKING_STARTED = 'RANKING_STARTED'
export const RANKING_SUCCESS = 'RANKING_SUCCESS'
export const RANKING_FAILURE = 'RANKING_FAILURE'

export const HEADER_SECTION_STARTED = 'HEADER_SECTION_STARTED'
export const HEADER_SECTION_SUCCESS = 'HEADER_SECTION_SUCCESS'
export const HEADER_SECTION_FAILURE = 'HEADER_SECTION_FAILURE'
