const styles = theme => ({
  root: {},
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 500,
    marginBottom: 0,
    '& > span': {
      fontSize: '20px',
      marginRight: theme.spacing(1),
    },
  },
  pendingUsersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& img': {
      '-webkit-filter': 'grayscale(1)',
      filter: 'grayscale(1)',
    },
  },
})

export default styles
