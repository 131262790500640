import React from 'react'
import { withStyles, Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import NxtDatePicker from 'components/nxt_date_picker/View'
import * as PropTypes from 'prop-types'
import styles from './View.Style'

const ActivePeriod = (props) => {
  const {
    classes,
    fromDate,
    toDate,
    onSelectPreviewToDate,
    onSelectPreviewFromDate,
    hasToDateError,
    isFromDateDisable,
    customIdeaTitles
  } = props

  return (
    <div className={classes.root}>
      <Paper id="info-message-box" className={classes.infoMessageBox}>
        <div className={classes.infoMessageContainer}>{i18n.t('CHALLENGES.ACTIVE_STATUS')}</div>
      </Paper>
      <div className={classes.datesContainer}>
        <NxtDatePicker
          isDisabled={isFromDateDisable}
          id="date-picker-from"
          onDateChange={onSelectPreviewFromDate}
          value={fromDate}
          label={i18n.t('CHALLENGES.FROM')}
          endIconName="icon-th"
          minDate={new Date()}
        />
        <NxtDatePicker
          id="date-picker-to"
          onDateChange={onSelectPreviewToDate}
          value={toDate}
          label={i18n.t('CHALLENGES.TO')}
          endIconName="icon-th"
          minDate={fromDate}
          hasError={hasToDateError}
        />
      </div>
      <div className={classes.dateInfo}>{i18n.t('CHALLENGES.ACTIVE_INFO', { title: customIdeaTitles.pluralTitle || "ideas" })}</div>
    </div>
  )
}
ActivePeriod.defaultProps = {
  toDate: null,
  hasToDateError: false,
}
ActivePeriod.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    infoMessageBox: PropTypes.string,
    infoMessageContainer: PropTypes.string,
    datesContainer: PropTypes.string,
    dateInfo: PropTypes.string,
  }).isRequired,
  fromDate: PropTypes.instanceOf(Date).isRequired,
  toDate: PropTypes.instanceOf(Date),
  onSelectPreviewFromDate: PropTypes.func.isRequired,
  onSelectPreviewToDate: PropTypes.func.isRequired,
  hasToDateError: PropTypes.bool,
  isFromDateDisable: PropTypes.bool.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}
export default withStyles(styles)(ActivePeriod)
