export const setContent = (email, password) =>
  `${encodeURIComponent('email')}=${encodeURIComponent(email)}&${encodeURIComponent(
    'pwd',
  )}=${encodeURIComponent(password)}`

export const setNewPassword = password =>
  `${encodeURIComponent('pwd')}=${encodeURIComponent(password)}`

export const setGoogleCode = code => `${encodeURIComponent('code')}=${encodeURIComponent(code)}`

export const setMicrosoftParams = (email, code) =>
  `${encodeURIComponent('email')}=${encodeURIComponent(email)}&${encodeURIComponent(
    'code',
  )}=${encodeURIComponent(code)}`

export const setUserDetails = (firstName, lastName, base64Photo) => {
  const photoString = base64Photo
    ? `${encodeURIComponent('photo')}=${encodeURIComponent(base64Photo)}&`
    : ''
  return `${photoString}${encodeURIComponent('first')}=${encodeURIComponent(
    firstName,
  )}&${encodeURIComponent('last')}=${encodeURIComponent(lastName)}&${encodeURIComponent(
    'linkedin',
  )}=false`
}
