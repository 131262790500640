import * as types from 'actions/sync/create_idea_form/Constants'
import * as videoTypes from 'actions/sync/video/Constants'
import { TYPE_OF_TIME } from 'common_constants/Selects'
import { DELETE_FILE_SUCCESS, POST_FILE_SUCCESS } from 'services/standardForm/constants'
import { REQUEST_JOIN_IDEA_SUCCESS } from '../actions/sync/ideas/ideasActionConstans'

export const initialState = {
  title: '',
  images: [],
  description: '',
  videos: {
    externalVideo: '',
    fileVideo: {
      blobKey: '',
      bucket: '',
      created: '',
      id: 0,
      key: '',
      name: '',
      size: 0,
      type: '',
    },
    fileVideoUrl: '',
    fileVideoType: '',
    fileVideoId: '',
    isVideoLoading: false,
    isVideoReady: false,
    isVideoRemoving: false,
    hasVideoError: false,
    isGeneratinVideoUploadUrl: false,
    storageVideoUploadUrl: '',
  },
  challenge: {
    name: '',
    imgSrc: '',
    id: '',
  },
  challengeOrganization: '',
  errors: {},
  organization: {},
  type: [],
  attribute: [],
  goals: {},
  pilotEstimatedQuantity: '',
  pilotEstimatedHours: '',
  pilotEstimatedTime: '',
  pilotEstimatedTimeType: TYPE_OF_TIME.find(item => item.id === 'days').id,
  implementEstimatedQuantity: '',
  implementEstimatedHours: '',
  implementEstimatedTime: '',
  implementEstimatedTimeType: TYPE_OF_TIME.find(item => item.id === 'days').id,
  economicBenefits: '',
  otherBenefits: '',
  documents: [],
  initialInvestment: '0',
  isPendingValidation: false,
  status: '',
  statusMessageText: '',
  statusMessageTitle: '',
  investors: [],
  team: [],
  pilotTeam: [],
  pendingInvitationsUsers: [],
  pendingInvitationsPilotTeamUsers: [],
  contributors: [],
  ownContribution: {
    userId: '',
    budget: '',
    time: '',
    other: '',
  },
  investment: {
    seedInvestors: [],
    firstRoundInvestors: [],
    secondRoundInvestors: [],
    thirdRoundInvestors: [],
  },
  userInvestmentsMap: {},
  submitter: {
    fullName: '',
    photo: '',
    email: '',
  },
  promoter: {
    fullName: '',
    photo: '',
    email: '',
  },
  owner: '',
  maxInvestment: 0,
  minInvestment: 0,
  backendErrorMessage: '',
  isTitleLoading: false,
  isTitleAvailable: true,
  isDocumentLoading: false,
  hasLoaded: false,
  isLoading: false,
  isInvestmentInProcess: false,
  endDate: undefined,
  createdDate: undefined,
  singleId: '',
  totalInvestors: '0',
  pledged: '0',
  goal: '0',
  visits: '0',
  canSendIdeaTeamInvitations: false,
  canSendPilotProjectTeamInvitations: false,
  isLoadingAskForInvestments: false,
  isLoadingShareIdea: false,
  isLoadingPendingIdea: false,
  showPendingTeamInvitationSection: false,
  showPendingInnovationTeamInvitationSection: false,
  isProcessingTeamInvitation: false,
  isProcessingInnovationTeamInvitation: false,
  postingCommentStatus: '',
  comments: [],
  totalComments: 0,
  isDraft: false,
  isPrivate: false,
  isAlreadyInvited: false,
  isAnonymous: false,
  kpis: [],
  isFormInUse: false,
  creatingIdea: false,
  fileToUpload: {
    uploadUrl: '',
    file: {
      path: '',
      lastModified: undefined,
      name: '',
      size: undefined,
      type: '',
    },
  },
  challengeStatus: '',
}

const createIdeaFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_IDEA_FORM:
      return initialState

    case types.SET_IDEA_FORM_TITLE:
      return {
        ...state,
        title: action.value,
      }

    case types.SET_IDEA_FORM_DESCRIPTION:
      return {
        ...state,
        description: action.value,
      }
    case types.ADD_IMAGE_SUCCESS:
      return {
        ...state,
        images: [...state.images, action.response],
        errors: {
          ...state.errors,
          imageUploadError: '',
        },
      }
    case types.SET_IDEA_FORM_IMAGES:
      return {
        ...state,
        images: action.images,
      }
    case types.ADD_IMAGE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          imageUploadError: action.value,
        },
      }
    case types.SET_IDEA_FORM_VIDEO:
      return {
        ...state,
        videos: {
          ...state.videos,
          externalVideo: action.value,
        },
      }
    case videoTypes.POST_UPLOAD_IDEA_VIDEO_STARTED:
      return {
        ...state,
        videos: {
          ...state.videos,
          isVideoLoading: true,
        },
      }
    case videoTypes.POST_UPLOAD_IDEA_VIDEO_SUCCESS:
      return {
        ...state,
        videos: {
          ...state.videos,
          isVideoLoading: false,
          isVideoReady: action.response.isVideoReady,
          fileVideo: action.response.video,
          fileVideoId: action.response.videoId,
        },
      }
    case videoTypes.POST_UPLOAD_IDEA_VIDEO_FAILURE:
      return {
        ...state,
        videos: {
          ...state.videos,
          isVideoLoading: false,
        },
      }
    case videoTypes.GET_CONFIRMATION_IDEA_VIDEO_SUCCESS:
      return {
        ...state,
        videos: {
          ...state.videos,
          isVideoReady: action.response.isVideoReady,
          hasVideoError: action.response.hasVideoError,
        },
      }
    case videoTypes.DELETE_IDEA_VIDEO_STARTED:
      return {
        ...state,
        videos: {
          ...state.videos,
          isVideoRemoving: true,
        },
      }
    case videoTypes.DELETE_IDEA_VIDEO_SUCCESS:
      return {
        ...state,
        videos: {
          ...state.videos,
          isVideoRemoving: false,
          externalVideo: '',
          fileVideo: '',
          fileVideoId: '',
          isVideoLoading: false,
          isVideoReady: false,
          hasVideoError: false,
        },
      }
    case videoTypes.POST_STORAGE_VIDEO_UPLOAD_URL_STARTED:
      return {
        ...state,
        videos: {
          ...state.videos,
          isGeneratinVideoUploadUrl: true,
        },
      }
    case videoTypes.POST_STORAGE_VIDEO_UPLOAD_URL_SUCCESS:
      return {
        ...state,
        videos: {
          ...state.videos,
          isGeneratinVideoUploadUrl: false,
          storageVideoUploadUrl: action.response.storageVideoUploadUrl,
        },
      }
    case videoTypes.POST_STORAGE_VIDEO_UPLOAD_URL_FAILURE:
      return {
        ...state,
        videos: {
          ...state.videos,
          isGeneratinVideoUploadUrl: false,
        },
      }
    case videoTypes.POST_UPLOAD_IDEA_STORAGE_VIDEO_STARTED:
      return {
        ...state,
        videos: {
          ...state.videos,
          isVideoLoading: true,
        },
      }
    case videoTypes.POST_UPLOAD_IDEA_STORAGE_VIDEO_SUCCESS:
      return {
        ...state,
        videos: {
          ...state.videos,
          isVideoLoading: false,
          isVideoReady: true,
          fileVideo: action.response.video,
          fileVideoUrl: action.response.videoUrl,
        },
      }
    case videoTypes.POST_UPLOAD_IDEA_STORAGE_VIDEO_FAILURE:
      return {
        ...state,
        videos: {
          ...state.videos,
          isVideoLoading: false,
        },
      }
    case videoTypes.RESET_IDEA_STORAGE_VIDEO:
      return {
        ...state,
        videos: {
          ...state.videos,
          fileVideo: '',
          fileVideoUrl: '',
          fileVideoId: '',
          isVideoLoading: false,
          isVideoReady: false,
          isVideoRemoving: false,
          hasVideoError: false,
          isGeneratinVideoUploadUrl: false,
          storageVideoUploadUrl: '',
        },
      }
    case types.SET_IDEA_FORM_GOAL:
      return {
        ...state,
        goals: { ...state.goals, ...action.value },
      }
    case types.SET_IDEA_FORM_CHALLENGE:
      return {
        ...state,
        challenge: action.value,
      }
    case types.SET_IDEA_FORM_ORGANIZATION:
      return {
        ...state,
        organization: action.value,
      }
    case types.SET_IDEA_FORM_TYPE:
      return {
        ...state,
        type: action.value,
      }
    case types.SET_IDEA_FORM_PILOT_ESTIMATED_QUANTITY:
      return {
        ...state,
        pilotEstimatedQuantity: action.value,
      }
    case types.SET_IDEA_FORM_PILOT_ESTIMATED_HOURS:
      return {
        ...state,
        pilotEstimatedHours: action.value,
      }
    case types.SET_IDEA_FORM_PILOT_ESTIMATED_TIME:
      return {
        ...state,
        pilotEstimatedTime: action.value,
      }
    case types.SET_IDEA_FORM_PILOT_ESTIMATED_TIME_TYPE:
      return {
        ...state,
        pilotEstimatedTimeType: action.value,
      }
    case types.SET_IDEA_FORM_IMPLEMENT_ESTIMATED_QUANTITY:
      return {
        ...state,
        implementEstimatedQuantity: action.value,
      }
    case types.SET_IDEA_FORM_IMPLEMENT_ESTIMATED_HOURS:
      return {
        ...state,
        implementEstimatedHours: action.value,
      }
    case types.SET_IDEA_FORM_IMPLEMENT_ESTIMATED_TIME:
      return {
        ...state,
        implementEstimatedTime: action.value,
      }
    case types.SET_IDEA_FORM_IMPLEMENT_ESTIMATED_TIME_TYPE:
      return {
        ...state,
        implementEstimatedTimeType: action.value,
      }
    case types.SET_IDEA_FORM_ECONOMIC_BENEFITS:
      return {
        ...state,
        economicBenefits: action.value,
      }
    case types.SET_IDEA_FORM_OTHER_BENEFITS:
      return {
        ...state,
        otherBenefits: action.value,
      }
    case types.SEARCH_TITLE_STARTED:
      return {
        ...state,
        isTitleLoading: true,
      }
    case types.SEARCH_TITLE_SUCCESS:
      return {
        ...state,
        isTitleAvailable: action.response,
        isTitleLoading: false,
      }
    case types.SEARCH_TITLE_FAILURE:
      return {
        ...state,
        isTitleLoading: false,
        isTitleAvailable: false,
      }
    case types.UPLOAD_DOCUMENT_STARTED:
      return {
        ...state,
        isDocumentLoading: true,
        errors: {
          ...state.errors,
          documentError: '',
        },
      }
    case types.UPLOAD_DOCUMENT_SUCCESS:
    case POST_FILE_SUCCESS:
      return {
        ...state,
        isDocumentLoading: false,
        errors: {
          ...state.errors,
          documentError: '',
        },
        documents: [action.response, ...state.documents],
      }
    case types.UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        errors: {
          ...state.errors,
          documentError: action.value,
        },
      }
    case types.DELETE_IDEA_DOCUMENT_SUCCESS:
    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        documents: action.response,
      }
    case types.SET_IDEA_FORM_INITIAL_INVESTMENT:
      return {
        ...state,
        initialInvestment: action.value,
      }
    case types.GET_IDEA_STARTED:
      return {
        ...state,
        isLoading: true,
        hasLoaded: false,
      }
    case types.GET_IDEA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        ...action.response,
      }
    case types.GET_KPIS_SUCCESS:
      return {
        ...state,
        ...action.response,
      }
    case types.SET_IDEA_CONTRIBUTIONS_SUCCESS:
      return {
        ...state,
        contributors: action.response.contributors,
        ownContribution: action.response.ownContribution,
      }
    case types.SET_CONTRIBUTION_TIME:
      return {
        ...state,
        ownContribution: {
          ...state.ownContribution,
          time: action.value,
        },
      }
    case types.SET_CONTRIBUTION_BUDGET:
      return {
        ...state,
        ownContribution: {
          ...state.ownContribution,
          budget: action.value,
        },
      }
    case types.SET_CONTRIBUTION_OTHER:
      return {
        ...state,
        ownContribution: {
          ...state.ownContribution,
          other: action.value,
        },
      }
    case types.GET_IDEA_INVESTORS_SUCCESS:
      return {
        ...state,
        investment: action.response.investment,
        userInvestmentsMap: action.response.userInvestmentsMap,
      }
    case types.POST_ASK_FOR_INVESTMENTS_STARTED:
      return {
        ...state,
        isLoadingAskForInvestments: true,
      }
    case types.POST_ASK_FOR_INVESTMENTS_FAILURE:
    case types.POST_ASK_FOR_INVESTMENTS_SUCCESS:
      return {
        ...state,
        isLoadingAskForInvestments: false,
      }
    case types.SHARE_IDEA_STARTED:
      return {
        ...state,
        isLoadingShareIdea: true,
      }
    case types.SHARE_IDEA_FAILURE:
    case types.SHARE_IDEA_SUCCESS:
      return {
        ...state,
        isLoadingShareIdea: false,
      }
    case types.POST_PUBLISH_DRAFT_STARTED:
    case types.POST_INVEST_IDEA_STARTED:
      return {
        ...state,
        isInvestmentInProcess: true,
      }
    case types.POST_INVEST_IDEA_SUCCESS:
      return {
        ...state,
        maxInvestment: action.response.maxInvestment,
        isInvestmentInProcess: false,
        pledged: action.response.pledged,
      }
    case types.POST_PUBLISH_DRAFT_FAILURE:
    case types.POST_INVEST_IDEA_FAILURE: {
      return {
        ...state,
        isInvestmentInProcess: false,
      }
    }
    case types.POST_PUBLISH_DRAFT_SUCCESS: {
      return {
        ...state,
        isInvestmentInProcess: false,
      }
    }
    case types.GET_IDEA_TEAM_SUCCESS: {
      return {
        ...state,
        pendingInvitationsUsers: action.response.pendingInvitationsUsers,
        pendingInvitationsPilotTeamUsers: action.response.pendingInvitationsPilotTeamUsers,
      }
    }
    case types.GET_IS_USER_INVITED_TO_INNOVATION_TEAM_SUCCESS: {
      return {
        ...state,
        showPendingInnovationTeamInvitationSection: action.response,
      }
    }
    case types.GET_IS_USER_INVITED_TO_TEAM_SUCCESS: {
      return {
        ...state,
        showPendingTeamInvitationSection: action.response,
      }
    }
    case types.POST_ACCEPT_JOIN_INNOVATION_TEAM_STARTED:
    case types.POST_REJECT_JOIN_INNOVATION_TEAM_STARTED: {
      return {
        ...state,
        isProcessingInnovationTeamInvitation: true,
      }
    }
    case types.POST_ACCEPT_JOIN_INNOVATION_TEAM_FAILURE:
    case types.POST_REJECT_JOIN_INNOVATION_TEAM_FAILURE: {
      return {
        ...state,
        isProcessingInnovationTeamInvitation: false,
      }
    }
    case types.POST_ACCEPT_JOIN_INNOVATION_TEAM_SUCCESS:
    case types.POST_REJECT_JOIN_INNOVATION_TEAM_SUCCESS: {
      return {
        ...state,
        showPendingInnovationTeamInvitationSection: false,
        isProcessingInnovationTeamInvitation: false,
      }
    }
    case types.POST_ACCEPT_JOIN_IDEA_TEAM_STARTED:
    case types.POST_REJECT_JOIN_IDEA_TEAM_STARTED: {
      return {
        ...state,
        isProcessingTeamInvitation: true,
      }
    }
    case types.POST_ACCEPT_JOIN_IDEA_TEAM_FAILURE:
    case types.POST_REJECT_JOIN_IDEA_TEAM_FAILURE: {
      return {
        ...state,
        isProcessingTeamInvitation: false,
      }
    }
    case types.POST_ACCEPT_JOIN_IDEA_TEAM_SUCCESS:
    case types.POST_REJECT_JOIN_IDEA_TEAM_SUCCESS: {
      return {
        ...state,
        showPendingTeamInvitationSection: false,
        isProcessingTeamInvitation: false,
      }
    }
    case types.RESET_IDEA_STATE: {
      return {
        ...state,
        hasLoaded: false,
        isLoading: false,
      }
    }
    case types.POST_IDEA_COMMENT_STARTED: {
      return {
        ...state,
        postingCommentStatus: 'loading',
      }
    }
    case types.POST_IDEA_COMMENT_SUCCESS: {
      return {
        ...state,
        postingCommentStatus: 'success',
      }
    }
    case types.POST_IDEA_COMMENT_FAILURE: {
      return {
        ...state,
        postingCommentStatus: 'failure',
      }
    }
    case types.GET_IDEA_COMMENTS_SUCCESS: {
      return {
        ...state,
        comments: action.response.comments,
        totalComments: action.response.totalComments,
      }
    }
    case types.DELETE_IDEA_STARTED:
    case types.POST_VALIDATE_IDEA_STARTED: {
      return {
        ...state,
        isLoadingPendingIdea: true,
      }
    }
    case types.DELETE_IDEA_FAILURE:
    case types.DELETE_IDEA_SUCCESS:
    case types.POST_VALIDATE_IDEA_FAILURE:
    case types.POST_VALIDATE_IDEA_SUCCESS: {
      return {
        ...state,
        isLoadingPendingIdea: false,
      }
    }

    case types.GET_IS_TEAM_REQUEST_ACTIVE_SUCCESS: {
      return {
        ...state,
        isAlreadyInvited: action.response,
      }
    }
    case REQUEST_JOIN_IDEA_SUCCESS: {
      return {
        ...state,
        isAlreadyInvited: true,
      }
    }
    case types.POST_KPIS_STARTED:
    case types.POST_NEW_KPI_STARTED: {
      return {
        ...state,
        isFormInUse: true,
      }
    }
    case types.POST_KPIS_SUCCESS:
    case types.POST_KPIS_FAILURE:
    case types.POST_NEW_KPI_SUCCESS:
    case types.POST_NEW_KPI_FAILURE: {
      return {
        ...state,
        isFormInUse: false,
      }
    }

    case types.EDIT_IDEA_STARTED:
    case types.CREATE_IDEA_STARTED: {
      return {
        ...state,
        creatingIdea: true,
      }
    }
    case types.EDIT_IDEA_SUCCESS:
    case types.CREATE_IDEA_SUCCESS: {
      return {
        ...state,
        creatingIdea: false,
      }
    }
    case types.EDIT_IDEA_FAILURE:
    case types.CREATE_IDEA_FAILURE: {
      return {
        ...state,
        creatingIdea: false,
        otherBenefits: action.value,
      }
    }
    case types.POST_FILE_UPLOAD_URL_STARTED: {
      return {
        ...state,
        isDocumentLoading: true,
      }
    }
    case types.POST_FILE_UPLOAD_URL_SUCCESS: {
      return {
        ...state,
        isDocumentLoading: false,
        fileToUpload: {
          uploadUrl: action.response.uploadUrl,
          file: action.response.file,
        },
      }
    }
    case types.POST_FILE_UPLOAD_URL_FAILURE: {
      return {
        ...state,
        isDocumentLoading: false,
        fileToUpload: {
          uploadUrl: '',
          file: {
            path: '',
            lastModified: undefined,
            name: '',
            size: undefined,
            type: '',
          },
        },
      }
    }
    case types.POST_UPLOAD_FILE_STARTED: {
      return {
        ...state,
        isDocumentLoading: true,
      }
    }
    case types.POST_UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        isDocumentLoading: false,
        documents: [...state.documents, action.response.document],
        fileToUpload: {
          uploadUrl: '',
          file: {
            path: '',
            lastModified: undefined,
            name: '',
            size: undefined,
            type: '',
          },
        },
      }
    }
    case types.POST_UPLOAD_FILE_FAILURE: {
      return {
        ...state,
        isDocumentLoading: false,
        fileToUpload: {
          uploadUrl: '',
          file: {
            path: '',
            lastModified: undefined,
            name: '',
            size: undefined,
            type: '',
          },
        },
      }
    }
    case types.REMOVE_IDEA_FILE: {
      return {
        ...state,
        documents: state.documents.filter(doc => doc.key !== action.fileKey),
      }
    }
    case videoTypes.SET_EXTERNAL_VIDEO: {
      return {
        ...state,
        videos: {
          ...state.videos,
          externalVideo: action.src,
        },
      }
    }
    case videoTypes.GET_IDEA_VIDEO_TYPE_SUCCESS: {
      return {
        ...state,
        videos: {
          ...state.videos,
          fileVideoType: action.response.type,
        },
      }
    }
    case types.GET_NEW_IDEA_CHALLENGE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        challengeOrganization: action.response,
      }
    }
    case types.SET_NEW_IDEA_CHALLENGE_ORGANIZATION: {
      return {
        ...state,
        challengeOrganization: action.challengeOrganization,
      }
    }
    default:
      return state
  }
}

export default createIdeaFormReducer
