import React from 'react'
import { connect } from 'react-redux'
import {
  setActiveTab,
  setDashboardIntervalDates,
  setUsersTableFilter,
  setIdeaTableFilter,
  setPrivateIdeaTableFilter,
  setUsersGridFilter,
} from 'actions/sync/dashboard'
import {
  getActivityTable,
  getSystemAccesses,
  getIdeasSeries,
  getIdeasTable,
  requestDashboardReport,
  getPeopleTable,
  getUsers,
  getUsersParticipation,
  getPrivateIdeasTable,
  getPrivateIdeasSeries,
  sendUsersReport,
  getWeekActivity,
  getMostActive,
  getGridUsers,
} from 'services/dashboard'
import { getPrivateIdeas, getIdeas } from 'services/ideas'
import { getActiveChallenges } from 'services/challenges'
import { getActiveTypes } from 'services/types'
import { getActiveAttributes } from 'services/attributes'
import { getGroups } from 'services/challenge_steps'
import { getActiveOrganizations } from 'services/organizations'
import { getActiveGoals } from 'services/goals'
import {
  showPopupDialog,
  showPopupSuccessSnackbar,
  showPopupErrorSnackbar,
} from 'support/popup_dialogs'
import i18n from 'support/i18n'
import Navigation from 'support/navigation'
import ModalContainer from './components/dialogs/ModalContainer'
import View from './View'
import { dashboardTabs } from './Constants'

const mapStateToProps = state => ({
  challenges: state.data.challenge.challenges,
  attributes: state.data.domain.attributes,
  attributesName: state.data.domain.domainConfiguration.attributesName,
  isAttributesActive: state.data.domain.domainConfiguration.isAttributesActive,
  types: state.data.domain.types,
  typesName: state.data.domain.domainConfiguration.typesName,
  isTypesActive: state.data.domain.domainConfiguration.isTypesActive,
  activeTab: dashboardTabs.ACTIVITY_WEEK,
  activityFromDate: state.data.dashboard.activityFromDate,
  activityToDate: state.data.dashboard.activityToDate,
  ideasFromDate: state.data.dashboard.ideasFromDate,
  ideasToDate: state.data.dashboard.ideasToDate,
  peopleFromDate: state.data.dashboard.peopleFromDate,
  peopleToDate: state.data.dashboard.peopleToDate,
  privateIdeasFromDate: state.data.dashboard.privateIdeasFromDate,
  privateIdeasToDate: state.data.dashboard.privateIdeasToDate,
  activity: {
    ...state.data.dashboard.activity,
    devicesInfo: {
      ...state.data.domain.domain,
    },
  },
  ideas: state.data.dashboard.ideas,
  people: state.data.dashboard.people,
  ideaTablePagination: state.data.dashboard.ideas.pagination,
  privateIdeas: state.data.dashboard.privateIdeas,
  ideasTableBottom: state.data.dashboard.ideas.tableDataBottom.data,
  ideasTableBottomTotal: state.data.dashboard.ideas.tableDataBottom.total,
  ideasTableBottomIsLoading: state.data.dashboard.ideas.tableDataBottom.ideasLoading,
  isLoading: state.data.dashboard.isLoading,
  isActivitySeriesLoading: state.data.dashboard.isActivitySeriesLoading,
  isIdeasSeriesLoading: state.data.dashboard.isIdeasSeriesLoading,
  isUsersParticipationSeriesLoading: state.data.dashboard.isUsersParticipationSeriesLoading,
  users: state.data.dashboard.people.usersTable.users,
  peopleUserFilter: state.data.dashboard.people.usersTable.filter,
  gridPeopleUserFilter: state.data.dashboard.people.usersGrid.filter,
  isUserTableLoading: state.data.dashboard.people.usersTable.isLoading,
  isPrivateIdeasLoading: state.data.dashboard.isPrivateIdeasSeriesLoading,
  showPrivateTab: state.data.domain.domainConfiguration.isPrivateFlowActive,
  userTableFilter: state.data.dashboard.people.usersTable.filter,
  userGridFilter: state.data.dashboard.people.usersGrid.filter,
  domainCreationDate: state.data.domain.domain.domainCreationDate,
  usersParticipation: state.data.dashboard.people.usersParticipation,
  organizations: state.data.domain.organizations,
  organizationsName: state.data.domain.domainConfiguration.organizationsName,
  isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
  groups: state.data.challengeSteps.preview.defaultGroups,
  strategics: state.data.domain.goals,
  isWeekActivitySeriesLoading: state.data.dashboard.isWeekActivitySeriesLoading,
  weekActivity: state.data.dashboard.weekActivity,
  weekActivityFromDate: state.data.dashboard.weekActivityFromDate,
  weekActivityToDate: state.data.dashboard.weekActivityToDate,
  ideaStatusStepper: state.data.domain.domainConfiguration.ideaStatusStepper,
  customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles
})
const mapDispatchToProps = dispatch => ({
  onChangeTab: activeTab => {
    dispatch(setActiveTab(activeTab))
  },
  onChangeDate: (activeTab, date) => {
    dispatch(setDashboardIntervalDates(activeTab, date))
  },
  getActivityComparisonTable: (fromDate, toDate) => dispatch(getActivityTable(fromDate, toDate)),
  getIdeasComparisonTable: (fromDate, toDate) => dispatch(getIdeasTable(fromDate, toDate)),
  getPeopleComparisonTable: (fromDate, toDate) => dispatch(getPeopleTable(fromDate, toDate)),
  getPrivateIdeasComparisonTable: (fromDate, toDate) =>
    dispatch(getPrivateIdeasTable(fromDate, toDate)),
  getSystemAccesses: fromDate => dispatch(getSystemAccesses(fromDate)),
  getIdeasSeries: fromDate => dispatch(getIdeasSeries(fromDate)),
  getUsers: filter => dispatch(getUsers(filter)),
  getGridUsers: (filter, replaceUsers) => dispatch(getGridUsers(filter, replaceUsers)),
  setUsersTableFilter: filter => dispatch(setUsersTableFilter(filter)),
  setIdeaTableFilter: filter => dispatch(setIdeaTableFilter(filter)),
  setUsersGridFilter: filter => dispatch(setUsersGridFilter(filter)),
  setPrivateIdeaTableFilter: filter => dispatch(setPrivateIdeaTableFilter(filter)),
  getUserParticipation: offset => dispatch(getUsersParticipation(offset)),
  getPrivateIdeasSeries: fromDate => dispatch(getPrivateIdeasSeries(fromDate)),
  onSendReport: value => {
    const onSuccess = () => {
      showPopupDialog(
        <ModalContainer
          description={i18n.t('DASHBOARD.SENDED')}
          title={i18n.t('DASHBOARD.REQUESTED')}
        />,
        dispatch,
      )
    }
    dispatch(requestDashboardReport(value, onSuccess))
  },
  navigateToUserProfile: email => dispatch(Navigation.navigateToUserProfile(email)),
  onSendPeopleReport: () => {
    const onSuccess = () => showPopupSuccessSnackbar(i18n.t('DASHBOARD.SENDED'), dispatch)
    dispatch(sendUsersReport(onSuccess))
  },
  getPrivateIdeas: ({
    challenge,
    organizations,
    status,
    types,
    limit,
    offset,
    goal,
    group,
  } = {}) => {
    const onFailure = () =>
      showPopupErrorSnackbar(i18n.t('PROFILE.ERROR_PROCESSING_REQUEST'), dispatch)
    dispatch(
      getPrivateIdeas(
        {
          challenge,
          organizations,
          status,
          types,
          limit,
          offset,
          goal,
          group,
        },
        onFailure,
      ),
    )
  },
  getIdeas: ({ challenge, organizations, status, types, attributes, limit, offset, goal, group } = {}) => {
    const onFailure = () => {
      showPopupErrorSnackbar(i18n.t('PROFILE.ERROR_PROCESSING_REQUEST'), dispatch)
    }
    dispatch(
      getIdeas({ challenge, organizations, status, types, attributes, limit, offset, goal, group }, onFailure),
    )
  },
  navigateToIdea: id => {
    dispatch(Navigation.navigateToIdea(id))
  },
  getActiveOrganizations: () => dispatch(getActiveOrganizations()),
  getGroups: () => dispatch(getGroups()),
  getActiveGoals: () => dispatch(getActiveGoals()),
  getActiveChallenges: () => dispatch(getActiveChallenges()),
  getActiveTypes: () => dispatch(getActiveTypes()),
  getActiveAttributes: () => dispatch(getActiveAttributes()),
  getWeekActivity: date => dispatch(getWeekActivity(date)),
  getMostActive: date => dispatch(getMostActive(date)),
  navigateToProfile: email => {
    dispatch(Navigation.navigateToUserProfile(email))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
