/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import DistributionGraph from './components/distribution_graph/View'
import Profitability from './components/profitability/View'
import Tabs from './components/tabs/View'
import Tab from './components/tab/View'
import styles from './View.Style'

const PortfolioInvestments = ({
  classes, 
  portfolio,
  distribution,
  created,
  initialBalance,
  currencyConfiguration,
  iconName,
  hasIconLeftSide, 
}) => {
  const [tab, setTab] = useState(0)


  const a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })

  const handleTabChange = (event, newTab) => {
    setTab(newTab)
  }

  return (
    <div className={classes.container}>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        aria-label="simple tabs example"
        variant="fullWidth"
      >
      <Tab label={i18n.t('INVESTMENTS.DISTRIBUTION')} {...a11yProps(0)} />
      <Tab label={i18n.t('INVESTMENTS.PROFITABILITY')} {...a11yProps(1)} />
    </Tabs>
      <div
        role="tabpanel"
        hidden={tab !== 0}
        id="simple-tabpanel-0"
        aria-labelledby="simple-tab-0"
      >
        <DistributionGraph data={distribution} />
      </div>
      <div
        role="tabpanel"
        hidden={tab !== 1}
        id="simple-tabpanel-1"
        aria-labelledby="simple-tab-1"
      >
        <Profitability 
          portfolio={portfolio}
          userCreated={created}
          userInitialBalance={initialBalance}
          currencyConfiguration={currencyConfiguration}
          iconName={iconName}
          hasIconLeftSide={hasIconLeftSide} 
        />
      </div>
    </div>
  )
}

PortfolioInvestments.defaultProps = {
  portfolio: [],
  distribution: {
    count: {
      raising: 0,
      approved: 0,
      closed: 0,
      prototyped: 0
    },
    invest: {
      raising: 0,
      approved: 0,
      closed: 0,
      prototyped: 0
    },
    win: {
      raising: 0,
      approved: 0,
      closed: 0,
      prototyped: 0
    }
  },
  created: '',
  initialBalance: null,
}

PortfolioInvestments.propTypes = {
  portfolio: PropTypes.array,
  distribution: PropTypes.object,
  created: PropTypes.string,
  initialBalance: PropTypes.number,
  currencyConfiguration: PropTypes.object.isRequired,
  iconName: PropTypes.string.isRequired,
  hasIconLeftSide: PropTypes.bool.isRequired,
}

export default withStyles(styles)(PortfolioInvestments)
