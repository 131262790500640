import React from 'react'
import * as PropTypes from 'prop-types'
import { Paper, withStyles, FormControlLabel } from '@material-ui/core'
import { NxtRadio } from 'components'
import clsx from 'clsx'
import styles from './View.Style'

const RowSetting = props => {
  const { classes, radioValue, radioLabel, mainRightText, listRightText, isRadioChecked } = props
  return (
    <Paper
      square={false}
      className={clsx(classes.root, isRadioChecked && classes.paperChecked)}
      elevation={3}
    >
      <div className={classes.radioWrapper}>
        <FormControlLabel value={radioValue} control={<NxtRadio value={radioValue} />} />
        <span className={classes.radioLabel}>{radioLabel}</span>
      </div>
      <div className={classes.textWrapper}>
        <div>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: mainRightText }}></span>
          <ul>
            {listRightText.map(value => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </div>
      </div>
    </Paper>
  )
}
RowSetting.defaultProps = {
  radioLabel: '',
  listRightText: [],
  isRadioChecked: false,
}
RowSetting.propTypes = {
  radioValue: PropTypes.string.isRequired,
  mainRightText: PropTypes.string.isRequired,
  listRightText: PropTypes.arrayOf(PropTypes.string),
  radioLabel: PropTypes.string,
  isRadioChecked: PropTypes.bool,
}
export default withStyles(styles)(RowSetting)
