import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Avatar, CircularProgress, Paper } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
import i18n from '../../support/i18n'
import { FileUploader } from '../index'
import { MAX_SIZE_DOCUMENT, DOCUMENT_TYPES } from '../../common_constants/Ideas'
import styles from './View.Style'
import DocumentElement from './components/document_element/View'

const DocumentSection = ({
  classes,
  onFileSelect,
  isDocumentLoading,
  documents,
  onDeleteDocument,
  canDelete,
  error,
  csrfToken,
}) => {
  return (
    <div className={classes.root}>
      <h2 className={classes.titleSection}>
        <span className="icon-attach" />
        {i18n.t('EXPLORE_IDEA.FILE_MANAGER.IDEA_DOCUMENTS')}
      </h2>
      <Paper id="info-message-box" className={classes.infoMessageBox}>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: i18n.t('EXPLORE_IDEA.FILE_MANAGER.ALLOWED_FILES', {
              max_size: MAX_SIZE_DOCUMENT,
              types: DOCUMENT_TYPES,
            }),
          }}
        />
      </Paper>
      <Paper className={classes.paper}>
        <FileUploader
          dropText={i18n.t('EXPLORE_IDEA.FILE_MANAGER.UPLOAD_FILES')}
          onFileSelect={onFileSelect}
        />
        <div>
          <ul className={classes.listContainer}>
            {isDocumentLoading && (
              <li className={classes.listItem}>
                <div className={classes.leftContent}>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </div>
                <div className={classes.rightContent}>
                  <CircularProgress className={classes.iconPadded} size={20} />
                </div>
              </li>
            )}
            {documents.map(document => (
              <li key={document.key} className={classes.listItem}>
                <DocumentElement
                  csrfToken={csrfToken}
                  canDelete={canDelete}
                  documentId={document.key}
                  name={document.name}
                  timeAgo={document.timeAgo}
                  size={document.size}
                  src={document.src}
                  onDeleteDocument={onDeleteDocument}
                />
              </li>
            ))}
          </ul>
          <span className={classes.errorMessage}>{error}</span>
        </div>
      </Paper>
    </div>
  )
}

DocumentSection.defaultProps = {
  isDocumentLoading: false,
  onDeleteDocument: () => {},
  error: '',
}

DocumentSection.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    listItem: PropTypes.string,
    rightContent: PropTypes.string,
    errorMessage: PropTypes.string,
  }).isRequired,
  onFileSelect: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  isDocumentLoading: PropTypes.bool,
  onDeleteDocument: PropTypes.func,
  error: PropTypes.string,
  csrfToken: PropTypes.string.isRequired,
}

export default withStyles(styles)(DocumentSection)
