const styles = () => ({
  dialogBox: {
    width: '530px',
    padding: '10px',
  },
  root: {
    '&:hover': {
      backgroundColor: 'unset',
    },
    color: 'black',
    '& .icon-approved': {
      color: 'black',
      fontSize: '38px',
      width: '33px',
      height: '33px',
      border: '1px solid transparent',
      borderRadius: '4px',
      boxShadow: 'none',
      '&:before': {
        marginTop: '-5px',
        display: 'block',
      },
    },
  },
  radioNotChecked: {
    color: 'black',
    width: '33px',
    fontSize: '38px',
    height: '33px',
    border: '1px solid #B6B6B6',
    borderRadius: '4px',
    boxShadow: '0 0 3px #666666 inset',
  },
})

export default styles
