import React from 'react'
import { withStyles, Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

const RoiConfiguration = (props) => {
  const { classes, customIdeaTitles } = props
  const mainTitle = i18n.t('CHALLENGES.ROI_CONF')
  const secondaryTitle = i18n.t('CHALLENGES.ROI_CONF_INFO', { title: customIdeaTitles.singularTitle || "idea" })

  return (
    <Paper className={classes.root} square={false}>
      <h3 className={classes.mainTitle}>{mainTitle}</h3>
      <p className={classes.secondaryTitle}>{secondaryTitle}</p>
    </Paper>
  )
}
export default withStyles(styles)(RoiConfiguration)
