import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Grid, Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import { PersonalData } from './components/PersonalData'
import { MarketInformation } from './components/MarketInformation'
import { OptionalInformation } from './components/OptionalInformation'
import { CompanyInfo } from './components/CompanyInfo'
import { dniPattern, emailPattern, initValuesForm } from './components/utils'
import ConfirmModal from './components/ConfirmModal'
import SocialNetworks from './components/SocialNetworks'

const StandardForm = props => {
  const {
    onSaveForm,
    ideaId,
    userEmail,
    currentDomain,
    formDataState,
    isIdeaOwner,
    onUploadFile,
    onDeleteFile,
    ideaDocuments,
  } = props

  const { standardFile, standardForm } = formDataState
  const { isLoading, ...othersProps } = standardForm

  const [standardInfo, setStandardInfo] = useState(initValuesForm)
  const [showModal, setShowModal] = useState(false)
  const [errors, setErrors] = useState()

  const handleStandardForm = (field, value) => {
    if (errors && errors[field]) delete errors[field]
    setStandardInfo({ ...standardInfo, [field]: value })
  }

  const handleOnSaveModal = () => {
    onSaveForm({ ...standardInfo, params: { ideaId, userEmail, currentDomain } })
    setShowModal(!showModal)
  }

  const dataIndexed = obj => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const optionalKeys = [
        'file',
        'whoFinances',
        'facebook',
        'twitter',
        'instagram',
        'linkedin',
        'others',
      ]

      if (!optionalKeys.includes(key) && value?.trim().length === 0) {
        return {
          ...acc,
          [key]: i18n.t('FORMS.ERRORS.NOT_EMPTY_TEXTFIELD'),
        }
      }

      if (key === 'corporateEmail' && !emailPattern.test(value)) {
        return { ...acc, [key]: i18n.t('FORMS.ERRORS.EMAIL_FORMAT') }
      }

      if (key === 'companyId' && !dniPattern.test(value)) {
        return { ...acc, [key]: i18n.t('FORMS.ERRORS.COMPANY_ID') }
      }

      const socialKeys = ['webSite', 'facebook', 'twitter', 'instagram', 'linkedin', 'others']

      if (socialKeys.includes(key)) {
        if (value?.trim().length > 0) {
          const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/gm
          if (!regex.test(value)) {
            return { ...acc, [key]: i18n.t('FORMS.ERRORS.URL_FORMAT') }
          }
        }
      }

      const celPattern = /^9\d{8}$/
      if (key === 'cel' && !celPattern.test(value)) {
        return { ...acc, [key]: i18n.t('FORMS.ERRORS.CEL_FORMAT') }
      }

      return acc
    }, {})
  }

  const validateParams = () => {
    const { personalData, companyInfo, marketInfo, socialNetworks } = standardInfo

    setErrors({
      ...errors,
      personalData: dataIndexed(personalData),
      companyInfo: dataIndexed(companyInfo),
      marketInfo: dataIndexed(marketInfo),
      socialNetworks: dataIndexed(socialNetworks),
    })
  }

  useEffect(() => {
    if (errors) {
      const isEmpty = Object.values(errors).filter(obj => Object.keys(obj).length > 0)
      setShowModal(!isEmpty.length)
    }
  }, [errors])

  useEffect(() => {
    if (othersProps !== standardInfo) {
      setStandardInfo(othersProps)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standardForm])

  return (
    <>
      <Paper elevation={0}>
        <Box p={5}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {isLoading ? (
              <Box p={8}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <PersonalData
                  onChangeValue={handleStandardForm}
                  info={standardInfo.personalData}
                  isDisabled={!isIdeaOwner}
                  anyError={errors?.personalData}
                />
                <CompanyInfo
                  onChangeValue={handleStandardForm}
                  info={standardInfo.companyInfo}
                  anyError={errors?.companyInfo}
                  isDisabled={!isIdeaOwner}
                />
                <MarketInformation
                  onChangeValue={handleStandardForm}
                  marketInfo={standardInfo.marketInfo}
                  anyError={errors?.marketInfo}
                  isDisabled={!isIdeaOwner}
                  standardFile={standardFile}
                  onFileSubmit={onUploadFile}
                  onFileDelete={onDeleteFile}
                  ideaDocuments={ideaDocuments}
                />
                <SocialNetworks
                  onChangeValue={handleStandardForm}
                  info={standardInfo.socialNetworks}
                  anyError={errors?.socialNetworks}
                  isDisabled={!isIdeaOwner}
                />
                <OptionalInformation
                  onChangeValue={handleStandardForm}
                  info={standardInfo.optionalQuestions}
                  isDisabled={!isIdeaOwner}
                />
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'uppercase', alignSelf: 'flex-end' }}
                    onClick={validateParams}
                    disabled={!isIdeaOwner}
                  >
                    {i18n.t('FORMS.STANDARD_FORM.BTN_NEXT_STEP')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Paper>
      {showModal ? (
        <ConfirmModal
          open={showModal}
          onSave={handleOnSaveModal}
          onClose={() => setShowModal(false)}
        />
      ) : null}
    </>
  )
}

export default StandardForm
