import React from 'react'
import * as PropTypes from 'prop-types'
import { FormControlLabel } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import NxtCheckbox from 'components/nxt_checkbox/View'
import styles from './View.Styles'

const FormLabel = props => {
  const { classes, title, description, value, onClick } = props
  return (
    <div className={classes.container}>
      <FormControlLabel
        className={classes.check}
        control={<NxtCheckbox isChecked={value || false} />}
        onClick={onClick}
      />
      <div className={classes.span}>
        <div className={classes.spanTitle}>{title}</div>
        <div className={classes.spanDesc}>{description}</div>
      </div>
    </div>
  )
}

FormLabel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(FormLabel)
