import { ServiceCall } from 'support/network/ServiceCallAction'
import {
  GET_ACTIVE_ATTRIBUTES_STARTED,
  GET_ACTIVE_ATTRIBUTES_SUCCESS,
  GET_ACTIVE_ATTRIBUTES_FAILURE,
} from 'actions/sync/attributes/Constants'
import { transformAttributeServiceResponseDataWithState } from './ResponseUtils'
import { prepareHeadersFromState } from '../HeadersUtils'

export const GET_ACTIVE_ATTRIBUTES_URL = '/s/domain/attribute/active'

export function getActiveAttributes() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_ACTIVE_ATTRIBUTES_STARTED
  apiCall.successReqType = GET_ACTIVE_ATTRIBUTES_SUCCESS
  apiCall.failureReqType = GET_ACTIVE_ATTRIBUTES_FAILURE
  apiCall.setEndpointFromState = () => GET_ACTIVE_ATTRIBUTES_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.transformResponseDataWithState = transformAttributeServiceResponseDataWithState
  apiCall.auth = true
  return apiCall.getAction()
}
