import React, { useEffect, useState } from 'react'
import i18n from 'support/i18n'
import SelectWithChecks from '../select_with_checks/View'

const OrganizationSelect = ({
  organizations,
  isOrganizationsActive,
  getOrganization,
  setOrganizationSelected,
  disabled,
  organizationDefaultText,
  organizationSelected
}) => {
  const [organizationList, setOrganizationList] = useState()

  useEffect(() => {
    setOrganizationList(organizations)
  }, [organizations])

  const handleGetOrganization = () => {
    if (isOrganizationsActive && !organizationList.length) {
      getOrganization()
    }
  }

  const idCheckFunction = (value, element) => {
    return value.indexOf(element.id) > -1
  }

  const onOrganizationsChange = (event, callback) => {
    const _callback = callback instanceof Function ? callback : () => { }

    setOrganizationSelected(event.target.value);
    _callback()
  }

  const renderOrganizationsValue = (selected) => {
    const selectedLength = selected.length

    switch (selectedLength) {
      case 0:
        return organizationDefaultText
      case 1:
        return (
          organizations.find(org => org.id === selected[0]) || { name: organizationDefaultText }
        ).name
      default:
        return `${i18n.t('HOME.FILTER.TEXT.SELECTED')}: ${selectedLength}`
    }
  }

  if (!isOrganizationsActive) return null

  return (
    <SelectWithChecks
      checkFunction={idCheckFunction}
      name="organizationSelected"
      defaultText={organizationDefaultText}
      onValueChange={onOrganizationsChange}
      list={organizations}
      multiple
      renderValue={renderOrganizationsValue}
      value={organizationSelected}
      disabled={disabled}
      width={250}
      onOpenHandler={handleGetOrganization}
    />
  )
}
export default OrganizationSelect
