import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    submitterLinkButton: {
      fontSize: 'inherit',
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(0.625),
    },
    submitterSpan: {
      fontSize: 'inherit',
      marginLeft: theme.spacing(0.6),
    },
    borderRight: {
      borderRight: '1px',
      borderRightColor: '#666',
      borderRightStyle: 'solid',
    },
    colorLight: {
      color: 'black',
    },
    colorBlack: {
      color: 'white',
    },
    container: {
      width: '32%',
      position: 'relative',
    },
    graph: {
      position: 'absolute',
      top: '-20px',
      right: 0,
    },
    headerBlock: {
      width: '100%',
      position: 'relative',
      paddingTop: '1rem',
    },
    headerBlockBlack: {
      color: 'white',
    },
    investors: {
      display: 'flex',
      flexFlow: 'column nowrap',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    img: {
      width: '100%',
      height: '12rem',
      display: 'block',
      overflow: 'hidden',
      objectFit: 'cover',
    },
    list: {
      padding: '0.75rem',
      height: 'auto',
      margin: 0,
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      listStyle: 'none',
      '& > li': {
        borderBottom: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#9a9a9a',
        padding: '0.8rem 0',
      },
      '& > li:first-child': {
        paddingTop: 0,
      },
      '& > li:last-child': {
        borderBottom: 0,
        paddingBottom: 0,
      },
    },
    listLight: {
      backgroundColor: '#f0f0f0',
      color: 'black',
    },
    listDark: {
      backgroundColor: 'unset',
      color: 'white',
    },
    paper: {
      padding: '0.7rem',
      height: '28rem',
      boxSizing: 'border-box',
      display: 'grid',
      gridTemplateColumns: '1fr',
    },
    enableMainButton: {
      gridTemplateRows: props =>
        props.onGoToButtonClick ? '280px 38px 1fr 80px' : '280px 38px 1fr 38px',
      gridTemplateAreas: '"header" "info" "list" "button"',
    },
    disableMainButton: {
      gridTemplateRows: '280px 74px 1fr',
      gridTemplateAreas: '"header" "info" "list"',
    },
    paperBlack: {
      backgroundColor: '#333',
    },
    paperClickable: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    gridButton: {
      gridArea: 'button',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
      '& i': {
        marginRight: '0.2rem',
      },
    },
    gridHeader: {
      position: 'relative',
      gridArea: 'header',
    },
    gridInfo: {
      position: 'relative',
      gridArea: 'info',
    },
    gridList: {
      position: 'relative',
      gridArea: 'list',
    },
    pledged: {
      display: 'flex',
      flexFlow: 'column nowrap',
      width: '60%',
      '& > div': {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        '& > i': {
          fontSize: '1rem',
        },
        '& > span': {
          fontSize: '1.2rem',
          fontWeight: 'bold',
        },
      },
      '& > span': {
        fontSize: '0.75rem',
      },
    },
    relative: {
      position: 'relative',
    },
    subText: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      '& p': {
        fontSize: '12px',
        lineHeight: '16px',
        margin: 0,
      },
    },
    subTextLeft: {
      width: '75%',
    },
    subTextRight: {
      textAlign: 'right',
      width: '25%',
      paddingRight: theme.spacing(1.5),
    },
    textBlock: {
      width: '60%',
    },
    title: {
      height: '54px',
      fontSize: '18px',
      lineHeight: '18px',
      margin: 0,
      marginBottom: '0.5rem',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    singleId: {
      fontSize: '0.75rem',
    },
    fullGoToButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 101,
      '&:hover': {
        cursor: 'pointer',
      }
    },
    sponsor: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      background: 'rgba(51,51,51,.65)',
      padding: '10px',
    },
    sponsorPhoto: {
      width: '39px',
      height: '39px',
      borderRadius: '39px',
    },
    sponsorName: {
      paddingLeft: '10px',
      color: '#fff',
      '& h4': {
        margin: 0,
      },
      '& span': {
        fontSize: '13px',
      }
    },
    bottom: {
      display: 'flex',
      flexDirection: 'row',
      color: '#fff',
      backgroundColor: '#666',
      padding: '5px 10px',
      '& .MuiButton-root': {
        color: '#fff',
        textTransform: 'inherit',
        lineHeight: '1rem',
        textAlign: 'left',
      }
    },
    ideas: {
      flex: 2,
      borderRight: '1px',
      borderRightColor: 'rgba(255, 255, 255, 0.3)',
      borderRightStyle: 'solid',
      '& p': {
        marginTop: 0,
        marginBottom: '6px',
      },
      '& p > span': {
        fontSize: '12px',
      }
    },
    mainButton: {
      flex: 1
    },
    ideasAvatar: {
      listStyle: 'none',
      padding: '0',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: 0,
      '& > li': {
        padding: '2%',
        height: '30px',
        width: '30px',
      },
      '& img': {
        width: 30,
        height: 30,
        borderRadius: '100%',
      },
    },
  })

export default styles
