const styles = theme => ({
  card: {
    padding: theme.spacing(1),
  },
  cardTitle: {
    borderBottom: '1px solid #ddd',
    padding: theme.spacing(1),
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      paddingRight: theme.spacing(0.5),
    },
    '& [class^="icon-"]': {
      fontSize: '26px',
    },
  },
  cardBody: {
    padding: theme.spacing(2, 1, 1, 1),
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    overflow: 'hidden',
    maxWidth: '420px',
  },
  timeAgoLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  timeAgo: {
    margin: theme.spacing(0, 0, 1, 0),
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row-reverse',
    width: 'fit-content',
    fontSize: '12px',
    '& > span': {
      padding: theme.spacing(0.5, 1),
    },
    '&:after': {
      content: '""',
      display: 'block',
      transform: 'rotate(90deg)',
      borderLeft: '10px solid transparent',
      paddingTop: '12px',
      borderRight: '10px solid transparent',
      borderBottom: '8px solid #f0f0f0',
    },
  },
  rightTimeAgo: {
    justifyContent: 'flex-start',
    '& > div': {
      flexDirection: 'row',
      '&:after': {
        transform: 'rotate(-90deg)',
      },
    },
  },
  primaryBackground: {
    backgroundColor: theme.palette.primary.main,
  },
})

export default styles
