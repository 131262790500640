import { connect } from 'react-redux'
import {
  getInvestments,
  getPortfolioInvestments,
  getUserInfo
} from 'services/user'
import Navigation from 'support/navigation'
import View from './View'

const mapStateToProps = state => {
  return {
    ideas: state.data.user.investments.ideas,
    totalInvested: state.data.user.investments.totalInvested,
    totalProfit: state.data.user.investments.totalProfit,
    fullInfoLoaded: state.data.domain.dataLoaded,
    userEmail: state.data.user.userEmail,
    portfolio: state.data.user.investments.portfolio,
    distribution: state.data.user.investments.distribution,
    created: state.data.user.created,
    initialBalance: state.data.user.initialBalance,
    currencyConfiguration: state.data.domain.domainConfiguration.currencyConfiguration,
    iconName: state.data.domain.domainConfiguration.currencyIcon,
    hasIconLeftSide: state.data.domain.domainConfiguration.hasCurrencyIconToLeft,
    customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getInvestments: userEmail => {
      dispatch(getInvestments(userEmail))
    },
    onTitleClick: id => {
      dispatch(Navigation.navigateToIdea(id))
    },
    getPortfolio: () => {
      dispatch(getPortfolioInvestments())
    },
    getUserInfo: () => {
      dispatch(getUserInfo())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
