const styles = theme => ({
  root: {
    alignItems: 'center',
  },
  isWithLeftLabel: {
    justifyContent: 'space-between !important',
    '& span': {
      fontSize: '0.875rem',
      marginLeft: theme.spacing(1),
    },
  },
  leftLabelContainer: {
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
  },
  rightLabelContainer: {
    position: 'absolute',
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontSize: '0.875rem',
    },
    '& span:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
  percentageContainer: {
    backgroundColor: 'black',
    color: theme.palette.common.white,
    height: '100%',
    width: '31px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
  },
  tooltipText: {
    fontSize: '0.875rem',
  },
})

export default styles
