import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/Button'
import i18n from 'support/i18n'
import clsx from 'clsx'
import { TAB_TYPES } from 'support/navigation/navigators/logged_in_navigator/View.Constants'
import styles from './View.Style'

const HeaderButtons = (props) => {
  const {
    classes,
    navigateToExplore,
    navigateToMyIdeas,
    navigateToInvestments,
    navigateToChallenges,
    activeTab,
    customTitlesConf
  } = props

  const { customIdeaTitles, isChallenge, isInvestment } = customTitlesConf

  return (
    <div className={classes.listContainer} role="navigation">
      <ul className={classes.domainMenuContainer}>
        <li className={clsx(activeTab === TAB_TYPES.EXPLORE && 'active')}>
          <ButtonBase component="a" onClick={navigateToExplore}>
            <b className="icon-explore" />
            <span>{i18n.t('MENU.EXPLORE')}</span>
          </ButtonBase>
        </li>
        <li className={clsx(activeTab === TAB_TYPES.MY_IDEAS && 'active')}>
          <ButtonBase component="a" onClick={navigateToMyIdeas}>
            <b className="icon-idea" />
            <span>{i18n.t('MENU.MY_IDEAS', { title: customIdeaTitles.pluralTitle || "ideas" })}</span>
          </ButtonBase>
        </li>
        {isInvestment && (
          <li className={clsx(activeTab === TAB_TYPES.INVESTMENTS && 'active')}>
            <ButtonBase component="a" onClick={navigateToInvestments}>
              <b className="icon-evolution" />
              <span>{i18n.t('MENU.INVESTMENTS')}</span>
            </ButtonBase>
          </li>
        )}
        {isChallenge && (
          <li className={clsx(activeTab === TAB_TYPES.CHALLENGES && 'active')}>
            <ButtonBase component="a" onClick={navigateToChallenges}>
              <b className="icon-target" />
              <span>{i18n.t('MENU.CHALLENGES')}</span>
            </ButtonBase>
          </li>
        )}
      </ul>
    </div>
  )
}

HeaderButtons.propTypes = {
  classes: PropTypes.shape({
    listContainer: PropTypes.string.isRequired,
  }).isRequired,
  activeTab: PropTypes.string.isRequired,
  customTitlesConf: PropTypes.shape({
    customIdeaTitles: PropTypes.shape({
      singularTitle: PropTypes.string,
      pluralTitle: PropTypes.string
    }),
    isChallenge: PropTypes.bool,
    isInvestment: PropTypes.bool,
  }).isRequired
}

export default withStyles(styles)(HeaderButtons)
