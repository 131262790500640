import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import RankingSection from 'pages/ranking/components/ranking_section/View'
import styles from './View.Style'

function Ranking({
  classes,
  topInvestors,
  topPosts,
  topPotentialInvestors,
  topIdeas,
  topIdeasInvestors,
  navigateToProfile,
  navigateToIdea,
  getRanking,
  navigateToProfileInvestmentRequest,
  customIdeaTitles
}) {
  useEffect(() => {
    getRanking()
  }, [getRanking])

  return (
    <Paper className={classes.container}>
      <RankingSection
        icon="icon-ranking"
        title={i18n.t('RANKING.INVESTORS')}
        rows={topInvestors}
        linkText="name"
        linkAttribute="email"
        amount="amountInvested"
        amountText={i18n.t('INVESTED')}
        hasCurrency
        navigateToLink={navigateToProfile}
        amountNavigateToLink={navigateToProfileInvestmentRequest}
      />
      <RankingSection
        icon="icon-avatar"
        title={i18n.t('RANKING.ENTREPENEURS', { title: customIdeaTitles.pluralTitle || "ideas" })}
        rows={topPosts}
        linkText="name"
        linkAttribute="email"
        amount="ideas"
        amountText={row => `${i18n.t('IDEAS_DELETED', { title: customIdeaTitles.pluralTitle || "ideas" })} ${row.deletedIdeas}`}
        navigateToLink={navigateToProfile}
      />
      <RankingSection
        icon="icon-funds"
        title={i18n.t('RANKING.LIQUID')}
        rows={topPotentialInvestors}
        linkText="name"
        linkAttribute="email"
        amount="balance"
        amountText={i18n.t('RANKING.ACTUAL_BALANCE')}
        hasCurrency
        navigateToLink={navigateToProfile}
        amountNavigateToLink={navigateToProfileInvestmentRequest}
      />
      <RankingSection
        icon="icon-idea"
        title={i18n.t('RANKING.MORE_INVESTED', { title: customIdeaTitles.pluralTitle || "ideas" })}
        rows={topIdeas}
        linkText="title"
        linkAttribute="id"
        amount="investment"
        amountText={i18n.t('INVESTED')}
        hasCurrency
        navigateToLink={navigateToIdea}
      />
      <RankingSection
        icon="icon-more-investors"
        title={i18n.t('RANKING.MORE_INVESTORS', { title: customIdeaTitles.pluralTitle || "ideas" })}
        rows={topIdeasInvestors}
        linkText="title"
        linkAttribute="id"
        amount="investors"
        amountText={i18n.t('IDEA_INVESTORS')}
        navigateToLink={navigateToIdea}
      />
    </Paper>
  )
}

Ranking.defaultProps = {
  topInvestors: [],
  topPosts: [],
  topPotentialInvestors: [],
  topIdeas: [],
  topIdeasInvestors: [],
}

Ranking.propTypes = {
  topInvestors: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      amountInvested: PropTypes.number,
    }),
  ),
  topPosts: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      ideas: PropTypes.number,
    }),
  ),
  topPotentialInvestors: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      balance: PropTypes.number,
    }),
  ),
  topIdeas: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
      investment: PropTypes.number,
    }),
  ),
  topIdeasInvestors: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
      investors: PropTypes.number,
    }),
  ),
  getRanking: PropTypes.func.isRequired,
  navigateToProfile: PropTypes.func.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  navigateToProfileInvestmentRequest: PropTypes.func.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(Ranking)
