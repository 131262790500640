import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import i18n from 'support/i18n'
import styles from './View.Style'
import ContributionsTable from './components/contributions_table/View'

const ContributionsSection = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { classes, contributions, onIdeaClick, userName, customIdeaTitle } = props

  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <span className="icon-working" />
        <span className={classes.titleText}>{i18n.t('PROFILE.CONTRIBUTIONS')}</span>
      </div>
      {contributions.length > 0 && (
        <Accordion onChange={(_, isOpened) => setIsOpen(isOpened)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="contributions-content"
            id="contributions-content"
          >
            {isOpen ? i18n.t('HIDE') : i18n.t('SHOW')}
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.investmentsRoot }}>
            <ContributionsTable
              contributions={contributions}
              onTitleClick={onIdeaClick}
              classes={{ middle: classes.rootTable }}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {contributions.length === 0 && (
        <Paper className={classes.noIdeasPaper}>
          {userName} {i18n.t('PROFILE.NO_CONTRIBUTIONS', { title: customIdeaTitle || "ideas" })}
        </Paper>
      )}
    </div>
  )
}

ContributionsSection.propTypes = {
  contributions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onIdeaClick: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  customIdeaTitle: PropTypes.string.isRequired
}

export default withStyles(styles)(ContributionsSection)
