import React from 'react'
import LogoFacebook from 'components/svg/components/facebook/View'
import LogoInstagram from 'components/svg/components/instagram/View'
import LogoLinkedin from 'components/svg/components/linkedin/View'
import LogoTwitter from 'components/svg/components/twitter/View'
import LogoYoutube from 'components/svg/components/youtube/View'

export const RenderIconRrss = {
  "Facebook": <LogoFacebook />,
  "Instagram": <LogoInstagram />,
  "Linkedin": <LogoLinkedin />,
  "Twitter": <LogoTwitter />,
  "Youtube": <LogoYoutube />
}
