import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import ButtonLink from 'components/button_link/View'
import styles from './View.Style'
import AddComment from './components/add_comment/View'
import CommentList from './components/comment_list/View'

const CommentsSection = props => {
  const {
    classes,
    onPostComment,
    postingCommentStatus,
    comments,
    onDeleteComment,
    onVoteComment,
    onEditComment,
    onUserClick,
    totalComments,
    userInvestmentsMap,
    currencyIcon,
    onShareIdea,
    isAdminOrInnovator,
    userEmail,
    searchSuggestions,
    suggestions,
    resetUserSuggestions,
    isPrivate,
    customIdeaTitle,
    onUpdateComment,
    stateTabIdea,
  } = props

  return (
    <div className={classes.root}>
      <div>
        <h2 className={classes.titleSection}>
          <div>
            <span className="icon-comment">
              <span className="counter">{totalComments}</span>
            </span>
            {i18n.t('IDEA.COMMENTS')}
          </div>
          {!isPrivate && (
            <div>
              <ButtonLink classes={{ linkButton: classes.shareIdea }} onLinkClick={onShareIdea}>
                <span className="icon-send" />
                <span>
                  {customIdeaTitle === ''
                    ? i18n.t('IDEA.SHARE_THIS_IDEA')
                    : i18n.t('IDEA.SHARE_THIS_IDEA').replace('idea', customIdeaTitle)}
                </span>
              </ButtonLink>
              <ButtonLink classes={{ linkButton: classes.shareIdea }} onLinkClick={onShareIdea}>
                <span className="icon-email" />
                <span>{i18n.t('IDEA.SHARE_THIS_IDEA_BY_EMAIL')}</span>
              </ButtonLink>
            </div>
          )}
        </h2>
      </div>
      <Paper className={classes.paper} elevation={3}>
        <div>
          <CommentList
            onPostComment={onPostComment}
            comments={comments}
            onDeleteComment={onDeleteComment}
            postingCommentStatus={postingCommentStatus}
            onVoteComment={onVoteComment}
            onEditComment={onEditComment}
            onUserClick={onUserClick}
            userInvestmentsMap={userInvestmentsMap}
            currencyIcon={currencyIcon}
            isAdminOrInnovator={isAdminOrInnovator}
            userEmail={userEmail}
            onMentionType={searchSuggestions}
            userSuggestions={suggestions}
            resetUserSuggestions={resetUserSuggestions}
          />
        </div>
        <AddComment
          userSuggestions={suggestions}
          onMentionType={searchSuggestions}
          onPostComment={onPostComment}
          postingCommentStatus={postingCommentStatus}
          resetUserSuggestions={resetUserSuggestions}
          onUpdateComment={onUpdateComment}
          stateTabIdea={stateTabIdea}
        />
      </Paper>
    </div>
  )
}

CommentsSection.propTypes = {
  onPostComment: PropTypes.func.isRequired,
  postingCommentStatus: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  onVoteComment: PropTypes.func.isRequired,
  onEditComment: PropTypes.func.isRequired,
  onUserClick: PropTypes.func.isRequired,
  totalComments: PropTypes.number.isRequired,
  userInvestmentsMap: PropTypes.shape({}).isRequired,
  currencyIcon: PropTypes.string.isRequired,
  isAdminOrInnovator: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
  searchSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string })).isRequired,
  resetUserSuggestions: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  customIdeaTitle: PropTypes.string.isRequired,
}

export default withStyles(styles)(CommentsSection)
