import { dashboardTabs } from 'pages/dashboard/Constants'
import * as types from './Constants'

export const setActiveTab = activeTab => ({
  type: types.SET_ACTIVE_TAB,
  activeTab,
})

export const setUsersTableFilter = usersTableFilter => ({
  type: types.SET_USERS_TABLE_FILTER,
  usersTableFilter,
})

export const setUsersGridFilter = usersGridFilter => ({
  type: types.SET_USERS_GRID_FILTER,
  usersGridFilter,
})

export const setIdeaTableFilter = tablePagination => ({
  type: types.SET_IDEAS_TABLE_FILTER,
  tablePagination,
})

export const setPrivateIdeaTableFilter = tablePagination => ({
  type: types.SET_PRIVATE_IDEAS_TABLE_FILTER,
  tablePagination,
})

export const setDashboardIntervalDates = (activeTab, dates) => {
  const reduxObject = { type: types.SET_DATES, dates: {} }
  switch (activeTab) {
    case dashboardTabs.IDEAS: {
      if (dates.fromDate) {
        reduxObject.dates.ideasFromDate = dates.fromDate
      }
      if (dates.toDate) {
        reduxObject.dates.ideasToDate = dates.toDate
      }
      break
    }
    case dashboardTabs.PEOPLE: {
      if (dates.fromDate) {
        reduxObject.dates.peopleFromDate = dates.fromDate
      }
      if (dates.toDate) {
        reduxObject.dates.peopleToDate = dates.toDate
      }
      break
    }
    case dashboardTabs.ACTIVITY_WEEK: {
      if (dates.fromDate) {
        reduxObject.dates.weekActivityFromDate = dates.fromDate
      }
      if (dates.toDate) {
        reduxObject.dates.weekActivityToDate = dates.toDate
      }
      break
    }
    case dashboardTabs.PRIVATE_IDEAS: {
      if (dates.fromDate) {
        reduxObject.dates.privateIdeasFromDate = dates.fromDate
      }
      if (dates.toDate) {
        reduxObject.dates.privateIdeasToDate = dates.toDate
      }
      break
    }
    default: {
      if (dates.fromDate) {
        reduxObject.dates.activityFromDate = dates.fromDate
      }
      if (dates.toDate) {
        reduxObject.dates.activityToDate = dates.toDate
      }
      break
    }
  }
  return reduxObject
}
