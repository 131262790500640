const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  progressContainer: {
    width: '37.5%',
  },
  percentage: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    alignItems: 'center',
    '& > span:first-child': {
      color: theme.palette.primary.main,
      '& > span:first-child': {
        fontSize: '32px',
        fontWeight: 'bold',
      },
    },
  },
  icon: {
    fontSize: '58px',
    color: '#999999',
  },
  dataContainer: {
    color: '#666',
    display: 'flex',
    flexDirection: 'column',
    width: '62.5%',
    padding: theme.spacing(1, 0, 1, 2),
    alignItems: 'center',
    '& > li': {
      padding: theme.spacing(1, 0),
      borderBottom: '1px solid #dad7d7',
    },
    '& > li:last-child': {
      border: 0,
    },
  },
  firstItem: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '32px',
    fontWeight: 'bold',
    width: '100%',
  },
  secondItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: '26px',
    fontWeight: 'bold',
  },
  normalItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  label: {
    width: '100%',
    fontSize: '12px',
    fontWeight: 'normal',
  },
})

export default styles
