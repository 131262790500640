import { connect } from 'react-redux'
import AlertModalView from 'support/popup_dialogs/views/components/alert_modal_view/AlertModalView'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import Navigation from 'support/navigation'
import i18n from 'support/i18n'

const mapStateToProps = state => {
  const { customIdeaTitles } = state.data.domain.customTitlesConf

  return {
    title: i18n.t('CONFIRMATION_MESSAGE'),
    description: i18n.t('EXPLORE_IDEA.SEND_SUCCESS', {
      title: customIdeaTitles.singularTitle || 'idea',
    }),
    mainButtonText: i18n.t('ACCEPT'),
    customIdeaTitles,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    mainButtonAction: () => {
      dispatch(hideDialog())
      dispatch(Navigation.navigateToIdea(ownProps.id))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModalView)
