import React from 'react'
import MuiTabs from '@material-ui/core/Tabs'
import styled from '@material-ui/core/styles/styled'
import styles from './View.Style'

const Tabs = (props) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ 
      children: <span className="MuiTabs-indicatorSpan" /> 
    }}
  />
)

export default styled(Tabs)(styles)