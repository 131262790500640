/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import ButtonBase from '@material-ui/core/ButtonBase'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth'
import startOfYear from 'date-fns/startOfYear'
import i18n from 'support/i18n'
import { getNames } from 'support/utils/dates'
import styles from './View.Style'

const RangeSelector = ({ classes, minDate, maxDate, value, onChange }) => {
  const [dates, setDates] = useState(null)
  const [monthsNames] = useState(getNames('MONTHS'))
  const [range, setRange] = useState({
    type: value,
    label: '',
    index: null,
  })

  const handleTypeChange = event => {
    const type = event.target.value
    let start = minDate
    const end = maxDate

    if (type === 'month') {
      start = dates.months[dates.months.length - 1].date
      setRange({
        type,
        start,
        label: dates.months[dates.months.length - 1].label,
        index: dates.months.length - 1,
      })
    } else if (type === 'year') {
      start = dates.years[dates.years.length - 1].date
      setRange({
        type,
        start,
        label: dates.years[dates.years.length - 1].label,
        index: dates.years.length - 1,
      })
    } else {
      setRange({ type: 'all', start, label: '', index: null })
    }

    if (onChange) {
      onChange({ type: event.target.value, start, end })
    }
  }

  const handlePrevRange = () => {
    let label = range.label
    let start = range.start
    const index = range.index - 1 >= 0 ? range.index - 1 : range.index

    if (range.type === 'month') {
      label = dates.months[index].label
      start = dates.months[index].date.getTime() > minDate ? dates.months[index].date : minDate
    } else if (range.type === 'year') {
      label = dates.years[index].label
      start = dates.years[index].date.getTime() > minDate ? dates.years[index].date : minDate
    }

    setRange({ type: range.type, start, label, index })

    if (onChange) {
      onChange({ type: range.type, start, end: maxDate })
    }
  }

  const handleNextRange = () => {
    let label = range.label
    let index = range.index
    let start = range.start

    if (range.type === 'month') {
      index = index + 1 < dates.months.length ? index + 1 : range.index
      label = dates.months[index].label
      start = dates.months[index].date.getTime() > minDate ? dates.months[index].date : minDate
    } else if (range.type === 'year') {
      index = index + 1 < dates.years.length ? index + 1 : range.index
      label = dates.years[index].label
      start = dates.years[index].date.getTime() > minDate ? dates.years[index].date : minDate
    }

    setRange({ type: range.type, label, index })

    if (onChange) {
      onChange({ type: range.type, start, end: maxDate })
    }
  }

  useEffect(() => {
    const datesLists = {
      months: [
        {
          date: minDate,
          label: monthsNames[minDate.getMonth()],
        },
      ],
      years: [
        {
          date: minDate,
          label: minDate.getFullYear(),
        },
      ],
    }
    let currMonthDate = add(minDate, { months: 1 })
    let currYearDate = add(minDate, { years: 1 })

    while (currMonthDate.getTime() < maxDate.getTime()) {
      datesLists.months.push({
        date: startOfMonth(currMonthDate),
        label: monthsNames[currMonthDate.getMonth()],
      })
      currMonthDate = add(currMonthDate, { months: 1 })
    }

    while (currYearDate.getTime() < maxDate.getTime()) {
      datesLists.years.push({
        date: startOfYear(currYearDate),
        label: currYearDate.getFullYear(),
      })
      currYearDate = add(currYearDate, { years: 1 })
    }

    datesLists.months.push({
      date: startOfMonth(maxDate),
      label: monthsNames[maxDate.getMonth()],
    })

    datesLists.years.push({
      date: startOfYear(maxDate),
      label: currYearDate.getFullYear(),
    })

    setDates(datesLists)
  }, [minDate, maxDate, monthsNames])

  const renderRangeLabel = () => {
    return (
      <div className={classes.rangeLabelContainer}>
        <ButtonBase onClick={handlePrevRange}>
          <ChevronLeft />
        </ButtonBase>
        <span className={classes.rangeLabel}>{range.label}</span>
        <ButtonBase onClick={handleNextRange}>
          <ChevronRight />
        </ButtonBase>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {dates && (
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="dates-ranges"
            name="row-radio-buttons-group"
            value={value}
            onChange={handleTypeChange}
          >
            <div className={classes.options}>
              <div className={classes.option}>
                <FormControlLabel
                  value="all"
                  control={<Radio color="primary" />}
                  label={i18n.t('INVESTMENTS.ALL')}
                />
              </div>
              <div className={classes.option}>
                <FormControlLabel
                  value="month"
                  control={<Radio color="primary" />}
                  label={i18n.t('INVESTMENTS.MONTH')}
                />
                {range.type === 'month' && renderRangeLabel()}
              </div>
              <div className={classes.option}>
                <FormControlLabel
                  value="year"
                  control={<Radio color="primary" />}
                  label={i18n.t('INVESTMENTS.YEAR')}
                />
                {range.type === 'year' && renderRangeLabel()}
              </div>
            </div>
          </RadioGroup>
        </FormControl>
      )}
    </div>
  )
}

RangeSelector.defaultProps = {
  value: 'all',
  onChange: undefined,
}

RangeSelector.propsTypes = {
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default withStyles(styles)(RangeSelector)
