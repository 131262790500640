import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import clsx from 'clsx'
import ImageUploader from 'components/image_uploader/View'
import i18n from 'support/i18n'
import VideoForm from 'components/video_form/View'
import styles from './View.Style'

const ImageVideoPanel = props => {
  const {
    classes,
    images,
    onSelectImage,
    mainImageValue,
    onSelectMainImage,
    deleteImage,
    onUploadExternalVideo,
    externalVideoSrc,
    fileVideoSrc,
    fileVideoType,
    isVideoLoading,
    isVideoReady,
    isVideoRemoving,
    onUploadVideoFile,
    hasVideoError,
    onRemoveVideo,
    hasImageError,
    imageUploadError,
    onExternalVideoError,
    disableUploadVideoFile,
  } = props
  return (
    <div className={classes.root}>
      <div className={classes.line}>
        <div className={clsx(classes.inputLabel, hasImageError && classes.error)}>
          {i18n.t('CHALLENGES.IMAGES')} *
        </div>
        <div className={classes.imageWrapper}>
          <ImageUploader
            images={images}
            deleteImage={deleteImage}
            onSelectImage={onSelectImage}
            mainImageValue={mainImageValue}
            onSelectMainImage={onSelectMainImage}
            imageUploadError={imageUploadError}
            canChooseMainImage
          />
        </div>
      </div>
      <div className={classes.line}>
        <div className={classes.inputLabel}>{i18n.t('CHALLENGES.VIDEO')}</div>
        <div className={classes.videoWrapper}>
          <VideoForm
            externalVideoSrc={externalVideoSrc}
            fileVideoSrc={fileVideoSrc}
            fileVideoType={fileVideoType}
            onExternalVideoError={onExternalVideoError}
            onChangeExternalVideo={onUploadExternalVideo}
            onSelectFileVideo={onUploadVideoFile}
            onRemoveFileVideo={onRemoveVideo}
            isVideoLoading={isVideoLoading}
            isVideoReady={isVideoReady}
            isVideoRemoving={isVideoRemoving}
            hasVideoError={hasVideoError}
            disableUploadVideoFile={disableUploadVideoFile}
          />
        </div>
      </div>
    </div>
  )
}

ImageVideoPanel.defaultProps = {
  fileVideoSrc: '',
  fileVideoType: '',
  mainImageValue: '',
  imageUploadError: '',
  hasImageError: false,
  isVideoLoading: false,
  isVideoReady: false,
  isVideoRemoving: false,
  hasVideoError: false,
  disableUploadVideoFile: false,
}

ImageVideoPanel.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    line: PropTypes.string,
    inputLabel: PropTypes.string,
    imageWrapper: PropTypes.string,
    videoWrapper: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  onSelectImage: PropTypes.func.isRequired,
  onSelectMainImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      src: PropTypes.string,
    }),
  ).isRequired,
  imageUploadError: PropTypes.string,
  hasImageError: PropTypes.bool,
  mainImageValue: PropTypes.string,
  externalVideoSrc: PropTypes.string.isRequired,
  fileVideoSrc: PropTypes.string,
  fileVideoType: PropTypes.string,
  isVideoLoading: PropTypes.bool,
  isVideoReady: PropTypes.bool,
  isVideoRemoving: PropTypes.bool,
  hasVideoError: PropTypes.bool,
  onUploadExternalVideo: PropTypes.func.isRequired,
  onUploadVideoFile: PropTypes.func.isRequired,
  onRemoveVideo: PropTypes.func.isRequired,
  onExternalVideoError: PropTypes.func.isRequired,
  disableUploadVideoFile: PropTypes.bool,
}

export default withStyles(styles)(ImageVideoPanel)
