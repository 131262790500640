import i18n from 'support/i18n'

export const getCurrencyHtml = ({
  number,
  currencyConfiguration,
  iconName,
  hasIconLeftSide,
}) => {
  let html = ''
  const icon = iconName ? `<span class="icon-${iconName}"></span>` : ''
  const currency = i18n.toCurrency(number, currencyConfiguration)

  if (hasIconLeftSide) {
    if (icon) html += icon
    html += currency
  } else {
    html += currency
    if (icon) html += icon
  }

  html = `<span class="virtual-currency"><span>${html}</span></span>`

  return html
}