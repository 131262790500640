import { formatLocaleDistanceToNow } from 'support/date_fns_locale'

export const ideaMapper = idea => ({
  challenged: idea.challenged,
  countdownEnds: idea.countdownEnds,
  created: idea.created,
  goal: idea.goal,
  id: idea.id,
  images: idea.images,
  investors: `${idea.investors}`,
  ownerName: idea.ownerName,
  pledged: `${idea.pledged}`,
  textWithoutTags: idea.text,
  title: idea.title,
})

export const ideaTeamMapper = idea => ({
  id: idea.id,
  imageSrc: (idea.images.length > 0 && idea.images[0].url) || '',
  investors: `${idea.investors}`,
  pledged: `${idea.pledged}`,
  title: idea.title,
})

export const contributionMapper = contribution => ({
  id: contribution.idea.id,
  image: contribution.idea.images[0].url || '',
  title: contribution.idea.title,
  budget: contribution.budget,
  time: contribution.hours,
  other: contribution.other,
})

export const organisationMapper = organisation => ({
  id: `${organisation.id}`,
  name: organisation.name,
})

export const timelineMapper = event => ({
  id: `${event.id || ''}`,
  type: event.type || '',
  ideaName: event.idea ? event.idea.title : '',
  ideaId: event.idea ? `${event.idea.id || ''}` : '',
  ideaPhotoSrc:
    (event.idea && event.idea.images && event.idea.images[0] && event.idea.images[0].url) || '',
  pledged: event.idea ? `${event.idea.pledged || '0'}` : '0',
  investors: event.idea ? `${event.idea.investors || '0'}` : '0',
  concept: event.concept || '',
  amountChanged: `${Math.abs(event.amount) || ''}`,
  totalBalance: `${Math.abs(event.finalAmount) || ''}`,
  isBalanceRising: !!event.balanceRainsing,
  timeAgo: formatLocaleDistanceToNow(
    new Date(event.date ? event.date.timestamp || event.date.dateString : new Date()),
  ),
  commentText: event.comment ? event.comment.text : '',
  commentLikes: event.comment ? event.comment.likes : '0',
  commentReplies: event.comment ? event.comment.comments : '0',
  badgeType: event.badgeType || '',
})

export const getUserDetail = jsonResponse => ({
  fullName: jsonResponse.fullname,
  balance: `${jsonResponse.balance}`,
  invested: `${jsonResponse.amountInvested}`,
  portfolio: `${jsonResponse.balance + jsonResponse.amountInvested}`,
  email: jsonResponse.email,
  photoSrc: jsonResponse.photo || '',
  badges: jsonResponse.badges,
})

export const transformGetMyProfileResponse = jsonResponse => {
  return {
    ...getUserDetail(jsonResponse),
  }
}

export const transformGetUserProfileResponse = jsonResponse => {
  return {
    ...getUserDetail(jsonResponse.user),
  }
}

export const transformGetUserIdeasResponse = jsonResponse => {
  return jsonResponse.data.reduce((acc, current) => {
    if (!current.anonymous) {
      return [...acc, ideaMapper(current)]
    }
    return acc
  }, [])
}

export const transformGetUserIdeasTeamResponse = jsonResponse => {
  return jsonResponse.data.map(ideaTeamMapper)
}

export const transformGetUserOrganisationsResponse = (jsonResponse) => {
  if (!jsonResponse) return []
  return jsonResponse.organizations.map(organisationMapper)
}

export const transformGetUserContributionsResponse = jsonResponse => {
  return jsonResponse.data.map(contributionMapper)
}

export const transformTimelineResponse = jsonResponse => {
  return jsonResponse.data.map(timelineMapper)
}
