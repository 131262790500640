import { ServiceCall } from 'support/network/ServiceCallAction'
import { setDocumentBody } from 'services/create_idea/RequestUtils'
import { transformDeleteDocument } from 'services/create_idea/ResponseUtils'
import {
  DELETE_FILE_FAILURE,
  DELETE_FILE_STARTED,
  DELETE_FILE_SUCCESS,
  GET_STANDARD_FORM_FAILURE,
  GET_STANDARD_FORM_STARTED,
  GET_STANDARD_FORM_SUCCESS,
  POST_FILE_FAILURE,
  POST_FILE_STARTED,
  POST_FILE_SUCCESS,
  POST_STANDARD_FORM_FAILURE,
  POST_STANDARD_FORM_STARTED,
  POST_STANDARD_FORM_SUCCESS,
} from './constants'
import {
  getStandardFormBody,
  // parseDeleteFileStandardForm,
  parseStandardFormResponse,
  setStandardFormBody,
  transformFileUploadResponse,
} from './helper'

export const POST_STANDARD_FORM_ENDPOINT = '/api/v1/idea/save-form-standard'
export const GET_STANDARD_FORM_ENDPOINT = '/api/v1/idea/get-form-standard'

export const postStandardForm = (values, onSuccess, onFailure) => {
  const serviceCall = new ServiceCall()
  serviceCall.startedReqType = POST_STANDARD_FORM_STARTED
  serviceCall.successReqType = POST_STANDARD_FORM_SUCCESS
  serviceCall.failureReqType = POST_STANDARD_FORM_FAILURE
  serviceCall.getHeadersFromState = () => ({
    'content-type': 'application/json',
  })
  serviceCall.setEndpointFromState = () => POST_STANDARD_FORM_ENDPOINT
  serviceCall.setBodyFromState = setStandardFormBody(values)
  serviceCall.method = 'POST'
  serviceCall.transformResponseDataWithState = (response, state) =>
    parseStandardFormResponse(response, state)
  serviceCall.auth = true
  serviceCall.onSuccess = onSuccess
  serviceCall.onFailure = onFailure
  return serviceCall.getAction()
}

export const getStandardForm = values => {
  const serviceCall = new ServiceCall()
  serviceCall.startedReqType = GET_STANDARD_FORM_STARTED
  serviceCall.successReqType = GET_STANDARD_FORM_SUCCESS
  serviceCall.failureReqType = GET_STANDARD_FORM_FAILURE
  serviceCall.getHeadersFromState = () => ({
    'content-type': 'application/json',
  })
  serviceCall.setEndpointFromState = () => GET_STANDARD_FORM_ENDPOINT
  serviceCall.setBodyFromState = getStandardFormBody(values)
  serviceCall.method = 'POST'
  serviceCall.transformResponseDataWithState = parseStandardFormResponse
  serviceCall.auth = true
  return serviceCall.getAction()
}

export const POST_FILE_STANDARDFORM_ENDPOINT = '/s/idea/##id##/document' // TODO: Must be change

export function postFileStandardForm(id, fileName, base64File, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = POST_FILE_STARTED
  apiCall.successReqType = POST_FILE_SUCCESS
  apiCall.failureReqType = POST_FILE_FAILURE
  apiCall.setEndpointFromState = () => `${POST_FILE_STANDARDFORM_ENDPOINT.replace('##id##', id)}`
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setDocumentBody(fileName, base64File)
  apiCall.transformResponseDataWithState = transformFileUploadResponse
  apiCall.onFailure = onFailure
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}

const DELETE_FILE_STANDARDFORM_ENDPOINT = '/api/v1/idea/##id##/document/##fileKey##' // TODO: Must be change

export function deleteFileStandardForm(ideaId, fileKey, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = DELETE_FILE_STARTED
  apiCall.successReqType = DELETE_FILE_SUCCESS
  apiCall.failureReqType = DELETE_FILE_FAILURE
  apiCall.setEndpointFromState = () =>
    `${DELETE_FILE_STANDARDFORM_ENDPOINT.replace('##id##', ideaId).replace('##fileKey##', fileKey)}`
  apiCall.method = 'DELETE'
  apiCall.auth = true
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = (_, state) =>
    transformDeleteDocument(fileKey, state.data.createIdea.documents)
  // apiCall.transformResponseDataWithState = parseDeleteFileStandardForm
  return apiCall.getAction()
}
