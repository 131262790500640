const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '& > span[class*="icon-comment"]': {
        fontSize: '32px',
        marginRight: theme.spacing(1),
        position: 'relative',
      },
      '& > span[class*="icon-comment"] > span[class*="counter"]': {
        fontFamily: '"Helvetica Neue", "sans-serif"',
        position: 'absolute',
        left: '0',
        fontSize: '14px',
        top: '18%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
  shareIdea: {
    fontSize: '20px',
    marginLeft: '15px',
    '& > span:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    '& > div:first-child': {
      maxHeight: '600px',
      overflowY: 'auto',
    },
  },
})

export default styles
