import { connect } from 'react-redux'
import { setAuthMessageBox, resetAuthMessageBox } from 'actions/sync/auth'
import { postChangePassword } from 'services/auth'
import { MESSAGE_TYPES } from 'components/auth_message_box/View.Utils'
import i18n from 'support/i18n'
import { showPopupSuccessSnackbar } from 'support/popup_dialogs'
import Navigation from 'support/navigation'
import { POSSIBLES_AUTH_ERRORS } from 'support/utils/auth'
import { getDomainLoginInfo } from 'services/domain'
import View from './View'

const mapStateToProps = (state) => {
  return {
    messageType: state.auth.messageBox.messageType,
    messageText: state.auth.messageBox.messageText,
    isChangingPassword: state.auth.isChangingPassword,
    useCustomTextColor: state.data.domain.loginInfo.useCustomTextColor,
    customTextColor: state.data.domain.loginInfo.customTextColor,
    useCustomImg: state.data.domain.loginInfo.useCustomImg,
    customImg: state.data.domain.loginInfo.customImg,
    useCustomColor: state.data.domain.loginInfo.useCustomColor,
    customColor: state.data.domain.loginInfo.customColor,
    useCustomText: state.data.domain.loginInfo.useCustomText,
    customText: state.data.domain.loginInfo.customText,
    useCustomBgImg: state.data.domain.loginInfo.useCustomBgImg,
    customBgImg: state.data.domain.loginInfo.customBgImg,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetAuthMessageBox: () => {
    dispatch(resetAuthMessageBox())
  },
  setAuthMessageBox: (messageType, messageText) => {
    dispatch(setAuthMessageBox(messageType, messageText))
  },
  onSubmit: (password, domain) => {
    const tokenId = ownProps.match.params.token
    const onSuccess = () => {
      showPopupSuccessSnackbar(i18n.t('PROFILE.EDIT_PASSWORD_SUCCESS'), dispatch)
      if (domain) {
        Navigation.navigateToCustomLogin(domain)
      } else {
        Navigation.navigateToLogin()
      }
    }
    const onFailure = responseError => {
      if (responseError.data.error.error === POSSIBLES_AUTH_ERRORS.REPEATED_INVALID_PASSWORD) {
        dispatch(
          setAuthMessageBox(
            MESSAGE_TYPES.ERROR,
            i18n.t('COMMON_LOGIN.HEADER.CHANGE_PASSWORD_REPEATED_KO'),
          ),
        )
      } else {
        dispatch(
          setAuthMessageBox(
            MESSAGE_TYPES.ERROR,
            i18n.t('COMMON_LOGIN.HEADER.CHANGE_PASSWORD_GENERAL_KO'),
          ),
        )
      }
    }
    dispatch(postChangePassword(password, tokenId, onSuccess, onFailure))
  },
  onLoad: (domain) => dispatch(getDomainLoginInfo(domain)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
