const styles = theme => ({
  root: {
    display: 'flex',
    '& > div': {
      position: 'relative',
    },
    '& > div:not(:first-child)': {
      paddingLeft: '20px',
    },
    '& > div:last-child::after': {
      width: '0px',
    },
  },
  messagePaper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 2),
    backgroundColor: '#E5F1E0',
    borderColor: '#C8E0BD',
    color: '#518D37',
  },
  messagePaperDiscarded: {
    backgroundColor: '#f2dede',
    borderColor: '#eed3d7',
    color: '#b94a48',
  },
  messageTitle: {
    fontSize: '0.875rem',
    fontWeight: 700,
    marginBottom: '5px',
  },
  messageText: {
    fontSize: '0.875rem',
  },
})

export default styles
