import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import { Paper } from '@material-ui/core'
import styles from './View.Style'
import DefaultLogisticRow from './components/default_logistic_row/View'
import BenefitsLogisticRow from './components/benefits_logistic_row/View'

const LogisticDataSection = props => {
  const {
    classes,
    pilotEstimatedQuantity,
    pilotEstimatedHours,
    pilotEstimatedTime,
    pilotEstimatedTimeType,
    implementEstimatedQuantity,
    implementEstimatedHours,
    implementEstimatedTime,
    implementEstimatedTimeType,
    economicBenefits,
    otherBenefits,
  } = props

  return (
    <Fragment>
      {(pilotEstimatedQuantity ||
        pilotEstimatedTime ||
        pilotEstimatedHours ||
        implementEstimatedQuantity ||
        implementEstimatedTime ||
        implementEstimatedHours ||
        economicBenefits ||
        otherBenefits) && (
        <Fragment>
          <div className={classes.logisticDataWrapper}>
            <h2 className={classes.logisticHeader}>
              <span className="icon-calculator" />
              {i18n.t('LOGISTIC.TITLE')}
            </h2>
            <Paper className={classes.root}>
              {(pilotEstimatedQuantity || pilotEstimatedTime || pilotEstimatedHours) && (
                <DefaultLogisticRow
                  titleIcon="icon-prototype"
                  titleText={i18n.t('IDEA_STATUS.PILOT_ACTIVE')}
                  estimatedHours={pilotEstimatedHours}
                  estimatedQuantity={pilotEstimatedQuantity}
                  estimatedTimeType={pilotEstimatedTimeType}
                  estimatedTime={pilotEstimatedTime}
                />
              )}
              {(implementEstimatedQuantity ||
                implementEstimatedTime ||
                implementEstimatedHours) && (
                <DefaultLogisticRow
                  titleIcon="icon-ideas-implemented"
                  titleText={i18n.t('LOGISTIC.IMPLEMENT')}
                  estimatedHours={implementEstimatedHours}
                  estimatedTimeType={implementEstimatedTimeType}
                  estimatedQuantity={implementEstimatedQuantity}
                  estimatedTime={implementEstimatedTime}
                />
              )}
              {(economicBenefits || otherBenefits) && (
                <BenefitsLogisticRow
                  economicBenefits={economicBenefits}
                  otherBenefits={otherBenefits}
                  titleText={i18n.t('LOGISTIC.ESTIMATED_BENEFITS')}
                  titleIcon="icon-ranking"
                />
              )}
            </Paper>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

LogisticDataSection.defaultProps = {}

LogisticDataSection.propTypes = {
  pilotEstimatedQuantity: PropTypes.string.isRequired,
  pilotEstimatedHours: PropTypes.string.isRequired,
  pilotEstimatedTime: PropTypes.string.isRequired,
  pilotEstimatedTimeType: PropTypes.string.isRequired,
  implementEstimatedQuantity: PropTypes.string.isRequired,
  implementEstimatedHours: PropTypes.string.isRequired,
  implementEstimatedTime: PropTypes.string.isRequired,
  implementEstimatedTimeType: PropTypes.string.isRequired,
  economicBenefits: PropTypes.string.isRequired,
  otherBenefits: PropTypes.string.isRequired,
}

export default withStyles(styles)(LogisticDataSection)
