const styles = theme => {
  return {
    container: {
      width: 800,
      padding: theme.spacing(1.25),
      '& .more-info': {
        color: theme.palette.primary.main,
      }
    },
    error: {
      backgroundColor: theme.palette.error.main,
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    icon: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    mainProfile: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    columnInputs: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(1.25),
      paddingTop: '8px !important',
      height: 'auto',
      '& div': {
        width: 300,
      },
    },
    passwordInputs: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(1.25),
      '& > div': {
        width: 235,
      },
    },
    buttonsContainer: {
      backgroundColor: '#F0F0F0',
      margin: theme.spacing(0, 2.5, 0, 2.5),
      padding: theme.spacing(3.75),
    },
    spinner: {
      marginLeft: theme.spacing(1.5),
    },
    photoContainer: {
      display: 'flex',
    },
    imageContainer: {
      width: 155,
      marginRight: theme.spacing(1.875),
      '& img': {
        height: 155,
      },
    },
    text: {
      fontSize: 14,
      lineHeight: '20px',
      color: '#646464',
    },
  }
}

export default styles
