const styles = theme => ({
  boldText: {
    fontWeight: 'bold',
  },
  cell: {
    '& .more-info': {
      width: 115,
      padding: 0,
      marginLeft: theme.spacing(0.5),
      marginTop: theme.spacing(-0.375),
      textAlign: 'left',
      textTransform: 'none',
      textDecoration: 'underline',
    }
  },
})

export default styles
