export function getBase64Split(file, cb, withMimeType = false) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    let base64result = reader.result

    if (base64result && !withMimeType) {
      ;[, base64result] = base64result.split(',')
    }
    cb(file.name, base64result)
  }
  reader.onerror = () => {
    // reader.onerror = function(error) {
    // console.log('Error on Base64 Utils: ', error)
    cb('')
  }
}

export function transformToEmbedUrl(url) {
  let returnUrl = ''
  if (!url) return ''
  if (!url.startsWith('https://')) returnUrl = 'https://'
  return `${returnUrl}${url.replace('youtu.be', 'youtube.com/embed').replace('watch?v=', 'embed/')}`
}

export function isYoutubeUrl(url) {
  if (url.includes('youtube.com')) return true
  return false
}

export function transformVideoType(type) {
  let validType = type.replace(';','')
  // to reproduce mov videos
  validType = validType.replace('quicktime','mp4')

  return validType
}
