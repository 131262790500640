import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Paper, Button } from '@material-ui/core'
import SideLogin from 'components/side_login/View'
import i18n from 'support/i18n'
import styles from './View.Style'

const Validate = props => {
  const { classes, onOkClick, instanceId } = props

  return (
    <div className={classes.base}>
      <SideLogin />
      <Paper className={classes.paper}>
        <div className={classes.centerContainer}>
          <h3>{i18n.t('VALIDATE_PAGE.TITLE')}</h3>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: i18n.t('VALIDATE_PAGE.VALIDATE_SUCCESS_1') }}
          />
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: i18n.t('VALIDATE_PAGE.VALIDATE_SUCCESS_2') }}
          />
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: i18n.t('VALIDATE_PAGE.VALIDATE_SUCCESS_3') }}
          />
          <Button variant="contained" onClick={() => onOkClick({ instanceId })} color="primary">
            {i18n.t('VALIDATE_PAGE.VALIDATE_BUTTON_LOGIN')}
          </Button>
        </div>
      </Paper>
    </div>
  )
}

Validate.propTypes = {
  onOkClick: PropTypes.func.isRequired,
  instanceId: PropTypes.string.isRequired,
}

export default withStyles(styles)(Validate)
