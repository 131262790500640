import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import pages from 'common_constants/Pages'
import {
  CustomLogin,
  Error,
  IdentityChoose,
  Login,
  ResetPassword,
  SignUp,
  Validate,
  Maintenance,
  AzureLogin,
  AdfsLogin,
} from 'pages'
import NavigationService from 'support/navigation/NavigationService'

const LoggedOutNavigator = props => {
  const {
    loggedIn,
    logging,
    lastDomainVisited,
    isDomainInMaintenance,
    shouldGoToMaintenance,
  } = props
  const customHistory = NavigationService.getHistory()
  const ROUTES = NavigationService.getRoutes()
  const urlDomain = customHistory.location.pathname.split('/')[1]
  let loggedOutRedirect = ''
  if (urlDomain) {
    loggedOutRedirect = ROUTES[pages.CUSTOM_LOGIN_PAGE].path.replace(':domain', urlDomain)
  }
  if (!loggedOutRedirect) {
    loggedOutRedirect = lastDomainVisited
      ? ROUTES[pages.CUSTOM_LOGIN_PAGE].path.replace(':domain', lastDomainVisited)
      : ROUTES[pages.LOGIN_PAGE].path
  }
  if (isDomainInMaintenance && shouldGoToMaintenance) {
    loggedOutRedirect = ROUTES[pages.MAINTENANCE].path.replace(':domain', lastDomainVisited)
  }
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path={ROUTES[pages.IDENTITY_CHOOSE_PAGE].path}
          render={componentProps =>
            logging ? <IdentityChoose {...componentProps} /> : <Redirect to={ROUTES.login.path} />
          }
        />
        <Route
          exact
          path={ROUTES[pages.CUSTOM_LOGIN_PAGE].path}
          render={componentProps => <CustomLogin {...componentProps} />}
        />
        <Route
          exact
          path={ROUTES[pages.AZURE_LOGIN].path}
          render={componentProps => <AzureLogin {...componentProps} />}
        />
        <Route
          exact
          path={ROUTES[pages.ADFS_LOGIN].path}
          render={componentProps => <AdfsLogin {...componentProps} />}
        />
        <Route
          exact
          path={ROUTES[pages.LOGIN_PAGE].path}
          render={componentProps => <Login {...componentProps} />}
        />
        <Route
          exact
          path={ROUTES[pages.RESET_PASSWORD].path}
          render={componentProps => <ResetPassword {...componentProps} />}
        />
        <Route
          exact
          path={ROUTES[pages.SIGN_UP].path}
          render={componentProps => <SignUp {...componentProps} />}
        />
        <Route
          exact
          path={ROUTES[pages.VALIDATE].path}
          render={componentProps => <Validate {...componentProps} />}
        />
        {isDomainInMaintenance && (
          <Route
            exact
            path={ROUTES[pages.MAINTENANCE].path}
            render={componentProps => <Maintenance {...componentProps} />}
          />
        )}
        <Route render={() => (loggedIn ? <Error /> : <Redirect to={loggedOutRedirect} />)} />
      </Switch>
    </Fragment>
  )
}

LoggedOutNavigator.propTypes = {
  classes: PropTypes.shape({
    base: PropTypes.string.isRequired,
  }).isRequired,
  isDomainInMaintenance: PropTypes.bool.isRequired,
  shouldGoToMaintenance: PropTypes.bool.isRequired,
}

export default LoggedOutNavigator
