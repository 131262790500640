import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, withTheme } from '@material-ui/core'
import clsx from 'clsx'
import { differenceInCalendarDays } from 'date-fns'
import i18n from 'support/i18n'
import NxtProgressCircle from 'components/nxt_progress_circle/View'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import { ideaStatusIconMapper, ideaStatusMapper } from 'pages/idea/detail/Constants'
import { calcPercentage } from 'support/utils/circleGraph'
import styles from './View.Style'

class SummaryData extends Component {
  constructor(props) {
    super(props)

    this.state = { percentage: 0, daysPercentage: 0 }
    this.renderChildren = this.renderChildren.bind(this)
  }

  componentDidMount() {
    const { pledged, goal, createdDate, endDate } = this.props
    const totalDays = differenceInCalendarDays(endDate, createdDate)
    const passedDays = differenceInCalendarDays(Date.now(), createdDate)
    this.setState({
      percentage: calcPercentage(pledged, goal),
      daysPercentage: calcPercentage(passedDays, totalDays),
    })
  }

  componentDidUpdate(prevProps) {
    const { pledged, goal } = this.props
    if (prevProps.pledged !== pledged || prevProps.goal !== goal) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        percentage: calcPercentage(pledged, goal),
      })
    }
  }

  renderChildren() {
    const { classes, currentStatus } = this.props
    const { percentage, daysPercentage } = this.state
    if (ideaStatusMapper[currentStatus] === ideaStatusMapper.PENDING_SUPPORT) {
      return (
        <NxtProgressCircle
          thickness={2}
          value={Number(daysPercentage)}
          mainColor="#999999"
          size={110}
        >
          <div className={classes.percentage}>
            <span>
              <span>{percentage}</span>
              <span>%</span>
            </span>
            <span>{i18n.t('PLEDGED')}</span>
          </div>
        </NxtProgressCircle>
      )
    }
    return (
      <span
        className={clsx(
          `icon-${ideaStatusIconMapper[ideaStatusMapper[currentStatus]]}`,
          classes.icon,
        )}
      />
    )
  }

  render() {
    const {
      classes,
      progressColor,
      theme,
      pledged,
      investors,
      visits,
      ideaId,
      endDate,
      goal,
      currentStatus,
      customIdeaTitle
    } = this.props

    const passedDays = differenceInCalendarDays(endDate, Date.now())
    const { percentage } = this.state

    return (
      <div className={classes.root}>
        <div className={classes.progressContainer}>
          <NxtProgressCircle
            thickness={4}
            value={
              ideaStatusMapper[currentStatus] === ideaStatusMapper.PENDING_SUPPORT
                ? Number(percentage)
                : 100
            }
            mainColor={progressColor || theme.palette.primary.main}
            size={150}
          >
            {this.renderChildren()}
          </NxtProgressCircle>
        </div>
        <ul className={classes.dataContainer}>
          <li className={classes.firstItem}>
            <div>
              <NxtVirtualCurrency>{pledged}</NxtVirtualCurrency>
            </div>
            <span className={classes.label}>{i18n.t('PLEDGED')}</span>
          </li>
          {ideaStatusMapper[currentStatus] < ideaStatusMapper.SUPPORTED && (
            <li className={classes.secondItem}>
              <div>
                <NxtVirtualCurrency>{goal - pledged}</NxtVirtualCurrency>
              </div>
              <span className={classes.label}>
                {`${i18n.t('NEEDED')} ${i18n.t('EXPLORE_IDEA.OF_TOTAL')} `}
                <NxtVirtualCurrency>{goal}</NxtVirtualCurrency>
              </span>
            </li>
          )}
          {ideaStatusMapper[currentStatus] <= ideaStatusMapper.ROUND_CLOSED && (
            <li className={classes.normalItem}>
              <div>{passedDays > 0 ? passedDays : 0}</div>
              <span className={classes.label}>
                {ideaStatusMapper[currentStatus] < ideaStatusMapper.ROUND_CLOSED
                  ? i18n.t('DAYS_TO_GO')
                  : i18n.t('DAYS_TO_APPROVE')}
              </span>
            </li>
          )}
          <li className={classes.normalItem}>
            <div>{investors}</div>
            <span className={classes.label}>{i18n.t('INVESTORS')}</span>
          </li>
          <li className={classes.normalItem}>
            <div>{visits}</div>
            <span className={classes.label}>{i18n.t('EXPLORE_IDEA.VISITS')}</span>
          </li>
          <li className={classes.normalItem}>
            <div>{ideaId}</div>
            <span className={classes.label}>
              {customIdeaTitle === ""
                ? i18n.t('EXPLORE_IDEA.SINGLE_ID')
                : i18n.t('EXPLORE_IDEA.SINGLE_ID').replace("idea", customIdeaTitle)
              }
            </span>
          </li>
        </ul>
      </div>
    )
  }
}

SummaryData.propTypes = {
  pledged: PropTypes.string.isRequired,
  goal: PropTypes.string.isRequired,
  investors: PropTypes.string.isRequired,
  visits: PropTypes.string.isRequired,
  ideaId: PropTypes.string.isRequired,
  currentStatus: PropTypes.string.isRequired,
  progressColor: PropTypes.string.isRequired,
  createdDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  customIdeaTitle: PropTypes.string.isRequired
}

export default withTheme(withStyles(styles)(SummaryData))
