import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import ButtonLink from 'components/button_link/View'
import i18n from 'support/i18n'
import style from './View.Style'

const AskInvestmentsSection = props => {
  const { classes, onAskInvestmentClick } = props
  return (
    <div className={classes.root}>
      <ButtonLink onLinkClick={onAskInvestmentClick}>
        <span className="icon-back" />
        {i18n.t('IDEA.ASK_FOR_INVESTMENT')}
      </ButtonLink>
    </div>
  )
}

AskInvestmentsSection.propTypes = {
  onAskInvestmentClick: PropTypes.func.isRequired,
}

export default withStyles(style)(AskInvestmentsSection)
