import { ServiceCall } from 'support/network/ServiceCallAction'
import {
  DELETE_CHALLENGE_BY_ID_STARTED,
  DELETE_CHALLENGE_BY_ID_SUCCESS,
  DELETE_CHALLENGE_BY_ID_FAILURE,
  GET_ACTIVE_CHALLENGES_FAILURE,
  GET_ACTIVE_CHALLENGES_STARTED,
  GET_ACTIVE_CHALLENGES_SUCCESS,
  GET_CHALLENGE_BY_ID_STARTED,
  GET_CHALLENGE_BY_ID_SUCCESS,
  GET_CHALLENGE_BY_ID_FAILURE,
  GET_CHALLENGE_VISITORS_BY_ID_STARTED,
  GET_CHALLENGE_VISITORS_BY_ID_SUCCESS,
  GET_CHALLENGE_VISITORS_BY_ID_FAILURE,
  POST_FINISH_CHALLENGE_BY_ID_STARTED,
  POST_FINISH_CHALLENGE_BY_ID_SUCCESS,
  POST_FINISH_CHALLENGE_BY_ID_FAILURE,
  APPLY_SEARCH_CHALLENGES_STARTED,
  APPLY_SEARCH_CHALLENGES_SUCCESS,
  APPLY_SEARCH_CHALLENGES_FAILURE,
} from 'actions/sync/challenges/Constants'
import { FIRST_LIMIT_CONSTANT } from 'common_constants/Ideas'
import { 
  transformGetChallengeService, 
  transformGetChallengeByIdService,
  transformSearchChallengeService, 
} from './ResponseUtils'
import { prepareHeadersFromState } from '../HeadersUtils'

export const GET_ACTIVE_CHALLENGES_URL = '/api/v1/challenge'
export const POST_FINISH_CHALLENGE_BY_ID_URL = '/s/v1.0/challenge/##id##/finish'
export const DELETE_CHALLENGE_BY_ID_URL = 's/v1.0/challenge/##id##'
export const GET_CHALLENGE_VISITORS_BY_ID_URL = '/s/v1.0/challenge/downloadVisitors/##id##'
export const FILTER_SEARCH_URL = 's/v1.0/challenge/search?q=##search##'

export function getActiveChallenges(status = 'ACTIVE', limitGroups = false, limit = 0) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_ACTIVE_CHALLENGES_STARTED
  apiCall.successReqType = GET_ACTIVE_CHALLENGES_SUCCESS
  apiCall.failureReqType = GET_ACTIVE_CHALLENGES_FAILURE
  apiCall.setEndpointFromState = () =>
    `${GET_ACTIVE_CHALLENGES_URL}?status=${status}&limitGroups=${limitGroups}&limit=${limit}`
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.transformResponseDataWithState = transformGetChallengeService
  apiCall.auth = true
  return apiCall.getAction()
}

export function getChallengeById(id, limit = FIRST_LIMIT_CONSTANT, offset = 0) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_CHALLENGE_BY_ID_STARTED
  apiCall.successReqType = GET_CHALLENGE_BY_ID_SUCCESS
  apiCall.failureReqType = GET_CHALLENGE_BY_ID_FAILURE
  apiCall.setEndpointFromState = () =>
    `${GET_ACTIVE_CHALLENGES_URL}/${id}/?limit=${limit}&offset=${offset}`
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.transformResponseDataWithState = transformGetChallengeByIdService
  apiCall.auth = true
  return apiCall.getAction()
}

export function postFinishChallengeById(id, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = POST_FINISH_CHALLENGE_BY_ID_STARTED
  apiCall.successReqType = POST_FINISH_CHALLENGE_BY_ID_SUCCESS
  apiCall.failureReqType = POST_FINISH_CHALLENGE_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => POST_FINISH_CHALLENGE_BY_ID_URL.replace('##id##', id)
  apiCall.method = 'POST'
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  return apiCall.getAction()
}

export function deleteChallengeById(id, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = DELETE_CHALLENGE_BY_ID_STARTED
  apiCall.successReqType = DELETE_CHALLENGE_BY_ID_SUCCESS
  apiCall.failureReqType = DELETE_CHALLENGE_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => DELETE_CHALLENGE_BY_ID_URL.replace('##id##', id)
  apiCall.method = 'DELETE'
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  return apiCall.getAction()
}

export function getChallengeVisitorsById(id, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_CHALLENGE_VISITORS_BY_ID_STARTED
  apiCall.successReqType = GET_CHALLENGE_VISITORS_BY_ID_SUCCESS
  apiCall.failureReqType = GET_CHALLENGE_VISITORS_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => GET_CHALLENGE_VISITORS_BY_ID_URL.replace('##id##', id)
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export function applySearchChallengesService(search, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = APPLY_SEARCH_CHALLENGES_STARTED
  apiCall.successReqType = APPLY_SEARCH_CHALLENGES_SUCCESS
  apiCall.failureReqType = APPLY_SEARCH_CHALLENGES_FAILURE
  apiCall.setEndpointFromState = () => FILTER_SEARCH_URL.replace('##search##', search)
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.transformResponseDataWithState = transformSearchChallengeService
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}
