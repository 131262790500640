import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Paper } from '@material-ui/core'
import { getTypeFromStatus } from 'pages/idea/detail/View.Utils'
import { ideaStatusMapper } from 'pages/idea/detail/Constants'
import clsx from 'clsx'
import styles from './View.Style'
import IdeaStatusStep from './components/idea_status_step/View'

const StatusSection = (props) => {
  const { classes, ideaStatusStepper, currentStatus, messageText, messageTitle, customIdeaTitle } = props
  const isApprovedStatus = ideaStatusMapper[currentStatus] === ideaStatusMapper.APPROVED_BY_IT
  const isDiscardedStatus = ideaStatusMapper[currentStatus] === ideaStatusMapper.DISCARDED
  return (
    <Fragment>
      <div className={classes.root}>
        <IdeaStatusStep
          text={ideaStatusStepper.pendingSupportLabel}
          mainCircleValue={35}
          secondaryCircleValue={30}
          icon="icon-earn-money"
          mainColor={ideaStatusStepper.pendingSupportColor}
          statusType={getTypeFromStatus(ideaStatusMapper.PENDING_SUPPORT, currentStatus)}
        />
        <IdeaStatusStep
          text={ideaStatusStepper.supportedLabel}
          mainCircleValue={100}
          secondaryCircleValue={100}
          icon="icon-success"
          mainColor={ideaStatusStepper.supportedColor}
          secondaryColor="#646464"
          statusType={getTypeFromStatus(ideaStatusMapper.SUPPORTED, currentStatus)}
        />
        <IdeaStatusStep
          text={ideaStatusStepper.roundClosedLabel}
          mainCircleValue={100}
          secondaryCircleValue={40}
          icon="icon-share"
          secondaryColor={ideaStatusStepper.roundClosedColor}
          statusType={getTypeFromStatus(ideaStatusMapper.ROUND_CLOSED, currentStatus)}
        />
        <IdeaStatusStep
          text={ideaStatusStepper.approvedLabel}
          mainCircleValue={100}
          secondaryCircleValue={0}
          icon="icon-approved"
          mainColor={ideaStatusStepper.approvedColor}
          statusType={getTypeFromStatus(ideaStatusMapper.APPROVED_BY_IT, currentStatus)}
        />
        <IdeaStatusStep
          text={ideaStatusStepper.pilotActiveLabel}
          mainCircleValue={100}
          secondaryCircleValue={0}
          icon="icon-prototype"
          mainColor={ideaStatusStepper.pilotActiveColor}
          statusType={getTypeFromStatus(ideaStatusMapper.PILOT_ACTIVE, currentStatus)}
        />
        {isDiscardedStatus ? (
          <IdeaStatusStep
            text={ideaStatusStepper.discardedLabel}
            mainCircleValue={100}
            secondaryCircleValue={0}
            icon="icon-close"
            mainColor={ideaStatusStepper.discardedColor}
            statusType={getTypeFromStatus(ideaStatusMapper.DISCARDED, currentStatus)}
          />
        ) : (
          <IdeaStatusStep
            text={ideaStatusStepper.implementedLabel}
            mainCircleValue={100}
            secondaryCircleValue={0}
            icon="icon-ranking"
            mainColor={ideaStatusStepper.implementedColor}
            statusType={getTypeFromStatus(ideaStatusMapper.IMPLEMENTED, currentStatus)}
          />
        )}
      </div>
      {(isApprovedStatus || isDiscardedStatus) && messageText && (
        <Paper
          className={clsx(classes.messagePaper, isDiscardedStatus && classes.messagePaperDiscarded)}
          id={isApprovedStatus ? 'approved-paper' : 'discarded-paper'}
        >
          {messageTitle && <span className={classes.messageTitle}>{messageTitle.replaceAll("idea", customIdeaTitle)}</span>}
          <span
            className={classes.messageText}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: messageText }}
          />
        </Paper>
      )}
    </Fragment>
  )
}

StatusSection.propTypes = {
  ideaStatusStepper: PropTypes.shape({
    approvedLabel: PropTypes.string.isRequired,
    approvedColor: PropTypes.string.isRequired,
    discardedLabel: PropTypes.string.isRequired,
    discardedColor: PropTypes.string.isRequired,
    implementedLabel: PropTypes.string.isRequired,
    implementedColor: PropTypes.string.isRequired,
    pendingSupportLabel: PropTypes.string.isRequired,
    pendingSupportColor: PropTypes.string.isRequired,
    pilotActiveLabel: PropTypes.string.isRequired,
    pilotActiveColor: PropTypes.string.isRequired,
    roundClosedLabel: PropTypes.string.isRequired,
    roundClosedColor: PropTypes.string.isRequired,
    supportedLabel: PropTypes.string.isRequired,
    supportedColor: PropTypes.string.isRequired,
  }).isRequired,
  currentStatus: PropTypes.string.isRequired,
  messageText: PropTypes.string.isRequired,
  messageTitle: PropTypes.string.isRequired,
  customIdeaTitle: PropTypes.string.isRequired
}

export default withStyles(styles)(StatusSection)
