/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { debounce } from 'debounce'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Boost from 'highcharts/modules/boost'
import NoData from 'highcharts/modules/no-data-to-display'
import i18n from 'support/i18n'
import { getLocaleShortDate } from 'support/date_fns_locale'
import styles from './View.Style'

Boost(Highcharts)
NoData(Highcharts)

class NxtStockChart extends Component {
  constructor(props) {
    super(props)
    this.chartRef = null
    const { title, onChangeDates, series, isLoading, legend, colors, minRange } = props

    this.state = {
      options: {
        title: { text: title },
        lang: {
          noData: isLoading
            ? ''
            : i18n.t('DASHBOARD.DATA_NOT_AVAILABLE_FOR_PERIOD')
        },
        noData: { style: { fontWeight: 'bold', fontSize: '15px', color: '#303030' } },
        plotOptions: { series: { boostThreshold: 1 } },
        tooltip: {
          formatter() {
            const header = `<b>${getLocaleShortDate(new Date(this.x))}<b>`
            const body = this.points
              .map(point => {
                return `<br><b><span style="color: ${point.color}">${point.series.name}:</span><span>${point.y}</span></b>`
              })
              .join('')
            return `${header}${body}`
          },
          shared: true,
        },
        rangeSelector: {
          inputEnabled: false,
          buttons: [
            { type: 'month', count: 1, text: i18n.t('STOCK_CHART.ONE_MONTH_TEXT') },
            { type: 'month', count: 3, text: i18n.t('STOCK_CHART.THREE_MONTH_TEXT') },
            { type: 'ytd', text: i18n.t('STOCK_CHART.YEAR_TO_DATE_TEXT') },
            { type: 'all', text: i18n.t('STOCK_CHART.ALL_TEXT') },
          ],
        },
        colors,
        legend,
        xAxis: {
          minRange,
          events: {
            afterSetExtremes: debounce(e => {
              if (e && e.min && e.max) {
                const { min, max } = e
                const fromDate = new Date(min)
                const toDate = new Date(max)
                onChangeDates({ fromDate, toDate })
              }
            }, 500),
          },
        },
        series,
      },
    }
    this.onChartCreated = this.onChartCreated.bind(this)
  }

  componentDidMount() {
    const { fromDate, toDate, isLoading } = this.props
    if (this.chartRef) {
      this.chartRef.xAxis[0].setExtremes(fromDate.getTime(), toDate.getTime())
      if (isLoading) {
        this.chartRef.showLoading()
      } else {
        this.chartRef.hideLoading()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { fromDate, toDate, isLoading, series, colors } = this.props
    const { options } = this.state

    if ((prevProps.fromDate !== fromDate || prevProps.toDate !== toDate) && this.chartRef) {
      this.chartRef.xAxis[0].setExtremes(fromDate.getTime(), toDate.getTime())
    }
    if (prevProps.isLoading !== isLoading && isLoading) {
      this.chartRef.showLoading()
      this.setState({
        options: { ...options, lang: { noData: '' } },
      })
    }
    if (prevProps.isLoading !== isLoading && !isLoading) {
      this.chartRef.hideLoading()
      this.setState({
        options: {
          ...options,
          lang: { noData: i18n.t('DASHBOARD.DATA_NOT_AVAILABLE_FOR_PERIOD') },
        },
      })
    }
    if (prevProps.series !== series) {
      this.setState({
        options: { ...options, series },
      })
    }
    if (colors.find((color, index) => color !== prevProps.colors[index])) {
      this.setState({
        options: { ...options, colors },
      })
    }
  }

  onChartCreated(chartReference) {
    this.chartRef = chartReference
  }

  render() {
    const { options } = this.state

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        constructorType="stockChart"
        callback={this.onChartCreated}
      />
    )
  }
}

NxtStockChart.defaultProps = {
  title: '',
  onChangeDates: () => { },
  legend: {},
  colors: ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
  minRange: undefined,
}

NxtStockChart.propTypes = {
  title: PropTypes.string,
  onChangeDates: PropTypes.func,
  fromDate: PropTypes.instanceOf(Date).isRequired,
  toDate: PropTypes.instanceOf(Date).isRequired,
  isLoading: PropTypes.bool.isRequired,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.array,
    }),
  ).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  legend: PropTypes.object,
  colors: PropTypes.arrayOf(PropTypes.string),
  minRange: PropTypes.number,
}

export default withStyles(styles)(NxtStockChart)
