import React from 'react'
import * as PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const ActiveFilters = (props) => {
  const { activeFilters, classes, showChallenges } = props
  const {
    status,
    challenge,
    organizations,
    types,
    attributes,
  } = activeFilters

  return (
    <div className={classes.container}>
      {status.map(element => (
        <Chip
          className={classes.chips}
          color="primary"
          key={element.id}
          label={element.name}
        />
      ))}

      {showChallenges && challenge.id && (
        <Chip className={classes.chips} color="primary" label={challenge.name} />
      )}

      {organizations.map(element => (
        <Chip className={classes.chips} color="primary" key={element.id} label={element.name} />
      ))}

      {types.map(element => (
        <Chip className={classes.chips} color="primary" key={element.id} label={element.name} />
      ))}

      {attributes.map(element => (
        <Chip className={classes.chips} color="primary" key={element.id} label={element.name} />
      ))}
    </div>
  )
}

ActiveFilters.defaultProps = {
  showChallenges: true,
}

ActiveFilters.propTypes = {
  activeFilters: PropTypes.shape({
    challenge: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ).isRequired,
    status: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string
    })).isRequired,
    types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    chips: PropTypes.string.isRequired,
  }).isRequired,
  showChallenges: PropTypes.bool,
}

export default withStyles(styles)(ActiveFilters)
