import React from 'react'
import { connect } from 'react-redux'
import { showPopupDialog } from "../popup_dialogs"
import SplashDialog from './components/splash_dialog'
import View from './View'

const mapStateToProps = (state) => {
  return {
    dataLoaded: state.data.domain.dataLoaded,
    isSplashActive: state.data.user.prefs.splash || false,
    userLegalAcceptance: state.data.user.legalAcceptance,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSplashModal: () => {
      showPopupDialog(<SplashDialog />, dispatch)
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
