import React from 'react'
import { Checkbox, withStyles } from '@material-ui/core'
import styles from './View.Styles'

const NxtCheckbox = ({ 
  classes, 
  isChecked, 
  onChange, 
  name,
}) => {
  return (
    <Checkbox
      className={classes.root}
      name={name}
      color="default"
      disableRipple
      disableFocusRipple
      checked={isChecked}
      icon={<span className={classes.checkboxNotChecked} />}
      checkedIcon={<span className="icon-approved" />}
      onChange={onChange}
    />
  )
}

export default withStyles(styles)(NxtCheckbox)
