import { ServiceCall } from 'support/network/ServiceCallAction'
import * as userProfileActions from 'actions/sync/user_profile/Constants'
import {
  transformGetMyProfileResponse,
  transformGetUserProfileResponse,
  transformGetUserIdeasResponse,
  transformGetUserIdeasTeamResponse,
  transformGetUserContributionsResponse,
  transformTimelineResponse,
  transformGetUserOrganisationsResponse,
} from './ResponseUtils'
import { setOrganisationsIds } from './RequestUtils'

export const GET_MY_PROFILE_URL = '/s/user/me'
export const GET_USER_PROFILE_URL = '/s/user/profile?e='
export const GET_USER_IDEAS_URL = '/api/v1/user/##email##/idea'
export const GET_USER_IDEAS_TEAM_URL = '/api/v1/user/##email##/idea-team'
export const GET_USER_CONTRIBUTIONS_URL = '/api/v1/user/##email##/contributions'
export const GET_USER_IDEAS_PILOT_TEAM_URL = '/api/v1/user/##email##/idea-initiative-team'
export const GET_USER_ORGANISATIONS_URL = '/api/v1/user/organizationsfilter?limit=50&offset=0'
export const PUT_USER_ORGANISATIONS_URL = '/api/v1/user/organizationsfilter'
const GET_USER_TIMELINE_URL = '/api/v1/user/##email##/timeline'

export function getMyProfile(onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userProfileActions.GET_MY_PROFILE_STARTED
  apiCall.successReqType = userProfileActions.GET_MY_PROFILE_SUCCESS
  apiCall.failureReqType = userProfileActions.GET_MY_PROFILE_FAILURE
  apiCall.setEndpointFromState = () => GET_MY_PROFILE_URL
  apiCall.method = 'GET'
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformGetMyProfileResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function getUserProfile(email, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userProfileActions.GET_USER_PROFILE_STARTED
  apiCall.successReqType = userProfileActions.GET_USER_PROFILE_SUCCESS
  apiCall.failureReqType = userProfileActions.GET_USER_PROFILE_FAILURE
  apiCall.setEndpointFromState = () => `${GET_USER_PROFILE_URL}${email}`
  apiCall.method = 'GET'
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformGetUserProfileResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function getUserIdeas(email) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userProfileActions.GET_USER_IDEAS_STARTED
  apiCall.successReqType = userProfileActions.GET_USER_IDEAS_SUCCESS
  apiCall.failureReqType = userProfileActions.GET_USER_IDEAS_FAILURE
  apiCall.setEndpointFromState = () => `${GET_USER_IDEAS_URL.replace('##email##', btoa(email))}`
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = transformGetUserIdeasResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function getUserIdeasTeam(email) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userProfileActions.GET_USER_IDEAS_TEAM_STARTED
  apiCall.successReqType = userProfileActions.GET_USER_IDEAS_TEAM_SUCCESS
  apiCall.failureReqType = userProfileActions.GET_USER_IDEAS_TEAM_FAILURE
  apiCall.setEndpointFromState = () => `${GET_USER_IDEAS_TEAM_URL.replace('##email##', btoa(email))}`
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = transformGetUserIdeasTeamResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function getUserIdeasPilotTeam(email) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userProfileActions.GET_USER_IDEAS_PILOT_TEAM_STARTED
  apiCall.successReqType = userProfileActions.GET_USER_IDEAS_PILOT_TEAM_SUCCESS
  apiCall.failureReqType = userProfileActions.GET_USER_IDEAS_PILOT_TEAM_FAILURE
  apiCall.setEndpointFromState = () =>
    `${GET_USER_IDEAS_PILOT_TEAM_URL.replace('##email##', btoa(email))}`
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = transformGetUserIdeasTeamResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function getUserOrganisations() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userProfileActions.GET_USER_ORGANISATIONS_STARTED
  apiCall.successReqType = userProfileActions.GET_USER_ORGANISATIONS_SUCCESS
  apiCall.failureReqType = userProfileActions.GET_USER_ORGANISATIONS_FAILURE
  apiCall.setEndpointFromState = () => GET_USER_ORGANISATIONS_URL
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = transformGetUserOrganisationsResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function putUserOrganisations(ids, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userProfileActions.PUT_USER_ORGANISATIONS_STARTED
  apiCall.successReqType = userProfileActions.PUT_USER_ORGANISATIONS_SUCCESS
  apiCall.failureReqType = userProfileActions.PUT_USER_ORGANISATIONS_FAILURE
  apiCall.setEndpointFromState = () => PUT_USER_ORGANISATIONS_URL
  apiCall.method = 'PUT'
  apiCall.setBodyFromState = () => setOrganisationsIds(ids)
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export function getUserContributions(email) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userProfileActions.GET_USER_CONTRIBUTIONS_STARTED
  apiCall.successReqType = userProfileActions.GET_USER_CONTRIBUTIONS_SUCCESS
  apiCall.failureReqType = userProfileActions.GET_USER_CONTRIBUTIONS_FAILURE
  apiCall.setEndpointFromState = () => `${GET_USER_CONTRIBUTIONS_URL.replace('##email##', btoa(email))}`
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = transformGetUserContributionsResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function getUserTimeline(userEmail) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userProfileActions.GET_USER_TIMELINE_STARTED
  apiCall.successReqType = userProfileActions.GET_USER_TIMELINE_SUCCESS
  apiCall.failureReqType = userProfileActions.GET_USER_TIMELINE_FAILURE
  apiCall.setEndpointFromState = state =>
    `${GET_USER_TIMELINE_URL.replace('##email##', btoa(userEmail))}?limit=${
      state.data.userProfile.timelineLimit
    }&offset=${state.data.userProfile.timelineOffset}`
  apiCall.transformResponseDataWithState = transformTimelineResponse
  apiCall.method = 'GET'
  apiCall.auth = true
  return apiCall.getAction()
}
