import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import NavigationService from 'support/navigation/NavigationService'
/* import SideLogin from 'components/side_login/View' */
import clsx from 'clsx'
import SignIn from './components/sign_in/View'
import styles from './View.Style'
import { GOOGLE_AUTH_ERRORS } from './View.Constants'
import { RenderCustomSide } from './components/RenderCustomSide'

const Login = props => {
  let isGoogleAuthLoaded = !!window.gapi
  if (!isGoogleAuthLoaded) {
    const script = document.createElement('script')

    script.src = 'https://apis.google.com/js/api.js'
    script.async = true
    script.defer = true
    script.onload = () => {
      isGoogleAuthLoaded = true
    }

    document.body.appendChild(script)
  }
  const {
    classes,
    onLoad,
    onSubmit,
    useCustomColor,
    customColor,
    useCustomTextColor,
    customTextColor,
    useCustomImg,
    customImg,
    useCustomText,
    customText,
    useCustomBgImg,
    customBgImg,
    newBgImg,
    useCustomLogin,
    onForgotPasswordClick,
    messageType,
    messageText,
    isLogging,
    resetAuthMessageBox,
    isEmailNotVerifiedYet,
    onSendMeActivationEmailClick,
    onCreateAccountClick,
    postGoogleLoginResult,
    setAuthMessageBox,
    isGoogleAuthEnabled,
    isAzureAuthEnabled,
    useCustomUrlLanding,
    customUrlLanding,
    isLoadedLoginInfo,
    onAzureClick,
    isAdfsAuthEnabled,
    onAdfsClick,
    useCreateAccount,
    onTokenCaptured,
    showLoader,
    useCustomSubTitle,
    customSubTitle,
    useCustomSubTitleWelcome,
    customSubTitleWelcome,
    useCustomWelcome,
    customWelcome,
    customRrss,
    customAppStoreLinks
  } = props

  const customHistory = NavigationService.getHistory()
  const [visible, setVisible] = useState(!useCustomLogin)
  const [hasLoginToken, setHasLoginToken] = useState(true)

  useEffect(() => {
    onLoad()
    resetAuthMessageBox()
    const { search } = NavigationService.getHistory().location
    if (search.includes('token')) {
      showLoader()
      setHasLoginToken(true)
      const token = search
        .split('token')[1]
        .split('=')[1]
        .split('&')[0]
      onTokenCaptured(token)
    } else {
      setHasLoginToken(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoadedLoginInfo && useCustomUrlLanding && customUrlLanding) {
      window.location.href = customUrlLanding
    } else if (isLoadedLoginInfo && (!useCustomUrlLanding || !customUrlLanding)) {
      setVisible(true)
    }
  }, [isLoadedLoginInfo, useCustomUrlLanding, customUrlLanding])

  const handleGoogleAuthClick = () => {
    resetAuthMessageBox()
    if (isGoogleAuthLoaded && isGoogleAuthEnabled) {
      window.gapi.load('auth2', async () => {
        try {
          const auth2 = window.gapi.auth2.init({
            client_id: process.env.REACT_APP_GPLUS_OAUTH_CLIENT_ID,
          })
          const googleResponse = await auth2.grantOfflineAccess({
            cookiepolicy: 'single_host_origin',
            prompt: 'select_account',
            scope: 'profile email https://www.googleapis.com/auth/drive.file',
          })
          if (googleResponse.code) {
            postGoogleLoginResult(googleResponse.code)
          } else {
            setAuthMessageBox(i18n.t('COMMON_LOGIN.HEADER.GOOGLE_AUTH_ERROR'))
          }
        } catch (err) {
          const { error } = err
          if (error !== GOOGLE_AUTH_ERRORS.POPUP_CLOSED_BY_USER) {
            setAuthMessageBox(i18n.t('COMMON_LOGIN.HEADER.GOOGLE_AUTH_ERROR'))
          }
        }
      })
    } else {
      setAuthMessageBox(i18n.t('COMMON_LOGIN.HEADER.GOOGLE_AUTH_NOT_LOADED'))
    }
  }

  const handleForgotPasswordClick = (email) => {
    const urlDomain = customHistory.location.pathname.split('/')[1]
    onForgotPasswordClick(email, urlDomain)
  }

  return (
    <>
      {!hasLoginToken && (
        <div className={clsx(classes.base, !visible && classes.notVisible)}>
          {/* {useCustomLogin ? ( */}
            <RenderCustomSide
              classes={classes}
              useCustomImg={useCustomImg}
              customImg={customImg}
              useCustomText={useCustomText}
              customText={customText}
              useCustomSubTitle={useCustomSubTitle}
              customSubTitle={customSubTitle}
              customRrss={customRrss}
            />
          {/* ) : (
            {<SideLogin />}
          )} */}
          <SignIn
            isAdfsAuthEnabled={isAdfsAuthEnabled}
            onClickAdfsAuth={onAdfsClick}
            isAzureAuthEnabled={isAzureAuthEnabled}
            onClickAzureAuth={onAzureClick}
            isGoogleAuthEnabled={isGoogleAuthEnabled}
            onClickGoogleAuth={handleGoogleAuthClick}
            isEmailNotVerifiedYet={isEmailNotVerifiedYet}
            onSendMeActivationEmailClick={onSendMeActivationEmailClick}
            isLogging={isLogging}
            messageType={messageType}
            messageText={messageText}
            onForgotPasswordClick={handleForgotPasswordClick}
            onSubmit={onSubmit}
            useCustomColor={useCustomColor}
            customColor={customColor}
            useCustomTextColor={useCustomTextColor}
            customTextColor={customTextColor}
            useCustomImg={useCustomImg}
            customImg={customImg}
            useCustomText={useCustomText}
            customText={customText}
            useCustomBgImg={useCustomBgImg}
            customBgImg={customBgImg}
            newBgImg={newBgImg}
            useCustomLogin={useCustomLogin}
            onCreateAccountClick={onCreateAccountClick}
            appStores={!useCustomLogin}
            usePolicies={!useCustomLogin}
            useCreateAccount={useCreateAccount}
            useCustomSubTitleWelcome={useCustomSubTitleWelcome}
            customSubTitleWelcome={customSubTitleWelcome}
            useCustomWelcome={useCustomWelcome}
            customWelcome={customWelcome}
            customAppStoreLinks={customAppStoreLinks}
          />
        </div>
      )}
    </>
  )
}

Login.defaultProps = {
  useCustomColor: false,
  customColor: '',
  useCustomTextColor: false,
  customTextColor: '',
  useCustomImg: false,
  customImg: '',
  useCustomText: false,
  customText: '',
  useCustomBgImg: false,
  customBgImg: '',
  newBgImg: 'img/image_3.png',
  useCustomUrlLanding: false,
  customUrlLanding: '',
  useCustomLogin: false,
  isLoadedLoginInfo: false,
  onCreateAccountClick: null,
  onLoad: () => { },
  setAuthMessageBox: () => { },
  onAzureClick: () => { },
  onAdfsClick: () => { },
  isGoogleAuthEnabled: false,
  isAzureAuthEnabled: false,
  isAdfsAuthEnabled: false,
  useCreateAccount: false,
  onTokenCaptured: () => { },
  showLoader: () => { },
  useCustomSubTitle: false,
  useCustomSubTitleWelcome: false,
  useCustomWelcome: false,
  customSubTitle: '',
  customSubTitleWelcome: '',
  customWelcome: '',
  customRrss: [],
  customAppStoreLinks: {
    androidUrl: "",
    iosUrl: ""
  }
}

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  useCustomColor: PropTypes.bool,
  customColor: PropTypes.string,
  useCustomTextColor: PropTypes.bool,
  customTextColor: PropTypes.string,
  useCustomImg: PropTypes.bool,
  customImg: PropTypes.string,
  useCustomText: PropTypes.bool,
  customText: PropTypes.string,
  useCustomBgImg: PropTypes.bool,
  customBgImg: PropTypes.string,
  newBgImg: PropTypes.string,
  useCustomLogin: PropTypes.bool,
  useCustomUrlLanding: PropTypes.bool,
  customUrlLanding: PropTypes.string,
  isLoadedLoginInfo: PropTypes.bool,
  isLogging: PropTypes.bool.isRequired,
  isEmailNotVerifiedYet: PropTypes.bool.isRequired,
  messageType: PropTypes.number.isRequired,
  messageText: PropTypes.string.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
  onSendMeActivationEmailClick: PropTypes.func.isRequired,
  resetAuthMessageBox: PropTypes.func.isRequired,
  postGoogleLoginResult: PropTypes.func.isRequired,
  setAuthMessageBox: PropTypes.func,
  onCreateAccountClick: PropTypes.func,
  onLoad: PropTypes.func,
  onAzureClick: PropTypes.func,
  onAdfsClick: PropTypes.func,
  isGoogleAuthEnabled: PropTypes.bool,
  isAzureAuthEnabled: PropTypes.bool,
  isAdfsAuthEnabled: PropTypes.bool,
  useCreateAccount: PropTypes.bool,
  onTokenCaptured: PropTypes.func,
  showLoader: PropTypes.func,
  useCustomSubTitle: PropTypes.bool,
  useCustomSubTitleWelcome: PropTypes.bool,
  useCustomWelcome: PropTypes.bool,
  customSubTitle: PropTypes.string,
  customSubTitleWelcome: PropTypes.string,
  customWelcome: PropTypes.string,
  customRrss: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    isChecked: PropTypes.bool
  })),
  customAppStoreLinks: PropTypes.shape({
    androidUrl: PropTypes.string,
    iosUrl: PropTypes.string
  })
}

export default withStyles(styles)(Login)
