import { connect } from 'react-redux'
import Navigation from 'support/navigation'
import { applySearchService, applyFilterService } from 'services/ideas'
import { getActiveOrganizations } from 'services/organizations'
import { getActiveTypes } from 'services/types'
import { getActiveAttributes } from 'services/attributes'
import { getActiveChallenges } from 'services/challenges'
import { FIRST_LIMIT_CONSTANT } from 'common_constants/Ideas'
import { getStatusSelectList } from 'pages/explore/View.Utils'
import View from './View'

const mapStateToProps = state => {
  return {
    domainLoaded: state.data.domain.dataLoaded,
    challenges: state.data.challenge.challenges,
    challenge: state.data.challenge.challengeById,
    isAdmin: state.data.user.admin,
    isInnovator: state.data.user.isInnovator,
    isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
    isTypesActive: state.data.domain.domainConfiguration.isTypesActive,
    isAttributesActive: state.data.domain.domainConfiguration.isAttributesActive,
    ideas: state.data.idea.data,
    totalIdeas: state.data.idea.total,
    organizations: state.data.domain.organizations,
    organizationsName: state.data.domain.domainConfiguration.organizationsName,
    typesName: state.data.domain.domainConfiguration.typesName,
    types: state.data.domain.types,
    attributesName: state.data.domain.domainConfiguration.attributesName,
    attributes: state.data.domain.attributes,
    hasMoreIdeasToLoad: state.data.idea.hasMore,
    isIdeasLoading: state.data.idea.ideasLoading,
    statusList: getStatusSelectList(state.data.domain.domainConfiguration.ideaStatusStepper),
    customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getActiveChallenges: () => dispatch(getActiveChallenges()),
    getPrivateIdeas: ({ challenge, organizations, status, types, attributes, offset } = {}) => {
      dispatch(
        applyFilterService({
          challenge,
          organizations,
          status,
          types,
          attributes,
          limit: FIRST_LIMIT_CONSTANT,
          offset,
          shouldLoadPrivateIdeas: true,
        }),
      )
    },
    applySearch: search => {
      dispatch(applySearchService({ search, shouldLoadPrivateIdeas: true }))
    },
    getOrganization: () => {
      dispatch(getActiveOrganizations())
    },
    getTypes: () => {
      dispatch(getActiveTypes())
    },
    getAttributes: () => dispatch(getActiveAttributes()),
    navigateToIdeaDetail: id => {
      dispatch(Navigation.navigateToIdea(id))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
