/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, ButtonBase } from '@material-ui/core'
import Navigation from 'support/navigation'
import i18n from 'support/i18n'
import { Image } from 'components'
import defaultPhoto from 'img/default-photo.png'
import styles from './View.Style'

const MostActiveUsers = ({ classes, data, customIdeaTitles }) => {
  const onUserClick = (userEmail) => {
    Navigation.navigateToUserProfile(userEmail)
  }

  const renderUser = ({
    email,
    photo,
    fullname,
    comments,
    ideasPublished,
    investments
  }) => {
    return (
      <div key={email} className={classes.user}>
        <ButtonBase className={classes.userButton} onClick={() => onUserClick(email)}></ButtonBase>
        <div className="left">
          <Image
            defaultSrc={defaultPhoto}
            src={photo}
            alt={fullname}
          />
        </div>
        <div className="right user-info">
          <div className="fullname">{fullname}</div>
          <div className="user-data-block">
            <div className="data-block">
              <span className="data-block-title">{i18n.t('DASHBOARD.IDEAS', { title: customIdeaTitles?.pluralTitle || "ideas" })}</span>
              <div className="data-block-number">
                <span className="amount" data-jq-text="ideas">{ideasPublished}</span>
                <b className="icon-idea pull-right"></b>
              </div>
            </div>
            <div className="data-block">
              <span className="data-block-title">{i18n.t('DASHBOARD.INVESTMENTS')}</span>
              <div className="data-block-number">
                <span className="amount" data-jq-text="investments">{investments}</span>
                <b className="icon-evolution pull-right"></b>
              </div>
            </div>
            <div className="data-block">
              <span className="data-block-title">{i18n.t('DASHBOARD.COMMENTS')}</span>
              <div className="data-block-number">
                <span className="amount" data-jq-text="comments">{comments}</span>
                <b className="icon-comment pull-right"></b>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>{i18n.t('DASHBOARD.MOST_ACTIVE_USERS')}</div>
      {!!data.length && data.map((userData) => {
        return renderUser({
          email: userData.user.email,
          photo: userData.user.photo,
          fullname: userData.user.fullname,
          comments: userData.comments,
          ideasPublished: userData.ideas_published,
          investments: userData.investments,
        })
      })}
    </div>
  )
}

MostActiveUsers.propTypes = {
  data: PropTypes.array,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(MostActiveUsers)
