import React, { useEffect, useState } from 'react'
import { TextField, withStyles } from '@material-ui/core'
import clsx from 'clsx'
import SuggestionList from 'components/autosuggestion/components/suggestion_list/View'
import styles from './View.Style'

const MentionTextField = props => {
  const {
    classes,
    className,
    resetUserSuggestions,
    label,
    onTextChange,
    textValue,
    onMentionType,
    userSuggestions,
    id,
  } = props

  const [isFocusedText, setIsTextFocused] = useState(false)

  const handleCommentText = event => {
    const { value } = event.target
    const userMatches = value.match(/^\B@(\w+)|[^<]\B@(\w+)/gm)

    if (userMatches && userMatches[0].length > 1) {
      const extractedValue = userMatches[0].trim().replace('@', '')
      if (extractedValue.length >= 3) {
        onMentionType(extractedValue)
      }
    }

    onTextChange(value)
  }

  const handleSuggestionSelect = user => {
    const firstCharacter = textValue
      .match(/^\B@(\w+)|[^<]\B@(\w+)/)[0]
      .charAt(0)
      .replace('@', '')

    onTextChange(
      textValue.replace(/^\B@(\w+)|[^<]\B@(\w+)/, `${firstCharacter}<@${user.fullName}>`),
    )

    resetUserSuggestions()
  }

  const handleResetSearch = () => {
    if (userSuggestions.length > 0) {
      setTimeout(() => {
        setIsTextFocused(false)
        resetUserSuggestions()
      }, 300)
    }
  }

  useEffect(() => {
    const listItem = document.querySelector(`#users-list-${id}`)
    if (listItem) listItem.scrollIntoView()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSuggestions])

  return (
    <div className={clsx(classes.root, className)}>
      <TextField
        multiline
        fullWidth
        label={label}
        minRows={3}
        onBlur={handleResetSearch}
        variant="outlined"
        value={textValue}
        onChange={handleCommentText}
        onFocus={() => setIsTextFocused(true)}
      />
      {isFocusedText && userSuggestions.length > 0 && (
        <div id={`users-list-${id}`} className={classes.suggestionList}>
          <SuggestionList
            lastSuggestionIndex={userSuggestions.length - 1}
            onSuggestionSelect={handleSuggestionSelect}
            suggestions={userSuggestions}
            listWidth="400px"
          />
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(MentionTextField)
