import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    appLogo: {
      width: '100%',
      maxWidth: '130px',
      height: 'auto',
    },
    button: {
      margin: theme.spacing(1),
      color: 'white',
      borderColor: 'white',
      fontWeight: 'bold',
    },
    footer: {
      gridArea: 'footer',
      display: 'grid',
      gridTemplateColumns: 'auto 960px auto',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '". footerContent ."',
      padding: '2rem 0',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    footerContent: {
      gridArea: 'footerContent',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.palette.headerText ? theme.palette.headerText.main : '#FFF',
    },
    summary: {
      width: '170px',
    },
    summaryIcon: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& b': {
        fontSize: '40px',
      },
      '& span': {
        fontSize: '32px',
        fontFamily: '"HelveticaNeueCondensedBold", "Helvetica Neue", sans-serif',
        fontStretch: 'condensed',
        fontWeight: 'bold',
      },
    },
    summaryText: {
      fontSize: '12px',
      paddingLeft: 44,
      display: 'inline-block',
    },
    links: {
      gridArea: 'links',
      display: 'grid',
      gridTemplateColumns: 'auto',
      gridTemplateRows:
        '[linksContent]',
      gridTemplateAreas: '". linksContent ." ". customizedMessageFooter ."',
      padding: '1rem 0',
      backgroundColor: '#f0f0f0',
      fontSize: '11px',
      transition: 'all .2s ease-in-out',
    },
    linksContent: {
      gridArea: 'linksContent',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      '& span': {
        color: 'rgba(0, 0, 0, 0.5)',
      },
      '& a': {
        color: 'rgba(0, 0, 0, 0.5)',
        fontWeight: 'bolder',
        textDecoration: 'none',
      },
      '& a:visited': {
        color: 'rgba(0, 0, 0, 0.5)',
      },
    },
    customizedMessageFooter: {
      gridArea: 'customizedMessageFooter',
      display: 'flex',
      color: 'darkgray',
      marginBottom: '1rem',
      fontSize: '14px',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
    },
    copyrightButton: {
      color: 'rgba(0, 0, 0, 0.5)',
      fontWeight: 'bolder',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
      '&:focus': {
        textDecoration: 'none',
      },
    },
  })

export default styles
