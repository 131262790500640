const styles = theme => ({
  submitterLinkButton: {
    fontSize: 'inherit',
    color: theme.palette.primary.main,
    marginLeft: '3px',
  },
  submitterSpan: {
    fontSize: 'inherit',
    marginLeft: theme.spacing(0.6),
  },
  button: {
    fontSize: '0.7rem',
  },
  buttonBlock: {
    position: 'relative',
    zIndex: 101,
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      flex: props => (props.onClickSecondaryButton ? 0.48 : 1),
    },
  },
  circleBase: {
    padding: '0.3rem',
  },
  container: {
    position: 'relative',
    '& .image': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  descriptionBlock: {
    height: '7rem',
    boxSizing: 'border-box',
    '& > div:first-child': {
      flex: '1',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxHeight: '36px',
    },
  },
  graph: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  list: {
    backgroundColor: '#f0f0f0',
    padding: '4%',
    height: 'auto',
    margin: 0,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    '& > li': {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.4rem 0',
    },
    '& > li:first-child': {
      paddingTop: 0,
    },
    '& > li:last-child': {
      borderBottom: 0,
      paddingBottom: 0,
    },
  },
  listLight: {
    backgroundColor: '#f0f0f0',
    color: 'black',
  },
  listDark: {
    backgroundColor: 'unset',
    color: 'white',
  },
  pledged: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '60%',
    borderRight: '1px',
    borderRightColor: '#666',
    borderRightStyle: 'solid',
    '& > div': {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      '& > i': {
        fontSize: '1rem',
      },
      '& > span': {
        fontSize: '1.2rem',
        fontWeight: 'bold',
      },
    },
    '& > span': {
      fontSize: '0.75rem',
    },
  },
  investors: {
    display: 'flex',
    flexFlow: 'column nowrap',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  singleId: {
    fontSize: '0.9rem',
    marginTop: '5px',
    marginBottom: '10px',
  },
  submittedBy: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ownerPhoto: {
    marginRight: '10px',
    '& img': {
      width: '32px',
      height: '32px',
      borderRadius: '32px',
    }
  },
  altTextBlock: {
    backgroundColor: '#666',
    padding: '0.5rem 1rem',
    marginLeft: '-0.5rem',
    marginRight: '-0.5rem',
    marginBottom: '-0.5rem',
  },
  back: {
    position: 'relative',
  },
  cardFullButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }
})

export default styles
