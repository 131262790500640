import React, { Component } from 'react'
import i18n from 'support/i18n'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import FormLabel from './components/form_control_label/View'
import styles from './View.Style'

class UserPreferences extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ideastatus: props.ideastatus,
      invest: props.invest,
      week: props.week,
      splash: props.splash,
      newidea: props.newidea,
      challenge: props.challenge,
      newcoment: props.newcoment,
    }

    this.handleOnCheckPrefs = this.handleOnCheckPrefs.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { ideastatus, invest, week, splash, newidea, challenge, newcoment } = this.props
    if (prevProps !== this.props) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ideastatus, invest, week, splash, newidea, challenge, newcoment })
    }
  }

  handleOnCheckPrefs(key) {
    const { getIdeaStatusPref } = this.props
    // eslint-disable-next-line react/destructuring-assignment
    const value = this.state[key]
    getIdeaStatusPref(key, !value)
    this.setState({ [key]: !value })
  }

  render() {
    const { classes, customIdeaTitles } = this.props
    const { ideastatus, invest, week, splash, newidea, challenge, newcoment } = this.state

    return (
      <div className={classes.top}>
        <h1>
          <i className="icon-settings" /> {i18n.t('PREFERENCES')}
        </h1>
        <div className={classes.box}>
          <span className={classes.title}>{i18n.t('USERPREF.IDEA_STATUS_TEXT')}</span>
          <FormLabel
            key="ideastatus"
            title={i18n.t('USERPREF.IDEA_STATUS_TITLE', { title: customIdeaTitles.pluralTitle || "ideas" })}
            description={i18n.t('USERPREF.IDEA_STATUS_DESC', { title: customIdeaTitles.pluralTitle || "ideas" })}
            value={ideastatus}
            onClick={() => this.handleOnCheckPrefs("ideastatus")}
          />
          <FormLabel
            key="invest"
            title={i18n.t('USERPREF.INVEST_TITLE')}
            description={i18n.t('USERPREF.INVEST_DESC', { title: customIdeaTitles.singularTitle || "idea" })}
            value={invest}
            onClick={() => this.handleOnCheckPrefs("invest")}
          />
          <FormLabel
            key="week"
            title={i18n.t('USERPREF.WEEK_TITLE')}
            description={i18n.t('USERPREF.WEEK_DESC')}
            value={week}
            onClick={() => this.handleOnCheckPrefs("week")}
          />
          <FormLabel
            key="splash"
            title={i18n.t('USERPREF.SPLASH_TITLE')}
            description={i18n.t('USERPREF.SPLASH_DESC')}
            value={splash}
            onClick={() => this.handleOnCheckPrefs("splash")}
          />
          <FormLabel
            key="newidea"
            title={i18n.t('USERPREF.NEWIDEA_TITLE', { title: customIdeaTitles.singularTitle || "idea" })}
            description={i18n.t('USERPREF.NEWIDEA_DESC', { title: customIdeaTitles.singularTitle || "idea" })}
            value={newidea}
            onClick={() => this.handleOnCheckPrefs("newidea")}
          />
          <FormLabel
            key="challenge"
            title={i18n.t('USERPREF.CHALLENGE_TITLE')}
            description={i18n.t('USERPREF.CHALLENGE_DESC')}
            value={challenge}
            onClick={() => this.handleOnCheckPrefs("challenge")}
          />
          <FormLabel
            key="newcoment"
            title={i18n.t('USERPREF.NEWCOMENT_TITLE')}
            description={i18n.t('USERPREF.NEWCOMENT_DESC', { title: customIdeaTitles.pluralTitle || "ideas" })}
            value={newcoment}
            onClick={() => this.handleOnCheckPrefs("newcoment")}
          />
        </div>
      </div>
    )
  }
}

UserPreferences.propTypes = {
  classes: PropTypes.shape({
    top: PropTypes.string,
    box: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  getIdeaStatusPref: PropTypes.func.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(UserPreferences)
