import React from 'react'
import * as PropTypes from 'prop-types'
import { Paper, withStyles, RadioGroup } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'
import RowSetting from './components/row_setting/View'
import { ROI_SETTINGS } from '../../../../../../View.Utils'

const EasyMode = ({ classes, onSettingChange, selectedGamingSetting, defaultValues, customIdeaTitles }) => {
  return (
    <Paper square className={classes.root}>
      <h3>{i18n.t('CHALLENGES.ROI_DEFAULT')}</h3>
      <div className={classes.mainContent}>
        <RadioGroup
          value={selectedGamingSetting}
          onChange={(event, setting) => onSettingChange(setting, defaultValues)}
        >
          <RowSetting
            radioValue={ROI_SETTINGS.DEFAULT}
            radioLabel={i18n.t('CHALLENGES.ROI_DEFAULT')}
            mainRightText={i18n.t('CHALLENGES.ROI_DEFAULT_INFO')}
            isRadioChecked={selectedGamingSetting === ROI_SETTINGS.DEFAULT}
          />
          <RowSetting
            radioValue={ROI_SETTINGS.FEWER_IDEAS}
            radioLabel={i18n.t('CHALLENGES.ROI_LESS', { title: customIdeaTitles.pluralTitle || "ideas" })}
            mainRightText={i18n.t('CHALLENGES.ROI_LESS_INFO', { title: customIdeaTitles.pluralTitle || "ideas" })}
            isRadioChecked={selectedGamingSetting === ROI_SETTINGS.FEWER_IDEAS}
            listRightText={[
              i18n.t('CHALLENGES.ROI_LESS_INFO1', { title: customIdeaTitles.pluralTitle || "ideas" }),
              i18n.t('CHALLENGES.ROI_LESS_INFO2', { title: customIdeaTitles.pluralTitle || "ideas" }),
            ]}
          />
          <RowSetting
            radioValue={ROI_SETTINGS.MORE_IDEAS}
            radioLabel={i18n.t('CHALLENGES.ROI_MORE', { title: customIdeaTitles.pluralTitle || "ideas" })}
            mainRightText={i18n.t('CHALLENGES.ROI_MORE_INFO', { title: customIdeaTitles.pluralTitle || "ideas" })}
            isRadioChecked={selectedGamingSetting === ROI_SETTINGS.MORE_IDEAS}
            listRightText={[
              i18n.t('CHALLENGES.ROI_MORE_INFO1', { title: customIdeaTitles.pluralTitle || "ideas" }),
              i18n.t('CHALLENGES.ROI_MORE_INFO2', { title: customIdeaTitles.pluralTitle || "ideas" }),
            ]}
          />
        </RadioGroup>
      </div>
    </Paper>
  )
}
EasyMode.defaultProps = {
  selectedGamingSetting: 'default',
}
EasyMode.propTypes = {
  onSettingChange: PropTypes.func.isRequired,
  selectedGamingSetting: PropTypes.string,
  defaultValues: PropTypes.shape({}).isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}
export default withStyles(styles)(EasyMode)
