import React from 'react'
import SelectWithChecks from '../select_with_checks/View'

const ChallengeSelect = ({
  challenges,
  challengeDefaultText,
  setSelectedChallenge,
  selectedChallenge,
  disabled
}) => {
  const challengeCheckFunction = (value, element) => {
    return value === element.id
  }

  const onChallengeChange = (event) => {
    if (event.target.value === selectedChallenge) {
      setSelectedChallenge('')
    } else {
      setSelectedChallenge(event.target.value)
    }
  }

  const parseIdToText = (string, list) => {
    if (list.length > 0 && string.length > 0) {
      const result = list.find(e => e.id === string)
      return result ? result.name : result
    }

    return ''
  }

  const renderChallengeValue = selected => {
    if (selected.length === 0) {
      return challengeDefaultText
    }
    return parseIdToText(selectedChallenge, challenges)
  }

  return (
    <SelectWithChecks
      checkFunction={challengeCheckFunction}
      name="selectedChallenge"
      defaultText={challengeDefaultText}
      onValueChange={onChallengeChange}
      list={challenges}
      renderValue={renderChallengeValue}
      value={selectedChallenge}
      disabled={disabled}
    />
  )
}

export default ChallengeSelect
