import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, withTheme } from '@material-ui/core'
import MainTooltip from 'components/main_tooltip/View'
import clsx from 'clsx'
import { fromWidthToColor } from 'pages/idea/detail/View.Utils'
import styles from './View.Style'

class StrategicRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leftLabelVisible: true,
      rightLabelVisible: true,
    }
    this.barRef = React.createRef()
    this.leftLabelRef = React.createRef()
    this.rightLabelRef = React.createRef()
  }

  componentDidMount() {
    const barWidth = this.barRef.current.getBoundingClientRect().width
    const leftLabelWidth = this.leftLabelRef.current.getBoundingClientRect().width
    if (barWidth > leftLabelWidth + 60) {
      this.setState({
        rightLabelVisible: false,
      })
    } else {
      this.setState({
        leftLabelVisible: false,
      })
    }
  }

  render() {
    const { classes, width, name, description, theme } = this.props
    const { leftLabelVisible, rightLabelVisible } = this.state
    return (
      <div
        ref={this.barRef}
        className={clsx(classes.root, leftLabelVisible && classes.isWithLeftLabel)}
        style={{
          width: `${width}%`,
          backgroundColor: `${theme.palette.primary.main}${fromWidthToColor(width)}`,
        }}
      >
        {leftLabelVisible && (
          <div ref={this.leftLabelRef} className={classes.leftLabelContainer}>
            <span>{name}</span>
            <MainTooltip
              title={<span className={classes.tooltipText}>{description}</span>}
              placement="right"
            >
              <span className="icon-info" />
            </MainTooltip>
          </div>
        )}
        <div className={classes.percentageContainer}>{width}%</div>
        {rightLabelVisible && (
          <div
            ref={this.rightLabelRef}
            className={classes.rightLabelContainer}
            style={{ left: `${width}%` }}
          >
            <span>{name}</span>
            <MainTooltip
              title={<span className={classes.tooltipText}>{description}</span>}
              placement="right"
            >
              <span className="icon-info" />
            </MainTooltip>
          </div>
        )}
      </div>
    )
  }
}

StrategicRow.propTypes = {
  width: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default withTheme(withStyles(styles)(StrategicRow))
