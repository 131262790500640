import React, { Fragment, useEffect } from 'react'
import * as PropTypes from 'prop-types'

const MicrosoftLogin = props => {
  const { checkMicrosoftLogin } = props

  useEffect(() => {
    checkMicrosoftLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Fragment />
}

MicrosoftLogin.propTypes = {
  checkMicrosoftLogin: PropTypes.func.isRequired,
}

export default MicrosoftLogin
