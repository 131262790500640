import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import NewPrivateIdea from '../../../../components/nxt_modal_new_private_idea'
import Navigation from "../.."
import AnalyticsService from "../../../analytics"
import { goToAdminThunk } from "../../Thunks"
import CopyrightDialog from "./components/copyright_dialog/View"
import { hideDialog } from '../../../popup_dialogs/modal_alert_controller/ModalActions'
import { showPopupDialog, showPopupErrorSnackbar } from "../../../popup_dialogs"
import { markNotificationsRead, notificationMeService } from '../../../../services/notifications'
import styles from './View.Style'
import View from './View'
import * as types from "../../../../actions/sync/auth/Constants";
import { logoutService } from "../../../../services/auth";
import i18n from "../../../i18n";

const mapStateToProps = (state, ownProps) => {
  return {
    domain: state.data.domain.currentDomain,
    appVersion: process.env.REACT_APP_VERSION,
    copyrightYear: process.env.REACT_APP_COPYRIGHT,
    logoUrl: state.data.domain.domainConfiguration.logo,
    customFooterText: state.data.domain.domainConfiguration.customFooterText,
    newIdeas: state.data.domain.domain.newIdeas,
    notifications: state.data.user.notifications,
    showAdmin: state.data.user.admin,
    userBalance: state.data.user.balance,
    userName: state.data.user.userName,
    userPhoto: state.data.user.userPhoto,
    totalInvestments: state.data.domain.domain.totalInvestments,
    currencyIcon: state.data.domain.domainConfiguration.currencyIcon,
    domains: state.data.user.domains,
    isPrivateFlowActive: state.data.domain.domainConfiguration.isPrivateFlowActive,
    currentLocation: ownProps.currentLocation,
    userLegalAcceptance: state.data.user.legalAcceptance,
    domainFailure: state.data.domain.failure,
    customTitlesConf: state.data.domain.customTitlesConf
  }
}

const mapDispatchToProps = dispatch => {
  function onLogoutSuccess() {
    return {
      type: types.PASSWORD_LOGOUT_SUCCESS,
    }
  }

  function onLogoutFailure() {
    showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.LOGOUT_FAILURE'), dispatch)
  }

  return {
    onLogoClick: () => {
      Navigation.navigateToExplore()
    },
    onCopyrightClick: year =>
      showPopupDialog(
        <CopyrightDialog year={year} onAcceptClick={() => dispatch(hideDialog())} />,
        dispatch,
      ),
    navigateToDomain: domainId => {
      Navigation.navigateToDomain(domainId)
    },

    submitYourIdea: (isPrivate = false) => {
      if (isPrivate) {
        showPopupDialog(<NewPrivateIdea />, dispatch)
      } else {
        dispatch(Navigation.navigateToNewIdea())
      }
      AnalyticsService.trackEvent({
        category: 'create-idea',
        action: 'click',
        label: 'home footer button',
      })
    },
    navigateToExplore: () => {
      Navigation.navigateToExplore()
    },
    navigateToMyIdeas: () => {
      Navigation.navigateToMyIdeas()
    },
    navigateToInvestments: () => {
      Navigation.navigateToInvestments()
    },
    navigateToChallenges: () => {
      Navigation.navigateToChallenges()
    },
    markNotificationsRead: () => {
      const onSuccess = () => {
        dispatch(notificationMeService())
      }
      dispatch(markNotificationsRead(onSuccess))
    },
    navigateToAdmin: () => {
      dispatch(goToAdminThunk())
    },
    navigateToLogout: () => {
      dispatch(logoutService(onLogoutSuccess, onLogoutFailure))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(View))
