import { ServiceCall } from '../../support/network/ServiceCallAction'
import * as types from '../../actions/sync/auth/Constants'
import {
  setContent,
  setNewPassword,
  setUserDetails,
  setGoogleCode,
  setMicrosoftParams,
} from './RequestUtils'
import {
  transformDomainServiceResponseDataWithState,
  transformRecoverPasswordResponse,
} from './ResponseUtils'
import { prepareHeadersFromState } from '../HeadersUtils'

const POST_LOGIN_URL = '/b/login'
const POST_RECOVER_PASSWORD_URL = '/b/##email##/send-recover-pwd'
const POST_INSTANCE_RECOVER_PASSWORD_URL = '/b/##instanceid##/##email##/send-recover-pwd'
const POST_CHANGE_PASSWORD_URL = '/b/change-pwd?code=##tokenId##'
const POST_SIGN_UP_URL = '/b/##domain##/signup'
const POST_USER_DETAILS_URL = '/b/##domain##/signup-complete'
const POST_SEND_VERIFICATION_EMAIL_URL = '/b/##email##/send-verification'
const POST_GOOGLE_LOGIN_URL = '/gplus/connect'
const POST_AZURE_LOGIN_URL = '/b/azure/connect'
const POST_ADFS_LOGIN_URL = '/b/adfs/connect'
const POST_LOGIN_TOKEN_URL = '/b/login-token/##token##'
const POST_LOGOUT_URL = '/b/logout'

export function loginService(name, password, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.PASSWORD_LOGIN_STARTED
  apiCall.successReqType = types.PASSWORD_LOGIN_SUCCESS
  apiCall.failureReqType = types.PASSWORD_LOGIN_FAILURE
  apiCall.setEndpointFromState = () => POST_LOGIN_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setContent(name, password)
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.onSuccess = response => onSuccess(response)
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformDomainServiceResponseDataWithState
  apiCall.auth = false
  return apiCall.getAction()
}

export function logoutService(onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.PASSWORD_LOGOUT_STARTED
  apiCall.successReqType = types.PASSWORD_LOGOUT_SUCCESS
  apiCall.failureReqType = types.PASSWORD_LOGOUT_FAILURE
  apiCall.setEndpointFromState = () => POST_LOGOUT_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.onSuccess = response => onSuccess(response)
  apiCall.onFailure = onFailure
  apiCall.auth = false
  return apiCall.getAction()
}

export function postRecoverPassword(email, instanceId) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_RECOVER_PASSWORD_STARTED
  apiCall.successReqType = types.POST_RECOVER_PASSWORD_SUCCESS
  apiCall.failureReqType = types.POST_RECOVER_PASSWORD_FAILURE
  apiCall.setEndpointFromState = () =>
    instanceId
      ? POST_INSTANCE_RECOVER_PASSWORD_URL.replace('##instanceid##', instanceId).replace(
          '##email##',
          email,
        )
      : POST_RECOVER_PASSWORD_URL.replace('##email##', email)
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.transformResponseDataWithState = transformRecoverPasswordResponse
  apiCall.auth = false
  return apiCall.getAction()
}

export function postChangePassword(password, tokenId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_CHANGE_PASSWORD_STARTED
  apiCall.successReqType = types.POST_CHANGE_PASSWORD_SUCCESS
  apiCall.failureReqType = types.POST_CHANGE_PASSWORD_FAILURE
  apiCall.setEndpointFromState = () => `${POST_CHANGE_PASSWORD_URL.replace('##tokenId##', tokenId)}`
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.setBodyFromState = () => setNewPassword(password)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = false
  return apiCall.getAction()
}

export function postSignUp(domain, email, password, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_SIGN_UP_STARTED
  apiCall.successReqType = types.POST_SIGN_UP_SUCCESS
  apiCall.failureReqType = types.POST_SIGN_UP_FAILURE
  apiCall.setEndpointFromState = () => `${POST_SIGN_UP_URL.replace('##domain##', domain)}`
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.setBodyFromState = () => setContent(email, password)
  apiCall.onFailure = onFailure
  apiCall.onSuccess = onSuccess
  apiCall.auth = false
  return apiCall.getAction()
}

export function postUserDetail(domain, firstName, lastName, base64Photo, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_USER_DETAILS_STARTED
  apiCall.successReqType = types.POST_USER_DETAILS_SUCCESS
  apiCall.failureReqType = types.POST_USER_DETAILS_FAILURE
  apiCall.setEndpointFromState = () => `${POST_USER_DETAILS_URL.replace('##domain##', domain)}`
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.setBodyFromState = () => setUserDetails(firstName, lastName, base64Photo)
  apiCall.onFailure = onFailure
  apiCall.onSuccess = onSuccess
  apiCall.auth = false
  return apiCall.getAction()
}

export function postSendVerificationEmail(email, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_SEND_VERIFICATION_EMAIL_STARTED
  apiCall.successReqType = types.POST_SEND_VERIFICATION_EMAIL_SUCCESS
  apiCall.failureReqType = types.POST_SEND_VERIFICATION_EMAIL_FAILURE
  apiCall.setEndpointFromState = () =>
    `${POST_SEND_VERIFICATION_EMAIL_URL.replace('##email##', email)}`
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.onFailure = onFailure
  apiCall.onSuccess = onSuccess
  apiCall.auth = false
  return apiCall.getAction()
}

export function postGoogleLoginResult(code, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_GOOGLE_LOGIN_STARTED
  apiCall.successReqType = types.POST_GOOGLE_LOGIN_SUCCESS
  apiCall.failureReqType = types.POST_GOOGLE_LOGIN_FAILURE
  apiCall.setEndpointFromState = () => POST_GOOGLE_LOGIN_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setGoogleCode(code)
  apiCall.onSuccess = response => onSuccess(response)
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformDomainServiceResponseDataWithState
  apiCall.auth = false
  return apiCall.getAction()
}

export function postMicrosoftResult(email, code, onSuccess, onFailure, isAzure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = isAzure ? types.POST_AZURE_LOGIN_STARTED : types.POST_ADFS_LOGIN_STARTED
  apiCall.successReqType = isAzure ? types.POST_AZURE_LOGIN_SUCCESS : types.POST_ADFS_LOGIN_SUCCESS
  apiCall.failureReqType = isAzure ? types.POST_AZURE_LOGIN_FAILURE : types.POST_ADFS_LOGIN_FAILURE
  apiCall.setEndpointFromState = () => (isAzure ? POST_AZURE_LOGIN_URL : POST_ADFS_LOGIN_URL)
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setMicrosoftParams(email, code)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformDomainServiceResponseDataWithState
  apiCall.auth = false
  return apiCall.getAction()
}

export function loginTokenService(token, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.TOKEN_LOGIN_DECODE_STARTED
  apiCall.successReqType = types.TOKEN_LOGIN_DECODE_SUCCESS
  apiCall.failureReqType = types.TOKEN_LOGIN_DECODE_FAILURE
  apiCall.setEndpointFromState = () => `${POST_LOGIN_TOKEN_URL.replace('##token##', token)}`
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.onSuccess = response => onSuccess(response)
  apiCall.onFailure = onFailure
  apiCall.auth = false
  return apiCall.getAction()
}
