import { createStyles } from '@material-ui/core'
import background from 'img/bg-white-login-pattern-icons.png'

const styles = () =>
  createStyles({
    side: {
      gridArea: 'side',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      background: `rgba(240, 240, 240, 1) url(${background}) top left`,
      color: '#000',
      overflow: 'hidden',
      textAlign: 'center',
      padding: '1rem 3rem 2rem 3rem',
      '& button, footer p, h2, a, header a': {
        fontFamily: 'brandon-grotesque, "Open Sans", open-sans, sans-serif',
      },
      '& .content h2': {
        marginBottom: '33px',
        opacity: '.89',
        fontWeight: 600,
        fontSize: '20px',
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
      },
      '& .content h2 small': {
        display: 'block',
        marginBottom: '17px',
        fontSize: '12px',
        letterSpacing: '1px',
      },
      '& .content p': {
        marginBottom: '35px',
        opacity: '.7',
        fontStyle: 'italic',
        fontSize: '15px',
        lineHeight: '25px',
      },
      '& .content a': {
        display: 'inline-block',
        border: '2px solid rgb(248, 194, 81)',
        borderRadius: '100px',
        fontSize: '10px',
        fontWeight: 700,
        color: 'rgb(248, 194, 81)',
        letterSpacing: '1px',
        padding: '0 36px',
        height: '36px',
        lineHeight: '36px',
        textDecoration: 'none',
        textTransform: 'uppercase',
      },
      '& .footer p': {
        marginBottom: '28px',
        opacity: '.39',
        fontSize: '11px',
        textTransform: 'uppercase',
        color: '#FFF',
        letterSpacing: '.91px',
      },
      '& .footer svg': {
        width: '30px',
        fill: '#000',
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      '& .footer a': {
        opacity: 0.6
      },
      '& .footer a:hover': {
        opacity: 1
      },
    },
    header: {
      paddingTop: '60px',
      width: '120px',
      height: 'auto',
      color: 'transparent',
    },
  })

export default styles
