import { ServiceCall } from 'support/network/ServiceCallAction'
import {
  GET_ACTIVE_ORGANIZATIONS_STARTED,
  GET_ACTIVE_ORGANIZATIONS_SUCCESS,
  GET_ACTIVE_ORGANIZATIONS_FAILURE,
} from 'actions/sync/organizations/Constants'
import { transformOrganizationServiceResponseDataWithState } from './ResponseUtils'
import { prepareHeadersFromState } from '../HeadersUtils'

export const GET_ACTIVE_ORGANIZATIONS_URL = '/s/domain/organization/active'

export function getActiveOrganizations() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_ACTIVE_ORGANIZATIONS_STARTED
  apiCall.successReqType = GET_ACTIVE_ORGANIZATIONS_SUCCESS
  apiCall.failureReqType = GET_ACTIVE_ORGANIZATIONS_FAILURE
  apiCall.setEndpointFromState = () => GET_ACTIVE_ORGANIZATIONS_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.transformResponseDataWithState = transformOrganizationServiceResponseDataWithState
  apiCall.auth = true
  return apiCall.getAction()
}
