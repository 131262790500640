import defaultPhoto from '../../img/default-photo.png'
import { formatLocaleDistanceToNow } from '../../support/date_fns_locale'

export const InitialRrssProps = [
  {
    title: 'Facebook',
    url: '',
    isChecked: false,
  },
  {
    title: 'Instagram',
    url: '',
    isChecked: false,
  },
  {
    title: 'Youtube',
    url: '',
    isChecked: false,
  },
  {
    title: 'Twitter',
    url: '',
    isChecked: false,
  },
  {
    title: 'Linkedin',
    url: '',
    isChecked: false,
  },
]

export const parseIdNameResponse = configResponse => {
  return configResponse.map(organization => {
    return { id: `${organization.id || ''}`, text: organization.name }
  })
}

export const getRealCurrencyConfiguration = currencyType => {
  let configuration = {
    precision: 0,
    format: '%u %n',
    delimiter: ',',
    separator: '.',
    unit: '$',
  }
  switch (currencyType) {
    case 'en_US':
      configuration = {
        ...configuration,
      }
      break
    case 'es_ES':
      configuration = {
        ...configuration,
        delimiter: '.',
        separator: ',',
        unit: '€',
        format: '%n %u',
      }
      break
    case 'es_PE':
      configuration = {
        ...configuration,
        unit: 'S/.',
      }
      break
    case 'es_MX':
    case 'es_CO':
    case 'es_CH':
      configuration = {
        ...configuration,
        delimiter: '.',
        separator: ',',
      }
      break
    case 'en_GB':
      configuration = {
        ...configuration,
        unit: '£',
      }
      break
    default:
      break
  }
  return configuration
}

export const getVirtualCurrencyConfiguration = domainCurrency => {
  const currencyIcon = (domainCurrency || '').includes('TH') ? 'thumbs-up' : 'nextcoin4'
  let configuration = {
    precision: 0,
    unit: '',
  }
  let hasCurrencyIconToLeft = true
  switch (domainCurrency) {
    case 'us_NX':
    case 'us_TH':
      configuration = {
        ...configuration,
        delimiter: ',',
        separator: '.',
      }
      break
    case 'es_NX':
    case 'es_TH':
      configuration = {
        ...configuration,
        delimiter: '.',
        separator: ',',
      }
      hasCurrencyIconToLeft = false
      break
    default:
      break
  }
  return {
    configuration,
    hasCurrencyIconToLeft,
    currencyIcon,
  }
}

export const transformConfigResponseDataWithState = jsonResponse => {
  return {
    defaultColors: {
      primary: jsonResponse.corporateAccentColor,
      secondary: jsonResponse.corporateColor,
      headerText: jsonResponse.corporateColorForeground,
    },
    isOrganizationsActive: jsonResponse.usingOrganizations,
    isTypesActive: jsonResponse.usingTypes,
    isAttributesActive: jsonResponse.usingAttributes,
    organizationsName: jsonResponse.nameOrganizations,
    useHelpSection: jsonResponse.useHelpSection,
  }
}

const parseUserLastName = (name, fullName) => {
  if (!name || !fullName) return ''
  return fullName.replace(name, '').trim()
}

export const transformFullInfoResponseDataWithState = jsonResponse => {
  const currencyConfiguration = getVirtualCurrencyConfiguration(
    jsonResponse.domainconf.currencyLocale,
  )

  const contributeCurrencyConfiguration = getRealCurrencyConfiguration(
    jsonResponse.domainconf.contributeCurrency,
  )

  const logisticCurrencyConfiguration = getRealCurrencyConfiguration(
    jsonResponse.domainconf.logisticCurrency,
  )

  return {
    admin: jsonResponse.user.admin,
    isInnovator: jsonResponse.user.innovation,
    balance: `${jsonResponse.user.balance || '0'}`,
    userName: jsonResponse.user.name,
    userLastName: parseUserLastName(jsonResponse.user.name, jsonResponse.user.fullname),
    userPhoto: jsonResponse.user.photo || defaultPhoto,
    email: jsonResponse.user.email,
    badges: jsonResponse.badges,
    domain: {
      newIdeas: `${jsonResponse.domain.numIdeas || '0'}`,
      totalInvestments: `${jsonResponse.domain.numInvestments || '0'}`,
      desktopAccesses: jsonResponse.domain.systemAccess,
      mobileAccesses: jsonResponse.domain.systemAccessMobile,
      iosAccesses: jsonResponse.domain.systemAccessIOS,
      androidAccesses: jsonResponse.domain.systemAccessAND,
      createdIdeas: jsonResponse.domain.numIdeas,
      implementedIdeas: jsonResponse.domain.numImlpemented,
      ideasVisits: jsonResponse.domain.ideaVisits,
      activeUsers: jsonResponse.domain.au,
      publishedComments: jsonResponse.domain.numComments,
      domainCreationDate: new Date(jsonResponse.domain.created),
      isDomainInMaintenance: jsonResponse.domain.maintenance,
    },
    domainConfiguration: {
      lang: jsonResponse.domainconf.lang,
      logo: (jsonResponse.domainconf.logo && jsonResponse.domainconf.logo.url) || '',
      customFooterText: jsonResponse.domainconf.customFooterText,
      organizationsName: jsonResponse.domainconf.nameOrganizations,
      typesName: jsonResponse.domainconf.nameTypes,
      attributesName: jsonResponse.domainconf.nameAttributes,
      isOrganizationsActive: !!jsonResponse.domainconf.usingOrganizations,
      isTypesActive: !!jsonResponse.domainconf.usingTypes,
      isAttributesActive: !!jsonResponse.domainconf.usingAttributes,
      useHelpSection: !!jsonResponse.domainconf.useHelpSection,
      isPreviousValidationEnabled: !!jsonResponse.domainconf.previousValidationEnabled,
      logistic: jsonResponse.domainconf.logistic,
      isLogisticActiveOnCreate: jsonResponse.domainconf.logisticActiveOnCreate,
      logisticCurrencyConfiguration,
      contributeCurrencySymbol: contributeCurrencyConfiguration.unit,
      contributeCurrencyConfiguration,
      hasCurrencyIconToLeft: currencyConfiguration.hasCurrencyIconToLeft,
      currencyConfiguration: currencyConfiguration.configuration,
      currencyIcon: currencyConfiguration.currencyIcon,
      challengeProposal: jsonResponse.domainconf.challengeProposals,
      isBudgetActive: !!jsonResponse.domainconf.canContributeBudget,
      isTimeActive: !!jsonResponse.domainconf.canContributeHours,
      isOtherActive: !!jsonResponse.domainconf.canContributeOther,
      isContributionActive: !!jsonResponse.domainconf.contributeAllowed,
      ideaStatusStepper: {
        approvedLabel: `${jsonResponse.domainconf.approvedLiteral || ''}`,
        approvedColor: `${jsonResponse.domainconf.approvedColor || ''}`,
        discardedLabel: `${jsonResponse.domainconf.discardedLiteral || ''}`,
        discardedColor: `${jsonResponse.domainconf.discardedColor || ''}`,
        implementedLabel: `${jsonResponse.domainconf.implementedLiteral || ''}`,
        implementedColor: `${jsonResponse.domainconf.implementedColor || ''}`,
        pendingSupportLabel: `${jsonResponse.domainconf.pendingSupportLiteral || ''}`,
        pendingSupportColor: `${jsonResponse.domainconf.pendingSupportColor || ''}`,
        pilotActiveLabel: `${jsonResponse.domainconf.pilotActiveLiteral || ''}`,
        pilotActiveColor: `${jsonResponse.domainconf.pilotActiveColor || ''}`,
        roundClosedLabel: `${jsonResponse.domainconf.roundClosedLiteral || ''}`,
        roundClosedColor: `${jsonResponse.domainconf.roundClosedColor || ''}`,
        supportedLabel: `${jsonResponse.domainconf.supportedLiteral || ''}`,
        supportedColor: `${jsonResponse.domainconf.supportedColor || ''}`,
      },
      splashConfiguration: {
        welcomeTitle: `${jsonResponse.domainconf.welcomeTitle || ''}`,
        splashHTML: `${jsonResponse.domainconf.splashHTML || ''}`,
        splashLogo: `${
          jsonResponse.domainconf.splashLogo && jsonResponse.domainconf.splashLogo.url
            ? jsonResponse.domainconf.splashLogo.url
            : ''
        }`,
        splashImage: `${
          jsonResponse.domainconf.splashImage && jsonResponse.domainconf.splashImage.url
            ? jsonResponse.domainconf.splashImage.url
            : ''
        }`,
      },
      privateBannerSrc: jsonResponse.domainconf.customIdeaBannerImage
        ? jsonResponse.domainconf.customIdeaBannerImage.url.concat('=s1600')
        : '',
      isPrivateFlowActive: !!jsonResponse.domainconf.privateFlow,
      isChallengeMandatoryForIdeaCreation: !!jsonResponse.domainconf.ideaChallengeMandatory,
      isAnonymousIdeas: jsonResponse.domainconf.anonideas,
      isAnonymousIdeasMandatory: jsonResponse.domainconf.anonideasMandatory,
      gaTrackId: jsonResponse.domainconf.gaTrackId,
      maintenanceTitle: jsonResponse.domainconf.maintenanceTitle || '',
      maintenanceText: jsonResponse.domainconf.maintenanceText || '',
      androidAppUrl:
        jsonResponse.domainconf.androidAppUrl ||
        'https://play.google.com/store/apps/details?id=com.nextinit',
      iosAppUrl:
        jsonResponse.domainconf.iosAppUrl ||
        'https://apps.apple.com/us/app/nextinit-app/id1448878323?l=en&mt=8',
      legalAcceptTitle: jsonResponse.domainconf.legalAcceptTitle || '',
      legalAcceptHTML: jsonResponse.domainconf.legalAcceptHTML || '',
    },
    prefs: jsonResponse.user.prefs
      ? {
          ideastatus: jsonResponse.user.prefs.ideastatus,
          invest: jsonResponse.user.prefs.invest,
          splash: jsonResponse.user.prefs.splash,
          newidea: jsonResponse.user.prefs.newidea,
          challenge: jsonResponse.user.prefs.challenge,
          newcoment: jsonResponse.user.prefs.newcoment,
          week: jsonResponse.user.prefs.week,
        }
      : {},
    nextinits: jsonResponse.nextinits,
    legalAcceptance: jsonResponse.user.legalAcceptance,
    gdpr: jsonResponse.gdpr,
    customTitlesConf: {
      customIdeaTitles: {
        singularTitle: jsonResponse.domainconf.singularTitle ?? '',
        pluralTitle: jsonResponse.domainconf.pluralTitle ?? '',
      },
      isChallenge: jsonResponse.domainconf.customShowChallenge ?? true,
      isInvestment: jsonResponse.domainconf.customShowInvestment ?? true,
      isRanking: jsonResponse.domainconf.customShowRanking ?? true,
    },
    standardFormActive: jsonResponse.domainconf.formstandard,
  }
}

function transformNotifications(accumulator, next) {
  const newItem = {}
  newItem.id = next.id
  newItem.text = next.title
  newItem.isRead = next.readed
  newItem.imageSrc = next.ideaPhoto || next.challengePhoto || ''
  newItem.icon = next.icon === 'SYSTEM' ? 'rocket' : next.icon
  newItem.timeAgo = formatLocaleDistanceToNow(new Date(next.created || new Date()))
  if (next.notificationType && next.notificationType.includes('NOTIFICATION_USER_FUNDS_EARNED')) {
    return {
      activities: accumulator.activities,
      notifications: [...accumulator.notifications, newItem],
      totalUnread: accumulator.totalUnread + !next.readed,
      unreadNotifications: accumulator.unreadNotifications + !next.readed,
      unreadActivities: accumulator.unreadActivities,
    }
  }
  return {
    activities: [...accumulator.activities, newItem],
    notifications: accumulator.notifications,
    totalUnread: accumulator.totalUnread + !next.readed,
    unreadNotifications: accumulator.unreadNotifications,
    unreadActivities: accumulator.unreadActivities + !next.readed,
  }
}

export const transformNotificationMeResponseDataWithState = jsonResponse => {
  return jsonResponse.items.reduce(transformNotifications, {
    activities: [],
    notifications: [],
    totalUnread: 0,
    unreadNotifications: 0,
    unreadActivities: 0,
  })
}

export const transformRejectedReasonsResponseDataWithState = jsonResponse => {
  return {
    data: jsonResponse.data,
    items: jsonResponse.items,
  }
}

export const transformApprovedReasonsResponseDataWithState = jsonResponse => {
  return {
    data: jsonResponse.data,
    items: jsonResponse.items,
  }
}

export const transformOrganizationsMeResponseDataWithState = jsonResponse => {
  return parseIdNameResponse(jsonResponse)
}

export const transformTypesMeResponseDataWithState = jsonResponse => {
  return parseIdNameResponse(jsonResponse)
}

export const transformInfoPointsResponse = jsonResponse => {
  return {
    infoPoints: jsonResponse,
  }
}

export const transformTotalInvestmentsResponse = jsonResponse => {
  return {
    totalInvestments: `${jsonResponse.domain.numInvestments || '0'}`,
  }
}

export const transformNewIdeasResponse = jsonResponse => {
  return {
    newIdeas: `${jsonResponse.domain.numIdeas || '0'}`,
  }
}

export const transformVisitsIdeas = jsonResponse => {
  return {
    ideasVisits: jsonResponse.domain.ideaVisits,
  }
}

export const transformCustomRrssResponse = value => {
  if (typeof value === 'string') {
    // eslint-disable-next-line no-unused-vars
    let obj // for eval key
    const respSplited = value.split('-,')
    // eslint-disable-next-line no-eval
    const respEval = respSplited.map(item => eval(`obj=${item}`))

    const parsedRRSS = respEval.map(item => {
      const urlParsed = item.url.trim() === 'null' ? '' : item.url
      const checkedParsed = item.isChecked !== 'false'
      return {
        ...item,
        url: urlParsed,
        isChecked: checkedParsed,
      }
    })

    return parsedRRSS
  }

  return InitialRrssProps
}

export const parseResponseLogin = response => {
  return {
    ...response,
    customBgImg: response.customBgImg?.concat('=s1600') ?? '',
    customRrss: transformCustomRrssResponse(response?.customRrss),
    customAppStoreLinks: {
      androidUrl: response.androidUrl ?? '',
      iosUrl: response.iosUrl ?? '',
    },
  }
}

export const parseUserDomainsResponse = response => {
  return response.userDomains
}
