import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from './View.Style'

const Splash = props => {
  const {
    classes,
    children,
    dataLoaded,
    isSplashActive,
    showSplashModal,
    userLegalAcceptance
  } = props

  useEffect(() => {
    if (dataLoaded && isSplashActive && userLegalAcceptance) {
      showSplashModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded, userLegalAcceptance])

  return (
    <div className={classes.container}>
      <div className={classes.container}>{children}</div>
    </div>
  )
}

Splash.propTypes = {
  children: PropTypes.node.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
  isSplashActive: PropTypes.bool.isRequired,
  showSplashModal: PropTypes.func.isRequired,
  userLegalAcceptance: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Splash)
