const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1.5),
    '& span:first-child': {
      fontSize: '1.125rem',
      marginRight: '0.5rem',
    },
    '& > button': {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
  },
})

export default styles
