/* eslint-disable react/no-this-in-sfc */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { format } from 'date-fns'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getIntervalIndex, getNames } from 'support/utils/dates'
import { getCurrencyHtml } from 'support/utils/currency'
import i18n from 'support/i18n'
import NxtDatePicker from 'components/nxt_date_picker/View'
import RangeSelector from './components/range_selector/View'
import styles from './View.Style'

const Profitability = ({
  classes,
  portfolio,
  userCreated,
  userInitialBalance,
  currencyConfiguration,
  iconName,
  hasIconLeftSide,
}) => {
  const [data, setData] = useState(null)
  const [interval] = useState(null)
  const groupedInvestments = useRef(null)
  const periodStadistics = useRef({
    initialValue: 0,
    finalValue: 0,
    profitability: 0,
    invest: 0
  })
  const [options, setOptions] = useState(null)
  const [titles] = useState({
    portfolio: i18n.t("BALANCE"),
    liquid: i18n.t("INVESTMENTS.LIQUID_UPPER"),
    profit: i18n.t("INVESTMENTS.PROFIT_UPPER")
  })
  const [colors] = useState({
    portfolio: "#f15703",
    liquid: "#0096e2",
    profit: "#90c131"
  })
  const [initRange, setInitRange] = useState({
    start: null,
    end: null,
  })
  const [datesRange, setDatesRange] = useState({
    start: null,
    end: null,
  })
  const [rangeType, setRangeType] = useState('all')

  const timeLineData = () => {
    let created = null
    let index = null
    let liquid = userInitialBalance
    let timestamp = null
    const currData = {}

    currData.start = datesRange.start
    currData.end = datesRange.end
    currData.liquid = []
    currData.portfolio = []
    currData.profit = []

    const indexStart = getIntervalIndex(currData.start, interval)
    const indexEnd = getIntervalIndex(currData.end, interval)

    // Generate array of grouped investments
    groupedInvestments.current = {}
    // eslint-disable-next-line no-plusplus
    for (let i = portfolio.length - 1; i >= 0; i--) {
      created = new Date(portfolio[i].created)
      index = getIntervalIndex(created, interval)
      if (index >= indexStart && index <= indexEnd) {
        if (groupedInvestments.current[index] == null) {
          groupedInvestments.current[index] = {
            profit: portfolio[i].profit,
            amount: portfolio[i].amount
          }
        }
        else {
          groupedInvestments.current[index].profit += portfolio[i].profit
          groupedInvestments.current[index].amount += portfolio[i].amount
        }
      }
    }

    // Generate arrays of liquid and portfolio in time range (from user creation to now)
    periodStadistics.current.invest = 0
    currData.liquid.push([getIntervalIndex(currData.start, interval), liquid])
    for (const groupedIndex in groupedInvestments.current) {
      timestamp = parseInt(groupedIndex, 10)
      liquid += groupedInvestments.current[groupedIndex].profit - groupedInvestments.current[groupedIndex].amount
      currData.liquid.push([timestamp, liquid])
      currData.portfolio.push([timestamp, -groupedInvestments.current[groupedIndex].amount])
      currData.profit.push([timestamp, groupedInvestments.current[groupedIndex].profit])
      periodStadistics.current.invest += groupedInvestments.current[groupedIndex].profit
    }
    currData.liquid.push([getIntervalIndex(currData.end, interval), liquid])

    setData(currData)
  }

  const calcStadistics = (range) => {
    let indexStart = range.start || getIntervalIndex(data.start, interval);
    let indexEnd = range.end || getIntervalIndex(data.end, interval);
    const keys = Object.keys(groupedInvestments.current);
    let index;

    // Calc indexes
    for (let i = 0; i < keys.length; i++) {
      index = parseInt(keys[i], 10);
      if (indexStart <= index) {
        indexStart = i;
        break;
      }
    }
    for (let i = keys.length - 1; i >= 0; i--) {
      index = parseInt(keys[i], 10);
      if (indexEnd >= index) {
        indexEnd = i;
        break;
      }
    }

    periodStadistics.current.finalValue = userInitialBalance;
    periodStadistics.current.invest = 0;
    periodStadistics.current.profit = 0;

    // Calc initial value
    if (keys.length > 0) {
      periodStadistics.current.initialValue = userInitialBalance;
      periodStadistics.current.finalValue = userInitialBalance;
      for (let i = 0, I = indexStart; i < I; i++) {
        periodStadistics.current.initialValue += groupedInvestments.current[keys[i]].profit - groupedInvestments.current[keys[i]].amount;
      }

      // Calc profitability & final value
      for (let i = indexStart, I = indexEnd; i <= I; i++) {
        periodStadistics.current.invest += groupedInvestments.current[keys[i]].amount;
        periodStadistics.current.profit += groupedInvestments.current[keys[i]].profit;
      }
      periodStadistics.current.finalValue += periodStadistics.current.profit - periodStadistics.current.invest;
      periodStadistics.current.profitability = periodStadistics.current.invest === 0
        ? 0
        : 100 * periodStadistics.current.profit / periodStadistics.current.invest
        ;
    }
  }

  const onStartChange = (newValue) => {
    setDatesRange({
      start: newValue,
      end: datesRange.end
    })
  }

  const onEndChange = (newValue) => {
    setDatesRange({
      start: datesRange.start,
      end: newValue,
    })
  }

  const onRangeSelectorChange = ({ type, start, end }) => {
    setRangeType(type)
    setDatesRange({ start, end })
  }

  useEffect(() => {
    Highcharts.setOptions(
      {
        lang:
        {
          months: getNames('MONTHS'),
          shortMonths: getNames('MONTHS', 3),
          colors: ['#0096e2']
        }
      }
    )
  }, [])

  useEffect(() => {
    if (userCreated) {
      setInitRange({
        start: new Date(userCreated),
        end: new Date()
      })
      setDatesRange({
        start: new Date(userCreated),
        end: new Date()
      })
    }
  }, [userCreated])

  useEffect(() => {
    if (datesRange.start && datesRange.end && portfolio) {
      timeLineData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesRange, portfolio])

  useEffect(() => {
    if (data) {
      setOptions({
        chart: {
          zoomType: 'x',
          spacingRight: 20,
          events: {
            redraw: function () {
              calcStadistics({
                start: parseInt(this.axes[0].min, 10),
                end: parseInt(this.axes[0].max, 10)
              });
            },
            load: function () {
              calcStadistics({
                start: getIntervalIndex(data.start, interval),
                end: getIntervalIndex(data.end, interval)
              });
            }
          }
        },
        credits: {
          enabled: false
        },
        title: false,
        xAxis: {
          type: 'datetime',
          maxZoom: 14 * 24 * 3600000, // fourteen days
          title: { text: null }
        },
        yAxis: {
          title: { text: null },
          labels: {
            formatter: function () {
              return getCurrencyHtml({
                number: this.value,
                currencyConfiguration,
                iconName,
                hasIconLeftSide
              });
            },
            useHTML: true
          }
        },
        tooltip: {
          shared: true,
          useHTML: true,
          borderWidth: 0,
          borderRadius: 0,
          hideDelay: 200,
          backgroundColor: 'rgba(255, 255, 255, 1)',
          style: {
            fontWeight: 'bold',
            fontSize: "14px",
            color: "black",
            padding: '0'
          },
          formatter: function () {
            const time = format(new Date(this.x), i18n.t('DATES.PROFITABILITY'));
            const liquid = getCurrencyHtml({
              number: this.y || 0,
              currencyConfiguration,
              iconName,
              hasIconLeftSide

            })
            const currPortfolio = getCurrencyHtml({
              number: this.points.length > 1 ? this.points[1].y || 0 : 0,
              currencyConfiguration,
              iconName,
              hasIconLeftSide
            })
            const profit = getCurrencyHtml({
              number: this.points.length > 2 ? this.points[2].y || 0 : 0,
              currencyConfiguration,
              iconName,
              hasIconLeftSide
            })
            const label = `
            <div class="evolution-label-wrapper pie-label-wrapper" style="background-color: ${colors.liquid}">
              <div class="evolution-label pie-label">
                <div class="title">
                  ${time}
                </div>
                <div class="subtitle"><b style="color:${colors.liquid}">${titles.liquid}: </b>
                  ${liquid}
                </div>
                <div class="subtitle"><b style="color:${colors.portfolio}">${titles.portfolio}: </b>
                  ${currPortfolio}
                </div>
                <div class="subtitle"><b style="color:${colors.profit}">${titles.profit}: </b>${profit}
                </div>
              </div>
            </div>`
            return label;
          }
        },
        legend: { enabled: false },
        plotOptions: {
          area: {
            lineWidth: 1,
            marker: { enabled: false },
            shadow: false,
            states: {
              hover: { lineWidth: 1 }
            }
          },
          column: {
            pointPadding: 5
          }
        },
        series: [
          {
            type: 'area',
            name: titles.liquid,
            data: data.liquid,
            color: colors.liquid,
            useHTML: true
          },
          {
            type: 'column',
            name: titles.portfolio,
            data: data.portfolio,
            color: colors.portfolio,
            borderWidth: 2,
            borderColor: colors.portfolio
          },
          {
            type: 'column',
            name: titles.profit,
            data: data.profit,
            color: colors.profit,
            borderWidth: 2,
            borderColor: colors.profit
          }
        ]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    currencyConfiguration,
    hasIconLeftSide,
    iconName,
    titles,
    colors,
    interval
  ])

  const renderLegend = (color, text) => {
    return (
      <div className={classes.legend}>
        <span className={classes.legendColor} style={{ backgroundColor: color }} />
        <span className={classes.legendText}>
          {text}
        </span>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.graph}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
          {initRange.start && initRange.end && (
            <RangeSelector
              value={rangeType}
              minDate={initRange.start}
              maxDate={initRange.end}
              onChange={onRangeSelectorChange}
            />
          )}
        </div>
        <div className={classes.filters}>
          <div className={classes.datesLabel}>{i18n.t('INVESTMENTS.EVOLUTION_BETWEEN')}</div>
          <div className={classes.dates}>
            <NxtDatePicker
              onDateChange={onStartChange}
              value={datesRange.start}
              endIconName="icon-th"
              minDate={initRange.start}
              maxDate={initRange.end}
            />
            <div className={classes.and}>{i18n.t('INVESTMENTS.AND')}</div>
            <NxtDatePicker
              onDateChange={onEndChange}
              value={datesRange.end}
              endIconName="icon-th"
              minDate={datesRange.start}
              maxDate={initRange.end}
            />
          </div>
          <div className={classes.details}>
            <div className={classes.legendContainer}>
              {renderLegend(colors.portfolio, titles.portfolio)}
              {renderLegend(colors.liquid, titles.liquid)}
              {renderLegend(colors.profit, titles.profit)}
            </div>
            <div className={classes.odd}>
              {i18n.t('INVESTMENTS.INITIAL_VALUE_PORTFOLIO')}
            </div>
            <div className={classes.even}>
              {i18n.t('INVESTMENTS.CURRENT_VALUE_PORTFOLIO')}
            </div>
            <div className={classes.odd}>
              {i18n.t('INVESTMENTS.PROFITABILITY_DURING_PERIOD')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Profitability.defaultProps = {
  portfolio: [],
  userCreated: '',
  userInitialBalance: null,

}

Profitability.propTypes = {
  portfolio: PropTypes.array,
  userCreated: PropTypes.string,
  userInitialBalance: PropTypes.number,
  currencyConfiguration: PropTypes.object.isRequired,
  iconName: PropTypes.string.isRequired,
  hasIconLeftSide: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Profitability)
