import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    container: {
      position: 'relative',
      '& .MuiMobileStepper-root': {
        backgroundColor: 'transparent',
      },
      '& .MuiButton-root.center-next-button, .MuiButton-root.center-back-button': {
        opacity: 0.8,
        '&:hover': {
          backgroundColor: "transparent",
          opacity: 1,
        },
        '& .MuiButton-label': {
          color: props => (props.arrowsColor),
          '& .MuiSvgIcon-root': {
            width: props => (props.arrowsSize),
            height: props => (props.arrowsSize),
            background: props => props.arrowsBgColor,
            borderRadius: '50%',
          },
        },
      },
      '& .center-inside-next-button': {
        position: 'absolute',
        top: '50%',
        right: 0,
        marginTop: '-12px',
        background: props => props.arrowsBgColor,
        color: props => (props.arrowsColor),
        minWidth: '30px',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        paddingTop: '10px',
        paddingBottom: '10px',
      },
      '& .center-inside-back-button': {
        position: 'absolute',
        top: '50%',
        left: 0,
        marginTop: '-12px',
        background: props => props.arrowsBgColor,
        color: props => (props.arrowsColor),
        minWidth: '30px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        paddingTop: '10px',
        paddingBottom: '10px',
      },
      '& .center-next-button': {
        position: 'absolute',
        top: '50%',
        right: '-60px',
        marginTop: '-12px',
      },
      '& .center-back-button': {
        position: 'absolute',
        top: '50%',
        left: '-60px',
        marginTop: '-12px',
      }
    },
    amount: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    days: {
      margin: 0,
    },
    descriptionCard: {
      width: '50%',
      backgroundColor: '#333',
      color: theme.palette.common.white,
    },
    dialGraph: {
      width: '96px',
      height: '96px',
      display: 'inline-block',
      position: 'relative',
      top: '7px',
      left: '-24px',
      background: '#333',
      borderRadius: '100%',
      padding: '12px',
    },
    dialGraphText: {
      position: 'relative',
      top: '-102px',
      left: '-12px',
      marginBottom: '0',
      width: '96px',
      height: '96px',
      lineHeight: '96px',
      textAlign: 'center',
      fontSize: '12px',
    },
    direction: theme.direction,
    graphBlock: {},
    sliderContent: {
      display: 'flex',
      flexDirection: 'row',
    },
    stepperRoot: {
      paddingLeft: 0,
      paddingRight: 0,
      '& button > span': {
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
  })

export default styles
