import React from 'react'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import createPalette from '@material-ui/core/styles/createPalette'
import * as PropTypes from 'prop-types'
import theme from './View.Style'

const ThemeProvider = props => {
  const { children, defaultColors } = props
  const customTheme = {
    ...theme,
    palette: createPalette({
      primary: {
        main: defaultColors.primary || '#ec632f',
      },
      secondary: {
        main: defaultColors.secondary || '#999',
      },
      headerText: {
        main: defaultColors.headerText || '#FFF',
      },
    }),
  }
  return <MuiThemeProvider theme={createTheme(customTheme)}>{children}</MuiThemeProvider>
}

ThemeProvider.defaultProps = {
  defaultColors: {
    primary: '',
    secondary: '',
    headerText: '',
  },
}

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  defaultColors: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
    headerText: PropTypes.string,
  }),
}

export default ThemeProvider
