/* eslint-disable react/jsx-no-bind */
import IconButton from '@material-ui/core/IconButton'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import * as PropTypes from 'prop-types'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

function TablePaginationActions(props) {
  const { classes, fullCount, page, rowsPerPage, onPaginationChange } = props

  function handleFirstPageButtonClick() {
    onPaginationChange(0, rowsPerPage)
  }

  function handleBackButtonClick() {
    onPaginationChange(page - 1, rowsPerPage)
  }

  function handleNextButtonClick() {
    onPaginationChange(page + 1, rowsPerPage)
  }

  function handleLastPageButtonClick() {
    onPaginationChange(Math.max(0, Math.ceil(fullCount / rowsPerPage) - 1), rowsPerPage)
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(fullCount / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(fullCount / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  fullCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(TablePaginationActions)
