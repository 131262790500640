import * as types from './Constants'

export const resetChallenge = () => {
  return { type: types.RESET_CHALLENGE }
}
export const resetSponsorSuggestions = () => {
  return { type: types.SEARCH_USER_SPONSOR_SUCCESS, response: [] }
}
export const setSponsorSuggestion = suggestion => {
  return { type: types.SET_SPONSOR_SUGGESTION, suggestion }
}

export const setProposerSuggestion = suggestion => {
  return { type: types.SET_PROPOSER_SUGGESTION, suggestion }
}

export const setEasyModeSetting = setting => {
  return { type: types.SET_EASY_SETTING, setting }
}

export const setAdvancedAmountToBeSupported = amount => {
  return { type: types.SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED, amount }
}

export const setAdvancedSupportIdeas = amount => {
  return { type: types.SET_ADVANCED_SUPPORT_IDEAS, amount }
}

export const setAdvancedApprovedIdeas = amount => {
  return { type: types.SET_ADVANCED_APPROVED_IDEAS, amount }
}

export const setAdvancedImplementedIdeas = amount => {
  return { type: types.SET_ADVANCED_IMPLEMENTED_IDEAS, amount }
}

export const increaseChallengeStep = () => {
  return { type: types.INCREASE_CHALLENGE_STEP }
}

export const decreaseChallengeStep = () => {
  return { type: types.DECREASE_CHALLENGE_STEP }
}

export const setTitleDescription = title => {
  return { type: types.SET_TITLE_DESCRIPTION, title }
}

export const setWhyImportantDescription = whyImportant => {
  return { type: types.SET_WHY_IMPORTANT_DESCRIPTION, whyImportant }
}

export const setChallengeDescription = description => {
  return { type: types.SET_DESCRIPTION, description }
}

export const setImages = images => ({
  type: types.DELETE_CHALLENGE_INFO_IMAGE_SUCCESS,
  images,
})

export const uploadImageFailure = value => ({
  type: types.SET_CHALLENGE_IMAGE_UPLOAD_ERROR,
  value,
})

export const setExternalVideo = value => ({
  type: types.SET_CHALLENGE_EXTERNAL_VIDEO_URL,
  value,
})

export const setGamingTabValue = activeTab => ({
  type: types.SET_GAMING_ACTIVE_TAB,
  activeTab,
})

export const setPreviewFromDate = fromDate => {
  return { type: types.SET_PREVIEW_FROM_DATE, fromDate }
}

export const setPreviewToDate = toDate => {
  return { type: types.SET_PREVIEW_TO_DATE, toDate }
}

export const moveGroupsToRight = groups => {
  return { type: types.ADD_GROUPS_TO_RIGHT, groups }
}

export const setRightGroups = groups => {
  return { type: types.SET_RIGHT_GROUPS, groups }
}

export const setChallengeRewardDescription = value => ({
  type: types.SET_CHALLENGE_REWARD_DESCRIPTION,
  value,
})

export const setChallengeRewardRewarded = value => ({
  type: types.SET_CHALLENGE_REWARD_REWARDED,
  value,
})

export const setChallengeRewardOther = value => ({
  type: types.SET_CHALLENGE_REWARD_OTHER,
  value,
})

export const setOrganization = value => {
  return { type: types.SET_CHALLENGE_ORGANIZATION, value }
}

export const resetVideo = () => {
  return { type: types.RESET_VIDEO_CHALLENGE }
}

export const setVideoFileReady = () => {
  return { type: types.SET_CHALLENGE_VIDEO }
}
