import {
  GET_ACTIVE_CHALLENGES_STARTED,
  GET_ACTIVE_CHALLENGES_SUCCESS,
  GET_ACTIVE_CHALLENGES_FAILURE,
  GET_CHALLENGE_BY_ID_STARTED,
  GET_CHALLENGE_BY_ID_SUCCESS,
  GET_CHALLENGE_BY_ID_FAILURE,
  APPLY_SEARCH_CHALLENGES_STARTED,
  APPLY_SEARCH_CHALLENGES_SUCCESS,
  APPLY_SEARCH_CHALLENGES_FAILURE,
} from 'actions/sync/challenges/Constants'
import {
  FIRST_SEARCH_APPLY_FILTER_FAILURE,
  FIRST_SEARCH_APPLY_FILTER_STARTED,
  FIRST_SEARCH_APPLY_FILTER_SUCCESS,
  APPLY_SEARCH_IDEAS_SUCCESS,
  APPLY_FILTER_IDEAS_STARTED,
  APPLY_FILTER_IDEAS_FAILURE,
  APPLY_FILTER_IDEAS_SUCCESS,
  APPLY_SEARCH_IDEAS_STARTED,
  APPLY_SEARCH_IDEAS_FAILURE,
} from 'actions/sync/ideas/ideasActionConstans'
import {
  GET_CHALLENGE_DETAIL_VIDEO_TYPE_SUCCESS,
} from 'actions/sync/video/Constants'

const initialState = {
  challenges: [],
  challengeById: {
    approvedMult: 0,
    description: '',
    desc: '',
    ends: 0,
    goalMult: 0,
    id: '',
    ideas: [],
    images: [],
    implementedMult: 0,
    importance: '',
    mainPhoto: '',
    name: '',
    numIdeas: '',
    proposerName: '',
    proposerPhoto: '',
    rewardText: '',
    rewardOther: '',
    rewardImage: {},
    rewardFor: '',
    sponsorName: '',
    sponsorPhoto: '',
    starts: 0,
    supportedMult: 0,
    visits: 0,
    video: '',
    videoType: '',
  },
  items: 0,
  ideas: [],
  totalIdeas: 0,
  challengesLoading: false,
  challengeIdeasLoading: false,
}

const challengeReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_SEARCH_CHALLENGES_STARTED:
    case GET_ACTIVE_CHALLENGES_STARTED:
    case GET_CHALLENGE_BY_ID_STARTED:
      return {
        ...state,
        challengesLoading: true,
      }
    case APPLY_SEARCH_CHALLENGES_SUCCESS:
    case GET_ACTIVE_CHALLENGES_SUCCESS:
      return {
        ...state,
        challenges: action.response.challenges,
        items: action.response.items,
        challengesLoading: false,
      }
    case APPLY_SEARCH_CHALLENGES_FAILURE:
    case GET_ACTIVE_CHALLENGES_FAILURE:
    case GET_CHALLENGE_BY_ID_FAILURE:
      return {
        ...state,
        challengesLoading: false,
      }

    case GET_CHALLENGE_BY_ID_SUCCESS:
      return {
        ...state,
        challengeById: action.response.challenge,
        challengesLoading: false,
      }

    case FIRST_SEARCH_APPLY_FILTER_STARTED:
    case APPLY_FILTER_IDEAS_STARTED:
      return {
        ...state,
        challengeIdeasLoading: true,
      }

    case FIRST_SEARCH_APPLY_FILTER_SUCCESS:
      return {
        ...state,
        ideas: action.response.data,
        totalIdeas: action.response.total,
        challengeIdeasLoading: false,
      }

    case APPLY_FILTER_IDEAS_SUCCESS:
      return {
        ...state,
        ideas: [...state.ideas, ...action.response.data],
        challengeIdeasLoading: false,
      }

    case FIRST_SEARCH_APPLY_FILTER_FAILURE:
    case APPLY_FILTER_IDEAS_FAILURE:
      return {
        ...state,
        challengeIdeasLoading: false,
      }
    case APPLY_SEARCH_IDEAS_STARTED:
      return {
        ...state,
        challengeIdeasLoading: true,
      }
    case APPLY_SEARCH_IDEAS_SUCCESS:
      return {
        ...state,
        ideas: action.response.data,
        totalIdeas: action.response.total,
        challengeIdeasLoading: false,
      }
    case APPLY_SEARCH_IDEAS_FAILURE:
      return {
        ...state,
        challengeIdeasLoading: false,
      }
    case GET_CHALLENGE_DETAIL_VIDEO_TYPE_SUCCESS:
      return {
        ...state,
        challengeById: {
          ...state.challengeById,
          videoType: action.response.type,
        }
      }

    default:
      return state
  }
}

export default challengeReducer
