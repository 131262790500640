const styles = theme => ({
  button: {
    background: 'none',
    position: 'relative',
    color: theme.palette.headerText ? theme.palette.headerText.main : '#FFF',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
  burger: {
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    padding: '4px',
    fontSize: '17px',
  },
})

export default styles
