const styles = () => ({
  root: {
    width: '100%',
  },
  row: {
    display: 'flex',
    marginBottom: '40px',
  },
  graphContainer: {
    flex: 3,
  },
  usersContainer: {
    flex: 2,
  },
  dateSelector: {
    flex: 9,
  } 
})

export default styles
