import background from 'img/bg-white-login-pattern-icons.png'

const styles = theme => ({
  notVisible: {
    display: 'none',
  },
  base: {
    ...theme.loginBase,
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    backgroundColor: props =>
      props.useCustomLogin && props.useCustomColor && props.customColor
        ? props.customColor
        : 'rgba(240, 240, 240, 1)',
    backgroundImage: props =>
      props.useCustomLogin && props.useCustomBgImg
        ? `url(${props.customBgImg})`
        : `url(${background})`,
    backgroundSize: props => (props.useCustomLogin && props.useCustomBgImg ? 'cover' : 'unset'),
    backgroundPosition: props =>
      props.useCustomLogin && props.useCustomBgImg ? 'bottom' : 'top left',
    color: props =>
      props.useCustomLogin && props.useCustomTextColor && props.customTextColor
        ? props.customTextColor
        : theme.palette.common.black,
  },
  customize: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      maxWidth: '120px',
      height: 'auto',
      marginTop: '25px',
      [theme.breakpoints.up('lg')]: {
        marginTop: '0',
      },
    },
    '& > h1': {
      fontSize: '20px',
      fontWeight: '400',
      textAlign: 'center',
      marginTop: '18px',
      marginBottom: 0,
      paddingLeft: '20px',
      paddingRight: '20px',
      [theme.breakpoints.up('lg')]: {
        fontSize: '30px',
      },
    },
  },
  content: {
    textAlign: 'center',
    '& h2': {
      fontFamily: 'brandon-grotesque, "Open Sans", open-sans, sans-serif',
      marginBottom: '33px',
      opacity: '.89',
      fontWeight: 600,
      fontSize: '20px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
    '& h2 small': {
      fontFamily: 'brandon-grotesque, "Open Sans", open-sans, sans-serif',
      display: 'block',
      marginBottom: '17px',
      fontSize: '12px',
      letterSpacing: '1px',
    },
    '& p': {
      marginBottom: '35px',
      opacity: '.7',
      fontStyle: 'italic',
      fontSize: '15px',
      lineHeight: '25px',
    },
  },
  forgotPassword: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    marginLeft: theme.spacing(1 / 4),
    color: '#cc5b69',
  },
  signUpContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    '& > div:first-child': {
      marginTop: theme.spacing(12),
      marginBottom: theme.spacing(8),
    },
  },
  dataProtectionContainer: {
    padding: '10px',
    '& > h3:first-child': {
      textAlign: 'center',
    },
    '& th': {
      fontSize: '0.625rem',
      color: props => (props.useCustomTextColor ? props.customTextColor : ''),
    },
    '& td': {
      fontSize: '0.625rem',
      color: props => (props.useCustomTextColor ? props.customTextColor : ''),
    },
    '& span': {
      fontSize: '0.625rem',
      fontWeight: 700,
      color: props =>
        props.useCustomLogin && props.useCustomTextColor && props.customTextColor
          ? props.customTextColor
          : theme.palette.common.black,
    },
    '& a': {
      color: props =>
        props.useCustomLogin && props.useCustomTextColor && props.customTextColor
          ? props.customTextColor
          : theme.palette.common.black,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: props =>
      props.useCustomLogin && props.useCustomColor ? props.customColor : theme.palette.primary.main,
    color: props => (props.useCustomLogin && props.customTextColor ? props.customTextColor : ''),
    '&:hover': {
      backgroundColor: props =>
        props.useCustomLogin && props.useCustomColor ? props.customColor : '',
    },
  },
})

export default styles
