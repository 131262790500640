const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  iconLeft: {
    padding: theme.spacing(1 / 2),
    border: '2px solid',
    borderRadius: '100%',
    '& .icon-funds': {
      fontSize: '1.25rem',
    },
    alignSelf: 'baseline',
  },
  rewardContainer: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  rewardLabel: {
    fontSize: '0.75rem',
    width: '100%',
    fontWeight: 700,
  },
  rewardValue: {
    marginTop: theme.spacing(1 / 4),
    marginBottom: theme.spacing(1.25),
    // maxHeight: '20px',
    overflow: 'hidden',
    fontSize: '0.75rem',
    '& .icon-gallery': {
      fontSize: '0.85rem',
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
  },
  benefitsValue: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'left',
  },
  factorBenefits: {
    width: '100%',
    '& span:nth-child(1)': {
      fontSize: '0.75rem',
    },
    '& span:nth-child(2)': {
      fontWeight: 700,
    },
  },
  labelBenefits: {
    width: '100%',
    fontSize: '0.75rem',
  },
  rewardCustomLink: {
    marginLeft: theme.spacing(1 / 2),
    color: 'white',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'none',
      outline: 'none',
    },
  },
})

export default styles
