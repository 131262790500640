import { RESET_IDEA_STORAGE_VIDEO, RESET_CHALLENGE_STORAGE_VIDEO, SET_EXTERNAL_VIDEO } from './Constants'

export function resetIdeaStorageVideo() {
  return { type: RESET_IDEA_STORAGE_VIDEO }
}

export function resetChallengeStorageVideo() {
  return { type: RESET_CHALLENGE_STORAGE_VIDEO }
}

export function setIdeaExternalVideo(src) {
  return { 
    type: SET_EXTERNAL_VIDEO,
    src 
  }
}