export const transformHelpSectionServiceResponseDataWithState = jsonResponse => {
  return {
    helpList: jsonResponse,
  }
}

export const transformRankingServiceResponseDataWithState = jsonResponse => {
  return {
    footerEntrepreneurs: jsonResponse.entrepeneurs,
    footerInvestors: jsonResponse.investors,
  }
}

export const transformHeaderSectionServiceResponseDataWithState = jsonResponse => {
  const response = jsonResponse || {}
  return {
    icon: response.ico || '',
    name: response.name || '',
    url: response.url || '',
  }
}
