const styles = () => ({
  root: {
    width: '100%',
  },
  title: {
    color: '#333333',
    fontSize: '18px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  user: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '& .left': {
      width: '100px',
      height: '100px',
      '& img': {
        width: '100%',
        height: '100%',
        display: 'block',
      }
    },
    '& .right.user-info': {
      flex: 2,
      backgroundColor: '#fff',
      color: '#756f6f',
      border: '1px solid #dcdcdc',
      borderLeft: 'none',
    },
    '& .fullname': {
      fontWeight: 700,
      textAlign: 'center',
      marginTop: '5px',
      marginBottom: '10px',
    },
    '& .user-data-block': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .data-block': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '& .pull-right': {
      marginLeft: '6px',
    },
    '& .data-block-number': {
      color: '#000',
      fontSize: '1.5rem',
      fontWeight: 700,
      marginTop: '6px',
    }
  },
  userButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
})

export default styles
