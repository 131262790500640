import React, { useEffect, useState } from 'react'
import { withStyles, Button, CircularProgress } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'
import MentionTextField from '../mention_text_field/View'

const AddComment = props => {
  const {
    classes,
    onPostComment,
    postingCommentStatus,
    onMentionType,
    userSuggestions,
    resetUserSuggestions,
    onUpdateComment,
    stateTabIdea,
  } = props

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (postingCommentStatus === 'success') {
      onUpdateComment('')
      setIsLoading(false)
    }
    if (postingCommentStatus === 'failure') {
      setIsLoading(false)
    }
    if (postingCommentStatus === 'loading') {
      setIsLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingCommentStatus])

  return (
    <div className={classes.root}>
      <MentionTextField
        id="main-comment"
        label={i18n.t('COMMENT.INPUT_PLACEHOLDER')}
        onTextChange={onUpdateComment}
        textValue={stateTabIdea.commentText}
        userSuggestions={userSuggestions}
        resetUserSuggestions={resetUserSuggestions}
        onMentionType={onMentionType}
      />
      <div className={classes.sideText}>
        <div className={classes.title}>
          <span className="icon-comment" />
          <span>{i18n.t('IDEA.EFFECTIVE_CONSTRUCTIVE_FEEDBACK')}</span>
        </div>
        <div className={classes.subtitle}>
          <span>{i18n.t('IDEA.BE_SPECIFIC')}</span>
          <span>{i18n.t('IDEA.ASK_QUESTIONS')}</span>
          <span>{i18n.t('IDEA.DONT_BE_AFRAID')}</span>
        </div>
      </div>
      <Button
        disabled={isLoading || stateTabIdea.commentText === ''}
        color="primary"
        variant="contained"
        onClick={() => onPostComment(stateTabIdea.commentText)}
      >
        {i18n.t('IDEA.POST_COMMENT')}
        {isLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} color="primary" />
        )}
      </Button>
    </div>
  )
}

export default withStyles(styles)(AddComment)
