export const POST_STANDARD_FORM_STARTED = 'POST_STANDARD_FORM_STARTED'
export const POST_STANDARD_FORM_SUCCESS = 'POST_STANDARD_FORM_SUCCESS'
export const POST_STANDARD_FORM_FAILURE = 'POST_STANDARD_FORM_FAILURE'

export const GET_STANDARD_FORM_STARTED = 'GET_STANDARD_FORM_STARTED'
export const GET_STANDARD_FORM_SUCCESS = 'GET_STANDARD_FORM_SUCCESS'
export const GET_STANDARD_FORM_FAILURE = 'GET_STANDARD_FORM_FAILURE'

export const POST_FILE_STARTED = 'POST_FILE_STARTED'
export const POST_FILE_SUCCESS = 'POST_FILE_SUCCESS'
export const POST_FILE_FAILURE = 'POST_FILE_FAILURE'

export const DELETE_FILE_STARTED = 'DELETE_FILE_STARTED'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE'
