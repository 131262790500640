import { connect } from 'react-redux'
import View from 'components/nxt_currency/View'

const mapStateToProps = state => {
  return {
    currencyConfiguration: state.data.domain.domainConfiguration.logisticCurrencyConfiguration,
  }
}
export default connect(
  mapStateToProps,
  null,
)(View)
