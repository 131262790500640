import { connect } from 'react-redux'
import { deleteIdeaById } from 'services/ideas'
import AlertModalView from 'support/popup_dialogs/views/components/alert_modal_view/AlertModalView'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import i18n from 'support/i18n'
import Navigation from 'support/navigation'
import { mainButtonActionSetup } from './Utils'

const dialogSetup = {
  navigateToAfter: () => Navigation.navigateToExplore(),
  onClickActionButton: (ownProps, onSuccess, onFailure) =>
    deleteIdeaById(ownProps.id, onSuccess, onFailure),
  successModalText: i18n.t('CHALLENGES.DELETE_OK'),
}

const mapStateToProps = state => {
  const { customIdeaTitles } = state.data.domain.customTitlesConf

  return {
    title: i18n.t('CONFIRMATION_MESSAGE'),
    description: i18n.t('EXPLORE_IDEA.DELETE_IDEA', {
      title: customIdeaTitles.singularTitle || 'idea',
      pluralTitle: customIdeaTitles.pluralTitle || 'ideas',
    }),
    mainButtonText: i18n.t('ACCEPT'),
    cancelable: true,
    cancelButtonText: i18n.t('CANCEL'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    mainButtonAction: () => mainButtonActionSetup(dispatch, ownProps, dialogSetup),
    cancelButtonAction: () => dispatch(hideDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModalView)
