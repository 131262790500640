import React from 'react'
import { Card, CardContent, Grid, TextField, Typography } from '@material-ui/core'
import i18n from 'support/i18n'
import { TextFieldStyled } from './utils'

export const OptionalInformation = ({ onChangeValue, info, isDisabled }) => {
  const { legalAgent, companyAddress, businessName } = info

  const onChange = (event, field) => {
    const { value } = event.target
    if (value.length >= 0) {
      const sanitized = value.replace(/[^\w\sñ]/gi, '')
      onChangeValue('optionalQuestions', { ...info, [field]: sanitized })
    }
  }

  return (
    <Grid item xs={12}>
      <Card
        variant="elevation"
        elevation={3}
        style={{
          borderRadius: 8,
        }}
      >
        <CardContent spacing={2}>
          <Typography variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
            {i18n.t('FORMS.STANDARD_FORM.FORM.OPTIONAL_QUESTIONS')}
          </Typography>
          <TextField
            label={i18n.t('FORMS.STANDARD_FORM.FORM.LEGAL_AGENT')}
            margin="normal"
            fullWidth
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'legalAgent')}
            value={legalAgent}
            disabled={isDisabled}
          />
          <TextField
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.COMPANY_ADDRESS')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'companyAddress')}
            value={companyAddress}
            disabled={isDisabled}
          />
          <TextField
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.BUSINESS_NAME')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'businessName')}
            value={businessName}
            disabled={isDisabled}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}
