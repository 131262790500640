import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import ButtonLink from 'components/button_link/View'
import i18n from 'support/i18n'
import styles from './View.Style'

const ReportIdeaSection = (props) => {
  const { classes, onReportClick, customIdeaTitle } = props

  return (
    <div className={classes.root}>
      <ButtonLink
        classes={{
          linkButton: classes.reportLinkButton,
        }}
        onLinkClick={onReportClick}
      >
        {i18n.t('EXPLORE_IDEA.REPORT_IDEA', { title: customIdeaTitle || "idea" })}
      </ButtonLink>
    </div>
  )
}

ReportIdeaSection.propTypes = {
  onReportClick: PropTypes.func.isRequired,
  customIdeaTitle: PropTypes.string.isRequired
}

export default withStyles(styles)(ReportIdeaSection)
