/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Box } from '@material-ui/core'
import { Filter } from '../../components'
import ShowFilter from '../../components/show_filter/View'
import PrivateIdeasBanner from './components/private_ideas_banner/View'
import AnalyticsService from '../../support/analytics'
import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from '../../common_constants/Ideas'
import ExploreBottom from './components/explore_bottom/View'
import ExploreFilterFields from './components/explore_filter_fields/View'
import SearchInput from './components/search_input/View'
import style from './View.Style'
import ChallegerSlider from './components/challenger_slider'
import NxtCircularLoader from '../../components/nxt_circular_loader/View'

export const INIT_FILTER = {
  status: [],
  challenge: {},
  organizations: [],
  types: [],
  attributes: [],
}

class Explore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFilters: INIT_FILTER,
      search: '',
      showSlider: false,
      offset: 0,
      disableFilters: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleCardTypeChange = this.handleCardTypeChange.bind(this)
    this.handleActiveFilters = this.handleActiveFilters.bind(this)
    this.handleOnLoadMore = this.handleOnLoadMore.bind(this)
    this.handleNavigateToNewIdea = this.handleNavigateToNewIdea.bind(this)
    this.handleResetSearchClick = this.handleResetSearchClick.bind(this)
    this.getIdeas = this.getIdeas.bind(this)
  }

  componentDidMount() {
    const { getActiveChallenges, getRankings, domainLoaded, applyFilter } = this.props

    if (domainLoaded) {
      getRankings()
      getActiveChallenges()
      applyFilter(INIT_FILTER)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      useHelpSection,
      getHelpSection,
      getHeaderSection,
      domainLoaded,
      getActiveChallenges,
      getRankings,
      applyFilter,
    } = this.props

    if (useHelpSection && prevProps.useHelpSection !== useHelpSection) {
      getHeaderSection()
      getHelpSection()
    }

    if (domainLoaded && prevProps.domainLoaded !== domainLoaded) {
      getActiveChallenges()
      getRankings()
      applyFilter(INIT_FILTER)
    }
  }

  componentWillUnmount() {
    this.setState({ activeFilters: INIT_FILTER })
  }

  getIdeas(offset = 0) {
    const { applyFilter } = this.props
    const { activeFilters } = this.state
    const { status, challenge, organizations, types, attributes } = activeFilters

    applyFilter(
      challenge.id || null,
      organizations?.map(org => `${org.id}`),
      status?.map(statusItem => `${statusItem.id}`),
      types?.map(type => `${type.id}`),
      attributes?.map(attribute => `${attribute.id}`),
      offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT,
      offset,
    )
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      disableFilters: !!event.target.value,
    })
  }

  handleCardTypeChange(value) {
    this.setState({ showSlider: value })
    AnalyticsService.trackEvent({
      category: 'explore-ideas',
      action: 'display',
      label: value ? 'carousel' : 'grid',
    })
  }

  handleActiveFilters(newFilters) {
    this.setState({ ...newFilters, offset: 0 }, this.getIdeas)
  }

  handleOnLoadMore() {
    const { offset } = this.state
    const newLimit = offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT
    const newOffset = newLimit + offset
    this.getIdeas(newOffset)
    this.setState({ offset: newOffset })
  }

  handleNavigateToNewIdea() {
    const { isPrivateFlowActive, navigateToNewIdea } = this.props
    navigateToNewIdea(isPrivateFlowActive)
  }

  handleResetSearchClick() {
    this.getIdeas()
    this.setState({ offset: 0 })
  }

  render() {
    const {
      applyFilter,
      applySearch,
      statusList,
      challenges,
      classes,
      footerInvestors,
      footerEntrepreneurs,
      getOrganization,
      getTypes,
      getAttributes,
      headerSection,
      helpList,
      ideas,
      isIdeasLoading,
      totalIdeas,
      organizationsName,
      navigateToChallengeDetail,
      navigateToExternal,
      navigateToHowItWorks,
      navigateToIdea,
      navigateToStaticHelpNextinit,
      navigateToRanking,
      navigateToUserProfile,
      navigateToNewPrivateIdea,
      organizations,
      organisationsSelected,
      typesName,
      types,
      attributesName,
      attributes,
      isOrganizationsActive,
      isAttributesActive,
      isTypesActive,
      hasMoreIdeasToLoad,
      isPrivateFlowActive,
      privateBannerSrc,
      ideaStatusStepper,
      customTitlesConf,
    } = this.props

    const { activeFilters, search, showSlider, offset, disableFilters } = this.state
    const { customIdeaTitles, isRanking } = customTitlesConf

    return (
      <>
        {/* CHALLENGERS */}
        {challenges.length > 0 && (
          <ChallegerSlider
            challenges={challenges}
            classes={classes}
            navigateToChallenge={navigateToChallengeDetail}
            navigateToIdea={navigateToIdea}
            isPrivate={isPrivateFlowActive}
            customIdeaTitle={customIdeaTitles.pluralTitle}
          />
        )}

        {/* FILTERS */}
        <Box mt={3} gridArea="middle">
          <div className={classes.middle}>
            <div className={classes.center}>
              {isPrivateFlowActive && (
                <PrivateIdeasBanner
                  imageSrc={privateBannerSrc}
                  onBannerClick={navigateToNewPrivateIdea}
                />
              )}
              {!isPrivateFlowActive && (
                <>
                  <Filter className={classes.center}>
                    <ExploreFilterFields
                      activeFilters={activeFilters}
                      statusList={statusList}
                      challenges={challenges}
                      className={classes.gridCenter}
                      getOrganization={getOrganization}
                      getTypes={getTypes}
                      getAttributes={getAttributes}
                      organizationsName={organizationsName}
                      organizations={organizations}
                      organizationsApplied={organisationsSelected}
                      typesName={typesName}
                      types={types}
                      attributesName={attributesName}
                      attributes={attributes}
                      isOrganizationsActive={isOrganizationsActive}
                      isTypesActive={isTypesActive}
                      isAttributesActive={isAttributesActive}
                      handleActiveFilters={this.handleActiveFilters}
                      disabled={disableFilters}
                    />
                  </Filter>
                  <div className={classes.ideasFilter}>
                    <SearchInput
                      applySearch={applySearch}
                      resetSearch={this.handleResetSearchClick}
                      handleChange={this.handleChange}
                      value={search}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {/* IDEAS */}
          {!isPrivateFlowActive && (
            <div className={classes.middle}>
              <div className={classes.center}>
                <NxtCircularLoader isLoading={isIdeasLoading}>
                  <ShowFilter
                    cards={ideas}
                    totalIdeas={totalIdeas}
                    onClick={navigateToIdea}
                    onSubmitterClick={navigateToUserProfile}
                    showSlider={showSlider}
                    onCardTypeChange={this.handleCardTypeChange}
                    activeFilters={activeFilters}
                    applyFilter={applyFilter}
                    onLoadMore={this.handleOnLoadMore}
                    offset={offset}
                    showLoadMoreButton={hasMoreIdeasToLoad}
                    ideaStatusStepper={ideaStatusStepper}
                    showSmallCardCircle
                    customIdeaTitles={customIdeaTitles}
                  />
                </NxtCircularLoader>
              </div>
            </div>
          )}
        </Box>

        {/* FOOTER */}
        {!isPrivateFlowActive && (
          <div className={classes.bottom}>
            <div className={classes.exploreBottom}>
              <ExploreBottom
                footerInvestors={footerInvestors}
                footerEntrepreneurs={footerEntrepreneurs}
                headerSection={headerSection}
                helpList={helpList}
                navigateToExternal={navigateToExternal}
                navigateToIdea={this.handleNavigateToNewIdea}
                navigateToStaticHelpNextinit={navigateToStaticHelpNextinit}
                navigateToHowItWorks={navigateToHowItWorks}
                navigateToRanking={navigateToRanking}
                navigateToUserProfile={navigateToUserProfile}
                showRanking={isRanking}
                customIdeaTitles={customIdeaTitles}
              />
            </div>
          </div>
        )}
      </>
    )
  }
}

Explore.defaultProps = {
  headerSection: {},
  statusList: [],
  organizations: [],
  types: [],
  attributes: [],
  isOrganizationsActive: false,
  isTypesActive: false,
  isAttributesActive: false,
}

Explore.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  applySearch: PropTypes.func.isRequired,
  statusList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      ideas: PropTypes.array.isRequired,
      mainPhoto: PropTypes.string.isRequired,
      numIdeas: PropTypes.string.isRequired,
      proposerName: PropTypes.string.isRequired,
      proposerPhoto: PropTypes.string.isRequired,
      sponsorName: PropTypes.string,
      sponsorPhoto: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  classes: PropTypes.shape({
    bottom: PropTypes.string.isRequired,
    middle: PropTypes.string.isRequired,
    top: PropTypes.string.isRequired,
  }).isRequired,
  footerInvestors: PropTypes.arrayOf(
    PropTypes.shape({
      amountInvested: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      fullname: PropTypes.string.isRequired,
      ideas: PropTypes.number.isRequired,
      investments: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
    }),
  ).isRequired,
  footerEntrepreneurs: PropTypes.arrayOf(
    PropTypes.shape({
      amountInvested: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      fullname: PropTypes.string.isRequired,
      ideas: PropTypes.number.isRequired,
      investments: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
    }),
  ).isRequired,
  getActiveChallenges: PropTypes.func.isRequired,
  getHeaderSection: PropTypes.func.isRequired,
  getHelpSection: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  getRankings: PropTypes.func.isRequired,
  getTypes: PropTypes.func.isRequired,
  getAttributes: PropTypes.func.isRequired,
  headerSection: PropTypes.shape({}),
  helpList: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string.isRequired,
      header: PropTypes.bool.isRequired,
      ico: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  ideas: PropTypes.arrayOf(
    PropTypes.shape({
      challenged: PropTypes.bool,
      textWithoutTags: PropTypes.string,
      id: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          bucket: PropTypes.string,
          key: PropTypes.string,
          name: PropTypes.string,
          size: PropTypes.number,
          url: PropTypes.string,
        }),
      ),
      investors: PropTypes.string,
      goal: PropTypes.string,
      pledged: PropTypes.string,
      sponsored: PropTypes.bool,
      ownerName: PropTypes.string,
      title: PropTypes.string,
      created: PropTypes.number,
      countdownEnds: PropTypes.number,
    }),
  ).isRequired,
  isIdeasLoading: PropTypes.bool.isRequired,
  isOrganizationsActive: PropTypes.bool,
  isTypesActive: PropTypes.bool,
  isAttributesActive: PropTypes.bool,
  isPrivateFlowActive: PropTypes.bool.isRequired,
  navigateToChallengeDetail: PropTypes.func.isRequired,
  navigateToExternal: PropTypes.func.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  navigateToHowItWorks: PropTypes.func.isRequired,
  navigateToStaticHelpNextinit: PropTypes.func.isRequired,
  navigateToRanking: PropTypes.func.isRequired,
  navigateToUserProfile: PropTypes.func.isRequired,
  navigateToNewIdea: PropTypes.func.isRequired,
  navigateToNewPrivateIdea: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  organizationsName: PropTypes.string.isRequired,
  privateBannerSrc: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  useHelpSection: PropTypes.bool.isRequired,
  domainLoaded: PropTypes.bool.isRequired,
  customTitlesConf: PropTypes.shape({
    customIdeaTitles: PropTypes.shape({
      singularTitle: PropTypes.string,
      pluralTitle: PropTypes.string,
    }).isRequired,
    isChallenge: PropTypes.bool,
    isInvestment: PropTypes.bool,
    isRanking: PropTypes.bool,
  }).isRequired,
}

export default withStyles(style)(Explore)
