import { generateRequestCsrf } from "support/utils/auth"

export const AuthRequestInterceptor = serviceClient => config => {
  return new Promise(resolve => {
    const { csrf } = serviceClient.store.getState().auth
    if (csrf) {
      const paramIndex = config.url.indexOf('?')
      if (paramIndex < 0) {
        return resolve({
          ...config,
          url: `${config.url}?_nxt_csrf=${generateRequestCsrf(csrf)}`,
        })
      }
      return resolve({
        ...config,
        url: `${config.url}&_nxt_csrf=${generateRequestCsrf(csrf)}`,
      })
    }
    return resolve(config)
  })
}
