const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    '& > button': {
      margin: theme.spacing(1, 0),
    },
    '& > div': {
      width: '49%',
    },
  },
  sideText: {
    padding: theme.spacing(1),
    color: '#666666',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '700',
    '& > span:first-child': {
      fontSize: '24px',
      marginRight: theme.spacing(1),
    },
  },
  subtitle: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
  },
  suggestions: {
    position: 'absolute',
    bottom: '50px',
  },
})

export default styles
