import { ServiceCall } from 'support/network/ServiceCallAction'
import * as types from 'actions/sync/create_idea_form/Constants'
import { prepareHeadersFromState } from '../HeadersUtils'
import {
  transformImageServiceResponseWithState,
  transformIdeaTitleResponseWithState,
  transformUploadFileResponse,
  transformGetIdeaResponse,
  transformPostContribution,
  transformPutContribution,
  transformDeleteContribution,
  transformContributionsResponse,
  transformInvestorsResponse,
  transformInvestResponse,
  transformDocumentUploadResponse,
  transformDeleteDocument,
  transformIdeaTeamResponse,
  transformGetCommentsResponse,
  transformKpisResponse,
  transformFileUploadUrlResponse,
  transformIdeaFileResponse,
} from './ResponseUtils'
import {
  setContent,
  setIdeaBodyFromState,
  setTitleBody,
  setContributionBodyFromState,
  setAskInvestmentsBody,
  setInvestmentAmount,
  setDocumentBody,
  setPostSendInvitationBody,
  setCommentBody,
  setValidatePendingIdea,
  setPublishDraft,
  setKpiContent,
  setFileContent,
} from './RequestUtils'
import { extractPropertiesFromJsonObject } from '../RequestUtils'

export const ADD_IMAGE_URL = '/s/idea/image'
export const DELETE_IDEA_IMAGE_URL = '/s/gcs/##image_key##/##id##'
export const SAVE_DRAFT_URL = '/s/idea/draft'
export const GET_IDEA_URL = '/s/idea/'
export const POST_REPORT_IDEA_URL = '/s/idea/##id##/report'
export const PUBLISH_IDEA_URL = '/s/idea/publish'
export const TITLE_AVAILABLE_URL = '/s/idea/url-available'
export const UPLOAD_FILE_URL = '/s/gcs/uploadurl'
const CONTRIBUTIONS_URL = '/api/v1/idea/##id##/contribution'
const INVESTORS_URL = '/s/idea/##id##/investors'
const POST_INVEST_URL = '/s/idea/##id##/invest'
const POST_SEND_INVITATION_URL = '/s/idea/##id##/invite'
const GET_IDEA_TEAM_URL = '/s/idea/##id##/team'
const POST_ASK_FOR_INVESTMENTS = '/s/user/requestInvest'
const DELETE_USER_FROM_TEAM_URL = '/s/idea/##id##/team/##email##?initiative=##isInitiative##'
const POST_IDEA_DOCUMENT_URL = '/s/idea/##id##/document'
const DELETE_IDEA_DOCUMENT_URL = '/api/v1/idea/##id##/document/##documentId##'
const GET_IS_USER_INVITED_TO_TEAM_URL = '/s/idea/isUserInvitedToIdeaTeam/##id##'
const GET_IS_USER_INVITED_TO_INNOVATION_TEAM_URL =
  '/s/idea/isUserInvitedToIdeaInnovationTeam/##id##'
const POST_REJECT_JOIN_IDEA_TEAM_URL = '/s/idea/rejectJoinTeam/##id##'
const POST_ACCEPT_JOIN_IDEA_TEAM_URL = '/s/idea/acceptJoinTeam/##id##'
const POST_REJECT_JOIN_INNOVATION_TEAM_URL = '/s/idea/rejectJoinInnovationTeam/##id##'
const POST_ACCEPT_JOIN_INNOVATION_TEAM_URL = '/s/idea/acceptJoinInnovationTeam/##id##'
const COMMON_IDEA_COMMENT_URL = '/s/comment/idea/##id##'
const PUT_DELETE_IDEA_COMMENT_URL = '/s/comment/idea/##id##/##commentId##'
const POST_VOTE_IDEA_COMMENT_URL = '/s/comment/##commentId##/vote'
const POST_SHARE_IDEA_URL = '/s/idea/##ideaId##/sharebyemail'
const POST_VALIDATE_IDEA_URL = '/s/idea/##ideaId##/validate'
const DELETE_IDEA_URL = '/s/idea/##ideaId##'
const POST_ACTIVATE_PILOT_URL = '/s/idea/##ideaId##/activate-pilot'
const POST_IMPLEMENT_PILOT_URL = '/s/idea/##ideaId##/implement'
const POST_TRANSFER_PROMOTER_URL = '/s/idea/##ideaId##/transferPromotter'
const POST_PUBLISH_DRAFT_URL = '/s/idea/##ideaId##/publish'
const GET_IS_TEAM_REQUEST_ACTIVE_URL = '/s/idea/isTeamRequestActive/##id##'
const UPDATE_KPI = '/s/idea/##ideaId##/kpi/##id##/edit'
const UPDATE_KPI_SUCCESS = '/s/idea/##ideaId##/kpi/##id##/success'
const DELETE_KPI = '/s/idea/##ideaId##/kpi/##id##/delete'
const NEW_KPI = '/s/idea/##ideaId##/kpi'
const POST_FILE_UPLOAD_URL = 'api/v1/idea/##id##/document/upload_url'
const GET_CHALLENGE_ORGANIZATIONS_URL = '/api/v1/challenge/challengeOrganization/##id##'

export const getKpis = id => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_KPIS_STARTED
  apiCall.successReqType = types.GET_KPIS_SUCCESS
  apiCall.failureReqType = types.GET_KPIS_FAILURE
  apiCall.setEndpointFromState = () => `${GET_IDEA_URL}${id}`
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformKpisResponse
  return apiCall.getAction()
}

export const newKpi = (
  { type, unit, range, expected, current, name, ideaId },
  onSuccess,
  onFailure,
) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_NEW_KPI_STARTED
  apiCall.successReqType = types.POST_NEW_KPI_SUCCESS
  apiCall.failureReqType = types.POST_NEW_KPI_FAILURE
  apiCall.setEndpointFromState = () => NEW_KPI.replace('##ideaId##', ideaId)
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setKpiContent({ type, unit, range, expected, current, name })
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export const updateKpi = (
  { id, type, unit, range, expected, current, name, success, ideaId },
  onSuccess,
  onFailure,
) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_KPIS_STARTED
  apiCall.successReqType = types.POST_KPIS_SUCCESS
  apiCall.failureReqType = types.POST_KPIS_FAILURE
  apiCall.setEndpointFromState = () =>
    UPDATE_KPI.replace('##ideaId##', ideaId).replace('##id##', id)
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () =>
    setKpiContent({ id, type, unit, range, expected, current, name, success })
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export const deleteKpi = ({ id, ideaId }, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.DELETE_KPIS_STARTED
  apiCall.successReqType = types.DELETE_KPIS_SUCCESS
  apiCall.failureReqType = types.DELETE_KPIS_FAILURE
  apiCall.setEndpointFromState = () =>
    DELETE_KPI.replace('##ideaId##', ideaId).replace('##id##', id)
  apiCall.method = 'POST'
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export const updateKpiSuccess = ({ id, success, ideaId }, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.UPDATE_KPI_SUCCESS_STARTED
  apiCall.successReqType = types.UPDATE_KPI_SUCCESS_SUCCESS
  apiCall.failureReqType = types.UPDATE_KPI_SUCCESS_FAILURE
  apiCall.setEndpointFromState = () =>
    UPDATE_KPI_SUCCESS.replace('##ideaId##', ideaId).replace('##id##', id)
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () =>
    `${encodeURIComponent('isSuccess')}=${encodeURIComponent(success)}`
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export function postIdeaImage(ideaId, image, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.ADD_IMAGE_STARTED
  apiCall.successReqType = types.ADD_IMAGE_SUCCESS
  apiCall.failureReqType = types.ADD_IMAGE_FAILURE
  apiCall.setEndpointFromState = () => ADD_IMAGE_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setContent(ideaId, image)
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformImageServiceResponseWithState
  apiCall.auth = true
  return apiCall.getAction()
}

export function deleteIdeaImage(ideaId, imageKey, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.DELETE_IDEA_IMAGE_STARTED
  apiCall.successReqType = types.DELETE_IDEA_IMAGE_SUCCESS
  apiCall.failureReqType = types.DELETE_IDEA_IMAGE_FAILURE
  apiCall.setEndpointFromState = () =>
    DELETE_IDEA_IMAGE_URL.replace('##id##', ideaId).replace(
      '##image_key##',
      encodeURIComponent(imageKey),
    )
  apiCall.method = 'DELETE'
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export const saveIdeaDraft = (formData, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.SAVE_IDEA_DRAFT_STARTED
  apiCall.successReqType = types.SAVE_IDEA_DRAFT_SUCCESS
  apiCall.failureReqType = types.SAVE_IDEA_DRAFT_FAILURE
  apiCall.setEndpointFromState = () => SAVE_DRAFT_URL
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.setBodyFromState = state => setIdeaBodyFromState(formData, state.data.createIdea.videos)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export const editIdea = (id, formData, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.EDIT_IDEA_STARTED
  apiCall.successReqType = types.EDIT_IDEA_SUCCESS
  apiCall.failureReqType = types.EDIT_IDEA_FAILURE
  apiCall.setEndpointFromState = () => `${SAVE_DRAFT_URL}/${id}`
  apiCall.method = 'PUT'
  apiCall.auth = true
  apiCall.setBodyFromState = state => setIdeaBodyFromState(formData, state.data.createIdea.videos)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export const createIdea = (formData, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.CREATE_IDEA_STARTED
  apiCall.successReqType = types.CREATE_IDEA_SUCCESS
  apiCall.failureReqType = types.CREATE_IDEA_FAILURE
  apiCall.setEndpointFromState = () => PUBLISH_IDEA_URL
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.setBodyFromState = state => setIdeaBodyFromState(formData, state.data.createIdea.videos)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export const postPublishDraft = (ideaId, investmentAmount, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_PUBLISH_DRAFT_STARTED
  apiCall.successReqType = types.POST_PUBLISH_DRAFT_SUCCESS
  apiCall.failureReqType = types.POST_PUBLISH_DRAFT_FAILURE
  apiCall.setEndpointFromState = () => POST_PUBLISH_DRAFT_URL.replace('##ideaId##', ideaId)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.setBodyFromState = () => setPublishDraft(investmentAmount)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export const titleAvailable = (title, onSuccess) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.SEARCH_TITLE_STARTED
  apiCall.successReqType = types.SEARCH_TITLE_SUCCESS
  apiCall.failureReqType = types.SEARCH_TITLE_FAILURE
  apiCall.setEndpointFromState = () => TITLE_AVAILABLE_URL
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.setBodyFromState = () => setTitleBody(title)
  apiCall.transformResponseDataWithState = transformIdeaTitleResponseWithState
  apiCall.onSuccess = onSuccess
  return apiCall.getAction()
}

export const uploadFile = onSuccess => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.UPLOAD_DOCUMENT_STARTED
  apiCall.successReqType = types.GET_URL_UPLOAD_FILE
  apiCall.failureReqType = types.UPLOAD_DOCUMENT_FAILURE
  apiCall.setEndpointFromState = () => UPLOAD_FILE_URL
  apiCall.method = 'GET'
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformUploadFileResponse
  return apiCall.getAction()
}

export const getIdea = id => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_IDEA_STARTED
  apiCall.successReqType = types.GET_IDEA_SUCCESS
  apiCall.failureReqType = types.GET_IDEA_FAILURE
  apiCall.setEndpointFromState = () => `${GET_IDEA_URL}${id}`
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformGetIdeaResponse
  return apiCall.getAction()
}

export const reportIdea = (id, onSuccess) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.REPORT_IDEA_STARTED
  apiCall.successReqType = types.REPORT_IDEA_SUCCESS
  apiCall.failureReqType = types.REPORT_IDEA_FAILURE
  apiCall.setEndpointFromState = () => `${POST_REPORT_IDEA_URL.replace('##id##', id)}`
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  return apiCall.getAction()
}

export function getIdeaContributions(id) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.SET_IDEA_CONTRIBUTIONS_STARTED
  apiCall.successReqType = types.SET_IDEA_CONTRIBUTIONS_SUCCESS
  apiCall.failureReqType = types.SET_IDEA_CONTRIBUTIONS_FAILURE
  apiCall.setEndpointFromState = () => CONTRIBUTIONS_URL.replace('##id##', id)
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformContributionsResponse
  return apiCall.getAction()
}

export function saveContribution(ideaId, contributionId) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.SET_IDEA_CONTRIBUTIONS_STARTED
  apiCall.successReqType = types.SET_IDEA_CONTRIBUTIONS_SUCCESS
  apiCall.failureReqType = types.SET_IDEA_CONTRIBUTIONS_FAILURE
  apiCall.setEndpointFromState = () => CONTRIBUTIONS_URL.replace('##id##', ideaId)
  apiCall.method = contributionId ? 'PUT' : 'POST'
  apiCall.setBodyFromState = setContributionBodyFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = contributionId
    ? transformPutContribution
    : transformPostContribution
  return apiCall.getAction()
}

export function deleteContribution(ideaId) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.SET_IDEA_CONTRIBUTIONS_STARTED
  apiCall.successReqType = types.SET_IDEA_CONTRIBUTIONS_SUCCESS
  apiCall.failureReqType = types.SET_IDEA_CONTRIBUTIONS_FAILURE
  apiCall.setEndpointFromState = () => CONTRIBUTIONS_URL.replace('##id##', ideaId)
  apiCall.method = 'DELETE'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformDeleteContribution
  return apiCall.getAction()
}

export function getIdeaInvestors(id) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_IDEA_INVESTORS_STARTED
  apiCall.successReqType = types.GET_IDEA_INVESTORS_SUCCESS
  apiCall.failureReqType = types.GET_IDEA_INVESTORS_FAILURE
  apiCall.setEndpointFromState = () => INVESTORS_URL.replace('##id##', id)
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformInvestorsResponse
  return apiCall.getAction()
}

export function postInvestIdea(id, investmentAmount, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_INVEST_IDEA_STARTED
  apiCall.successReqType = types.POST_INVEST_IDEA_SUCCESS
  apiCall.failureReqType = types.POST_INVEST_IDEA_FAILURE
  apiCall.setEndpointFromState = () => POST_INVEST_URL.replace('##id##', id)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformInvestResponse
  apiCall.setBodyFromState = () => setInvestmentAmount(investmentAmount)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function postAskForInvestments(ideaId, usersEmails, content, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_ASK_FOR_INVESTMENTS_STARTED
  apiCall.successReqType = types.POST_ASK_FOR_INVESTMENTS_SUCCESS
  apiCall.failureReqType = types.POST_ASK_FOR_INVESTMENTS_FAILURE
  apiCall.setEndpointFromState = () => POST_ASK_FOR_INVESTMENTS
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.setBodyFromState = () => setAskInvestmentsBody(ideaId, usersEmails, content)
  return apiCall.getAction()
}

export function postIdeaDocument(id, fileName, base64File, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.UPLOAD_DOCUMENT_STARTED
  apiCall.successReqType = types.UPLOAD_DOCUMENT_SUCCESS
  apiCall.failureReqType = types.UPLOAD_DOCUMENT_FAILURE
  apiCall.setEndpointFromState = () => `${POST_IDEA_DOCUMENT_URL.replace('##id##', id)}`
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setDocumentBody(fileName, base64File)
  apiCall.transformResponseDataWithState = transformDocumentUploadResponse
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export function deleteIdeaDocument(ideaId, documentId, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.DELETE_IDEA_DOCUMENT_STARTED
  apiCall.successReqType = types.DELETE_IDEA_DOCUMENT_SUCCESS
  apiCall.failureReqType = types.DELETE_IDEA_DOCUMENT_FAILURE
  apiCall.setEndpointFromState = () =>
    `${DELETE_IDEA_DOCUMENT_URL.replace('##id##', ideaId).replace('##documentId##', documentId)}`
  apiCall.method = 'DELETE'
  apiCall.auth = true
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = (_, state) =>
    transformDeleteDocument(documentId, state.data.createIdea.documents)
  return apiCall.getAction()
}

export function postPrepareIdeaDocuments(id, fileName, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.UPLOAD_DOCUMENT_STARTED
  apiCall.successReqType = types.UPLOAD_DOCUMENT_SUCCESS
  apiCall.failureReqType = types.UPLOAD_DOCUMENT_FAILURE
  apiCall.setEndpointFromState = () => `${POST_IDEA_DOCUMENT_URL.replace('##id##', id)}`
  apiCall.method = 'POST'
  apiCall.transformResponseDataWithState = transformDocumentUploadResponse
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export function postSendInvitations(email, ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_SEND_INVITATION_STARTED
  apiCall.successReqType = types.POST_SEND_INVITATION_SUCCESS
  apiCall.failureReqType = types.POST_SEND_INVITATION_FAILURE
  apiCall.setEndpointFromState = () => POST_SEND_INVITATION_URL.replace('##id##', ideaId)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.setBodyFromState = () => setPostSendInvitationBody(email)
  return apiCall.getAction()
}

export function getIdeaTeam(ideaId) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_IDEA_TEAM_STARTED
  apiCall.successReqType = types.GET_IDEA_TEAM_SUCCESS
  apiCall.failureReqType = types.GET_IDEA_TEAM_FAILURE
  apiCall.setEndpointFromState = () => GET_IDEA_TEAM_URL.replace('##id##', ideaId)
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformIdeaTeamResponse
  return apiCall.getAction()
}

export function deleteUserFromTeam(ideaId, email, isInitiative = false, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.DELETE_USER_FROM_TEAM_STARTED
  apiCall.successReqType = types.DELETE_USER_FROM_TEAM_SUCCESS
  apiCall.failureReqType = types.DELETE_USER_FROM_TEAM_FAILURE
  apiCall.setEndpointFromState = () =>
    DELETE_USER_FROM_TEAM_URL.replace('##id##', ideaId)
      .replace('##email##', email)
      .replace('##isInitiative##', `${!!isInitiative}`)
  apiCall.method = 'DELETE'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function getIsUserInvitedToInnovationIdeaTeam(ideaId) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_IS_USER_INVITED_TO_INNOVATION_TEAM_STARTED
  apiCall.successReqType = types.GET_IS_USER_INVITED_TO_INNOVATION_TEAM_SUCCESS
  apiCall.failureReqType = types.GET_IS_USER_INVITED_TO_INNOVATION_TEAM_FAILURE
  apiCall.setEndpointFromState = () =>
    GET_IS_USER_INVITED_TO_INNOVATION_TEAM_URL.replace('##id##', ideaId)
  apiCall.method = 'GET'
  apiCall.auth = true
  return apiCall.getAction()
}

export function getIsUserInvitedToIdeaTeam(ideaId) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_IS_USER_INVITED_TO_TEAM_STARTED
  apiCall.successReqType = types.GET_IS_USER_INVITED_TO_TEAM_SUCCESS
  apiCall.failureReqType = types.GET_IS_USER_INVITED_TO_TEAM_FAILURE
  apiCall.setEndpointFromState = () => GET_IS_USER_INVITED_TO_TEAM_URL.replace('##id##', ideaId)
  apiCall.method = 'GET'
  apiCall.auth = true
  return apiCall.getAction()
}

export function postAcceptJoinIdeaTeam(ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_ACCEPT_JOIN_IDEA_TEAM_STARTED
  apiCall.successReqType = types.POST_ACCEPT_JOIN_IDEA_TEAM_SUCCESS
  apiCall.failureReqType = types.POST_ACCEPT_JOIN_IDEA_TEAM_FAILURE
  apiCall.setEndpointFromState = () => POST_ACCEPT_JOIN_IDEA_TEAM_URL.replace('##id##', ideaId)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function postRejectJoinIdeaTeam(ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_REJECT_JOIN_IDEA_TEAM_STARTED
  apiCall.successReqType = types.POST_REJECT_JOIN_IDEA_TEAM_SUCCESS
  apiCall.failureReqType = types.POST_REJECT_JOIN_IDEA_TEAM_FAILURE
  apiCall.setEndpointFromState = () => POST_REJECT_JOIN_IDEA_TEAM_URL.replace('##id##', ideaId)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function postAcceptJoinInnovationTeam(ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_ACCEPT_JOIN_INNOVATION_TEAM_STARTED
  apiCall.successReqType = types.POST_ACCEPT_JOIN_INNOVATION_TEAM_SUCCESS
  apiCall.failureReqType = types.POST_ACCEPT_JOIN_INNOVATION_TEAM_FAILURE
  apiCall.setEndpointFromState = () =>
    POST_ACCEPT_JOIN_INNOVATION_TEAM_URL.replace('##id##', ideaId)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function postRejectJoinInnovationTeam(ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_REJECT_JOIN_INNOVATION_TEAM_STARTED
  apiCall.successReqType = types.POST_REJECT_JOIN_INNOVATION_TEAM_SUCCESS
  apiCall.failureReqType = types.POST_REJECT_JOIN_INNOVATION_TEAM_FAILURE
  apiCall.setEndpointFromState = () =>
    POST_REJECT_JOIN_INNOVATION_TEAM_URL.replace('##id##', ideaId)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function postComment(comment, parentId, ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_IDEA_COMMENT_STARTED
  apiCall.successReqType = types.POST_IDEA_COMMENT_SUCCESS
  apiCall.failureReqType = types.POST_IDEA_COMMENT_FAILURE
  apiCall.setEndpointFromState = () => COMMON_IDEA_COMMENT_URL.replace('##id##', ideaId)
  apiCall.setBodyFromState = () => setCommentBody(comment, parentId)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function getIdeaComments(ideaId) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_IDEA_COMMENTS_STARTED
  apiCall.successReqType = types.GET_IDEA_COMMENTS_SUCCESS
  apiCall.failureReqType = types.GET_IDEA_COMMENTS_FAILURE
  apiCall.setEndpointFromState = () =>
    `${COMMON_IDEA_COMMENT_URL.replace('##id##', ideaId)}?all=true`
  apiCall.setBodyFromState = () => `${encodeURIComponent('all')}=${encodeURIComponent(true)}`
  apiCall.transformResponseDataWithState = transformGetCommentsResponse
  apiCall.method = 'GET'
  apiCall.auth = true
  return apiCall.getAction()
}

export function deleteComment(commentId, ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.DELETE_IDEA_COMMENT_STARTED
  apiCall.successReqType = types.DELETE_IDEA_COMMENT_SUCCESS
  apiCall.failureReqType = types.DELETE_IDEA_COMMENT_FAILURE
  apiCall.setEndpointFromState = () =>
    PUT_DELETE_IDEA_COMMENT_URL.replace('##id##', ideaId).replace('##commentId##', commentId)
  apiCall.method = 'DELETE'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function voteComment(commentId, ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.VOTE_IDEA_COMMENT_STARTED
  apiCall.successReqType = types.VOTE_IDEA_COMMENT_SUCCESS
  apiCall.failureReqType = types.VOTE_IDEA_COMMENT_FAILURE
  apiCall.setEndpointFromState = () =>
    POST_VOTE_IDEA_COMMENT_URL.replace('##commentId##', commentId)
  apiCall.setBodyFromState = () => `${encodeURIComponent('idea')}=${encodeURIComponent(ideaId)}`
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function putComment(comment, commentId, ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.VOTE_IDEA_COMMENT_STARTED
  apiCall.successReqType = types.VOTE_IDEA_COMMENT_SUCCESS
  apiCall.failureReqType = types.VOTE_IDEA_COMMENT_FAILURE
  apiCall.setEndpointFromState = () =>
    PUT_DELETE_IDEA_COMMENT_URL.replace('##id##', ideaId).replace('##commentId##', commentId)
  apiCall.setBodyFromState = () => setCommentBody(comment)
  apiCall.method = 'PUT'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function postShareIdea(ideaId, emails, content, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.SHARE_IDEA_STARTED
  apiCall.successReqType = types.SHARE_IDEA_SUCCESS
  apiCall.failureReqType = types.SHARE_IDEA_FAILURE
  apiCall.setEndpointFromState = () => POST_SHARE_IDEA_URL.replace('##ideaId##', ideaId)
  apiCall.setBodyFromState = () => extractPropertiesFromJsonObject({ content, emails })
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function postValidateIdea(ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_VALIDATE_IDEA_STARTED
  apiCall.successReqType = types.POST_VALIDATE_IDEA_SUCCESS
  apiCall.failureReqType = types.POST_VALIDATE_IDEA_FAILURE
  apiCall.setEndpointFromState = () => POST_VALIDATE_IDEA_URL.replace('##ideaId##', ideaId)
  apiCall.setBodyFromState = () => setValidatePendingIdea()
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function deleteIdea(ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.DELETE_IDEA_STARTED
  apiCall.successReqType = types.DELETE_IDEA_SUCCESS
  apiCall.failureReqType = types.DELETE_IDEA_FAILURE
  apiCall.setEndpointFromState = () => DELETE_IDEA_URL.replace('##ideaId##', ideaId)
  apiCall.method = 'DELETE'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function postActivatePilotProject(ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.ACTIVATE_PILOT_PROJECT_STARTED
  apiCall.successReqType = types.ACTIVATE_PILOT_PROJECT_SUCCESS
  apiCall.failureReqType = types.ACTIVATE_PILOT_PROJECT_FAILURE
  apiCall.setEndpointFromState = () => POST_ACTIVATE_PILOT_URL.replace('##ideaId##', ideaId)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function postImplementPilotProject(ideaId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.IMPLEMENT_PILOT_PROJECT_STARTED
  apiCall.successReqType = types.IMPLEMENT_PILOT_PROJECT_SUCCESS
  apiCall.failureReqType = types.IMPLEMENT_PILOT_PROJECT_FAILURE
  apiCall.setEndpointFromState = () => POST_IMPLEMENT_PILOT_URL.replace('##ideaId##', ideaId)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function postTransferPromoter(ideaId, email, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.TRANSFER_IDEA_PROMOTER_STARTED
  apiCall.successReqType = types.TRANSFER_IDEA_PROMOTER_SUCCESS
  apiCall.failureReqType = types.TRANSFER_IDEA_PROMOTER_FAILURE
  apiCall.setEndpointFromState = () => POST_TRANSFER_PROMOTER_URL.replace('##ideaId##', ideaId)
  apiCall.setBodyFromState = () => `${encodeURIComponent('to')}=${encodeURIComponent(email)}`
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export const getIsTeamRequestActive = id => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_IS_TEAM_REQUEST_ACTIVE_STARTED
  apiCall.successReqType = types.GET_IS_TEAM_REQUEST_ACTIVE_SUCCESS
  apiCall.failureReqType = types.GET_IS_TEAM_REQUEST_ACTIVE_FAILURE
  apiCall.setEndpointFromState = () => GET_IS_TEAM_REQUEST_ACTIVE_URL.replace('##id##', id)
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = response => response
  return apiCall.getAction()
}

export function postFileUploadUrl(id, file, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_FILE_UPLOAD_URL_STARTED
  apiCall.successReqType = types.POST_FILE_UPLOAD_URL_SUCCESS
  apiCall.failureReqType = types.POST_FILE_UPLOAD_URL_FAILURE
  apiCall.setEndpointFromState = () => POST_FILE_UPLOAD_URL.replace('##id##', id)
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = json => transformFileUploadUrlResponse(json, file)
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export function postIdeaFile(data, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_UPLOAD_FILE_STARTED
  apiCall.successReqType = types.POST_UPLOAD_FILE_SUCCESS
  apiCall.failureReqType = types.POST_UPLOAD_FILE_FAILURE
  apiCall.setEndpointFromState = () => data.uploadUrl
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = () => ({
    'content-type': 'multipart/form-data',
  })
  apiCall.setBodyFromState = () => setFileContent(data.file)
  apiCall.transformResponseDataWithState = json => transformIdeaFileResponse(json, data.file)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export function getNewIdeaChallengeOrganization(challengeId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_NEW_IDEA_CHALLENGE_ORGANIZATION_STARTED
  apiCall.successReqType = types.GET_NEW_IDEA_CHALLENGE_ORGANIZATION_SUCCESS
  apiCall.failureReqType = types.GET_NEW_IDEA_CHALLENGE_ORGANIZATION_FAILURE
  apiCall.setEndpointFromState = () =>
    GET_CHALLENGE_ORGANIZATIONS_URL.replace('##id##', challengeId)
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}
