import React from 'react'
import i18n from 'support/i18n'
import * as PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import TextWithSelect from 'components/form/text_with_select/View'
import { TYPE_OF_TIME } from 'common_constants/Selects'
import style from './View.Style'

const quantityOptions = TYPE_OF_TIME.map(type => ({ ...type, name: i18n.t(type.name) }))
const negativeNumberError = i18n.t('LOGISTIC.FIELD_FORMAT_ERROR')

const EstimateForm = props => {
  const {
    classes,
    title,
    helpText,
    quantity,
    quantityError,
    hours,
    hoursError,
    time,
    timeError,
    timeType,
    onQuantityChange,
    onHoursChange,
    onTimeChange,
    onTimeTypeChange,
    classIcon,
    currency,
  } = props

  return (
    <div className={classes.root}>
      <div className={classes.titles}>
        <h3>
          <span className={`icon-${classIcon}`} />
          {title}
        </h3>
        <p>{helpText}</p>
      </div>
      <div className={classes.formContainer}>
        <TextField
          fullWidth
          label={`${i18n.t('LOGISTIC.ESTIMATED_AMOUNT')} ${currency}`}
          value={quantity}
          error={quantityError}
          margin="normal"
          type="number"
          onChange={event => onQuantityChange(event.target.value)}
          helperText={quantityError ? negativeNumberError : ''}
        />
        <TextField
          fullWidth
          label={i18n.t('LOGISTIC.HOURSMAN')}
          margin="normal"
          type="number"
          value={hours}
          onChange={event => onHoursChange(event.target.value)}
          error={hoursError}
          helperText={hoursError ? negativeNumberError : ''}
        />
        <TextWithSelect
          label={i18n.t('LOGISTIC.TIME')}
          fullWidth
          type="number"
          value={time}
          selectValue={timeType}
          handleTextValue={onTimeChange}
          handleSelectValue={onTimeTypeChange}
          selectOptions={quantityOptions}
          error={timeError}
          helperText={negativeNumberError}
        />
      </div>
    </div>
  )
}

EstimateForm.defaultProps = {
  helpText: '',
  quantity: '',
  quantityError: false,
  hours: '',
  hoursError: false,
  time: '',
  timeError: false,
  timeType: quantityOptions[0].id,
  classIcon: '',
  currency: '',
}

EstimateForm.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    titles: PropTypes.string,
    formContainer: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  quantity: PropTypes.string,
  quantityError: PropTypes.bool,
  hours: PropTypes.string,
  hoursError: PropTypes.bool,
  time: PropTypes.string,
  timeError: PropTypes.bool,
  timeType: PropTypes.string,
  onQuantityChange: PropTypes.func.isRequired,
  onHoursChange: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  onTimeTypeChange: PropTypes.func.isRequired,
  classIcon: PropTypes.string,
  currency: PropTypes.string,
}

export default withStyles(style)(EstimateForm)
