const styles = theme => ({
  top: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    flex: 1,
  },
  cardGroup: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingBottom: '1rem',
    '& > div': {
      marginRight: '2%',
    },
    '& > div:last-child': {
      marginRight: 0,
    },
  },
  secondCircle: {
    width: '75%',
  },
  selected: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  selectors: {
    flex: 1,
    width: '100%',
    ...theme.flexRowCenter,
    justifyContent: 'flex-end',
    '& > button': {
      minWidth: '40px',
      minHeight: '40px',
      fontSize: '1.5rem',
      padding: '0',
      margin: '0.3rem',
    },
    '& > button:last-child': {
      marginRight: 0,
    },
  },
  slide: {
    marginTop: '2rem',
    marginBottom: '3rem',
  },
  unselected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    '& span': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  loadMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3rem',
  },
  icon: {
    fontSize: '34px',
    color: '#999',
  },
})

export default styles
