import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, withStyles } from '@material-ui/core'
import { ButtonLink } from '../../components'
import i18n from '../../support/i18n'
import Navigation from '../../support/navigation'
import NavigationService from '../../support/navigation/NavigationService'
import DataProtection from '../../components/data_protection/View'
import UserDetailsForm from './components/user_details_form/View'
import SignUpForm from './components/sign_up_form/View'
import SignUpComplete from './components/sign_up_complete/View'
import styles from './View.Style'

const SignUp = props => {
  const {
    classes,
    onSubmit,
    useCreateAccount,
    useCustomColor,
    customColor,
    useCustomTextColor,
    customTextColor,
    useCustomImg,
    customImg,
    useCustomText,
    customText,
    useCustomBgImg,
    customBgImg,
    useCustomLogin,
    messageType,
    messageText,
    isSigning,
    setAuthMessageBox,
    resetAuthMessageBox,
    isUserAlreadyExisting,
    onForgotPasswordClick,
    signUpStep,
    isSavingUserDetails,
    onSaveUserDetails,
    onImageSelected,
    onClose,
    onLoad,
    getGDPR,
    gdpr,
    domaninFailure,
    handleGetUserDomains,
    userDomainList,
    currentDomain,
  } = props

  const customHistory = NavigationService.getHistory()
  const [email, setEmail] = useState('')
  const [isEmailFixed, setEmailFixed] = useState(false)
  const [showGDPR, setShowGDPR] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)

  useEffect(() => {
    if (domaninFailure) {
      Navigation.navigateToLogin()
    }
  }, [domaninFailure])

  useEffect(() => {
    onLoad()
    const queryParams = new URLSearchParams(window.location.search)
    const emailParam = queryParams.get('email')
    setEmailFixed(!!emailParam)
    if (emailParam) setEmail(emailParam)
    resetAuthMessageBox()
    getGDPR()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoginClick = () => {
    const urlComponents = customHistory.location.pathname.split('/')
    if (urlComponents.length >= 2 && urlComponents[1]) {
      const domain = urlComponents[1]
      Navigation.navigateToCustomLogin(domain)
    } else {
      Navigation.navigateToLogin()
    }
  }

  const formatDomainNames = namesArray => {
    if (namesArray.length === 0) {
      return ''
    }

    if (namesArray.length === 1) {
      return namesArray[0]
    }

    if (namesArray.length === 2) {
      return namesArray.join(` ${i18n.t('AND')} `)
    }

    const lastElement = namesArray.pop()
    return `${namesArray.join(', ')} ${i18n.t('AND')} ${lastElement}`
  }

  const handleOnRecoverPass = userEmail => {
    const urlDomain = customHistory.location.pathname.split('/')[1]
    onForgotPasswordClick(userEmail, urlDomain)
  }

  const renderUserAlreadyExisting = () => {
    const domainList = userDomainList?.map(item => item.domainName)

    if (domainList.includes(currentDomain)) {
      return (
        <div>
          <span>{i18n.t('COMMON_LOGIN.HEADER.USER_ALREADY_EXISTING')}</span>
          <ButtonLink
            classes={{ linkButton: classes.forgotPassword }}
            onLinkClick={() => handleOnRecoverPass(email)}
          >
            {i18n.t('COMMON_LOGIN.HEADER.FORGOT_PWD')}
          </ButtonLink>
        </div>
      )
      // eslint-disable-next-line no-else-return
    } else {
      return (
        <div>
          <span>
            {i18n.t('COMMON_LOGIN.HEADER.USER_ALREADY_EXISTING_1', {
              instance: formatDomainNames(domainList) || 'unknown',
            })}{' '}
            {i18n.t('COMMON_LOGIN.HEADER.USER_ALREADY_EXISTING_2')}{' '}
            <ButtonLink
              classes={{ linkButton: classes.forgotPassword }}
              onLinkClick={handleLoginClick}
            >
              {i18n.t('COMMON_LOGIN.HEADER.USER_ALREADY_EXISTING_3')}
            </ButtonLink>{' '}
            {i18n.t('COMMON_LOGIN.HEADER.USER_ALREADY_EXISTING_4')}{' '}
            <ButtonLink
              classes={{ linkButton: classes.forgotPassword }}
              onLinkClick={() => onForgotPasswordClick(email)}
            >
              {i18n.t('COMMON_LOGIN.HEADER.USER_ALREADY_EXISTING_5')}
            </ButtonLink>{' '}
          </span>
        </div>
      )
    }
  }

  const handleToggleGDPR = () => {
    setShowGDPR(!showGDPR)
  }

  const handleOnSubmit = (userEmail, password) => {
    setSubmitDisabled(true)
    onSubmit(userEmail, password)
  }

  const handleOnChangeEmail = value => {
    setSubmitDisabled(false)
    setEmail(value)
  }

  return (
    <div className={classes.base}>
      <div className={classes.customize}></div>
      <div className={classes.signUpContainer}>
        {signUpStep === 0 && (
          <SignUpForm
            email={email}
            onEmailChange={handleOnChangeEmail}
            setAuthMessageBox={setAuthMessageBox}
            isSigning={isSigning}
            messageType={messageType}
            messageText={isUserAlreadyExisting ? renderUserAlreadyExisting() : messageText}
            onSubmit={handleOnSubmit}
            useCreateAccount={useCreateAccount}
            useCustomColor={useCustomColor}
            customColor={customColor}
            useCustomTextColor={useCustomTextColor}
            customTextColor={customTextColor}
            useCustomImg={useCustomImg}
            customImg={customImg}
            useCustomText={useCustomText}
            customText={customText}
            useCustomBgImg={useCustomBgImg}
            customBgImg={customBgImg}
            useCustomLogin={useCustomLogin}
            isEmailFixed={isEmailFixed}
            onLoginClick={handleLoginClick}
            onToggleGDPR={handleToggleGDPR}
            onUserDomains={handleGetUserDomains}
            onSubmitDisabled={submitDisabled}
          />
        )}
        {signUpStep === 1 && (
          <UserDetailsForm
            useCustomColor={useCustomColor}
            customColor={customColor}
            useCustomTextColor={useCustomTextColor}
            customTextColor={customTextColor}
            useCustomImg={useCustomImg}
            customImg={customImg}
            useCustomText={useCustomText}
            customText={customText}
            useCustomBgImg={useCustomBgImg}
            customBgImg={customBgImg}
            useCustomLogin={useCustomLogin}
            setAuthMessageBox={setAuthMessageBox}
            isSavingUserDetails={isSavingUserDetails}
            messageType={messageType}
            messageText={isUserAlreadyExisting ? renderUserAlreadyExisting() : messageText}
            onSubmit={onSaveUserDetails}
            onImageSelected={onImageSelected}
            onClose={onClose}
          />
        )}
        {signUpStep === 2 && <SignUpComplete />}
        <div className={classes.dataProtectionContainer}>
          {showGDPR && (
            <Dialog
              open={showGDPR}
              onClose={handleToggleGDPR}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DataProtection
                  data={gdpr}
                  linkColor={useCustomTextColor && customTextColor ? customTextColor : null}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleToggleGDPR}
                  className={classes.submit}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {i18n.t('TERMS_AND_CONDITIONS_TABLE.CLOSE_BTN')}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      </div>
    </div>
  )
}

SignUp.defaultProps = {
  useCreateAccount: false,
  useCustomColor: false,
  customColor: '',
  useCustomTextColor: false,
  customTextColor: '',
  useCustomImg: false,
  customImg: '',
  useCustomText: false,
  customText: '',
  useCustomBgImg: false,
  customBgImg: '',
  useCustomLogin: false,
  gdpr: {
    id: null,
    language: '',
    responsable: '',
    purpose: '',
    legitimation: '',
    recipients: '',
    rights: '',
    additionalinformation: '',
    responsableUrl: '',
    purposeUrl: '',
    legitimationUrl: '',
    recipientsUrl: '',
    rightsUrl: '',
    additionalinformationUrl: '',
    gdprHtml: '',
  },
  domaninFailure: false,
}

SignUp.propTypes = {
  signUpStep: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSaveUserDetails: PropTypes.func.isRequired,
  setAuthMessageBox: PropTypes.func.isRequired,
  resetAuthMessageBox: PropTypes.func.isRequired,
  useCreateAccount: PropTypes.bool,
  useCustomColor: PropTypes.bool,
  customColor: PropTypes.string,
  useCustomTextColor: PropTypes.bool,
  customTextColor: PropTypes.string,
  useCustomImg: PropTypes.bool,
  customImg: PropTypes.string,
  useCustomText: PropTypes.bool,
  customText: PropTypes.string,
  useCustomBgImg: PropTypes.bool,
  customBgImg: PropTypes.string,
  useCustomLogin: PropTypes.bool,
  isSigning: PropTypes.bool.isRequired,
  isUserAlreadyExisting: PropTypes.bool.isRequired,
  isSavingUserDetails: PropTypes.bool.isRequired,
  messageType: PropTypes.number.isRequired,
  messageText: PropTypes.node.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  getGDPR: PropTypes.func.isRequired,
  gdpr: PropTypes.shape({
    id: PropTypes.number,
    language: PropTypes.string,
    responsable: PropTypes.string,
    purpose: PropTypes.string,
    legitimation: PropTypes.string,
    recipients: PropTypes.string,
    rights: PropTypes.string,
    additionalinformation: PropTypes.string,
    responsableUrl: PropTypes.string,
    purposeUrl: PropTypes.string,
    legitimationUrl: PropTypes.string,
    recipientsUrl: PropTypes.string,
    rightsUrl: PropTypes.string,
    additionalinformationUrl: PropTypes.string,
    gdprHtml: PropTypes.string,
  }),
  domaninFailure: PropTypes.bool,
}

export default withStyles(styles)(SignUp)
