import React, { useState, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

const RequestInvestment = props => {
  const {
    classes,
    myIdeasToInvestIn,
    isSendingInvestmentRequest,
    hasMyIdeasLoaded,
    onCancelClick,
    onSendRequestClick,
  } = props

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    if (hasMyIdeasLoaded && inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth)
    }
  }, [hasMyIdeasLoaded])

  const [ideaIdToInvestIn, setIdeaIdToInvestIn] = useState('')
  const [textToSend, setTextToSend] = useState('')

  const isValid = ideaIdToInvestIn && textToSend
  return (
    <div className={classes.root}>
      <FormControl variant="outlined" fullWidth>
        {!hasMyIdeasLoaded && (
          <TextField
            label={i18n.t('PROFILE.SELECT_IDEA_LABEL')}
            margin="normal"
            variant="outlined"
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ),
            }}
          />
        )}
        {hasMyIdeasLoaded && (
          <Fragment>
            <InputLabel ref={inputLabel}>{i18n.t('PROFILE.SELECT_IDEA_LABEL')}</InputLabel>
            <Select
              value={ideaIdToInvestIn}
              onChange={event => setIdeaIdToInvestIn(event.target.value)}
              labelWidth={labelWidth}
            >
              {myIdeasToInvestIn.map(idea => (
                <MenuItem value={idea.id} key={idea.id}>
                  {idea.title}
                </MenuItem>
              ))}
            </Select>
          </Fragment>
        )}
        <TextField
          className={classes.inputContainer}
          id="outlined-multiline-static"
          label={i18n.t('PROFILE.MESSAGE_LABEL')}
          multiline
          minRows="4"
          value={textToSend}
          onChange={event => setTextToSend(event.target.value)}
          variant="outlined"
        />
        <div className={classes.buttons}>
          <Button
            onClick={() => onSendRequestClick(ideaIdToInvestIn, textToSend)}
            color="primary"
            variant="contained"
            autoFocus
            disabled={!isValid || isSendingInvestmentRequest}
          >
            {i18n.t('PROFILE.SEND_REQUEST')}
            {isSendingInvestmentRequest && <CircularProgress size={20} />}
          </Button>
          <Button onClick={onCancelClick} color="primary">
            {i18n.t('CANCEL')}
          </Button>
        </div>
      </FormControl>
    </div>
  )
}

RequestInvestment.propTypes = {
  myIdeasToInvestIn: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasMyIdeasLoaded: PropTypes.bool.isRequired,
  isSendingInvestmentRequest: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onSendRequestClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(RequestInvestment)
