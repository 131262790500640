import React from 'react'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import ModalAlertController from './support/popup_dialogs/modal_alert_controller'
import { initialLoadActionConditional } from './actions/async/InitialLoadActions'
import DataInitializer from './support/data_initializer'
import WelcomeSplash from './support/splash'
import RootNav from './support/navigation/navigators/root_nav'
import ThemeProvider from './support/theme/theme_provider'
/* import HelpWidget from './support/help_widget' */
import { persistor, store } from './store'
import Loader from './support/loader/component'
import MainLoader from './support/loader/nxt_loader'
import './App.css'

const App = () => {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Loader loader={<MainLoader />}>
            <BrowserRouter>
              <ThemeProvider>
                <DataInitializer onInit={initialLoadActionConditional}>
                  <ModalAlertController>
                    {/* <HelpWidget> */}
                    <WelcomeSplash>
                      <RootNav />
                    </WelcomeSplash>
                    {/* </HelpWidget> */}
                  </ModalAlertController>
                </DataInitializer>
              </ThemeProvider>
            </BrowserRouter>
          </Loader>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  )
}
export default App