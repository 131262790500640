import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { ButtonLink, Image } from 'components'
import defaultImage from 'img/default-photo.png'
import i18n from 'support/i18n'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import TabPanel from './components/tab_panel/View'

class RankingColumn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleNavigateToUserProfile = this.handleNavigateToUserProfile.bind(this)
  }

  a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })

  handleChange(event, newValue) {
    this.setState({ value: newValue })
  }

  handleNavigateToUserProfile(user) {
    const { navigateToUserProfile } = this.props
    navigateToUserProfile(user)
  }

  render() {
    const { classes, footerInvestors, footerEntrepreneurs, navigateToRanking, customIdeaTitle } = this.props
    const { value } = this.state
    const customTextLabel = i18n.t('HOME.FOOTER.IDEAS', { title: customIdeaTitle || "Ideas" })

    return (
      <Fragment>
        <div className={classes.title}>
          <span className="icon-ranking" />
          <h3>
            <ButtonLink onLinkClick={navigateToRanking}>
              {i18n.t('HOME.FOOTER.TOP_RANKING')}
            </ButtonLink>
          </h3>
        </div>
        <div className={classes.tabs}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
            aria-label="simple tabs example"
            variant="fullWidth"
          >
            <Tab label={i18n.t('HOME.FOOTER.INVESTORS')} {...this.a11yProps(0)} />
            <Tab label={customTextLabel} {...this.a11yProps(1)} />
          </Tabs>
          <TabPanel classes={classes} tabValue={value} index={0}>
            <ul className={classes.dataList}>
              {footerInvestors.length > 0 &&
                footerInvestors.map(user => {
                  return (
                    <li key={user.email} className={classes.dataItem}>
                      <div className={classes.userAvatar}>
                        <Image alt={user.fullname} defaultSrc={defaultImage} src={user.photo} />
                      </div>
                      <div className={classes.data}>
                        <div className={classes.userName}>
                          <ButtonLink
                            classes={{ linkButton: classes.linkButton }}
                            onLinkClick={() => this.handleNavigateToUserProfile(user.email)}
                          >
                            {user.fullname}
                          </ButtonLink>
                          <p>
                            {i18n
                              .t('HOME.FOOTER.IN_INVESTMENTS')
                              .replace(':number', user.investments)}
                          </p>
                        </div>
                        <div className={classes.investedAmount}>
                          <NxtVirtualCurrency>{user.amountInvested}</NxtVirtualCurrency>
                        </div>
                      </div>
                    </li>
                  )
                })}
            </ul>
          </TabPanel>
          <TabPanel classes={classes} tabValue={value} index={1}>
            <ul className={classes.dataList}>
              {footerEntrepreneurs.length > 0 &&
                footerEntrepreneurs.map(user => {
                  return (
                    <li key={user.email} className={classes.dataItem}>
                      <div className={classes.userAvatar}>
                        <img src={user.photo} alt={user.fullname} />
                      </div>
                      <div className={classes.data}>
                        <div className={classes.userName}>
                          <p>{user.fullname}</p>
                          <p>
                            <span className="icon-nextcoin4" />
                            {`${user.ideas} ${i18n.t('HOME.FOOTER.IDEAS_PROPOSED')}`}
                            {`, ${i18n.t('HOME.FOOTER.IDEAS_DELETED')} ${user.deletedIdeas}`}
                          </p>
                        </div>
                      </div>
                    </li>
                  )
                })}
            </ul>
          </TabPanel>
        </div>
        <span className={classes.seeAll}>
          <b className="icon-ranking" />
          <ButtonLink classes={{ linkButton: classes.linkButton }} onLinkClick={navigateToRanking}>
            {i18n.t('HOME.FOOTER.SEE_ALL')}
          </ButtonLink>
        </span>
      </Fragment>
    )
  }
}

RankingColumn.propTypes = {
  classes: PropTypes.shape({
    data: PropTypes.string.isRequired,
    dataItem: PropTypes.string.isRequired,
    dataList: PropTypes.string.isRequired,
    investedAmount: PropTypes.string.isRequired,
    seeAll: PropTypes.string.isRequired,
    tabs: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    userAvatar: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }).isRequired,
  footerInvestors: PropTypes.arrayOf(
    PropTypes.shape({
      amountInvested: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      fullname: PropTypes.string.isRequired,
      ideas: PropTypes.number.isRequired,
      investments: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
    }),
  ).isRequired,
  footerEntrepreneurs: PropTypes.arrayOf(
    PropTypes.shape({
      amountInvested: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      fullname: PropTypes.string.isRequired,
      ideas: PropTypes.number.isRequired,
      investments: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
    }),
  ).isRequired,
  navigateToRanking: PropTypes.func.isRequired,
  navigateToUserProfile: PropTypes.func.isRequired,
}

export default RankingColumn
