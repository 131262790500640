import React, { Component, Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { ButtonLink } from 'components'
import NavigationService from 'support/navigation/NavigationService'
import * as pages from 'common_constants/Pages'
import styles from './View.Style'
import People from './components/people/View'
import Gaming from './components/gaming/View'
import ChallengeHeader from './components/challenge_header/View'
import Description from './components/description/View'
import ExtraRewards from './components/extra_rewards/View'
import PreviewLaunch from './components/preview_launch/View'
import StepNavigator from './components/step_navigator/View'
import {
  STEPS_MAPPER,
  descriptionStepValidator,
  peopleStepValidator,
  extraRewardsStepValidator,
  previewStepValidator,
  ROI_SETTINGS,
} from './View.Utils'

class ChallengeForm extends Component {
  constructor(props) {
    super(props)

    const { match } = props

    this.state = {
      errorObject: {},
      externalVideoError: false,
    }

    this.isNewChallenge = NavigationService.getRoutes()[pages.NEW_CHALLENGE].path === match.path
    this.isEditChallenge = NavigationService.getRoutes()[pages.EDIT_CHALLENGE].path === match.path
    this.isProposeChallenge =
      NavigationService.getRoutes()[pages.PROPOSE_CHALLENGE].path === props.match.path

    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.renderNextButtonLabel = this.renderNextButtonLabel.bind(this)
    this.getBannerData = this.getBannerData.bind(this)
    this.handleOrganisationSelect = this.handleOrganisationSelect.bind(this)
  }

  componentDidMount() {
    const { initializeChallenge, getChallenge } = this.props

    if (this.isEditChallenge) {
      getChallenge()
    } else {
      initializeChallenge(this.isProposeChallenge)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      challengeDescription,
      isOrganizationsActive,
      getOrganizations,
      activeStep,
      imageUploadError,
      groupId,
      getChallengeSelectedGroups,
      isActive,
      isProposed,
      completeChallengeData,
      startConfirmationService,
      getChallengeVideoType,
    } = this.props

    if (challengeDescription.fileVideoSrc && !challengeDescription.fileVideoType) {
      getChallengeVideoType(challengeDescription.fileVideoSrc)
    }

    if (prevProps.isOrganizationsActive !== isOrganizationsActive && isOrganizationsActive) {
      getOrganizations()
    }

    if (prevProps.activeStep !== activeStep) {
      window.scrollTo(0, 0)
    }

    if (prevProps.imageUploadError !== imageUploadError) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => ({ errorObject: { ...prevState.errorObject, imageUploadError } }))
    }

    if (
      (prevProps.groupId !== groupId || (prevProps.isActive !== isActive && isActive)) &&
      groupId &&
      this.isEditChallenge
    ) {
      getChallengeSelectedGroups(groupId)
    }

    if (prevProps.isProposed !== isProposed && isProposed && this.isEditChallenge) {
      completeChallengeData()
    }

    if (
      prevProps.challengeDescription !== challengeDescription &&
      prevProps.challengeDescription.fileVideoId !== challengeDescription.fileVideoId &&
      challengeDescription.fileVideoId
    ) {
      startConfirmationService(challengeDescription.fileVideoId)
    }
  }

  componentWillUnmount() {
    const { onComponentWillUnmount } = this.props
    onComponentWillUnmount()
  }

  getBannerData() {
    const { classes, onResetChallenge } = this.props
    if (this.isEditChallenge) {
      return {
        bannerContent: (
          /* eslint-disable-next-line react/no-danger */
          <span dangerouslySetInnerHTML={{ __html: i18n.t('CHALLENGES.INFO_SAVE') }} />
        ),
        bannerColor: '#fdf4e2',
      }
    }

    if (this.isNewChallenge) {
      return {
        bannerContent: (
          <Fragment>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `${i18n.t('CHALLENGES.INFO_SAVE')} ${i18n.t(
                  'CHALLENGES.INFO_FINISH_LATER',
                )}`,
              }}
            />
            <div className={classes.bannerSecondLine}>
              <span>{i18n.t('CHALLENGES.INFO_RESTART_CHALLENGE')} </span>
              <ButtonLink onLinkClick={onResetChallenge}>
                {i18n.t('CHALLENGES.RESTART_CHALLENGE')}
              </ButtonLink>
            </div>
          </Fragment>
        ),
        bannerColor: '#d9edf7',
      }
    }
    return {
      bannerColor: '#d9edf7',
      bannerContent: (
        /* eslint-disable-next-line react/no-danger */
        <span dangerouslySetInnerHTML={{ __html: i18n.t('CHALLENGES.INFO_FIELDS') }} />
      ),
    }
  }

  getHeaderTitle() {
    if (this.isProposeChallenge) {
      return i18n.t('CHALLENGES.PROPOSE')
    }
    if (this.isEditChallenge) {
      return i18n.t('CHALLENGES.EDIT')
    }
    return i18n.t('CHALLENGES.CREATE_CHALLENGE')
  }

  handleNext() {
    const {
      activeStep,
      challengeDescription,
      selectedSponsor,
      saveDescriptionInfo,
      savePeopleInfo,
      saveRoiInfo,
      reward,
      saveRewardInfo,
      launchChallenge,
      proposeChallenge,
      previewChallenge,
      isActive,
      editDates,
      errors,
    } = this.props

    const { externalVideoError } = this.state
    let errorObject = {}
    let serviceToCall = () => { }

    switch (activeStep) {
      case STEPS_MAPPER.description:
        errorObject = descriptionStepValidator({
          ...challengeDescription,
          ...errors,
          externalVideoError,
        })
        serviceToCall = this.isProposeChallenge ? proposeChallenge : saveDescriptionInfo
        break
      case STEPS_MAPPER.people:
        errorObject = peopleStepValidator(selectedSponsor)
        serviceToCall = savePeopleInfo
        break
      case STEPS_MAPPER.gaming:
        errorObject = {}
        serviceToCall = saveRoiInfo
        break
      case STEPS_MAPPER.extraRewards:
        errorObject = extraRewardsStepValidator({ ...reward, ...errors })
        serviceToCall = saveRewardInfo
        break
      case STEPS_MAPPER.previewAndLaunch:
        errorObject = previewStepValidator(previewChallenge)
        if (isActive) {
          serviceToCall = editDates
        } else {
          serviceToCall = () => launchChallenge(this.isNewChallenge)
        }
        break
      default:
        break
    }

    if (Object.keys(errorObject).length === 0) {
      serviceToCall()
    }

    this.setState({
      errorObject,
    })
  }

  handlePrevious() {
    const { activeStep, decreaseChallengeStep } = this.props

    decreaseChallengeStep(activeStep)
  }

  handleOrganisationSelect(value) {
    const { previewChallenge, onOrganizationSelect } = this.props
    const organisation = previewChallenge.organization
    let innerOrganisation = value

    if (organisation === value) {
      innerOrganisation = ''
    }

    onOrganizationSelect(innerOrganisation)
  }

  renderNextButtonLabel() {
    const { activeStep, isActive } = this.props

    if (this.isProposeChallenge) {
      return i18n.t('CHALLENGES.PROPOSE_NOW')
    }

    if (isActive && activeStep === 4) {
      return i18n.t('CHALLENGES.SAVE_CHALLENGE')
    }

    return activeStep === 4 ? i18n.t('CHALLENGES.LAUNCH') : i18n.t('CHALLENGES.SAVE_AND_CONTINUE')
  }

  render() {
    const {
      classes,
      getSponsorSuggestions,
      sponsorSuggestions,
      sponsorStarted,
      onSponsorSelected,
      selectedSponsor,
      onRemoveSponsor,
      getProposerSuggestions,
      proposerSuggestions,
      proposerStarted,
      onProposerSelected,
      selectedProposer,
      onRemoveProposer,
      onSettingChange,
      selectedGamingSetting,
      onSliderChangeCommitted,
      approveMult,
      implementMult,
      supportMult,
      currentGoal,
      activeStep,
      onResetChallenge,
      onTitleChange,
      onWhyImportantChange,
      onDescriptionChange,
      challengeDescription,
      previewChallenge,
      onSelectPreviewFromDate,
      onSelectPreviewToDate,
      organizationList,
      isOrganizationsActive,
      onSelectImage,
      onSelectMainImage,
      deleteImage,
      onUploadExternalVideo,
      onUploadVideoFile,
      onRemoveExternalVideo,
      onRemoveVideo,
      reward,
      onRewardDescriptionChange,
      onRewardRewardedChange,
      onRewardOtherChange,
      onSelectRewardImage,
      deleteRewardImage,
      onMoveToRight,
      onMoveToLeft,
      onRightGroupClick,
      onClickToShowRewardImage,
      isNextStepInProgress,
      isLoading,
      defaultValues,
      setActiveTab,
      activeTab,
      isActive,
      challengeId,
      organizationsName,
      customIdeaTitles
    } = this.props

    const { errorObject } = this.state

    return (
      <div className={classes.root}>
        {!isLoading && (
          <ChallengeHeader
            showStepper={!this.isProposeChallenge}
            title={this.getHeaderTitle()}
            activeStep={activeStep}
            onResetChallenge={onResetChallenge}
            {...this.getBannerData()}
          />
        )}
        {!isLoading && activeStep === 0 && (
          <Description
            onExternalVideoError={error => this.setState({ externalVideoError: error })}
            challengeDescription={challengeDescription}
            onTitleChange={onTitleChange}
            onWhyImportantChange={onWhyImportantChange}
            onDescriptionChange={onDescriptionChange}
            onSelectImage={onSelectImage}
            onSelectMainImage={onSelectMainImage}
            deleteImage={deleteImage}
            onUploadExternalVideo={onUploadExternalVideo}
            onUploadVideoFile={() => onUploadVideoFile(challengeId)}
            onRemoveExternalVideo={onRemoveExternalVideo}
            onRemoveVideo={() => onRemoveVideo(challengeDescription.fileVideoId)}
            errorObject={errorObject}
            disableUploadVideoFile={this.isProposeChallenge}
          />
        )}
        {activeStep === 1 && (
          <People
            getSponsorSuggestions={getSponsorSuggestions}
            sponsorSuggestions={sponsorSuggestions}
            sponsorStarted={sponsorStarted}
            onSponsorSelected={onSponsorSelected}
            selectedSponsor={selectedSponsor}
            onRemoveSponsor={onRemoveSponsor}
            getProposerSuggestions={getProposerSuggestions}
            proposerSuggestions={proposerSuggestions}
            proposerStarted={proposerStarted}
            onProposerSelected={onProposerSelected}
            selectedProposer={selectedProposer}
            onRemoveProposer={onRemoveProposer}
            hasSponsorError={errorObject.sponsor}
          />
        )}
        {activeStep === 2 && (
          <Gaming
            onChangeTab={setActiveTab}
            onSettingChange={onSettingChange}
            selectedGamingSetting={selectedGamingSetting}
            onSliderChangeCommitted={onSliderChangeCommitted}
            defaultValues={defaultValues}
            approveMult={approveMult}
            implementMult={implementMult}
            supportMult={supportMult}
            currentGoal={currentGoal}
            activeTab={activeTab}
            customIdeaTitles={customIdeaTitles}
          />
        )}
        {activeStep === 3 && (
          <ExtraRewards
            reward={reward}
            onDescriptionChange={onRewardDescriptionChange}
            onRewardedChange={onRewardRewardedChange}
            onOtherChange={onRewardOtherChange}
            onSelectImage={onSelectRewardImage}
            deleteImage={deleteRewardImage}
            errorObject={errorObject}
            customIdeaTitles={customIdeaTitles}
          />
        )}
        {activeStep === 4 && (
          <PreviewLaunch
            previewChallenge={previewChallenge}
            onSelectPreviewFromDate={onSelectPreviewFromDate}
            onSelectPreviewToDate={onSelectPreviewToDate}
            onOrganizationSelect={this.handleOrganisationSelect}
            organizationList={organizationList}
            isOrganizationsActive={isOrganizationsActive}
            organizationsName={organizationsName}
            onMoveToRight={onMoveToRight}
            onMoveToLeft={onMoveToLeft}
            onRightGroupClick={onRightGroupClick}
            challengeDescription={challengeDescription}
            defaultValues={defaultValues}
            selectedSponsor={selectedSponsor}
            selectedProposer={selectedProposer}
            approveMult={approveMult}
            implementMult={implementMult}
            supportMult={supportMult}
            reward={reward}
            onClickToShowRewardImage={onClickToShowRewardImage}
            errorObject={errorObject}
            isFromDateDisable={isActive}
            showOrganisations={!isActive}
            isGroupDisabled={isActive}
            customIdeaTitles={customIdeaTitles}
          />
        )}
        {!isLoading && (
          <StepNavigator
            onBackClick={this.handlePrevious}
            onNextClick={this.handleNext}
            canGoBack={activeStep !== 0}
            nextLabel={this.renderNextButtonLabel()}
            isLoading={isNextStepInProgress}
          />
        )}
      </div>
    )
  }
}

ChallengeForm.propTypes = {
  initializeChallenge: PropTypes.func.isRequired,
  getChallenge: PropTypes.func.isRequired,
  completeChallengeData: PropTypes.func.isRequired,
  onComponentWillUnmount: PropTypes.func.isRequired,
  getChallengeSelectedGroups: PropTypes.func.isRequired,
  getSponsorSuggestions: PropTypes.func.isRequired,
  sponsorSuggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sponsorStarted: PropTypes.bool.isRequired,
  onSponsorSelected: PropTypes.func.isRequired,
  selectedSponsor: PropTypes.shape({}).isRequired,
  onRemoveSponsor: PropTypes.func.isRequired,
  getProposerSuggestions: PropTypes.func.isRequired,
  proposerSuggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  proposerStarted: PropTypes.bool.isRequired,
  onProposerSelected: PropTypes.func.isRequired,
  selectedProposer: PropTypes.shape({}).isRequired,
  onRemoveProposer: PropTypes.func.isRequired,
  onSettingChange: PropTypes.func.isRequired,
  selectedGamingSetting: PropTypes.oneOf([
    ROI_SETTINGS.DEFAULT,
    ROI_SETTINGS.FEWER_IDEAS,
    ROI_SETTINGS.MORE_IDEAS,
  ]).isRequired,
  onSliderChangeCommitted: PropTypes.func.isRequired,
  approveMult: PropTypes.number.isRequired,
  implementMult: PropTypes.number.isRequired,
  supportMult: PropTypes.number.isRequired,
  currentGoal: PropTypes.number.isRequired,
  onResetChallenge: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onWhyImportantChange: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  challengeDescription: PropTypes.shape({
    title: PropTypes.string,
    whyImportant: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        src: PropTypes.string,
      }),
    ),
    mainImageValue: PropTypes.string,
    externalVideoSrc: PropTypes.string,
  }).isRequired,
  onUploadExternalVideo: PropTypes.func.isRequired,
  onUploadVideoFile: PropTypes.func.isRequired,
  onRemoveExternalVideo: PropTypes.func.isRequired,
  onRemoveVideo: PropTypes.func.isRequired,
  reward: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.shape({}),
    rewarded: PropTypes.string,
    other: PropTypes.string,
  }).isRequired,
  onRewardDescriptionChange: PropTypes.func.isRequired,
  onRewardRewardedChange: PropTypes.func.isRequired,
  onRewardOtherChange: PropTypes.func.isRequired,
  onSelectRewardImage: PropTypes.func.isRequired,
  deleteRewardImage: PropTypes.func.isRequired,
  previewChallenge: PropTypes.shape({
    fromDate: PropTypes.instanceOf(Date),
    toDate: PropTypes.instanceOf(Date),
    leftGroups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.string,
        id: PropTypes.string,
      }),
    ),
    rightGroups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.string,
        id: PropTypes.string,
      }),
    ),
    organization: PropTypes.string,
  }).isRequired,
  onSelectPreviewFromDate: PropTypes.func.isRequired,
  onSelectPreviewToDate: PropTypes.func.isRequired,
  onOrganizationSelect: PropTypes.func.isRequired,
  organizationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  getOrganizations: PropTypes.func.isRequired,
  isOrganizationsActive: PropTypes.bool.isRequired,
  onMoveToRight: PropTypes.func.isRequired,
  onMoveToLeft: PropTypes.func.isRequired,
  onRightGroupClick: PropTypes.func.isRequired,
  onClickToShowRewardImage: PropTypes.func.isRequired,
  decreaseChallengeStep: PropTypes.func.isRequired,
  saveDescriptionInfo: PropTypes.func.isRequired,
  saveRoiInfo: PropTypes.func.isRequired,
  saveRewardInfo: PropTypes.func.isRequired,
  isNextStepInProgress: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isProposed: PropTypes.bool.isRequired,
  savePeopleInfo: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}).isRequired,
  setActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  launchChallenge: PropTypes.func.isRequired,
  proposeChallenge: PropTypes.func.isRequired,
  editDates: PropTypes.func.isRequired,
  imageUploadError: PropTypes.string.isRequired,
  challengeId: PropTypes.string.isRequired,
  startConfirmationService: PropTypes.func.isRequired,
  getChallengeVideoType: PropTypes.func.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(ChallengeForm)
