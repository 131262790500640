import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    container: {
      width: '230px',
      padding: 0,
      margin: 0,
      left: '-6.5rem',
      top: '66px',
      position: 'absolute',
      backgroundColor: '#FFF',
      zIndex: 1201,
      borderRadius: '4px',
      boxShadow: '0 1px 1px rgba(0, 0, 0, 0.35)',
      '&:before': {
        top: '-10px',
        left: '105px',
        position: 'absolute',
        display: 'inline-block',
        borderRight: '10px solid transparent',
        borderBottom: '10px solid #FFF',
        borderLeft: '10px solid transparent',
        borderBottomColor: 'rgba(0, 0, 0, 0.2)',
        content: '""',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '-10px',
        right: '0px',
        left: '0px',
        margin: 'auto',
        height: 0,
        width: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '10px solid #fff',
      },
      '& hr': {
        margin: 0,
      },
      '& li': {
        fontSize: '16px',
        cursor: 'pointer',
        listStyleType: 'none',
        clear: 'both',
        color: 'black',
      },
      '& li:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
      '& li > a': {
        textDecoration: 'none',
        color: 'inherit',
        padding: '7% 8%',
        width: '100%',
      },
      '& li > a > span': {
        color: 'rgba(0, 0, 0, 0.54)',
        // lineHeight: '20px',
        //  marginTop: '20px',
        //   marginBottom: '20px',
        marginLeft: '15px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        '&:hover': {
          color: '#0a0a0a',
          textDecoration: 'none',
          opacity: 0.8,
        },
      },
      '& li > a > span > [class^="icon-"]': {
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 'bold',
        marginRight: '1em',
      },
    },
  })

export default styles
