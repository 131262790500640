import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import ButtonLink from 'components/button_link/View'
import i18n from 'support/i18n'
import { withStyles } from '@material-ui/core'
import styles from './View.Styles'

const NxtReward = props => {
  const {
    classes,
    rewardLabel,
    rewardText,
    whoGetRewardLabel,
    whoGetRewardText,
    rewardImageSrc,
    onClickToShowRewardImage,
    customIdeaTitles
  } = props

  const replaceIdeaText = (value) => {
    return value?.includes("ideas")
      ? value?.replaceAll(/ideas|Ideas/g, customIdeaTitles.pluralTitle)
      : value?.replaceAll(/idea|Idea/g, customIdeaTitles.singularTitle)
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.iconLeft}>
          <span className="icon-ranking" />
        </div>
        <div className={classes.rewardContainer}>
          <div className={classes.rewardLabel}>{rewardLabel}</div>
          <div className={classes.rewardValue}>
            {rewardText}
            {rewardImageSrc.length > 0 && (
              <ButtonLink
                onLinkClick={() => onClickToShowRewardImage(rewardImageSrc)}
                classes={{ linkButton: classes.rewardCustomLink }}
              >
                <span className="icon-gallery" />
              </ButtonLink>
            )}
          </div>
          {whoGetRewardText && (
            <>
              <div className={classes.rewardLabel}>{whoGetRewardLabel}</div>
              <div className={classes.rewardValue}>
                {replaceIdeaText(whoGetRewardText)}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

NxtReward.defaultProps = {
  classes: {
    root: '',
    iconLeft: '',
    rewardContainer: '',
    rewardLabel: '',
    rewardValue: '',
  },
  rewardLabel: i18n.t('CHALLENGES.DETAIL.REWARD'),
  whoGetRewardLabel: i18n.t('CHALLENGES.WHO_REWARDED_INFO'),
  rewardText: '',
  whoGetRewardText: '',
  rewardImageSrc: '',
  onClickToShowRewardImage: () => { },
  customIdeaTitles: {
    singularTitle: "",
    pluralTitle: ""
  }
}

NxtReward.propsTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    iconLeft: PropTypes.string,
    rewardContainer: PropTypes.string,
    rewardLabel: PropTypes.string,
    rewardValue: PropTypes.string,
  }),
  rewardLabel: PropTypes.string,
  rewardText: PropTypes.string,
  whoGetRewardLabel: PropTypes.string,
  whoGetRewardText: PropTypes.string,
  rewardImageSrc: PropTypes.string,
  onClickToShowRewardImage: PropTypes.func,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  })
}
export default withStyles(styles)(NxtReward)
