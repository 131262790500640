import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import * as kpiTypes from 'common_constants/Kpis'
import { withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import KpisFilter from './components/kpis_filter/View'
import KpisList from './components/kpis_list/View'
import styles from './View.Style'
import KpiForm from './components/kpi_form/View'

const KpiSection = props => {
  const {
    classes,
    total,
    list,
    onUpdateKpiAction,
    onUpdateKpiSuccess,
    onDeleteKpi,
    onSubmit,
    ideaId,
    isFormInUse,
  } = props
  const [kpis, setKpis] = useState(list)
  const [activeFilter, setActiveFilter] = useState('')
  const initialFormValue = {
    id: '',
    type: kpiTypes.QUANTITATIVE,
    range: '',
    unit: '',
    expected: '',
    current: '',
    name: '',
    touched: false,
    updated: false,
    error: false,
  }
  const [formValue, setFormValue] = useState(initialFormValue)

  const resetFormValue = () => {
    setFormValue(initialFormValue)
  }

  const editForm = ({ id, type, range, unit, expected, current, name, updated }) => {
    resetFormValue()
    setFormValue({
      ...formValue,
      id,
      type,
      range,
      unit,
      expected,
      current,
      name,
      touched: true,
      updated,
    })
  }

  const checkFormIsFilled = () => {
    const { type, range, unit, expected, name, touched } = formValue
    if (
      touched &&
      expected.length > 0 &&
      name.length > 0 &&
      ((type === kpiTypes.QUANTITATIVE && unit.length > 0) ||
        (type === kpiTypes.QUALITATIVE && range.length > 0))
    ) {
      setFormValue({ ...formValue, error: false })
      return true
    }
    setFormValue({ ...formValue, error: true })
    return false
  }

  const handleOnSubmitForm = () => {
    if (checkFormIsFilled()) {
      if (formValue.updated) {
        onUpdateKpiAction({ ...formValue, ideaId })
      } else {
        onSubmit({ ...formValue, ideaId })
      }
      resetFormValue()
    }
  }

  const resetFilter = () => {
    setKpis(list)
    setActiveFilter('')
  }

  const handleDelete = ({ id } = {}) => {
    onDeleteKpi({ id, ideaId })
    resetFormValue()
  }

  const handleApplyFilter = newFilter => {
    setActiveFilter(newFilter)
    setKpis(list.filter(item => item.type === newFilter && item))
  }

  useEffect(() => {
    if (activeFilter.length > 0) {
      setKpis(list.filter(item => item.type === activeFilter && item))
    } else {
      resetFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <h2>
          <span className="icon-approved" />
          {i18n.t('EXPLORE_IDEA.MANAGE_KPIS')}
        </h2>
        {total > 0 && (
          <h3 className={classes.total}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: i18n.t('IDEA.YOU_HAVE_KPI').replace('##kpis##', total),
              }}
            />
          </h3>
        )}
      </div>
      <KpisFilter applyFilter={handleApplyFilter} resetFilter={resetFilter} />
      <KpisList
        ideaId={ideaId}
        list={kpis}
        onUpdateKpiSuccess={onUpdateKpiSuccess}
        onDeleteKpi={handleDelete}
        onItemEdit={editForm}
      />
      <KpiForm
        classes={classes}
        formValue={formValue}
        onSubmit={handleOnSubmitForm}
        onChangeValue={setFormValue}
        onCancel={resetFormValue}
        isFormInUse={isFormInUse}
      />
    </div>
  )
}

KpiSection.defaultProps = {
  total: 0,
  list: [],
  isFormInUse: false,
}

KpiSection.propTypes = {
  total: PropTypes.number,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdateKpiAction: PropTypes.func.isRequired,
  onUpdateKpiSuccess: PropTypes.func.isRequired,
  onDeleteKpi: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  ideaId: PropTypes.string.isRequired,
  isFormInUse: PropTypes.bool,
}

export default withStyles(styles)(KpiSection)
