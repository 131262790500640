const pages = require('./Pages')

function getObjectES5Way(domain) {
  const routes = {}
  routes[pages.ROOT_LOGGED_PAGE] = {
    path: `/${domain}`,
  }
  routes[pages.RESET_PASSWORD] = {
    path: `/reset-password/:token`,
  }
  routes[pages.NEW_IDEA] = {
    path: `/${domain}/idea/new`,
  }
  routes[pages.NEW_PRIVATE_IDEA] = {
    path: `/${domain}/idea/private/new`,
  }
  routes[pages.NEW_IDEA_CHALLENGE_SELECTED] = {
    path: `/${domain}/idea/new/:challengeId`,
  }
  routes[pages.EDIT_IDEA] = {
    path: `/${domain}/idea/edit/:id`,
  }
  routes[pages.DETAIL_IDEA] = {
    path: `/${domain}/idea/detail/:id`,
  }
  routes[pages.PROPOSE_CHALLENGE] = {
    path: `/${domain}/challenge/propose`,
  }
  routes[pages.NEW_CHALLENGE] = {
    path: `/${domain}/challenge/new`,
  }
  routes[pages.EDIT_CHALLENGE] = {
    path: `/${domain}/challenge/edit/:id`,
  }
  routes[pages.DETAIL_CHALLENGE] = {
    path: `/${domain}/challenge/detail/:id`,
  }
  routes[pages.CHALLENGES_PAGE] = {
    path: `/${domain}/challenges`,
  }
  routes[pages.CUSTOM_LOGIN_PAGE] = {
    path: `/:domain/login`,
  }
  routes[pages.DASHBOARD] = {
    path: `/${domain}/dashboard`,
  }
  routes[pages.EXPLORE_PAGE] = {
    path: `/${domain}/explore`,
  }
  routes[pages.HOW_IT_WORKS] = {
    path: `/${domain}/how-it-works`,
  }
  routes[pages.IDENTITY_CHOOSE_PAGE] = {
    path: `/identity-choose`,
  }
  routes[pages.INVESTMENTS_PAGE] = {
    path: `/${domain}/investments`,
  }
  routes[pages.LEGAL_ACCEPTANCE_PAGE] = {
    path: `/${domain}/legal-acceptance`,
  }
  routes[pages.LOGIN_PAGE] = {
    path: `/login`,
  }
  routes[pages.LOGOUT_PAGE] = {
    path: `/logout`,
  }
  routes[pages.MY_IDEAS_PAGE] = {
    path: `/${domain}/my-ideas`,
  }
  routes[pages.PENDING_VALIDATION_PAGE] = {
    path: `/${domain}/pending-validation`,
  }
  routes[pages.PRIVACY_COOKIES_PAGE] = {
    path: `/${domain}/privacy-cookies`,
  }
  routes[pages.PRIVATE_IDEAS_PAGE] = {
    path: `/${domain}/private-ideas`,
  }
  routes[pages.PROFILE_PAGE] = {
    path: `/${domain}/profile`,
  }
  routes[pages.PROFILE_PAGE_USER] = {
    path: `/${domain}/profile/:email`,
  }
  routes[pages.RANKING_PAGE] = {
    path: `/${domain}/ranking`,
  }
  routes[pages.USER_PREFERENCES_PAGE] = {
    path: `/${domain}/user-preferences`,
  }
  routes[pages.SIGN_UP] = {
    path: `/:domain/sign-up`,
  }
  routes[pages.MAINTENANCE] = {
    path: `/:domain/maintenance`,
  }
  routes[pages.VALIDATE] = {
    path: `/validate`,
  }
  routes[pages.AZURE_LOGIN] = {
    path: `/:domain/azure/:email/:code`,
  }
  routes[pages.ADFS_LOGIN] = {
    path: `/:domain/adfs/:email/:code`,
  }
  return routes
}

const ROUTES = getObjectES5Way

module.exports = ROUTES
