const styles = theme => ({
  root: {
    '& > span': {
      fontSize: '22px',
    },
    display: 'flex',
  },
  titleLogistic: {
    fontSize: '1.125rem',
    fontWeight: 500,
  },
  valuesContainer: {
    width: '100%',
    margin: theme.spacing(0, 2),
  },
  valuesLogistic: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2.5, 1.5),
    backgroundColor: 'rgb(247, 247, 247)',
    display: 'flex',
    '& div:nth-child(2)': {
      marginLeft: theme.spacing(2),
    },
    '& div:nth-child(3)': {
      marginLeft: theme.spacing(2),
    },
    '& div:only-child': {
      flex: 1,
    },
  },
  isOne: {
    width: '37%',
  },
  isOneQuantity: {
    width: '42%',
  },
  isTwo: {
    width: '66%',
  },
  isThree: {
    width: 'auto',
  },
  estimatedQuantity: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px dotted black',
    flex: 1,
    '& span:first-child': {
      color: 'rgb(102, 102, 102)',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '14px',
    },
    '& span:last-child': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: '28px',
    },
  },
  estimatedHours: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px dotted black',
    flex: 0.8,
    '& span:first-child': {
      color: 'rgb(102, 102, 102)',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '14px',
    },
    '& span:last-child': {
      fontWeight: 700,
      fontSize: '28px',
      color: '#666',
    },
  },
  estimatedTime: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.8,
    borderBottom: '1px dotted black',
    '& span:first-child': {
      color: 'rgb(102, 102, 102)',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '14px',
    },
    '& span:last-child': {
      fontWeight: 700,
      fontSize: '28px',
      color: '#666',
    },
  },
})

export default styles
