/* eslint-disable react/forbid-prop-types */
import React, { Component, Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import i18n from 'support/i18n'
import InvestmentsTable from 'components/investments_table/View'
import TotalInvestments from './components/total_investments/View'
import PortfolioInvestments from './components/portfolio_investments/View'
import styles from './View.Style'

class Investments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0,
    }

    this.handleTabChange = this.handleTabChange.bind(this)
  }

  componentDidMount() {
    const {
      fullInfoLoaded,
      getInvestments,
      userEmail,
      getPortfolio,
      getUserInfo
    } = this.props
    if (fullInfoLoaded) {
      getUserInfo()
      getInvestments(userEmail)
      getPortfolio()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fullInfoLoaded,
      getInvestments,
      userEmail,
      getPortfolio,
      getUserInfo
    } = this.props
    if (fullInfoLoaded !== prevProps.fullInfoLoaded && fullInfoLoaded) {
      getUserInfo()
      getInvestments(userEmail)
      getPortfolio()
    }
  }

  a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })

  handleTabChange(event, newTab) {
    this.setState({
      tab: newTab
    })
  }

  render() {
    const {
      classes,
      totalInvested,
      totalProfit,
      ideas,
      onTitleClick,
      portfolio,
      distribution,
      created,
      initialBalance,
      currencyConfiguration,
      iconName,
      hasIconLeftSide,
      customIdeaTitles
    } = this.props

    const { tab } = this.state

    return (
      <Fragment>
        <div className={classes.top}>
          <div className={classes.topContainer}>
            <h1>
              <i className="icon-evolution" /> {i18n.t('SCREEN.INVESTMENTS')}
            </h1>
          </div>
          <TotalInvestments totalProfit={totalProfit} totalInvested={totalInvested} />
        </div>
        <div className={classes.middle}>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleTabChange}
            aria-label="simple tabs example"
            variant="fullWidth"
          >
            <Tab label={i18n.t('INVESTMENTS.BALANCE')} {...this.a11yProps(0)} />
            <Tab label={i18n.t('INVESTMENTS.PORTFOLIO')} {...this.a11yProps(1)} />
          </Tabs>
          <div
            role="tabpanel"
            hidden={tab !== 0}
            id="simple-tabpanel-0"
            aria-labelledby="simple-tab-0"
          >
            {ideas.length ? (
              <InvestmentsTable ideas={ideas} onTitleClick={onTitleClick} customIdeaTitles={customIdeaTitles} />
            ) : (
              <div className={classes.noInvestments}>
                {i18n.t('INVESTMENTS.NO_INVESTMENTS_FOUND')}
              </div>
            )}
          </div>
          <div
            role="tabpanel"
            hidden={tab !== 1}
            id="simple-tabpanel-1"
            aria-labelledby="simple-tab-1"
          >
            {portfolio.length ? (
              <div>
                <PortfolioInvestments
                  portfolio={portfolio}
                  distribution={distribution}
                  created={created}
                  initialBalance={initialBalance}
                  currencyConfiguration={currencyConfiguration}
                  iconName={iconName}
                  hasIconLeftSide={hasIconLeftSide}
                />
              </div>
            ) : (
              <div className={classes.noInvestments}>
                {i18n.t('INVESTMENTS.NO_INVESTMENTS_FOUND')}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

Investments.defaultProps = {
  ideas: [],
  totalInvested: 0,
  totalProfit: 0,
  fullInfoLoaded: false,
  userEmail: '',
  portfolio: [],
  distribution: {
    count: {
      raising: 0,
      approved: 0,
      closed: 0,
      prototyped: 0
    },
    invest: {
      raising: 0,
      approved: 0,
      closed: 0,
      prototyped: 0
    },
    win: {
      raising: 0,
      approved: 0,
      closed: 0,
      prototyped: 0
    }
  },
  created: '',
  initialBalance: null,
}

Investments.propTypes = {
  ideas: PropTypes.arrayOf(PropTypes.shape),
  totalInvested: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalProfit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullInfoLoaded: PropTypes.bool,
  userEmail: PropTypes.string,
  getInvestments: PropTypes.func.isRequired,
  onTitleClick: PropTypes.func.isRequired,
  getPortfolio: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  portfolio: PropTypes.array,
  distribution: PropTypes.object,
  created: PropTypes.string,
  initialBalance: PropTypes.number,
  currencyConfiguration: PropTypes.object.isRequired,
  iconName: PropTypes.string.isRequired,
  hasIconLeftSide: PropTypes.bool.isRequired,
  customIdeaTitles: PropTypes.shape({
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(Investments)
