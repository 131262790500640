const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  helpText: {
    textAlign: 'center',
    fontWeight: 500,
  },
})

export default styles
