import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import NavigationService from 'support/navigation/NavigationService'
import { Image } from 'components'
import SideLogin from 'components/side_login/View'
import ResetPasswordForm from './components/reset_password_form/View'
import styles from './View.Style'

const ResetPassword = props => {
  const {
    classes,
    onSubmit,
    useCreateAccount,
    messageType,
    messageText,
    isChangingPassword,
    setAuthMessageBox,
    resetAuthMessageBox,
    onLoad,
    useCustomColor,
    customColor,
    useCustomTextColor,
    customTextColor,
    useCustomImg,
    customImg,
    useCustomText,
    customText,
  } = props
  const [currentDomain, setCurrentDomain] = useState(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetAuthMessageBox, [])

  useEffect(() => {
    const { search } = NavigationService.getHistory().location
    if (search.includes('ns')) {
      const domain = search
        .split('ns')[1]
        .split('=')[1]
        .split('&')[0]
      onLoad(domain)
      setCurrentDomain(domain)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.base}>
      {!currentDomain && <SideLogin />}
      {currentDomain && (
        useCustomImg || useCustomText ? (
          <div className={classes.customize}>
            {useCustomImg && customImg && (
              <Image defaultSrc="" src={customImg} alt="custom company logo" />
            )}
            {useCustomText && customText && <h1 className={classes.title}>{customText}</h1>}
          </div>
        ) : (
          <SideLogin />
        )
      )}
      <ResetPasswordForm
        setAuthMessageBox={setAuthMessageBox}
        isChangingPassword={isChangingPassword}
        messageType={messageType}
        messageText={messageText}
        onSubmit={(password) => onSubmit(password, currentDomain)}
        useCreateAccount={useCreateAccount}
        useCustomColor={useCustomColor}
        customColor={customColor}
        useCustomTextColor={useCustomTextColor}
        customTextColor={customTextColor}
      />
    </div>
  )
}

ResetPassword.defaultProps = {
  useCreateAccount: false,
}

ResetPassword.propTypes = {
  useCreateAccount: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setAuthMessageBox: PropTypes.func.isRequired,
  resetAuthMessageBox: PropTypes.func.isRequired,
  isChangingPassword: PropTypes.bool.isRequired,
  messageType: PropTypes.number.isRequired,
  messageText: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
}

export default withStyles(styles)(ResetPassword)
