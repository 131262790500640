import { ServiceCall } from 'support/network/ServiceCallAction'
import * as types from 'actions/sync/video/Constants'
import {
  transformConfirmationVideoResponse,
  transformStorageVideoUploadUrlResponse,
  transformStorageVideoResponse,
  transformVideoResponse,
} from './ResponseUtils'
import { setVideoContent, setIdeaStorageUploadUrl } from './RequestUtils'

const GET_CONFIRMATION_VIDEO_URL = '/s/videos/##videoId##'
const DELETE_VIDEO_URL = '/s/videos/##videoId##'
const POST_STORAGE_VIDEO_UPLOAD_URL = 'api/v1/idea/##id##/document/upload_url'
const POST_UPLOAD_STORAGE_VIDEO_URL = '/api/v1/idea/null/document/video'

export function checkVideoState(isIdea, videoId, onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = !isIdea
    ? types.GET_CONFIRMATION_CHALLENGE_VIDEO_STARTED
    : types.GET_CONFIRMATION_IDEA_VIDEO_STARTED
  apiCall.successReqType = !isIdea
    ? types.GET_CONFIRMATION_CHALLENGE_VIDEO_SUCCESS
    : types.GET_CONFIRMATION_IDEA_VIDEO_SUCCESS
  apiCall.failureReqType = !isIdea
    ? types.GET_CONFIRMATION_CHALLENGE_VIDEO_FAILURE
    : types.GET_CONFIRMATION_IDEA_VIDEO_FAILURE
  apiCall.setEndpointFromState = () => GET_CONFIRMATION_VIDEO_URL.replace('##videoId##', videoId)
  apiCall.transformResponseDataWithState = transformConfirmationVideoResponse
  apiCall.method = 'GET'
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}

function deleteVideo(isIdea, videoId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = isIdea
    ? types.DELETE_IDEA_VIDEO_STARTED
    : types.DELETE_CHALLENGE_VIDEO_STARTED
  apiCall.successReqType = isIdea
    ? types.DELETE_IDEA_VIDEO_SUCCESS
    : types.DELETE_CHALLENGE_VIDEO_SUCCESS
  apiCall.failureReqType = isIdea
    ? types.DELETE_IDEA_VIDEO_FAILURE
    : types.DELETE_CHALLENGE_VIDEO_FAILURE
  apiCall.setEndpointFromState = () => DELETE_VIDEO_URL.replace('##videoId##', videoId)
  apiCall.method = 'DELETE'
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

function postStorageVideoUploadUrl(isIdea, id, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = isIdea
    ? types.POST_STORAGE_VIDEO_UPLOAD_URL_STARTED
    : types.POST_CHALLENGE_STORAGE_VIDEO_UPLOAD_URL_STARTED
  apiCall.successReqType = isIdea
    ? types.POST_STORAGE_VIDEO_UPLOAD_URL_SUCCESS
    : types.POST_CHALLENGE_STORAGE_VIDEO_UPLOAD_URL_SUCCESS
  apiCall.failureReqType = isIdea
    ? types.POST_STORAGE_VIDEO_UPLOAD_URL_FAILURE
    : types.POST_CHALLENGE_STORAGE_VIDEO_UPLOAD_URL_FAILURE
  apiCall.setEndpointFromState = () => POST_STORAGE_VIDEO_UPLOAD_URL.replace('##id##', id)
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = transformStorageVideoUploadUrlResponse
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

function postStorageVideo(isIdea, video, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = isIdea
    ? types.POST_UPLOAD_IDEA_STORAGE_VIDEO_STARTED
    : types.POST_UPLOAD_CHALLENGE_STORAGE_VIDEO_STARTED
  apiCall.successReqType = isIdea
    ? types.POST_UPLOAD_IDEA_STORAGE_VIDEO_SUCCESS
    : types.POST_UPLOAD_CHALLENGE_STORAGE_VIDEO_SUCCESS
  apiCall.failureReqType = isIdea
    ? types.POST_UPLOAD_IDEA_STORAGE_VIDEO_FAILURE
    : types.POST_UPLOAD_CHALLENGE_STORAGE_VIDEO_FAILURE
  apiCall.setEndpointFromState = state => setIdeaStorageUploadUrl(isIdea, state)
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = () => ({
    'content-type': 'multipart/form-data',
  })
  apiCall.setBodyFromState = () => setVideoContent(video)
  apiCall.transformResponseDataWithState = (jsonResponse, state) =>
    transformStorageVideoResponse(jsonResponse, state, POST_UPLOAD_STORAGE_VIDEO_URL)
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

function getVideoTypeFromUrl(isIdea, inDetail, url, onFailure) {
  let startedReqType = types.GET_IDEA_VIDEO_TYPE_STARTED
  let successReqType = types.GET_IDEA_VIDEO_TYPE_SUCCESS
  let failureReqType = types.GET_IDEA_VIDEO_TYPE_FAILURE
  if (!isIdea) {
    if (inDetail) {
      startedReqType = types.GET_CHALLENGE_DETAIL_VIDEO_TYPE_STARTED
      successReqType = types.GET_CHALLENGE_DETAIL_VIDEO_TYPE_SUCCESS
      failureReqType = types.GET_CHALLENGE_DETAIL_VIDEO_TYPE_FAILURE
    } else {
      startedReqType = types.GET_CHALLENGE_VIDEO_TYPE_STARTED
      successReqType = types.GET_CHALLENGE_VIDEO_TYPE_SUCCESS
      failureReqType = types.GET_CHALLENGE_VIDEO_TYPE_FAILURE
    }
  }
  const apiCall = new ServiceCall()
  apiCall.startedReqType = startedReqType
  apiCall.successReqType = successReqType
  apiCall.failureReqType = failureReqType
  apiCall.setEndpointFromState = () => url
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = (jsonResponse, state, headers) =>
    transformVideoResponse(headers)
  apiCall.onFailure = onFailure
  apiCall.auth = false
  return apiCall.getAction()
}

export function deleteIdeaVideo(videoId, onSuccess = () => {}, onFailure = () => {}) {
  return deleteVideo(true, videoId, onSuccess, onFailure)
}

export function deleteChallengeVideo(videoId, onSuccess = () => {}, onFailure = () => {}) {
  return deleteVideo(false, videoId, onSuccess, onFailure)
}

export function postIdeaVideo(video, onFailure) {
  return postStorageVideo(true, video, onFailure)
}

export function postChallengeVideo(video, onFailure) {
  return postStorageVideo(false, video, onFailure)
}

export function generateVideoUploadUrl(id, onFailure) {
  return postStorageVideoUploadUrl(true, id, onFailure)
}

export function generateChallengeVideoUploadUrl(id, onFailure) {
  return postStorageVideoUploadUrl(false, id, onFailure)
}

export function getChallengeVideoType(url, onFailure) {
  return getVideoTypeFromUrl(false, false, url, onFailure)
}

export function getChallengeDetailVideoType(url, onFailure) {
  return getVideoTypeFromUrl(false, true, url, onFailure)
}

export function getIdeaVideoType(url, onFailure) {
  return getVideoTypeFromUrl(true, false, url, onFailure)
}
