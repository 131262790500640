import React, { Fragment, Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import NxtSlider from 'components/nxt_slider/View'
import MainTooltip from 'components/main_tooltip/View'
import styles from './View.Style'

class GoalsSlider extends Component {
  constructor(props) {
    super(props)

    this.handleSliderValue = this.handleSliderValue.bind(this)
  }

  handleSliderValue(newValue, id) {
    const { onGoalChange, values } = this.props
    onGoalChange({ ...values, [id]: newValue })
  }

  render() {
    const { classes, helpText, goals, values } = this.props

    return (
      <div className={classes.root}>
        <p>{helpText}</p>
        <div className={classes.sliderContainer}>
          {goals.map(goal => (
            <Fragment key={goal.id}>
              <Typography className={classes.sliderTitle}>
                {goal.name}
                <MainTooltip title={goal.desc} placement="right">
                  <span className="icon-help" />
                </MainTooltip>
              </Typography>
              <NxtSlider
                onChangeCommitted={(event, newValue) => this.handleSliderValue(newValue, goal.id)}
                step={1}
                value={Object.keys(values).includes(goal.id) ? values[goal.id] : 0}
                valueLabelDisplay="on"
              />
            </Fragment>
          ))}
        </div>
      </div>
    )
  }
}

GoalsSlider.defaultProps = {
  helpText: '',
}

GoalsSlider.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    sliderContainer: PropTypes.string,
    sliderTitle: PropTypes.string,
  }).isRequired,
  onGoalChange: PropTypes.func.isRequired,
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      desc: PropTypes.string,
    }),
  ).isRequired,
  values: PropTypes.shape({}).isRequired,
  helpText: PropTypes.string,
}

export default withStyles(styles)(GoalsSlider)
