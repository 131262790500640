import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    base: {
      ...theme.loginBase,
      gridTemplateColumns: props => {
        return props.useCustomLogin ? '1fr 1fr' : '460px 1fr'
      },
      gridTemplateRows: '1fr',
      backgroundColor: props =>
        props.useCustomColor && props.customColor ? props.customColor : '',
      backgroundImage: props =>
        props.useCustomBgImg ? `url(${props.customBgImg})` : '',
      backgroundSize: props => (props.useCustomBgImg ? 'cover' : 'unset'),
      backgroundPosition: props =>
        props.useCustomBgImg ? 'bottom' : 'unset',
      color: props =>
        props.useCustomTextColor ? props.customTextColor : 'black',
    },
    customize: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > img': {
        maxWidth: '120px',
        height: 'auto',
        marginTop: '25px',
        [theme.breakpoints.up('lg')]: {
          marginTop: '0',
        }
      },
    },
    title: {
      fontSize: '20px',
      fontWeight: '400',
      textAlign: 'center',
      marginTop: '18px',
      marginBottom: 0,
      paddingLeft: '20px',
      paddingRight: '20px',
      [theme.breakpoints.up('lg')]: {
        fontSize: '30px',
      }
    }
  })

export default styles
