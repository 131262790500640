import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import EstimateForm from './components/estimate_form/View'
import EstimatedBenefitsForm from './components/estimated_benefits_form/View'

const LogisticData = (props) => {
  const {
    logisticCurrency,
    pilotEstimatedHours,
    pilotEstimatedQuantity,
    pilotEstimatedTime,
    pilotEstimatedTimeType,
    implementEstimatedHours,
    implementEstimatedQuantity,
    implementEstimatedTime,
    implementEstimatedTimeType,
    economicBenefits,
    otherBenefits,
    onImplementQuantityChange,
    onImplementHoursChange,
    onImplementTimeChange,
    onImplementTimeTypeChange,
    onPilotQuantityChange,
    onPilotHoursChange,
    onPilotTimeChange,
    onPilotTimeTypeChange,
    onEconomicBenefitsChange,
    onOtherBenefitsChange,
    logisticDataErrors,
    customIdeaTitle
  } = props

  return (
    <Fragment>
      <EstimateForm
        currency={logisticCurrency}
        title={i18n.t('LOGISTIC.PILOT')}
        classIcon="prototype"
        helpText={i18n.t('LOGISTIC.PILOT_EXT', { title: customIdeaTitle || "idea" })}
        quantity={pilotEstimatedQuantity}
        quantityError={logisticDataErrors.pilotEstimatedQuantity}
        hours={pilotEstimatedHours}
        hoursError={logisticDataErrors.pilotEstimatedHours}
        time={pilotEstimatedTime}
        timeError={logisticDataErrors.pilotEstimatedTime}
        timeType={pilotEstimatedTimeType}
        onQuantityChange={onPilotQuantityChange}
        onHoursChange={onPilotHoursChange}
        onTimeChange={onPilotTimeChange}
        onTimeTypeChange={onPilotTimeTypeChange}
      />
      <EstimateForm
        currency={logisticCurrency}
        title={i18n.t('LOGISTIC.IMPLEMENT')}
        classIcon="ideas-implemented"
        helpText={i18n.t('LOGISTIC.IMPLEMENT_EXT', { title: customIdeaTitle || "idea" })}
        quantity={implementEstimatedQuantity}
        quantityError={logisticDataErrors.implementEstimatedQuantity}
        hours={implementEstimatedHours}
        hoursError={logisticDataErrors.implementEstimatedHours}
        time={implementEstimatedTime}
        timeError={logisticDataErrors.implementEstimatedTime}
        timeType={implementEstimatedTimeType}
        onQuantityChange={onImplementQuantityChange}
        onHoursChange={onImplementHoursChange}
        onTimeChange={onImplementTimeChange}
        onTimeTypeChange={onImplementTimeTypeChange}
      />
      <EstimatedBenefitsForm
        title={i18n.t('LOGISTIC.ESTIMATED_BENEFITS')}
        classIcon="ranking"
        helpText={i18n.t('LOGISTIC.ESTIMATED_BENEFITS_EXT', { title: customIdeaTitle || "idea" })}
        benefits={economicBenefits}
        benefitsError={logisticDataErrors.economicBenefits}
        otherBenefits={otherBenefits}
        onEconomicBenefitsChange={onEconomicBenefitsChange}
        onOtherBenefitsChange={onOtherBenefitsChange}
      />
    </Fragment>
  )
}

LogisticData.defaultProps = {
  logisticDataErrors: {
    implementEstimatedHours: false,
    implementEstimatedQuantity: false,
    implementEstimatedTime: false,
    pilotEstimatedHours: false,
    pilotEstimatedQuantity: false,
    pilotEstimatedTime: false,
    economicBenefits: false,
  },
  customIdeaTitle: ""
}

LogisticData.propTypes = {
  pilotEstimatedHours: PropTypes.string.isRequired,
  pilotEstimatedQuantity: PropTypes.string.isRequired,
  pilotEstimatedTime: PropTypes.string.isRequired,
  pilotEstimatedTimeType: PropTypes.string.isRequired,
  implementEstimatedHours: PropTypes.string.isRequired,
  implementEstimatedQuantity: PropTypes.string.isRequired,
  implementEstimatedTime: PropTypes.string.isRequired,
  implementEstimatedTimeType: PropTypes.string.isRequired,
  economicBenefits: PropTypes.string.isRequired,
  otherBenefits: PropTypes.string.isRequired,
  logisticCurrency: PropTypes.string.isRequired,
  logisticDataErrors: PropTypes.shape({
    implementEstimatedHours: PropTypes.bool,
    implementEstimatedQuantity: PropTypes.bool,
    implementEstimatedTime: PropTypes.bool,
    pilotEstimatedHours: PropTypes.bool,
    pilotEstimatedQuantity: PropTypes.bool,
    pilotEstimatedTime: PropTypes.bool,
    economicBenefits: PropTypes.bool,
  }),

  onImplementQuantityChange: PropTypes.func.isRequired,
  onImplementHoursChange: PropTypes.func.isRequired,
  onImplementTimeChange: PropTypes.func.isRequired,
  onImplementTimeTypeChange: PropTypes.func.isRequired,
  onPilotQuantityChange: PropTypes.func.isRequired,
  onPilotHoursChange: PropTypes.func.isRequired,
  onPilotTimeChange: PropTypes.func.isRequired,
  onPilotTimeTypeChange: PropTypes.func.isRequired,
  onEconomicBenefitsChange: PropTypes.func.isRequired,
  onOtherBenefitsChange: PropTypes.func.isRequired,
  customIdeaTitle: PropTypes.string
}

export default LogisticData
