import { FULL_INFO_SUCCESS } from 'services/TypesConstants'
import {
  DELETE_FILE_FAILURE,
  DELETE_FILE_STARTED,
  DELETE_FILE_SUCCESS,
  GET_STANDARD_FORM_FAILURE,
  GET_STANDARD_FORM_STARTED,
  GET_STANDARD_FORM_SUCCESS,
  POST_FILE_FAILURE,
  POST_FILE_STARTED,
  POST_FILE_SUCCESS,
  POST_STANDARD_FORM_FAILURE,
  POST_STANDARD_FORM_STARTED,
  POST_STANDARD_FORM_SUCCESS,
} from 'services/standardForm/constants'

const initialState = {
  isStandardActive: false,
  standardFile: {
    isFileLoading: false,
    fileData: null,
  },
  standardForm: {
    isLoading: false,
    personalData: {
      name: '',
      lastName: '',
      company: '',
      workPosition: '',
    },
    companyInfo: {
      cel: '',
      corporateEmail: '',
      countryHeadOffice: '',
      otherCountries: '',
      companyId: '',
      annualSales: '',
      companySize: '',
      companyAge: '',
      solutionName: '',
      solutionLevel: '',
      solutionDescription: '',
      valueProposal: '',
    },
    marketInfo: {
      mainCompetitors: '',
      mainCustomers: '',
      file: {
        name: '',
        id: '',
        key: '',
        url: '',
        type: '',
        size: 0,
      },
      acceleratorBelong: '',
      seedCapital: '',
      whoFinances: '',
    },
    socialNetworks: {
      webSite: '',
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: '',
      others: '',
    },
    optionalQuestions: {
      legalAgent: '',
      companyAddress: '',
      businessName: '',
    },
  },
}

const formsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FULL_INFO_SUCCESS:
      return {
        ...state,
        isStandardActive: action.response.standardFormActive,
      }
    case GET_STANDARD_FORM_STARTED:
    case POST_STANDARD_FORM_STARTED:
      return {
        ...state,
        standardForm: {
          ...state.standardForm,
          isLoading: true,
        },
      }
    case GET_STANDARD_FORM_SUCCESS:
    case POST_STANDARD_FORM_SUCCESS:
      return {
        ...state,
        standardForm: {
          ...state.standardForm,
          ...action.response,
          isLoading: false,
        },
        standardFile: {
          isFileLoading: false,
          fileData: action.response.marketInfo.file,
        },
      }
    case GET_STANDARD_FORM_FAILURE:
    case POST_STANDARD_FORM_FAILURE:
      return {
        ...state,
        standardForm: initialState.standardForm,
        standardFile: {
          isFileLoading: false,
          fileData: null,
        },
      }
    case POST_FILE_STARTED:
    case DELETE_FILE_STARTED:
      return {
        ...state,
        standardFile: {
          ...state.standardFile,
          isFileLoading: true,
        },
      }
    case POST_FILE_SUCCESS:
      return {
        ...state,
        standardFile: {
          fileData: action.response,
          isFileLoading: false,
        },
      }
    case POST_FILE_FAILURE:
      return {
        ...state,
        standardForm: {
          ...state.standardForm,
          marketInfo: {
            ...state.standardForm.marketInfo,
            file: initialState.standardForm.marketInfo.file,
          },
        },
        standardFile: {
          fileData: null,
          isFileLoading: false,
        },
      }
    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        standardFile: {
          fileData: initialState.standardFile.fileData,
          isFileLoading: false,
        },
      }
    case DELETE_FILE_FAILURE:
      return {
        ...state,
        standardForm: {
          ...state.standardForm,
          marketInfo: {
            ...state.standardForm.marketInfo,
            file: initialState.standardForm.marketInfo.file,
          },
        },
        standardFile: {
          fileData: null,
          isFileLoading: false,
        },
      }
    default:
      return state
  }
}

export default formsReducer
