import React from 'react'
import { connect } from 'react-redux'
import {
  getSponsorSuggestions,
  getProposerSuggestions,
  getRoiInfo,
  postChallengeMainImage,
  getChallengeDraft,
  postInfoChallengeImage,
  postRewardChallengeImage,
  deleteInfoImage,
  deleteRewardImage,
  getGroups,
  putChallengeInfo,
  putPeopleInfo,
  putRoiInfo,
  putRewardInfo,
  deleteChallenge,
  putProposeChallenge,
  getChallenge,
  getChallengeSelectedGroups,
  putChallengeDates,
} from 'services/challenge_steps'
import {
  setSponsorSuggestion,
  setProposerSuggestion,
  setEasyModeSetting,
  setAdvancedAmountToBeSupported,
  setAdvancedSupportIdeas,
  setAdvancedApprovedIdeas,
  setAdvancedImplementedIdeas,
  increaseChallengeStep,
  decreaseChallengeStep,
  setTitleDescription,
  setWhyImportantDescription,
  setChallengeDescription,
  uploadImageFailure,
  setExternalVideo,
  setChallengeRewardDescription,
  setChallengeRewardRewarded,
  setChallengeRewardOther,
  setPreviewFromDate,
  setPreviewToDate,
  setGamingTabValue,
  moveGroupsToRight,
  setRightGroups,
  setOrganization,
  resetChallenge,
} from 'actions/sync/challenge_steps'
import { startConfirmationService } from 'actions/async/uploadFileActions'
import { resetChallengeStorageVideo } from 'actions/sync/video'
import {
  deleteChallengeVideo,
  postChallengeVideo,
  generateChallengeVideoUploadUrl,
  getChallengeVideoType
} from 'services/videos'
import {
  SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED,
  SET_ADVANCED_SUPPORT_IDEAS,
  SET_ADVANCED_APPROVED_IDEAS,
  SET_ADVANCED_IMPLEMENTED_IDEAS,
} from 'actions/sync/challenge_steps/Constants'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import i18n from 'support/i18n'
import ImageCropper from 'components/image_uploader/components/image_cropper/View'
import {
  showPopupDialog,
  showPopupErrorSnackbar,
  showPopupSuccessSnackbar,
} from 'support/popup_dialogs'
import { getActiveOrganizations } from 'services/organizations'
import debounce from 'debounce'

import Navigation from 'support/navigation'
import VideoUploadDialog from 'pages/idea/form/components/dialogs/UploadVideoContainer'
import { VIDEO_STATE_TYPE } from 'common_constants/Video'
import View from './View'
import { advancedValuesBySetting } from './View.Utils'
import ConfirmSubmitContainer from './components/preview_launch/components/dialogs/ConfirmSubmitContainer'
import ImageViewDialog from './components/preview_launch/components/dialogs/ImageViewDialog/View'

const mapStateToProps = state => ({
  sponsorSuggestions: state.data.challengeSteps.sponsorSuggestions,
  sponsorStarted: state.data.challengeSteps.sponsorStarted,
  proposerSuggestions: state.data.challengeSteps.proposerSuggestions,
  proposerStarted: state.data.challengeSteps.proposerStarted,
  selectedSponsor: state.data.challengeSteps.selectedSponsor,
  selectedProposer: state.data.challengeSteps.selectedProposer,
  selectedGamingSetting: state.data.challengeSteps.gaming.easyMode.settings,
  approveMult: state.data.challengeSteps.gaming.advancedMode.approveMult,
  implementMult: state.data.challengeSteps.gaming.advancedMode.implementMult,
  supportMult: state.data.challengeSteps.gaming.advancedMode.supportMult,
  currentGoal: state.data.challengeSteps.gaming.advancedMode.currentGoal,
  defaultValues: state.data.challengeSteps.gaming.advancedMode.defaultValues,
  activeStep: state.data.challengeSteps.activeStep,
  challengeDescription: state.data.challengeSteps.challengeDescription,
  reward: state.data.challengeSteps.reward,
  isNextStepInProgress: state.data.challengeSteps.isNextStepInProgress,
  isLoading: state.data.challengeSteps.isLoading,
  activeTab: state.data.challengeSteps.gaming.activeTab,
  previewChallenge: state.data.challengeSteps.preview,
  organizationList: state.data.domain.folderOrganizations,
  isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
  organizationsName: state.data.domain.domainConfiguration.organizationsName,
  imageUploadError: state.data.challengeSteps.errors.imageUploadError,
  groupId: state.data.challengeSteps.preview.groupId,
  isActive: state.data.challengeSteps.isActive,
  isProposed: state.data.challengeSteps.isProposed,
  challengeId: state.data.challengeSteps.id,
  customIdeaTitles: state.data.domain.customTitlesConf.customIdeaTitles
})

const mapDispatchToProps = (dispatch, ownProps) => {
  const challengeId = ownProps.match.params.id
  return {
    onComponentWillUnmount: () => {
      dispatch(resetChallenge())
    },
    proposeChallenge: () => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('CHALLENGES.PROPOSE_SENT'), dispatch)
        Navigation.navigateToChallenges()
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('CHALLENGES.PROPOSE_ERROR'), dispatch)
      }
      dispatch(putProposeChallenge(onSuccess, onFailure))
    },
    getChallengeSelectedGroups: groupId => {
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('CHALLENGES.GET_CHALLENGE_SELECTED_GROUPS_KO'), dispatch)
      }
      dispatch(getChallengeSelectedGroups(groupId, onFailure))
    },
    getChallenge: () => {
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('CHALLENGES.GET_CHALLENGE_DETAIL_KO'), dispatch)
      }
      dispatch(getChallenge(challengeId, onFailure))
    },
    completeChallengeData: () => {
      dispatch(getRoiInfo())
      dispatch(getGroups())
    },
    initializeChallenge: isProposing => {
      const onDeleteSucces = () => {
        dispatch(getRoiInfo())
      }
      const onSuccess = ({ challengeDescription }) => {
        const { title, description } = challengeDescription
        if (!title && !description) {
          dispatch(deleteChallenge(onDeleteSucces))
        } else if (!isProposing) {
          dispatch(getRoiInfo())
        }
      }
      dispatch(getChallengeDraft(onSuccess))
      if (!isProposing) {
        dispatch(getGroups())
      }
    },
    setActiveTab: (event, activeTab) => {
      dispatch(setGamingTabValue(activeTab))
    },
    onRightGroupClick: groups => {
      dispatch(setRightGroups(groups))
    },
    onMoveToRight: groups => {
      dispatch(moveGroupsToRight(groups))
    },
    onMoveToLeft: groups => {
      dispatch(setRightGroups(groups))
    },
    onSelectPreviewFromDate: fromDate => {
      dispatch(setPreviewFromDate(fromDate))
    },
    onSelectPreviewToDate: toDate => {
      dispatch(setPreviewToDate(toDate))
    },
    onDescriptionChange: debounce(
      description => dispatch(setChallengeDescription(description)),
      300,
    ),
    saveRewardInfo: () => {
      const onSuccess = () => {
        dispatch(increaseChallengeStep())
      }
      dispatch(putRewardInfo(onSuccess))
    },
    saveRoiInfo: () => {
      const onSuccess = () => {
        dispatch(increaseChallengeStep())
      }
      dispatch(putRoiInfo(onSuccess))
    },
    savePeopleInfo: () => {
      const onSuccess = () => {
        dispatch(increaseChallengeStep())
      }
      dispatch(putPeopleInfo(onSuccess))
    },
    saveDescriptionInfo: () => {
      const onSuccess = () => {
        dispatch(increaseChallengeStep())
      }
      dispatch(putChallengeInfo(onSuccess))
    },
    onTitleChange: debounce(title => dispatch(setTitleDescription(title)), 300),
    onWhyImportantChange: debounce(
      whyImportant => dispatch(setWhyImportantDescription(whyImportant)),
      300,
    ),
    onResetChallenge: () => {
      const onSuccess = () => {
        dispatch(getRoiInfo())
      }
      dispatch(deleteChallenge(onSuccess))
    },
    increaseChallengeStep: activeStep => {
      if (activeStep < 4) {
        dispatch(increaseChallengeStep())
      }
    },
    decreaseChallengeStep: activeStep => {
      if (activeStep > 0) {
        dispatch(decreaseChallengeStep())
      }
    },
    getRoiInfo: () => {
      dispatch(getRoiInfo())
    },
    getSponsorSuggestions: username => {
      dispatch(getSponsorSuggestions(username))
    },
    getProposerSuggestions: username => {
      dispatch(getProposerSuggestions(username))
    },
    onSponsorSelected: suggestion => {
      dispatch(setSponsorSuggestion(suggestion))
    },
    onRemoveSponsor: () => {
      dispatch(setSponsorSuggestion({}))
    },
    onProposerSelected: suggestion => {
      dispatch(setProposerSuggestion(suggestion))
    },
    onRemoveProposer: () => {
      dispatch(setProposerSuggestion({}))
    },
    onSettingChange: (setting, defaultValues) => {
      dispatch(
        setEasyModeSetting({
          setting,
          advancedModeMult: advancedValuesBySetting(setting, defaultValues),
        }),
      )
    },
    onSliderChangeCommitted: (type, amount) => {
      switch (type) {
        case SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED:
          dispatch(setAdvancedAmountToBeSupported(amount))
          break
        case SET_ADVANCED_SUPPORT_IDEAS:
          dispatch(setAdvancedSupportIdeas(amount))
          break
        case SET_ADVANCED_APPROVED_IDEAS:
          dispatch(setAdvancedApprovedIdeas(amount))
          break
        case SET_ADVANCED_IMPLEMENTED_IDEAS:
          dispatch(setAdvancedImplementedIdeas(amount))
          break
        default:
          break
      }
    },
    onSelectImage: image => {
      const onSuccess = () => dispatch(hideDialog())
      const onFailure = error => {
        if (error.status === 413) {
          dispatch(uploadImageFailure(i18n.t('IDEA.CHOOSE_VALID_IMAGE')))
        } else {
          showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
        }
        dispatch(hideDialog())
      }
      const component = (
        <ImageCropper
          imageFile={image}
          onSelectCrop={(_, croppedImage) => {
            dispatch(postInfoChallengeImage(croppedImage, onSuccess, onFailure))
          }}
          onClose={() => dispatch(hideDialog())}
        />
      )
      showPopupDialog(component, dispatch)
    },
    onSelectMainImage: imageName => {
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
      }
      dispatch(postChallengeMainImage(imageName, onFailure))
    },
    deleteImage: (_, imageName) => {
      const onFailure = errorResponse => {
        const { data } = errorResponse
        if (data && data.error && data.error.status === 400 && data.error.code === 2003) {
          showPopupErrorSnackbar(i18n.t('CHALLENGES.DELETE_IMAGE_ERROR'), dispatch)
        } else {
          showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
        }
      }
      dispatch(deleteInfoImage(imageName, onFailure))
    },
    onRewardDescriptionChange: debounce(
      value => dispatch(setChallengeRewardDescription(value)),
      300,
    ),
    onRewardRewardedChange: value => dispatch(setChallengeRewardRewarded(value)),
    onRewardOtherChange: debounce(value => dispatch(setChallengeRewardOther(value)), 300),
    onSelectRewardImage: image => {
      const onSuccess = () => dispatch(hideDialog())
      const onFailure = error => {
        if (error.status === 413) {
          dispatch(uploadImageFailure(i18n.t('IDEA.CHOOSE_VALID_IMAGE')))
        } else {
          showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
        }
        dispatch(hideDialog())
      }
      const component = (
        <ImageCropper
          imageFile={image}
          onSelectCrop={(_, croppedImage) => {
            dispatch(postRewardChallengeImage(croppedImage, onSuccess, onFailure))
          }}
          onClose={() => dispatch(hideDialog())}
        />
      )
      showPopupDialog(component, dispatch)
    },
    deleteRewardImage: (_, imageName) => {
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
      }
      dispatch(deleteRewardImage(imageName, onFailure))
    },
    onUploadExternalVideo: src => dispatch(setExternalVideo(src)),
    onUploadVideoFile: innerChallengeId =>
      showPopupDialog(
        <VideoUploadDialog
          parentId={innerChallengeId}
          onOuterUploadVideo={postChallengeVideo}
          beforeOuterUploadVideo={generateChallengeVideoUploadUrl}
          onOuterDeleteVideo={deleteChallengeVideo}
          stateType={VIDEO_STATE_TYPE.CHALLENGE}
        />,
        dispatch,
      ),
    startConfirmationService: videoId => {
      dispatch(startConfirmationService({ isIdea: false, videoId }))
    },
    onRemoveExternalVideo: () => dispatch(setExternalVideo('')),
    onRemoveVideo: () => {
      dispatch(resetChallengeStorageVideo())
    },
    onOrganizationSelect: value => dispatch(setOrganization(value)),
    getOrganizations: () => dispatch(getActiveOrganizations()),
    onClickToShowRewardImage: src => {
      const onClose = () => dispatch(hideDialog())
      showPopupDialog(<ImageViewDialog src={src} onClose={onClose} />, dispatch)
    },
    launchChallenge: isNewChallenge => {
      const component = <ConfirmSubmitContainer shouldDeleteDraft={isNewChallenge} />
      showPopupDialog(component, dispatch)
    },
    editDates: () => {
      const onSuccess = () => {
        Navigation.navigateToChallengeDetail(challengeId)
      }
      dispatch(putChallengeDates(onSuccess))
    },
    getChallengeVideoType: url => dispatch(getChallengeVideoType(url)),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
