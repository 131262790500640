export default theme => ({
  root: {
    backgroundColor: '#666',
    padding: theme.spacing(4, 10),
  },
  submitSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '38%',
    },
  },
  investmentInput: {
    backgroundColor: 'white',
    borderRadius: '5px',
    '& input': {
      paddingTop: '8.5px',
      paddingBottom: '8.5px',
    },
  },
  backToTopContainer: {
    display: 'flex',
    color: 'white',
    width: '100%',
    '&  span': {
      fontSize: '55px',
    },
    '&  p': {
      fontSize: '20px',
      marginTop: theme.spacing(-2.5),
      textTransform: 'none',
    },
  },
  backToTop: {
    color: 'white',
    '& span': {
      display: 'flex',
      flexDirection: 'column',
    },
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  formButtons: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  dividerColor: {
    borderTopColor: '#999999',
  },
})
