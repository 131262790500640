export function setProfileBody(first, last, photo, oldPass, newPass) {
  let profile = `${encodeURIComponent('first')}=${encodeURIComponent(first)}`
  profile += `&${encodeURIComponent('last')}=${encodeURIComponent(last)}`
  if (photo) profile += `&${encodeURIComponent('photo')}=${encodeURIComponent(photo)}`
  if (oldPass) profile += `&${encodeURIComponent('old_pass')}=${encodeURIComponent(oldPass)}`
  if (newPass) profile += `&${encodeURIComponent('new_pass')}=${encodeURIComponent(newPass)}`
  return profile
}

export function setUserPrefsBody(key, value) {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
}

export function setRequestInvestmentBody(ideaId, messageContent) {
  return `${encodeURIComponent('ideaK')}=${encodeURIComponent(ideaId)}&${encodeURIComponent(
    'content',
  )}=${encodeURIComponent(messageContent)}`
}

export function setLegalAcceptance(accept) {
  return `${encodeURIComponent('accept')}=${encodeURIComponent(accept)}`
}
