import React, { Component, Fragment } from 'react'
import i18n from 'support/i18n'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import { ButtonLink } from 'components'
import TextEditor from 'components/text_editor/View'
import styles from './View.Style'
import IdeaAvatar from '../idea_avatar/View'
import InviteeForm from './components/invitee_form/View'
import PendingInvitations from './components/pendings_invitations/View'

class SendJoinSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requestAccessToTeam: '',
      showRequestTeamEditor: false,
    }
    this.handleChangeHTML = this.handleChangeHTML.bind(this)
    this.handleSendRequest = this.handleSendRequest.bind(this)
    this.toggleRequestJoinTeam = this.toggleRequestJoinTeam.bind(this)
  }

  handleChangeHTML(event, editor) {
    const value = editor.getData()
    this.setState({ requestAccessToTeam: value })
  }

  handleSendRequest(request) {
    const { ideaId, onRequestJoinIdeaTeam } = this.props
    onRequestJoinIdeaTeam(ideaId, request)
  }

  toggleRequestJoinTeam() {
    const { requestAccessToTeam, showRequestTeamEditor } = this.state
    this.setState({
      showRequestTeamEditor: !showRequestTeamEditor,
      requestAccessToTeam: !showRequestTeamEditor ? '' : requestAccessToTeam,
    })
  }

  render() {
    const {
      classes,
      team,
      onSearchSuggestions,
      suggestions,
      inputLabel,
      pendingInvitationsUsers,
      onSendInvitationClick,
      onPendingUserClick,
      onTeamUserClick,
      onPendingUserRemoveClick,
      canAddMembers,
      sectionTitle,
      inputHelpText,
      highlightedUserEmail,
      isSearchingForUser,
      canJoinTeam,
      isAlreadyInvited,
      isAnonymous,
      disableAddMembers,
    } = this.props
    const { showRequestTeamEditor, requestAccessToTeam } = this.state
    return (
      <Fragment>
        <div className={classes.root}>
          <h2 className={classes.titleSection}>
            <span className="icon-team" />
            {sectionTitle}
          </h2>
          <div>
            {!isAnonymous &&
              team.map(userTeam => (
                <ButtonLink
                  onLinkClick={() => onTeamUserClick(userTeam.email)}
                  key={userTeam.email}
                >
                  <IdeaAvatar
                    name={userTeam.fullName}
                    photoSrc={userTeam.photoSrc}
                    isAdmin={highlightedUserEmail === userTeam.email}
                    hasDeleteButton={canAddMembers && highlightedUserEmail !== userTeam.email}
                    onDeleteButtonClick={() => onPendingUserRemoveClick(userTeam.email)}
                  />
                </ButtonLink>
              ))}
            {canAddMembers && (
              <InviteeForm
                onSearchSuggestions={onSearchSuggestions}
                onSendInvitationClick={onSendInvitationClick}
                suggestions={suggestions}
                inputLabel={inputLabel}
                inputHelpText={inputHelpText}
                pendingInvitationsUsers={pendingInvitationsUsers}
                isSearchingForUser={isSearchingForUser}
                disableAddMembers={disableAddMembers}
              />
            )}
            <PendingInvitations
              pendingInvitationsUsers={pendingInvitationsUsers}
              onPendingUserClick={onPendingUserClick}
              onPendingUserRemoveClick={onPendingUserRemoveClick}
              showDeleteUserIcon={canAddMembers}
            />
          </div>
          <div className={classes.joinTheTeamContainer}>
            {!disableAddMembers && 
             !isAlreadyInvited && 
             canJoinTeam && 
             !showRequestTeamEditor && (
              <Button color="primary" onClick={this.toggleRequestJoinTeam} variant="contained">
                {i18n.t('IDEA.JOIN_THIS_TEAM')}
              </Button>
            )}
            {!isAlreadyInvited && showRequestTeamEditor && (
              <div>
                <TextEditor
                  onTextChange={text => this.setState({ requestAccessToTeam: text })}
                  value={requestAccessToTeam}
                />
                <div className={classes.editorButtons}>
                  <Button
                    id="send-invitation-button"
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleSendRequest(requestAccessToTeam)}
                  >
                    {i18n.t('IDEA.SEND_REQUEST')}
                  </Button>
                  <Button
                    id="cancel-button"
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      this.setState({
                        showRequestTeamEditor: false,
                      })
                    }
                  >
                    {i18n.t('IDEA.CANCEL')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

SendJoinSection.defaultProps = {
  inputHelpText: '',
}

SendJoinSection.propTypes = {
  isSearchingForUser: PropTypes.bool.isRequired,
  inputHelpText: PropTypes.string,
  sectionTitle: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  highlightedUserEmail: PropTypes.string.isRequired,
  team: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      isAdmin: PropTypes.bool.isRequired,
      photoSrc: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      photoSrc: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pendingInvitationsUsers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      photoSrc: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSearchSuggestions: PropTypes.func.isRequired,
  onSendInvitationClick: PropTypes.func.isRequired,
  onPendingUserClick: PropTypes.func.isRequired,
  onTeamUserClick: PropTypes.func.isRequired,
  onPendingUserRemoveClick: PropTypes.func.isRequired,
  canAddMembers: PropTypes.bool.isRequired,
  canJoinTeam: PropTypes.bool.isRequired,
  disableAddMembers: PropTypes.bool.isRequired,
}

export default withStyles(styles)(SendJoinSection)
