const styles = () => ({
  root: {
    position: 'relative',
  },
  suggestionList: {
    position: 'absolute',
  },
})

export default styles
