import React from 'react'
import { Card, CardContent, Grid, TextField, Typography } from '@material-ui/core'
import i18n from 'support/i18n'
import { TextFieldStyled, limitedKeyUpRegex } from './utils'

const SocialNetworks = ({ onChangeValue, info, anyError, isDisabled }) => {
  const { webSite, facebook, twitter, instagram, linkedin, others } = info

  const onChange = (event, field) => {
    if (['webSite'].includes(field)) {
      const { value: item } = event.target

      if (item?.trim().length > 0) {
        if (limitedKeyUpRegex.test(item)) {
          return onChangeValue('socialNetworks', { ...info, [field]: item })
        }

        return null
      }
    }

    const data = { ...info, [field]: event.target.value }
    return onChangeValue('socialNetworks', data)
  }

  return (
    <Grid item xs={12}>
      <Card
        variant="elevation"
        elevation={3}
        style={{
          borderRadius: 8,
        }}
      >
        <CardContent spacing={2}>
          <Typography variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
            {i18n.t('FORMS.STANDARD_FORM.FORM.SOCIAL_NETWORKS')}
          </Typography>
          <TextField
            id="standard-full-width"
            label={i18n.t('FORMS.STANDARD_FORM.FORM.WEB_SITE')}
            margin="normal"
            required
            fullWidth
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'webSite')}
            value={webSite}
            disabled={isDisabled}
            error={anyError && 'webSite' in anyError}
            helperText={anyError?.webSite}
          />
          <TextField
            id="standard-full-width"
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.FACEBOOK')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'facebook')}
            value={facebook}
            disabled={isDisabled}
            error={anyError && 'facebook' in anyError}
            helperText={anyError?.facebook}
          />
          <TextField
            id="standard-full-width"
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.TWITTER')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'twitter')}
            value={twitter}
            disabled={isDisabled}
            error={anyError && 'twitter' in anyError}
            helperText={anyError?.twitter}
          />
          <TextField
            id="standard-full-width"
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.INSTAGRAM')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'instagram')}
            value={instagram}
            disabled={isDisabled}
            error={anyError && 'instagram' in anyError}
            helperText={anyError?.instagram}
          />
          <TextField
            id="standard-full-width"
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.LINKEDIN')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'linkedin')}
            value={linkedin}
            disabled={isDisabled}
            error={anyError && 'linkedin' in anyError}
            helperText={anyError?.linkedin}
          />
          <TextField
            id="standard-full-width"
            fullWidth
            label={i18n.t('FORMS.STANDARD_FORM.FORM.OTHERS')}
            margin="normal"
            InputLabelProps={TextFieldStyled}
            onChange={event => onChange(event, 'others')}
            value={others}
            disabled={isDisabled}
            error={anyError && 'others' in anyError}
            helperText={anyError?.others}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}

export default SocialNetworks
