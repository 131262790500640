import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import clsx from 'clsx'
import ButtonLink from 'components/button_link/View'
import i18n from 'support/i18n'
import styles from './View.Style'

const SummaryStepper = props => {
  const { classes, activeStep, onHelpClick } = props
  return (
    <div className={classes.root}>
      <span className={classes.link}>
        <span>{i18n.t('VENTURE_ROUNDS.TITLE')}</span>
        <ButtonLink onLinkClick={onHelpClick}>
          <span className="icon-help" />
        </ButtonLink>
      </span>
      <div className={classes.stepper}>
        <div
          className={clsx(
            classes.seedStep,
            activeStep === 0 ? classes.activeStep : classes.inactiveStep,
          )}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: i18n.t('VENTURE_ROUNDS.SEED') }}
        />
        <div
          className={clsx(
            classes.firstStep,
            activeStep === 1 ? classes.activeStep : classes.inactiveStep,
          )}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: i18n.t('VENTURE_ROUNDS.FIRST') }}
        />
        <div
          className={clsx(
            classes.secondStep,
            activeStep === 2 ? classes.activeStep : classes.inactiveStep,
          )}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: i18n.t('VENTURE_ROUNDS.SECOND') }}
        />
        <div
          className={clsx(
            classes.thirdStep,
            activeStep === 3 ? classes.activeStep : classes.inactiveStep,
          )}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: i18n.t('VENTURE_ROUNDS.THIRD') }}
        />
      </div>
    </div>
  )
}

SummaryStepper.defaultProps = {
  activeStep: -1,
}

SummaryStepper.propTypes = {
  activeStep: PropTypes.number,
  onHelpClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(SummaryStepper)
