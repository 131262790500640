const styles = theme => ({
  link: {
    fontSize: '14px',
    '& > span': {
      marginRight: theme.spacing(0.5),
    },
    '& > button': {
      color: theme.palette.primary.main,
    },
  },
  stepper: {
    display: 'flex',
    flexDirection: 'row',
    color: '#666',
    position: 'relative',
    '& > div': {
      padding: theme.spacing(0, 2),
      height: '38px',
      borderRadius: '0 20% 20% 0 / 0% 46% 46% 0',
      border: '2px solid #FFF',
      display: 'flex',
      justifyContent: 'flex-end',
      flex: 1,
      alignItems: 'center',
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },
  seedStep: {
    zIndex: 5,
  },
  firstStep: {
    zIndex: 4,
    marginLeft: '-16px',
  },
  secondStep: {
    zIndex: 3,
    marginLeft: '-16px',
  },
  thirdStep: {
    zIndex: 2,
    marginLeft: '-16px',
  },
  inactiveStep: {
    backgroundColor: '#eeeeee',
  },
  activeStep: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
})

export default styles
