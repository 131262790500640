import background from 'img/bg-idea-status.png'

const styles = () => ({
  statusItems: {
    margin: '0px',
    padding: '5px',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '60px',
    width: '150px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    border: '1px solid #E3E3E3',
    '&:after': {
      content: '""',
      width: '24px',
      height: '70px',
      background: `url(${background}) 0 0 no-repeat`,
      position: 'absolute',
      zIndex: 1,
      right: '-20px',
    },
  },
  disabledStatus: {
    color: '#999',
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
  iconContainerNext: {
    opacity: 0.4,
  },
  iconsStyles: {
    position: 'absolute',
    height: '24px',
    width: '24px',
    fontSize: '18px',
    textAlign: 'center',
    lineHeight: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  },
  primaryProgressContainer: {
    position: 'absolute',
    width: 40,
    height: 40,
  },
  secondaryProgressContainer: {
    position: 'absolute',
    width: 30,
    height: 30,
  },
  textContainer: {
    flex: 1,
    marginLeft: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  textContainerNext: {
    opacity: 0.4,
    fontWeight: 400,
  },
  textContainerPrevious: {
    color: '#999',
    fontWeight: 400,
  },
})

export default styles
