const styles = theme => ({
  root: {
    gridColumnStart: 2,
    padding: theme.spacing(5, 0),
  },
  pendingValidationContainer: {
    marginTop: 0,
  },
})

export default styles
