const styles = theme => ({
  root: {
    gridColumnStart: 2,
    padding: theme.spacing(5, 0),
    '& .MuiTabs-root': {
      minHeight: '36px',
      paddingLeft: theme.spacing(2),
      '&	.MuiTab-fullWidth': {
        flexGrow: 'unset',
      },
      '& .MuiTabs-flexContainer': {
        display: 'block',
      }
    },
    '& .MuiTab-root': {
      minHeight: '36px',
      backgroundColor: '#dbdbdb',
      textTransform: 'capitalize',
      marginLeft: '1px',
      marginRight: '1px',
      paddingTop: '9px',
      paddingBottom: '9px',
      color: '#000',
      [theme.breakpoints.up('md')]: {
        minWidth: 'initial',
      },
      '&.MuiTab-textColorInherit': {
        opacity: 1,
      }
    },
    '& .Mui-selected': {
      backgroundColor: '#fff',
      fontWeight: 'bold',
      borderTop: '1px solid #e1e1e1',
      borderRight: '1px solid #e1e1e1',
      borderLeft: '1px solid #e1e1e1',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    }
  },
  paper: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      marginRight: theme.spacing(1),
    },
  },
  dashboardContainer: {
    paddingTop: theme.spacing(3),
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    marginBottom: '1rem',
  },
  hide: {
    display: 'none',
  },
})

export default styles
