const styles = theme => ({
  daysPercentage: {
    margin: 0,
    '& > span:first-child': {
      fontSize: '14px',
      fontWeight: 'bold',
      marginRight: theme.spacing(0.5),
    },
    '& > span:last-child': {
      fontSize: '11px',
    },
  },
  days: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span:first-child': {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    '& > span:last-child': {
      fontSize: '12px',
    },
  },
  percentage: {
    margin: 0,
    color: theme.palette.primary.main,
    '& > span:first-child': {
      fontSize: '17px',
      fontWeight: 'bold',
    },
    '& > span:last-child': {
      fontSize: '11px',
    },
  },
})

export default styles
