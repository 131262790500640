import { ServiceCall } from 'support/network/ServiceCallAction'
import * as userTypes from 'actions/sync/user/userActionConstans'
import {
  SET_USER_PREFS_STARTED,
  SET_USER_PREFS_SUCCESS,
  SET_USER_PREFS_FAILURE,
  REGISTER_SYSTEM_ACCESS_STARTED,
  REGISTER_SYSTEM_ACCESS_SUCCESS,
  REGISTER_SYSTEM_ACCESS_FAILURE,
} from 'actions/sync/settings/Constants'
import { prepareHeadersFromState } from '../HeadersUtils'
import {
  transformGetInvestmentsServiceResponseDataWithState,
  transformEditProfileServiceResponseDataWithState,
  transformUserPrefsServiceResponseWithState,
  transformGetPortfolioServiceResponseDataWithState,
} from './ResponseUtils'
import { setProfileBody, setUserPrefsBody, setRequestInvestmentBody, setLegalAcceptance } from './RequestUtils'

export const GET_INVESTMENTS_URL = '/api/v1/user/##user_mail##/investment?limit=50&offset=0'
export const GET_PORTFOLIO_INVESTMENTS_URL = '/s/user/me/investments'
export const GET_USER_INFO_URL = '/s/user/me'
export const EDIT_PROFILE_URL = '/s/user/edit-info'
export const SET_USER_PREFS_URL = '/s/user/prefs'
export const POST_USER_ACCEPT_LEGAL_URL = '/s/user/legalaccept'
const POST_REGISTER_SYSTEM_ACCESS = '/s/user/system-access'
const POST_REQUEST_INVESTMENT_URL = '/s/user/##user_email##/requestInvest'

export function getInvestments(userMail) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userTypes.GET_INVESTMENTS_STARTED
  apiCall.successReqType = userTypes.GET_INVESTMENTS_SUCCESS
  apiCall.failureReqType = userTypes.GET_INVESTMENTS_FAILURE
  apiCall.setEndpointFromState = () => GET_INVESTMENTS_URL.replace('##user_mail##', btoa(userMail))
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformGetInvestmentsServiceResponseDataWithState
  return apiCall.getAction()
}

export function editProfile(onSuccess, onFailure, first, last, photo, oldPass, newPass) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userTypes.EDIT_PROFILE_STARTED
  apiCall.successReqType = userTypes.EDIT_PROFILE_SUCCESS
  apiCall.failureReqType = userTypes.EDIT_PROFILE_FAILURE
  apiCall.setEndpointFromState = () => EDIT_PROFILE_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setProfileBody(first, last, photo, oldPass, newPass)
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformEditProfileServiceResponseDataWithState
  return apiCall.getAction()
}

export function postUserPrefsService(key, value) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = SET_USER_PREFS_STARTED
  apiCall.successReqType = SET_USER_PREFS_SUCCESS
  apiCall.failureReqType = SET_USER_PREFS_FAILURE
  apiCall.setEndpointFromState = () => SET_USER_PREFS_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setUserPrefsBody(key, value)
  apiCall.transformResponseDataWithState = transformUserPrefsServiceResponseWithState
  apiCall.auth = true
  return apiCall.getAction()
}

export function postRegisterSystemAccess() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = REGISTER_SYSTEM_ACCESS_STARTED
  apiCall.successReqType = REGISTER_SYSTEM_ACCESS_SUCCESS
  apiCall.failureReqType = REGISTER_SYSTEM_ACCESS_FAILURE
  apiCall.setEndpointFromState = () => POST_REGISTER_SYSTEM_ACCESS
  apiCall.method = 'POST'
  apiCall.auth = true
  return apiCall.getAction()
}

export function postRequestInvestment(investorEmail, ideaId, messageContent, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userTypes.POST_REQUEST_INVESTMENT_STARTED
  apiCall.successReqType = userTypes.POST_REQUEST_INVESTMENT_SUCCESS
  apiCall.failureReqType = userTypes.POST_REQUEST_INVESTMENT_FAILURE
  apiCall.setEndpointFromState = () =>
    POST_REQUEST_INVESTMENT_URL.replace('##user_email##', investorEmail)
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setRequestInvestmentBody(ideaId, messageContent)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export function getPortfolioInvestments() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userTypes.GET_PORTFOLIO_INVESTMENTS_STARTED
  apiCall.successReqType = userTypes.GET_PORTFOLIO_INVESTMENTS_SUCCESS
  apiCall.failureReqType = userTypes.GET_PORTFOLIO_INVESTMENTS_FAILURE
  apiCall.setEndpointFromState = () => GET_PORTFOLIO_INVESTMENTS_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformGetPortfolioServiceResponseDataWithState
  return apiCall.getAction()
}

export function getUserInfo() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userTypes.GET_USER_INFO_STARTED
  apiCall.successReqType = userTypes.GET_USER_INFO_SUCCESS
  apiCall.failureReqType = userTypes.GET_USER_INFO_FAILURE
  apiCall.setEndpointFromState = () => GET_USER_INFO_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = jsonResponse => jsonResponse
  return apiCall.getAction()
}

export function postAcceptLegal() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userTypes.POST_USER_ACCEPT_LEGAL_STARTED
  apiCall.successReqType = userTypes.POST_USER_ACCEPT_LEGAL_SUCCESS
  apiCall.failureReqType = userTypes.POST_USER_ACCEPT_LEGAL_FAILURE
  apiCall.setEndpointFromState = () => POST_USER_ACCEPT_LEGAL_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setLegalAcceptance(true)
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  return apiCall.getAction()
}

export function postDeclineLegal(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = userTypes.POST_USER_DECLINE_LEGAL_STARTED
  apiCall.successReqType = userTypes.POST_USER_DECLINE_LEGAL_SUCCESS
  apiCall.failureReqType = userTypes.POST_USER_DECLINE_LEGAL_FAILURE
  apiCall.setEndpointFromState = () => POST_USER_ACCEPT_LEGAL_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setLegalAcceptance(false)
  apiCall.onSuccess = onSuccess
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  return apiCall.getAction()
}
