export const arrayToGroups = (list, howMany) => {
  const result = []
  const a = list.slice(0)
  while (a[0]) {
    result.push(a.splice(0, howMany))
  }
  return result
}

export const reduceToProp = (list, prop, cb) => {
  return list.map(item => {
    if (cb) return cb(item[prop])

    return item[prop]
  })
}

export const sortByProp = (list, prop) => {
  list.sort(( a, b ) => {
    if ( a[prop] < b[prop] ){
      return -1;
    }
    if ( a[prop] > b[prop] ){
      return 1;
    }
    return 0;
  });
  
}
