export function checkYoutubeUrl(url) {
  const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  return url && url.match(p) ? RegExp.$1 : false
}

export function getVideoOrigin(url) {
  if (url) {
    if (url.include("youtube.com")) return 'YOUTUBE'
    
    return 'BLOBSTORAGE'
  }

  return ''
}
