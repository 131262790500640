import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import ButtonLink from 'components/button_link/View'
import styles from './View.Style'

const ChallengeSection = props => {
  const { classes, challengeImageSrc, challengeName, onClickViewChallenge, challengeId } = props

  return (
    <Fragment>
      {challengeImageSrc && challengeName && challengeId && (
        <div className={classes.root}>
          <h2 className={classes.titleSection}>
            <span className="icon-target" />
            {i18n.t('DASHBOARD.CHALLENGES')}
          </h2>
          <div className={classes.challengeContainer}>
            <img className={classes.challengeImage} src={challengeImageSrc} alt="challenge" />
            <div className={classes.challengeDescriptionContainer}>
              <span>{challengeName}</span>
              <ButtonLink onLinkClick={() => onClickViewChallenge(challengeId)}>
                {i18n.t('EXPLORE_IDEA.VIEW_CHALLENGE')}
              </ButtonLink>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

ChallengeSection.defaultProps = {}

ChallengeSection.propTypes = {
  challengeImageSrc: PropTypes.string.isRequired,
  challengeName: PropTypes.string.isRequired,
  challengeId: PropTypes.string.isRequired,
  onClickViewChallenge: PropTypes.func.isRequired,
}

export default withStyles(styles)(ChallengeSection)
