import background from 'img/bg-white-login-pattern-icons.png'

const styles = () => ({
  container: {
    display: 'grid',
    gridArea: 'middle',
    gridColumn: 2,
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: `rgba(240, 240, 240, 1) url(${background}) top left`,
  },
  content: {
    position: 'relative',
    zIndex: 2,
    '& img': {
      maxHeight: '80px',
    }
  },
  gdpr: {
    marginTop: '20px',
    paddingTop: '28px',
    background: '#fff',
    padding: '2.375rem 1.25rem 1.875rem',
    margin: '1rem 0 1.3125rem',
    boxShadow: '0 1px 1px rgba(0,0,0,.18)',
    borderTop: '2px solid',
    height: '70vh',
    overflowY: 'scroll',
    '& tbody td': {
      paddingTop: '10px',
      paddingBottom: '10px',
      borderBottom: '1px solid #f0f0f0',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
    '& .MuiButton-root': {
      marginLeft: '10px'
    }
  },
  legalAccept: {
    padding: '20px',
    marginBottom: '50px',
    border: '1px solid #f0f0f0',
  },
  divider: {
    width: '90%',
    borderBottom: '2px solid #f0f0f0',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '50px',
  }
})

export default styles
