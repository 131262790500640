import React from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { TextField, Typography, withStyles, Button, IconButton } from '@material-ui/core'
import ButtonLink from 'components/button_link/View'
import styles from './View.Style'

const ContributionForm = props => {
  const {
    classes,
    timeValue,
    budgetValue,
    otherValue,
    onTimeChange,
    onBudgetChange,
    onOtherChange,
    onClose,
    isEditing,
    currencySymbol,
    canMakeItReal,
    isTimeActive,
    isBudgetActive,
    isOtherActive,
    onSaveContribution,
    onDeleteContribution,
    errors,
  } = props
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {isEditing && (
          <Typography>{i18n.t('EXPLORE_IDEA.CONTRIBUTE.EDIT_YOUR_CONTRIBUTION')}</Typography>
        )}
        <IconButton className="icon-clear-field" disableRipple onClick={onClose} size="small" />
      </div>
      <div className={classes.form}>
        {(isTimeActive || isBudgetActive) && (
          <div>
            <div>
              {isTimeActive && (
                <TextField
                  id="time-field"
                  label={i18n.t('EXPLORE_IDEA.CONTRIBUTE.TIME')}
                  type="number"
                  onChange={event => onTimeChange(event.target.value)}
                  defaultValue={timeValue}
                  variant="outlined"
                  InputProps={{ endAdornment: 'h' }}
                  error={!!errors.time}
                  helperText={errors.time || ''}
                />
              )}
              {isBudgetActive && (
                <TextField
                  id="budget-field"
                  label={i18n.t('EXPLORE_IDEA.CONTRIBUTE.CASH')}
                  onChange={event => onBudgetChange(event.target.value)}
                  defaultValue={budgetValue}
                  type="number"
                  InputProps={{ endAdornment: currencySymbol }}
                  variant="outlined"
                  error={!!errors.budget}
                  helperText={errors.budget || ''}
                />
              )}
            </div>
            <p className={classes.helpText}>{i18n.t('EXPLORE_IDEA.CONTRIBUTE.FORM_INFO')}</p>
          </div>
        )}
        {isOtherActive && (
          <TextField
            id="other-field"
            label={`${i18n.t('EXPLORE_IDEA.CONTRIBUTE.OTHER')} ${otherValue.length}/120`}
            multiline
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={event => onOtherChange(event.target.value)}
            defaultValue={otherValue}
            variant="outlined"
            maxRows={4}
            minRows={4}
            inputProps={{ maxLength: 120 }}
            error={!!errors.other}
            helperText={errors.other || ''}
          />
        )}
      </div>
      <div className={classes.buttonsWrapper}>
        {isEditing && (
          <ButtonLink onLinkClick={onDeleteContribution}>
            <span className="icon-trash" />
            {i18n.t('EXPLORE_IDEA.CONTRIBUTE.DELETE_MY_CONTRIBUTION')}
          </ButtonLink>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={!canMakeItReal}
          onClick={onSaveContribution}
        >
          {i18n.t('EXPLORE_IDEA.CONTRIBUTE.MAKE_IT_REAL')}
        </Button>
      </div>
    </div>
  )
}

ContributionForm.propTypes = {
  timeValue: PropTypes.string.isRequired,
  budgetValue: PropTypes.string.isRequired,
  otherValue: PropTypes.string.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  onBudgetChange: PropTypes.func.isRequired,
  onOtherChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  isTimeActive: PropTypes.bool.isRequired,
  isBudgetActive: PropTypes.bool.isRequired,
  isOtherActive: PropTypes.bool.isRequired,
  canMakeItReal: PropTypes.bool.isRequired,
  onSaveContribution: PropTypes.func.isRequired,
  onDeleteContribution: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
}

export default withStyles(styles)(ContributionForm)
